import request from "apiInstance";
import toast from "react-hot-toast";

export const getAllOpenMarketInvoices = async ({
  page = 0,
  pageSize = 10,
  searchQuery = "",
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/list-invoices?page=${page}&size=${pageSize}&search=${searchQuery}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const availableOpenMarketInvoiceApi = async ({
  page = 0,
  pageSize = 10,
  searchQuery = "",
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/available?page=${page}&size=${pageSize}&search=${searchQuery}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const placeBidOnInvoice = async ({ id, body }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/open-market/place-offer?invoiceID=${id}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const updateFunderOpenMarketConfigApi = async ({
  programType = "OPEN_MARKET",
  discountRate,
  recourseType,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/rule/open-market?programType=${programType}&discountRate=${discountRate}&recourseType=${recourseType}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getFunderOpenMarketConfigApi = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/rule/get-funder-default-discount-for-open-market`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const lockAnInvoice = async ({ id }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/open-market/lock-invoice?invoiceID=${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const updateAnInvoice = async ({ id, body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/open-market/update-offer?offerID=${id}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const cancelInvoiceBid = async ({ offerId }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/open-market/cancel-bid?offerID=${offerId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const invoiceByStatus = async ({
  page = 0,
  pageSize = 10,
  status,
  searchQuery = "",
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/list-offers?size=${pageSize}&search=${searchQuery}&page=${page}&status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const invoiceByID = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/view-invoice?invoiceID=${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const invoiceBidByID = async ({ offerId }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/view-offer?offerID=${offerId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderDashBoard = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/funder-dashboard`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderWonBids = async ({ page, pageSize }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/funder-won-bids?page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const supplierOpenMarket = async ({
  page = 0,
  pageSize = 20,
  search = "",
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/market-requests?page=${page}&size=${pageSize}&search=${search} `,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const supplierOpenMarketByStatus = async ({ status, search = "" }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/market-requests?search=${search}&status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const updateBidOfferStatus = async ({ status, offerId }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/open-market/offer/modify-status/${offerId}?status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllBidOffers = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/invoices/${id}/bids`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const invoiceImportIntoOpenMarket = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/fund-request/open-market",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const importInvoiceOpenMarketApi = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/open-market/request-open-market",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const cancelSupplierPendingConfirmation = async ({
  openMarketReference,
}) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/open-market/cancel-open-market-invoice/${openMarketReference}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllBidDetails = async ({ openMarketReference }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/bid-details/${openMarketReference}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getBidHistory = async ({
  openMarketReference,
  page = 0,
  pageSize = 20,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/history/${openMarketReference}?page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// // // // // // // // // // // // // // BUYER OPEN MARKET // // // // // // // // // // // // // // // // //
export const findAllBuyerOpenMarket = async ({
  page,
  pageSize,
  searchTerm,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/buyer-pending-funding-invoices?page=${page}&size=${pageSize}&searchTerm=${searchTerm} `,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const findFundedBuyerOpenMarket = async ({
  page,
  pageSize,
  searchTerm,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/buyer-open-market-funded-invoice?page=${page}&size=${pageSize}&searchTerm=${searchTerm} `,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const pendingBuyerConfirmationSupplierApi = async ({
  page = 0,
  pageSize = 10,
  searchQuery = "",
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/find-all-suppliers-pending-invoices?page=${page}&size=${pageSize}&searchTerm=${searchQuery}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getCurrentLockRateApi = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/get-open-market-lock-rate/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const approveOpenMarketFunding = async ({ body }) => {
  try {
    const response = await request({
      method: "put",
      url: "/api/v1/open-market/approve-open-market-funding",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const lockedInvoicesSupplierApi = async ({
  page = 0,
  pageSize = 10,
  searchQuery = "",
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/find-all-suppliers-invoices-by-status-locked?page=${page}&size=${pageSize}&searchTerm=${searchQuery}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const pendingBuyerOpenMarket = async ({ page, pageSize, searchKey }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/buyer-open-market-invoice-pending?page=${page}&size=${pageSize}&searchKey=${searchKey} `,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierOpenMarketDashboardApi = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/supplierDashboard`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const buyerOpenMarketDetails = async ({ openMarketReference }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/invoice-details/${openMarketReference}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllSupplierOpenMarketInvoicesApi = async ({
  page = 0,
  pageSize = 10,
  searchQuery = "",
  status = "",
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/supplier/invoices?page=${page}&size=${pageSize}${
        searchQuery === "" ? "" : `&searchTerm=${searchQuery}`
      }${status === "" ? "" : `&status=${status}`}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getInvoicesInOpenMarketFunderApi = async ({
  page = 0,
  pageSize = 10,
  searchQuery = "",
  status = "",
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/all-invoices-in-open-market?page=${page}&size=${pageSize}&searchTerm=${searchQuery}&status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const ongoingBidOpenMarketFunderApi = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/bid-active-invoices-for-funder`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getOpenMarketPercentageLimitApi = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/global-percentage-limit`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderPlaceBidOpenMarketApi = async ({ body, invoiceId }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/open-market/place-bid?invoiceID=${invoiceId}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderUpdateOpenMarketBidApi = async (body) => {
  try {
    const response = await request({
      method: "put",
      url: `api/v1/open-market/update-bid`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderDetailsOpenMarketBidApi = async (id) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/open-market/funder/invoice-details/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const funderOpenMarketMetricsApi = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/funder-metric`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllFunderListClosedMarketApi = async ({ searchQuery }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/view-funders?search=${searchQuery}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
