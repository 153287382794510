import { motion, AnimatePresence } from "framer-motion";
import { PACautionIcon } from "assets/images/svgs";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  pendingBulkEarlyPaymentDetails,
  updateBulkDiscountPecentageClosedMarket,
} from "appstate/buyer/buyerSlice";
import toast, { LoaderIcon } from "react-hot-toast";

const EditDiscountModal = ({ setIsOpen, data, setData, earlyPaymentId }) => {
  const dispatch = useDispatch();
  const [discountRate, setDiscountRate] = useState(data?.discountRate || "");
  const [loading, setLoading] = useState(false);
  const handleDiscountRateChange = (e) => {
    const value = e.target.value.replace("%", "");
    if (value === "" || /^[0-9]*\.?[0-9]*$/.test(value)) {
      setDiscountRate(value);
    }
  };
  const handleFocus = (e) => {
    const { target } = e;
    const len = target.value.length;
    target.setSelectionRange(len - 1, len - 1);
  };

  const handleCursorPosition = (e) => {
    const { target } = e;
    const len = target.value.length;
    if (target.selectionStart === len) {
      target.setSelectionRange(len - 1, len - 1);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      handleCursorPosition(e);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    const body = {
      earlyPaymentRequestId: earlyPaymentId,
      invoiceId: data?.invoicesId,
      newDiscountRate: discountRate,
    };
    dispatch(updateBulkDiscountPecentageClosedMarket(body)).then((data) => {
      if (data?.payload?.success) {
        setLoading(false);
        setData({});
        setIsOpen(false);
        dispatch(
          pendingBulkEarlyPaymentDetails({
            id: earlyPaymentId,
            status: "PENDING_APPROVAL",
          })
        );
      } else {
        setLoading(false);
        toast.error(data?.payload?.message);
      }
    });
  };
  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const modalVariants = {
    hidden: { y: "-50%", opacity: 0 },
    visible: { y: "0%", opacity: 1 },
    exit: { y: "50%", opacity: 0 },
  };

  return (
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 z-40 bg-black bg-opacity-50 flex items-center justify-center"
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={overlayVariants}
        onClick={() => {
          setData({});
          setIsOpen(false);
        }}
      >
        <motion.div
          className="bg-white rounded-lg shadow-lg w-11/12 px-6  max-w-lg"
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={modalVariants}
          onClick={(e) => e.stopPropagation()}
        >
          <header className="text-lg italic font-bold py-5">
            Edit the discount rate
          </header>

          <main className="mt-5">
            <section className="flex justify-between pb-6 mb-8 border-b-[0.8px] border-[#D8D8D8]">
              <div className="flex flex-col gap-1 text-sm">
                <span className="font-medium">Discount Rate</span>
                <span>
                  <input
                    style={{
                      borderRadius: "4px",
                      border: "0.8px solid rgba(240, 128, 0, 0.40)",
                    }}
                    inputMode="decimal"
                    onKeyDown={handleKeyDown}
                    type="text"
                    placeholder="20%"
                    value={discountRate ? `${discountRate}%` : ""}
                    onFocus={handleFocus}
                    onClick={handleCursorPosition}
                    onChange={handleDiscountRateChange}
                    className="p-2 outline-none w-[65%] text-base"
                  />
                </span>
              </div>

              <div className="flex flex-col gap-2 text-sm items-end">
                <span className="font-semibold text-[20px]">
                  {data?.invoiceRef || "N/A"}
                </span>
                <span className="text-sm text-[#555]">Ref Number</span>
              </div>
            </section>

            <section className="flex gap-2 mb-6">
              <span>
                <PACautionIcon className="w-5 h-5" />
              </span>
              <em className="text-[#7B7878] font-semibold text-xs">
                The discount rate offered to suppliers upon request is only a
                benchmark rate. Please choose a different rate if desired.
              </em>
            </section>
          </main>

          <footer className="flex justify-between py-5">
            <motion.button
              whileTap={{ scale: 0.92 }}
              onClick={() => setIsOpen(false)}
              className="px-6 font-semibold py-3 text-red bg-white border border-red rounded-[5px] hover:bg-red hover:text-white transition-all ease-in-out"
            >
              Cancel
            </motion.button>

            <motion.button
              disabled={discountRate === "" || loading}
              whileTap={{ scale: 0.92 }}
              onClick={() => {
                handleSubmit();
              }}
              className="px-6 font-semibold flex justify-center items-center py-3 bg-primaryColor text-white min-w-[106.5px]   rounded-[5px] hover:bg-primaryColor/90 transition-all ease-in-out"
            >
              {loading ? <LoaderIcon /> : <span>Confirm</span>}
            </motion.button>
          </footer>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default EditDiscountModal;
