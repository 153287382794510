import { useSelector } from 'react-redux';

function SimpleCard() {
  const { buyerDashboardData } = useSelector((state) => state.buyer);
  return (
    <div className=' w-full mx-auto'>
      <div className='lg:flex-1 px-2  py-8   rounded-md bg-white w-full'>
        <div className=' flex flex-1 flex-wrap justify-evenly '>
          <Card
            amount={buyerDashboardData?.totalInvoices || 22}
            title='Total Invoice'
            color={'#F08000'}
          />
          <Card
            amount={buyerDashboardData?.totalEarlyPaymentRequest || 12}
            title='Early Payment Request'
            color={'#0076EC'}
          />
          <Card
            amount={buyerDashboardData?.totalTransactionWithFunders || 15}
            title='Transaction with Funders'
            color={'#2D0432'}
          />
        </div>
      </div>
    </div>
  );
}

function Card({ amount = 0, title, color }) {
  const cardStyle = {
    backgroundColor: color,
  };
  return (
    <div
      style={cardStyle}
      className={` cursor-pointer mb-2 flex flex-col  justify-center items-center text-white py-10  w-full md:w-[156px] h-[69px] rounded-md transition duration-500 transform hover:scale-105 ease-in-out`}
    >
      <div className=' flex flex-row justify-between gap-3  items-center '>
        <span className='block font-semibold pl-2 text-4xl md:text-[2rem] lg:text-[2.5rem] '>
          {amount}
        </span>
        <span className='block pr-2 text-sm'>{title}</span>
      </div>

      <span className='w-full flex px-[5%] justify-end '>
        <img src='/arrow-lead.svg' alt='' />
      </span>
    </div>
  );
}

export default SimpleCard;
