import React, { useEffect, useState } from "react";
import Graph from "./Graph";
// import Notification from "./Notification";
import RecentRequest from "./RecentRequest";
import {
  getDirectFundingRequests,
  getEarlyPaymentRequests,
  getEarlyPaymentRequestsBulk,
} from "appstate/api/invoice/invoice";
import toast from "react-hot-toast";

const Overview = () => {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [bulkData, setBulkData] = useState([]);
  const [total, setTotal] = useState("");
  const [directFundingSingleData, setDirectFundingSingleData] = useState([]);
  const [directFundingBulkData, setDirectFundingBulkData] = useState([]);
  // eslint-disable-next-line
  const [page, setPage] = useState(0);

  const fetchDirectFundingRequests = async (isBulk) => {
    setLoading(true);
    try {
      const response = await getDirectFundingRequests({
        bulk: false,
        page,
        size,
        search: "",
      });
      setDirectFundingSingleData(response?.data);
      setTotal(response?.meta?.totalElements);
    } catch (error) {
      toast.error("Failed to fetch funding requests!");
    } finally {
      setLoading(false);
    }
  };

  const fetchDirectFundingRequestsBulk = async (isBulk) => {
    setLoading(true);
    try {
      const response = await getDirectFundingRequests({
        bulk: true,
        page,
        size,
        search: "",
      });
      setDirectFundingBulkData(response?.data);
      setTotal(response?.meta?.totalElements);
    } catch (error) {
      toast.error("Failed to fetch funding requests!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDirectFundingRequests();
    fetchDirectFundingRequestsBulk();
    // eslint-disable-next-line
  }, []);

  const size = 10;

  const fetchEarlyPayments = async () => {
    setLoading(true);
    try {
      const response = await getEarlyPaymentRequests({
        page: 1,
        size,
        search: "",
        status: "",
      });
      setTotal(response?.meta?.totalElements);
      setData(response?.data);
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };

  const bulkFetchEarlyPaymentsRequest = async () => {
    setLoading(true);
    try {
      const response = await getEarlyPaymentRequestsBulk({
        page,
        size: 10,
        search: "",
        status: "",
      });
      setTotal(response?.meta?.totalElements);
      setBulkData(response?.data);
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEarlyPayments();
    bulkFetchEarlyPaymentsRequest();
    // eslint-disable-next-line
  }, []);

  const approvedEarlyPayments = [...data, ...bulkData].filter(
    (request) => request.status === "APPROVED"
  );

  const approvedDirectFunding = [
    ...directFundingSingleData,
    ...directFundingBulkData,
  ].filter((request) => request.status === "APPROVED");

  const totalApprovedEarlyPayments = approvedEarlyPayments?.length;
  const totalApprovedDirectFundingRequest = approvedDirectFunding?.length;

  const totalEarlyPaymentRequests = data?.length + bulkData?.length;

  const totalDirectFundingRequests =
    directFundingSingleData?.length + directFundingBulkData?.length;
  // console.log("totalEarlyPaymentRequests " , data)
  // eslint-disable-next-line
  const totalPages = total;
  // Dummy data

  return (
    <div className="roundedmd w-full mt5 mx6 mb[17px]">
      <div className="lg:flex gap-2 mt-5">
        {/* FIRST GRID */}
        <div className="py7 px2 py3 w-full lg:w-[60%] bg-[#FAFAFAFB] rounded-md">
          {/* CARDS */}
          <div className="grid grid-cols-2 m3 gap-2 gap-y-4 p1 m-7">
            <div className="flex flex-col w-300 h-full px-[20px] flexcol justify-between items-start bg-white rounded-[5.184px] shadow-md pb-2">
              <p className="text-[#11192A] font-normal text-base mt-3">
                Total Early Payment Request
              </p>
              <h1 className="text-[24px] font-semibold py[13px] mt7 h14 pt[10%] text-[#000] mb3">
                {totalEarlyPaymentRequests}
              </h1>
              <span className="py-3 px-2 my-5 bg-primaryColor w-full rounded-[5.184px] text-white">
                <p>Total Approved Early Payment Request</p>
                <h1 className="text-[24px] font-semibold">
                  {totalApprovedEarlyPayments}
                </h1>
              </span>
            </div>

            <div className="flex flex-col w-300 h-114 px-[20px] flexcol justify-between items-start bg-white rounded-[5.184px] shadow-md pb-2">
              <p className="text-[#11192A] font-normal text-base mt-3">
                Total Direct Funding Request
              </p>
              <h1 className="text-[24px] font-semibold py[13px] mt7 text-[#3E0445] mb3">
                {totalDirectFundingRequests}
              </h1>
              <span className="py-3 px-2 my-5 bg-secondaryColor w-full rounded-[5.184px] text-white">
                <p>Total Approved Direct Funding Request</p>
                <h1 className="text-[24px] font-semibold">
                  {totalApprovedDirectFundingRequest}
                </h1>
              </span>
            </div>
          </div>
        </div>
        {/* SECOND GRID */}
        <div className="w-full lg:w-[40%] rounded-2xl border border-transparent bg-white shadow-lg p-4 text-black items-center justify-center">
          <div className="text-black font-bold text-lg tracking-wide flex items-center justify-center">
            Requests Status
          </div>
          <div className="mt-5 flex items-center justify-center wfull">
            <Graph totalEarlyPaymentRequests={totalEarlyPaymentRequests} totalDirectFundingRequests={totalDirectFundingRequests} />
          </div>
        </div>
      </div>

      <div className="lg:flex gap-6 mt-5">
        {/* FIRST GRID */}
        {/* <div className="p-3 w-full lg:w-[45%] bg-white rounded-md">
          <Notification />
        </div> */}
        {/* SECOND GRID */}
        <div className="p-3 w-full lg:w[55%] bg-white rounded-md">
          <RecentRequest
            data={data}
            bulkData={bulkData}
            directFundingSingleData={directFundingSingleData}
            directFundingBulkData={directFundingBulkData}
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
