import {
  // PAIconCocacola,
  PAIconInvShop,
  // PAIconSmallDangote,
  // PAIconSmallNestle,
  // PAIconSmallShell,
  // PAIconSmallTrust,
  // PAIconSmallBrewIcon,
} from "assets/images/svgs";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

function WonBidCard({
  id,
  closedMarketReference = "N/A",
  buyerCompanyName = "N/A",
  invoiceType = "N/A",
  lockRate = "N/A",
  status = "N/A",
  requestAmount = "N/A",
  dueDate = "N/A",
  supplierCompanyName = "N/A",
  earlyPaymentDate,
  bulk,
}) {
  const navigate = useNavigate();
  const formatAmount = (amount) => {
    if (!amount) return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <motion.div
      onClick={() =>
        !bulk
          ? navigate(
              `/payablefinancing-closedmarket/funderclosedmarketoverviewdetails/${id}`
            )
          : navigate(
              `/payablefinancing-closedmarket/funderclosedmarketbulkoverviewdetails/${id}`
            )
      }
      whileTap={{ scale: 0.98 }}
      style={{ boxShadow: "0px 2px 4px 0px rgba(232, 226, 216, 0.36)" }}
      className="flex  cursor-pointer bg-white rounded-[10px] sm:w-full md:w-full gap-6"
    >
      {/* first layer */}

      <div className="flex flex-col cursor-pointer  bg-white py-5 px-6 rounded-[10px] sm:w-full md:w-full gap-6 ">
        <div className=" flex cursor-pointer justify-between bg-white   rounded-[10px] w-full sm:w-full md:w-full gap-6">
          <span className="flex gap-6  items-center flex-nowrap whitespace-nowrap">
            <PAIconInvShop />
            <div className="flex flex-col justify-center">
              <span className="font-[500] text-sm">
                {closedMarketReference}
              </span>
              <span className=" text-[#959595] text-[10px]">
                {"Reference number"}
              </span>
            </div>
          </span>
          {/* Button segment */}
          <span>
            <button
              className={`h-[37px] p-2.5 rounded-[5px]  flex-col justify-start items-start gap-0.5 inline-flex flex-nowrap whitespace-nowrap ${
                !bulk ? " bg-[#f08000]/10" : "bg-[#07593d]/10"
              }`}
            >
              <div className="text-black text-sm font-medium ">
                {bulk ? "Bulk Request" : "Single Request"}
              </div>
            </button>
          </span>
          {/* button stops here */}
          <span className="flex gap-6 items-center flex-nowrap whitespace-nowrap ">
            <div className="flex flex-col justify-center flex-nowrap whitespace-nowrap">
              <span className="font-[500] text-sm">{buyerCompanyName}</span>
              <span className=" text-[#959595] text-[10px]">Buyer</span>
            </div>
          </span>
          <span className="flex gap-6 items-center  flex-nowrap whitespace-nowrap">
            <div className="flex flex-col justify-center flex-nowrap whitespace-nowrap">
              <span className="font-[500] text-sm">{supplierCompanyName}</span>
              <span className=" text-[#959595] text-[10px]">Supplier</span>
            </div>
          </span>
          <span className="hidden extra-md:flex gap-6 ">
            <div className="flex flex-col justify-center">
              <span className="font-[500] text-[#2FA06A]  text-sm">
                {status === "LOCKED" ? "Locked" : status}
              </span>
              <span className=" text-[#959595] text-[10px]">
                Status of invoice
              </span>
            </div>
          </span>
          <button class=" p-2.5 bg-white  justify-center items-center gap-2 inline-flex"></button>
          {/* for button */}
          <div></div>{" "}
        </div>

        <div className="flex cursor-pointer justify-between bg-white   rounded-[10px] w-full sm:w-full md:w-full gap-6">
          <span className="flex gap-3  items-center flex-nowrap whitespace-nowrap">
            <div className="text-black text-sm font-medium ">Lock rate</div>
            <div className="text-[#949494] text-sm font-medium ">
              {lockRate}%
            </div>
          </span>

          <span className="flex gap-3  items-center flex-nowrap whitespace-nowrap">
            <div className="text-black text-sm font-medium ">
              Request Amount
            </div>
            <div className="text-[#949494] text-sm font-medium ">
              {formatAmount(requestAmount)}
            </div>
          </span>
          <span className="flex gap-3  items-center flex-nowrap whitespace-nowrap">
            <div className="text-black text-sm font-medium ">
              Early Payment Date
            </div>
            <div className="text-[#949494] text-sm font-medium ">
              {earlyPaymentDate}
            </div>
          </span>
          <span className="flex gap-3  items-center flex-nowrap whitespace-nowrap">
            <div className="text-black text-sm font-medium ">Due Date</div>
            <div className="text-[#949494] text-sm font-medium ">{dueDate}</div>
          </span>
        </div>
      </div>
    </motion.div>
  );
}

export default WonBidCard;
