import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "shared/Card";
import Loading from "shared/Loading";
import AccountModalContainer from "shared/accountModal/Modal";
import Heading from "shared/heading/Heading";
import {
  allBuyerInvoiceDataByStatus,
  getInvoiceForApprove,
} from "appstate/invoice/invoiceSlice";
import { useNavigate } from "react-router-dom";

function PendingApproval({ onRowSelect }) {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [companyName, setCompanyName] = useState("");
  // const [page, setPage] = useState(0);
  // const [startPage, setStartPage] = useState(0);
  // const [endPage, setEndPage] = useState(5);
  // const pageSize = 10;
  const status = "PENDING_APPROVAL";
  const { buyerInvoiceDataByStatus, isLoading } = useSelector(
    (state) => state?.invoice
  );

  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(allBuyerInvoiceDataByStatus({ status }));
  }, [dispatch, status]);

  useEffect(() => {
    setData(buyerInvoiceDataByStatus?.data);
    // if (buyerInvoiceDataByStatus?.data) {
    //   setPendingApprovalCount(buyerInvoiceDataByStatus?.data.length);
    // }
  }, [buyerInvoiceDataByStatus]);

  // const InvoiceDetails = (id) => {
  //   let path = `/invoice/buyer-invoice-details/${id}`;
  //   navigate(path);
  // };

  const InvoiceDetails = (id) => {
    let path = `/invoice/invoice/approve/${id}`;
    navigate(path);
  };

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const columns = [
    {
      name: "Invoice Reference",
      selector: (row) => row.invoiceReference,
    },
    { name: "Supplier", selector: (row) => row.supplierCompanyName },

    {
      name: "Invoice Date",
      selector: (row) => row.createdDate,
    },
    {
      name: "Due Date",
      selector: (row) => row.dueDate,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.grandTotal,
      cell: (row) => <p className="text-success">{formatAmount(row.grandTotal)}</p>,
    },
  ];

  const totalPages = buyerInvoiceDataByStatus?.meta?.totalElements;

  const handleRowSelect = (row) => {
    const checkedRows = row.selectedRows.map((curr) => curr.id);
    dispatch(getInvoiceForApprove(checkedRows));
    onRowSelect(checkedRows);
  };

  return (
    <>
      <AccountModalContainer
        accontModal
        isShown={open}
        onClose={() => {
          setOpen(false);
          setSuccess(false);
        }}
        // isLoading={associateLoading}
        success={success}
        warning="Are you sure you would like to approve this/these invoice upload?"
      />
      <Card className="rounded-lg">
        <Heading
          setCompanyName={setCompanyName}
          //   search={handleSearch}
          companyName={companyName}
          invoice
          // heading={!isLoading && `${totalPages} Invoices`}
          heading={!isLoading && `${totalPages ? totalPages : 0} Invoices`}
        />
        <div className="rounded-b-lg px-[24px] pb-[24px] min-h-[70vh]">
          <Table
            columns={columns}
            data={data}
            onRowClicked={(row) => InvoiceDetails(row.id)}
            pointer
            progressPending={isLoading}
            selectableRows
            handleRowSelect={handleRowSelect}
            progressComponent={<Loading />}
            tableHeader
            className="mt-5"
          />
          {/* <div className="flex justify-center gap-x-[8px] mt-[20px]">
            {!isLoading && totalPages > 10 && (
              <Pagination
                page={page}
                startPage={startPage}
                setEndPage={setEndPage}
                endPage={endPage}
                setStartPage={setStartPage}
                setPage={setPage}
                totalPages={totalPages}
              />
            )}
          </div> */}
        </div>
      </Card>
    </>
  );
}

export default PendingApproval;
