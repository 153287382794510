import { motion } from "framer-motion";
import {
  PAIconCocacola,
  PAIconInvShop,
  PAIconModelOne,
  PAIconTrophy,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleCancelButtonOn } from "../buttonSlice";
import TooltipShared from "shared/tooltip/Tooltip";
import { formatCurrency } from "utilities/helper/formatters";

function PendingBidRequestCard({
  openMarketId,
  setOfferId,
  leading,
  id,
  invoiceNumber = "R3456X89Y",
  buyerCompanyName = "Tacna Plc.",
  supplierCompanyName = "NJ Breweries",
  description = "1.2%",
  fundingAmount = "N272,847,900",
  lastModifiedDate = "2023-11-01",
  dueDate = "2023-11-01",
  numberOfBids = "0",
  status = "notleading",
  paymentDate = "N/A",
  lockRate = "N/A",
}) {
  const navigate = useNavigate();

  const formatDate = (dateArray) => {
    if (!Array.isArray(dateArray) || dateArray.length !== 3) return "";
    const date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };

  const dispatch = useDispatch();

  return (
    <motion.div
      whileTap={{ scale: 0.97 }}
      onClick={() => {
        dispatch(toggleCancelButtonOn());
        navigate(`ongoing/${id}?openMarketId=${openMarketId}`);
      }}
      style={{ boxShadow: "0px 4px 12px 5px rgba(0, 0, 0, 0.04)" }}
      className="bg-white cursor-pointer flex flex-col gap-6 px-4 py-5 rounded-[10px] sm:px-6 sm:py-6"
    >
      {/* Top Section */}
      <div className="flex flex-wrap justify-between gap-y-4">
        {/* Invoice Number */}
        <div className="flex gap-3 items-center w-full sm:w-auto">
          <PAIconInvShop />
          <div className="flex flex-col gap-1">
            <TooltipShared bold text={invoiceNumber} maxLength={20} />
            <span className="text-xs text-gray-500">Invoice Reference</span>
          </div>
        </div>

        {/* Buyer */}
        <div className="flex gap-3 items-center w-full sm:w-auto">
          <PAIconModelOne />
          <div className="flex flex-col gap-1">
            <span className="text-sm font-medium">{buyerCompanyName}</span>
            <span className="text-xs text-gray-500">Buyer</span>
          </div>
        </div>

        {/* Supplier */}
        <div className="flex gap-3 items-center w-full sm:w-auto">
          <PAIconCocacola />
          <div className="flex flex-col gap-1">
            <span className="text-sm font-medium">{supplierCompanyName}</span>
            <span className="text-xs text-gray-500">Supplier</span>
          </div>
        </div>
        {leading ? (
          <div className="flex items-center justify-center sm:justify-start">
            <div className="bg-[#FEC123] flex gap-0.5 items-center justify-center text-white p-2.5 rounded">
              <span className="text-sm font-medium">Currently Leading</span>
              <PAIconTrophy />
            </div>
          </div>
        ) : (
          <div className="flex items-center gap-2 w-full sm:w-auto">
            <div className="flex flex-col gap-1">
              <span className="text-sm text-secondaryColor font-medium">
                Bid Active
              </span>
              <span className="text-xs text-gray-500">Status of Invoice</span>
            </div>
          </div>
        )}
        {/* Status */}
      </div>

      {/* Middle Section (Status Badge) */}

      {/* Bottom Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-y-4 gap-x-6">
        {/* Lock Rate */}
        <div className="text-sm flex gap-2">
          <span className="text-black">Lock Rate:</span>
          <span className="text-gray-500">
            {lockRate ? formatCurrency(lockRate) : "-"}%
          </span>
        </div>

        {/* Request Amount */}
        <div className="text-sm flex gap-2">
          <span className="text-black">Request Amount:</span>
          <span className="text-gray-500">{formatCurrency(fundingAmount)}</span>
        </div>

        {/* Request Date */}
        <div className="text-sm flex gap-2">
          <span className="text-black">Request Date:</span>
          <span className="text-gray-500">{dueDate}</span>
        </div>

        {/* Payment Date */}
        <div className="text-sm flex gap-2">
          <span className="text-black">Payment Date:</span>
          <span className="text-gray-500">
            {paymentDate !== "N/A" ? formatDate(paymentDate) : "N/A"}
          </span>
        </div>
      </div>
    </motion.div>
  );
}

export default PendingBidRequestCard;
