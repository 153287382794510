import React from "react";
import WalletAdd from "../../../../../../assets/images/svgs/changeaccounticon.svg";
import EmptyWallet from "../../../../../../assets/images/svgs/empty-wallet.svg";
import { formatCurrency } from "utilities/helper/formatters";

const TransferFundModal = ({
  handleShowVirtualAccountSelection,
  handleCloseVirtualAccount,
  handleChangeAccount,
  handleCloseTransferModal,
  handleShowPin,
  invoice, 
  selectedAccount
}) => {
  return (
    <>
      <div className={`fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50 ${invoice ? 'w-full' : 'w-[900px]'}`}>
        <div className="relative w-full max-w-lg p-6 bg-white rounded-lg shadow-lg">
          <div>
            <h2>Transfer Funds</h2>
          </div>
          <br />
          <div>
            <h2 style={{ fontSize: "18px", lineHeight: "21px" }}>Accounts</h2>
          </div>
          <div className="flex justify-between">
            <p
              style={{
                fontSize: "12px",
                fontWeight: "600px",
                lineHeight: "14px",
                color: "#858285",
              }}
            >
              This virtual account would be used to fund this early payment
              request
            </p>
            <button
              onClick={handleChangeAccount}
              style={{
                width: "200px",
                color: "#BB0C0C",
                borderRadius: "8px",
                borderColor: "#BB0C0C",
                borderWidth: "1px",
                borderStyle: "solid",
              }}
              className="flex items-center justify-center"
            >
              <img
                src={WalletAdd}
                alt="Wallet Add Icon"
                className="w-5 h-5 mr-2"
              />
              Change Account
            </button>
          </div>
          <hr className="my-4 border-t border-[#F3F2F2]" />
          <div className="w-[60%]">
            <div className="bg-primaryColor p-2">
              <div className="flex justify-between">
                <div className="flex">
                  <img src={EmptyWallet} alt="" />
                  <span className="ml-2" style={{ color: "white" }}>
                    {selectedAccount?.accountName}
                  </span>
                </div>
                <div>
                  <span style={{ color: "white" }}>Virtual Account</span>
                </div>
              </div>
              <div>
                <span style={{ color: "white" }}>NGN {formatCurrency(Number(selectedAccount?.accountBalance))}</span>
              </div>
              <div className="flex">
                <span style={{ color: "white" }}>{selectedAccount?.bankName || "PayEdge"}</span>
                <span className="mx-2 border-l border-white h-6"></span>
                <span style={{ color: "white" }}>{selectedAccount?.accountNumber}</span>
              </div>
            </div>
          </div>
          <br />
          <div className="flex justify-between">
            <button
              onClick={handleCloseTransferModal}
              style={{
                borderColor: "#DE1515",
                color: "#DE1515",
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: "8px",
                padding: "8px 16px",
              }}
            >
              Cancel
            </button>
            <button
              className="py-2 px-8"
              onClick={handleShowPin}
              style={{ backgroundColor: "#F08000", color: "white" }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransferFundModal;
