import {
  PAIconCocacola,
  PAIconInvShop,
  PAIconModelOne,
} from "assets/images/svgs";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import TooltipShared from "shared/tooltip/Tooltip";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import { formatCurrency } from "utilities/helper/formatters";

function AcceptedInvoiceCard({
  bidOfferId,
  status,
  id,
  invoiceNumber,
  buyerCompanyName,
  funderCompanyName = "N/A",
  invoiceAmount,
  dueDate,
  lockRate,
  invoiceReference = "N/A",
}) {
  const navigate = useNavigate();

  const formatDate = (dateArray) => {
    if (!Array.isArray(dateArray) || dateArray.length !== 3) return "";
    const date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };
  return (
    <motion.div
      onClick={() => navigate(`acceptedInvoice/${id}`)}
      whileTap={{ scale: 0.98 }}
      style={{ boxShadow: "0px 2px 4px 0px rgba(232, 226, 216, 0.36)" }}
      className="flex cursor-pointer justify-between bg-white py-5 px-6 rounded-[10px]"
    >
      <span className="flex gap-6 w-[200px]  items-center">
        <PAIconInvShop />
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">
            <TooltipShared text={invoiceReference} maxLength={15} />
          </span>
          <span className=" text-[#959595] text-[10px]">Reference number</span>
        </div>
      </span>

      <span className="flex font-light gap-6 w-[100px]  items-center">
        <div className="flex flex-col justify-center">
          <span className="text-sm">
            <TooltipShared text={`INV-${invoiceNumber}`} maxLength={10} />
          </span>
          <span className=" text-[#959595] text-[10px]">Invoice number</span>
        </div>
      </span>

      <span className=" hidden w-[150px]  extra-md:flex gap-6 font-light items-center ">
        <PAIconCocacola />
        <div className="flex flex-col justify-center">
          <span className="text-sm">{funderCompanyName}</span>
          <span className=" text-[#959595] text-[10px]">Funder</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 font-light items-center ">
        <PAIconModelOne />
        <div className="flex flex-col justify-center">
          <span className="text-sm">{buyerCompanyName}</span>
          <span className=" text-[#959595] text-[10px]">Buyer</span>
        </div>
      </span>

      <span className="flex w-[80px] gap-6 font-light ">
        <div className="flex flex-col justify-center">
          <span className="text-secondaryColor text-sm">
            {formatCapitaliseString(status)}
          </span>
          <span className=" text-[#959595] text-[10px]">Status of Invoice</span>
        </div>
      </span>

      <span className="flex gap-6 w-[100px] font-light ">
        <div className="flex flex-col justify-center">
          <span className="text-sm">{formatCurrency(invoiceAmount)}</span>
          <span className=" text-[#959595] text-[10px]">Request Amount</span>
        </div>
      </span>

      <span className="hidden w-[100px]  semi-lg:flex gap-6 font-light ">
        <div className="flex flex-col justify-center">
          <span className="flex items-center gap-1 text-sm">
            <span>{formatCurrency(lockRate)}%</span> -{" "}
            <span className="text-[10px] text-secondaryColor">Recourse</span>
          </span>
          <span className=" text-[#959595] text-[10px]">Lock Rate</span>
        </div>
      </span>

      <span className="hidden  semi-lg:flex gap-6 font-light ">
        <div className="flex flex-col justify-center">
          <span className="text-sm">{formatDate(dueDate)}</span>
          <span className=" text-[#959595] text-[10px]">Due Date</span>
        </div>
      </span>

      <span className="hidden  semi-lg:flex gap-6 font-light ">
        <div className="flex flex-col justify-center">
          <span className="text-sm">{formatDate(dueDate)}</span>
          <span className=" text-[#959595] text-[10px]">Bid Closure Time</span>
        </div>
      </span>

      {/* <motion.button
        whileTap={{ scale: 0.9 }}
        className="py-2.5 px-4 rounded-[10px] bg-white border text-sm border-primaryColor"
      >
        View
      </motion.button> */}
    </motion.div>
  );
}

export default AcceptedInvoiceCard;
