import {
  PAIconArrowLeftGreen,
  PAIconEditPenYellow,
  PAIconModelOne,
} from "assets/images/svgs";
import React, { useEffect, useState } from "react";
import AccountModalContainer from "shared/accountModal/Modal";
import { AnimatePresence } from "framer-motion";
import AddFundModal from "./AddFundModal";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import SuccessModal from "shared/SuccessModal";
import SelectAccountModal from "./modalcomponent/SelectAccountModal";
import ImportInvoicesModal from "./importInvoicesModal";
import EnterPin from "features/openMarket/funder/components/modal/Pin";
import {
  allEarlyPaymentRequestBulkPending,
  allEarlyPaymentRequestSinglePending,
  importEarlyPaymentToCloseMarket,
  pendingBulkEarlyPaymentDetails,
} from "appstate/buyer/buyerSlice";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "utilities/helper/dateFormatterSpelt";
import { formatCapitalize } from "utilities/formatCapitalize";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import { formatCurrency } from "utilities/helper/formatters";
import TooltipShared from "shared/tooltip/Tooltip";
import EditDiscountModal from "./EditDiscountModal";

const DetailsBulk = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const earlyId = searchParams.get("earlyId");

  // const location = useLocation();
  // const { fundingChannel } = location.state || {};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [selectedFunderIds, setSelectedFunderIds] = useState(false);
  const [addFundModal, setaddFundModal] = useState(false);
  const [editDiscountModal, setEditDiscountModal] = useState(false);
  const [selectedAccountDetails, setSelectedAccountDetails] = useState([]);
  const [confirmSelectedAccountModal, setConfirmSelectedAccountModal] =
    useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isSelectAccountModalOpen, setIsSelectAccountModalOpen] =
    useState(false);

  const [successMessage, setSuccessMessage] = useState("");

  const [pinModal, setPinModal] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});

  const status = "PENDING_APPROVAL";

  const {
    pendingSingleEarlyPaymentDetailsData,
    pendingBulkEarlyPaymentDetailsData,
  } = useSelector((state) => state?.buyer);

  let totalInvoiceAmount =
    pendingBulkEarlyPaymentDetailsData?.data?.earlyPaymentInvoiceResponse?.reduce(
      (total, invoice) => {
        return total + invoice?.invoiceAmount;
      },
      0
    ) || 0;

  const handleBackBtnClick = () => {
    window.history.back();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleAcceptProceed = () => {
    setSuccess(false);
    setIsModalOpen(false);
    handleImportInvoice();
  };

  const handleAccountSubmit = () => {
    setIsSelectAccountModalOpen(false);
    setTimeout(() => {
      setConfirmSelectedAccountModal(true);
    }, 500);

    // setIsProceedModalOpen(true);
  };

  const handleProceedEnterPin = () => {
    setConfirmSelectedAccountModal(false);
    setTimeout(() => {
      setPinModal(true);
    }, 500);
  };

  const handleImportInvoice = () => {
    setRejectModalOpen(false);
    setaddFundModal(true);
  };

  function handleProceedToImport() {
    setaddFundModal(false);
    setIsSelectAccountModalOpen(true);
  }

  const handleClosedMarketImport = (pin) => {
    const pendingToast = toast.loading("Importing Invoice to Closed Market...");

    let earlyPayment = {
      earlyPaymentId: pendingBulkEarlyPaymentDetailsData?.data?.earlyPaymentId,
      payableAmount:
        pendingBulkEarlyPaymentDetailsData?.data?.totalPayableAmount,
      discountAmount:
        pendingBulkEarlyPaymentDetailsData?.data?.totalDiscountAmount,
    };

    const body = {
      funderId: [...selectedFunderIds],
      earlyPayments: [earlyPayment],
      virtualAccountId: selectedAccountDetails?.virtualAccountId,
      transactionPin: pin,
    };
    dispatch(importEarlyPaymentToCloseMarket(body)).then((data) => {
      if (data?.payload?.success) {
        setSelectedFunderIds([]);
        toast.dismiss(pendingToast);
        setPinModal(false);
        setSuccessMessage("Early Payment request sent for workflow approval");
        setIsSuccessModalOpen(true);
        dispatch(allEarlyPaymentRequestBulkPending({ status }));
        dispatch(allEarlyPaymentRequestSinglePending({ status }));
        setTimeout(() => navigate("/closedmarket"), 700);
      } else {
        toast.dismiss(pendingToast);
        toast.error(data?.payload?.message);
      }
    });
  };

  useEffect(() => {
    dispatch(pendingBulkEarlyPaymentDetails({ id: earlyId, status }));
  }, [dispatch, status, earlyId]);

  return (
    <>
      <div
        onClick={handleBackBtnClick}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
      >
        <PAIconArrowLeftGreen />
        <span className="flex items-center text-primaryColor">Go Back</span>
      </div>

      <div className="grid lg:grid-cols-[60%,40%] gap-5 min-h-[80vh]">
        {/* 60% */}
        <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px] pt-7">
          <div className="px-6 ">
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between">
                <div className="flex gap-1 font-medium">
                  <h1 className="text-[18px] font-medium">Reference No: | </h1>
                  <TooltipShared
                    text={
                      pendingBulkEarlyPaymentDetailsData?.data
                        ?.requestReferenceNumber || "RD34DASHKEG9KKGH"
                    }
                    textSize="text-[18px]"
                    maxLength={20}
                  />
                </div>
                <button
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                  className="px-4 py-2.5 text-sm  bg-primaryColor rounded-[5px] text-white"
                >
                  Import Selected Request
                </button>
              </div>

              <main className=" w-full flex justify-between">
                <div className="flex flex-col gap-3 w-[35%]">
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-x-[50px] justify-between">
                      <span className=" text-xs font-semibold">
                        Request Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {pendingBulkEarlyPaymentDetailsData?.data?.requestDate
                          ? formatDate(
                              pendingBulkEarlyPaymentDetailsData?.data
                                ?.requestDate
                            )
                          : "N/A"}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px] justify-between">
                      <span className=" text-xs font-semibold">
                        Total Request Amount:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {pendingBulkEarlyPaymentDetailsData?.data
                          ?.totalRequestAmount
                          ? formatDate(
                              pendingBulkEarlyPaymentDetailsData?.data
                                ?.totalRequestAmount
                            )
                          : "N/A"}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px] justify-between">
                      <span className=" text-xs font-semibold">
                        Total Invoice Amount:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {formatCurrency(totalInvoiceAmount)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" flex flex-col items-end">
                  <div className="items-end">
                    <button
                      className={`px-8 py-2 font-medium text-xs rounded-[4px] ${
                        pendingBulkEarlyPaymentDetailsData?.data?.status ===
                        "approved"
                          ? "text-[#2EA923] bg-[#D8EFD6] "
                          : "text-secondaryColor bg-secondaryColor/20"
                      } `}
                    >
                      {formatCapitaliseString(
                        pendingBulkEarlyPaymentDetailsData?.data?.status
                      ) || "Approved"}
                    </button>
                  </div>
                </div>
              </main>
            </div>

            <div className="flex justify-between my-5">
              <div className="flex flex-col">
                <span className=" font-bold text-sm">
                  {formatCapitalize(
                    pendingBulkEarlyPaymentDetailsData?.data?.buyerCompanyName
                  ) || "N/A"}
                </span>
                <span className="text-[#979797] text-xs">Buyer Details</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-sm">
                  {pendingBulkEarlyPaymentDetailsData?.data?.paymentTerms ||
                    "5"}{" "}
                  Days
                </span>
                <span className="text-[#979797] text-xs">Payment</span>
              </div>
              <div className="flex flex-col">
                <span className="font-bold text-sm">NGN</span>
                <span className=" text-[#979797] text-xs">Currency</span>
              </div>
            </div>

            <div className="flex flex-col gap-4 mt-5">
              <header className="border-b-[5px] flex justify-between items-center border-b-[#F6F5FA] pb-3">
                <h1 className="text-sm font-bold">Invoice List</h1>

                <span className="text-[#11192A] font-light text-lg">
                  {pendingBulkEarlyPaymentDetailsData?.data
                    ?.earlyPaymentInvoiceResponse?.length || 0}{" "}
                  Invoices
                </span>
              </header>
              <div className=" w-full overflow-x-auto">
                <table className="overflow-x-auto w-full">
                  <thead className="bg-subtleGray h-[70px] w-[50vw] overflow-x-auto">
                    <tr className=" bg-unusualGray p-2.5">
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Ref Number
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Due Date
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Request Amount
                      </td>
                      <td className="text-[#11192A] p-2.5 text-xs font-bold w-[120px]">
                        Discount rate
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Discount Amount
                      </td>
                    </tr>
                  </thead>
                  {pendingBulkEarlyPaymentDetailsData?.data?.earlyPaymentInvoiceResponse?.map(
                    (item, index) => (
                      <tbody key={index}>
                        <tr className=" py-[3px] px-1.5">
                          <td className=" text-xs text-[#11192A] p-2.5">
                            {formatCurrency(item?.invoiceRef)}
                          </td>
                          <td className=" text-xs text-[#11192A] p-2.5">
                            {item?.requestDate}
                          </td>
                          <td className=" text-xs text-[#11192A] p-2.5 w-[20%]">
                            {formatCurrency(item?.requestAmount)}
                          </td>

                          <td className=" text-xs text-[#11192A] p-2.5">
                            <span className="flex gap-1 items-center">
                              {" "}
                              {item?.discountRate}%{" "}
                              <span
                                onClick={() => {
                                  setSelectedDetails(item);
                                  setEditDiscountModal(true);
                                }}
                                className="cursor-pointer"
                              >
                                {" "}
                                <PAIconEditPenYellow />
                              </span>
                            </span>
                          </td>
                          <td className=" text-xs text-[#11192A] p-2.5">
                            {formatCurrency(item?.discountedAmount)}
                          </td>
                        </tr>
                      </tbody>
                    )
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* 40% */}
        <div className="bg-white rounded-[10px] gap-8 pt-5 pb-24">
          <div className="px-4">
            <h2 className="font-semibold text-lg leading-[30px] mb-4">
              Early Payment Details
            </h2>

            <div className="flex justify-between my-5">
              <div className="flex gap-2">
                <PAIconModelOne />
                <div className="flex flex-col">
                  <span className="text-sm">
                    {" "}
                    {
                      pendingSingleEarlyPaymentDetailsData?.data
                        ?.supplierCompanyName
                    }
                  </span>
                  <span className="text-[#979797]  text-xs"> Supplier</span>
                </div>
              </div>
              {/* <div className="flex flex-col">
                <span className="text-sm">
                  {invoiceIdData?.data?.discount}%
                </span>
                <span className="text-[#979797]  text-xs">Discount rate</span>
              </div> */}
            </div>

            <div className="flex justify-between pb-5">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-sm">
                    {formatCurrency(
                      pendingBulkEarlyPaymentDetailsData?.data
                        ?.totalDiscountAmount
                    )}
                  </span>
                  <span className="text-[#979797]  text-xs">
                    Total Discount Amount
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm">
                  {formatDate(
                    pendingBulkEarlyPaymentDetailsData?.data?.requestDate
                  )}{" "}
                  ( {pendingBulkEarlyPaymentDetailsData?.data?.paymentDay} days)
                </span>
                <span className="text-[#979797]  text-xs">
                  Early Payment Request Date
                </span>
              </div>
            </div>

            <div className="flex justify-between mt-5 border-b-[5px] border-b-[#F6F5FA] pb-10">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-sm">
                    {formatCurrency(
                      pendingBulkEarlyPaymentDetailsData?.data
                        ?.totalRequestAmount
                    )}
                  </span>
                  <span className="text-[#979797]  text-xs">
                    Total Request Amount
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm">
                  {" "}
                  {formatCurrency(
                    pendingBulkEarlyPaymentDetailsData?.data?.totalPayableAmount
                  )}
                </span>
                <span className="text-[#979797]  text-xs">
                  Total Payable amount
                </span>
              </div>
            </div>

            <div className="border-b-[5px] border-b-[#F6F5FA] pb-5">
              <h2 className="font-medium text-base my-3">
                Note from Supplier:
              </h2>
              <p className="text-[#959595] text-sm">
                {pendingBulkEarlyPaymentDetailsData?.data?.description}
              </p>
            </div>
          </div>

          {/* <AcceptModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            confirm={handleAcceptProceed}
            // checkedAccounts={checkedAccounts}
          /> */}

          <AccountModalContainer
            accontModal
            isShown={isModalOpen}
            onClose={() => {
              handleCloseModal();
            }}
            success={success}
            handleAssociate={handleAcceptProceed}
            warning=" Are you sure you would like to import this requests?"
          />
        </div>
      </div>

      <ImportInvoicesModal
        isOpen={isRejectModalOpen}
        onClose={handleCloseModal}
        handleImportInvoice={handleImportInvoice}
      />
      <AnimatePresence>
        {isSelectAccountModalOpen && (
          <SelectAccountModal
            overlayDark
            onClose={() => setIsSelectAccountModalOpen(false)}
            onAction={handleAccountSubmit}
            setSelectedAccountDetails={setSelectedAccountDetails}
          />
        )}
      </AnimatePresence>

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
        message={successMessage}
      />

      {/* Approve Selected Account */}
      <AnimatePresence>
        {confirmSelectedAccountModal && (
          <ViewDocumentModal
            onAction={() => handleProceedEnterPin()}
            onClose={() => setConfirmSelectedAccountModal(false)}
            proceed="Yes Proceed"
          >
            <span className="text-center">
              Are you sure you want to proceed with this account <br />
              <span className="font-bold italic text-center text-primaryColor">
                {selectedAccountDetails?.accountNumber}
              </span>{" "}
              <br />
              for the reimbursement of this request
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {addFundModal && (
        <AddFundModal
          onClose={() => setaddFundModal(false)}
          handleProceed={handleProceedToImport}
          selectedFunderIds={selectedFunderIds}
          setSelectedFunderIds={setSelectedFunderIds}
        />
      )}

      <AnimatePresence>
        {pinModal && (
          <EnterPin
            onClose={() => setPinModal(false)}
            onSubmit={(pin) => {
              handleClosedMarketImport(pin);
            }}
          />
        )}
      </AnimatePresence>

      {editDiscountModal && (
        <EditDiscountModal
          earlyPaymentId={earlyId}
          setIsOpen={setEditDiscountModal}
          setData={setSelectedDetails}
          data={selectedDetails}
        />
      )}
    </>
  );
};

export default DetailsBulk;
