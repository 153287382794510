import { PAIconArrowLeftGreen } from "assets/images/svgs";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import DetailCard from "../card/DetailCard";
import UpdateBillsModal from "../../modals/UpdateBillsModal";
import OngoingAcceptUpdateBidModal from "../../modals/AccepUpdateBidModal";
import InvoiceLockedModal from "../../modals/InvoiceLockedModal";
import OngoingCancelBidModal from "../../modals/OngoingCancelBidModal";
import OngoingBidCancelledSuccessModal from "../../modals/OngoingBidsCancelSuccessful";
import TransitionScale from "shared/animation/TransitionScale";
import MoreInvoiceIdSegment from "./MoreInvoiceIdSegment";
import toast from "react-hot-toast";
import PinModal from "shared/PinModal";
import { allVirtualAccounts } from "appstate/wallet/walletSlice";

import { useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
//funderFetchInvoiceDetails
import {
  fetchAllClosedMarketIngoingBidDetails,
  funderCloseMarketUpdateBid,
  funderCloseMarketCancelBid,
} from "appstate/funder/funderSlice";

const Details = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ongoingAcceptUpdateBidModal, setOngoingAcceptUpdateBidModal] =
    useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [ongoingCancelBidModal, setOngoingCancelBidModal] = useState(false);
  const [bidPin, setBidPin] = useState("");
  const [ongoingBidCancelledSuccessModal, setOngoingBidCancelledSuccessModal] =
    useState(false);
  const { id } = useParams();

  const goBack = () => {
    navigate(-1);
  };
  const dispatch = useDispatch();

  const { ongoingBidDetails } = useSelector((state) => state.funder);

  useEffect(() => {
    dispatch(fetchAllClosedMarketIngoingBidDetails({ closeMarketId: id }));
  }, [dispatch, id]);

  const [discount, setDiscount] = useState("");

  const handleOpenUpdateBid = () => {
    if (ongoingBidDetails?.closeMarketInvoice?.discount) {
      setDiscount(ongoingBidDetails?.closeMarketInvoice?.discount);
    }
    setIsModalOpen(true);
  };

  const handleUpdateBid = () => {
    if (ongoingBidDetails?.closeMarketInvoice?.discount) {
      setDiscount(ongoingBidDetails?.closeMarketInvoice?.discount);
    }
    setIsModalOpen(false);
    setOngoingAcceptUpdateBidModal(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setDiscount("");
    // setRejectModalOpen(false);
  };
  const handleInvoiceModalClose = () => {
    setIsInvoiceModalOpen(false);
    goBack();
  };
  const handleOnCancelSuccessClose = () => {
    setOngoingBidCancelledSuccessModal(false);
    goBack();
  };
  // const handleOnCancelBidTrue = () => {
  //   setOngoingCancelBidModal(false);
  //   setOngoingBidCancelledSuccessModal(true);
  // };

  const handleAccept = () => {
    // handlePinModal();

    setBidPin(true);

    setOngoingAcceptUpdateBidModal(false);
  };

  useEffect(() => {
    dispatch(allVirtualAccounts());
  }, [dispatch]);

  const leadingBid = ongoingBidDetails?.closeMarketBidHistory?.data?.filter(
    (elem) => elem.winning === true
  );

  const selectedAccounts = useSelector(
    (state) => state?.wallet?.selectedAccounts
  );

  const formatAmount = (amount) => {
    if (!amount) return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const handlePinModal = (pin) => {
    // setBidPin(false);  closedMarketRequestDetails
    const pendingToast = toast.loading("Updating your Bid...");

    //virtualAccount

    const bidBody = {
      amount: ongoingBidDetails?.closedMarketRequestDetails?.requestAmount,
      discountRate: parseFloat(discount),
      note: "note",
      virtualAccountId: selectedAccounts?.id || "",
      transactionPin: pin,
    };

    dispatch(
      funderCloseMarketUpdateBid({ closeMarketId: id, body: bidBody })
    ).then((data) => {
      if (data?.payload?.status === 200) {
        setIsInvoiceModalOpen(true);
        setBidPin(false);
      }
      if (data?.payload?.success) {
        toast.dismiss(pendingToast);
      } else {
        toast.dismiss(pendingToast);
        toast.error(data?.payload?.message);
      }
    });
  };

  const handleCancelBid = () => {
    const pendingToast = toast.loading("cancelling bid your Bid...");
    dispatch(
      funderCloseMarketCancelBid({
        bidId: ongoingBidDetails?.closedMarketRequestDetails?.closeMarketId,
      })
    ).then((data) => {
      if (data?.payload?.status === 200) {
        setOngoingBidCancelledSuccessModal(true);
        // setBidPin(false);
      }
      if (data?.payload?.success) {
        toast.dismiss(pendingToast);
        setOngoingBidCancelledSuccessModal(true);
      } else {
        toast.dismiss(pendingToast);
        toast.error(data?.payload);
        // console.log("ERROR====", data);
        // setDiscountRate("");
      }
    });
  };

  const [showCancelButton, setShowCancelButton] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCancelButton(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const othersBid = ongoingBidDetails?.closeMarketBidHistory?.data?.filter(
    (elem) => elem.winning !== true
  );

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const paymentDateObj = new Date(
        `${ongoingBidDetails?.closedMarketRequestDetails?.earlyPaymentDate}T00:00:00.000Z`
      );
      const currentDate = new Date();
      const timeDiff = paymentDateObj.getTime() - currentDate.getTime();

      if (timeDiff > 0) {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

        setCountdown({
          days,
          hours,
          minutes,
          seconds,
        });
      } else {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [ongoingBidDetails?.closedMarketRequestDetails?.earlyPaymentDate]);

  return (
    <>
      <UpdateBillsModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        handleYes={handleUpdateBid}
        discountRate={discount || ""}
        handleSetDiscountRate={(e) => setDiscount(e.target.value)}
      />

      <PinModal
        isOpen={bidPin}
        onClose={() => {
          setBidPin(false);
          setDiscount("");
        }}
        onSubmit={handlePinModal}
        // isLoading={isLoading}
        headerText="Enter Pin"
        instructionText="Enter your transaction PIN to verify your identity."
      />

      <OngoingAcceptUpdateBidModal
        isOpen={ongoingAcceptUpdateBidModal}
        onClose={() => setOngoingAcceptUpdateBidModal(false)}
        refNum={ongoingBidDetails?.closeMarketInvoice?.invoiceRef || ""}
        handleYes={handleAccept}
      />
      <InvoiceLockedModal
        isOpen={isInvoiceModalOpen}
        onClose={handleInvoiceModalClose}
      />
      <OngoingCancelBidModal
        isOpen={ongoingCancelBidModal}
        onClose={() => setOngoingCancelBidModal(false)}
        refNum={ongoingBidDetails?.closeMarketInvoice?.invoiceRef || ""}
        handleYes={handleCancelBid}
      />
      <OngoingBidCancelledSuccessModal
        isOpen={ongoingBidCancelledSuccessModal}
        onClose={handleOnCancelSuccessClose}
        message={"Your bid has been cancelled"}
      />
      <Link
        onClick={goBack}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
      >
        <PAIconArrowLeftGreen />
        <span className="flex items-center text-primaryColor">Go Back</span>
      </Link>

      <div className="grid lg:grid-cols-[60%,40%] gap-5">
        <TransitionScale className=" pb-5 bg-white  rounded-[10px]  pt-2">
          {/* Header with Left Padding */}

          {/* Other sections */}

          <section className="  mt-3">
            {/* Down Flex Texts */}
            <div className=" flex justify-between px-6  mt-4">
              {" "}
              <div class="text-[#222222] text-lg font-medium ">
                Reference No: |{" "}
                {ongoingBidDetails?.closeMarketInvoice?.invoiceRef}
              </div>
              <div class="h-[37px] p-2.5 rounded-[5px] border border-[#f08000]/20 justify-center items-center gap-2.5 inline-flex">
                <div class="text-[#f08000] text-sm font-medium ">
                  Bid Active
                </div>
              </div>
            </div>
            <div className=" flex justify-between px-6">
              {/* first stage */}

              <div class="lg:w-[240px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex  mt-5">
                <div class="self-stretch  flex-col justify-center items-start gap-1 flex ">
                  <div class="self-stretch justify-between items-center inline-flex">
                    <div class="text-[#222222] text-xs font-semibold ">
                      Invoice Number
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      INV-
                      {ongoingBidDetails?.closeMarketInvoice?.invoiceNo ||
                        "N/A"}
                    </div>
                  </div>
                  <div class="self-stretch justify-between items-center inline-flex ">
                    <div class="text-[#222222] text-xs font-semibold ">
                      Created Date:{" "}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      {ongoingBidDetails?.closeMarketInvoice?.createdDate}
                    </div>
                  </div>
                  <div class="self-stretch justify-between items-center inline-flex">
                    <div class="text-[#222222] text-xs font-semibold ">
                      Submitted Date:{" "}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      {ongoingBidDetails?.closeMarketInvoice?.submittedDate ||
                        "N/A"}
                    </div>
                  </div>
                  <div class="self-stretch justify-between items-center inline-flex">
                    <div class="text-[#222222] text-xs font-semibold ">
                      Approved Date:{" "}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      {ongoingBidDetails?.closeMarketInvoice?.approvedDate}
                    </div>
                  </div>
                  <div class="self-stretch justify-between items-center inline-flex">
                    <div class="text-[#222222] text-xs font-semibold ">
                      Due Date:{" "}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      {ongoingBidDetails?.closeMarketInvoice?.dueDate}
                    </div>
                  </div>
                  <div class="self-stretch justify-between items-center inline-flex">
                    <div class="text-[#222222] text-xs font-semibold ">
                      Issuance Date:{" "}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      {ongoingBidDetails?.closeMarketInvoice?.issuanceDate ||
                        "N/A"}
                    </div>
                  </div>
                </div>

                {/* stop */}
              </div>

              <div class="lg:w-[240px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex  mt-5">
                <div class="self-stretch  flex-col justify-center items-start gap-1 flex ">
                  <div class="self-stretch justify-between items-center inline-flex">
                    <div class="text-[#222222] text-xs font-semibold ">
                      Request Amount:
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      {
                        ongoingBidDetails?.closedMarketRequestDetails
                          ?.requestAmount
                      }
                    </div>
                  </div>
                  <div class="self-stretch justify-between items-center inline-flex ">
                    <div class="text-[#222222] text-xs font-semibold ">
                      Request Date:
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      {
                        ongoingBidDetails?.closedMarketRequestDetails
                          ?.fundRequestDate
                      }
                    </div>
                  </div>

                  <div class="self-stretch justify-between items-center inline-flex ">
                    <div class="text-[#222222] text-xs font-semibold ">
                      Payment Date:
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold ">
                      {
                        ongoingBidDetails?.closedMarketRequestDetails
                          ?.paymentDate
                      }
                    </div>
                  </div>
                </div>

                {/* stop */}
              </div>
            </div>

            {/* stops here */}
            <div className="  px-6   mt-11">
              <header className="border-b-[5px] border-b-[#F6F5FA] mt-3  flex justify-between py-1">
                {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                <div>
                  <div class="text-[#222222] text-lg font-semibold  ">
                    {ongoingBidDetails?.closeMarketInvoice?.buyerCompanyName}
                  </div>
                  <div class="text-[#7a7a7a] text-xs font-light  ">
                    Buyer details
                  </div>
                </div>

                <div class="h-[41px] flex-col justify-start items-start  inline-flex px-12">
                  <div class="text-[#222222] text-base font-semibold ">
                    {ongoingBidDetails?.closeMarketInvoice?.paymentTerms} Days
                  </div>
                  <div class="w-[100px] text-[#7a7a7a] text-xs font-light  mt-1">
                    Payment
                  </div>
                </div>

                <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                  <div class="text-[#222222] text-lg font-semibold ">
                    {" "}
                    {ongoingBidDetails?.closeMarketInvoice?.currency}
                  </div>
                  <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                    Currency
                  </div>
                </div>
              </header>

              <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">
                {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                <div class="text-[#11192a] text-sm font-semibold ">
                  Line Items
                </div>
                <div class="text-[#11192a] text-lg font-light  mr-6">
                  {ongoingBidDetails?.closeMarketInvoice?.items?.length} Total
                  Items{" "}
                </div>
              </header>

              <div className=" w-full overflow-x-auto mt-3 pb-4">
                <table className=" w-full overflow-x-auto">
                  <thead className="bg-subtleGray w-full p-6  flex-nowrap whitespace-nowrap  ">
                    <tr className=" bg-unusualGray  px-6 w-full flex-nowrap whitespace-nowrap ">
                      {/* <td className="text-[#11192A] p-2.5 text-xs font-bold">
                          Item Number
                        </td> */}
                      <td class="text-[#11192a] text-xs font-semibold  p-4  flex-nowrap whitespace-nowrap ">
                        Item{" "}
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Description{" "}
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Unit Price
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Quantity
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Amount
                        <div> (before tax)</div>
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Tax Rate
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Tax Amount
                      </td>
                      <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                        Total Price
                      </td>
                    </tr>
                  </thead>

                  <tbody className="  ">
                    {ongoingBidDetails?.closeMarketInvoice?.items?.length > 0 &&
                      ongoingBidDetails?.closeMarketInvoice?.items?.map(
                        (cell, idx) => {
                          return (
                            <tr key={idx} className=" w-full ">
                              <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 ">
                                {cell.itemName}
                              </td>
                              <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                {cell.description}
                              </td>
                              <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                {cell.unitPrice}
                              </td>
                              <td className="text-[#11192a] text-xs font-semibold mt-2 text-center">
                                {cell.quantity}
                              </td>
                              <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                {cell.amount}
                              </td>
                              <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                {cell.taxRate}
                              </td>
                              <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                {cell.taxAmount}
                              </td>
                              <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                {cell.totalPrice}
                              </td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
              {/* Calculate cost and price  shaded or gray section */}
            </div>

            <div className=" px-6   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2]">
              <div className="lg:flex md:flex justify-between  w-full sm:block ">
                {/* First left side */}
                <div>
                  <section>
                    {" "}
                    <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                      Receiving Funding Account Information
                    </div>
                    {/* <div class="text-[#222222] text-lg font-semibold ">
                          Receiving Funding Account Information
                        </div> */}
                    <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                      {
                        ongoingBidDetails?.closeMarketInvoice?.virtualAccount
                          ?.accountNumber
                      }
                    </div>
                    <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                      {
                        ongoingBidDetails?.closeMarketInvoice?.virtualAccount
                          ?.accountName
                      }
                    </div>
                  </section>
                </div>

                {/* second division */}

                <div>
                  {" "}
                  <div class=" flex-col justify-start items-start inline-flex lg:min-w-[290px]">
                    <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                      <div className=" flex w-full justify-between">
                        <div class="text-[#222222] text-xs font-semibold ">
                          Subtotal
                        </div>
                        <div class=" text-[#222222] text-xs font-semibold ">
                          {ongoingBidDetails?.closeMarketInvoice?.subTotal}
                        </div>
                      </div>
                      <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                        <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                          <span>VAT %</span>
                          <span>
                            {" "}
                            {ongoingBidDetails?.closeMarketInvoice?.vat}%
                          </span>
                        </div>
                        <div class="text-[#222222] text-xs font-medium ">
                          {/* {vatPercent} */}
                          {formatAmount(
                            ongoingBidDetails?.closeMarketInvoice?.vatAmount
                          )}
                        </div>
                      </div>

                      <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                        <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                          <span>Discount %</span>
                          <span>
                            {ongoingBidDetails?.closeMarketInvoice?.discount}%
                          </span>
                        </div>
                        <div class="text-[#222222] text-xs font-medium ">
                          {formatAmount(
                            ongoingBidDetails?.closeMarketInvoice
                              ?.discountAmount
                          )}{" "}
                        </div>
                      </div>
                    </section>
                    <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black">
                      <div class="text-[#222222] text-lg font-semibold ">
                        Grand Total
                      </div>
                      <div class="text-[#222222] text-lg font-semibold ">
                        {ongoingBidDetails?.closeMarketInvoice?.grandTotal}
                      </div>
                    </div>
                  </div>
                </div>

                {/* stops */}
              </div>
            </div>
          </section>
        </TransitionScale>{" "}
        <div className="bg-white rounded-[10px] gap-8 ">
          {/* <FundDetailSide /> */}
          {/* second */}
          <div className=" py-8 px-6 border-b border-[#c1c1c1]/60">
            {/* first flebox */}
            <div className=" flex flex-row justify-between w-full">
              <div class="text-black text-xl font-medium ">Bid Details</div>

              <div className="flex items-start justify-center gap-2 text-center  text-base font-bold text-gray-800 mb-2">
                <div>
                  <p>{countdown.days}</p>
                  <p className="text-[12px] text-[#777777]">days</p>{" "}
                </div>
                <div>
                  <p>{countdown.hours}</p>
                  <p className="text-[12px] text-[#777777]">hours</p>
                </div>
                :
                <div>
                  <p>{countdown.minutes}</p>
                  <p className="text-[12px] text-[#777777]">minutes</p>
                </div>{" "}
                :
                <div>
                  <p>{countdown.seconds}</p>
                  <p className="text-[12px] text-[#777777]">seconds</p>{" "}
                </div>{" "}
              </div>
            </div>
            {/* button section */}
            {ongoingBidDetails?.closedMarketRequestDetails
              ?.closedMarketStatus !== "BID_ACTIVE" && (
              <button
                class="h-[47px] px-2.5 py-[15px] bg-[#07593d] rounded-[5px] justify-center items-center gap-2.5 inline-flex mt-8 w-full cursor-pointer"
                onClick={handleOpenUpdateBid}
              >
                <div class="text-white text-base font-medium ">Update Bid</div>
              </button>
            )}
          </div>

          {/* Last section */}
          <div className="py-2  gap-8 flex flex-col ">
            {/*  Winning Bid section */}
            {/* Your bid seCTION */}
            <MoreInvoiceIdSegment
              details={ongoingBidDetails?.closeMarketInvoice}
              closedMarketRequestDetails={
                ongoingBidDetails?.closedMarketRequestDetails
              }
              currentDa={leadingBid}
              cancelBid={() => setOngoingCancelBidModal(true)}
              showButton={showCancelButton}
              otherBids={othersBid}
            />
            {/* stop it */}
          </div>

          {/* search section */}

          {/* stops here */}
        </div>
      </div>
    </>
  );
};

export default Details;
