import {
  PAIconAgreeFolder,
  PAIconAgreeReject,
  PAIconAgreeWarning,
  // PAIconDownloadFunding,
  PAIconInventoryArrowLeft,
  PAIconSignPen,
} from "assets/images/svgs";
import { useNavigate, useParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import { useEffect, useState } from "react";
//   import AgreementSignedModal from "../common/modals/AgreementSignedModal";
import { useDispatch, useSelector } from "react-redux";
//   import AgreementRejectedModal from "../common/modals/AgreementRejectedModal";
import { useRef } from "react";
// import html2pdf from "html2pdf.js";
import ProgressModal from "shared/progressModal/ProgressModal";
import AgreementSignedModal from "features/onboarding/common/modals/AgreementSignedModal";
import DashboardLayout from "layout/dashboardlayout";
import {
  acceptPfp,
  allFunderPfp,
  declinePfpRequest,
  viewAgreementDetailsById,
} from "appstate/funder/funderSlice";
import Loading from "shared/Loading";
import { agreementById } from "appstate/toolsAndAgreement/toolsAndAgreementSlice";
import toast from "react-hot-toast";
import AgreementRejectedModal from "./RejectModal";
import FundAccountOption from "../components/modals/SelectAccountModal";

function PFPAgreement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [signAgreementModal, setSignAgreementModal] = useState(false);
  const [rejectAgreementModal, setRejectAgreementModal] = useState(false);
  const [rejectedAgreement, setRejectedAgreement] = useState(false);
  const [downloadAgreementClick, setDownloadAgreementClick] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [selectAccountModal, setSelectAccountModal] = useState(false);
  // On Accept Agreement
  const [agreementAccepted, setAgreementAccepted] = useState(false);
  const [message, setMessage] = useState(false);
  const { id } = useParams();

  const { viewAgreementDetailsByIdData, isLoading } = useSelector(
    (state) => state?.funder
  );
  const { agreementByIdData } = useSelector(
    (state) => state?.toolsAndAgreements
  );
  const selectedAccounts = useSelector(
    (state) => state?.wallet?.selectedAccounts
  );

  const documentId = viewAgreementDetailsByIdData?.data?.documentId;
  // useEffect(() => {
  //   dispatch(agreementById({ id: documentId }));
  // }, [dispatch, documentId]);
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(agreementById({ id: documentId }));
    }, 2000); // 2000 ms = 2 seconds

    // Cleanup function to clear the timeout in case the component unmounts or dependencies change
    return () => clearTimeout(timer);
  }, [dispatch, documentId]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleSelectAccount = () => {
    setSelectAccountModal(false);
    setSignAgreementModal(true);
  }

  const handleAcceptAgreement = () => {
    setSignAgreementModal(true);
    const accountId = selectedAccounts?.id;
    dispatch(acceptPfp({ id, accountId })).then((data) => {
      if (data?.payload?.success) {
        setSignAgreementModal(false);
        setAgreementAccepted(true);
        setMessage(data?.payload?.message);
        // navigate(`/payablefinancing-campaign`);
      }
    });
    // setAgreementAccepted(true);
  };

  useEffect(() => {
    if (id) {
      dispatch(
        viewAgreementDetailsById({
          pfpId: id,
        })
      );
    }
  }, [dispatch, id]);

  const handleCloseAcceptAgreement = () => {
    setAgreementAccepted(false);
    dispatch(allFunderPfp());
    navigate(-1);
  };

  const handleCancelContinue = () => {
    setRejectedAgreement(false);
    navigate(-1);
  };

  const handleRejectAgreement = () => {
    // dispatch(uploadFundingAgreementDecision(false)).then((data) => {
    //   if (data?.payload?.success) {
    //     setRejectAgreementModal(false);
    //     setRejectedAgreement(true);
    //   }
    // });
    // setRejectAgreementModal(false);
    dispatch(declinePfpRequest({ id })).then((data) => {
      if (data?.payload?.success) {
        setRejectAgreementModal(false);
        setRejectedAgreement(true);
        dispatch(allFunderPfp());
        navigate(`/payablefinancing-campaign`);
      }
    });
  };
  // handle download

  const modalContentRef = useRef(null);

  const handleDownload = () => {
    setDownloadAgreementClick(true);
    setDownloadModal(true);

    setTimeout(() => {
      const url = agreementByIdData?.data?.url;
      if (url) {
        const link = document.createElement("a");
        link.href = url;
        link.download = agreementByIdData?.data?.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        toast.error("No URL available for download.");
      }
      setDownloadModal(false);
      setDownloadAgreementClick(false);
    }, 3500);
  };

  return (
    <DashboardLayout
      section="Payable Finance Program"
      subHeader="Here is an overview of your PFP"
    >
      {/* <Heading nohelp /> */}
      {!isLoading ? (
        <div className="flex flex-col gap-6 items-center w-full pb-20 pt-4 px-4 md:px-[3%] relative">
          <div
            onClick={() => handleBack()}
            className="left-[3%] cursor-pointer absolute flex items-center justify-center"
          >
            <PAIconInventoryArrowLeft />
            <p className="mt-[2px] text-sm font-normal text-lightGray">
              Go Back
            </p>
          </div>
          <header className="flex flex-col items-center w-full pt-6">
            <div>
              <h1 className="font-medium text-2xl">
                SIGN PAYABLE FINANCE PROGRAM
              </h1>
            </div>
          </header>

          <main className="rounded-[10px] bg-[#F6F6F680] h-full w-full">
            <div
              className={`flex flex-col ${
                downloadAgreementClick ? "lg:flex-col" : "lg:flex-row"
              } justify-between h-full w-full`}
            >
              <section
                ref={modalContentRef}
                className={`wfit ${
                  downloadAgreementClick ? "lg:w-fit" : "lg: w-1/2"
                } m-10 p-[30px] overflow-y-auto border-[1.39px] border-[#B4B4B433] rounded-[2px] bg-white ${
                  downloadAgreementClick ? "min-h-fit" : "max-h-[600px]"
                } scrollbar-hide pb-5`}
              >
                <header className="flex w-full mb-2.5 justify-between font-bold">
                  <span></span>
                  <span>{agreementByIdData?.data?.name}</span>
                  <span
                    // onClick={() => handleDownload()}
                    className="cursor-pointer"
                  >
                    {/* {!downloadAgreementClick && <PAIconDownloadFunding />} */}
                  </span>
                </header>

                {/* <iframe
                  src={agreementByIdData?.data?.url}
                  title="Funding Agreement Document"
                  width="100%"
                  height="600px"
                  className="border border-gray-300"
                  style={{ width: "100%", height: "100%", minHeight: "600px" }}
                /> */}
                {agreementByIdData?.data?.url ? (
                  <iframe
                    src={agreementByIdData?.data?.url}
                    title="Funding Agreement Document"
                    width="100%"
                    height="600px"
                    className="border border-gray-300"
                    style={{
                      width: "100%",
                      height: "100%",
                      minHeight: "600px",
                    }}
                  />
                ) : (
                  <div className="flex items-center justify-center">
                    <Loading />
                  </div>
                )}
              </section>

              <section className="min-h-[570px] rounded-r-[10px] px-10 py-8 bg-custom-orange w-full lg:w-[40%]">
                <div className="py-6 px-5 border-[0.5px] border-[#CFCFCF] rounded-[10px] h-full bg-white">
                  <header className="flex px-5 items-center justify-between">
                    <span className="font-semibold">Agreement Details</span>
                    <PAIconAgreeFolder onClick={handleDownload} />
                  </header>

                  <main className="px-5">
                    <div className="flex justify-between text-sm font-medium py-2.5 mt-2">
                      <span>Program name</span>
                      <span className="text-[#979797]">
                        {" "}
                        {viewAgreementDetailsByIdData?.data?.programName}
                      </span>
                    </div>

                    <div className="flex justify-between text-sm font-medium py-2.5">
                      <span>Agreement Initiator</span>
                      <span className="text-[#979797]">
                        {" "}
                        {viewAgreementDetailsByIdData?.data?.initiator}
                      </span>
                    </div>

                    <div className="flex justify-between text-sm font-medium py-2.5">
                      <span>Agreement Recipient</span>
                      <span className="text-[#979797]">
                        {viewAgreementDetailsByIdData?.data?.recipient}
                      </span>
                    </div>

                    <div className="flex justify-between text-sm font-medium py-2.5">
                      <span>Buyer Share</span>
                      <span className="text-[#979797]">
                        {viewAgreementDetailsByIdData?.data?.buyerShare}
                      </span>
                    </div>

                    <div className="flex justify-between text-sm font-medium py-2.5">
                      <span>Funder Share</span>
                      <span className="text-[#979797]">
                        {viewAgreementDetailsByIdData?.data?.funderShare}
                      </span>
                    </div>

                    <div className="flex justify-between text-sm font-medium py-2.5">
                      <span>Discount Rate</span>
                      <span className="text-[#979797]">
                        {viewAgreementDetailsByIdData?.data?.discountRate}
                      </span>
                    </div>

                    <div className="flex justify-between text-sm font-medium py-2.5">
                      <span>Payment Period</span>
                      <span className="text-[#979797]">
                        {viewAgreementDetailsByIdData?.data?.paymentPeriod}
                      </span>
                    </div>

                    <div className="flex justify-between text-sm font-medium py-2.5">
                      <span>Date of Initiation</span>
                      <span className="text-[#979797]">
                        {viewAgreementDetailsByIdData?.data?.initiationDate}
                      </span>
                    </div>

                    <div className="flex justify-between text-sm font-medium py-2.5">
                      <span>Duration</span>
                      <span className="text-[#979797]">
                        From: {viewAgreementDetailsByIdData?.data?.startDate}{" "}
                        <br /> To: {viewAgreementDetailsByIdData?.data?.endDate}
                      </span>
                    </div>
                    {/* 
                  <div className="flex justify-between text-sm font-medium py-2.5">
                    <span>Description</span>
                  </div>

                  <div className="py-2.5 text-[#979797] text-sm font-medium">
                    The funding agreement outlines the detailed terms and
                    conditions under which you will receive financial support.
                    It ensures both parties clearly understand their obligations
                    and expectations, providing a framework for the funding
                    process.
                  </div> */}
                  </main>
                </div>
              </section>
            </div>

            <footer className="w-full flex flex-col md:flex-row justify-between px-10 py-5 shadow-sm rounded-b-[10px] bg-white">
              <section className="flex gap-3 items-center">
                <span>
                  <PAIconAgreeWarning />
                </span>

                <div className="text-sm font-semi">
                  By clicking the{" "}
                  <span className="text-secondaryColor font-extrabold">
                    “Click to sign”
                  </span>{" "}
                  button, you're approving <br /> and confirming your consent to
                  the above agreement
                </div>
              </section>
              <section className="flex gap-4 text-sm">
                <motion.button
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setSelectAccountModal(true)}
                  className="font-medium flex gap-2.5 justify-center items-center py-2.5 px-5 text-white rounded-[5px] bg-secondaryColor"
                >
                  Click to sign <PAIconSignPen />
                </motion.button>

                <motion.button
                  className="flex gap-2.5 items-center justify-center rounded-[5px] border text-[#CD3A1A] border-[#CD3A1A] py-2.5 px-5"
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setRejectAgreementModal(true)}
                >
                  Reject
                  <PAIconAgreeReject />
                </motion.button>
              </section>
            </footer>
          </main>

          <AnimatePresence>
            {selectAccountModal && (
              // <ViewDocumentModal
              //   onAction={() => {
              //     handleAcceptAgreement();
              //   }}
              //   onClose={() => setSignAgreementModal(false)}
              // >
              //   <span className=" text-center">
              //     Are you sure you want to{" "}
              //     <span className="text-primaryColor">Sign</span> this
              //     Agreement?
              //   </span>
              // </ViewDocumentModal>
              <FundAccountOption
                // isOpen={signAgreementModal}
                isOpen={setSelectAccountModal}
                onClose={() => setSelectAccountModal(false)}
                HeaderText={<p>Select which account to fund with</p>}
                // onSubmit={handleAcceptAgreement}
                onSubmit={handleSelectAccount}
              />
            )}
          </AnimatePresence>

          {signAgreementModal && (
            <ViewDocumentModal
              onAction={handleAcceptAgreement}
              onClose={() => setSignAgreementModal(false)}
              proceed="Yes Proceed"
            >
              <span className=" text-center">
                Are you sure you want to proceed with this account{" "}
                <span className="font-bold text-center text-primaryColor">
                  {selectedAccounts?.accountNumber}
                </span>{" "}
                to bid on this invoice
              </span>
            </ViewDocumentModal>
          )}

          <AnimatePresence>
            {rejectedAgreement && (
              <AgreementRejectedModal
                onClose={() => setRejectedAgreement(false)}
                onContinue={handleCancelContinue}
              ></AgreementRejectedModal>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {rejectAgreementModal && (
              <ViewDocumentModal
                onAction={() => {
                  handleRejectAgreement();
                }}
                onClose={() => {
                  setRejectAgreementModal(false);
                }}
              >
                <span className=" text-center">
                  Are you sure you want to{" "}
                  <span className="text-[#BB0C0C]">Reject</span> this Agreement?
                </span>
              </ViewDocumentModal>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {agreementAccepted && (
              <AgreementSignedModal
                extraStyle="text-center"
                onClose={() => {
                  handleCloseAcceptAgreement();
                }}
                message={message}
              />
            )}
          </AnimatePresence>
        </div>
      ) : (
        <div className="flex items-center justify-center h-screen">
          <Loading />
        </div>
      )}

      {downloadModal && <ProgressModal />}
    </DashboardLayout>
  );
}

export default PFPAgreement;
