import { PAIconArrowLeftGreen, PAIconCocacola } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useParams } from "react-router-dom";

import TransitionScale from "shared/animation/TransitionScale";
import DirectFundingAcceptModal from "./modals/DirectFundingAcceptModal";
import { useEffect, useState } from "react";
import ConfirmDirectFundingModal from "./modals/ConfirmDirectFundingModal";

// import DirectFundingBidSelectAccountModal from "./modals/DirectFundingBidSelectAccountModal";

// import BidPinModal from "features/payableFinaceForFunder/CloseMarket/modals/BidPinModal";
import BidProceedModal from "features/payableFinaceForFunder/CloseMarket/modals/BidProceedModal";
import BidLoaderModal from "features/payableFinaceForFunder/CloseMarket/modals/BidLoaderModla";
import DirectFundingPayBidSuccessOnModal from "./modals/DirectFundingPaySucessOnBoardModal";
import DirectFundingRejectModal from "./modals/DirectFundingRejectModal";
import ReasonForRequiredModal from "./modals/ResaonRequired";
import DirectFundingDeclinedModal from "./modals/DirectFundingDeclined";
import {
  approveDirectFunding,
  getDirectFundingDetails,
  getVirtualAccounts,
  rejectDirectFunding,
} from "appstate/api/invoice/invoice";
import EarlyPaymentVirtualAccountSelection from "../fundingSection/earlyPaymentRequest/singlerequest/Modal/VirtualAccountSelection";
import PinModalPayment from "../fundingSection/earlyPaymentRequest/singlerequest/Modal/PinModal";
import EarlyPaymentConfirmation from "../fundingSection/earlyPaymentRequest/singlerequest/Modal/EarlyPaymentConfirmation";
import toast from "react-hot-toast";

const FunderDirectFundingDetailsAccepted = () => {
  const { id } = useParams();
  const [directFundingData, setDirectFundingData] = useState(null);
  // eslint-disable-next-line
  const [error, setError] = useState(null);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const [virtualAccounts, setVirtualAccounts] = useState([]);
  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [bidSelectAccountModal, setBidSelectAccountModal] = useState(false);

  const [bidProceed, setBidProceed] = useState(false);
  const [bidPin, setBidPin] = useState(false);
  const [bidLoaderModal, setBidLoaderModal] = useState(false);
  const [bidSuccess, setBidSuccess] = useState(false);
  const [reasonFor, setReasonfor] = useState(false);
  const [directRejectModal, setDirectRejectModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [tempData, setTempData] = useState([]);
  const [data, setData] = useState([]);
  const [newApprovedData, setNewApprovedData] = useState(null);


  useEffect(() => {
    const fetchDirectFundingDetails = async () => {
      try {
        const result = await getDirectFundingDetails(id);
        setDirectFundingData(result);
      } catch (error) {
        setError(error.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchDirectFundingDetails();
    }
  }, [id]);

  useEffect(() => {
    const fetchVirtualAccounts = async () => {
      try {
        const result = await getVirtualAccounts();
        setVirtualAccounts(result?.data);
      } catch (error) {
        console.error("Failed to fetch virtual accounts:", error);
      }
    };

    fetchVirtualAccounts();
  }, []);

  const handleRejectFunding = async () => {
    try {
      const response = await rejectDirectFunding({
        id: directFundingData?.id,
        rejectionReason: data?.text,
      });
      if (response?.success === true) {
        setReasonfor(false);
        setDirectRejectModal(true);
      }
    } catch (error) {
      console.error("Error rejecting funding:", error);
      toast.error(error);
    }
  };

  const handleApprove = async () => {
    try {
      setLoading(true);
      const pin = otpInputs?.toString();
      const transactPin = pin?.replace(/,/g, "");
      const payload = {
        transactionPin: transactPin,
        virtualAccountId: selectedAccount?.id,
        directFundingIds: [directFundingData?.id],
        comment: "Approval for funding processed.",
        discountRate: newApprovedData?.discount || directFundingData?.discountRate
      };
      const response = await approveDirectFunding(payload);
      if (response?.success === true) {
        setBidLoaderModal(true);
        setTimeout(() => {
          setBidLoaderModal(false);
          setBidSuccess(true);

          setTimeout(() => {
            setBidSuccess(false);
            navigate(-1);
          }, 3000);
        }, 2000);
      }
    } catch (error) {
      console?.error("Approval failed:", error);
      toast?.error("Something went wrong! Please try again .");
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();

  function handleNavigate() {
    let path = -1;
    navigate(path);
  }

  const handleProceed = () => {
    setBidProceed(false);

    setTimeout(() => {
      setBidPin(true);
    }, 300);
  };

  const handleYes = () => {
    setAcceptModal(false);
    setConfirmModal(true);
  };
  const handleReject = () => {
    setRejectModal(false);
    setReasonfor(true);
  };
  const [disCountPer, setDiscountPer] = useState("2%");

  const [prevDis, setPrevDis] = useState("2%");
  // const [editDiscount, setEditDiscount] = useState(false);
  const handleDiscountChange = (e) => {
    e.preventDefault();
    setDiscountPer(e.target.value);
  };

  const handleSave = () => {
    setDiscountPer(disCountPer);
    setPrevDis(disCountPer);
  };
  const handleCancel = () => {
    setDiscountPer(prevDis);
    setPrevDis(prevDis);
  };
  const handdleConfirmNext = () => {
    setConfirmModal(false);
    setBidSelectAccountModal(true);
  };
  const handleBidSuccessClose = () => {
    setBidSuccess(false);
    navigate(-1);
  };
  const handleContinue = () => {
    setReasonfor(false);
    setDirectRejectModal(true);
  };
  const handleDeclineClose = () => {
    setDirectRejectModal(false);
    handleNavigate();
  };
  const onClose = () => {
    setBidSelectAccountModal(false);
  };
  const onClosePinModal = () => {
    setBidPin(false);
  };
  const confirmPayment = () => {
    setShowConfirmModal(true);
    setBidPin(false);
  };
  const approvalDateArray = directFundingData?.invoice[0]?.approvalDate;
  const approvalDate = Array.isArray(approvalDateArray)
    ? approvalDateArray.join("-")
    : null;

  const createdDate = directFundingData?.invoiceDate;
  const datePart = createdDate?.split("T")[0];

  return (
    <>
      <TransitionScale>
        <DirectFundingAcceptModal
          mess1={"Would you like to proceed with "}
          blueText={"Approving "}
          mess2={" this Direct"}
          mess3={"funding request"}
          isOpen={acceptModal}
          onClose={() => setAcceptModal(false)}
          handleYes={handleYes}
        />
      </TransitionScale>
      <TransitionScale>
        <DirectFundingRejectModal
          mess1={"Would you like to proceed with "}
          blueText={"Rejecting "}
          mess2={" this Direct"}
          mess3={"funding request"}
          isOpen={rejectModal}
          onClose={() => setRejectModal(false)}
          handleYes={handleReject}
        />
      </TransitionScale>

      <TransitionScale>
        <ConfirmDirectFundingModal
          isOpen={confirmModal}
          onClose={() => setConfirmModal(false)}
          disValue={disCountPer}
          handleChange={handleDiscountChange}
          handleCancel={handleCancel}
          handleSave={handleSave}
          handleNext={handdleConfirmNext}
          newApprovedData={newApprovedData}
          setNewApprovedData={setNewApprovedData}
          directFundingId={directFundingData?.id}
          discountRate={directFundingData?.discountRate}
          discountAmount={directFundingData?.invoice[0]?.discountAmount}
          fundableAmount={directFundingData?.fundableAmount}
        />
      </TransitionScale>
      <TransitionScale>
        {
          bidSelectAccountModal && (
            <div>
              <EarlyPaymentVirtualAccountSelection
                handleCloseVirtualAccount={onClose}
                setOpen={setBidProceed}
                onClose={onClose}
                virtualAccounts={virtualAccounts}
                selectedAccount={selectedAccount}
                setSelectedAccount={setSelectedAccount}
                invoice={true}
              />
            </div>
          )
        }
      </TransitionScale>

      <TransitionScale>
        {bidLoaderModal && (
          <BidLoaderModal
            isOpen={bidLoaderModal}
            onClose={() => setBidLoaderModal(false)}
            onAction={() => {
              setBidLoaderModal(false);
              setTimeout(() => {
                setBidSuccess(true);
              }, 300);
            }}
            statusText={"Fund Invoice"}
          />
        )}{" "}
      </TransitionScale>

      {showConfirmModal && (
        <div className="absolute mt-[20px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[615px] h-[254px] z-10 rounded-[10px] shadow-lg">
          <EarlyPaymentConfirmation
            hide={() => setShowConfirmModal(false)}
            // isLoading={earlyPaymentLoading}
            handleAssociate={handleApprove}
            invoice={true}
            // earlyPayment={true}
            warning=" Would you like to proceed with approving this direct funding? "
          />
        </div>
      )}

      <TransitionScale>
        {" "}
        <PinModalPayment
          confirmPayment={confirmPayment}
          isOpen={bidPin}
          onCancel={onClosePinModal}
          otpInputs={otpInputs}
          setOtpInputs={setOtpInputs}
          invoice={true}
        />
      </TransitionScale>
      <TransitionScale>
        {" "}
        <BidProceedModal
          isOpen={bidProceed}
          refNum={selectedAccount?.accountNumber}
          onClose={() => setBidProceed(false)}
          handleYes={handleProceed}
        />
      </TransitionScale>
      <TransitionScale>
        <DirectFundingPayBidSuccessOnModal
          isOpen={bidSuccess}
          onClose={handleBidSuccessClose}
          message={"Early payment request sent for"}
          sucess={"workflow approval"}
        />
      </TransitionScale>
      {reasonFor && (
        <TransitionScale>
          <ReasonForRequiredModal
            onClose={() => setReasonfor(false)}
            onContinue={handleContinue}
            tempData={tempData}
            setTempData={setTempData}
            data={data}
            setData={setData}
            handleRejectFunding={handleRejectFunding}
          />
        </TransitionScale>
      )}
      <TransitionScale>
        <DirectFundingDeclinedModal
          isOpen={directRejectModal}
          onClose={handleDeclineClose}
        />
      </TransitionScale>
      {/* <TransitionScale>
        {" "}
        <BidAcceptModal
          isOpen={ongoingAcceptUpdateBidModal}
          onClose={() => setOngoingAcceptUpdateBidModal(false)}
          refNum={"R3456X89Y"}
          handleYes={handleAccept}
        />
      </TransitionScale> */}
      <DashboardLayout
        section="Direct Fund Request"
        subHeader="Manage all your direct fund requests"
      >
        <header>
          <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            {/* <PAIconInventoryArrowLeft />
      <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p> */}
            <PAIconArrowLeftGreen />
            <span className="flex items-center text-primaryColor">Go Back</span>
          </div>
        </header>

        <div className="grid lg:grid-cols-[60%,40%] gap-5">
          <TransitionScale className=" pb-5 bg-white  rounded-[10px] mt-5 pt-2 ">
            {/* Header with Left Padding */}

            {/* Other sections */}

            <section className="  mt-3">
              {/* Down Flex Texts */}
              <div className=" flex justify-between px-6">
                {/* first stage */}

                <div class="lg:w-[240px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex">
                  <div class=" justify-between items-center inline-flex mt-2">
                    <div class="text-[#222222] text-base font-semibold  ">
                      Reference No:
                    </div>
                    <div class="text-[#222222] text-base font-semibold ">
                      | {directFundingData?.reference}
                    </div>
                  </div>
                  <div class="self-stretch  flex-col justify-center items-start gap-1 flex mt-2">
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs w-[97px]">Invoice Number:</span>
                      <span className="text-[#979797]  text-xs">
                        {directFundingData?.invoiceNumber}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs w-[97px]">Created Date:</span>
                      <span className="text-[#979797]  text-xs">
                        {datePart}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs w-[97px]">Submitted Date:</span>
                      <span className="text-[#979797]  text-xs">
                        {directFundingData?.newRequestedDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs w-[97px]">Approved Date:</span>
                      <span className="text-[#979797]  text-xs">
                        {approvalDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[97px]">
                      <span className=" text-xs">Due Date:</span>
                      <span className="text-[#979797]  text-xs">
                        {directFundingData?.invoiceDueDate}
                      </span>
                    </div>
                  </div>

                  {/* stop */}
                </div>
                {/* second stage */}
                <div className="flex justify-between items-center mt-10 ">
                  {" "}
                  <div class="h-[37px] py-2 rounded-[5px]  bg-[#ffbe55]/30 justify-center items-center gap-2.5 inline-flex px-4">
                    <div class="text-[#f08000] text-sm font-medium ">
                      Pending Approval
                    </div>
                  </div>
                </div>
              </div>

              {/* stops here */}
              <div className="  px-6 mt-24">
                <header className="border-b-[5px] border-b-[#F6F5FA] mt-3  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
              Supplier/buyer details
            </h1> */}
                  <div>
                    <div class="text-[#222222] text-lg font-semibold  ">
                      {directFundingData?.funderCompanyName}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-light  ">
                      Buyer details
                    </div>
                  </div>

                  <div class="h-[41px] flex-col justify-start items-start  inline-flex px-12">
                    <div class="text-[#222222] text-base font-semibold ">
                      60 Days
                    </div>
                    <div class="w-[100px] text-[#7a7a7a] text-xs font-light  mt-1">
                      Payment terms
                    </div>
                  </div>

                  <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                    <div class="text-[#222222] text-lg font-semibold ">
                      {directFundingData?.currency}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                      Currency
                    </div>
                  </div>
                </header>

                <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
              Supplier/buyer details
            </h1> */}
                  <div class="text-[#11192a] text-sm font-semibold ">
                    Line Items
                  </div>
                  <div class="text-[#11192a] text-lg font-light  mr-6">
                    {directFundingData?.invoice[0]?.items.length} Total Items{" "}
                  </div>
                </header>
                <div className=" w-full overflow-x-auto mt-3 pb-4">
                  <table className=" w-full overflow-x-auto">
                    <thead className="bg-subtleGray w-full p-6  flex-nowrap whitespace-nowrap  ">
                      <tr className=" bg-unusualGray  px-6 w-full flex-nowrap whitespace-nowrap ">
                        {/* <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      Item Number
                    </td> */}
                        <td class="text-[#11192a] text-xs font-semibold  p-4  flex-nowrap whitespace-nowrap ">
                          Item{" "}
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Description{" "}
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Unit Price
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Quantity
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Amount
                          <div> (before tax)</div>
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Tax Rate
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Tax Amount
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Total Price
                        </td>
                      </tr>
                    </thead>

                    <tbody className="  ">
                      {/* here */}
                      {directFundingData?.invoice[0]?.items.map((cell, idx) => {
                        return (
                          <tr key={idx} className=" w-full ">
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap ">
                              {cell.itemName}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                              {cell.description}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                              {cell.unitPrice}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 text-center flex-nowrap whitespace-nowrap">
                              {cell.Quantity}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                              {cell.amount}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                              {cell.taxRate}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                              {cell.taxAmount}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                              {cell.totalPrice}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {/* Calculate cost and price  shaded or gray section */}
              </div>

              <div className=" px-6   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2]">
                <div className="lg:flex md:flex justify-between  w-full sm:block ">
                  {/* First left side */}
                  <div>
                    <section>
                      {" "}
                      <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                        Receiving Funding Account Information
                      </div>
                      {/* <div class="text-[#222222] text-lg font-semibold ">
                      Receiving Funding Account Information
                    </div> */}
                      <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                        {
                          directFundingData?.invoice[0]?.fundingAccountDetails
                            ?.accountNumber
                        }
                      </div>
                      <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                        {
                          directFundingData?.invoice[0]?.fundingAccountDetails
                            ?.accountName
                        }
                      </div>
                    </section>
                  </div>

                  {/* second division */}

                  <div>
                    {" "}
                    <div class=" flex-col justify-start items-start inline-flex lg:min-w-[290px]">
                      <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                        <div className=" flex w-full justify-between">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Subtotal
                          </div>
                          <div class=" text-[#222222] text-xs font-semibold ">
                            {/* 10,129,892 */}
                          </div>
                        </div>
                        <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                          <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                            <span>VAT%</span>
                            <span>{directFundingData?.invoice[0]?.vat}%</span>
                          </div>
                          <div class="text-[#222222] text-xs font-medium ">
                            {/* 573,960 */}
                          </div>
                        </div>

                        <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                          <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                            <span>Discount%</span>
                            <span>{directFundingData?.discountRate}%</span>
                          </div>
                          <div class="text-[#222222] text-xs font-medium ">
                            {directFundingData?.invoice[0]?.discountAmount}
                          </div>
                        </div>
                      </section>
                      <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black">
                        <div class="text-[#222222] text-lg font-semibold ">
                          Grand Total
                        </div>
                        <div class="text-[#222222] text-lg font-semibold ">
                          {directFundingData?.invoice[0]?.grandTotal}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* stops */}
                </div>
              </div>
            </section>
          </TransitionScale>
          {/* stops the best here */}

          <div className="bg-white rounded-[10px] gap-8  mt-5">
            {/* <FundDetailSide /> */}
            <div class="w-full justify-start items-center gap-4 inline-flex ">
              <div class=" justify-start items-center gap-2.5 flex w-full px-6  py-3">
                <div class="text-black text-xl font-bold  mt-5">
                  Direct Funding Details
                </div>
              </div>
            </div>

            <div>
              {" "}
              {/* second */}
              <div className="   pb-4">
                <div class=" justify-between  flex flex-row    px-6 mt-4  ">
                  <div className="flex gap-2 py-2.5">
                    <PAIconCocacola />
                    <div className="flex flex-col">
                      {/* <span className=" text-black text-xs font-normal">
                    Coca Cola
                  </span> */}
                      <span class="text-black text-sm font-normal ">
                        {directFundingData?.funderCompanyName}
                      </span>
                      {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                      <span class="text-[#949494] text-sm font-normal ">
                        Buyer
                      </span>
                    </div>
                  </div>
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {" "}
                        {directFundingData?.discountRate}%
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Discount rate
                      </div>
                    </div>
                  </div>

                  {/* here */}
                </div>
                <div class=" justify-between  flex flex-row    px-6  mt-6 ">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {directFundingData?.invoice[0]?.discountAmount}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Discount amount
                      </div>
                    </div>
                  </div>
                  {/* hd */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {directFundingData?.invoiceDate}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Payment Date
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div class=" justify-between  flex flex-row    border-b border-[#c2c2c2]/30 px-6  py-3  pb-12 mt-6">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        N {directFundingData?.newRequestedAmount}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Request amount
                      </div>
                    </div>
                  </div>
                  {/* dhdh */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {directFundingData?.fundableAmount}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Fundable Amount
                      </div>
                    </div>
                  </div>
                </div>
                {/* 2nd */}
                <div class=" justify-between  flex flex-col    border-b border-[#c2c2c2]/30 px-6  pb-10 ">
                  <div class="text-black text-lg font-semibold  mt-6">
                    Note from Supplier:{" "}
                  </div>

                  <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                    {directFundingData?.noteFromSupplier}
                    <br className=" mt-3" />
                    {/* Best regards, <br /> */}
                    {/* ABC Limited */}
                  </div>
                </div>{" "}
                {/* another header */}
                {/* header ends here */}
                {/* here */}
                <div class=" justify-between  flex flex-col    px-6  ">
                  <div class="text-black text-lg font-semibold  mt-6">
                    Your decision
                  </div>

                  <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                    {/* Early Payment Request: Please review and take action. Accept
                    if approved, or Reject if not suitable. Thank you */}
                  </div>

                  {/* stops */}
                </div>
                <div className=" grid lg:grid-cols-[47%,47%] gap-4 px-6  mt-7">
                  <button
                    className={`  px-3 text-white justify-center  flex items-center bg-[#07593d] rounded-[5px] py-2 text-sm ${"cursor-pointer"}`}
                    //   onClick={() =>
                    //     navigate("/invoice/invoice/fundingSection/invoiceupload")
                    //   }
                    onClick={() => setAcceptModal(true)}
                  >
                    Accept
                  </button>
                  <button
                    className={`    py-2 px-3  text-[#F08000]  border border-[#F08000] justify-center  flex items-center bg-white rounded-[5px]  text-sm ${"cursor-pointer"}`}
                    //   class=" px-6 py-2 bg-white rounded-[5px] border border-[#de1515] justify-center items-center gap-2.5 inline-flex"
                    onClick={() => setRejectModal(true)}
                  >
                    <div>Reject</div>
                  </button>
                </div>
                {/* stops here */}
              </div>
              {/* stops here */}
              {/* Third */}
              {/* third stops */}
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};
export default FunderDirectFundingDetailsAccepted;
