import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import Card from "shared/Card";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";
import AccountModalContainer from "shared/accountModal/Modal";
// import { getInvoiceForApprove } from "appstate/invoice/invoiceSlice";
import {
  allEarlyPaymentRequestBulkPending,
  allEarlyPaymentRequestSinglePending,
  getUpdateDiscountRatePreview,
  importEarlyPaymentToCloseMarket,
} from "appstate/buyer/buyerSlice";
import { useNavigate } from "react-router-dom";
import { PAIconArrowLeftGreen } from "assets/images/svgs";

import toast from "react-hot-toast";
import DashboardLayout from "layout/dashboardlayout";

import ImportInvoicesModal from "../components/carddetails/importInvoicesModal";
import AddFundModal from "../components/carddetails/AddFundModal";
import SelectAccountModal from "../components/carddetails/modalcomponent/SelectAccountModal";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import EnterPin from "features/openMarket/funder/components/modal/Pin";
import TooltipShared from "shared/tooltip/Tooltip";
import { formatCurrency } from "utilities/helper/formatters";
import { getAllFunderListClosedMarket } from "appstate/openMarket/openMarketSlice";
import SuccessModal from "shared/SuccessModal";
import ConfirmDiscountRateModal from "../components/carddetails/ConfirmDiscountRateModal";

function DirectEarlyPaymentRequests() {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  // const [rowActive, setRowActive] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllBulk, setSelectAllBulk] = useState(false);
  // const [rowTotal, setRowTotal] = useState([]);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [selectedFunderIds, setSelectedFunderIds] = useState(false);
  const [addFundModal, setaddFundModal] = useState(false);
  const [activeTab, setActiveTab] = useState("single");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsContent, setSelectedRowsContent] = useState([]);

  const [selectedRowsBulk, setSelectedRowsBulk] = useState([]);
  const [selectedRowsContentBulk, setSelectedRowsContentBulk] = useState([]);
  const [, setSelectedContent] = useState([]);
  const [selectedAccountDetails, setSelectedAccountDetails] = useState([]);
  const [confirmSelectedAccountModal, setConfirmSelectedAccountModal] =
    useState(false);
  const [isSelectAccountModalOpen, setIsSelectAccountModalOpen] =
    useState(false);

  const [pinModal, setPinModal] = useState(false);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);

  const status = "PENDING_APPROVAL";

  const {
    allEarlyPaymentRequestSingleData,
    allEarlyPaymentRequestBulkData,
    isLoading,
  } = useSelector((state) => state?.buyer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allEarlyPaymentRequestBulkPending({ status }));
    dispatch(allEarlyPaymentRequestSinglePending({ status }));
    dispatch(getAllFunderListClosedMarket());
  }, [dispatch, status]);

  // Handlers
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allIds =
        activeTab === "single"
          ? allEarlyPaymentRequestSingleData?.data?.buyerSingleEarlyPaymentResponses?.map(
              (row) => row?.invoiceId
            ) || []
          : allEarlyPaymentRequestBulkData?.data?.buyerBulkEarlyPaymentResponses?.map(
              (row) => row?.earlyPaymentId
            ) || [];

      const allRows =
        activeTab === "single"
          ? allEarlyPaymentRequestSingleData?.data?.buyerSingleEarlyPaymentResponses?.map(
              (row) => row
            ) || []
          : allEarlyPaymentRequestBulkData?.data?.buyerBulkEarlyPaymentResponses?.map(
              (row) => row
            ) || [];
      setSelectedRows(allIds);
      setSelectedRowsContent(allRows);
    } else {
      setSelectedRows([]);
      setSelectedRowsContent([]);
    }
  };

  const handleSelectAllBulk = () => {
    setSelectAllBulk(!selectAllBulk);
    if (!selectAllBulk) {
      const allIds =
        allEarlyPaymentRequestBulkData?.data?.buyerBulkEarlyPaymentResponse?.map(
          (row) => row?.earlyPaymentId
        ) || [];

      const allRows =
        allEarlyPaymentRequestBulkData?.data?.buyerBulkEarlyPaymentResponse?.map(
          (row) => row
        ) || [];
      setSelectedRowsBulk(allIds);
      setSelectedRowsContentBulk(allRows);
    } else {
      setSelectedRowsBulk([]);
      setSelectedRowsContentBulk([]);
    }
  };

  const handleCheckboxChange = (row) => {
    if (selectAll) setSelectAll(false);
    const isSelected = selectedRows?.includes(row?.invoiceId);
    const updatedRows = isSelected
      ? selectedRows?.filter((id) => id !== row?.invoiceId)
      : [...selectedRows, row?.invoiceId];

    const updatedRowContents = isSelected
      ? selectedRows?.filter((id) => id !== row?.invoiceId)
      : [...selectedRowsContent, row];
    setSelectedRows(updatedRows);
    setSelectedRowsContent(updatedRowContents);
  };

  const handleCheckboxChangeBulk = (row) => {
    if (selectAllBulk) setSelectAllBulk(false);
    const isSelected = selectedRowsBulk?.includes(row?.earlyPaymentId);
    const updatedRows = isSelected
      ? selectedRowsBulk?.filter((id) => id !== row?.earlyPaymentId)
      : [...selectedRowsBulk, row?.earlyPaymentId];

    const updatedRowContents = isSelected
      ? selectedRowsBulk?.filter((id) => id !== row?.earlyPaymentId)
      : [...selectedRowsContentBulk, row];
    setSelectedRowsBulk(updatedRows);
    setSelectedRowsContentBulk(updatedRowContents);
  };

  const columnSingle = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows?.includes(row?.invoiceId)}
        />
      ),
      width: "45px",
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request Ref No
        </span>
      ),
      selector: (row) => (
        <span>
          <TooltipShared
            text={row?.earlyPaymentRef}
            maxLength={15}
            textSize="text-xs"
          />
        </span>
      ),
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Invoice Number.
        </span>
      ),
      selector: (row) => row?.invoiceNo,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Supplier
        </span>
      ),
      selector: (row) => row?.supplierCompanyName,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Payment Date",
      selector: (row) => row?.earlyPaymentDate,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Tier",
      selector: (row) => row?.tier,
      sortable: true,
      // width: "80px",
    },
    {
      name: "Request Amount",
      selector: (row) => row?.requestAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{formatCurrency(row.requestAmount)}</p>
      ),
    },
    {
      name: "Discount Rate",
      selector: (row) => row?.discountRate,
      sortable: true,
      cell: (row) => <p className="text-yellow-500">{row.discountRate}%</p>,
    },
    {
      name: "Payable Amount",
      selector: (row) => row?.payableAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{formatCurrency(row.payableAmount)}</p>
      ),
    },
    // {
    //   name: "Discount Amount",
    //   selector: (row) => row.discountAmount,
    //   sortable: true,
    //   cell: (row) => <p className="text-yellow-400">{row.discountAmount}</p>,
    // },
  ];

  const columnBulk = [
    {
      name: (
        <input
          type="checkbox"
          onChange={handleSelectAllBulk}
          checked={selectAllBulk}
        />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChangeBulk(row)}
          checked={selectedRowsBulk?.includes(row?.earlyPaymentId)}
        />
      ),
      width: "45px",
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request Ref No
        </span>
      ),
      selector: (row) => row?.requestReferenceNumber,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Supplier
        </span>
      ),
      selector: (row) => row?.supplierCompanyName,
      sortable: true,
    },
    {
      name: "Payment Date",
      selector: (row) => row?.requestDate,
      sortable: true,
      // width: "120px",
    },
    {
      name: "No. of Invoices",
      selector: (row) => row?.noOfInvoices,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Tier",
      selector: (row) => row?.tier,
      sortable: true,
    },
    {
      name: "Total Request Amount",
      selector: (row) => row?.totalRequestAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">
          {row?.totalRequestAmount?.toLocaleString()}
        </p>
      ),
    },
    {
      name: "Total Discount Amount",
      selector: (row) => row?.totalDiscountAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-yellow-600">
          {row?.totalDiscountAmount?.toLocaleString()}
        </p>
      ),
    },
    {
      name: "Total Payable Amount",
      selector: (row) => row?.totalPayableAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">
          {row?.totalPayableAmount?.toLocaleString()}
        </p>
      ),
    },
  ];
  const singleTotalPages =
    allEarlyPaymentRequestSingleData?.meta?.totalElements;

  const bulkTotalPages = allEarlyPaymentRequestBulkData?.meta?.totalElements;

  const handleCloseModal = () => {
    setRejectModalOpen(false);
  };

  const handleImport = (e) => {
    e.preventDefault();
    // dispatch(getAllFunderListClosedMarket());
    setRejectModalOpen(true);
  };

  const handleAccountSubmit = () => {
    setIsSelectAccountModalOpen(false);
    setTimeout(() => {
      setConfirmSelectedAccountModal(true);
    }, 500);

    // setIsProceedModalOpen(true);
  };

  const handleProceedEnterPin = () => {
    setConfirmSelectedAccountModal(false);
    setTimeout(() => {
      setPinModal(true);
    }, 500);
  };

  const handleImportInvoice = () => {
    setRejectModalOpen(false);
    setaddFundModal(true);
  };
  const handleImportInvoiceSingle = () => {
    setRejectModalOpen(false);
    setIsDiscountModalOpen(true);
  };
  function handleProceedToImport() {
    setaddFundModal(false);
    setIsSelectAccountModalOpen(true);
  }

  const handleClosedMarketImport = (pin) => {
    const pendingToast = toast.loading("Importing Invoice to Closed Market...");

    let earlyPayment =
      activeTab === "single"
        ? selectedRowsContent?.map(
            ({ earlyPaymentId, payableAmount, discountAmount }) => ({
              earlyPaymentId,
              payableAmount,
              discountAmount,
            })
          )
        : selectedRowsContentBulk?.map(
            ({ earlyPaymentId, totalPayableAmount, totalDiscountAmount }) => ({
              earlyPaymentId,
              payableAmount: totalPayableAmount,
              discountAmount: totalDiscountAmount,
            })
          );

    let adjustedDiscountRequest =
      activeTab === "single"
        ? selectedRowsContent?.map(
            ({ earlyPaymentId, invoiceId, discountRate }) => ({
              earlyPaymentRequestId: earlyPaymentId,
              invoiceId,
              newDiscountRate: discountRate,
            })
          )
        : selectedRowsContentBulk?.map(
            ({ earlyPaymentId, invoiceId, discountRate }) => ({
              earlyPaymentRequestId: earlyPaymentId,
              invoiceId,
              newDiscountRate: discountRate,
            })
          );

    const body = {
      funderId: [...selectedFunderIds],
      earlyPayments: [...earlyPayment],
      virtualAccountId: selectedAccountDetails?.virtualAccountId,
      transactionPin: pin,
    };

    activeTab === "single"
      ? dispatch(
          getUpdateDiscountRatePreview({
            adjustDiscountRequests: [...adjustedDiscountRequest],
          })
        ).then((data) => {
          if (
            data?.payload?.success &&
            data?.payload?.data?.errorDiscounts?.length === 0
          ) {
            dispatch(importEarlyPaymentToCloseMarket(body)).then((data) => {
              if (data?.payload?.success) {
                setSelectedRows([]);
                setSelectedRowsContent([]);
                toast.dismiss(pendingToast);
                setPinModal(false);
                setSuccessMessage(
                  "Early Payment request sent for workflow approval"
                );
                setIsSuccessModalOpen(true);
                dispatch(allEarlyPaymentRequestBulkPending({ status }));
                dispatch(allEarlyPaymentRequestSinglePending({ status }));
              } else {
                toast.dismiss(pendingToast);
                if (data?.payload?.status === 200)
                  toast.error(data?.payload?.message);

                if (data?.payload?.status !== 200)
                  toast.error(data?.payload?.message);
              }
            });
          } else {
            toast.dismiss(pendingToast);
            toast.error(data?.payload?.data?.errorDiscounts[0]?.message);
          }
        })
      : dispatch(importEarlyPaymentToCloseMarket(body)).then((data) => {
          if (data?.payload?.success) {
            setSelectedRowsBulk([]);
            setSelectedRowsContentBulk([]);
            toast.dismiss(pendingToast);
            setPinModal(false);
            setSuccessMessage(
              "Early Payment request sent for workflow approval"
            );
            setIsSuccessModalOpen(true);
            dispatch(allEarlyPaymentRequestBulkPending({ status }));
            dispatch(allEarlyPaymentRequestSinglePending({ status }));
          } else {
            toast.dismiss(pendingToast);
            toast.error(data?.payload?.message);
          }
        });
  };

  const navigate = useNavigate();
  const goBack = () => {
    navigate("/closedmarket");
  };
  const singleDetail = (id, pfpId) => {
    navigate(
      "/closedmarket/directEarlyPaymentRequestsDetail/" +
        id +
        "?earlyId=" +
        pfpId
    );
  };

  const bulkDetail = (id, pfpId) => {
    navigate(
      "/closedmarket/directEarlyPaymentRequestsDetail/bulk/" +
        id +
        "?earlyId=" +
        pfpId
    );
  };

  return (
    <>
      <DashboardLayout
        section={"Closed Market"}
        subHeader={"Manage your invoices on the closed market"}
      >
        <span
          onClick={goBack}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full bg-white px-3 py-5 rounded-[8px]"
        >
          <PAIconArrowLeftGreen />
          <span className="flex text-sm items-center text-primaryColor">
            Go Back
          </span>
        </span>
        <ImportInvoicesModal
          isOpen={isRejectModalOpen}
          onClose={handleCloseModal}
          handleImportInvoice={() => {
            activeTab === "single"
              ? handleImportInvoiceSingle()
              : handleImportInvoice();
          }}
        />
        <AnimatePresence>
          {isSelectAccountModalOpen && (
            <SelectAccountModal
              overlayDark
              onClose={() => setIsSelectAccountModalOpen(false)}
              onAction={handleAccountSubmit}
              setSelectedAccountDetails={setSelectedAccountDetails}
            />
          )}
        </AnimatePresence>

        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={() => setIsSuccessModalOpen(false)}
          message={successMessage}
        />

        {/* Approve Selected Account */}
        <AnimatePresence>
          {confirmSelectedAccountModal && (
            <ViewDocumentModal
              onAction={() => handleProceedEnterPin()}
              onClose={() => setConfirmSelectedAccountModal(false)}
              proceed="Yes Proceed"
            >
              <span className="text-center">
                Are you sure you want to proceed with this account <br />
                <span className="font-bold italic text-center text-primaryColor">
                  {selectedAccountDetails?.accountNumber}
                </span>{" "}
                <br />
                for the reimbursement of this request
              </span>
            </ViewDocumentModal>
          )}
        </AnimatePresence>

        {isDiscountModalOpen && (
          <ConfirmDiscountRateModal
            data={selectedRowsContent}
            list
            isOpen={isDiscountModalOpen}
            setDiscountData={setSelectedRowsContent}
            preData={selectedRowsContent}
            onClose={() => setIsDiscountModalOpen(false)}
            onNext={() => {
              setIsDiscountModalOpen(false);
              handleImportInvoice();
            }}
          />
        )}

        {addFundModal && (
          <AddFundModal
            onClose={() => setaddFundModal(false)}
            handleProceed={handleProceedToImport}
            selectedFunderIds={selectedFunderIds}
            setSelectedFunderIds={setSelectedFunderIds}
          />
        )}

        <AnimatePresence>
          {pinModal && (
            <EnterPin
              onClose={() => setPinModal(false)}
              onSubmit={(pin) => {
                handleClosedMarketImport(pin);
              }}
            />
          )}
        </AnimatePresence>

        <AccountModalContainer
          accontModal
          isShown={open}
          onClose={() => {
            setOpen(false);
            setSuccess(false);
          }}
          success={success}
          warning="Are you sure you would like to approve this/these invoice upload?"
        />
        <Card className="rounded-lg">
          <Card className="  rounded-tl-[10px]  rounded-tr-[10px] p-[0.5px] ">
            <div className=" px-[24px]  rounded-tl-[10px]  rounded-tr-[10px]  ">
              <div className="mt-2 mb-3 grid md:grid-cols-2 items-center pt-3">
                <div className=" flex flex-col relative">
                  <div className="  flex ">
                    <div className="text-zinc-900 text-sm font-bold ">
                      Early Payment Requests
                    </div>
                  </div>

                  <div class="text-zinc-600 text-[10px] font-normal">
                    All available invoices from direct early payment requests
                  </div>
                </div>

                <div className="flex md:col-span-1 justify-end">
                  {activeTab === "single" ? (
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      class={` h-[37px] px-[15px] py-2.5 ${
                        selectedRows?.length > 0
                          ? "bg-[#07593D]"
                          : "bg-[#D5D8DB]"
                      } rounded-[5px] justify-start items-center gap-3.5 inline-flex`}
                      disabled={selectedRows?.length < 1}
                      onClick={handleImport}
                    >
                      <div class="text-white text-sm font-normal">
                        Import Selected Requests
                      </div>
                    </motion.button>
                  ) : (
                    <motion.button
                      whileTap={{ scale: 0.95 }}
                      class={` h-[37px] px-[15px] py-2.5 ${
                        selectedRowsBulk?.length > 0
                          ? "bg-[#07593D]"
                          : "bg-[#D5D8DB]"
                      } rounded-[5px] justify-start items-center gap-3.5 inline-flex`}
                      disabled={selectedRowsBulk?.length < 1}
                      onClick={handleImport}
                    >
                      <div class="text-white text-sm font-normal">
                        Import Selected Requests
                      </div>
                    </motion.button>
                  )}
                </div>
              </div>
              <div className="flex gap-3 mb-1">
                <p
                  className={`cursor-pointer pb-3 text-sm font-bold relative ${
                    activeTab === "single" ? "text-[#F08000]" : "text-[#959595]"
                  }`}
                  onClick={() => handleTabClick("single")}
                >
                  Single requests
                  {activeTab === "single" && (
                    <span
                      className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                 bg-[#F08000] rounded-lg  h-1.5 w-5"
                    ></span>
                  )}
                </p>

                <p
                  className={`cursor-pointer text-sm font-bold relative ${
                    activeTab === "bulk" ? "text-[#F08000]" : "text-[#959595]"
                  }`}
                  onClick={() => handleTabClick("bulk")}
                >
                  Bulk requests
                  {activeTab === "bulk" && (
                    <span
                      className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                 bg-[#F08000] rounded-lg h-1.5 w-5"
                    ></span>
                  )}
                </p>
              </div>
            </div>
          </Card>
          <div className="rounded-b-lg px-[24px] pb-[24px]">
            {activeTab === "single" ? (
              <>
                <Table
                  columns={columnSingle}
                  data={
                    allEarlyPaymentRequestSingleData?.data
                      ?.buyerSingleEarlyPaymentResponses
                  }
                  progressPending={isLoading}
                  progressComponent={<Loading />}
                  tableHeader
                  pointer
                  onRowClicked={(row) => {
                    setSelectedContent(row);
                    singleDetail(row?.invoiceId, row?.earlyPaymentId);
                  }}
                  className="mt-5"
                />
                <div className="flex justify-center gap-x-[8px] mt-[20px]">
                  {!isLoading && singleTotalPages > 10 && (
                    <Pagination
                      page={page}
                      startPage={startPage}
                      setEndPage={setEndPage}
                      endPage={endPage}
                      setStartPage={setStartPage}
                      setPage={setPage}
                      totalPages={
                        allEarlyPaymentRequestSingleData?.meta?.totalElements
                      }
                    />
                  )}
                </div>{" "}
              </>
            ) : (
              <>
                <Table
                  columns={columnBulk}
                  data={
                    allEarlyPaymentRequestBulkData?.data
                      ?.buyerBulkEarlyPaymentResponse
                  }
                  progressPending={isLoading}
                  progressComponent={<Loading />}
                  tableHeader
                  pointer
                  onRowClicked={(row) =>
                    bulkDetail(row?.earlyPaymentId, row?.earlyPaymentId)
                  }
                  className="mt-5"
                />
                <div className="flex justify-center gap-x-[8px] mt-[20px]">
                  {!isLoading && bulkTotalPages > 10 && (
                    <Pagination
                      page={page}
                      startPage={startPage}
                      setEndPage={setEndPage}
                      endPage={endPage}
                      setStartPage={setStartPage}
                      setPage={setPage}
                      totalPages={
                        allEarlyPaymentRequestBulkData?.meta?.totalElements
                      }
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </Card>
      </DashboardLayout>
    </>
  );
}

export default DirectEarlyPaymentRequests;
