import { useState, useEffect } from 'react';

function  useCountdown(dueDate) {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [isTimeUnderOneHour, setIsTimeUnderOneHour] = useState(false);

  useEffect(() => {
    if (!dueDate) return;

    const targetDate = new Date(dueDate); // Convert the due date to a Date object
    const timer = setInterval(() => {
      const now = new Date();
      const diff = targetDate - now;

      if (diff <= 0) {
        clearInterval(timer);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        setIsTimeUnderOneHour(false);
        return;
      }

      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });

      // Check if the time left is under 1 hour (less than 60 minutes)
      setIsTimeUnderOneHour(hours === 0 && (minutes < 60 || (minutes === 0 && seconds > 0)));
    }, 1000);

    return () => clearInterval(timer); // Clean up the interval on component unmount
  }, [dueDate]);

  return { timeLeft, isTimeUnderOneHour };
}

export default useCountdown;
