import { PAIconCancel } from "assets/images/svgs";
import { allVirtualAccounts } from "appstate/wallet/walletSlice";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import walletwhite from "../../../../assets/images/svgs/empty-wallet.svg";

function DirectFundingBidSelectAccountModal({
  onClose,
  isOpen,
  handleProceed,
  setCheckedAccount,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allVirtualAccounts());
  }, [dispatch]);

  const { allVirtualAccountsData } = useSelector((state) => state.wallet);

  if (!isOpen) {
    return null;
  }
  return (
    <div
      className="bg-[rgba(0,0,0,0.2)] w-[100%] h-[100vh] flex justify-center items-center absolute left-0 top-0 z-[1000]"
    >
      <div>
        <div className="flex flex-col justify-between items-center bg-white rounded-[8px]  w-[650px]">
          <div className="h-[70px] px-[5%] rounded-t-[8px] bg-[#FAFAFA] flex items-center w-[100%] justify-between">
            <h1 className="font-medium text-[20px]">Select Account</h1>
            <PAIconCancel onClick={onClose} />
          </div>

          <div className="flex items-center w-[100%] px-[5%] mt-[2%]">
            <p className="text-left text-[16px] font-regular">
              Select which account you would like to use for this request
            </p>
          </div>

          <div className="mt-[3%] w-[100%] flex flex-wrap justify-evenly px-[3%] mb-[2%] overflow-y-auto h-[250px]">
            {allVirtualAccountsData?.data?.map((account, index) => (
              <div key={index} className="flex items-start w-[48%] mb-[2%]">
                <div
                  className={
                    "bg-[#07593D] border-[1px] p-[4%] w-[100%] h-[100px] rounded-[5px] ml-[2%]"
                  }
                  onClick={() => {
                    setCheckedAccount(account);
                    handleProceed();
                  }}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center justify-start w-[70%]">
                      <img src={walletwhite} alt="" />

                      <p className="ml-[2%] font-semibold text-[14px] text-[white]">
                        {account.accountName}
                      </p>
                    </div>
                    <p className="font-regular text-[10px] italic text-[white]">
                      Virtual Account
                    </p>
                  </div>

                  <div className="mt-[2%]">
                    <p className="font-bold text-[16px] italic text-[white]">
                      {account.balance.toLocaleString()}
                    </p>
                  </div>

                  <div className="mt-[2%]">
                    <p className="text-[14px] font-light text-[white]">
                      {account.bankName}
                    </p>
                    <p className="text-[13px] text-[white]">
                      PayEdge MFB | {account.accountNumber}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* <div className="p-[5%] w-[100%]">
            <div className="mt-[3%] flex items-center justify-between w-[100%]">
              <button
                onClick={confirm}
                className="bg-[#F08000] text-[#fff] w-[120px] rounded-[4px] h-[45px]"
              >
                Confirm
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default DirectFundingBidSelectAccountModal;
