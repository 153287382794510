// import {
//   PAIconCocacola,
//   PAIconDownload,
//   PAIconModelOne,
// } from "assets/images/svgs";

import { PAIconIconParkSucess, PAIconMDISuccess } from "assets/images/svgs";
import TransactionReceipt from "features/wallet(new)/transactions/TransactionReceipt";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import AcceptModal from "shared/AcceptModal";
import ProgressModal from "shared/progressModal/ProgressModal";
import TooltipShared from "shared/tooltip/Tooltip";
import { formatCapitalize } from "utilities/formatCapitalize";

function WonBidsInvoiceDetails({ invoiceByIdData }) {
  const [confirmPayment, setConfirmPayment] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);
  const navigate = useNavigate();

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  let openMarketStatusText = isLoading ? (
    <span className="italic font-semibold">Transferring of funds</span>
  ) : (
    <span>Funds Transfered</span>
  );

  let receiptTimeout = null;

  function handleDownloadSuccessful() {
    // setFundAccountPinModal(false);

    toast.custom(
      <>
        <div className="flex-1 items-center justify-center flex w-full h-full">
          <div className="flex mt-[300px]">
            <div className="bg-primaryColor w-[147.79px] h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
              <PAIconMDISuccess />
            </div>
            <div className="bg-white  flex-col justify-center items-center self-stretch py-[25px] h-[160px] rounded-r-[7px] shadow-lg flex px-16 ">
              <PAIconIconParkSucess />
              <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
                Funds disbursed successfully
              </p>
            </div>
          </div>
        </div>
      </>
    );

    receiptTimeout = setTimeout(() => {
      toast.dismiss();
      setShowReceipt(true);
    }, 500);
  }

  useEffect(() => {
    return () => {
      clearTimeout(receiptTimeout);
    };
  }, [receiptTimeout]);

  return (
    <motion.section
      initial={{
        x: 20,
      }}
      animate={{
        x: 0,
      }}
      exit={{
        x: -20,
      }}
      transition={{
        delay: 0.6,
      }}
      className="flex flex-col gap8 px-6 pt-7 pb-24 w-full semi-lg:w-[60%] bg-white rounded-[10px]"
    >
      <div className="mb-2 flex flex-col gap-3 ">
        <header className="flex items-center justify-between">
          <span className="flex gap-1 text-[18px]">
            Reference No |{"  "}
            <TooltipShared
              // fontWeight="font-light"
              textSize="text-[18px]"
              text={
                invoiceByIdData?.data?.openMarketReference ||
                invoiceByIdData?.data?.invoiceReference
              }
            />
          </span>
          <button
            className="bg-primaryColor p-2.5 text-sm font-medium text-white rounded"
            onClick={() => setConfirmPayment(true)}
          >
            Pay Now
          </button>
        </header>

        <main className="w-full flex items-center justify-between">
          <div className="flex flex-col gap-3 w-2/5">
            {/* <div className="flex justify-between">
              <span className=" font-medium">Invoice | INV-2002</span>
              <span>{invoiceByIdData?.data?.invoiceReference}</span>
            </div> */}

            <div className="flex flex-col gap-1">
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Invoice Number:</span>
                <span className="text-[#979797]  text-xs">
                  {`INV-${invoiceByIdData?.data?.invoiceNumber}` || "INV-2024"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Created Date:</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.createdDate?.slice(0, 10) || "N/A"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Submitted Date:</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.submittedDate?.slice(0, 10) || "N/A"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Approved Date:</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.approvedDate?.slice(0, 10) || "N/A"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Due Date:</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.dueDate?.slice(0, 10) || "N/A"}
                </span>
              </div>

              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Issuance Date</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.issuanceDate?.slice(0, 10) || "N/A"}
                </span>
              </div>
            </div>
          </div>

          {/* STATUS */}
          <div className="rounded-md py-1.5 bg-[#23AD5229] px-6">
            <p className="text-center text-primaryColor text-xs font-bold">
              {invoiceByIdData?.data?.status || "Won Bids"}
            </p>
          </div>
        </main>
      </div>

      {/* Buyer details and co. */}
      <div className="flex justify-between my-5 border-b-[5px] border-b-[#F6F5FA] py-3">
        <div className="flex flex-col">
          <span className="font-bold text-sm">
            {invoiceByIdData?.data?.buyerCompanyName}
          </span>
          <span className="text-[#979797] text-xs">Buyer details</span>
        </div>
        <div className="flex flex-col">
          <span className="font-bold text-sm">
            {invoiceByIdData?.data?.paymentTerms}
          </span>
          <span className="text-[#979797] text-xs">Payment</span>
        </div>
        <div className="flex flex-col">
          <span className="font-bold text-sm ">
            {invoiceByIdData?.data?.currency}
          </span>
          <span className="text-[#979797] text-xs">Currency</span>
        </div>
      </div>

      <div className="flex mb-8 flex-col gap-4">
        <header className="border-b-[5px] border-b-[#F6F5FA] pb-3 flex items-center justify-between">
          <h1 className="text-[18px] font-normal">Line Items</h1>
          <p>{invoiceByIdData?.data?.items?.length} Total Items</p>
        </header>

        <div className=" w-full overflow-x-auto">
          <table className=" w-full overflow-x-auto">
            <thead className="bg-subtleGray w-">
              <tr className="bg-unusualGray p-2.5 ">
                <td className="text-[#11192A] p-2.5 text-xs font-bold">Item</td>
                <td className="p-2.5 text-xs font-bold">Description</td>
                <td className="p-2.5 text-xs font-bold">Unit Price</td>
                <td className="p-2.5 text-xs font-bold">Quantity</td>
                <td className="p-2.5 text-xs font-bold">
                  Amount
                  <br />
                  (before tax)
                </td>
                <td className="p-2.5 text-xs font-bold">Tax Rate</td>
                <td className="p-2.5 text-xs font-bold">Tax Amount</td>
                <td className="p-2.5 text-xs font-bold">Total Price</td>
              </tr>
            </thead>
            <tbody>
              {invoiceByIdData?.data?.items?.map((item, index) => (
                <tr key={index} className=" py-[3px] px-1.5">
                  <td className=" text-xs text-[rgb(17,25,42)] p-2.5">
                    {item?.itemName}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.description}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.unitPrice}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.quantity}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {formatAmount(item?.amount)}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.taxRate}%
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {formatAmount((item?.taxRate / 100) * item?.amount)}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {formatAmount(
                      item?.amount + (item?.taxRate / 100) * item?.amount
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Accounts Information */}
        <div className="flex itemscenter justify-between px6">
          <div>
            <h1 className="border-b border-[#B1A6B2] font-semibold">
              Receiving Funding Account Information
            </h1>
            <p className="mt-3 text[#979797]">
              {invoiceByIdData?.data?.fundingAccount?.accountNumber ||
                invoiceByIdData?.data?.fundingAccountNumber}
            </p>
            <p className="text[#979797]">
              {formatCapitalize(
                invoiceByIdData?.data?.fundingAccount?.accountName
              ) || formatCapitalize(invoiceByIdData?.data?.fundingAccountName)}
            </p>
          </div>
          <div className="flex flex-col">
            <div className="flex justify-between gap-x-[100px] w-full">
              <span className="text-xs w-[97px]">Sub Total</span>
              <span className="text-[#979797] text-xs text-right">
                {formatAmount(invoiceByIdData?.data?.subTotal)}
              </span>
            </div>

            <div className="flex justify-between w-full gap-x-[100px] my-2">
              <span className="flex items-center text-xs w-[97px]">
                Discount%
                <span className="border border-[#979797] pr-10 pl-2 inline-block ml-4">
                  {formatAmount(invoiceByIdData?.data?.discount) ?? "0.00"}%
                </span>
              </span>
              <span className="text-[#979797] text-xs">
                {formatAmount(
                  (invoiceByIdData?.data?.discount / 100) *
                    invoiceByIdData?.data?.subTotal
                )}
              </span>
            </div>

            <div className="flex justify-between w-full gap-x-[100px] pb-5">
              <span className="flex items-center text-xs w-[97px]">
                VAT%
                <span className="border border-[#979797] ml-10 pr-11 pl-2 inline-block">
                  {formatAmount(invoiceByIdData?.data?.vat)}%
                </span>
              </span>
              <span className="text-[#979797] text-xs">
                {formatAmount(
                  (invoiceByIdData?.data?.vat / 100) *
                    invoiceByIdData?.data?.subTotal
                )}
              </span>
            </div>

            <div className="flex justify-between items-center w-full gap-x-[100px] border-t-2 border-b-2 font-bold text-lg py-1">
              <span className="w-[97px]">Grand Total</span>
              <span className="text[#979797] py-1">
                {formatAmount(
                  invoiceByIdData?.data?.subTotal -
                    (invoiceByIdData?.data?.discount / 100) *
                      invoiceByIdData?.data?.subTotal +
                    (invoiceByIdData?.data?.vat / 100) *
                      invoiceByIdData?.data?.subTotal
                )}
              </span>
            </div>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {confirmPayment && (
          <AcceptModal
            isOpen={confirmPayment}
            onClose={() => setConfirmPayment(false)}
            message={
              <>
                Are you sure you would like to{" "}
                <span className=" font-bold">pay now</span> for this <br />
                <span className="text-[#07593D] font-bold">Invoice?</span>
              </>
            }
            onConfirm={() => {
              setIsLoading(true);
              setConfirmPayment(false);
              setPaymentModal(true);
              setTimeout(() => {
                setIsLoading(false);
                setTimeout(() => {
                  setPaymentModal(false);
                  handleDownloadSuccessful();
                }, 300);
              }, 4500);
            }}
            yes
          />
        )}

        {paymentModal && (
          <ProgressModal openMarket openMarketText={openMarketStatusText} />
        )}

        {showReceipt && (
          <TransactionReceipt
            transferDetails={{
              id: 1,
              amount: 100.0,
              transactionDate: "2024-11-19T14:30:00.000Z",
              transactionType: "Credit",
              transactionReference: "TRX-001",
              description: "Test transaction",
              sourceAccountName: "John Doe",
              sourceAccountNumber: "12345678910",
              destinationAccountName: "Jane Doe",
              destinationAccountNumber: "12345678910",
              currency: "USD",
            }}
            onClose={() => {
              navigate("/open-market");
            }}
            paymentAmount="$100.00"
            currency="USD"
            senderDetails={{
              name: "John Doe",
              bank: "Payedge Virtual Account",
              accountNumber: "0976543211",
            }}
            beneficiaryDetails={{
              name: "Jane Doe",
              bank: "Payedge Virtual Account",
              accountNumber: "0976543211",
            }}
            transactionType="Disbursement"
            transactionReference="TRX-001"
            description="Test transaction"
            paymentDate="Tuesday, November 19, 2024"
          />
        )}
      </AnimatePresence>
    </motion.section>
  );
}

export default WonBidsInvoiceDetails;
