import { PAIconCancel, PAIconIconAcceptedBid } from "assets/images/svgs";
import React from "react";
import { useSelector } from "react-redux";

function BidInvoiceLockedModal({ onClose, isOpen, toOngoingTab }) {
  const selectedAccounts = useSelector(
    (state) => state?.wallet?.selectedAccounts
  );

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
        <div className="bg-white p-5 rounded-[10px] shadow-md flex flex-col items-center  md:w-[459px] ] h-[364px] pt-8">
          <header className="flex justify-between border-b-4 border-whisper200 pb-[16px] w-full">
            <p class="text-[#222222] text-base font-medium ">Bid Processing</p>
            <div
              onClick={onClose}
              className="flex justify-between items-center gap-x-[10px] cursor-pointer"
            >
              <PAIconCancel className="w-[8px]" />

              <p className="text-[#222222] text-sm font-medium ">Close</p>
            </div>
          </header>
          <div className=" flex flex-col flex-1 w-full pb-6">
            <span className=" mt-2  font-normal  text-[#949494] text-center text-sm">
              Account{" "}
              <span className="text-[#07593d] font-semibold  ">
                {selectedAccounts?.accountNumber}
              </span>{" "}
              would be liened to fund invoice <br />
              <span className=" text-center  justify-center">
                {" "}
                should you win this bid offer
              </span>
            </span>

            <div className="  flex justify-center items-center p-5">
              <PAIconIconAcceptedBid />
            </div>

            <span className="text-[#f08000] text-sm font-normal text-neutral-800 text-center  tracking-wide ">
              You can cancel your bid within 30 seconds of placing it.
            </span>

            <div class=" text-neutral-800 text-center font-normal  tracking-wide text-[#949494]   text-sm  ">
              You can monitor the bid process in the{" "}
              <button
                className="text-[#07593d]  underline"
                onClick={toOngoingTab}
              >
                Ongoing bid tab
              </button>
              . <br /> Thanks
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BidInvoiceLockedModal;
