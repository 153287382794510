import {
  PAIconCocacola,
  PAIconInvShop,
  PAIconSmallDangote,
  PAIconSmallNestle,
  PAIconSmallShell,
  PAIconSmallTrust,
  PAIconSmallBrewIcon,
} from "assets/images/svgs";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

function OverViewCard({
  id,
  invoiceNumber = "N/A",
  buyerName = "N/A",
  invoiceType = "N/A",
  lockRate = "N/A",
  statusOfInvoice = "N/A",
  requestAmount = "N/A",
  dueDate = "N/A",
}) {
  const navigate = useNavigate();

  return (
    <motion.div
      onClick={() =>
        invoiceType === "Single Invoice"
          ? navigate(
              `/payablefinancing-closedmarket/funderclosedmarketoverviewdetails/${id}`
            )
          : navigate(
              `/payablefinancing-closedmarket/funderclosedmarketbulkoverviewdetails/${id}`
            )
      }
      whileTap={{ scale: 0.98 }}
      style={{ boxShadow: "0px 2px 4px 0px rgba(232, 226, 216, 0.36)" }}
      className="flex cursor-pointer justify-between bg-white py-5 px-6 rounded-[10px] w-full sm:w-full md:w-full gap-6"
    >
      <span className="flex gap-6 items-center flex-nowrap whitespace-nowrap">
        <PAIconInvShop />
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceNumber}</span>
          <span className="text-[#959595] text-[10px]">Reference number</span>
        </div>
      </span>

      <span>
        <button
          className={`h-[37px] p-2.5 rounded-[5px] inline-flex ${
            invoiceType === "Single Invoice"
              ? "bg-[#f08000]/10"
              : "bg-[#07593d]/10"
          }`}
        >
          <div className="text-black text-sm font-medium">{invoiceType}</div>
        </button>
      </span>

      <span className="flex gap-6 items-center flex-nowrap whitespace-nowrap">
        {buyerName === "Coca Cola" ? (
          <PAIconCocacola />
        ) : buyerName === "Dangote" ? (
          <PAIconSmallDangote />
        ) : buyerName === "Nestle Plc." ? (
          <PAIconSmallNestle />
        ) : buyerName === "Defi Ltd." ? (
          <PAIconSmallShell />
        ) : buyerName === "Trust" ? (
          <PAIconSmallTrust />
        ) : (
          <PAIconSmallBrewIcon />
        )}
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{buyerName}</span>
          <span className="text-[#959595] text-[10px]">Buyer</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-primaryColor text-sm">
            {lockRate}
          </span>
          <span className="text-[#07593D] text-[10px]">Lock rate</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-[#2fa069] text-sm">
            {statusOfInvoice === "LOCKED" ? "Locked" : statusOfInvoice}
          </span>
          <span className="text-[#959595] text-[10px]">Status of invoice</span>
        </div>
      </span>

      <span className="flex gap-6 flex-nowrap whitespace-nowrap">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{requestAmount}</span>
          <span className="text-[#959595] text-[10px]">Request Amount</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{dueDate}</span>
          <span className="text-[#959595] text-[10px]">Due Date</span>
        </div>
      </span>
    </motion.div>
  );
}

export default OverViewCard;
