import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableHeader from "shared/tableHeader/TableHeader";
import PayableFinanceModal from "./components/Modal";
import { useSelector, useDispatch } from "react-redux";
import { allBuyerPfp, getPfpId } from "appstate/buyer/buyerSlice";
import Pagination from "shared/Pagination";
import {
  // PAIconEmptyStateTable,
  PAIconEyePfp,
  PAIconTableEmptyState,
} from "assets/images/svgs";

const AllPayableFinance = () => {
  const [open, setOpen] = useState(false);
  const [pfpFilter, setPfpFilter] = useState(false);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const pageSize = 10;
  const { allBuyerPfpData } = useSelector((state) => state.buyer);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [initialData, setInitialData] = useState();

  const onRowClicked = (row) => {
    let path = "payablefinancedetails";
    dispatch(getPfpId(row?.pfpId));
    navigate(path, {
      state: { financingOption: row.financingOption, status: row.status },
    });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(allBuyerPfp({ page, pageSize, searchTerm }));
  }, [dispatch, page, pageSize, searchTerm]);
  useEffect(() => {
    setData(allBuyerPfpData?.data);
  }, [allBuyerPfpData]);

  const handleChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const columns = [
    {
      name: "Campaign Name",
      selector: (row) => row.campaignName,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Financing Type",
      selector: (row) => row.financingOption,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Start Date",
      selector: (row) => row.startDate,
      sortable: true,
      width: "10rem",
    },
    {
      name: "End Date",
      selector: (row) => row.endDate,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Payment Period",
      selector: (row) => row.paymentPeriod + " days",
      sortable: true,
      width: "10rem",
    },
    {
      name: "Status",
      selector: (row) => (
        <p
          className={
            row.status === "IN_PROGRESS"
              ? "text-primaryColor"
              : row.status === "PENDING"
              ? "text-secondaryColor"
              : row.status === "STOPPED"
              ? "text-[#BB0C0C]"
              : "text-black"
          }
        >
          {row.status === "IN_PROGRESS"
            ? "In Progress"
            : row.status === "PENDING"
            ? "Pending"
            : row.status === "STOPPED"
            ? "Stopped"
            : row.status === "ENDED"
            ? "Ended"
            : "Cancelled"}
        </p>
      ),
      sortable: true,
      width: "10rem",
    },
    {
      name: "",
      selector: (row) => (
        <>
          <PAIconEyePfp />
        </>
      ),
      sortable: true,
      width: "10rem",
    },
  ];

  useEffect(() => {
    // Set the initial data when allBuyerPfpData changes
    setInitialData(allBuyerPfpData?.data);
    setData(allBuyerPfpData?.data); // Set the data to the initial data
  }, [allBuyerPfpData]);

  useEffect(() => {
    let filteredData = initialData; // Start with the initial data

    if (selectedFilter === "REVERSE_FACTORING") {
      filteredData = initialData.filter(
        (item) => item.financingOption === "Reverse Factoring"
      );
    } else if (selectedFilter === "DYNAMIC_DISCOUNTING") {
      filteredData = initialData.filter(
        (item) => item.financingOption === "Dynamic Discounting"
      );
    }

    setData(filteredData); // Update the data with the filtered data
    setPfpFilter(true);
  }, [selectedFilter, initialData]);

  const totalPages = allBuyerPfpData?.meta?.totalElements;
  let path = "/payablefinancing/payablefinancedetail/createpayablefinance";

  return (
    <DashboardLayout
      section="PFP Campaign"
      subHeader="Manage all your payable finance campaign"
    >
      <PayableFinanceModal isShown={open} hide={() => setOpen(false)} />
      <Card className="p-[24px] rounded-[10px]">
        <div>
          <TableHeader
            details="All PFP Campaign"
            total="Access details of your payable finance program"
            btnName="Create PFP Campaign"
            path={path}
            payableFinance
            pfpFilter={pfpFilter}
            handleChange={handleChange}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>

        {allBuyerPfpData?.meta?.size === 0 ? (
          <div className="flex flex-col items-center justify-center w-full h-[65vh]">
            <div className="flex flex-col gap-4 items-center justify-center">
              <PAIconTableEmptyState />
              <p>No Data Available</p>
            </div>
          </div>
        ) : (
          <Table
            columns={columns}
            data={data}
            onRowClicked={onRowClicked}
            //   progressPending={isLoading}
            //   progressComponent={<Loading />}
            pointer
            tableHeader
            className="mt-5"
          />
        )}

        <div className="flex justify-center gap-x-[8px] mt-[20px]">
          {totalPages > 9 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default AllPayableFinance;
