import React, { useEffect, useState } from "react";
import Button from "shared/Button";
import InputField from "shared/InputField";
import {
  allAgrementDocument,
  allBuyerAssociatedFunders,
} from "appstate/buyer/buyerSlice";
import UploadAgreementModal from "../components/Modal/UploadAggrementModal";
import { useDispatch, useSelector } from "react-redux";
import { allVirtualAccounts } from "appstate/wallet/walletSlice";
// import { unwrapResult } from "@reduxjs/toolkit";
const StepTwo = ({
  handleNextPage,
  handlePreviousPage,
  formData,
  handleInputChange,
  errors,
  handleChange,
  searchName,
  filteredData,
  storeData,
  handleSelect,
  handleRemoveEmail,
  isDropdownOpen,
  setIsDropdownOpen,
  isFunderDropdownOpen,
  setIsFunderDropdownOpen,
  selectedOptions,
  handleTiersChange,
  options,
  reverse,
  setIsIndustryDropdownOpen,
  isIndustryDropdownOpen,
  dynamic,
  handleIndustryChange,
  industryErrors,
  isAccountDropdownOpen,
  setIsAccountDropdownOpen,
  selectedIndustryOptions,
  industryOptions,
  handleAccountChange,
  accountOptions,
  selectedAccountOptions,
  selectedTemplateIds,
  setSelectedTemplateIds,
  handleAgreementCheckboxChange,
  handleFileChange,
  selectedAccounts,
  isStepTwoReverseDisabled,
  selectedNames,
  setSelectedNames,
  fundersName,
  handleFunderChange,
  isStepTwoDynamicDisabled,
}) => {
  const dispatch = useDispatch();
  const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false);
  const [page] = useState(0);
  const pageSize = 10;
  const search = "";
  const { allVirtualAccountsData } = useSelector((state) => state?.wallet);

  useEffect(() => {
    dispatch(allAgrementDocument(page, pageSize), allVirtualAccounts());
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    dispatch(allVirtualAccounts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(allBuyerAssociatedFunders({ page, pageSize, search }));
  }, [dispatch, page, pageSize, search]);

  const { allBuyerFundingAgreement, buyerAssociatedFundersData } = useSelector(
    (state) => state?.buyer
  );
  const { allTiersData } = useSelector((state) => state?.tier);

  // const dispatch = useDispatch();
  const selectedIndustriesText =
    selectedIndustryOptions?.length > 0
      ? `Selected industries are ${selectedIndustryOptions.join(", ")}`
      : "No industries selected";

  return (
    <div>
      <div className="flex flex-col items-center justify-center">
        <div className="mb-[20px] w-[600px]">
          <InputField
            labelFontSize
            type="number"
            label="Payment Duration:"
            id="Payment Duration"
            name="paymentDuration"
            placeholder="0 days"
            className="border border-[#c5c5c5] rounded-[4px] px-[16px] py-[12px] outline-none w-[600px] height-[50px]"
            onChange={handleInputChange}
            value={formData.paymentDuration}
          />
          {errors?.paymentDuration && (
            <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
              {errors.paymentDuration}
            </p>
          )}
        </div>

        <div
          className={
            isDropdownOpen
              ? "w-[600px] flex flex-col"
              : "w-[600px] flex flex-col"
          }
        >
          <label
            htmlFor=""
            className="font-normal text-sm text-textColor mb-[10px]"
          >
            Tier Selection
          </label>
          <div
            className={`dropdown relative ${isDropdownOpen ? "open" : ""}`}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <div
              className={`selected-options border border-[#c5c5c5] h-[45px] ${
                isDropdownOpen ? "rounded-t-[4px]" : "rounded-[4px]"
              } p-2 cursor-pointer flex justify-between items-center`}
            >
              {options?.length === 0 ? (
                <span className="text-sm">
                  What tier qualifies for program{" "}
                </span>
              ) : options?.length === 1 ? (
                <span className="text-sm">{options}</span>
              ) : (
                <span className="text-sm">{options?.length} Tiers selected</span>
              )}
              <svg
                className="h-4 w-4 ml-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {isDropdownOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 15l7-7 7 7"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                )}
              </svg>
            </div>
            {errors?.tiers && (
              <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
                {errors?.tiers}
              </p>
            )}
            <div
              className={`dropdown-menu bg-white border flex flex-wrap border-[#c5c5c5] border-t-0 w-full z-10 ${
                isDropdownOpen ? "block" : "hidden"
              } transition-all duration-500 ${
                isDropdownOpen ? "ease-in" : "ease-out"
              }`}
            >
              {allTiersData?.data?.map((option) => (
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="option"
                  key={option?.tierName}
                >
                  <label className="block cursor-pointer p-2 text-[14px]">
                    <input
                      type="checkbox"
                      // checked={option.checked}
                      onClick={(e) => e.stopPropagation()}
                      checked={options.some(
                        (selected) => selected === option.tierName
                      )}
                      id="industries"
                      name="industries"
                      value={formData.industries}
                      onChange={() => handleTiersChange(option)}
                      className="mr-2"
                    />
                    {option?.tierName}
                  </label>
                </div>
              ))}
            </div>
            <p className="text-sm mt-[10px] mb-[10px]">
              {selectedOptions?.map((option) => option?.tierName).join(", ")}
            </p>
          </div>
        </div>

        <div
          className={
            isIndustryDropdownOpen
              ? "w-[600px] flex flex-col mt-2"
              : "w-[600px] flex flex-col mt-2"
          }
        >
          <label
            htmlFor=""
            className="font-normal text-sm text-textColor mb-[10px]"
          >
            Industry Selection
          </label>
          <div
            className={`dropdown relative ${
              isIndustryDropdownOpen ? "open" : ""
            }`}
            onClick={() => setIsIndustryDropdownOpen(!isIndustryDropdownOpen)}
          >
            <div
              className={`selected-options border border-[#c5c5c5] h-[45px] ${
                isIndustryDropdownOpen ? "rounded-t-[4px]" : "rounded-[4px]"
              } p-2 cursor-pointer flex justify-between items-center`}
            >
              {selectedIndustryOptions?.length === 0 ? (
                <span className="text-sm">
                  What industry qualifies for the campaign{" "}
                </span>
              ) : selectedIndustryOptions.length === 1 ? (
                <span className="text-sm">{selectedIndustryOptions}</span>
              ) : (
                <span className="text-sm">
                  {selectedIndustryOptions?.length} industries selected
                </span>
              )}
              <svg
                className="h-4 w-4 ml-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {isIndustryDropdownOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 15l7-7 7 7"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                )}
              </svg>
            </div>
            {industryErrors?.industry && (
              <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
                {industryErrors?.industry}
              </p>
            )}

            <div
              className={`dropdown-menu bg-white h-[120px] w-[600px] overflow-y-auto border flex flex-wrap flex-col border-[#c5c5c5] border-t-0  z-10 ${
                isIndustryDropdownOpen ? "block" : "hidden"
              } transition-all duration-500 ${
                isIndustryDropdownOpen ? "ease-in" : "ease-out"
              }`}
            >
              {industryOptions?.map((option) => (
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="option"
                  key={option}
                >
                  <label className="block cursor-pointer p-2 text-[14px]">
                    <input
                      type="checkbox"
                      // checked={option.checked}
                      checked={selectedIndustryOptions?.includes(option)}
                      onClick={(e) => e.stopPropagation()}
                      id="industries"
                      name="industries"
                      value={formData.industries}
                      onChange={() => handleIndustryChange(option)}
                      className="mr-2"
                    />
                    {option}
                  </label>
                </div>
              ))}
            </div>
            <p className="text-sm mt-[10px] mb-[10px]">
              {selectedIndustriesText}
            </p>
          </div>
        </div>

        {dynamic === true && (
          <div
            className={
              isAccountDropdownOpen
                ? "w-[600px] flex flex-col mt-2"
                : "w-[600px] flex flex-col mt-2"
            }
          >
            <label
              htmlFor=""
              className="font-normal text-sm text-textColor mb-[10px]"
            >
              Select Account For Funding
            </label>
            <div
              className={`dropdown relative ${isDropdownOpen ? "open" : ""}`}
              onClick={() => setIsAccountDropdownOpen(!isAccountDropdownOpen)}
            >
              <div
                className={`selected-options border border-[#c5c5c5] h-[45px] ${
                  isDropdownOpen ? "rounded-t-[4px]" : "rounded-[4px]"
                } p-2 cursor-pointer flex justify-between items-center`}
              >
                {selectedAccounts === null ? (
                  <span className="text-sm">Select accounts for funding </span>
                ) : (
                  <span className="text-sm">{selectedAccounts}</span>
                )}
                <svg
                  className="h-4 w-4 ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  {isAccountDropdownOpen ? (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 15l7-7 7 7"
                    />
                  ) : (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  )}
                </svg>
              </div>
              {errors.tiers && (
                <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
                  {errors.tiers}
                </p>
              )}
              <div
                className={`dropdown-menu bg-white border flex flex-wrap border-[#c5c5c5] border-t-0 w-full z-10 ${
                  isAccountDropdownOpen ? "block" : "hidden"
                } transition-all duration-500 ${
                  isAccountDropdownOpen ? "ease-in" : "ease-out"
                }`}
              >
                {allVirtualAccountsData?.data?.map((option) => (
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="option"
                    key={option.id}
                  >
                    <label className="block cursor-pointer p-2">
                      <input
                        type="checkbox"
                        // checked={option.checked}
                        checked={selectedAccounts === option?.accountName}
                        onClick={(e) => e.stopPropagation()}
                        id="accounts"
                        name="accounts"
                        value={formData?.accounts}
                        onChange={() => handleAccountChange(option)}
                        className="mr-2"
                      />
                      {option?.accountName}
                    </label>
                  </div>
                ))}
              </div>
              <p className="text-sm mt-[10px] mb-[10px]">{selectedAccounts}</p>
            </div>
          </div>
        )}

        {reverse === true && (
          <div className="w-[600px]">
            <label
              htmlFor=""
              className="text-sm font-semibold text-textColor mb-[8px]"
            >
              Sharing ratio
            </label>

            <div className="flex justify-between items-center">
              <div>
                <InputField
                  labelFontSize
                  label="Buyer"
                  type="number"
                  id="buyerShare"
                  name="buyerShare"
                  placeholder="buyer share"
                  value={formData?.buyerShare}
                  onChange={handleInputChange}
                  className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none border rounded-[8px] px-[16px] py-[12px] w-[235px] outline-none"
                />
              </div>
              <p className="text-primaryColor text-[20px] relative top-3"> :</p>
              <InputField
                labelFontSize
                label="Funder"
                placeholder={
                  formData.buyerShare > "0"
                    ? 100 - formData.buyerShare
                    : "100% - BS"
                }
                disabled
                className={`${
                  formData?.buyerShare > "0" ? "bg-[#FFFFFF]" : "bg-[#EFEFEF]"
                } border rounded-[8px] px-[16px] py-[12px] w-[235px] outline-none`}
              />
            </div>
            {errors?.buyerShare && (
              <p className="text-red text-xs relative top-2 mb-[10px]">
                {errors.buyerShare}
              </p>
            )}
          </div>
        )}

        {reverse === true && (
          <div className="w-[600px] mt-5">
            <p className="text-[14px] mb-2">Upload Agreement Document</p>
            <div className="flex items-center">
              {selectedNames && (
                <div className="border-[1px] rounded-[4px] h-[40px] flex items-center px-3 mr-3">
                  <p className="text-[14px]">{selectedNames}</p>
                </div>
              )}
              <button
                className="bg-[#f2f2f2] border-[1px] border-[#cccccc] text-black px-5 py-1 h-[40px] rounded-[4px]"
                onClick={() => {
                  setIsAgreementModalOpen(true);
                }}
              >
                Choose File
              </button>
            </div>
          </div>
        )}

        {/* <p className="text-gray800 text-sm font-[300] italic mb-[20px] mt-[1.12rem]">
          <span className="text-[#F08000] text-sm font-semibold">Note</span>:
          Sharing ratio is less service charge of 25%
        </p> */}

        {reverse === true && (
          <div className={"w-[600px] flex flex-col mt-5"}>
            <label
              htmlFor=""
              className="font-normal text-sm text-textColor mb-[10px]"
            >
              Invite funders to campaign
            </label>
            <div
              className={`dropdown relative ${isDropdownOpen ? "open" : ""}`}
              onClick={() => setIsFunderDropdownOpen(!isFunderDropdownOpen)}
            >
              <div
                className={`selected-options border border-[#c5c5c5] h-[45px] ${
                  isFunderDropdownOpen ? "rounded-t-[4px]" : "rounded-[4px]"
                } p-2 cursor-pointer flex justify-between items-center`}
              >
                {formData?.funderName?.length === 0 ? (
                  <span className="text-sm">
                    What tier qualifies for program{" "}
                  </span>
                ) : formData?.funderName?.length === 1 ? (
                  <span className="text-sm">{formData?.funderName}</span>
                ) : (
                  <span className="text-sm">
                    {formData?.funderName?.length} Tiers selected
                  </span>
                )}
                <svg
                  className="h-4 w-4 ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  {isFunderDropdownOpen ? (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 15l7-7 7 7"
                    />
                  ) : (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  )}
                </svg>
              </div>
              {errors?.funder && (
                <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
                  {errors.funder}
                </p>
              )}
              <div
                className={`dropdown-menu bg-white border flex flex-wrap border-[#c5c5c5] border-t-0 w-full z-10 ${
                  isFunderDropdownOpen ? "block" : "hidden"
                } transition-all duration-500 ${
                  isFunderDropdownOpen ? "ease-in" : "ease-out"
                }`}
              >
                {buyerAssociatedFundersData?.data?.map((option) => (
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="option"
                    key={option.userId}
                  >
                    <label className="flex cursor-pointer p-2 text-[14px]">
                      <input
                        type="checkbox"
                        // checked={option.checked}
                        onClick={(e) => e.stopPropagation()}
                        // checked={formData.fundersName?.includes(option.companyName)}
                        checked={formData?.fundersName?.some(
                          (selected) => selected === option?.companyName
                        )}
                        id="industries"
                        name="industries"
                        value={formData?.funderIds}
                        onChange={() => handleFunderChange(option)}
                        className="mr-2"
                      />
                      <div className="ml-2">
                        <p className="text-[16px] font-semibold">
                          {option?.companyName}
                        </p>
                        <p className="text-[14px] text-[#7a7a7a]">
                          {option?.email}
                        </p>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
              <p className="text-sm mt-[10px] mb-[10px]">
                {selectedOptions?.map((option) => option.tierName).join(", ")}
              </p>
            </div>
          </div>
        )}

        {/* {reverse === true && (
          <div className="w-[600px] mt-5">
            <InputField
              labelFontSize
              type="text"
              label="Invite Funder"
              placeholder="Search associated funders"
              value={searchName}
              onChange={handleChange}
              className="border rounded-[8px] px-[16px] py-[12px] outline-none mb-[10px]"
            />
            {searchName.trim() !== "" && (
              <div className="bg-[#EFEFEF] text-sm p-[10px] rounded">
                {filteredData?.map((funder, index) => (
                  <div
                    className="cursor-pointer"
                    onClick={() => handleSelect(funder)}
                    key={index}
                  >
                    <p>{funder?.fullName}</p>
                  </div>
                ))}
              </div>
            )}
            {storeData?.map((funder, index) => (
              <div
                key={index}
                className="flex justify-between items-center mb-[18px]"
              >
                <p className="text-sm text-textColor">{funder.fullName}</p>
                <Button
                  neutral
                  onClick={() => handleRemoveEmail(index)}
                  className="py-[8px] px-[16px] border-[1px] border-primaryColor !text-primaryColor text-xs rounded-[5px]"
                >
                  Remove
                </Button>
              </div>
            ))}
            {errors.funderIds && (
              <p className="text-red text-xs  w-[300px] md:w-[424px]">
                {errors.funderIds}
              </p>
            )}
          </div>
        )} */}
      </div>

      <UploadAgreementModal
        isOpen={isAgreementModalOpen}
        allBuyerFundingAgreement={allBuyerFundingAgreement}
        selectedTemplateIds={selectedTemplateIds}
        setSelectedTemplateIds={setSelectedTemplateIds}
        handleAgreementCheckboxChange={handleAgreementCheckboxChange}
        handleFileChange={handleFileChange}
        selectedNames={selectedNames}
        setSelectedNames={setSelectedNames}
        onClose={() => {
          setIsAgreementModalOpen(false);
        }}
      />

      <div className="flex justify-between mt-[1.88rem]">
        <Button
          type="Button"
          neutral
          className="!text-lightGray text-base !font-normal"
          onClick={handlePreviousPage}
        >
          Previous
        </Button>
        <Button
          type="Button"
          neutral
          onClick={handleNextPage}
          disabled={reverse === true ? isStepTwoReverseDisabled : isStepTwoDynamicDisabled}
          className="!text-white !font-normal bg-primaryColor py-[8px] px-[24px] rounded-[5px]"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default StepTwo;
