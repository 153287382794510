import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllSuppliers } from "appstate/api/auth/onboarding";
import { supplierTransactions } from "appstate/api/supplier";
import { supplierDashboard } from "appstate/api/supplier/dashboard";
import { requestDirectFunding } from "appstate/api/supplier/directFunding";
import { createTransPin } from "appstate/api/transactionPin";
import {
  earlyPaymentRequestSend,
  getAllApprovedInvoices,
} from "appstate/api/supplier/earlyPayment";
import {
  allSupplierNotifications,
  notificationById,
} from "appstate/api/supplier/notification";
import {
  disassociateSupplierBuyer,
  disassociateSupplierFunder,
  getAllSupplierAssociatedBuyers,
  supplierAssociateWithBuyer,
  supplierAssociatedFunder,
  supplierAssociatedFunderDF,
  supplierSendAssociationReqeustToFunder,
  supplierSendOnboardingInviteToFunder,
  suppliersSearch,
} from "appstate/api/supplier/relationshipManagement";

const initialState = {
  associateLoading: false,
  isLoading: false,
  supplierDashboardData: [],
  transactions: [],
  associatedBuyerId: null,
  associatedFunderId: null,
  supplierAssociatedBuyersData: [],
  supplierAssociatedFundersData: [],
  supplierAssociatedFundersDataDf: [],
  allSuppliersData: [],
  disAssociateLoading: false,
  inviteLoading: false,
  notifications: [],
  notification: null,
  approvedInvoicesData: [],
  approvedInvoiceId: null,
  earlyPaymentLoading: false,
  directFundingLoading: false,
};

export const associateSupplierWithBuyer = createAsyncThunk(
  "supplier/associateSupplierWithBuyer",
  supplierAssociateWithBuyer
);
export const getSupplierDashboard = createAsyncThunk(
  "supplier/getSupplierDashboard",
  supplierDashboard
);
export const getAllSupplierTransactions = createAsyncThunk(
  "supplier/getAllSupplierTransactions",
  supplierTransactions
);
export const sendAsscociateRequestToFunder = createAsyncThunk(
  "supplier/sendAsscociateRequestToFunder",
  supplierSendAssociationReqeustToFunder
);
export const allSupplierAssociatedBuyer = createAsyncThunk(
  "supplier/allSupplierAssociatedBuyer",
  getAllSupplierAssociatedBuyers
);

export const supplierOnboardingInviteToFunder = createAsyncThunk(
  "supplier/supplierOnboardingInviteToFunder",
  supplierSendOnboardingInviteToFunder
);

export const getAllSupplierAssociatedFunder = createAsyncThunk(
  "supplier/getAllSupplierAssociatedFunder",
  supplierAssociatedFunder
);

export const getAllSupplierAssociatedFunderDf = createAsyncThunk(
  "supplier/getAllSupplierAssociatedFunderDf",
  supplierAssociatedFunderDF
);


export const allSuppliers = createAsyncThunk(
  "supplier/allSuppliers",
  getAllSuppliers
);

export const supplierBuyerDisassociate = createAsyncThunk(
  "supplier/supplierBuyerDisassociate",
  disassociateSupplierBuyer
);

export const supplierFunderDisassociate = createAsyncThunk(
  "supplier/supplierFunderDisassociate",
  disassociateSupplierFunder
);

export const searchSupplier = createAsyncThunk(
  "supplier/searchSupplier",
  suppliersSearch
);

export const getAllSupplierNotifications = createAsyncThunk(
  "supplier/getAllSupplierNotifications",
  allSupplierNotifications
);

export const getSupplierNotificationById = createAsyncThunk(
  "supplier/getSupplierNotificationById",
  notificationById
);

export const allApprovedInvoices = createAsyncThunk(
  "supplier/allApprovedInvoices",
  getAllApprovedInvoices
);

export const sendEarlyPaymentRequest = createAsyncThunk(
  "supplier/sendEarlyPaymentRequest",
  earlyPaymentRequestSend
);

export const directFundingRequest = createAsyncThunk(
  "supplier/directFundingRequest",
  requestDirectFunding
);
export const supplierCreateTransPin = createAsyncThunk(
  "supplier/createTransPin",
  createTransPin
);

export const suppliersSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    getAssociatedBuyerId: (state, action) => {
      state.associatedBuyerId = action.payload;
    },
    getAssociatedFunderId: (state, action) => {
      state.associatedFunderId = action.payload;
    },
    getApprovedInvoiceId: (state, action) => {
      state.approvedInvoiceId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(associateSupplierWithBuyer.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(associateSupplierWithBuyer.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(associateSupplierWithBuyer.rejected, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(getSupplierDashboard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSupplierDashboard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplierDashboardData = action?.payload;
    });
    builder.addCase(getSupplierDashboard.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getAllSupplierTransactions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllSupplierTransactions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transactions = action?.payload;
    });
    builder.addCase(getAllSupplierTransactions.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(sendAsscociateRequestToFunder.pending, (state) => {
      state.associateLoading = true;
    });
    builder.addCase(sendAsscociateRequestToFunder.fulfilled, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(sendAsscociateRequestToFunder.rejected, (state) => {
      state.associateLoading = false;
    });
    builder.addCase(allSupplierAssociatedBuyer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allSupplierAssociatedBuyer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplierAssociatedBuyersData = action.payload;
    });
    builder.addCase(allSupplierAssociatedBuyer.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(supplierOnboardingInviteToFunder.pending, (state) => {
      state.inviteLoading = true;
    });
    builder.addCase(supplierOnboardingInviteToFunder.fulfilled, (state) => {
      state.inviteLoading = false;
    });
    builder.addCase(supplierOnboardingInviteToFunder.rejected, (state) => {
      state.inviteLoading = false;
    });
    builder.addCase(getAllSupplierAssociatedFunder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllSupplierAssociatedFunder.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.supplierAssociatedFundersData = action.payload;
      }
    );
    builder.addCase(getAllSupplierAssociatedFunder.rejected, (state) => {
      state.isLoading = false;
    });
    //here
    builder.addCase(getAllSupplierAssociatedFunderDf.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllSupplierAssociatedFunderDf.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.supplierAssociatedFundersDataDf = action.payload;
      }
    );
    builder.addCase(getAllSupplierAssociatedFunderDf.rejected, (state) => {
      state.isLoading = false;
    });
    //here
    builder.addCase(allSuppliers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allSuppliers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allSuppliersData = action.payload;
    });
    builder.addCase(allSuppliers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(searchSupplier.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchSupplier.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(searchSupplier.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(supplierBuyerDisassociate.pending, (state) => {
      state.disAssociateLoading = true;
    });
    builder.addCase(supplierBuyerDisassociate.fulfilled, (state, action) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(supplierBuyerDisassociate.rejected, (state) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(supplierFunderDisassociate.pending, (state) => {
      state.disAssociateLoading = true;
    });
    builder.addCase(supplierFunderDisassociate.fulfilled, (state, action) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(supplierFunderDisassociate.rejected, (state) => {
      state.disAssociateLoading = false;
    });
    builder.addCase(getAllSupplierNotifications.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllSupplierNotifications.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notifications = action.payload;
    });
    builder.addCase(getAllSupplierNotifications.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getSupplierNotificationById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSupplierNotificationById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notification = action.payload;
    });
    builder.addCase(getSupplierNotificationById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(allApprovedInvoices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allApprovedInvoices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.approvedInvoicesData = action.payload;
    });
    builder.addCase(allApprovedInvoices.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(sendEarlyPaymentRequest.pending, (state) => {
      state.earlyPaymentLoading = true;
    });
    builder.addCase(sendEarlyPaymentRequest.fulfilled, (state) => {
      state.earlyPaymentLoading = false;
    });
    builder.addCase(sendEarlyPaymentRequest.rejected, (state) => {
      state.earlyPaymentLoading = false;
    });

    builder.addCase(directFundingRequest.pending, (state) => {
      state.directFundingLoading = true;
    });
    builder.addCase(directFundingRequest.fulfilled, (state) => {
      state.directFundingLoading = false;
    });
    builder.addCase(directFundingRequest.rejected, (state) => {
      state.directFundingLoading = false;
    });

    builder.addCase(supplierCreateTransPin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(supplierCreateTransPin.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(supplierCreateTransPin.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
export const {
  getAssociatedBuyerId,
  getAssociatedFunderId,
  getApprovedInvoiceId,
} = suppliersSlice.actions;
export default suppliersSlice.reducer;
