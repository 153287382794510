import React, { useEffect, useState } from "react";
import Table from "layout/table/Table";
// import { useNavigate } from "react-router-dom";
// import { PAIconInventoryArrowLeft } from "assets/images/svgs";

const ProgressDetails = ({ columns, data, funderColumns, viewPfpByIdData }) => {
  const [activeTab, setActiveTab] = useState("funder");
  const [supplierDetails, setSupplierDetails] = useState(null);
  const [funderData, setFunderData] = useState(null);
  // const navigate = useNavigate();
  // function handleGoback() {
  //   let path = -1;
  //   navigate(path);
  // }

  useEffect(() => {
    setSupplierDetails(viewPfpByIdData?.data?.supplierDetails);
    setFunderData(viewPfpByIdData?.data?.funderDetails);
  }, [
    viewPfpByIdData?.data?.supplierDetails,
    viewPfpByIdData?.data?.funderDetails,
  ]);

  return (
    <div className="mt-[48px]">
      <h1 className="text-sm font-semibold md:justify-start text-textColor flex items-center">
        Players on the PFP
        {activeTab === "funder" ? (
          <span className="w-[25px] h-[25px] flex justify-center items-center bg-conch ml-[10px] rounded-[50%]">
          {funderData?.length}
        </span>
        ) : (
          <span className="w-[25px] h-[25px] flex justify-center items-center bg-conch ml-[10px] rounded-[50%]">
          {supplierDetails?.length}
        </span>
        )}
       
      </h1>
      <div className="mt-[24px] pr-[30px]">
        <div className="flex items-center">
          <div
            className={
              activeTab === "funder"
                ? "px-[3%] py-3 bg-[#fcfcfc] text-primaryColor font-semibold"
                : "px-[3%] py-3 text-black cursor-pointer"
            }
            onClick={() => {
              setActiveTab("funder");
            }}
          >
            <p>Funder</p>
          </div>
          <div
            className={
              activeTab === "supplier"
                ? "px-[3%] py-3 bg-[#fcfcfc] text-primaryColor font-semibold"
                : "px-[3%] py-3 text-black cursor-pointer"
            }
            onClick={() => {
              setActiveTab("supplier");
            }}
          >
            <p>Supplier</p>
          </div>
        </div>
        <Table
          columns={activeTab === "funder" ? funderColumns : columns}
          data={activeTab === "funder" ? funderData : supplierDetails}
          // onRowClicked={onRowClicked}
          //   progressPending={isLoading}
          //   progressComponent={<Loading />}
          pointer
          tableHeader
        />
      </div>
    </div>
  );
};

export default ProgressDetails;
