import React, { useEffect } from "react";
import ClosedMarketCard from "./cards/ClosedMarketCard";
import { useState } from "react";
import Pagination from "shared/Pagination";
import BidInvoiceModal from "../modals/BidInvoiceModal";
import BidAcceptModal from "../modals/BidAcceptModal";
// import BidSelectAccountModal from "../modals/BidSelectAccountModal";

import BidProceedModal from "../modals/BidProceedModal";
// import BidPinModal from "../modals/BidPinModal";
import BidInvoiceLockedModal from "../modals/BidInvoiceLockedModal";
import BidLoaderModal from "../modals/BidLoaderModla";
import {
  placeCloseMarketBid,
  setClosedMarketActiveTab,
} from "appstate/funder/funderSlice";
import { useDispatch } from "react-redux";
import TransitionOpacity from "shared/animation/TransitionOpacity";
import { AnimatePresence } from "framer-motion";
import { funderFetchAllClosedMarketByStatus } from "appstate/funder/funderSlice";
import { useSelector } from "react-redux";
import TableEmptyState from "shared/TableEmptyState";
import FundAccountOption from "features/wallet(new)/overview/FundAccount/ExternalSource";
import { allVirtualAccounts } from "appstate/wallet/walletSlice";
import PinModal from "shared/PinModal";
import toast from "react-hot-toast";

const ClosedMarket = ({ closedMarketQuery }) => {
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const [onBid, setOnbid] = useState(false);

  const pageSize = 10;

  const dispatch = useDispatch();

  const [bidSelectAccountModal, setBidSelectAccountModal] = useState(false);
  const [ongoingAcceptUpdateBidModal, setOngoingAcceptUpdateBidModal] =
    useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [bidProceed, setBidProceed] = useState(false);
  const [bidPin, setBidPin] = useState(false);
  const [bidLoaderModal, setBidLoaderModal] = useState(false);
  const [refNum, setRefNum] = useState("");
  const [discountRate, setDiscountRate] = useState("");
  const [closeMarketId, setCloseMarketId] = useState("");
  const [amount, setAmount] = useState("");
  const [loockRate, setLockRate] = useState("");
  const [buyerDiscountRate, setBuyerDiscountRate] = useState("");
  const [status, setStatus] = useState("");

  const handleSetDiscountRate = (e) => {
    setDiscountRate(e.target.value);
  };
  useEffect(() => {
    dispatch(allVirtualAccounts());
  }, [dispatch]);

  const selectedAccounts = useSelector(
    (state) => state?.wallet?.selectedAccounts
  );
  // console.log("weguw", selectedAccounts);

  const handleAccept = () => {
    setOngoingAcceptUpdateBidModal(false);
    setTimeout(() => {
      setBidSelectAccountModal(true);
    }, 300);
  };

  const handleProceed = () => {
    setBidProceed(false);

    setTimeout(() => {
      setBidPin(true);
    }, 300);
  };

  const handleInvoiceModalClose = () => {
    setIsInvoiceModalOpen(false);
  };

  const handleOnselectModal = () => {
    setBidSelectAccountModal(false);

    setTimeout(() => {
      setBidProceed(true);
    }, 300);
  };

  const handlePinModal = (pin) => {
    // setBidPin(false);
    const pendingToast = toast.loading("Placing your Bid...");

    const bidBody = {
      amount: amount,
      discountRate: parseFloat(discountRate),
      note: "note",
      virtualAccountId: selectedAccounts?.id || "",
      transactionPin: pin,
    };

    dispatch(placeCloseMarketBid({ closeMarketId, body: bidBody })).then(
      (data) => {
        if (data?.payload?.success) {
          toast.dismiss(pendingToast);
          setBidPin(false);
          setBidLoaderModal(true);
          dispatch(funderFetchAllClosedMarketByStatus());
          setDiscountRate("");
        } else {
          toast.dismiss(pendingToast);
          toast.error(data?.payload?.message);
          // setDiscountRate("");
        }
      }
    );
  };

  const handleContinueBidding = () => {
    setOnbid(false);
    setTimeout(() => {
      setOngoingAcceptUpdateBidModal(true);
    }, 300);
  };

  const handleStartBid = () => {
    setTimeout(() => {
      setOnbid(true);
    }, 300);
  };

  const handleTabClick = (tabNumber) => {
    setIsInvoiceModalOpen(false);
    dispatch(setClosedMarketActiveTab(tabNumber));
    sessionStorage.setItem("activeTab", tabNumber);
  };

  const { funderClosedMarketDataByStatus } = useSelector(
    (state) => state.funder
  );
  // console.log(funderClosedMarketDataByStatus);
  const statusquery = "";
  const search = "";
  useEffect(() => {
    dispatch(
      funderFetchAllClosedMarketByStatus({
        status: statusquery,
        search,
        page,
        pageSize,
      })
    );
  }, [dispatch, page, status]);
  const totalPages = funderClosedMarketDataByStatus?.meta?.totalElements;

  const filteredBids = funderClosedMarketDataByStatus?.data?.filter((bid) => {
    if (status === "") return true;
    if (status === "BID_ACTIVE") return bid?.status === "BID_ACTIVE";
    if (status === "POSTED") return bid?.status === "POSTED";
    return true;
  });

  return (
    <>
      <TransitionOpacity>
        <AnimatePresence>
          <BidInvoiceModal
            isOpen={onBid}
            handleYes={handleContinueBidding}
            onClose={() => setOnbid(false)}
            handleSetDiscountRate={handleSetDiscountRate}
            discountRate={discountRate}
            lockRate={loockRate || "-"}
            buyerDiscountRate={buyerDiscountRate || "N/A"}
          />
        </AnimatePresence>

        <AnimatePresence>
          <BidInvoiceLockedModal
            isOpen={isInvoiceModalOpen}
            onClose={handleInvoiceModalClose}
            toOngoingTab={() => handleTabClick(3)}
          />
        </AnimatePresence>

        {/* <AnimatePresence> */}
        <>
          {bidLoaderModal && (
            <BidLoaderModal
              isOpen={bidLoaderModal}
              onClose={() => setBidLoaderModal(false)}
              onAction={() => {
                setBidLoaderModal(false);
                setTimeout(() => {
                  setIsInvoiceModalOpen(true);
                }, 300);
              }}
              statusText={"Verifying account"}
            />
          )}{" "}
        </>

        <AnimatePresence>
          {" "}
          {/* <BidPinModal
            isOpen={bidPin}
            onClose={() => setBidPin(false)}
            handleNext={handlePinModal}
          /> */}
          <PinModal
            isOpen={bidPin}
            onClose={() => {
              setBidPin(false);
            }}
            onSubmit={handlePinModal}
            // isLoading={isLoading}
            headerText="Enter Pin"
            instructionText="Enter your transaction PIN to verify your identity."
          />
        </AnimatePresence>

        {/* 3 */}
        <AnimatePresence>
          {" "}
          <BidProceedModal
            isOpen={bidProceed}
            refNum={
              selectedAccounts?.accountNumber && selectedAccounts?.accountNumber
            }
            onClose={() => setBidProceed(false)}
            handleYes={handleProceed}
          />
        </AnimatePresence>

        {/* 2 */}
        <AnimatePresence>
          {" "}
          <BidAcceptModal
            isOpen={ongoingAcceptUpdateBidModal}
            onClose={() => setOngoingAcceptUpdateBidModal(false)}
            refNum={refNum}
            handleYes={handleAccept}
          />
        </AnimatePresence>

        {/* ACCOUNT */}
        <AnimatePresence>
          {" "}
          {/* <BidSelectAccountModal
            isOpen={bidSelectAccountModal}
            onClose={() => setBidSelectAccountModal(false)}
            handleProceed={handleOnselectModal}
          /> */}
          <FundAccountOption
            isOpen={bidSelectAccountModal}
            onClose={() => setBidSelectAccountModal(false)}
            HeaderText="Select which account you want to use for this bid with"
            onSubmit={handleOnselectModal}
          />
        </AnimatePresence>
        <div>
          <div className="flex flex-col  rounded-[10px] bg-unusualGray  px-6 mt-2">
            <header className="pb-4  flex flex-row gap-1  py-8 ">
              <div className="flex flex-row gap-2">
                <div className="text-black text-sm font-normal ">
                  Filter bids:
                </div>

                <div>
                  <select
                    className=" shadow-sm  text-sm focus:outline-none cursor-pointer h-[37px] px-5 py-2.5 rounded-[5px] border border-[#949494] justify-start items-end gap-2 inline-flex md:min-w-[12.5rem]"
                    // onChange={(e) => {}}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="" key="all-bids">
                      All Closed Market Bids
                    </option>
                    <option value="BID_ACTIVE" key="bid-active">
                      Bid Active Request
                    </option>
                    <option value="POSTED" key="posted-request">
                      Posted Request
                    </option>
                  </select>
                </div>

                {/*  */}
              </div>
              <div></div>
            </header>

            <div className="w-full  overflow-x-auto  ">
              {/*  <TableEmptyState dashboard /> */}
              {funderClosedMarketDataByStatus?.data?.length > 0 ? (
                <div className="flex flex-col gap-2.5 ">
                  {funderClosedMarketDataByStatus?.data &&
                    filteredBids?.map((bids, idx) => (
                      <ClosedMarketCard
                        key={idx}
                        id={bids.id}
                        amount={setAmount}
                        setLockRate={setLockRate}
                        setBuyerDiscountRate={setBuyerDiscountRate}
                        closeMarketId={setCloseMarketId}
                        refNum={setRefNum}
                        invoiceNumber={bids.closedMarketReference}
                        buyerName={bids.buyerCompanyName}
                        lockRate={bids.lockRate}
                        buyerDiscountRate={bids.buyerDiscountRate}
                        statusOfInvoice={bids.status}
                        requestAmount={bids.requestAmount}
                        dueDate={bids?.invoiceDueDate}
                        earlyPaymentDate={bids.earlyPaymentDate}
                        supplierName={bids.supplierCompanyName}
                        onBid={handleStartBid}
                        bulk={bids.bulk}
                      />
                    ))}
                </div>
              ) : (
                <TableEmptyState dashboard />
              )}
            </div>
          </div>

          <div className="flex justify-center gap-x-[8px] mt-[20px]">
            {totalPages > 10 && (
              <Pagination
                page={page}
                startPage={startPage}
                setEndPage={setEndPage}
                endPage={endPage}
                setStartPage={setStartPage}
                setPage={setPage}
                totalPages={totalPages}
              />
            )}
          </div>
        </div>
      </TransitionOpacity>
    </>
  );
};

export default ClosedMarket;
