import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  PAIconArrowDown,
  PAIconColapsClose,
  PAIconColapsOpen,
  PAIconEdgeLogo,
  PAIconLogout,
  PAIconProfilePicSide,
  PAIconYellowBadge,
} from "assets/images/svgs";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "appstate/auth/authSlice";
import {
  adminSideMenu,
  buyerSidebarContent,
  funderSidebarContent,
  generalSideMenu,
  supplierSidebarContent,
} from "utilities/SidebarContents";
import { staffProfileById } from "appstate/roleManager/roleManagerSlice";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutConfirmModal from "layout/modal/LogoutModal";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";

const Sidebar = () => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const location = useLocation(); // Hook to get current route
  const dispatch = useDispatch();
  const [activeLink, setActiveLink] = useState(
    sessionStorage.getItem("activeMenuItem") || 0
  ); // Track the active link for sidebarData
  const [activeGeneralLink, setActiveGeneralLink] = useState(null); // Track the active link for generalSideMenu
  const [isCollapsed, setIsCollapsed] = useState(false); // Manage collapsed/expanded sidebar state
  const [openDropdown, setOpenDropdown] = useState(null); // Track which parent has its dropdown open
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const { isAuthenticated } = useSelector((state) => state.auth);
  const { staffProfileByIdData } = useSelector((state) => state.roleManager);
  const userName = keycloak?.idTokenParsed;

  const { userId } = useSelector((state) => state.auth);

  const constants = [
    "default-roles-payassyst",
    "offline_access",
    "uma_authorization",
  ];
  const roleIndex = userId?.realm_access?.roles.findIndex(
    (role) => !constants.includes(role)
  );

  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();

  const player = userRole?.includes("buyer")
    ? "buyer"
    : userRole?.includes("funder")
    ? "funder"
    : "supplier";

  const sidebarData = (() => {
    switch (player) {
      case "supplier":
        return supplierSidebarContent;
      case "buyer":
        return buyerSidebarContent;
      case "funder":
        return funderSidebarContent;
      default:
        return adminSideMenu;
    }
  })();

  const handleLogout = () => {
    if (isAuthenticated || keycloak?.authenticated) {
      sessionStorage.clear();
      keycloak.logout().then(() => dispatch(logOut()));
    }
  };

  useEffect(() => {
    if (userRole?.includes("staff")) {
      dispatch(
        staffProfileById({
          player,
          staffId: keycloak?.tokenParsed?.sub,
        })
      );
    }
  }, [dispatch, keycloak?.tokenParsed?.sub, player, userRole]);

  // Automatically collapse the sidebar on small screens
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setIsCollapsed(true); // Collapse sidebar on small screens
      } else {
        setIsCollapsed(false); // Expand sidebar on medium and large screens
      }
    };

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Call on mount to set initial state
    handleResize();

    // Clean up event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const currentRoute = location.pathname;

    let foundSidebarLink = false; // Flag to ensure no conflict with generalSideMenu

    // Handle active link for sidebarData (parent-child relationship)
    sidebarData.forEach((item, index) => {
      if (
        item.route &&
        (currentRoute === item.route || currentRoute.startsWith(item.route))
      ) {
        // If parent route is valid and matches the current route (or is a parent of a child route)
        setActiveLink(index);
        sessionStorage.setItem("activeMenuItem", index);
        foundSidebarLink = true;
      }

      item?.subMenu?.forEach((child, childIndex) => {
        if (
          child.route &&
          (currentRoute === child.route || currentRoute.startsWith(child.route))
        ) {
          // If child route is valid and matches the current route (or is a deeper child route)
          setActiveLink(`${index}-${childIndex}`);
          sessionStorage.setItem("activeMenuItem", `${index}-${childIndex}`);
          foundSidebarLink = true;
        }
      });
    });

    // Handle active link for generalSideMenu (only if not already handled by sidebarData)
    if (!foundSidebarLink) {
      generalSideMenu.forEach((item, index) => {
        if (
          item.route &&
          (currentRoute === item.route || currentRoute.startsWith(item.route))
        ) {
          // Check if general menu item matches the current route or is a parent of a deeper route
          setActiveGeneralLink(index);
        }
      });
    }
  }, [dispatch, location, sidebarData]);

  const handleLinkClick = (index, route) => {
    setActiveLink(index); // Set the active link first
    sessionStorage.setItem("activeMenuItem", index);
    navigate(route); // Then navigate
  };

  const handleSubLinkClick = (parentIndex, childIndex, route) => {
    setActiveLink(`${parentIndex}-${childIndex}`); // For child links, set the active link
    sessionStorage.setItem("activeMenuItem", `${parentIndex}-${childIndex}`);
    navigate(route); // Then navigate
  };

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index); // Toggle the dropdown for the parent link
  };

  return (
    <>
      <motion.div
        className="min-h-screen overflow-auto scrollbar-hide bg-primaryColor text-white flex"
        initial={{ width: "18.25rem" }} // Start with expanded width
        animate={{ width: isCollapsed ? "" : "18.25rem" }} // Animate width on collapse/expand
        transition={{ duration: 0.3, ease: "easeInOut" }} // Duration and easing
      >
        {/* Sidebar */}
        <div className="pt-7 pb10 flex flex-col space-y-6 w-full">
          {/* Sidebar Header */}
          <div
            className={`flex px-5 items-end ${
              isCollapsed ? "justify-center" : ""
            }`}
          >
            <PAIconEdgeLogo
              className={`${
                isCollapsed ? "hidden" : "w-36 h-auto"
              } transition-all duration-300 ease-in-out`}
            />
            {/* Collapsible Button */}
            <div className="flex items">
              {isCollapsed ? (
                <PAIconColapsOpen
                  className="cursor-pointer text-white"
                  onClick={() => setIsCollapsed(!isCollapsed)}
                />
              ) : (
                <PAIconColapsClose
                  className="cursor-pointer text-white"
                  onClick={() => setIsCollapsed(!isCollapsed)}
                />
              )}
            </div>
          </div>

          {/* Sidebar Links */}
          <nav className="space-y-1">
            {sidebarData?.map((item, index) => (
              <div key={index}>
                {/* Parent Link */}
                <div
                  className={`w-full text-left px-5 py-3 flex items-center space-x-3 ${
                    isCollapsed ? "justify-center" : ""
                  } ${
                    activeLink === index
                      ? "bg-secondaryColor hover:bg-secondaryColor/90 text-white"
                      : "text-gray-300 hover:bg-gray/10"
                  } hover:ease-in-out duration-300 focus:outline-none transition-colors cursor-pointer`}
                  onClick={() => {
                    handleLinkClick(index, item?.route);
                    toggleDropdown(index); // Toggle dropdown when parent is clicked
                  }}
                >
                  <span>{item?.icon}</span>
                  {!isCollapsed && (
                    <span className="font-medium leading-normal tracking-[0.5px]">
                      {item?.text}
                    </span>
                  )}
                  {item?.subMenu?.length > 0 && (
                    <motion.span
                      className="ml-auto transform"
                      animate={{
                        rotate: openDropdown === index ? 180 : 0, // Rotate the arrow when dropdown is open
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <PAIconArrowDown className="stroke-white" />
                    </motion.span>
                  )}
                </div>

                {/* Animated Dropdown for Children */}
                {item?.subMenu?.length > 0 && (
                  <motion.div
                    className="pl-6 overflow-hidden"
                    initial={{ opacity: 0, maxHeight: 0 }}
                    animate={{
                      opacity: openDropdown === index ? 1 : 0,
                      maxHeight: openDropdown === index ? "40rem" : 0, // Animate maxHeight for smooth transition
                    }}
                    transition={{
                      duration: 0.3,
                      ease: "easeInOut",
                    }}
                  >
                    <ul className="space-y-2 mt-2">
                      {item?.subMenu?.map((child, childIndex) => (
                        <li className="cursor-pointer" key={childIndex}>
                          <span
                            className={`py-3 cursor-pointer px-5 text-sm text-gray-300 hover:bg-gray/10 hover:ease-in-out duration-300 transition-colors rounded-md flex mr-4 items-center space-x-3 ${
                              isCollapsed ? "justify-center" : ""
                            } ${
                              activeLink === `${index}-${childIndex}`
                                ? "bg-secondaryColor text-white"
                                : ""
                            }`}
                            onClick={() => {
                              handleSubLinkClick(
                                index,
                                childIndex,
                                child?.route
                              );
                            }}
                          >
                            <span>{child?.icon}</span>
                            {!isCollapsed && (
                              <span className="font-medium leading-normal tracking-[0.5px]">
                                {child?.text}
                              </span>
                            )}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </motion.div>
                )}
              </div>
            ))}
          </nav>

          {/* Divider */}
          <div
            className={`border-t w-[80%] mx-auto my-4 ${isCollapsed ? "" : ""}`}
            style={{ borderColor: "rgba(255, 255, 255, 0.40)" }}
          ></div>

          {/* General Side Menu */}
          <nav className="space-y-1">
            {generalSideMenu?.map((item, index) => (
              <div key={index}>
                {/* Parent Link */}
                <div
                  className={`w-full text-left px-5 py-3 flex items-center space-x-3 ${
                    isCollapsed ? "justify-center" : ""
                  } ${
                    activeGeneralLink === index
                      ? "bg-secondaryColor hover:bg-secondaryColor/90 text-white"
                      : "text-gray-300 hover:bg-gray/10"
                  } hover:ease-in-out duration-300 focus:outline-none transition-colors cursor-pointer`}
                  onClick={() => {
                    setActiveGeneralLink(index); // Set active link for general side menu
                    sessionStorage.setItem("activeGeneralMenuItem", index);
                    navigate(item?.route); // Navigate to route
                  }}
                >
                  <span>{item?.icon}</span>
                  {!isCollapsed && (
                    <span className="font-medium leading-normal tracking-[0.5px]">
                      {item?.text}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </nav>

          <div
            className={`flex px-5 pt-5 gap-4 ${
              isCollapsed ? "flex-col items-center" : "flex-row"
            }`}
          >
            <PAIconProfilePicSide />
            {!isCollapsed && (
              <div
                className={`flex flex-col text-sm text-gray-400 ${
                  isCollapsed ? "opacity-0" : "opacity-100"
                }`} // Hide name when collapsed
              >
                <span className="font-medium mb-1.5">
                  {userName?.given_name === "staff staff"
                    ? staffProfileByIdData?.data?.fullName?.split(" ")[0]
                    : userName?.given_name?.split(" ")[0]}
                </span>
                <span
                  style={{ color: "rgba(255, 255, 255, 0.60)" }}
                  className="text-xs"
                >
                  {formatCapitaliseString(player)}
                </span>

                <span className="mt-0.5 text-[8px] text-[#FFA946] flex items-center gap-1">
                  <span>Verified</span>
                  <PAIconYellowBadge />
                </span>
              </div>
            )}
          </div>

          <footer className="mt-4 px-5  flex justify-center pb-10">
            {/* Logout Button */}
            {!isCollapsed ? (
              <motion.button
                onClick={() => setLogoutModalOpen(true)}
                className={`gap-y-2 w-full bg-red-600 hover:bg-red-700 text-white border border-[#F08000] py-2 rounded-md ${
                  isCollapsed ? "text-center" : ""
                }`}
                whileTap={{ scale: 0.92 }}
                initial={{ opacity: 1 }}
                animate={{ opacity: isCollapsed ? 0 : 1 }} // Fade logout button in/out
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                <span className="flex items-center justify-center gap-2">
                  <PAIconLogout />
                  <span className="font-medium">Logout</span>{" "}
                </span>
              </motion.button>
            ) : (
              <motion.button
                onClick={() => setLogoutModalOpen(true)}
                className={`gap-y-2 w-full bg-red-600 hover:bg-red-700 text-white border border-[#F08000] py-2 rounded-md ${
                  isCollapsed ? "text-center" : ""
                }`}
                whileTap={{ scale: 0.92 }}
                initial={{ opacity: 1 }}
                animate={{ opacity: isCollapsed ? 1 : 0 }} // Fade logout button in/out
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                <span className="flex w-full items-center justify-center">
                  <PAIconLogout />
                </span>
              </motion.button>
            )}
          </footer>
        </div>
      </motion.div>
      <LogoutConfirmModal
        isOpen={logoutModalOpen}
        onClose={() => setLogoutModalOpen(false)}
        onConfirm={handleLogout}
      />
    </>
  );
};

export default Sidebar;
