import React, { useState } from "react";
import { PAIconSmallClose } from "assets/images/svgs";
import { motion } from "framer-motion";
import { formatCurrency } from "utilities/helper/formatters";
import { formatCapitalize } from "utilities/formatCapitalize";

const FunderMetricModal = ({ data, onClose }) => {
  const [activeTab, setActiveTab] = useState("evaluation");

  const handleCloseDeleteModal = () => {
    onClose();
  };

  const renderContent = () => {
    switch (activeTab) {
      case "evaluation":
        return (
          <div>
            <section className="flex flex-col gap-6">
              <header className="font-bold text-sm">
                Performance Evaluation Metrix
              </header>
              {/* Section 1 */}
              <section className="flex justify-between">
                <div className="flex flex-col gap-2">
                  <span className="text-[#7A7A7A] text-sm">
                    No. of Accepted bids
                  </span>
                  <span className="text-sm">40%</span>
                </div>

                <div className="flex flex-col gap-2">
                  <span className="text-[#7A7A7A] text-sm">Lock Rate</span>
                  <span className="text-sm">30%</span>
                </div>

                <div className="flex flex-col gap-2">
                  <span className="text-[#7A7A7A] text-sm">Response Time</span>
                  <span className="text-sm">30%</span>
                </div>
              </section>

              {/* Section 2 */}

              <section className="flex flex-col gap-6">
                <header className="font-bold text-sm">
                  Funder’s Performance Data
                </header>
                <div className="flex flex-col pb-2 border-b border-[#95959570] gap-2">
                  <span className="text-[#7A7A7A] text-sm">
                    No. of Locked bids
                  </span>
                  <div className="flex justify-between">
                    <span className="text-sm">
                      {" "}
                      {data?.numberOfLockedBids || "0"} bids Locked{" "}
                    </span>
                    <span className="text-sm">
                      {formatCurrency(data?.lockedBidsScore || "N/A")}%
                    </span>
                  </div>
                </div>

                <div className="flex flex-col gap-2 border-b border-[#95959570] pb-2">
                  <span className="text-[#7A7A7A] text-sm">
                    Low Discount Rates
                  </span>
                  <div className="flex justify-between">
                    <span className="text-sm">
                      Average Discount rate of{" "}
                      {data?.averageDiscountRate
                        ? formatCurrency(data?.averageDiscountRate)
                        : "-"}
                      %
                    </span>
                    <span className="text-sm">
                      {data?.averageDiscountRate
                        ? formatCurrency(data?.discountRateScore)
                        : "-"}
                      %
                    </span>
                  </div>
                </div>

                <div className="flex flex-col gap-2 border-b border-[#95959570] pb-2">
                  <span className="text-[#7A7A7A] text-sm">Response Time</span>
                  <div className="flex justify-between">
                    <span className="text-sm">Places offers within 24hrs</span>
                    <span className="text-sm">
                      {" "}
                      {data?.responseTimeScore
                        ? formatCurrency(data?.responseTimeScore)
                        : "0.00"}
                      %
                    </span>
                  </div>
                </div>
              </section>

              {/* Section 3 */}

              {/* <section className="flex flex-col gap-6">
                <header className="font-bold text-sm">Computation</header>
                <div className="flex flex-col gap-2">
                  <span className="text-[#7A7A7A] text-sm">
                    (90 * 0.40) + (82 * 0.30) + (84 * 0.30)
                  </span>
                </div>
              </section> */}

              {/* Section  */}

              <section className="flex justify-between">
                <span className="font-bold text-sm">TOTAL SCORE</span>
                <span className="font-bold text-sm text-primaryColor">
                  {data?.totalScore ? formatCurrency(data?.totalScore) : "-"}%
                </span>
              </section>
            </section>
          </div>
        );
      case "detail":
        return (
          <div className="flex flex-col gap-8">
            {/* Section 1 */}

            <section className="flex flex-col gap-6">
              <header className="font-bold text-sm">
                Personal Information
              </header>

              <div className="text-sm flex flex-col gap-2">
                <span className="text-[#7A7A7A] ">Name</span>
                <span>{formatCapitalize(data?.funderName) || "N/A"}</span>
              </div>

              <div className="text-sm flex flex-col gap-2">
                <span className="text-[#7A7A7A] ">Email</span>
                <span>{data?.email || "N/A"}</span>
              </div>

              <div className="text-sm flex flex-col gap-2">
                <span className="text-[#7A7A7A] ">Contact number</span>
                <span>{data?.contactNumber || "N/A"}</span>
              </div>

              <div className="text-sm flex flex-col gap-2">
                <span className="text-[#7A7A7A] ">Account Type</span>
                <span>Open Market Funder</span>
              </div>
            </section>

            <section className="flex flex-col gap-6">
              <header className="font-bold text-sm">Company Information</header>

              <div className="text-sm flex flex-col gap-2">
                <span className="text-[#7A7A7A] ">Company Address</span>
                <span>{formatCapitalize(data?.companyAddress) || "N/A"}</span>
              </div>
            </section>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <motion.div>
      <motion.div className="bg-slate-600/20 fixed inset-0 flex items-center justify-center z-50">
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="modal-overlay"
        ></motion.div>

        <motion.div
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            scale: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="px-6 pt-9 pb-12 bg-white rounded-[10px] w-[60%] md:w-1/2 extra-md:w-[33%] min-h-[565px]  max-h-[565px] flex flex-col"
        >
          <header className="flex justify-between pb-4 border-b-[3px] border-[#F6F5FA]">
            <span className=" text-[#222] font-bold">
              Funder's Metric Score
            </span>

            <span
              onClick={handleCloseDeleteModal}
              className="flex items-center cursor-pointer gap-2 text-sm font-[500] text-[#565656]"
            >
              <PAIconSmallClose />
              Close
            </span>
          </header>

          <div className="flex space-x-4 py-4">
            <button
              onClick={() => setActiveTab("evaluation")}
              className={`${
                activeTab === "evaluation"
                  ? "font-semibold text-secondaryColor text-xs pb-2 border-b border-secondaryColor"
                  : "text-[#959595] text-xs pb-2"
              }`}
            >
              Funder's Evaluation
            </button>
            <button
              onClick={() => setActiveTab("detail")}
              className={`${
                activeTab === "detail"
                  ? "font-semibold text-secondaryColor text-xs pb-2 border-b border-secondaryColor"
                  : "text-[#959595] text-xs  pb-2"
              }`}
            >
              Funder's Detail
            </button>
          </div>

          <main className="pt-4 overflow-auto scrollbar-hide">
            {renderContent()}
          </main>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default FunderMetricModal;
