import Table from "layout/table/Table";
import { useNavigate } from "react-router-dom";
import TableEmptyState from "shared/TableEmptyState";
import Loading from "shared/Loading";
import { fetchTransactionHistory } from "appstate/buyer/buyerSlice";
import { useDispatch } from "react-redux";
// import Pagination from "shared/Pagination";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
const SingleRequest = () => {
  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const [searchQuery] = useState("");
  const [page] = useState(0);

  const pageSize = 10;

  /*
  {
  "data": [
    {
      "transactionId": "string",
      "programId": "string",
      "transactionParty": "string",
      "paymentDate": "2025-01-23",
      "invoiceNo": "string",
      "currency": "DZD",
      "amount": 0,
      "status": "PENDING",
      "fundingChannel": "WALLET"
    }
  ],
  "success": true,
  "message": "string",
  "status": 0,
  "meta": {
    "size": 0,
    "page": 0,
    "numberOfElements": 0,
    "totalElements": 0
  }
}

  */

  const column = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Transaction Party
        </span>
      ),
      selector: (row) => row.transactionParty,
      sortable: true,
      cell: (row) => (
        <div className="">
          <p>{row.transactionParty}</p>
          <span
            className={`${
              row.transactionParty === "Supplier"
                ? "text-[#2EA923]"
                : "text-[#F08000]"
            }`}
          >
            {`(${row.transactionParty})`}
          </span>
        </div>
      ),
    },
    {
      name: "Payment Date",
      selector: (row) => row.paymentDate?.slice(0, 10),
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.invoiceNo,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "6.2rem",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => (
        <p className="text-[#DE1515]">{formatAmount(row.amount)}</p>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Successful" ? (
          <p className="text-[#07593D]">{row.status}</p>
        ) : row.status === "REJECTED" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "UPDATED" ? (
          <p className="text-blue-300">{row.status}</p>
        ) : row.status === "PARTIALLY Successful" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },

    {
      name: "Funding Channels",
      selector: (row) => row.fundingChannel,
      sortable: true,
      width: "6.2rem",
    },
    {
      name: "Action",
      // selector: (row) => row.action,
      sortable: true,
      cell: (row) => (
        <div
          className="border border-gray300 rounded text-[#959595] p-2"
          // onClick={() => transactionDetails(row.invoiceId)}
        >
          View Details
        </div>
      ),
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchTransactionHistory({
        page,
        size: pageSize,
        search: searchQuery,
        isBulk: false,
      })
    );
  }, [dispatch, page, searchQuery]);

  const {
    transHistory,
    // earlyPaymentOfferByVa,
    isLoading,
  } = useSelector((state) => state.buyer);

  const ReturnedComponent = () => (
    <div>
      {" "}
      {transHistory?.data?.length > 0 ? (
        <Table
          columns={column}
          data={transHistory?.data}
          onRowClicked={(row) => handleRowClick(row)}
          progressPending={isLoading}
          progressComponent={<Loading />}
          pointer
          tableHeader
          className="mt-5"
        />
      ) : (
        <TableEmptyState />
      )}
    </div>
  );

  const navigate = useNavigate();
  const handleRowClick = (row) => {
    if (row.channel === "Loan Default") {
      navigate(
        `/disbursement/funder/disbursement/detailsforEarlyPaymentRequest/${row.idx}`
      );
    } else if (row.channel === "Early Payment Request") {
      // navigate(
      //   `/work-flow-management/detail/PendingRequestInvoiceApprovalScreen/${row.workflowId}`
      // );
      navigate(
        `/disbursement/funder/disbursement/detailsforEarlyPaymentRequestMain/${row.idx}`
      );
      // navigate(
      //   `/work-flow-management/detail/approvalviawalletscreendetails/${row.workflowId}`
      // );
    } else if (row.channel === "Early Payment Offers ") {
      navigate(
        `/disbursement/funder/disbursement/detailsforEarlyPaymentOffer/${row.idx}`
      );
    }
  };
  return (
    // <Table
    //   columns={column}
    //   data={dumData}
    //   onRowClicked={(row) => handleRowClick(row)}
    //   //   progressComponent={<Loading />}
    //   pointer
    //   tableHeader
    //   className="mt-5"
    // />

    <ReturnedComponent />
  );
};
export default SingleRequest;
