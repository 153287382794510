import { PAIconAddRole, PAIconArrowLeftGreen } from "assets/images/svgs";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import { AnimatePresence, motion } from "framer-motion";
import CreateNewRule from "./modals/CreateNewRule";
import CreateRuleFinalise from "./modals/CreateRuleFinalise";
import RulesConfigSearchInput from "./RulesConfigSearchInput";
import {
  allRules,
  getFunderDirectFundingRate,
  updateDirectFundingRate,
  updatePercentageLimitAndDiscount,
} from "appstate/rulesandconfig/rulesandconfigSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { getEarlyPaymentFunderConfig } from "appstate/funder/funderSlice";
import {
  getFunderOpenMarketConfig,
  updateFunderOpenMarketConfig,
} from "appstate/openMarket/openMarketSlice";
// import toast from "react-hot-toast";

function RulesConfigHeader({
  setRuleSavedModal,
  handleSearch,
  searchBy,
  setSearchBy,
  searchQuery,
  setSearchQuery,
  setTestModal,
}) {
  const dispatch = useDispatch();
  const { ruleType } = useParams();
  const { getFunderDirectFundingRateData } = useSelector(
    (state) => state?.rulesAndConfig
  );
  const { getEarlyPaymentFunderConfigData } = useSelector(
    (state) => state?.funder
  );
  const { getFunderOpenMarketConfigData } = useSelector(
    (state) => state?.openMarket
  );
  // console.log(getFunderOpenMarketConfigData);
  let rateInit =
    ruleType === "EARLY_PAYMENT"
      ? getEarlyPaymentFunderConfigData?.data[0]?.discount ?? 0
      : ruleType === "DIRECT_FUNDING"
      ? getFunderDirectFundingRateData?.data ?? 0
      : ruleType === "OPEN_MARKET"
      ? getFunderOpenMarketConfigData?.data ?? 0
      : 0;

  let percentLimitInit =
    ruleType === "EARLY_PAYMENT"
      ? getEarlyPaymentFunderConfigData?.data[0]?.percentageLimit ?? 0
      : ruleType === "DIRECT_FUNDING"
      ? getFunderDirectFundingRateData?.data ?? 0
      : 0;

  const [createNewRuleModal, setCreateNewRuleModal] = useState(false);
  const [createNewRuleModalFinal, setCreateNewRuleModalFinal] = useState(false);
  const [toggleEdit, setToggleEdit] = useState(false);
  const [toggleEditSub, setToggleEditSub] = useState(false);
  const [discountRate, setDiscountRate] = useState(
    ruleType === "OPEN_MARKET"
      ? rateInit?.withRecourseRate
      : ruleType === "DIRECT_FUNDING"
      ? rateInit[0]?.discount
      : rateInit
  );
  const [discountRateSub, setDiscountRateSub] = useState(
    ruleType === "OPEN_MARKET"
      ? rateInit?.withoutRecourseRate
      : ruleType === "DIRECT_FUNDING"
      ? rateInit[1]?.discount
      : rateInit
  );
  const [percentageLimit, setPercentageLimit] = useState(
    ruleType === "DIRECT_FUNDING"
      ? percentLimitInit[0]?.percentageLimit
      : percentLimitInit
  );
  const [percentageLimitSub, setPercentageLimitSub] = useState(
    ruleType === "DIRECT_FUNDING"
      ? percentLimitInit[1]?.percentageLimit
      : percentLimitInit
  );

  let programType;
  switch (ruleType) {
    case "EARLY_PAYMENT":
      programType = "EARLY_PAYMENT_REQUEST";
      break;

    case "DIRECT_FUNDING":
      programType = "DIRECT_FUNDING";
      break;

    default:
      programType = "";
  }

  console.log(`rateInit: ${rateInit} = percentLimit: ${percentLimitInit}`);

  const handleFocus = (e) => {
    const { target } = e;
    const len = target.value.length;
    target.setSelectionRange(len - 1, len - 1);
  };

  const handleCursorPosition = (e) => {
    const { target } = e;
    const len = target.value.length;
    if (target.selectionStart === len) {
      target.setSelectionRange(len - 1, len - 1);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      handleCursorPosition(e);
    }
  };

  const handleDiscountAndPercentageUpdate = () => {
    const pendingToast = toast.loading(
      "Updating Early Payment Configurations..."
    );
    dispatch(
      updatePercentageLimitAndDiscount({
        discountRate,
        percentageLimit,
        ruleType,
        programType,
      })
    ).then((data) => {
      if (data?.payload?.success) {
        toast.dismiss(pendingToast);
        dispatch(allRules({ page: 0, pageSize: 10, ruleType }));
        toast.success(data?.payload?.message);
        setToggleEdit(!toggleEdit);
      } else {
        toast.dismiss(pendingToast);
        toast.error(data?.payload?.message);
      }
    });
  };
  const handleRateUpdateDirectFunding = (disRate, percentLimit, type) => {
    dispatch(
      updateDirectFundingRate({
        discountRate: disRate,
        percentageLimit: percentLimit,
        ruleType: "DIRECT_FUNDING",
        recourseType: type,
      })
    ).then((data) => {
      if (data?.payload?.success) {
        dispatch(allRules({ page: 0, pageSize: 10, ruleType }));
        if (ruleType === "EARLY_PAYMENT")
          dispatch(getEarlyPaymentFunderConfig());
        toast.success(data?.payload?.message);
        if (toggleEdit) setToggleEdit(false);
        if (toggleEditSub) setToggleEditSub(false);
      }
    });
  };

  const handleRateUpdateOpenMarket = (disRate, type) => {
    const pendingToast = toast.loading("Updating Open Market Discount Rate...");
    dispatch(
      updateFunderOpenMarketConfig({
        discountRate: disRate,
        recourseType: type,
      })
    ).then((data) => {
      if (data?.payload?.success) {
        toast.dismiss(pendingToast);
        dispatch(allRules({ page: 0, pageSize: 10, ruleType }));
        if (ruleType === "EARLY_PAYMENT")
          dispatch(getEarlyPaymentFunderConfig());

        if (ruleType === "OPEN_MARKET") dispatch(getFunderOpenMarketConfig());

        if (ruleType === "DIRECT_FUNDING")
          dispatch(getFunderDirectFundingRate());
        toast.success(data?.payload?.message);
        setToggleEdit(false);
        setToggleEditSub(false);
      } else {
        toast.dismiss(pendingToast);
        toast.error(data?.payload?.message);
      }
    });
  };

  useEffect(() => {
    if (ruleType === "DIRECT_FUNDING") dispatch(getFunderDirectFundingRate());
    if (ruleType === "EARLY_PAYMENT") dispatch(getEarlyPaymentFunderConfig());
    if (ruleType === "OPEN_MARKET") dispatch(getFunderOpenMarketConfig());
  }, [dispatch, ruleType]);

  useEffect(() => {
    if (ruleType === "EARLY_PAYMENT") setDiscountRate(rateInit);
    if (ruleType === "OPEN_MARKET") {
      setDiscountRate(rateInit?.withRecourseRate);
      setPercentageLimit(percentLimitInit);
      setDiscountRateSub(rateInit?.withoutRecourseRate);
    }

    if (ruleType === "DIRECT_FUNDING") {
      setDiscountRate(rateInit[0]?.discount);
      setPercentageLimit(percentLimitInit[0]?.percentageLimit);
      setDiscountRateSub(rateInit[1]?.discount);
      setPercentageLimitSub(percentLimitInit[1]?.percentageLimit);
    }
  }, [rateInit, percentLimitInit, ruleType]);

  // useEffect(() => {
  //   if (discountRate !== rateInit) setDiscountRate(rateInit);
  //   if (percentageLimit !== percentLimitInit)
  //     setPercentageLimit(percentLimitInit);
  // }, [rateInit, percentLimitInit, discountRate, percentageLimit]);

  return (
    <div>
      {/* Create New Rule Modal */}
      <AnimatePresence>
        {createNewRuleModal && (
          <CreateNewRule
            setTestModal={setTestModal}
            setRuleSavedModal={setRuleSavedModal}
            closeModal={() => setCreateNewRuleModal(false)}
            setCreateNewRuleModalFinal={setCreateNewRuleModalFinal}
          />
        )}
      </AnimatePresence>

      {/* Rule Modal Finalise  */}
      <AnimatePresence>
        {createNewRuleModalFinal && (
          <CreateRuleFinalise
            closeModal={() => setCreateNewRuleModalFinal(false)}
            setCreateNewRuleModal={setCreateNewRuleModal}
            setRuleSavedModal={setRuleSavedModal}
          />
        )}
      </AnimatePresence>

      <header className=" flex flex-col gap-6 w-full">
        <div className="flex gap-4">
          <Link to="/settings/rules&config" className="flex gap-1.5 text-sm ">
            <PAIconArrowLeftGreen />
            <span className="flex items-center text-primaryColor">Go Back</span>
          </Link>

          <div>
            <h1 className="text-xl font-light">
              {formatCapitaliseString(ruleType)}
            </h1>
          </div>
        </div>

        <div className="p-4 rounded-lg border border-[#95959580] bg-[#E0E0E01A] flex flex-col gap-5">
          <div className="flex justify-between">
            <span className="font-semibold">
              Default configurations for {formatCapitaliseString(ruleType)}
            </span>
          </div>
          {ruleType === "OPEN_MARKET" ? (
            <div className="flex flex-col lg:flex-row justify-between gap-10">
              <div className="flex pb-6 flex-col md:flex-row gap-14 text-sm items-end">
                <div className="flex w-full md:w-auto items-end  gap-6">
                  <div className="gap-4">
                    <span className="font-semibold text-primaryColor">
                      With Recourse
                    </span>
                    <span className="flex gap-2 py-2 font-light">
                      Discount Rate
                    </span>
                  </div>

                  <span className="items-end">
                    <input
                      value={discountRate ? `${discountRate}%` : ""}
                      onChange={(e) => {
                        const value = e.target.value.replace("%", "");
                        if (value === "" || /^[0-9]*\.?[0-9]*$/.test(value)) {
                          setDiscountRate(value);
                        }
                      }}
                      onKeyDown={handleKeyDown}
                      onFocus={handleFocus}
                      onClick={handleCursorPosition}
                      disabled={!toggleEdit}
                      placeholder="0.0%"
                      className={`p-2.5 flex w-20  rounded ${
                        toggleEdit && "border border-primaryColor"
                      }  outline-none justify-center`}
                      type="text"
                    />
                  </span>
                </div>

                <div className="flex w-full md:w-auto justify-center ">
                  {toggleEdit ? (
                    <button
                      onClick={() => {
                        handleRateUpdateOpenMarket(
                          discountRate,
                          "WITH_RECOURSE"
                        );
                      }}
                      className="text-white rounded-[5px] bg-primaryColor px-5 py-2.5 text-sm font-light"
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setToggleEdit(!toggleEdit);
                      }}
                      className="text-secondaryColor rounded-[5px] border border-secondaryColor px-5 py-2.5 text-sm font-light"
                    >
                      Edit
                    </button>
                  )}
                </div>
              </div>

              <div className="flex flex-col md:flex-row gap-14 text-sm items-center justifybetween">
                <div className="flex w-full md:w-auto items-center gap-6">
                  <div className="gap-4">
                    <span className="font-semibold text-primaryColor">
                      Without Recourse
                    </span>
                    <span className="flex gap-2 py-2 font-light">
                      Discount Rate
                    </span>
                  </div>

                  <span>
                    <input
                      value={discountRateSub ? `${discountRateSub}%` : ""}
                      onChange={(e) => {
                        const value = e.target.value.replace("%", "");
                        if (value === "" || /^[0-9]*\.?[0-9]*$/.test(value)) {
                          setDiscountRateSub(value);
                        }
                      }}
                      onKeyDown={handleKeyDown}
                      onFocus={handleFocus}
                      onClick={handleCursorPosition}
                      disabled={!toggleEditSub}
                      placeholder="0.0%"
                      className={`p-2.5 flex w-20  rounded ${
                        toggleEdit && "border border-primaryColor"
                      }  outline-none justify-center`}
                      type="text"
                    />
                  </span>
                </div>

                <div>
                  {toggleEditSub ? (
                    <button
                      onClick={() => {
                        handleRateUpdateOpenMarket(
                          discountRateSub,
                          "WITHOUT_RECOURSE"
                        );
                      }}
                      className="text-white rounded-[5px] bg-primaryColor px-5 py-2.5 text-sm font-light"
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setToggleEditSub(!toggleEditSub);
                      }}
                      className="text-secondaryColor rounded-[5px] border border-secondaryColor px-5 py-2.5 text-sm font-light"
                    >
                      Edit
                    </button>
                  )}
                </div>
              </div>
            </div>
          ) : ruleType === "DIRECT_FUNDING" ? (
            <div className="flex flex-col  justify-between gap-10">
              <div className="flex border-b pb-6 flex-col md:flex-row gap-14 text-sm items-end">
                <div className="flex w-full md:w-auto items-end  gap-6">
                  <div className="gap-4">
                    <span className="font-semibold text-primaryColor">
                      With Recourse
                    </span>
                    <span className="flex gap-2 py-2 font-light">
                      Discount Rate
                    </span>
                  </div>

                  <span className=" items-end">
                    <input
                      value={discountRate ? `${discountRate}%` : ""}
                      onChange={(e) => {
                        const value = e.target.value.replace("%", "");
                        if (value === "" || /^[0-9]*\.?[0-9]*$/.test(value)) {
                          setDiscountRate(value);
                        }
                      }}
                      onKeyDown={handleKeyDown}
                      onFocus={handleFocus}
                      onClick={handleCursorPosition}
                      disabled={!toggleEdit}
                      placeholder="0.0%"
                      className={`p-2.5 flex w-20  rounded ${
                        toggleEdit && "border border-primaryColor"
                      }  outline-none justify-center`}
                      type="text"
                    />
                  </span>
                </div>

                <div className="flex w-full md:w-auto items-center gap-4">
                  <span className="flex gap-2 py-2 font-light">
                    Percentage Limit
                  </span>

                  <span>
                    <input
                      value={percentageLimit ? `${percentageLimit}%` : ""}
                      onChange={(e) => {
                        const value = e.target.value.replace("%", "");
                        if (value === "" || /^[0-9]*\.?[0-9]*$/.test(value)) {
                          setPercentageLimit(value);
                        }
                      }}
                      onKeyDown={handleKeyDown}
                      onFocus={handleFocus}
                      onClick={handleCursorPosition}
                      disabled={!toggleEdit}
                      placeholder="0.0%"
                      className={`p-2.5 flex w-20  rounded ${
                        toggleEdit && "border border-primaryColor"
                      }  outline-none justify-center`}
                      type="text"
                    />
                  </span>
                </div>

                <div>
                  {toggleEdit ? (
                    <button
                      onClick={() => {
                        handleRateUpdateDirectFunding(
                          discountRate,
                          percentageLimit,
                          true
                        );
                      }}
                      className="text-white rounded-[5px] bg-primaryColor px-5 py-2.5 text-sm font-light"
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setToggleEdit(!toggleEdit);
                      }}
                      className="text-secondaryColor rounded-[5px] border border-secondaryColor px-5 py-2.5 text-sm font-light"
                    >
                      Edit
                    </button>
                  )}
                </div>
              </div>

              <div className="flex flex-col md:flex-row gap-14 text-sm items-center justifybetween">
                <div className="flex w-full md:w-auto items-center gap-6">
                  <div className="gap-4">
                    <span className="font-semibold text-primaryColor">
                      Without Recourse
                    </span>
                    <span className="flex gap-2 py-2 font-light">
                      Discount Rate
                    </span>
                  </div>

                  <span>
                    <input
                      value={discountRateSub ? `${discountRateSub}%` : ""}
                      onChange={(e) => {
                        const value = e.target.value.replace("%", "");
                        if (value === "" || /^[0-9]*\.?[0-9]*$/.test(value)) {
                          setDiscountRateSub(value);
                        }
                      }}
                      onKeyDown={handleKeyDown}
                      onFocus={handleFocus}
                      onClick={handleCursorPosition}
                      disabled={!toggleEditSub}
                      placeholder="0.0%"
                      className={`p-2.5 flex w-20  rounded ${
                        toggleEditSub && "border border-primaryColor"
                      }  outline-none justify-center`}
                      type="text"
                    />
                  </span>
                </div>

                <div className="flex w-full md:w-auto items-center gap-4">
                  <span className="flex gap-2 py-2 font-light">
                    Percentage Limit
                  </span>

                  <span>
                    <input
                      value={percentageLimitSub ? `${percentageLimitSub}%` : ""}
                      onChange={(e) => {
                        const value = e.target.value.replace("%", "");
                        if (value === "" || /^[0-9]*\.?[0-9]*$/.test(value)) {
                          setPercentageLimitSub(value);
                        }
                      }}
                      onKeyDown={handleKeyDown}
                      onFocus={handleFocus}
                      onClick={handleCursorPosition}
                      disabled={!toggleEditSub}
                      placeholder="0.0%"
                      className={`p-2.5 flex w-20  rounded ${
                        toggleEditSub && "border border-primaryColor"
                      }  outline-none justify-center`}
                      type="text"
                    />
                  </span>
                </div>

                <div>
                  {toggleEditSub ? (
                    <button
                      onClick={() => {
                        handleRateUpdateDirectFunding(
                          discountRateSub,
                          percentageLimitSub,
                          false
                        );
                      }}
                      className="text-white rounded-[5px] bg-primaryColor px-5 py-2.5 text-sm font-light"
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setToggleEditSub(!toggleEditSub);
                      }}
                      className="text-secondaryColor rounded-[5px] border border-secondaryColor px-5 py-2.5 text-sm font-light"
                    >
                      Edit
                    </button>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col  justify-between gap-10">
              <div className="flex pb-6 flex-col md:flex-row gap-14 text-sm items-end">
                <div className="flex w-full md:w-auto items-end  gap-6">
                  <div className="gap-4">
                    <span className="flex gap-2 py-2 font-light">
                      Discount Rate
                    </span>
                  </div>

                  <span className=" items-end">
                    <input
                      value={discountRate ? `${discountRate}%` : ""}
                      onChange={(e) => {
                        const value = e.target.value.replace("%", "");
                        if (value === "" || /^[0-9]*\.?[0-9]*$/.test(value)) {
                          setDiscountRate(value);
                        }
                      }}
                      onKeyDown={handleKeyDown}
                      onFocus={handleFocus}
                      onClick={handleCursorPosition}
                      disabled={!toggleEdit}
                      placeholder="0.0%"
                      className={`p-2.5 flex w-20  rounded ${
                        toggleEdit && "border border-primaryColor"
                      }  outline-none justify-center`}
                      type="text"
                    />
                  </span>
                </div>

                <div className="flex w-full md:w-auto items-center gap-4">
                  <span className="flex gap-2 py-2 font-light">
                    Percentage Limit
                  </span>

                  <span>
                    <input
                      value={percentageLimit ? `${percentageLimit}%` : ""}
                      onChange={(e) => {
                        const value = e.target.value.replace("%", "");
                        if (value === "" || /^[0-9]*\.?[0-9]*$/.test(value)) {
                          setPercentageLimit(value);
                        }
                      }}
                      onKeyDown={handleKeyDown}
                      onFocus={handleFocus}
                      onClick={handleCursorPosition}
                      disabled={!toggleEdit}
                      placeholder="0.0%"
                      className={`p-2.5 flex w-20  rounded ${
                        toggleEdit && "border border-primaryColor"
                      }  outline-none justify-center`}
                      type="text"
                    />
                  </span>
                </div>

                <div>
                  {toggleEdit ? (
                    <button
                      onClick={() => {
                        handleDiscountAndPercentageUpdate();
                      }}
                      className="text-white rounded-[5px] bg-primaryColor px-5 py-2.5 text-sm font-light"
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setToggleEdit(!toggleEdit);
                      }}
                      className="text-secondaryColor rounded-[5px] border border-secondaryColor px-5 py-2.5 text-sm font-light"
                    >
                      Edit
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="flex md:flex-row justify-between flex-row-reverse">
          <motion.div whileTap={{ scale: 0.95 }} className="flex items-center">
            <button
              onClick={() => setCreateNewRuleModal(true)}
              className="px-6 gap-1.5 text-sm border-[0.5px] border-[#ccc] rounded-[5px] text-white py-3 flex justify-center items-center bg-brilliantYellow transform transition duration-500"
            >
              <PAIconAddRole />
              <span className="hidden md:inline-block ">Create new rule</span>
            </button>
          </motion.div>

          <div>
            <div>
              <RulesConfigSearchInput
                onSearch={handleSearch}
                searchBy={searchBy}
                setSearchBy={setSearchBy}
                setSearchQuery={setSearchQuery}
                searchQuery={searchQuery}
              />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default RulesConfigHeader;
