import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Card from "shared/Card";
import { motion } from "framer-motion";
import CocaCola from "../../../assets/images/svgs/cocacola.svg";
import { useEffect, useState } from "react";
import { cancelDirectFundingRequest, getDirectFundingDetails } from "appstate/api/invoice/invoice";
import EarlyPaymentConfirmation from "../fundingSection/earlyPaymentRequest/singlerequest/Modal/EarlyPaymentConfirmation";
import toast from "react-hot-toast";

const SupplierBulkDirectFundingDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [directFundingData, setDirectFundingData] = useState(null);
  // const [totalRequestedAmount, setTotalRequestedAmount] = useState(null);
  // const [reference, setReference] = useState(null);
  const { state } = location?.state || {};
   // eslint-disable-next-line
   const [error, setError] = useState(null);
   // eslint-disable-next-line
   const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDirectFundingDetails = async () => {
      try {
        const result = await getDirectFundingDetails(id);
        setDirectFundingData(result);
      } catch (error) {
        setError(error.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchDirectFundingDetails();
    }
  }, [id]);

  const handleCancelRequest = async () => {
    try {
      setLoading(true);
      await cancelDirectFundingRequest(id);
      toast.success("Direct funding request canceled successfully!");
      navigate("/supplier-early-payments");
    } catch (error) {
      toast.error(error?.response?.data?.message || "Failed to cancel request!");
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  function handleNavigate() {
    let path = -1;
    navigate(path);
  }

  const handleConfirmModal = () => {
    setOpen(true);
  };

  const totalInvoiceAmount = directFundingData?.invoice?.reduce(
    (total, invoice) => total + (invoice?.invoiceAmount || 0),
    0
  );

  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      {open && (
        <div className="absolute mt-[20px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[614px] h-[254px] z-10 rounded-[10px] shadow-lg">
          <EarlyPaymentConfirmation
            hide={() => setOpen(false)}
            // isLoading={earlyPaymentLoading}
            handleAssociate={handleCancelRequest}
            earlyPayment={true}
            invoice={true}
            warning={
              <>
               Are you sure you want to cancel this request?
              </>
            }
          />
        </div>
      )}
      <header>
        <div
          onClick={handleNavigate}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>
      </header>
      <div className="flex justify-between">
        <Card className="w-[60%] p-[24px] rounded-[10px]">
          <div className=" flex  flex-col gap-3 ">
            <main className=" w-full flex justify-between">
              <div className="flex flex-col gap-3 w-1/2">
                <div className="flex">
                  <span>Reference No:</span>
                  <span className="pl-2">|{directFundingData?.reference}</span>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[107px]">Request Date:</span>
                    <span className="text-[#979797]  text-xs">
                    {directFundingData?.newRequestedDate}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[107px]">
                      Total Request Amount:
                    </span>
                    <span className="text-[#979797]  text-xs">
                    {directFundingData?.newRequestedAmount}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[107px]">
                      Total Discount Amount:
                    </span>
                    <span className="text-[#979797]  text-xs">
                    {directFundingData?.invoice[0]?.discountAmount}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[107px]">
                      Total Invoice Amount:
                    </span>
                    <span className="text-[#979797]  text-xs">
                    {totalInvoiceAmount?.toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>

              {directFundingData?.status === "REJECTED" && (
                <div className="flex flex-col justify-between">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    className="flex bg-[#FAD9D9] text-sm justify-center gap-2 text-[#DE1515] rounded-[5px] items-center px-[35px] py-1.5 "
                  >
                    {directFundingData?.status}
                  </motion.button>
                </div>
              )}

              {directFundingData?.status === "APPROVED" && (
                <div className="flex flex-col justify-between">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    className="flex bg-[#D9EFD6] text-sm justify-center gap-2 text-[#59BB50] rounded-[5px] items-center px-[35px] py-1.5 "
                  >
                    {directFundingData?.status}
                  </motion.button>
                </div>
              )}

              {directFundingData?.status === "PROCEESSING" && (
                <div className="flex flex-col justify-between">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    className="flex bg-[#C7D7FF] text-sm justify-center gap-2 text-[#6994FF] rounded-[5px] items-center px-[35px] py-1.5 "
                  >
                    {directFundingData?.status}
                  </motion.button>
                </div>
              )}

              {directFundingData?.status === "PENDING_CONFIRMATION" && (
                <div className="flex flex-col justify-between">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    className="flex bg-[#FFBE5557] text-sm justify-center gap-2 text-[#F08000] rounded-[5px] items-center px-[35px] py-1.5 "
                  >
                    {directFundingData?.status}
                    {/* <PAIconLockYellow /> */}
                  </motion.button>
                </div>
              )}

              {directFundingData?.status === "PENDING_APPROVAL" && (
                <div className="flex flex-col justify-between">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    className="flex bg-[#FFBE5557] text-sm justify-center gap-2 text-[#F08000] rounded-[5px] items-center px-[35px] py-1.5 "
                  >
                    {directFundingData?.status}
                    {/* <PAIconLockYellow /> */}
                  </motion.button>
                </div>
              )}
            </main>
          </div>
          <br />
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="font-light  text-xs">{directFundingData?.funderCompanyName}</span>
              <span className="text-[#979797] text-sm">buyer details</span>
            </div>
            <div className="flex flex-col">
              <span className="font-light   text-xs">60 Days</span>
              <span className="text-[#979797] text-sm">Payment</span>
            </div>
            <div className="flex flex-col">
              <span className="text-[#979797] flex justify-end text-xs">
              {directFundingData?.invoice[0]?.currency}
              </span>
              <span className="font-light text-sm">Currency</span>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3"></header>
            <div className="flex  justify-between">
              <div className="flex gap-2"></div>
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-[#979797]  text-xs">{directFundingData?.invoice?.length} Invoices</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm">Invoice List</h1>
            </header>
            <div className=" w-full overflow-x-auto">
              <table className=" w-full overflow-x-auto">
                <thead className="bg-subtleGray w-">
                  <tr className=" bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      Ref No
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Due Date</td>
                    <td className=" p-2.5 text-xs font-bold">Request Amount</td>
                    <td className=" p-2.5 text-xs font-bold">Discount Rate</td>
                    <td className=" p-2.5 text-xs font-bold">
                      Receivable Amount
                    </td>
                  </tr>
                </thead>
                 {directFundingData?.invoice?.map((item, index) => (
                  <tbody key={index}>
                    <tr className=" py-[3px] px-1.5">
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {directFundingData?.reference}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.dueDate}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {directFundingData?.newRequestedAmount}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.discount}%
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {directFundingData?.receivableAmount}
                      </td>
                    </tr>
                  </tbody>
                ))} 
              </table>
            </div>
          </div>
        </Card>
        <Card className="w-[38%] p-[24px] rounded-[10px]">
          <div>
            <div className="flex justify-between">
              <span className="font-bold text-[#000000]">
                Direct Funding Details
              </span>
             
                {directFundingData?.status === "PENDING_CONFIRMATION" && <div className="flex flex-col justify-between">
                  <motion.button
                    onClick={handleConfirmModal}
                    whileTap={{ scale: 0.9 }}
                    className="flex bg-[#BB0C0C] text-sm justify-center gap-2 text-[white] rounded-[5px] items-center px-[35px] py-1.5 "
                  >
                    Cancel Request
                    {/* <PAIconLockYellow /> */}
                  </motion.button>
                </div>}
              
            </div>
          </div>
          <div>
            <br />
            <div className="flex justify-between">
              <div className="flex">
                <div>
                  <img src={CocaCola} alt="" />
                </div>
                <div style={{ lineHeight: 1 }} className="flex flex-col pl-2">
                  <span>{directFundingData?.funderCompanyName}</span>
                  <span className="text-[#959595]">buyer</span>
                </div>
              </div>
              <div>
                {/* <div style={{ lineHeight: 1 }} className="flex flex-col pl-2">
                  <span className="flex justify-end">1.5%</span>
                  <span className="text-[#959595]">Discount rate</span>
                </div> */}
              </div>
            </div>
            <br />
            <div className="flex justify-between">
              <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                <span>₦{directFundingData?.invoice[0]?.discountAmount}</span>
                <span className="text-[#959595]">Discount Amount</span>
              </div>
              <div>
                <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                  <span>23-Dec-2024(20 days)</span>
                  <span className="flex justify-end text-[#959595]">
                    Early Payment Date
                  </span>
                </div>
              </div>
            </div>
            <br />
            <div className="flex justify-between">
              <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                <span>₦{directFundingData?.newRequestedAmount}</span>
                <span className="text-[#959595]">Total Request Amount</span>
              </div>
              <div>
                <div style={{ lineHeight: 1.3 }} className="flex flex-col pl-2">
                  <span className="flex justify-end">₦{directFundingData?.receivableAmount}</span>
                  <span className="text-[#959595]">
                    Total Receivable Amount
                  </span>
                </div>
              </div>
            </div>
          </div>
          <br />
          {state === "Rejected" ? null : (
            <div className="flex flex-col">
              <span className="font-bold text-[#000000]">
                Notes from supplier:
              </span>
              <span className="text-[#959595]">
              {directFundingData?.noteFromSupplier}
              </span>
            </div>
          )}
          <br />
          {state === "Rejected" && <hr className="text-[#959595]" />}
          <br />
          {state === "Rejected" && (
            <div className="flex flex-col">
              <span className="font-bold text-[#D40606]">
                Reason for rejection
              </span>
              <span className="text-[#959595]">
                You rejected this Invoice based on Unreliable History reasons
              </span>
            </div>
          )}
        </Card>
      </div>
    </DashboardLayout>
  );
};
export default SupplierBulkDirectFundingDetails;
