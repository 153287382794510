import DashboardLayout from "layout/dashboardlayout";
import DetailsBulk from "../components/carddetails/DirectPaymentRequestDetailBulk";

const DirectEarlyPaymentRequestsBulkDetailContainer = () => {
  return (
    <>
      <DashboardLayout
        section={"Closed Market"}
        subHeader={"Manage your invoices on the closed market"}
      >
        <DetailsBulk />
      </DashboardLayout>
    </>
  );
};

export default DirectEarlyPaymentRequestsBulkDetailContainer;
