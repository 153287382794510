import React, { useState, useRef, useEffect } from "react";
import { approveDirectFundingRequest } from "appstate/funder/funderSlice";
import { PAIconCancelMini } from "assets/images/svgs";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";

const BidPinModal = ({
  isOpen,
  onClose,
  handleNext,
  checkedAccount,
  isBulk,
}) => {
  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
  const {
    funderEarlyPaymentViewBulkPendingDetailsData,
    funderEarlyPaymentViewSinglePendingDetailsData,
  } = useSelector((state) => state?.funder);
  const dispatch = useDispatch();
  const { invoiceIdData } = useSelector((state) => state.invoice);
  const [data, setData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const isDisabled = otpInputs[otpInputs.length - 1] === "";
  useEffect(() => {
    setInvoiceData(invoiceIdData?.data);
  }, [invoiceIdData]);
  const inputRefs = useRef([]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, otpInputs.length);
  }, [otpInputs.length]);

  const handleInputChange = (index, value) => {
    if (!isNaN(value) || value === "") {
      const newOtpInputs = [...otpInputs];
      newOtpInputs[index] = value;
      setOtpInputs(newOtpInputs);

      if (value !== "" && index < otpInputs.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  useEffect(() => {
    if (isBulk) {
      setData(funderEarlyPaymentViewBulkPendingDetailsData?.data);
    } else {
      setData(funderEarlyPaymentViewSinglePendingDetailsData?.data);
    }
  }, [
    funderEarlyPaymentViewBulkPendingDetailsData,
    funderEarlyPaymentViewSinglePendingDetailsData,
    isBulk,
  ]);

  // const funderbodySingle = {
  //   earlyPaymentId: data?.earlyPaymentId,
  //   directFundingId: data?.directFundingId,
  //   virtualAccountId: checkedAccount?.id,
  //   invoiceAmount: invoiceData?.grandTotal,
  //   payableAmount: data?.fundableAmount,
  //   requestedAmount: data?.requestAmount,
  //   fundableAmount: data?.fundableAmount,
  //   discountAmount: data?.discountAmount,
  //   transactionPin: otpInputs.join(""),
  //   buyerId: data?.userId,
  // };

  const funderbodyBulk = {
    earlyPaymentId: data?.earlyPaymentId,
    directFundingId: data?.directFundingId,
    virtualAccountId: checkedAccount?.id,
    invoiceAmount: invoiceData?.grandTotal,
    payableAmount: data?.fundableAmount || data?.totalFundableAmount,
    requestedAmount: data?.requestAmount || data?.totalRequestAmount,
    fundableAmount: data?.fundableAmount || data?.totalFundableAmount,
    discountAmount: data?.discountAmount || data?.totalDiscountAmount,
    transactionPin: otpInputs.join(""),
    buyerId: invoiceData?.buyerId || data?.buyerId,
  };

  

  const handleFundingApproval = async () => {
    try {
      const response = await dispatch(
        approveDirectFundingRequest(funderbodyBulk)
      ).unwrap();
      if (response?.status === 200) {
        handleNext();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otpInputs[index] === "") {
      const newOtpInputs = [...otpInputs];
      newOtpInputs[index - 1] = "";
      setOtpInputs(newOtpInputs);

      inputRefs.current[index - 1].focus();
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-[10px] z-[1000]">
        <div className="bg-white p10 px5 rounded-md max-w-xs md:max-w-lg w-full pb-5 px10">
          <div className="flex justify-between items-center py-5 px-6 rounded-t-[10px]  bg-[#FAFAFA]">
            <p className="text-sm text-black font-thin">Enter Pin</p>
            <button
              onClick={onClose}
              className="gap-2 text-xl cursor-pointer flex justify-center items-center"
            >
              <PAIconCancelMini />{" "}
              <span className="text-sm text-[#565656] flex items-center">
                Close
              </span>
            </button>
          </div>

          {/* BVN Form */}
          <form
            className="w-full px-7 pt-7"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <p className="mb-5 flex justify-center text-black  text-base font-semibold  italic">
              Enter your PIN
            </p>

            <div className="mb-5 flex flex-row justify-center gap-2">
              {otpInputs.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  value={value.replace(/./g, "*")}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  maxLength="1"
                  className={`border rounded p-2 ${
                    isDisabled
                      ? "border-[#07593D] focus:border-[#07593D]"
                      : "border-[#07593D]"
                  }    focus:outline-none w-10 h-10 flex-shrink-0 text-center font-bold`}
                  ref={(ref) => (inputRefs.current[index] = ref)}
                />
              ))}
            </div>
            <p className="text-[#7A7A7A] text-sm font-normal flex justify-center my-5">
              Enter your transaction PIN to verify your identity
            </p>

            {/* Buttons */}
            <div className="flex gap-4 justify-end mt-10">
              <button
                type="button"
                onClick={onClose}
                className="text-center px-6 py-2.5 rounded-[5px] text-[#7A7A7A] text-base font-normal font-['SF Pro Display'] tracking-wide"
              >
                Cancel
              </button>

              <button
                onClick={handleFundingApproval}
                disabled={isDisabled}
                className={`text-center ${
                  isDisabled
                    ? "bg-[#EDEDED] text-[#7A7A7A]"
                    : "bg-primaryColor text-white"
                }  px-6 py-2.5 rounded-[5px]  text-base font-normal`}
                type="submit"
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BidPinModal;
