import { PAIconArrowLeftGreen } from "assets/images/svgs";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import UpdateBillsModal from "../../modals/UpdateBillsModal";
import OngoingAcceptUpdateBidModal from "../../modals/AccepUpdateBidModal";
import InvoiceLockedModal from "../../modals/InvoiceLockedModal";
import OngoingCancelBidModal from "../../modals/OngoingCancelBidModal";
import OngoingBidCancelledSuccessModal from "../../modals/OngoingBidsCancelSuccessful";
import DashboardLayout from "layout/dashboardlayout";
import MoreInvoiceIdSegment from "./MoreInvoiceIdSegment";
import Table from "layout/table/Table";
import TransitionScale from "shared/animation/TransitionScale";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  fetchAllClosedMarketIngoingBidDetails,
  funderCloseMarketUpdateBid,
} from "appstate/funder/funderSlice";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import toast from "react-hot-toast";

import PinModal from "shared/PinModal";

const OngoingBidBulkDetails = () => {
  const { id } = useParams();
  const [bidPin, setBidPin] = useState("");

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ongoingAcceptUpdateBidModal, setOngoingAcceptUpdateBidModal] =
    useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [ongoingCancelBidModal, setOngoingCancelBidModal] = useState(false);
  const [ongoingBidCancelledSuccessModal, setOngoingBidCancelledSuccessModal] =
    useState(false);
  const goBack = () => {
    navigate(-1);
  };
  const dispatch = useDispatch();
  const handleOpenUpdateBid = () => {
    setIsModalOpen(true);
  };

  const handleUpdateBid = () => {
    setIsModalOpen(false);
    setOngoingAcceptUpdateBidModal(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    // setRejectModalOpen(false);
  };
  const handleInvoiceModalClose = () => {
    setIsInvoiceModalOpen(false);
    goBack();
  };
  const handleOnCancelSuccessClose = () => {
    setOngoingBidCancelledSuccessModal(false);
    goBack();
  };
  const handleOnCancelBidTrue = () => {
    setOngoingCancelBidModal(false);
    setOngoingBidCancelledSuccessModal(true);
  };

  useEffect(() => {
    dispatch(fetchAllClosedMarketIngoingBidDetails({ closeMarketId: id }));
  }, [dispatch, id]);

  const selectedAccounts = useSelector(
    (state) => state?.wallet?.selectedAccounts
  );

  const { ongoingBidDetails } = useSelector((state) => state.funder);

  // Calculate the difference in time

  // Calculate the difference in days

  const [discount, setDiscount] = useState(
    `${ongoingBidDetails?.closeMarketInvoice?.discount}%` || ""
  );

  const handleAccept = () => {
    // handlePinModal();

    setBidPin(true);

    setOngoingAcceptUpdateBidModal(false);
  };

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Ref Number
        </span>
      ),
      selector: (row) => row.invoiceRef,
    },

    {
      name: "Due Date",
      selector: (row) => row.dueDate,
    },
    {
      name: "Request Amount",
      selector: (row) => row.requestAmount,
    },
    {
      name: "Buyer Rate",
      selector: (row) => row.discountRate,
    },
    {
      name: "Discount Amount",
      selector: (row) => row.discountAmount,
    },
    {
      name: "Fundable Amount",
      selector: (row) => row.fundableAmount,
    },
  ];

  // const navigateToDetail = (id) =>
  //   navigate(
  //     `/payablefinancing-closedmarket/funderclosedmarketbuldetailspage/${id}`
  //   );

  const navigateToDetail = (invoiceId) =>
    navigate(
      `/payablefinancing-closedmarket/funderclosedmarketbuldetailspage/${invoiceId}`
    );

  const handlePinModal = (pin) => {
    // setBidPin(false);  closedMarketRequestDetails
    const pendingToast = toast.loading("Updating your Bid...");

    //virtualAccount

    const bidBody = {
      amount: ongoingBidDetails?.closedMarketRequestDetails?.requestAmount,
      discountRate: parseFloat(discount),
      note: "note",
      virtualAccountId: selectedAccounts?.id || "",
      transactionPin: pin,
    };

    /*
      {
    "amount": 0,
    "discountRate": 0,
    "note": "string",
    "virtualAccountId": "string",
    "transactionPin": "string"
  }
  
      */

    // console.log("===BODY===", bidBody);

    //funderCloseMarketUpdateBid

    dispatch(
      funderCloseMarketUpdateBid({ closeMarketId: id, body: bidBody })
    ).then((data) => {
      if (data?.payload?.status === 200) {
        setIsInvoiceModalOpen(true);
        setBidPin(false);
      }
      if (data?.payload?.success) {
        toast.dismiss(pendingToast);
        // setBidPin(false);
        // setBidLoaderModal(true);
        // dispatch(funderFetchAllClosedMarketByStatus());
        // setDiscountRate("");
      } else {
        toast.dismiss(pendingToast);
        toast.error(data?.payload?.message);
        // console.log("ERROR====", data);
        // setDiscountRate("");
      }
    });
  };

  const leadingBid = ongoingBidDetails?.closeMarketBidHistory?.data?.filter(
    (elem) => elem.winning === true
  );

  const othersBid = ongoingBidDetails?.closeMarketBidHistory?.data?.filter(
    (elem) => elem.winning !== true
  );

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const paymentDateObj = new Date(
        `${ongoingBidDetails?.closedMarketRequestDetails?.earlyPaymentDate}T00:00:00.000Z`
      );
      const currentDate = new Date();
      const timeDiff = paymentDateObj.getTime() - currentDate.getTime();

      if (timeDiff > 0) {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

        setCountdown({
          days,
          hours,
          minutes,
          seconds,
        });
      } else {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [ongoingBidDetails?.closedMarketRequestDetails?.earlyPaymentDate]);

  return (
    <DashboardLayout
      section="Closed Market"
      subHeader="View invoices uploaded to the closed market by your associated buyers"
    >
      <>
        <UpdateBillsModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          handleYes={handleUpdateBid}
          discountRate={discount || ""}
          handleSetDiscountRate={(e) => setDiscount(e.target.value)}
        />
        <OngoingAcceptUpdateBidModal
          isOpen={ongoingAcceptUpdateBidModal}
          onClose={() => setOngoingAcceptUpdateBidModal(false)}
          refNum={ongoingBidDetails?.closeMarketInvoice?.invoiceRef}
          handleYes={handleAccept}
        />
        <InvoiceLockedModal
          isOpen={isInvoiceModalOpen}
          onClose={handleInvoiceModalClose}
        />

        <PinModal
          isOpen={bidPin}
          onClose={() => {
            setBidPin(false);
          }}
          onSubmit={handlePinModal}
          // isLoading={isLoading}
          headerText="Enter Pin"
          instructionText="Enter your transaction PIN to verify your identity."
        />
        <OngoingCancelBidModal
          isOpen={ongoingCancelBidModal}
          onClose={() => setOngoingCancelBidModal(false)}
          refNum={"R3456X89Y"}
          handleYes={handleOnCancelBidTrue}
        />
        <OngoingBidCancelledSuccessModal
          isOpen={ongoingBidCancelledSuccessModal}
          onClose={handleOnCancelSuccessClose}
          message={"Your bid has been cancelled"}
        />
        <Link
          onClick={goBack}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
        >
          <PAIconArrowLeftGreen />
          <span className="flex items-center text-primaryColor">Go Back</span>
        </Link>

        <div className="grid lg:grid-cols-[60%,40%] gap-5">
          <TransitionScale className=" pb-5 bg-white  rounded-[10px]  pt-2">
            {/* Header with Left Padding */}

            {/* Other sections */}

            <section className="  mt-3">
              {/* Down Flex Texts */}
              <div className=" flex justify-between px-6  mt-4">
                {" "}
                <div class="text-[#222222] text-lg font-medium ">
                  Reference No: |{" "}
                  {ongoingBidDetails?.closeMarketInvoice?.invoiceRef || "N/A"}
                </div>
                {/* <div class="h-[37px] p-2.5 rounded-[5px] border border-[#f08000]/20 justify-center items-center gap-2.5 inline-flex"> */}
                <h1
                  className={`rounded-[5px] text-sm font-medium p-2.5 ${
                    ongoingBidDetails?.closedMarketRequestDetails
                      ?.closedMarketStatus === "POSTED"
                      ? "text-primaryColor bg-white border border-primaryColor/20"
                      : "text-secondaryColor bg-white border border-secondaryColor/20"
                  } `}
                >
                  {" "}
                  {formatCapitaliseString(
                    ongoingBidDetails?.closedMarketRequestDetails
                      ?.closedMarketStatus
                  )}
                </h1>
                {/* </div> */}
              </div>
              <div className=" flex justify-between px-6">
                {/* first stage */}

                <div class="lg:w-[240px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex  mt-5">
                  <div class="self-stretch  flex-col justify-center items-start gap-1 flex ">
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Created Date:
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {ongoingBidDetails?.invoiceSummary?.createdDate ||
                          "N/A"}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex ">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Due Date:
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {ongoingBidDetails?.invoiceSummary?.dueDate || "N/A"}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Total Request amount
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {ongoingBidDetails?.invoiceSummary
                          ?.totalRequestAmount || "N/A"}
                      </div>
                    </div>

                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Total Invoice amount
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {ongoingBidDetails?.invoiceSummary
                          ?.totalInvoiceAmount || "N/A"}
                      </div>
                    </div>
                  </div>

                  {/* stop */}
                </div>
                {/* second stage */}

                <div className=" mt-5">
                  {" "}
                  <div class="w-[107px] flex-col justify-end items-end gap-1 inline-flex">
                    <div class="text-right text-[#222222] text-base font-bold ">
                      {ongoingBidDetails?.invoiceSummary?.totalRequestAmount ||
                        "N/A"}
                    </div>
                    <div class="text-[#979797] text-xs font-normal ">
                      Total request amount
                    </div>
                  </div>
                </div>
              </div>

              {/* stops here */}
              <div className="  px-6  ">
                <header className="border-b-[5px] border-b-[#F6F5FA] mt-3  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                  <div>
                    <div class="text-[#222222] text-lg font-semibold  ">
                      {
                        ongoingBidDetails?.closedMarketRequestDetails
                          ?.buyerCompanyName
                      }
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-light  ">
                      Buyer details
                    </div>
                  </div>

                  <div class="h-[41px] flex-col justify-start items-start  inline-flex px-12">
                    <div class="text-[#222222] text-base font-semibold ">
                      {ongoingBidDetails?.closeMarketInvoice?.paymentTerms} Days
                    </div>
                    <div class="w-[100px] text-[#7a7a7a] text-xs font-light  mt-1">
                      Payment
                    </div>
                  </div>

                  <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                    <div class="text-[#222222] text-lg font-semibold ">
                      {" "}
                      {ongoingBidDetails?.invoiceSummary?.currency || "N/A"}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                      Currency
                    </div>
                  </div>
                </header>

                <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                  <div class="text-[#11192a] text-sm font-semibold ">
                    Invoice List
                  </div>
                  <div class="text-[#11192a] text-lg font-light  mr-6">
                    {ongoingBidDetails?.bulkInvoice?.data?.length} Invoices
                  </div>
                </header>

                {/* <div className=" w-full overflow-x-auto mt-3 pb-4"></div> */}

                <div className=" w-full overflow-x-auto mt-3 ">
                  <Table
                    columns={columns}
                    data={ongoingBidDetails?.bulkInvoice?.data}
                    pointer
                    className=""
                    onRowClicked={(row) => navigateToDetail(row?.invoiceId)}
                    progressPending={false}
                    // progressComponent={<Loading />}
                  />
                </div>
                {/* Calculate cost and price  shaded or gray section */}
              </div>
            </section>
          </TransitionScale>

          <div className="bg-white rounded-[10px] gap-8 ">
            {/* <FundDetailSide /> */}
            {/* second */}
            <div className=" py-8 px-6 border-b border-[#c1c1c1]/60">
              <div className=" flex flex-row justify-between w-full">
                <div class="text-black text-xl font-medium ">Bid Details</div>

                <div className="flex items-start justify-center gap-2 text-center  text-base font-bold text-gray-800 mb-2">
                  <div>
                    <p>{countdown.days}</p>
                    <p className="text-[12px] text-[#777777]">days</p>{" "}
                  </div>
                  <div>
                    <p>{countdown.hours}</p>
                    <p className="text-[12px] text-[#777777]">hours</p>
                  </div>
                  :
                  <div>
                    <p>{countdown.minutes}</p>
                    <p className="text-[12px] text-[#777777]">minutes</p>
                  </div>{" "}
                  :
                  <div>
                    <p>{countdown.seconds}</p>
                    <p className="text-[12px] text-[#777777]">seconds</p>{" "}
                  </div>{" "}
                </div>
              </div>

              {ongoingBidDetails?.closedMarketRequestDetails
                ?.closedMarketStatus !== "BID_ACTIVE" && (
                <button
                  class="h-[47px] px-2.5 py-[15px] bg-[#07593d] rounded-[5px] justify-center items-center gap-2.5 inline-flex mt-8 w-full cursor-pointer"
                  onClick={handleOpenUpdateBid}
                >
                  <div class="text-white text-base font-medium ">
                    Update Bid
                  </div>
                </button>
              )}
            </div>

            {/* Last section */}
            <div className="py-8  gap-8 flex flex-col ">
              {/*  Winning Bid section */}
              {/* Your bid seCTION */}
              <MoreInvoiceIdSegment
                details={ongoingBidDetails?.closeMarketInvoice}
                closedMarketRequestDetails={
                  ongoingBidDetails?.closedMarketRequestDetails
                }
                currentDa={leadingBid}
                otherBids={othersBid}
              />
              {/* stop it */}
            </div>

            {/* search section */}

            {/* stops here */}
          </div>
        </div>
      </>
    </DashboardLayout>
  );
};

export default OngoingBidBulkDetails;
