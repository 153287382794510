import { AnimatePresence, motion } from "framer-motion";
import {
  PAIconArrowLeftGreen,
  PAIconIconParkSucess,
  PAIconMDISuccess,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect, useState } from "react";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import toast from "react-hot-toast";
import OfferDetailsModal from "./modal/OfferDetailsModal";
import InvoiceLockSuccessModal from "./modal/InvoiceLockSuccessModal";
import { useDispatch, useSelector } from "react-redux";
import {
  bidHistory,
  detailsBuyerOpenMarket,
  funderUpdateOpenMarketBid,
  placeInvoiceBid,
  updateInvoice,
  // getInvoiceBidByStatus,
} from "appstate/openMarket/openMarketSlice";
import { toggleCancelButtonOff } from "./buttonSlice";
import InvoiceDetails from "features/openMarket/common/components/InvoiceDetails";
// import ViewMoreBidders from "features/openMarket/supplier/components/ViewMoreBidders";
// import CardLoading from "./cards/CardLoading";
// import TransitionOpacity from "shared/animation/TransitionOpacity";
// import { timeAgo } from "utilities/helper/timeAgo";
import VerifyLien from "./modal/VerifyLienModal";
import Loading from "shared/Loading";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import { formatDate } from "utilities/helper/dateFormatterSpelt";
import { formatCurrency } from "utilities/helper/formatters";
import useCountdown from "utilities/helper/useCountdown";

function OngoingBidInvoiceDetails() {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const openMarketId = searchParams.get("openMarketId");

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const buttonClicked = useSelector(
    (state) => state?.invoiceButton?.buttonClicked
  );

  useEffect(() => {
    dispatch(detailsBuyerOpenMarket({ openMarketReference: id }));
  }, [dispatch, id]);

  const { detailsBuyerOpenMarketData, bidHistoryData } = useSelector(
    (state) => state?.openMarket
  );

  // console.log(detailsBuyerOpenMarketData);

  const { userDetails } = useSelector((state) => state?.auth);
  // console.log(userDetails);

  const [page] = useState(0);
  const pageSize = 5;

  const [bidAmount, setBidAmount] = useState("");
  const [discountRate, setDiscountRate] = useState("");
  const [noteToSupplier, setNoteToSupplier] = useState("");

  const [bidModalOpen, setBidModalOpen] = useState(false);

  const [offerDetailModalOpen, setOfferDetailModalOpen] = useState(false);
  const [bidRequest, setBidRequest] = useState(false);
  const [verifyLienStatusModal, setVerifyLienStatusModal] = useState(false);
  const [invoiceLockedSuccessModal, setInvoiceLockedSuccessModal] =
    useState(false);
  const [activeTab, setActiveTab] = useState("bidHistory");
  const [sortedBidHistory, setSortedBidHistory] = useState([]);

  function handleGoBack() {
    dispatch(toggleCancelButtonOff());
    navigate(-1); // Navigate back one step
  }

  // Handle Bid
  const handleBid = () => {
    const body = { amount: bidAmount, discountRate, note: noteToSupplier };
    dispatch(placeInvoiceBid({ id, body })).then((data) => {
      if (data?.payload?.success) {
        toast.success("Your bid has been sent succesfully");
        setBidAmount("");
        setDiscountRate("");
        setNoteToSupplier("");
      }
    });
  };

  // Handle Update Detail
  const handleUpdate = () => {
    const body = { amount: bidAmount, discountRate, note: noteToSupplier };
    dispatch(updateInvoice({ id: openMarketId, body })).then((res) => {
      if (res?.payload?.success) {
        toast.success("Your bid has been updated succesfully");
        setBidAmount("");
        setDiscountRate("");
        setNoteToSupplier("");
      }
    });
  };

  const handleAction = () => {
    const body = {
      discountRate,
      openMarketId,
    };

    dispatch(funderUpdateOpenMarketBid(body)).then((data) => {
      const pendingToast = toast.loading("Updating Bid Request...");
      if (data?.payload?.success) {
        toast.dismiss(pendingToast);
        toast.success("Bid Request Updated Successfully");

        setOfferDetailModalOpen(false);
        setBidRequest(false);
        handleProceedToSelectAccount();
      } else {
        toast.dismiss(pendingToast);
        if (data?.payload?.status === 200) {
          toast.error(data?.payload?.message);
        }
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setOfferDetailModalOpen(true);

    // handleAction();
  };

  const handleProceedToSelectAccount = () => {
    setBidRequest(false);
    setTimeout(() => {
      setInvoiceLockedSuccessModal(true);
      dispatch(bidHistory({ openMarketReference: id, page, pageSize }));
    }, 500);
  };

  useEffect(() => {
    dispatch(bidHistory({ openMarketReference: id, page, pageSize }));
  }, [dispatch, id, page, pageSize]);

  useEffect(() => {
    if (bidHistoryData?.bidHistory?.data) {
      // Create a shallow copy of the bid history and sort it
      const sortedData = [...bidHistoryData?.bidHistory?.data]?.sort(
        (a, b) => b?.isLeading - a?.isLeading
      );
      setSortedBidHistory(sortedData);
    }
  }, [bidHistoryData]);

  const [cancelBid, setCancelBid] = useState(false);

  const [showCancelButton, setShowCancelButton] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowCancelButton(false);
    }, 5000);
  }, []);

  const { timeLeft, isTimeUnderOneHour } = useCountdown(
    formatDate(detailsBuyerOpenMarketData?.data?.paymentDate)
  );

  function handleDownloadSuccessful() {
    setCancelBid(false);

    toast.custom(
      <>
        <div className="flex-1 items-center justify-center flex w-full h-full">
          <div className="flex mt-[300px]">
            <div className="bg-primaryColor w-[147.79px] h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
              <PAIconMDISuccess />
            </div>
            <div className="bg-white  flex-col justify-center items-center self-stretch py-[25px] h-[160px] rounded-r-[7px] shadow-lg flex px-16 ">
              <PAIconIconParkSucess />
              <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
                Your bid has been Cancelled
              </p>
            </div>
          </div>
        </div>
      </>
    );

    setTimeout(() => {
      toast.dismiss();
      navigate(-1);
    }, 500);
  }

  return (
    <DashboardLayout subHeader="Here is what is happening in your finance">
      <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
        <header onClick={handleGoBack} className="w-full">
          <div className="flex cursor-pointer w-fit gap-1 items-center">
            <PAIconArrowLeftGreen />
            <span className=" text-primaryColor text-sm">Go back</span>
          </div>
        </header>

        <main className="flex flex-col semi-lg:flex-row pb-8 gap-8 w-full ">
          <InvoiceDetails
            invoiceByIdData={detailsBuyerOpenMarketData}
            // setLockModalOpen={setLockModalOpen}
          />

          <motion.section
            initial={{
              y: -20,
            }}
            animate={{
              y: 0,
            }}
            exit={{
              y: 20,
            }}
            transition={{
              delay: 0.8,
            }}
            className="flex flex-col gap-3 px- pt-7 pb-24 w-full semi-lg:w-2/5 bg-white rounded-[10px]"
          >
            <header className="flex justify-between pb-2 px-6">
              <span className="font-medium">Bid Details</span>

              <span>
                <section
                  className={`flex ${isTimeUnderOneHour ? "text-red" : ""}`}
                >
                  <div className="flex">
                    <div className="flex items-center justify-center flex-col">
                      <span className="font-semibold">{timeLeft.days}</span>
                      <span className="text-xs">days</span>
                    </div>
                    <span>:</span>
                  </div>

                  <div className="flex">
                    <div className="flex items-center justify-center flex-col">
                      <span className="font-semibold">{timeLeft.hours}</span>
                      <span className="text-xs">hours</span>
                    </div>
                    <span>:</span>
                  </div>

                  <div className="flex gap-0">
                    <div className="flex items-center justify-center flex-col">
                      <span className="font-semibold">{timeLeft.minutes}</span>
                      <span className="text-xs">mins</span>
                    </div>
                    <span>:</span>
                  </div>

                  <div className="flex">
                    <div className="flex  items-center justify-center flex-col">
                      <span className="font-semibold">{timeLeft.seconds}</span>
                      <span className="text-xs">secs</span>
                    </div>
                  </div>
                </section>
              </span>
            </header>

            <motion.button
              whileTap={{ scale: 0.96 }}
              className="p-2.5 rounded-[5px] bg-primaryColor text-white mx-6"
              onClick={handleSubmit}
            >
              Update Bid
            </motion.button>
            <div className="border-t border-gray"></div>
            <div className="w-full ">
              <div className="mt-3 flex justifycenter px-6">
                <div className="flex space-x-4">
                  {/* Bid History Tab */}
                  <button
                    onClick={() => setActiveTab("bidHistory")}
                    className={`text-gray600 ${
                      activeTab === "bidHistory"
                        ? "border-b border-secondaryColor text-secondaryColor"
                        : ""
                    } font-medium text-sm`}
                  >
                    Bid History
                  </button>
                  {/* Request Details Tab */}
                  <button
                    onClick={() => setActiveTab("requestDetails")}
                    className={`pb2 text-gray600 ${
                      activeTab === "requestDetails"
                        ? "border-b border-secondaryColor text-secondaryColor"
                        : ""
                    } font-medium text-sm`}
                  >
                    Request Details
                  </button>
                </div>
              </div>
              <div className="mt-3">
                {activeTab === "bidHistory" &&
                  sortedBidHistory?.map((bid, index) => (
                    <div key={index} className="relative">
                      <div
                        className={`flex justify-between px-6 pb-1 my-6 ${
                          bid?.isLeading
                            ? "bg-[#F1F9FF] border-b-[2px] border-r-[2px] border-l-[2px] border-t-[2px] rounded border-primaryColor"
                            : !bid?.isLeading &&
                              userDetails?.data?.companyName ===
                                bid?.funderCompanyName
                            ? "border-[2px] rounded border-secondaryColor/50"
                            : ""
                        } ${
                          bid?.isLeading
                            ? "transition-opacity duration-300"
                            : ""
                        } ${
                          bid?.isLeading && showCancelButton
                            ? "opacity-50"
                            : "opacity-100"
                        }`}
                      >
                        {bid?.isLeading && (
                          <div
                            className={`${
                              userDetails?.data?.companyName ===
                              bid?.funderCompanyName
                                ? "text-primaryColor"
                                : ""
                            } absolute top-[-10px] left-4 text-xs font-semibold bg-white px-2`}
                          >
                            {userDetails?.data?.companyName ===
                            bid?.funderCompanyName
                              ? "Your bid is the winning bid"
                              : "Winning bid"}
                          </div>
                        )}

                        {!bid?.isLeading &&
                          userDetails?.data?.companyName ===
                            bid?.funderCompanyName && (
                            <div
                              className={`absolute top-[-10px] left-4 text-xs font-semibold bg-white px-2`}
                            >
                              Your bid
                            </div>
                          )}
                        <div className="flex gap-6 items-center w-[30%] mt-3">
                          <div className="flex flex-col gap-0.5">
                            <span
                              className={`text-xs font-[500] ${
                                bid.win ? "text-primaryColor" : ""
                              }`}
                            >
                              {bid?.funderName}
                            </span>
                            <span className="text-[10px] text-[#959595]">
                              Funder
                            </span>
                          </div>
                        </div>
                        <div className="flex gap-6 items-center w-[25%] mt-3">
                          <div className="flex flex-col gap-0.5">
                            <span className="text-xs font-[500]">
                              {bid?.discountRate}% {" - "}
                              <span
                                className={`text-[8px] ${
                                  bid?.discountType === "WITHOUT_RECOURSE"
                                    ? "text-primaryColor"
                                    : "text-secondaryColor"
                                }`}
                              >
                                {formatCapitaliseString(bid?.discountType)}
                              </span>
                            </span>
                            <span className="text-[10px] text-[#959595]">
                              Discount Rate
                            </span>
                          </div>
                        </div>
                        <div className="flex gap-6 items-center w-[20%] mt-3">
                          <div className="flex flex-col gap-0.5">
                            <span
                              className={`text-xs font-[500] ${
                                bid?.status === "LOCK"
                                  ? "text-secondaryColor"
                                  : "text-black"
                              } ${bid?.isLeading ? "font-bold" : ""}`}
                            >
                              {formatCapitaliseString(bid?.status)}ed
                            </span>
                            <span className="text-[10px] text-[#959595]">
                              Status
                            </span>
                          </div>
                        </div>
                        <div className="flex gap-6 items-center w-[20%] mt-3">
                          <div className="flex flex-col gap-0.5">
                            <span className="text-xs font-[500]">
                              {bid?.lockDuration ?? "N/A"}
                            </span>
                            <span className="text-[10px] text-[#959595]">
                              Bidding Time
                            </span>
                          </div>
                        </div>
                      </div>
                      {bid?.isLeading && showCancelButton && (
                        <>
                          <div className="absolute inset-0 flex items-center justify-center z-20">
                            <div className="transform scale-50">
                              <Loading />
                            </div>
                          </div>
                          <AnimatePresence>
                            <motion.div
                              initial={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              onClick={() => setCancelBid(true)}
                              className="absolute top-0 right-0 m-2 flex items-center bg-white text-[8px] text-red border-[1.5px] border-red py-1 px-2 rounded cursor-pointer z-30"
                            >
                              Cancel x{" "}
                              <span className="text-black ml-1">| 30s</span>
                            </motion.div>
                          </AnimatePresence>
                        </>
                      )}
                    </div>
                  ))}

                {activeTab === "requestDetails" && (
                  <div className="space-y-4">
                    <div className="flex justify-between items-center px-6">
                      <span>
                        {formatCapitaliseString(
                          detailsBuyerOpenMarketData?.data?.supplierCompanyName
                        )}{" "}
                        <br />
                        <span className="text-sm text-gray">Supplier</span>
                      </span>
                      <div className="flex flex-col items-end">
                        <span>
                          {formatCapitaliseString(
                            detailsBuyerOpenMarketData?.data?.buyerCompanyName
                          )}
                        </span>
                        <span className="text-sm text-gray">Buyer</span>
                      </div>
                    </div>

                    <div className="flex justify-between items-center px-6">
                      <span>
                        {formatDate(
                          detailsBuyerOpenMarketData?.data?.fundRequestDate
                        )}
                        <br />
                        <span className="text-sm text-gray">Request Date</span>
                      </span>
                      <div className="flex flex-col items-end">
                        <span>
                          {formatDate(
                            detailsBuyerOpenMarketData?.data?.paymentDate
                          )}
                        </span>
                        <span className="text-sm text-gray">Payment Date</span>
                      </div>
                    </div>

                    <div className="flex justify-between items-center px-6">
                      <span>
                        {formatCurrency(
                          detailsBuyerOpenMarketData?.data?.fundingAmount
                        )}
                        <br />
                        <span className="text-sm text-gray">
                          Request Amount
                        </span>
                      </span>
                      <div className="flex flex-col items-end">
                        <span>
                          {formatCurrency(
                            detailsBuyerOpenMarketData?.data?.payableAmount
                          )}
                        </span>
                        <span className="text-sm text-gray">
                          Fundable Amount
                        </span>
                      </div>
                    </div>

                    <br />
                    {/* <div className="border mt-5 border-gray"></div>
                    <div className="px-6">
                      <h1>Note from Supplier: </h1>
                      <p className="text-gray600 text-sm mt-3">
                        Seeking Early payment due to unexpected challenges.
                        Facing temporary financial constraints and need a bit
                        more time to ensure a smooth transaction. Appreciate
                        your understanding. Thank you. Best regards, ABC Limited
                      </p>
                    </div> */}
                  </div>
                )}
              </div>
            </div>
          </motion.section>
        </main>
      </TransitionScale>
      {/* Bid Modal */}
      <AnimatePresence>
        {bidModalOpen && (
          <ViewDocumentModal
            onAction={buttonClicked ? handleUpdate : handleBid}
            onClose={() => setBidModalOpen(false)}
          >
            <span className=" text-center">
              Would you like to proceed with{" "}
              {buttonClicked ? "updating you bid on" : "bidding on"} this
              invoice
              <br />
              <span className="font-bold text-center text-primaryColor">
                {id}
              </span>
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {/* Offer Detail Modal */}
      <AnimatePresence>
        {offerDetailModalOpen && (
          <OfferDetailsModal
            id={id}
            onAction={() => {
              setOfferDetailModalOpen(false);
              setBidRequest(true);
            }}
            onClose={() => setOfferDetailModalOpen(false)}
            update
            discountRate={discountRate}
            setDiscountRate={setDiscountRate}
          />
        )}
      </AnimatePresence>

      {/* Lock Modal */}
      <AnimatePresence>
        {bidRequest && (
          <ViewDocumentModal
            onAction={() => handleAction()}
            onClose={() => setBidRequest(false)}
            proceed="Yes"
          >
            <span className=" text-center">
              Are you sure you want to{" "}
              <span className="font-bold text-center text-primaryColor">
                Update your bid
              </span>{" "}
              on this request with reference number{" "}
              <span className="font-bold text-center text-primaryColor">
                {id}
              </span>{" "}
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {cancelBid && (
          <ViewDocumentModal
            onAction={() => handleDownloadSuccessful()}
            onClose={() => setCancelBid(false)}
            proceed="Yes"
          >
            <span className=" text-center">
              Would you like to cancel your bid on request <br />
              <span className="font-bold text-center text-primaryColor">
                {id}
              </span>
              ?{" "}
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {verifyLienStatusModal && (
        <VerifyLien
          onClose={() => setVerifyLienStatusModal(false)}
          onAction={() => {
            setVerifyLienStatusModal(false);
            setTimeout(() => {
              setInvoiceLockedSuccessModal(true);
            }, 500);
          }}
        />
      )}

      <AnimatePresence>
        {invoiceLockedSuccessModal && (
          <InvoiceLockSuccessModal
            setActiveTab={() => {
              sessionStorage.setItem("activeTab", 3);
              navigate("/open-market");
            }}
            update
            onClose={() => setInvoiceLockedSuccessModal(false)}
          />
        )}
      </AnimatePresence>
    </DashboardLayout>
  );
}

export default OngoingBidInvoiceDetails;
