import { motion } from "framer-motion";

function UpcomingPayment({
  amountText = "Payable Amount",
  handleMakePayment,
  player = "Supplier",
  subText = "Details on the next Early Payment Request date",
  supplierName,
  payableAmount,
  countDown,

  isData,
}) {
  const animationVariants = {
    initial: { opacity: 0, x: 50, scale: 0.95 },
    animate: { opacity: 1, x: 0, scale: 1 },
    exit: { opacity: 0, x: 50, scale: 0.95 },
    transition: { duration: 6.5, ease: "easeInOut" }, // Increased duration here
  };

  return (
    <motion.div
      className="w-full lg:w-1/2 px-5 flex flex-col gap-5 bg-white rounded py-3"
      initial="initial"
      animate="animate"
      exit="exit"
      variants={animationVariants}
    >
      <header className="flex gap-6 justify-between items-center">
        <div className="flex flex-col gap-1">
          <span className="text-[#222] text-[17px]">Upcoming Payment</span>

          <span className="text-xs font-medium text-[#959595]">{subText}</span>
        </div>

        <span>
          <motion.button
            whileTap={{ scale: 0.92 }}
            onClick={() => handleMakePayment()}
            className={`py-2 px-4 text-sm bg-primaryColor text-white rounded   ${
              !isData ? "cursor-not-allowed" : "cursor-pointer"
            }`}
            disabled={!isData}
          >
            Pay now
          </motion.button>
        </span>
      </header>

      <main className="flex flex-col gap-5">
        <div className="flex justify-between items-center">
          <span className="text-sm text-[#7A7A7A] font-medium">
            Count down{" "}
          </span>

          <span className="text-secondaryColor font-medium">{countDown}</span>
        </div>

        <div className="flex justify-between items-center">
          <span className="text-sm text-[#7A7A7A] font-medium">
            Name of {player}{" "}
          </span>

          <span className="text-[#222] font-medium">{supplierName}</span>
        </div>

        <div className="flex justify-between items-center">
          <span className="text-sm text-[#7A7A7A] font-medium">
            {amountText}
          </span>

          <span className="text-[#222] font-medium">
            <span className="text-xs font-light">{payableAmount && "N"} </span>{" "}
            {payableAmount}
          </span>
        </div>
      </main>
    </motion.div>
  );
}

export default UpcomingPayment;
