// import React, { useState, useEffect, useMemo } from "react";
import React, { useState, useEffect } from "react";

import OngoingBidCard from "./card/OngoingBidCard";
import OngoingBidCancelSuccessOnBoardModal from "../modals/OngoingBidCancelSucessOnBoardModal";
import OngoingCancelOnBoardModal from "../modals/OngoingCancelOnBoardModal";
import { useSelector } from "react-redux";

import Pagination from "shared/Pagination";
import { funderFetchAllClosedMarketBySingleStatus } from "appstate/funder/funderSlice";
import { useDispatch } from "react-redux";
import TableEmptyState from "shared/TableEmptyState";

const OngoingBid = ({ closedMarketQuery }) => {
  const [ongoingCancelBidModal, setOngoingCancelBidModal] = useState(false);
  const [ongoingBidCancelledSuccessModal, setOngoingBidCancelledSuccessModal] =
    useState(false);

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);

  const pageSize = 10;

  const handleOnCancelSuccessClose = () => {
    setOngoingBidCancelledSuccessModal(false);
  };
  const handleOnCancelBidTrue = () => {
    setOngoingCancelBidModal(false);
    setOngoingBidCancelledSuccessModal(true);
  };
  const [status, setStatus] = useState("");

  const dispatch = useDispatch();

  const { funderClosedMarketDataBySingleStatus } = useSelector(
    (state) => state.funder
  );

  const startDate = "";
  const endDate = "";
  const search = "";
  useEffect(() => {
    dispatch(
      funderFetchAllClosedMarketBySingleStatus({
        page,
        size: pageSize,
        searchTerm: search,
        startDate,
        endDate,
      })
    );
  }, [dispatch, page]);

  const filteredBids = funderClosedMarketDataBySingleStatus?.data?.filter(
    (bid) => {
      if (status === "") return true;
      if (status === "Currently Leading") return bid?.leadingBid === true;

      if (status !== "Currently Leading") return bid?.leadingBid !== true;
      return true;
    }
  );

  return (
    <>
      <OngoingCancelOnBoardModal
        isOpen={ongoingCancelBidModal}
        onClose={() => setOngoingCancelBidModal(false)}
        refNum={"R3456X89Y"}
        handleYes={handleOnCancelBidTrue}
      />
      <OngoingBidCancelSuccessOnBoardModal
        isOpen={ongoingBidCancelledSuccessModal}
        onClose={handleOnCancelSuccessClose}
        message={"Your bid has been cancelled"}
      />
      <div>
        <div className="flex flex-col  rounded-[10px] bg-unusualGray  px-6 mt-2">
          <header className="pb-4  flex flex-row gap-1  py-8 ">
            <div className="flex flex-row gap-2">
              <div className="text-black text-sm font-normal ">
                Filter bids:
              </div>

              <div>
                <select
                  className="shadow-sm text-sm focus:outline-none cursor-pointer h-[37px] px-5 py-2.5 rounded-[5px] border border-[#949494] justify-start items-end gap-2 inline-flex md:min-w-[12.5rem]"
                  // onChange={handleFilterChange}
                  // value={activeFilter || ""}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="" key="all-bids">
                    All Ongoing Bids
                  </option>
                  <option value="Currently Leading" key="leading-request">
                    Bids I'm currently leading
                  </option>
                  <option value="BID_ACTIVE" key="bid-active">
                    Bids I'm not currently leading
                  </option>
                </select>
              </div>

              {/*  */}
            </div>
            <div></div>
          </header>

          <div className="w-full  overflow-x-auto  ">
            {/*  <TableEmptyState dashboard /> */}
            {/* Single Invoice */}
            {funderClosedMarketDataBySingleStatus?.data?.length > 0 ? (
              <div className="flex flex-col gap-2.5 ">
                {funderClosedMarketDataBySingleStatus?.data &&
                  funderClosedMarketDataBySingleStatus?.data.length > 0 &&
                  filteredBids?.map((bids, idx) => (
                    <OngoingBidCard
                      key={idx}
                      id={bids.id}
                      invoiceNumber={bids.closedMarketReference}
                      buyerName={bids.buyerCompanyName}
                      invoiceType={`${
                        bids.bulk ? "Bulk Invoice" : "Single Invoice"
                      }`}
                      lockRate={bids.lockRate}
                      statusOfInvoice={bids.status}
                      requestAmount={bids.requestAmount}
                      dueDate={bids?.invoiceDueDate}
                      earlyPaymentDate={bids.earlyPaymentDate}
                      supplierName={bids.supplierCompanyName}
                      onCancel={() => setOngoingCancelBidModal(true)}
                      currentlyLeading={bids.leadingBid && "Currently Leading"}
                      leading={bids.leadingBid}
                    />
                  ))}
              </div>
            ) : (
              <TableEmptyState dashboard />
            )}
          </div>
        </div>
        {/* ?.meta?.totalElements */}

        <div className="flex justify-center gap-x-[8px] mt-[20px]">
          {funderClosedMarketDataBySingleStatus?.meta?.totalElements > 10 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={
                funderClosedMarketDataBySingleStatus?.meta?.totalElements
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default OngoingBid;
