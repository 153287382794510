import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { PAIconCancelMini } from "assets/images/svgs";
import TooltipShared from "shared/tooltip/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { pendingSingleEarlyPaymentDetails } from "appstate/buyer/buyerSlice";
import { useLocation } from "react-router-dom";
import { formatCurrency } from "utilities/helper/formatters";

const ConfirmDiscountRateModal = ({
  onClose,
  onNext,
  isOpen,
  list,
  setDiscountData,
  preData,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const earlyId = searchParams.get("earlyId");

  const { pendingSingleEarlyPaymentDetailsData } = useSelector(
    (state) => state?.buyer
  );
  const [data, setData] = useState([]);

  // console.log(data);

  useEffect(() => {
    if (earlyId) {
      dispatch(pendingSingleEarlyPaymentDetails(earlyId));
    }
  }, [dispatch, earlyId]);

  useEffect(() => {
    if (!list && pendingSingleEarlyPaymentDetailsData?.data) {
      setData([pendingSingleEarlyPaymentDetailsData?.data]);
      setDiscountData([pendingSingleEarlyPaymentDetailsData?.data]);
    } else {
      setData(preData);
    }
  }, [pendingSingleEarlyPaymentDetailsData, list, preData, setDiscountData]);

  const handleDiscountRateChange = (index, value) => {
    const updatedData = [...data];
    const updatedItem = { ...updatedData[index] };

    if (value === "") {
      updatedItem.discountRate = "";
      updatedItem.requestAmount = updatedItem.requestAmount || 0;
      updatedItem.discountAmount = 0;
      // updatedItem.payableAmount = updatedItem.payableAmount;
    } else {
      const numericRate = parseFloat(value);

      if (!isNaN(numericRate) && numericRate >= 0 && numericRate <= 100) {
        updatedItem.discountRate = value;

        const requestAmount = parseFloat(updatedItem.requestAmount) || 0;
        const paymentDay = parseFloat(updatedItem.paymentDay) || 0;

        // Formula: requestAmount * newDiscountRate / 100 * paymentDay / 360
        updatedItem.discountAmount = (
          (requestAmount * numericRate * paymentDay) /
          (100 * 360)
        ).toFixed(2);
        updatedItem.payableAmount = (
          requestAmount - updatedItem.discountAmount
        ).toFixed(2);
      }
    }

    updatedData[index] = updatedItem;
    setData(updatedData);
    setDiscountData(updatedData);
  };

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8, y: 50 },
    visible: { opacity: 1, scale: 1, y: 0 },
    exit: { opacity: 0, scale: 0.8, y: -50 },
  };

  return (
    <AnimatePresence>
      (
      <motion.div
        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[999999]"
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <motion.div
          className="relative bg-white rounded-lg shadow-lg w-full max-w-2xl mx-4 sm:mx-auto top-[5%] max-h-[75vh] h-fit transform flex flex-col"
          variants={modalVariants}
        >
          <header className="flex justify-between items-center bg-[#fafafa] px-6 py-5 rounded-lg">
            <h2 className="text-sm text-[#222] font-semibold">
              Confirm Discount Rate
            </h2>
            <button
              onClick={onClose}
              className="text-gray-500 flex items-center gap-2 hover:text-gray-700"
            >
              <PAIconCancelMini />
              <span className="text-[565656] font-medium text-sm">Close</span>
            </button>
          </header>
          <div className="p-6 overflow-x-auto">
            <table className="w-full text-xs">
              <thead>
                <tr className="bg-[#fafafa]">
                  <th className="px-4 py-2 text-left">Request Ref. No.</th>
                  <th className="px-4 py-2 text-left">Invoice Ref</th>
                  <th className="px-4 py-2 text-left">Discount Rate</th>
                  <th className="px-4 py-2 text-left">Discount Amount</th>
                  <th className="px-4 py-2 text-left">Payable Amount</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-50 border-b-[1px] border-[#f7f7f7]"
                  >
                    <td className="px-4 py-2">
                      <TooltipShared
                        text={item?.earlyPaymentRef}
                        maxLength={15}
                        textSize="text-xs"
                        highlight="text-xs"
                      />
                    </td>
                    <td className="px-4 py-2">{item?.invoiceRef}</td>
                    <td className="px-4 py-2">
                      <input
                        type="text"
                        value={item?.discountRate || ""}
                        onChange={(e) =>
                          /^\d{0,2}(\.\d{0,2})?$/.test(e.target.value) &&
                          handleDiscountRateChange(index, e.target.value)
                        }
                        onBlur={(e) => {
                          const value = parseFloat(e.target.value);
                          if (!isNaN(value)) {
                            handleDiscountRateChange(
                              index,
                              Math.min(Math.max(value, 0), 100).toFixed(2)
                            );
                          }
                        }}
                        placeholder="Enter discount rate"
                        className="w-20 border border-black/30 outline-none rounded px-2 py-1 focus:ring focus:ring-green-200"
                      />
                    </td>
                    <td className="px-4 py-2">
                      {formatCurrency(
                        item?.discountAmount?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      )}
                    </td>
                    <td className="px-4 py-2">
                      {formatCurrency(
                        item?.payableAmount?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end gap-3 items-center px-6 py-4">
            <button
              onClick={onClose}
              className="bg-gray-200 text-gray-700 px-6 py-2 rounded hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              onClick={onNext}
              className="bg-primaryColor text-white px-6 py-2 rounded hover:bg-primaryColor/90 transition-all"
            >
              Next
            </button>
          </div>
        </motion.div>
      </motion.div>
      )
    </AnimatePresence>
  );
};

export default ConfirmDiscountRateModal;
