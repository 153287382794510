// import Playcard from "./components/Playcard";

import { getEarlyPaymentFunderConfig } from "appstate/funder/funderSlice";
import CardFlow from "features/settings/components/WorkflowConfig/common/CardFlow";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

function BuyerRulesandConfig() {
  const dispatch = useDispatch();
  const { allRulesData } = useSelector((state) => state?.rulesAndConfig);

  useEffect(() => {
    dispatch(getEarlyPaymentFunderConfig());
  }, [dispatch]);

  const totalPages = allRulesData?.meta?.totalElements;
  return (
    <div className="hfull w-full py-4">
      <main className="w-full max-w-[1200px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center items-center gap-4">
        <CardFlow
          totalPages={totalPages}
          title="Early Payment"
          subtitle="Last Updated: 02-05-21"
          text="Manage rules related to early payment discounts for invoices"
          href="EARLY_PAYMENT"
        />
      </main>
    </div>
  );
}

export default BuyerRulesandConfig;
