import request from "apiInstance";
import toast from "react-hot-toast";

export const getAllClosedMarketByStatus = async ({
  status,
  search,
  page,
  pageSize,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/closed-market/requests-in-closed-market?status=${status}&search=${search}&page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const closedMarketWonBids = async ({ page, pageSize }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/closed-market/funder-won-bids?page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllClosedMarketWonBid = async ({ page, pageSize }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/closed-market/funder-won-bids`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const getAllClosedMarketDashBoard = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/closed-market/funder-dashboard`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllClosedMarketBySingleStatus = async ({
  page,
  size,
  searchTerm,
  startDate,
  endDate,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/closed-market/funder-active-bids?page=${page}&size=${size}&searchTerm=${searchTerm}&startDate=${startDate}&endDate=${endDate}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const closeMarketPlaceBid = async ({ body, closeMarketId }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/closed-market/place-bid?closeMarketId=${closeMarketId}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const closeMarketUpdateBid = async ({ body, closeMarketId }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/closed-market/update-bid/${closeMarketId}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const closeMarketCancelBid = async ({ bidId }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/closed-market/cancel-bid/${bidId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// /api/v1/closed-market/funder/view/{closeMarketId}

export const getAllClosedMarketInvoiceById = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllClosedMarketIngoingBidDetails = async ({
  closeMarketId,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/closed-market/funder-details-view/${closeMarketId}?page=0&size=10`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getBidsCurrentlyLeading = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/closed-market/funder-leading-bids`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getBidsNotCurrentlyLeading = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/closed-market/funder-bids-not-leading`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
