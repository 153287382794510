import request from "apiInstance";
import toast from "react-hot-toast";

export const createInvoice = async (invoiceData) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/invoice/for-buyer",
      data: invoiceData,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const getAllEarlyPaymentOffers = async ({ pageNo, pageSize, search }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/offers`,
      params: {
        pageNo,
        pageSize,
        search
      },
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    // toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

// /api/v1/payable-finance/early-payment-offer/674efde3cc17783054fd743b
export const getSingleEarlyPaymentOffer = async (offerId) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/early-payment-offer/${offerId}`,
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const SingleEarlyPaymentAcceptOffer = async ({ virtualAccountId, offerIds, accept, reason }) => {
  try {
    const response = await request({
      method: "put",
      url: "/api/v1/payable-finance/accept-offer",
      data: {
        virtualAccountId,
        offerIds,
        accept,
        reason,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const SingleEarlyPaymentRejectOffer = async ({  offerIds, accept, reason }) => {
  try {
    const response = await request({
      method: "put",
      url: "/api/v1/payable-finance/accept-offer",
      data: {
        offerIds,
        accept,
        reason,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    // toast.success("Offer Rejected successfully!");
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const getInvoicesByDateFunder = async ({ page, size, sort, directFundingDate }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/direct-funding/request/invoices`,
      params: {
        page,
        size,
        sort,
        directFundingDate: directFundingDate,
      },
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const getAllApprovedDfInvoices = async ({ page, size, status }) => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/direct-funding/request/invoices",
      params: {
        size,
      },
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const getAllApprovedInvoices = async ({ page, size, status }) => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/invoice/supplier/invoices",
      params: {
        page,
        size,
        status,
      },
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};



export const getSingleEarlyPaymentDetails = async (earlyPaymentId) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/single-details/${earlyPaymentId}`,
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

// /api/v1/fund-request/early-payment-request/bulk-details/{early PaymentId}
export const getBulkEarlyPaymentDetails = async (earlyPaymentId) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/bulk-details/${earlyPaymentId}`,
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const getDirectFundingDetails = async (DirecfundingId) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/direct-funding/request/${DirecfundingId}`,
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const editDiscountRateDirectFunding = async ({ directFundingId, discountRate }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/direct-funding/${directFundingId}/update-discount-rate`,
      params: {
        discountRate,
      },
      headers: {
        Accept: "application/json",
      },
    });
    toast.success("Discount rate updated successfully!");
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};


// /api/v1/direct-funding/reject-direct-funding
export const rejectDirectFunding = async ({ id, rejectionReason }) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/direct-funding/reject-direct-funding",
      data: {
        id,
        rejectionReason,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};


// /api/v1/direct-funding/approve-direct-funding

export const approveDirectFunding = async ({ transactionPin, virtualAccountId, directFundingIds, comment, discountRate }) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/direct-funding/approve-single-direct-funding",
      data: {
        transactionPin,
        virtualAccountId,
        directFundingIds,
        comment,
        discountRate
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    // toast.success("Direct funding approved successfully!");
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const approveDirectFundingBulk = async ({ transactionPin, virtualAccountId, directFundingIds, comment }) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/direct-funding/approve-bulk-direct-funding",
      data: {
        transactionPin,
        virtualAccountId,
        directFundingIds,
        comment,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    // toast.success("Direct funding approved successfully!");
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const getPendingDirectFundingConfirmationBoth = async ({ size, sort, sortDirection, page }) => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/direct-funding/pending-confirmation",
      params: {
        size,
        sort,
        sortDirection,
        page,
      },
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const getPendingDirectFundingBoth = async ({ size, sort, sortDirection, page }) => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/direct-funding/pending-confirmation",
      params: {
        size,
        sort,
        sortDirection,
        page,
      },
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const getPendingFunding = async ({ page, size, funded, direction, bulk }) => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/direct-funding/track-all-invoice",
      params: {
        page,
        size,
        funded,
        direction,
        bulk,
      },
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

// /api/v1/direct-funding/pending-confirmation?size=10&sort=PENDING_CONFIRMATION&bulk=true&sortDirection=DESC&page=0
export const getPendingDirectFundingConfirmation = async ({ size, sort,  sortDirection, page }) => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/direct-funding/pending-confirmation",
      params: {
        size,
        sort,
       
        sortDirection,
        page,
      },
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};


// /api/v1/direct-funding/funding-requests?bulk=false&page=0&size=10&status=PENDING_CONFIRMATION
export const getFunderDirectFundingPending = async ({ page, size, bulk, status }) => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/direct-funding/funding-requests",
      params: {
        page,
        size,
        bulk,
        status,
      },
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};



export const getFundedDirectFunding = async ({ page, size, funded, search, status }) => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/direct-funding/track-all-invoice",
      params: {
        page,
        size,
        funded,
        search,
        status,
      },
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};



// /api/v1/direct-funding/confirm/request
export const confirmDirectFundingRequest = async ({ directFundingIds, transactionPin, reimbursementAccountId }) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/direct-funding/confirm/request",
      data: {
        directFundingIds, 
        transactionPin,
        reimbursementAccountId,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    toast.success("Direct funding request confirmed successfully!");
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};


export const getVirtualAccounts = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/account/get-user-virtual-accounts",
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const getPercentage = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/rule/get-admin-discount-config?programType=EARLY_PAYMENT_REQUEST",
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const getEarlyPaymentRequests = async ({
  page,
  size,
  search,
  status,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/single-list?page=${page}&size=${size}&search=${search}&status=${status}`,
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

// /api/v1/fund-request/fetch-discount-bulk?buyerId=${buyerId}
export const fetchDiscountBuyerForBulk = async ({
  buyerId,
  earlyPaymentPercentage,
  earlyPaymentDate,
  invoiceIds,
}) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/fund-request/fetch-discount-bulk?buyerId=${buyerId}`,
      data: {
        earlyPaymentPercentage,
        earlyPaymentDate,
        invoiceIds: invoiceIds,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};



export const buyerWithActivatedEarlyPayment = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/fund-request/earlypayment/request/buyerlist",
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

// /api/v1/fund-request/early-payment-request/bulk-temp/{earlyPaymentId}
export const getEarlyPaymentRequestBulkTemp = async (earlyPaymentId) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/bulk-temp/${earlyPaymentId}`,
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

// /api/v1/direct-funding/view-default-config
export const getDefaultConfig = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/direct-funding/view-default-config",
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const getEarlyPaymentRequestsBulk = async ({
  page,
  size,
  search,
  status,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/bulk-list?page=${page}&size=${size}&search=${search}&status=${status}`,
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const fetchDiscountBuyer = async ({
  buyerId,
  earlyPaymentDate,
  earlyPaymentPercentage,
  invoiceId,
}) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/fund-request/fetch-discount?buyerId=${buyerId}`,
      data: {
        earlyPaymentPercentage,
        earlyPaymentDate,
        invoiceId,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

// /api/v1/direct-funding /{directFundingId}/cancel

export const cancelDirectFundingRequest = async (directFundingId) => {
  try {
    const response = await request({
      method: "delete",
      url: `/api/v1/direct-funding/${directFundingId}/cancel`,
      headers: {
        Accept: "application/json",
      },
    });
    toast.success("Direct funding request canceled successfully!");
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};



export const getDirectFundingRequests = async ({ bulk, page, size, search, status }) => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/direct-funding/funding-requests",
      params: {
        bulk,
        page,
        size,
        search,
        status
      },
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const createDirectFundingBulkRequest = async (requestData) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/direct-funding/direct-funding-request/bulk",
      data: requestData,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    toast.success("Bulk funding request created successfully!");
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};


export const ApplyRulesAndConfigBulk = async (payload) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/direct-funding/fetch/applied-invoice-percentage",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const createEarlyPaymentRequestSingle = async (paymentRequestData) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/fund-request/early-payment-request/single",
      data: paymentRequestData,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const getInvoicesByDate = async ({ buyerId, earlyPaymentDate }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/earlypayment/request/invoices`,
      params: {
        buyerId,
        earlyPaymentDate: earlyPaymentDate,
      },
      headers: {
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};


export const createEarlyPaymentRequestBulk = async (paymentRequestData) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/fund-request/early-payment-request/bulk",
      data: paymentRequestData,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const downloadCsvTemplateForInvoice = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/invoice/download-template",
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement("a");
    a.href = url;
    a.setAttribute("download", "invoice_template.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // toast.success("CSV template downloaded successfully!");
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getTemplateHeaders = async (csvFile) => {
  try {
    const formData = new FormData();
    formData.append("file", csvFile);
    const response = await request({
      method: "post",
      url: "/api/v1/invoice/headers",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const insertUpload = async (csvFile) => {
  try {
    const formData = new FormData();
    formData.append("file", csvFile);
    const response = await request({
      method: "post",
      url: "/api/v1/invoice/upload?page=0&size=10",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response?.data;
  } catch (error) {
    console.error("Upload error: ", error);
    toast.error(error?.response?.data?.message + "!");
  }
};

export const saveMappingForLater = async (payload, token) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/invoice/save-mapping-for-later",
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const mapInvoiceData = async (mappingRequest, token) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/invoice/mapping",
      params: mappingRequest,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const insertInvoice = async (invoiceData) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/invoice/insert-upload",
      data: invoiceData,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const saveValidationForLaterInvoice = async (invoiceData) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/invoice/save-validation-for-later",
      data: invoiceData,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getPlayerInvoices = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/${data.playerType}/invoices?page=0&size=10`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const EditInvoice = async (invoiceId, invoiceData) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/invoice/${invoiceId}?invoiceId=${invoiceId}`,
      data: invoiceData,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const updateInvoice = async (data) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/invoice/update/${data.type}?invoice=${data.invoiceId}`,
      data: data.body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getInvoiceById = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/${data.player}/${data.invoiceId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getInvoiceByStatus = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/${data.player}/status/${data.invoiceStatus}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getInvoiceByReference = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/${data.player}/inv-ref/${data.invoiceReference}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllInvoices = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoices?page=0&size=10`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllInvoiceById = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// /api/v1/direct-funding/direct-funding-request/single
export const createDirectFundingRequest = async (fundingRequestData) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/direct-funding/direct-funding-request/single",
      data: fundingRequestData,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    // toast.success("Direct funding request created successfully!");
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};


export const fetchAppliedRule = async (funderId, requestBody) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/direct-funding/fetch/applied-rule?funderId=${funderId}`,
      data: requestBody,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const getAllInvoiceByRef = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/inv-ref/${data.invoiceRef}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllInvoiceByStatus = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/status/${data.status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const deleteInvoiceById = async (data) => {
  try {
    const response = await request({
      method: "delete",
      url: `/api/v1/invoice/${data.invoiceId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getInvoiceDashboard = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/invoice/supplier/dashboard",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllRecentActivitiesInvoices = async ({ page, pageSize }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/supplier/recent-activities?page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllSupplierInvoices = async ({
  page,
  pageSize,
  search,
  status,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/supplier/invoices?page=${page}&search=${search}&size=${pageSize}&status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const getBuyerInvoicesByStatus = async ({ status }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/buyer/status/${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getFundSectionOverview = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/fund-request/overview",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllOpenMarket = async ({ page, pageSize }) => {
  try {
    const response = await request({
      method: "get",
      url: `/fund-request/open-markets?${page}&${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getEarlyPayments = async ({
  page,
  pageSize,
  bulk,
  status,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/fund-request/early-payments?page=${page}&size=${pageSize}&bulk=${bulk}&status=${status}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getDirectFundings = async ({
  page,
  pageSize,
  bulk,
  status,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/fund-request/direct-fundings?page=${page}&size=${pageSize}&bulk=${bulk}&status=${status}&search=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierInvoiceById = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/supplier/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const approveInvoice = async ({ body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/invoice/approve`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const approveSupplierInvoice = async ({ body }) => {
  try {
    const response = await request({
      method: "put",
      url: "/api/v1/invoice/submit-for-approval",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const uploadInvoice = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/invoice/insert-upload",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllPayablesInvoice = async ({ page, pageSize, search }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/payables?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllPendingUploadInvoice = async ({
  page,
  pageSize,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/saveForLater?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// /api/v1/invoice/resume/mapping/{reference}

export const getInvoiceResumeMapping = async (reference) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/resume/mapping/${reference}`,
      headers: {
        Accept: "application/json",
      },
    });
    toast.success("Invoice resume mapping fetched successfully!");
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

// GET/api/v1/invoice/resume/validation/{reference}

export const getInvoiceResumeValidation = async (reference) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/resume/validation/${reference}`,
      headers: {
        Accept: "application/json",
      },
    });
    toast.success("Invoice resume validation fetched successfully!");
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
    throw error;
  }
};

export const deletePendingUploadInvoice = async ({ id }) => {
  try {
    const response = await request({
      method: "delete",
      url: `/api/v1/invoice/cancel-resume/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const deleteCreatedInvoice = async ({ id }) => {
  try {
    const response = await request({
      method: "delete",
      url: `/api/v1/invoice/delete/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const submitCreatedInvoice = async ({ id }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/invoice/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// WebSocket connection function
export const connectToWebSocket = ({ recipient_id, sender_ids, onMessage }) => {
  const ws = new WebSocket(
    `wss://payapi.aetdevops.com/ws/messages/${sender_ids}/${recipient_id}`
  );

  ws.onopen = () => {
    // console.log("WebSocket connection established");
  };

  ws.onmessage = (event) => {
    const incomingData = JSON.parse(event.data);
    // console.log("Message received:", incomingData);
    if (onMessage) {
      onMessage(incomingData);
    }
  };

  ws.onerror = (error) => {
    console.error("WebSocket error:", error);
  };

  ws.onclose = () => {
    console.log("WebSocket connection closed");
  };

  return ws;
};
