import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { PAIconArrowDownThin } from "assets/images/svgs";

function LienApproveCard({
  sectA = "Total Money Liened",
  sectB = "Total Approved Early Payment Request ",
  account,
  lienAmount,
  totalApprovedEPR,
  refetchTable,
}) {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({
    name: "Praise Akobundu",
    accountNumber: "7364738921",
    bank: "PayEdge MFB",
    type: "Virtual Account",
  });

  // const accounts = [
  //   {
  //     name: "Praise Akobundu",
  //     accountNumber: "7364738921",
  //     bank: "PayEdge MFB",
  //     type: "Primary Virtual Account",
  //   },
  //   {
  //     name: "Jane Doe",
  //     accountNumber: "1234567890",
  //     bank: "PayEdge MFB",
  //     type: "Secondary Virtual Account",
  //   },
  //   {
  //     name: "John Smith",
  //     accountNumber: "9876543210",
  //     bank: "PayEdge MFB",
  //     type: "Secondary Virtual Account",
  //   },
  // ];

  const toggleDropdown = () => setDropdownOpen((prev) => !prev);

  const selectAccount = (account) => {
    setSelectedAccount(account);
    setDropdownOpen(false);
  };

  const closeDropdown = () => setDropdownOpen(false);

  // Framer Motion Variants for the dropdown animation
  const dropdownVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
    exit: { opacity: 0, y: -10, transition: { duration: 0.3 } },
  };

  // Framer Motion Variants for the parent animation
  const parentVariants = {
    initial: { opacity: 0, y: -10 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: -10, transition: { duration: 0.5 } },
  };

  return (
    <motion.div
      className="w-full lg:w-[60%] relative"
      initial="initial"
      animate="animate"
      exit="exit"
      variants={parentVariants} // Apply parent animation
    >
      {/* Header Section */}
      <header
        className="px-6 rounded-t border-b border-[#eee] bg-[#fff] text-[15.131px] py-3 flex items-center justify-between cursor-pointer relative z-20"
        onClick={toggleDropdown}
      >
        <span>
          {selectedAccount.name} |{" "}
          <span className="italic text-[#222] font-[600]">
            {selectedAccount.accountNumber}
          </span>{" "}
          | <span className="font-[500]">{selectedAccount.bank}</span> |{" "}
          {selectedAccount.type}
        </span>
        <span>
          <PAIconArrowDownThin />
        </span>

        {/* Dropdown Menu with Animation */}
        <AnimatePresence>
          {isDropdownOpen && (
            <motion.ul
              className="absolute top-full left-0 w-full bg-[#fff] shadow-md rounded-b py-3 z-30"
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={dropdownVariants}
            >
              {account &&
                account?.length > 0 &&
                account.map((account, index) => (
                  <li
                    key={index}
                    className="py-2 px-6 cursor-pointer hover:bg-gray-100"
                    onClick={() => {
                      selectAccount(account);
                      toggleDropdown();
                      refetchTable(account);
                    }}
                  >
                    {account.name} |{" "}
                    <span className="italic">{account.accountNumber}</span> |{" "}
                    {account.bank} | {account.type}
                  </li>
                ))}
            </motion.ul>
          )}
        </AnimatePresence>
      </header>

      {/* Overlay */}
      {isDropdownOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-25 z-10"
          onClick={closeDropdown}
        />
      )}

      {/* Main Section */}
      <main className="px-6 bg-[#fff] w-full rounded-b py-3">
        <div className="flex flex-col sm:flex-row justify-between lg:justify-normal">
          <section className="flex flex-col gap-2.5 py-8 w-fit pr-9 border-r border-[#C5C5C5]">
            <div>
              <span className=" mr-0.5  font-medium  text-primaryColor">
                NGN
              </span>
              <span className="text-[32px] text-primaryColor font-medium">
                {lienAmount}
              </span>
            </div>

            <span className="text-[#7a7a7a] text-[15px] font-light">
              {sectA}
            </span>
          </section>

          <section className="flex flex-col items-center gap-2.5 py-8 w-fit pl-9">
            <div className="flex justify-center">
              <span className="text-[32px] text-primaryColor font-medium">
                {totalApprovedEPR}
              </span>
            </div>

            <span className="text-[#7a7a7a] text-center text-[15px] font-light flex justify-center">
              {sectB}
            </span>
          </section>
        </div>
      </main>
    </motion.div>
  );
}

export default LienApproveCard;
