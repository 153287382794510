import React from "react";
import { PAIconModelOne } from "assets/images/svgs";

const ViaDirectFunding = ({ data }) => {
  return (
    <div className="px-4">
      <h2 className="font-semibold text-lg leading-[30px] mb-4">
        Early Payment Details
      </h2>

      <div className="flex justify-between my-5">
        <div className="flex gap-2">
          <PAIconModelOne />
          <div className="flex flex-col">
            <span className="text-sm">Supplier</span>
            <span className="text-[#979797]  text-xs">
              {" "}
              {data?.supplierAccountName}
            </span>
          </div>
        </div>
        <div className="flex flex-col items-end">
          <span className="text-sm">{data?.discountRate}%</span>
          <span className="text-[#979797]  text-xs">Discount rate</span>
        </div>
      </div>

      <div className="flex justify-between pb-5">
        <div className="flex gap-2">
          <div className="flex flex-col">
            <span className="text-sm">
              N{data?.discountAmount?.toLocaleString() || "0.00"}
            </span>
            <span className="text-[#979797]  text-xs">Discount Amount</span>
          </div>
        </div>
        <div className="flex flex-col items-end">
          <span className="text-sm">{data?.requestDate}</span>
          <span className="text-[#979797]  text-xs">
            Early Payment Request Date
          </span>
        </div>
      </div>

      <div className="flex justify-between mt-5 border-b-[5px] border-b-[#F6F5FA] pb-10">
        <div className="flex gap-2">
          <div className="flex flex-col">
            <span className="text-sm">
              N{data?.requestAmount?.toLocaleString() || "0.00"}
            </span>
            <span className="text-[#979797]  text-xs">Request Amount</span>
          </div>
        </div>
        <div className="flex flex-col items-end">
          <span className="text-sm">
            {data?.payableAmount?.toLocaleString()}
          </span>
          <span className="text-[#979797]  text-xs">Payable amount</span>
        </div>
      </div>

      <div className="border-b-[5px] border-b-[#F6F5FA] pb-5">
        <h2 className="font-medium text-base my-3">Note from Supplier:</h2>
        <p className="text-[#959595] text-sm">{data?.description}</p>
      </div>

      <div className="pb-3">
        <h2 className="font-medium text-base leading-[30px] my-2">
          Rejection reason
        </h2>
        <p className="text-[#959595] text-sm">
          This invoice was rejected by {""}
          <span className="font-semibold text-black">
          {data?.funderVirtualAccountName}
          </span>{" "}
          your associated funder for funding
        </p>
      </div>
    </div>
  );
};

export default ViaDirectFunding;
