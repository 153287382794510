import React from "react";
import { PAIconSmallNestle } from "assets/images/svgs";
import { formatCapitalize } from "utilities/formatCapitalize";

const AddModalComponent = ({
  onBoxChange,
  boxChecked,
  companyName,
  discount,
}) => {
  return (
    <div class="w-full pb-4 border-b border-[#BDBDBD] items-center gap-4 inline-flex">
      <div>
        <input
          type="checkbox"
          onChange={onBoxChange}
          checked={boxChecked}
          style={{ height: 20, width: 20 }}
          color="#F08000"
          class="checked:bg-blue-500 bg-black cursor-pointer"
        />
      </div>

      <div className="flex w-full justify-between">
        <div class="justify-start items-center gap-3 flex">
          <PAIconSmallNestle style={{ height: 40, width: 40 }} />
          <div class="flex-col justify-start items-start gap-[7px] inline-flex">
            <div class="text-zinc-900 text-sm font-normal ">
              {formatCapitalize(companyName)}
            </div>
          </div>
        </div>

        <div className="flex flex-col pr-2   gap-1">
          <span>{discount}% </span>

          <span className="text-primaryColor text-[10px]">Discount</span>
        </div>
      </div>
    </div>
  );
};

export default AddModalComponent;
