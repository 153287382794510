import TransitionOpacity from "shared/animation/TransitionOpacity";
import AllInvoicesCard from "./cards/AllInvoicesCard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getAllSupplierOpenMarket,
  getAllSupplierOpenMarketInvoices,
} from "appstate/openMarket/openMarketSlice";
import LoadingScreen from "shared/LoadingScreen";
import EmptyPage from "features/roleManager/Dashbaord/components/EmptyPage";
import { PAIconSearchGreen } from "assets/images/svgs";
import { motion } from "framer-motion";
import Pagination from "shared/Pagination";

function AllInvoices({ allInvoiceQuery, setAllInvoiceQuery }) {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(3);
  const pageSize = 10;

  const { isLoading, getAllSupplierOpenMarketInvoicesData } = useSelector(
    (state) => state?.openMarket
  );
  useEffect(() => {
    dispatch(getAllSupplierOpenMarket());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getAllSupplierOpenMarketInvoices({
        page,
        pageSize,
        searchQuery: allInvoiceQuery,
        status,
      })
    );
  }, [dispatch, page, pageSize, status, allInvoiceQuery]);

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setAllInvoiceQuery(value);
  };
  // console.log(getAllSupplierOpenMarketInvoicesData);

  return (
    <TransitionOpacity className="flex flex-col gap-5 min-h-[75vh] rounded-b-[10px] bg-unusualGray  pb-6">
      <div className="flex bg-white py-5 px-6 justify-between items-center w-full">
        <div className="flex items-center gap-2.5">
          <span className="py-2.5 text-sm">Filter Request Status: </span>

          <select
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
            className="text-sm font-medium border border-[#959595] rounded-[5px] px-5 py-2.5 outline-none cursor-pointer"
          >
            <option value="" key="">
              All Invoices
            </option>
            <option value="POSTED" key="">
              Posted Invoices
            </option>
            <option value="BID_ACTIVE" key="">
              Bid Active Invoices
            </option>
          </select>
        </div>
        <div className="flex w-[35%]  items-center gap-3">
          <div className="flex w-full items-center gap-4">
            <div className="flex gap-2.5 w-full bg-white border-[0.5px] border-[#ccc] p-2.5 items-center rounded-[5px]">
              <PAIconSearchGreen />
              <input
                type="text"
                placeholder="Search for request"
                value={allInvoiceQuery}
                onChange={handleSearchInputChange}
                className="px-2 w-full text-xs flex items-center text-black placeholder:text-black focus:outline-none"
              />
            </div>
          </div>

          <motion.button
            whileTap={{ scale: 0.94 }}
            className="py-2.5 text-white text-sm px-6 rounded-[5px] bg-[#2FA06A]"
          >
            Search
          </motion.button>
        </div>
      </div>
      <div className="w-full px-6 font-medium text-sm text-end text-primaryColor justify-end items-center">
        Days:Hours:Mins:Secs
      </div>
      {isLoading ? (
        <LoadingScreen />
      ) : getAllSupplierOpenMarketInvoicesData?.data?.length > 0 ? (
        <div className="flex px-6 flex-col gap-4 h-full justify-center">
          {getAllSupplierOpenMarketInvoicesData?.data?.map((invoice) => (
            <AllInvoicesCard
              key={invoice?.openMarketReference}
              id={invoice?.openMarketReference}
              invoiceReference={invoice?.openMarketReference}
              invoiceNumber={invoice?.invoiceNumber}
              buyerCompanyName={invoice?.buyerCompanyName}
              fundingAmount={invoice?.fundingAmount}
              invoiceDueDate={invoice?.invoiceDueDate?.join("-")}
              paymentDate={invoice?.paymentDate?.join("-")}
              status={invoice?.status}
              lockRate={invoice?.lockRate}
              isLoading={isLoading}
            />
          ))}
          <div className="flex items-center h-full justify-center">
            {getAllSupplierOpenMarketInvoicesData?.meta?.totalElements >=
              pageSize && (
              <Pagination
                page={page}
                startPage={startPage}
                endPage={endPage}
                setEndPage={setEndPage}
                setPage={setPage}
                setStartPage={setStartPage}
                totalPages={
                  getAllSupplierOpenMarketInvoicesData?.meta?.totalElements
                }
              />
            )}
          </div>
        </div>
      ) : (
        <EmptyPage />
      )}
    </TransitionOpacity>
  );
}

export default AllInvoices;
