import { PAIconArrowLeftGreen } from "assets/images/svgs";
import React, { useState, useEffect } from "react";
import BidDetails from "./BidDetails";
import { useLocation } from "react-router-dom";
import ViaWalletDetails from "./ViaWalletDetails";
import ViaDirectFunding from "./ViaDirectFunding";
import { useDispatch, useSelector } from "react-redux";
import { approvedBulkEarlyPaymentDetails } from "appstate/buyer/buyerSlice";
import { useNavigate } from "react-router-dom";

const Details = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { fundingChannel } = location.state || {};
  const status = "PENDING_APPROVAL";
  const dispatch = useDispatch();
  const { getInvoiceId, approvedBulkEarlyPaymentDetailsData } = useSelector(
    (state) => state?.buyer
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    const id = getInvoiceId;
    dispatch(approvedBulkEarlyPaymentDetails({ id, status }));
  }, [dispatch, getInvoiceId]);

  useEffect(() => {
    setData(approvedBulkEarlyPaymentDetailsData?.data);
  }, [approvedBulkEarlyPaymentDetailsData]);

  const handleBackBtnClick = () => {
    let path = "/payablefinancing/overview";
    navigate(path, { state: { selectedtab: "earlyPayments" } });
  };

  return (
    <>
      <div
        onClick={handleBackBtnClick}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
      >
        <PAIconArrowLeftGreen />
        <span className="flex items-center text-primaryColor">Go Back</span>
      </div>

      <div className="grid lg:grid-cols-[60%,40%] gap-5 h-[80vh]">
        {/* 60% */}
        <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px] pt-7">
          <div className="px-6 ">
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between">
                <h1 className="text-[18px] font-bold">
                  Reference No: | {data?.requestReferenceNumber}
                </h1>
              </div>

              <main className=" w-full flex justify-between">
                <div className="flex flex-col gap-3 w-1/2">
                  <div className="flex flex-col gap-1">
                    <div className="flex">
                      <span className=" text-xs font-semibold w-[45%]">
                        Request Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {data?.requestReferenceNumber}
                      </span>
                    </div>
                    <div className="flex">
                      <span className=" text-xs font-semibold w-[45%]">
                        Total Request Amount:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        {data?.totalRequestAmount}
                      </span>
                    </div>
                    <div className="flex">
                      <span className=" text-xs font-semibold w-[45%]">
                        Total Discount Amount:
                      </span>
                      <span className="text-[#979797] text-xs">
                        {data?.totalDiscountAmount}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="items-end">
                  {data?.fundingChannel === "WALLET" && (
                    <div>
                      <button className="w-[140px] h-[35px] bg-primaryColor rounded-[4px] text-white">
                        Disburse Now
                      </button>
                    </div>
                  )}

                  {data?.fundingChannel === "WALLET" && (
                    <div className="mt-4">
                      <button className="w-[120px] text-[14px] h-[35px] bg-[#D8EFD6] rounded-[4px] text-[#2EA923]">
                        Approved
                      </button>
                    </div>
                  )}
                </div>

                {fundingChannel === "Direct Funding" && (
                  <div>
                    <button className="w-[140px] h-[35px] bg-[#D8EFD6] rounded-[4px] text-[#2EA923]">
                      Approved
                    </button>
                  </div>
                )}

                {fundingChannel === "Closed Market" && (
                  <div>
                    <button className="w-[140px] h-[35px] border-primaryColor border-[1px] rounded-[4px] text-primaryColor">
                      Posted
                    </button>
                  </div>
                )}
              </main>
            </div>

            <div className="flex justify-between my-5 border-b-[3px] border-slate-100 pb-2">
              <div className="flex flex-col">
                <span className=" font-bold text-sm">
                  {data?.buyerCompanyName}
                </span>
                <span className="text-[#979797] text-xs">Buyer Details</span>
              </div>
             
              <div className="flex flex-col">
                <span className="font-bold text-sm">NGN</span>
                <span className=" text-[#979797] text-xs">Currency</span>
              </div>
            </div>

            <div className="flex flex-col gap-4 mt-5">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3 flex items-center justify-between">
                <h1 className="text-sm font-bold">Invoice List</h1>
                <h1 className="text-sm font-bold">
                  {data?.earlyPaymentInvoiceResponse?.length} Invoice(s)
                </h1>
              </header>
              <div className=" w-full overflow-x-auto h-[45vh]">
                <table className="overflow-x-auto w-[50vw]">
                  <thead className="bg-[#EFEFEF] h-[50px] w-[50vw] overflow-x-auto">
                    <tr className=" bg-unusualGray px-2.5 py-1">
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Ref Number
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Due Date
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Request Amount
                      </td>
                      <td className=" p-2.5 text-xs font-bold w-[120px]">
                        Discount Rate
                      </td>
                      <td className="text-[#11192A] p-2.5 text-xs font-bold w-[120px]">
                        Discount Amount
                      </td>
                    </tr>
                  </thead>
                  {data?.earlyPaymentInvoiceResponse?.map((item, index) => (
                    <tbody key={index}>
                      <tr className=" py-[3px] px-1.5">
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.invoiceRef}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5 w-[20%]">
                          {item?.requestDate}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.requestAmount}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5 flex items-center gap-1">
                          {item?.discountRate}%
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.discountedAmount}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* 40% */}
        <div className="bg-white rounded-[10px] gap-8 pt-5 pb-24">
          {data?.fundingChannel === "WALLET" && (
            <ViaWalletDetails data={data} />
          )}

          {data?.fundingChannel === "DIRECT_FUNDING" && <ViaDirectFunding data={data} />}

          {data?.fundingChannel === "CLOSED_MARKET" && <BidDetails data={data} />}
        </div>
      </div>
    </>
  );
};

export default Details;
