import { allVirtualAccounts } from "appstate/wallet/walletSlice";
import { PAIconCancelMini, PAIconWallet } from "assets/images/svgs";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { LoaderIcon } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { formatCapitalize } from "utilities/formatCapitalize";
import { formatCurrency } from "utilities/helper/formatters";
function SelectAccountModal({
  onClose,
  onAction,
  setSelectedAccountDetails,
  overlayDark,
}) {
  const dispatch = useDispatch();
  const { isLoading, allVirtualAccountsData } = useSelector(
    (state) => state?.wallet
  );

  useEffect(() => {
    dispatch(allVirtualAccounts());
  }, [dispatch]);

  const handleSubmit = (accountNumber) => {
    onAction(accountNumber);
  };

  return (
    <motion.div className="z-[9999999]">
      <motion.div
        className={`${
          overlayDark ? "bg-black bg-opacity-50" : "bg-slate-600/20"
        } fixed inset-0 flex items-center justify-center z-[9999999]`}
      >
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="modal-overlay "
        ></motion.div>

        <motion.div
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            scale: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="bg-white overflow-auto rounded-[10px] min-w-[35%] w-[60%] lg:w-[45%] max-h-[80%] lg:max-h-[70%] min-h-[50%] pb-10 flex flex-col gap-1"
        >
          <header className=" px-6  items-center flex bg-[#FAFAFA] py-5  justify-between">
            <span className=" font-bold">Selected account</span>

            <span
              onClick={() => onClose()}
              className="flex cursor-pointer items-center gap-2"
            >
              <PAIconCancelMini /> Close
            </span>
          </header>

          <section className="text-[#5F4D61] text-center px-6 mt-4 mb-4">
            Select which account you want to use for the bid
          </section>

          <section className="px-6 overflow-auto flex flex-col gap-3">
            <header className="text-[#5F4D61]  font-semibold pb-3 italic">
              Accounts
            </header>
            <main
              className={`${
                allVirtualAccountsData?.data?.length < 1
                  ? "flex"
                  : isLoading
                  ? "flex"
                  : "grid"
              } gap-x-5 w-full overflow-auto grid-cols-1 md:grid-cols-2`}
            >
              {isLoading ? (
                <div className="w-full flex justify-center items-center gap-2">
                  Fetching Account Details <LoaderIcon />
                </div>
              ) : (
                <>
                  {" "}
                  {allVirtualAccountsData?.data?.length > 0 ? (
                    <>
                      {allVirtualAccountsData?.data?.map((account) => (
                        <motion.div
                          whileTap={{ scale: 0.96 }}
                          onClick={() => {
                            setSelectedAccountDetails(account);
                            handleSubmit();
                          }}
                          className="bg-primaryColor min-w-[212px] mb-6 cursor-pointer flex flex-col gap-1.5 text-white w-full  p-3 rounded-[10px]"
                        >
                          <div className="flex justify-between items-center">
                            <div className="flex items-center gap-1.5">
                              <PAIconWallet />
                              <span className="text-sm">
                                {formatCapitalize(account?.accountName)}
                              </span>
                            </div>

                            <span className=" text-[10px]">
                              Virtual Account
                            </span>
                          </div>

                          <div className=" italic font-semibold">
                            {formatCurrency(account?.balance)}
                          </div>

                          <div className="flex justify-between items-center">
                            <span>PayEdge MFB | {account?.accountNumber}</span>
                          </div>
                        </motion.div>
                      ))}
                    </>
                  ) : (
                    <div className="flex w-full flex-col text-center justify-center">
                      <span className="font-medium flex text-center justify-center w-full">
                        No virtual account available
                      </span>
                    </div>
                  )}
                </>
              )}
            </main>
          </section>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default SelectAccountModal;
