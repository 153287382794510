import DashboardLayout from "layout/dashboardlayout";
import { useEffect, useRef, useState } from "react";
import SupplierEarlyPaymentHeader from "./supplierearlypaymentheader";
import Button from "shared/Button";
import Table from "layout/table/Table";
import { PAIconCancel, PAIconOption } from "assets/images/svgs";
import Card from "shared/Card";
import { useNavigate } from "react-router-dom";
import TableIcons from "../../assets/images/svgs/TableIcons.svg";
import Overview from "features/invoice/fundingSection/overview/Overview";
import { getDirectFundingRequests, getEarlyPaymentRequests, getEarlyPaymentRequestsBulk } from "appstate/api/invoice/invoice";
import toast from "react-hot-toast";
import Pagination from "shared/Pagination";

const SupplierEarlyPayments = () => {
  const dropdownRef = useRef();
  const [selectedTab, setSelectedTab] = useState("Overview");
  const [dropdown, setDropdown] = useState(false);
  // eslint-disable-next-line
  const [atBottom, setAtBottom] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState("Single Request");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [directFundingSingleData, setDirectFundingSingleData] = useState([]);
  const [directFundingBulkData, setDirectFundingBulkData] = useState([]);
  const [bulkData, setBulkData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const [status, setStatus] = useState("");
  const [directFundingStatus, setDirectFundingStatus] = useState("");
  const [total, setTotal] = useState("");
  const [buyerName, setBuyerName] = useState("");
  const [referenceSearch, setReferenceSearch] = useState("");

  const size = 10;

  const handleDropdown = (row) => {
    if (dropdown === row.id) {
      setDropdown(null);
    } else {
      setDropdown(row.id);
    }
  };

  const fetchEarlyPayments = async () => {
    setLoading(true);
    try {
      const response = await getEarlyPaymentRequests({
        page: 1,
        size: 10,
        search: buyerName,
        status: status || "",
      });
      setTotal(response?.meta?.totalElements);
      setData(response?.data);
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };


  const bulkFetchEarlyPaymentsRequest = async () => {
    setLoading(true);
    try {
      const response = await getEarlyPaymentRequestsBulk({
        page: 1,
        size: 10,
        search: buyerName,
        status: status || "",
      });
      setTotal(response?.meta?.totalElements);
      setBulkData(response?.data);
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchDirectFundingRequests = async (isBulk) => {
    setLoading(true);
    try {
      const response = await getDirectFundingRequests({
        bulk: isBulk,
        page,
        size,
        search: referenceSearch,
        status: directFundingStatus
      });
      if (isBulk) {
        setDirectFundingBulkData(response?.data);
      } else {
        setDirectFundingSingleData(response?.data);
      }
      setTotal(response?.meta?.totalElements);
    } catch (error) {
      toast.error("Failed to fetch funding requests!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedRequest === "Single Request") {
      fetchDirectFundingRequests(false);
    } else if (selectedRequest === "Bulk Request") {
      fetchDirectFundingRequests(true);
    }
    // eslint-disable-next-line 
  }, [selectedRequest, page, referenceSearch, directFundingStatus]);


  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleDirectFundingStatusChange = (event) => {
    setDirectFundingStatus(event.target.value);
  };

  const handleBuyerNameChange = (event) => {
    setBuyerName(event.target.value);
  };

  const handleReferenceNameChange = (event) => {
    setReferenceSearch(event.target.value);
  };

  useEffect(() => {
    fetchEarlyPayments();
    bulkFetchEarlyPaymentsRequest();
    // eslint-disable-next-line
  }, [page, size, status, buyerName]);

  const requestOptions = [
    { id: 1, name: "Single Request" },
    { id: 2, name: "Bulk Request" },
  ];



  const statusOptions = [
    { id: 0, name: "All", value: "" },
    { id: 1, name: "Approved", value: "APPROVED" },
    { id: 2, name: "Pending Approval", value: "PENDING_APPROVAL" },
    { id: 3, name: "Pending Confirmation", value: "PENDING_CONFIRMATION" },
    { id: 4, name: "Processing", value: "PROCESSING" },
    { id: 5, name: "Rejected", value: "REJECTED" },
  ];

  const onRowClicked = (row) => {
    if (row?.status === "PENDING_APPROVAL") {
      let path = `/single-early-payment-details-pending-approval/${row?.id}`;
      navigate(path, { state: { invoiceId: row?.invoiceId } });
    }
    if (row?.status === "APPROVED") {
      let path = `/single-early-payment-details-approval/${row?.id}`;
      navigate(path, { state: { invoiceId: row?.invoiceId } });
    }
    if (row?.status === "PROCESSING") {
      let path = `/single-early-payment-details-processing/${row?.id}`;
      navigate(path, { state: { invoiceId: row?.invoiceId } });
    }
    if (row?.status === "REJECTED") {
      let path = `/single-early-payment-details-rejected/${row?.id}`;
      navigate(path, { state: { invoiceId: row?.invoiceId } });
    }
  };

  const onBulkEarlyPaymentRowClicked = (row) => {
    if (row?.status === "PENDING_APPROVAL") {
      let path = `/single-bulk-early-payment-details-pending-approval/${row?.earlyPaymentId}`;
      navigate(path, { state: { invoiceId: row?.earlyPaymentId } });
    }
    if (row?.status === "APPROVED") {
      let path = `/single-bulk-early-payment-details-approval/${row?.earlyPaymentId}`;
      navigate(path, { state: { invoiceId: row?.invoiceId } });
    }
    if (row?.status === "PROCESSING") {
      let path = `/single-bulk-early-payment-details-processing/${row?.earlyPaymentId}`;
      navigate(path, { state: { invoiceId: row?.invoiceId } });
    }
    if (row?.status === "REJECTED") {
      let path = `/single-bulk-early-payment-details-rejected/${row?.earlyPaymentId}`;
      navigate(path, { state: { invoiceId: row?.invoiceId } });
    }
  };

  // SupplierSingleEarlyPaymentDetailsApproved

  const DirectFundingClicked = (row) => {

    if (row?.status === "PENDING_CONFIRMATION") {
      let path = `/single-direct-funding-details-pending-confirmation/${row?.id}`;
      navigate(path, { state: { invoiceId: row?.id } });
    }
    if (row?.status === "APPROVED") {
      let path = `/single-direct-funding-details-approval/${row?.id}`;
      navigate(path, { state: { invoiceId: row?.id } });
    }
    if (row?.status === "PENDING_APPROVAL") {
      let path = `/single-direct-funding-details-pending-approval/${row?.id}`;
      navigate(path, { state: { invoiceId: row?.id } });
    }
    if (row?.status === "PROCESSING") {
      let path = `/single-direct-funding-details-processing/${row?.id}`;
      navigate(path, { state: { invoiceId: row?.id } });
    }
    if (row?.status === "REJECTED") {
      let path = `/single-direct-funding-details-rejected/${row?.id}`;
      navigate(path, { state: { invoiceId: row?.id } });
    }
  };

  const BulkDirectFundingClicked = (row) => {
    if (row?.status === "PENDING_CONFIRMATION") {
      let path = `/single-bulk-direct-funding-details-pending-confirmation/${row?.id}`;
      navigate(path, { state: { invoiceId: row?.id } });
    }
    if (row?.status === "PENDING_APPROVAL") {
      let path = `/single-bulk-direct-funding-details-pending-approval/${row?.id}`;
      navigate(path, { state: { invoiceId: row?.id } });
    }
    if (row?.status === "APPROVED") {
      let path = `/single-bulk-direct-funding-details-approval/${row?.id}`;
      navigate(path, { state: { invoiceId: row?.id } });
    }
    if (row?.status === "PROCESSING") {
      let path = `/single-bulk-direct-funding-details-processing/${row?.id}`;
      navigate(path, { state: { invoiceId: row?.id } });
    }
    if (row?.status === "REJECTED") {
      let path = `/single-bulk-direct-funding-details-rejected/${row?.id}`;
      navigate(path, { state: { invoiceId: row?.id } });
    }
  };

  const DirectFundingRequestFlow = () => {
    if (selectedRequest === "Single Request") {
      navigate("single-direct-funding-request-flow");
    } else {
      navigate("/bulk-direct-funding-request-flow");
    }
  };

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  const GoToRequestFlow = () => {
    if (selectedRequest === "Single Request") {
      navigate("single-early-payment-request-flow");
    } else {
      navigate("/bulk-early-payment-request-flow");
    }
  };

  const HeaderWithIcon = ({ icon, text }) => (
    <div className="flex items-center">
      <img src={icon} alt="" className="mr-2" />
      <span>{text}</span>
    </div>
  );

  //single
  const directFundingColumn = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Request Ref.No" />,
      selector: (row) => row?.reference,
    },
    ...(selectedRequest === "Single Request"
      ? [
        {
          name: <HeaderWithIcon icon={TableIcons} text="Invoice Number" />,
          selector: (row) => row?.invoiceNumber,
        },
      ]
      : []),
    {
      name: <HeaderWithIcon icon={TableIcons} text="Funder" />,
      selector: (row) => row?.funderCompanyName,
      width: "15rem",
    },
    {
      name: "Early Payment date",
      selector: (row) => row?.newRequestedDate,
    },
    {
      name: "Request Amount",
      selector: (row) => row?.newRequestedAmount,
    },
    {
      name: "Discount amount",
      selector: (row) => row?.invoice[0]?.discountAmount,
    },
    {
      name: "Receivable Amount",
      selector: (row) => (
        <span
          style={{
            color:
              row.status === "PENDING_CONFIRMATION"
                ? "#F08000"
                : row.status === "APPROVED"
                  ? "#2EA923"
                  : row.status === "PENDING_APPROVAL"
                    ? "#F08000"
                    : row.status === "REJECTED"
                      ? "#D40606"
                      : row.status === "PROCESSING"
                        ? "#5C8AFF"
                        : "black",
          }}
        >
          {row?.receivableAmount}
        </span>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color:
              row.status === "PENDING_CONFIRMATION"
                ? "#F08000"
                : row.status === "APPROVED"
                  ? "#2EA923"
                  : row.status === "PENDING_APPROVAL"
                    ? "#F08000"
                    : row.status === "REJECTED"
                      ? "#D40606"
                      : row.status === "PROCESSING"
                        ? "#5C8AFF"
                        : "black",
          }}
        >
          {row?.status}
        </span>
      ),
    },
    {
      cell: (row) => (
        <div ref={dropdownRef}>
          {dropdown ? (
            <PAIconCancel
              title="Options"
              onClick={() => handleDropdown(row)}
              className="w-[10px]"
            />
          ) : (
            <PAIconOption title="Options" onClick={() => handleDropdown(row)} />
          )}
          {dropdown === row.id && row && (
            <div className="relative">
              <Card
                className={`w-[160px] right-[-10px] ${atBottom && "bottom-[20px]"
                  } absolute z-10 drop-shadow-md rounded-md py-[12px]`}
              >
                <div className="flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-sm font-normal">Preview</p>
                </div>
                <div className="flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-sm font-normal">Export Request</p>
                </div>
                <div className="flex items-center gap-x-[10px] mt-[10px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-sm font-normal text-[#DE1515]">
                    Cancel Request
                  </p>
                </div>
              </Card>
            </div>
          )}
        </div>
      ),
      width: "5rem",
    },
  ];

  const column = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Request Ref.No" />,
      selector: (row) => row?.reference,
    },
    ...(selectedRequest === "Single Request"
      ? [
        {
          name: <HeaderWithIcon icon={TableIcons} text="Invoice Number" />,
          selector: (row) => row?.invoiceNumber,
        },
      ]
      : []),
    {
      name: <HeaderWithIcon icon={TableIcons} text="Buyer" />,
      selector: (row) => row?.buyerCompanyName,
      width: "15rem",
    },
    {
      name: "Early Payment date",
      selector: (row) => row?.newRequestedDate,
    },
    {
      name: "Request Amount",
      selector: (row) => row?.totalRequestedAmount,
    },
    {
      name: "Discount amount",
      selector: (row) => row?.discountedAmount,
    },
    {
      name: "Receivable Amount",
      selector: (row) => (
        <span
          style={{
            color:
              row.status === "PENDING_APPROVAL"
                ? "#F08000"
                : row.status === "APPROVED"
                  ? "#2EA923"
                  : row.status === "REJECTED"
                    ? "#D40606"
                    : row.status === "PROCESSING"
                      ? "#5C8AFF"
                      : "black",
          }}
        >
          {row?.receivableAmount}
        </span>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color:
              row.status === "PENDING_APPROVAL"
                ? "#F08000"
                : row.status === "APPROVED"
                  ? "#2EA923"
                  : row.status === "REJECTED"
                    ? "#D40606"
                    : row.status === "PROCESSING"
                      ? "#5C8AFF"
                      : "black",
          }}
        >
          {row?.status}
        </span>
      ),
    },
    {
      cell: (row) => (
        <div ref={dropdownRef}>
          {dropdown ? (
            <PAIconCancel
              title="Options"
              onClick={() => handleDropdown(row)}
              className="w-[10px]"
            />
          ) : (
            <PAIconOption title="Options" onClick={() => handleDropdown(row)} />
          )}
          {dropdown === row.id && row && (
            <div className="relative">
              <Card
                className={`w-[160px] right-[-10px] ${atBottom && "bottom-[20px]"
                  } absolute z-10 drop-shadow-md rounded-md py-[12px]`}
              >
                <div className="flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-sm font-normal">Preview</p>
                </div>
                <div className="flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-sm font-normal">Export Request</p>
                </div>
                <div className="flex items-center gap-x-[10px] mt-[10px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-sm font-normal text-[#DE1515]">
                    Cancel Request
                  </p>
                </div>
              </Card>
            </div>
          )}
        </div>
      ),
      width: "5rem",
    },
  ];

  const columnBulkEp = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Request Ref No" />,
      selector: (row) => row?.reference,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Buyer" />,
      selector: (row) => row?.buyerCompanyName,
      width: "15rem",
    },
    {
      name:
        selectedTab === "Early Payment"
          ? "Early Payment Date"
          : "Direct Funding Date",
      selector: (row) => row?.newRequestedDate,
    },
    {
      name: "No of Invoices",
      selector: (row) => row?.noOfInvoice,
    },
    {
      name: "Request Amount",
      selector: (row) => row?.totalRequestedAmount,
    },
    {
      name: "Discount Amount",
      selector: (row) => row?.discountAmount,
    },
    {
      name: "Receivable Amount",
      selector: (row) => (
        <span
          style={{
            color:
              row.status === "PENDING_CONFIRMATION"
                ? "#F08000"
                : row.status === "APPROVED"
                  ? "#2EA923"
                  : row.status === "REJECTED"
                    ? "#D40606"
                    : row.status === "PROCESSING"
                      ? "#5C8AFF"
                      : "black",
          }}
        >
          {row?.receivableAmount}
        </span>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color:
              row.status === "PENDING_CONFIRMATION"
                ? "#F08000"
                : row.status === "APPROVED"
                  ? "#2EA923"
                  : row.status === "REJECTED"
                    ? "#D40606"
                    : row.status === "PROCESSING"
                      ? "#5C8AFF"
                      : "black",
          }}
        >
          {row?.status}
        </span>
      ),
    },
    {
      cell: (row) => (
        <div ref={dropdownRef}>
          {dropdown ? (
            <PAIconCancel
              title="Options"
              onClick={() => handleDropdown(row)}
              className="w-[10px]"
            />
          ) : (
            <PAIconOption title="Options" onClick={() => handleDropdown(row)} />
          )}
          {dropdown === row.id && row && (
            <div className="relative">
              <Card
                className={`w-[160px] right-[-10px] ${atBottom && "bottom-[20px]"
                  } absolute z-10 drop-shadow-md rounded-md py-[12px]`}
              >
                <div className="flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-sm font-normal">Preview</p>
                </div>
                <div className="flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-sm font-normal">Export Request</p>
                </div>
                <div className="flex items-center gap-x-[10px] mt-[10px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-sm font-normal text-[#DE1515]">
                    Cancel Request
                  </p>
                </div>
              </Card>
            </div>
          )}
        </div>
      ),
      width: "5rem",
    },
  ];

  //df bulk
  const columnBulk = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Request Ref No" />,
      selector: (row) => row?.reference,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Buyer" />,
      selector: (row) => row?.invoice[0]?.buyerCompanyName,
      width: "15rem",
    },
    {
      name:
        selectedTab === "Early Payment"
          ? "Early Payment Date"
          : "Direct Funding Date",
      selector: (row) => row?.newRequestedDate,
    },
    {
      name: "No of Invoices",
      selector: (row) => row?.numberOfInvoices,
    },
    {
      name: "Request Amount",
      selector: (row) => row?.newRequestedAmount,
    },
    {
      name: "Discount Amount",
      selector: (row) => row?.invoice[0]?.discountAmount,
    },
    {
      name: "Receivable Amount",
      selector: (row) => (
        <span
          style={{
            color:
              row.status === "PENDING_CONFIRMATION"
                ? "#F08000"
                : row.status === "APPROVED"
                  ? "#2EA923"
                  : row.status === "REJECTED"
                    ? "#D40606"
                    : row.status === "PROCESSING"
                      ? "#5C8AFF"
                      : "black",
          }}
        >
          {row?.receivableAmount}
        </span>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color:
              row.status === "PENDING_CONFIRMATION"
                ? "#F08000"
                : row.status === "APPROVED"
                  ? "#2EA923"
                  : row.status === "REJECTED"
                    ? "#D40606"
                    : row.status === "PROCESSING"
                      ? "#5C8AFF"
                      : "black",
          }}
        >
          {row?.status}
        </span>
      ),
    },
    {
      cell: (row) => (
        <div ref={dropdownRef}>
          {dropdown ? (
            <PAIconCancel
              title="Options"
              onClick={() => handleDropdown(row)}
              className="w-[10px]"
            />
          ) : (
            <PAIconOption title="Options" onClick={() => handleDropdown(row)} />
          )}
          {dropdown === row.id && row && (
            <div className="relative">
              <Card
                className={`w-[160px] right-[-10px] ${atBottom && "bottom-[20px]"
                  } absolute z-10 drop-shadow-md rounded-md py-[12px]`}
              >
                <div className="flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-sm font-normal">Preview</p>
                </div>
                <div className="flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-sm font-normal">Export Request</p>
                </div>
                <div className="flex items-center gap-x-[10px] mt-[10px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-sm font-normal text-[#DE1515]">
                    Cancel Request
                  </p>
                </div>
              </Card>
            </div>
          )}
        </div>
      ),
      width: "5rem",
    },
  ];

  const totalPages = total;

  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      <div className="mt5 mx6 mb-[17px] w-full cursor-pointer rounded-md bg-white">
        <div className="gap1 flex-col items-center justify-between gap-10 p-4 px-4 md:flex md:flex-row">
          <div className="flex-col gap-10 md:flex md:flex-row">
            <div
              className={
                selectedTab === "Overview"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 text-base font-normal"
              }
              onClick={() => handleTabClick("Overview")}
            >
              Overview
            </div>
            <div
              className={
                selectedTab === "Early Payment"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 my-2 text-base font-normal md:my-0"
              }
              onClick={() => handleTabClick("Early Payment")}
            >
              Early Payment
            </div>
            <div
              className={
                selectedTab === "Direct Funding"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 my-2 text-base font-normal md:my-0"
              }
              onClick={() => handleTabClick("Direct Funding")}
            >
              Directing Funding
            </div>
          </div>
        </div>
      </div>
      {selectedTab === "Overview" && (
        <div>
          <Overview directFundingBulkData={directFundingBulkData} directFundingSingleData={directFundingSingleData} />
        </div>
      )}
      {selectedTab === "Early Payment" && (
        <div>
          <div className="mt-4">
            <SupplierEarlyPaymentHeader
              statusOptions={statusOptions}
              selectedRequest={selectedRequest}
              setSelectedRequest={setSelectedRequest}
              requestOptions={requestOptions}
              onChange={handleStatusChange}
              handleBuyerNameChange={handleBuyerNameChange}
              buyerName={buyerName}
            />
          </div>
          <br />
          <div>
            <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
              <div className="flex justify-between flex-wrap ">
                <div>
                  <p className="text-black text-sm font-medium">
                    Early payment request
                  </p>
                  <p className="text-textColor text-xs font-normal">
                    {selectedRequest === "Single Request"
                      ? "List of all single early payment requests you've made"
                      : "List of all bulk early payment requests you've made"}
                  </p>
                </div>
                <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
                  <Button
                    neutral
                    // disabled={data < 1}
                    onClick={GoToRequestFlow}
                    className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-[#FFBE55] text-sm !text-black rounded-[5px] font-medium"
                  >
                    <p style={{ color: "white" }}>
                      {selectedRequest === "Single Request"
                        ? "+ Create new single request"
                        : "+ Create new bulk request"}
                    </p>
                  </Button>
                </div>
              </div>
            </header>
            <div className="p-[24px] bg-white rounded-b-[10px]">
              {selectedRequest === "Single Request" &&
                selectedTab === "Early Payment" && (
                  <Table
                    columns={column}
                    data={data}
                    pointer
                    onRowClicked={onRowClicked}
                  // progressPending={loading}
                  // progressComponent={<Loading />}
                  // tableHeader
                  />
                )}
              <div className="flex justify-center gap-x-[8px]  mt-12">
                {selectedRequest === "Single Request" &&
                  selectedTab === "Early Payment" &&
                  !loading &&
                  totalPages > 10 && (
                    <Pagination
                      page={page}
                      startPage={startPage}
                      setEndPage={setEndPage}
                      endPage={endPage}
                      setStartPage={setStartPage}
                      setPage={setPage}
                      totalPages={totalPages}
                    />
                  )}
              </div>
              {selectedRequest === "Bulk Request" &&
                selectedTab === "Early Payment" && (
                  <Table
                    columns={columnBulkEp}
                    data={bulkData}
                    pointer
                    onRowClicked={onBulkEarlyPaymentRowClicked}
                  // progressPending={loading}
                  // progressComponent={<Loading />}
                  // tableHeader
                  />
                )}
              <br />
              <div className="flex justify-center gap-x-[8px]  mt-12">
                {selectedRequest === "Bulk Request" &&
                  selectedTab === "Early Payment" &&
                  !loading &&
                  totalPages > 10 && (
                    <Pagination
                      page={page}
                      startPage={startPage}
                      setEndPage={setEndPage}
                      endPage={endPage}
                      setStartPage={setStartPage}
                      setPage={setPage}
                      totalPages={totalPages}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* direct funding section */}
      {selectedTab === "Direct Funding" && (
        <div>
          <div className="mt-4">
            <SupplierEarlyPaymentHeader
              statusOptions={statusOptions}
              header={
                selectedRequest === "Single Request" ? "Approved" : "Sort by"
              }
              selectedRequest={selectedRequest}
              handleBuyerNameChange={handleReferenceNameChange}
              setSelectedRequest={setSelectedRequest}
              requestOptions={requestOptions}
              onChange={handleDirectFundingStatusChange}
            />
          </div>
          <br />
          <div>
            <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
              <div className="flex justify-between flex-wrap ">
                <div>
                  <p className="text-black text-sm font-medium">
                    Direct Funding Request
                  </p>
                  <p className="text-textColor text-xs font-normal">
                    {selectedRequest === "Single Request"
                      ? "List of all single payment extension requests you've made"
                      : "List of all bulk payment extension requests you've made"}
                  </p>
                </div>
                <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
                  <Button
                    neutral
                    // disabled={data < 1}
                    onClick={DirectFundingRequestFlow}
                    className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-[#FFBE55] text-sm !text-black rounded-[5px] font-medium"
                  >
                    <p style={{ color: "white" }}>
                      {selectedRequest === "Single Request"
                        ? "+ Create new single request"
                        : "+ Create new bulk request"}
                    </p>
                  </Button>
                </div>
              </div>
            </header>
            <div className="p-[24px] bg-white rounded-b-[10px]">
              {selectedRequest === "Single Request" &&
                selectedTab === "Direct Funding" && (
                  <Table
                    columns={directFundingColumn}
                    data={directFundingSingleData}
                    pointer
                    onRowClicked={DirectFundingClicked}
                  // progressPending={loading}
                  // progressComponent={<Loading />}
                  // tableHeader
                  />
                )}
              <div className="flex justify-center gap-x-[8px]  mt-12">
                {selectedRequest === "Single Request" &&
                  selectedTab === "Direct Funding" &&
                  !loading &&
                  totalPages > 10 && (
                    <Pagination
                      page={page}
                      startPage={startPage}
                      setEndPage={setEndPage}
                      endPage={endPage}
                      setStartPage={setStartPage}
                      setPage={setPage}
                      totalPages={totalPages}
                    />
                  )}
              </div>
              {selectedRequest === "Bulk Request" &&
                selectedTab === "Direct Funding" && (
                  <Table
                    columns={columnBulk}
                    data={directFundingBulkData}
                    pointer
                    onRowClicked={BulkDirectFundingClicked}
                  // progressPending={loading}
                  // progressComponent={<Loading />}
                  // tableHeader
                  />
                )}
              <br />
              <div className="flex justify-center gap-x-[8px]  mt-12">
                {selectedRequest === "Bulk Request" &&
                  selectedTab === "Direct Funding" &&
                  !loading &&
                  totalPages > 10 && (
                    <Pagination
                      page={page}
                      startPage={startPage}
                      setEndPage={setEndPage}
                      endPage={endPage}
                      setStartPage={setStartPage}
                      setPage={setPage}
                      totalPages={totalPages}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};
export default SupplierEarlyPayments;
