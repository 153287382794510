import React from "react";

const RejectReasons = ({
    isOpen,
    onClose,
    handleReject,
    story,
    setStory
}) => {

    if (!isOpen) {
        return null;
    }

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
            <div className="bg-white p-5 rounded-md shadow-md flex flex-col px-10 w-[600px]">
                <div className="flex justify-between items-center w-full mb-4">
                    <span className="font-bold text-lg">Reject Reason Required</span>
                    <button
                        style={{ fontSize: 12 }}
                        onClick={onClose}
                        className="text-gray-500 hover:text-black"
                    >
                        X Close
                    </button>
                </div>
                <hr style={{ border: "0.5px solid #CACACA", marginBottom: "16px" }} />
                <div style={{ width: "95%", margin: "0 auto" }} className="mb-4">
                    <p style={{ color: "#CACACA", fontWeight: 500, fontSize: 15 }}>
                        Please enter a reason(s) that will be sent to the recipient Samuel
                        James (Buyer)
                    </p>
                    <p style={{ color: "#CACACA", paddingTop: 2, fontWeight: 500, fontSize: 15 }}>
                        Thank you for your valuable feedback
                    </p>
                </div>
                <br />
                <hr style={{ border: "0.5px solid #CACACA", marginBottom: "16px" }} />
                <div className="pt-4">
                    <label
                        style={{ color: "#06593D", fontWeight: 500, fontSize: 12 }}
                    >
                        Input your reply
                    </label>
                </div>
                <textarea
                    value={story}
                    onChange={(e) => setStory(e.target.value)}
                    placeholder=""
                    className="w-full h-32 p-2 border border-gray-300 rounded-md resize-none focus:outline-none focus:ring-1 focus:ring-[#06593D]"
                ></textarea>

                <div className="w-full mt-4">
                    <button
                        onClick={handleReject}
                        disabled={!story.length}
                        className={`w-full flex items-center justify-center bg-primaryColor text-white px-6 py-3 rounded-md  ${!story.length ? "opacity-50 cursor-not-allowed" : ""
                            }`}
                    >
                        <span>Submit</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RejectReasons;
