import React, { useEffect } from "react";

import WonBidCard from "./card/WonBidCard";
import Pagination from "shared/Pagination";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allClosedMarketWonBids } from "appstate/funder/funderSlice";
import TableEmptyState from "shared/TableEmptyState";

const WonBid = ({ closedMarketQuery }) => {
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      allClosedMarketWonBids({
        page: 0,
        pageSize: 10,
      })
    );
  }, [dispatch]);

  const { allClosedMarketWonBidsData } = useSelector((state) => state?.funder);
  // console.log("ddd: ", allClosedMarketWonBidsData);

  const totalPages = allClosedMarketWonBidsData?.meta?.totalElements;
  return (
    <div>
      <div className="flex flex-col  rounded-[10px] bg-unusualGray  px-6 mt-2 py-7 lg:min-h-[750px] md:min-h-[750px]">
        {/* <div className="flex flex-col gap-2.5 "> */}
        <div className="w-full  overflow-x-auto  ">
          {allClosedMarketWonBidsData?.data?.length > 0 ? (
            <div className="flex flex-col gap-2.5 ">
              {allClosedMarketWonBidsData?.data?.map((bids, idx) => (
                <WonBidCard
                  key={idx}
                  id={bids.id}
                  closedMarketReference={bids.closedMarketReference}
                  buyerCompanyName={bids.buyerCompanyName}
                  invoiceType={bids.invoiceType}
                  lockRate={bids.lockRate}
                  status={bids.status}
                  requestAmount={bids.requestAmount}
                  dueDate={bids?.invoiceDueDate}
                  earlyPaymentDate={bids.earlyPaymentDate}
                  supplierCompanyName={bids.supplierCompanyName}
                  bulk={bids.bulk}
                />
              ))}
            </div>
          ) : (
            <TableEmptyState dashboard />
          )}
        </div>
      </div>

      <div className="flex justify-center gap-x-[8px] mt-[20px]">
        {totalPages > 10 && (
          <Pagination
            page={page}
            startPage={startPage}
            setEndPage={setEndPage}
            endPage={endPage}
            setStartPage={setStartPage}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </div>
    </div>
  );
};

export default WonBid;
