import {
  PAIconCancel,
  PAIconInviteLink,
  PAIconInviteRemove,
} from "assets/images/svgs";
import Button from "shared/Button";
import InputField from "shared/InputField";
import SuccessModal from "./SuccessModal";
import Spinner from "shared/Spinner/Spinner";
// in progress

const InvitationModal = ({
  onClose,
  message,
  heading,
  info,
  role,
  invite,
  setInvite,
  newInvite,
  setNewInvite,
  handleAdd,
  success,
  isLoading,
  funderBuyer
}) => {
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (e) => {
    setNewInvite(e.target.value);
  };

  const handleAddEmail = () => {
    if (newInvite.trim() !== "") {
      if (isValidEmail(newInvite)) {
        setInvite([...invite, newInvite]);
        setNewInvite("");
      } else {
        alert("Please enter a valid email address");
      }
    }
  };

  const handleRemoveEmail = (index) => {
    const updatedFunder = invite.filter((_, i) => i !== index);
    setInvite(updatedFunder);
  };

  const handleCopyLink = () => {
    if (invite?.length > 0) {
      const funderList = invite.join(", ");
      navigator.clipboard.writeText(funderList).then(() => {
        alert("copied to clipboard!");
      }).catch((err) => {
        console.error("Failed to copy funder(s): ", err);
      });
    } else {
      alert("No funders to copy!");
    }
  };

  return (
    <>
      {success ? (
        <SuccessModal onClose={onClose} message={message} />
      ) : (
        <div className="py-[38px] px-[44px]">
          <div className="flex justify-between items-center border-b-[1px] border-dustyGray pb-[10px]">
            <h1 className="text-lg font-semibold text-textColor">{heading}</h1>
            <PAIconCancel
              onClick={onClose}
              className="w-[13.184px] cursor-pointer"
            />
          </div>
          <p className="text-xs mt-[16px] text-gray800">{info}</p>
          <div className="mt-[34px] border-b-[1px] border-dustyGray pb-[40px]">
            <InputField
              onChange={handleInputChange}
              value={newInvite}
              placeholder={funderBuyer ? "Email of buyer" : "Email of suppliers"}
              className="border-[1px] !border-primaryColor rounded-[5px] px-[16px] outline-none"
            />
            <div className="flex justify-end mt-[5px]">
              <Button
                neutral
                onClick={handleAddEmail}
                disabled={!isValidEmail(newInvite)}
                className="py-[8px] px-[30px] bg-white !text-primaryColor border-[1px] border-primaryColor rounded-[5px] text-xs"
              >
                Add
              </Button>
            </div>
            {invite?.length > 0 && (
              <p className="text-base font-semibold text-primaryColor mb-[30px]">
                {`Invite ${invite?.length} ${role}`}
              </p>
            )}
            {invite?.map((funder, index) => (
              <div
                key={index}
                className="flex justify-between items-center mb-[18px]"
              >
                <p className="text-sm text-textColor">{funder}</p>
                <Button
                  neutral
                  onClick={() => handleRemoveEmail(index)}
                  className="py-[8px] px-[16px] border-[1px] border-primaryColor !text-primaryColor text-xs rounded-[5px] flex gap-2 justify-between items-center"
                >
                  <PAIconInviteRemove />
                  remove
                </Button>
              </div>
            ))}
          </div>
          <div className="flex justify-between mt-[30px]">
            <div
              className="flex items-center gap-x-[5px] cursor-pointer"
              onClick={handleCopyLink}
            >
              <PAIconInviteLink />
              <p className="text-xs text-primaryColor">Copy link</p>
            </div>
            <Button
              neutral
              disabled={invite?.length === 0 || isLoading}
              onClick={handleAdd}
              className="w-[142px] h-[42px] bg-primaryColor text-white rounded-[5px] text-xs"
            >
              {isLoading ? <Spinner /> : "Invite"}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default InvitationModal;
