import { getCurrentLockRate } from "appstate/openMarket/openMarketSlice";
import { PAIconCancelMini } from "assets/images/svgs";
import { motion } from "framer-motion";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

const OfferDetailsModal = ({
  id,
  onClose,
  onAction,
  update,
  discountRate,
  setDiscountRate,
}) => {
  const dispatch = useDispatch();
  const handleDiscountRateChange = (e) => {
    const value = e.target.value.replace("%", "");
    if (value === "" || /^[0-9]*\.?[0-9]*$/.test(value)) {
      setDiscountRate(value);
    }
  };

  const { getCurrentLockRateData } = useSelector((state) => state?.openMarket);

  useEffect(() => {
    dispatch(getCurrentLockRate(id));
  }, [dispatch, id]);

  const handleFocus = (e) => {
    const { target } = e;
    const len = target.value.length;
    target.setSelectionRange(len - 1, len - 1);
  };

  const handleCursorPosition = (e) => {
    const { target } = e;
    const len = target.value.length;
    if (target.selectionStart === len) {
      target.setSelectionRange(len - 1, len - 1);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!discountRate) {
      return toast.error("Kindly provide a discount rate to proceed");
    }

    if (discountRate >= getCurrentLockRateData?.data?.lockRate) {
      return toast.error(
        `Bid discount rate (${discountRate}%) cannot exceed or be equal to global maximum rate (${getCurrentLockRateData?.data?.lockRate}%)!`
      );
    }
    onAction();
  };

  return (
    <motion.div>
      <motion.div className="bg-slate-600/20 fixed inset-0 flex items-center justify-center z-50">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.2 } }}
          exit={{ opacity: 0, transition: { delay: 0.1 } }}
          className="modal-overlay"
        ></motion.div>

        <motion.form
          onSubmit={handleSubmit}
          initial={{ scale: 0 }}
          animate={{ scale: 1, transition: { duration: 0.2 } }}
          exit={{ scale: 0, transition: { delay: 0.1 } }}
          className="pt-9 bg-white overflow-auto rounded-[10px] min-w-[33%] max-w-[60%] lg:max-w-[47%] px-6 max-h-[80%] lg:max-h-[100%] min-h[50%] pb-10 flex flex-col gap-1"
        >
          <header className="flex border-b-[4px] border-[#F6F5FA] pb-4 justify-between">
            <span className="font-bold">Bid Details</span>

            <span
              onClick={() => onClose()}
              className="flex cursor-pointer items-center gap-2"
            >
              <PAIconCancelMini /> Close
            </span>
          </header>

          <section className="flex flex-col gap-6">
            {update ? null : (
              <div className="text-sm">
                Please input your invoice rate. <br />
                Please be aware that the bidding option available to you is with{" "}
                <br />
                <span className="font-bold text-secondaryColor">Recourse</span>,
                as you are not associated with the buyer.
                <div className="font-bold text-primaryColor">
                  Current bid rate: {getCurrentLockRateData?.data?.lockRate}%
                </div>
                <div className="font-medium mt-2 text-secondaryColor">
                  You can cancel your bid within 30 seconds of placing it
                </div>
              </div>
            )}

            <input
              className="w-full border border-primaryColor p-2.5 rounded-[5px] outline-none"
              type="text"
              value={discountRate ? `${discountRate}%` : ""}
              onChange={handleDiscountRateChange}
              inputMode="decimal"
              onFocus={handleFocus}
              onClick={handleCursorPosition}
              onKeyUp={handleCursorPosition}
            />

            <motion.button
              whileTap={{ scale: 0.96 }}
              className="p-2.5 rounded-[5px] bg-primaryColor text-white"
            >
              {update ? "Update Bid" : "Bid on Request"}
            </motion.button>
          </section>
        </motion.form>
      </motion.div>
    </motion.div>
  );
};

export default OfferDetailsModal;
