import { PAIconFilter, PAIconOutlineSearch } from "assets/images/svgs";
import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import Button from "shared/Button";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignOffersInvoices } from "appstate/buyer/buyerSlice";
import Pagination from "shared/Pagination";

function StepThree({
  handlePreviousPage,
  handleNextPage,
  formData,
  reverse,
  setSelectedInvoice,
  setCurrentPage,
}) {
  const [data, setData] = useState([]);
  const { offerInvoices } = useSelector((state) => state.buyer);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const totalPages = offerInvoices?.meta?.totalElements;
  const [endPage, setEndPage] = useState(5);
  const dispatch = useDispatch();
  const pageSize = 10;
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [totalDiscount, setTotalDiscount] = useState("");
  const [totalYield, setTotalYield] = useState("");
  const [totalOffer, setTotalOffer] = useState("");
  const [totalPayable, setTotalPayable] = useState("");

  useEffect(() => {
    const body = {
      programName: formData.programName || "",
      financingOption: formData.financingOptions || "",
      startDate: formData.startDate || "",
      endDate: formData.endDate || "",
      tierCategory: formData.tiers || [],
      discountRate: parseFloat(formData.discount) || 0,
      minimumAmount: parseFloat(formData.minAmount) || 0,
      maximumAmount: parseFloat(formData.maxAmount) || 0,
      funderPercentage:
        reverse === true ? parseFloat(100 - formData.buyerShare) || 0 : 0,
      buyerPercentage: parseFloat(formData.buyerShare) || 0,
      industryTypes: formData.industries || [],
      paymentDuration: parseInt(formData.paymentDuration) || 0,
      invoiceIds: [],
      documentId: formData.agreemntDocument || "",
      funderIds: formData.funderIds || [],
      fundingAccountId: formData.accounts || "",
      offerPercentage: parseFloat(formData.percentage) || 0,
    };

    dispatch(getCampaignOffersInvoices(body, page, pageSize));
  }, [dispatch, formData, page, pageSize, reverse]);

  useEffect(() => {
    setData(offerInvoices?.data);
  }, [offerInvoices]);

  const handleViewMembers = () => {
    setCurrentPage(5);
  };

  const handleCheckboxChange = (row) => {
    const selectedIndex = selectedRows?.indexOf(row.invoiceId);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, row.invoiceId];
    } else {
      newSelectedRows = selectedRows.filter((id) => id !== row.invoiceId);
    }

    setSelectedRows(newSelectedRows);

    const totalAmount = newSelectedRows.reduce((total, id) => {
      const selectedRow = data.find((r) => r.invoiceId === id);
      return total + (selectedRow ? selectedRow.discountAmount : 0);
    }, 0);

    setTotalDiscount(parseFloat(totalAmount.toFixed(1)));

    const totalOfferAmount = newSelectedRows.reduce((total, id) => {
      const selectedRow = data.find((r) => r.invoiceId === id);
      return total + (selectedRow ? selectedRow.offerAmount : 0);
    }, 0);

    setTotalOffer(parseFloat(totalOfferAmount.toFixed(1)));

    const totalPayableAmount = newSelectedRows.reduce((total, id) => {
      const selectedRow = data.find((r) => r.invoiceId === id);
      return total + (selectedRow ? selectedRow.payableAmount : 0);
    }, 0);

    setTotalPayable(parseFloat(totalPayableAmount.toFixed(1)));

    const totalYieldAmount = newSelectedRows.reduce((total, id) => {
      const selectedRow = data.find((r) => r.invoiceId === id);
      return total + (selectedRow ? selectedRow.payableAmount : 0);
    }, 0);

    setTotalYield(parseFloat(totalYieldAmount.toFixed(1)));
  };


  const handleSelectAll = () => {
    if (selectAll) {
      // Deselect all
      setSelectedRows([]);
      // Reset totals
      setTotalDiscount(0);
      setTotalOffer(0);
      setTotalPayable(0);
      setTotalYield(0);
    } else {
      // Select all
      const allIds = data?.map((row) => row.invoiceId);
      setSelectedRows(allIds);

      // Calculate totals for all selected rows
      const totalAmount = data?.reduce(
        (total, row) => total + (row.discountAmount || 0),
        0
      );
      setTotalDiscount(parseFloat(totalAmount.toFixed(1)));

      const totalOfferAmount = data?.reduce(
        (total, row) => total + (row.offerAmount || 0),
        0
      );
      setTotalOffer(parseFloat(totalOfferAmount.toFixed(1)));

      const totalPayableAmount = data.reduce(
        (total, row) => total + (row.payableAmount || 0),
        0
      );
      setTotalPayable(parseFloat(totalPayableAmount.toFixed(1)));

      const totalYieldAmount = data.reduce(
        (total, row) => total + (row.payableAmount || 0),
        0
      );
      setTotalYield(parseFloat(totalYieldAmount.toFixed(1)));
    }

    setSelectAll(!selectAll);
  };

  const buyerYield = (totalYield * formData.buyerShare) / 100;


  const handleProceed = () => {
    setSelectedInvoice(selectedRows);
    handleNextPage();
  };

  const column = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      selector: "",
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row.invoiceId)}
        />
      ),
      width: "50px",
    },
    {
      name: "Invoice Number",
      selector: (row) => `INV-${row?.invoiceNo}`,
      sortable: true,
    },
    {
      name: "Supplier",
      selector: (row) => row?.supplierName,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row?.currency,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
      sortable: true,
    },
    {
      name: "Payment Date",
      selector: (row) => row?.paymentDate,
      sortable: true,
    },
    {
      name: "Invoiced Amount",
      selector: (row) => row?.invoiceAmount.toLocaleString(),
      sortable: true,
    },
    {
      name: "Offer Amount",
      selector: (row) => row?.offerAmount.toLocaleString(),
      sortable: true,
    },
    {
      name: "Discount Amount",
      selector: (row) => row?.discountAmount.toLocaleString(),
      sortable: true,
    },
    {
      name: "Payable Amt",
      selector: (row) => row?.payableAmount.toLocaleString(),
      sortable: true,
    },
  ];

  return (
    <div className="mx-[3.5rem]">
      <header className="flex justify-between">
        <h1 className="text-textColor text-sm font-bold">
          Number of Invoices in campaign:{" "}
          <span className="font-medium">{data?.length || 0}</span>
        </h1>
        <p
          onClick={handleViewMembers}
          className="text-textColor text-sm underline cursor-pointer"
        >
          View Members
        </p>
      </header>
      <p className="text-textColor text-sm mt-[28px]">
        Invoices from Suppliers in campaign
      </p>
      <p className="text-[#BFBFBF] text-xs mt-[8px]">
        Select invoices for the campaign
      </p>
      <div className="border border-brightGray rounded-[0.5rem] px-[1.69rem] pb-[1.69rem] mt-[8px]">
        <div className="flex justify-between">
          <div className="flex items-center gap-x-[8px]">
            <div className="flex items-center gap-x-[8px] border border-silver px-[1.25rem] rounded text-sm h-[45px]">
              <p>Filter</p>
              <span className=" bg-[#E6E1FF] rounded-[6px] px-2 py-[2px]">
                {selectedRows?.length}
              </span>
              <p>Selected</p>
            </div>
            <div className="border-[1px] border-silver px-[0.25rem] rounded h-[45px] flex items-center justify-center">
              <select
                className="py-[0.625rem] px-[0.8rem] outline-none text-sm text-textColor"
                name=""
                id=""
              >
                <option value="">Sort by</option>
              </select>
            </div>
            <Button
              neutral
              // onClick={setOpen ? () => setOpen(true) : handleNavigate}
              className="flex !text-[#565656] gap-2 text-xs font-medium items-center mb-[10px] md:mb-0 border border-[#ccc] md:px-[5px] bg-white py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.75 14.75H2.25C1.97344 14.75 1.75 14.5266 1.75 14.25V2.75C1.75 2.47344 1.97344 2.25 2.25 2.25H7.875C7.94375 2.25 8 2.30625 8 2.375V3.25C8 3.31875 7.94375 3.375 7.875 3.375H2.875V13.625H13.125V8.625C13.125 8.55625 13.1812 8.5 13.25 8.5H14.125C14.1938 8.5 14.25 8.55625 14.25 8.625V14.25C14.25 14.5266 14.0266 14.75 13.75 14.75ZM12.0448 3.61142L11.2292 2.7958C11.1558 2.72236 11.1995 2.5958 11.3027 2.5833L14.1058 2.25517C14.1855 2.2458 14.2542 2.31298 14.2448 2.39423L13.9167 5.19736C13.9042 5.30048 13.7777 5.34423 13.7042 5.2708L12.8855 4.45205L8.88234 8.45517C8.83391 8.50361 8.75422 8.50361 8.70578 8.45517L8.04328 7.79267C7.99484 7.74423 7.99484 7.66455 8.04328 7.61611L12.0448 3.61142Z"
                  fill="#565656"
                />
              </svg>
              <p className="whitespace-nowrap">Export</p>
            </Button>
          </div>
          <div>
            <div className="flex md:col-span-1 justify-end">
              <div className="flex items-center justify-between gap-5">
                <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
                  <div className="items-center flex bg-white">
                    <PAIconOutlineSearch />
                    <input
                      className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                      type="text"
                      placeholder="Search for request"
                      // value={searchQuery}
                      // onChange={(e) => setSearchQuery(e.target.value)}
                      // onKeyPress={(e) => {
                      //   if (e.key === "Enter") {
                      //     handleSearch();
                      //   }
                      // }}
                    />
                  </div>
                  <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                    <PAIconFilter className="bg-[#2FA06A]" />
                  </div>
                </div>
                <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
                  <PAIconFilter /> Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <Table
          columns={column}
          data={data}
          pointer
          tableHeader
          className="mt-5"
        />
      </div>
      <div className="flex gap-x-[2.5rem] mt-[1.75rem]">
        <div className="flex gap-x-[5px] items-center">
          <p className="text-lightGray text-[0.875rem]">
            Total Discount Amount:
          </p>
          <h1 className="text-primaryColor text-[1.125rem] font-bold">
            NGN{" "}
            {totalDiscount === "" ? "0.00 " : totalDiscount.toLocaleString()}
          </h1>
        </div>

        <div className="flex gap-x-[5px] items-center">
          <p className="text-lightGray text-[0.875rem]">
            Total Payable Amount:
          </p>
          <h1 className="text-primaryColor text-[1.125rem] font-bold">
            NGN {totalPayable === "" ? "0.00" : totalPayable.toLocaleString()}
          </h1>
        </div>

        {formData?.financingOptions === "REVERSE_FACTORING" && (
          <div className="flex gap-3 items-center">
            <div className="flex gap-x-[5px] items-center">
              <p className="text-lightGray text-[0.875rem]">Buyer's Yield:</p>
              <h1 className="text-primaryColor text-[1.125rem] font-bold">
                NGN {buyerYield === "" ? "0.00" : buyerYield.toLocaleString()}
              </h1>
            </div>

            <div className="flex gap-x-[5px] items-center">
              <p className="text-lightGray text-[0.875rem]">
                Total Offer Amount:
              </p>
              <h1 className="text-primaryColor text-[1.125rem] font-bold">
                NGN {totalOffer === "" ? "0.00" : totalOffer.toLocaleString()}
              </h1>
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-center gap-x-[8px] mt-[20px]">
        {totalPages > 10 && (
          <Pagination
            page={page}
            startPage={startPage}
            setEndPage={setEndPage}
            endPage={endPage}
            setStartPage={setStartPage}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </div>
      <div className="flex justify-between mt-[1.88rem]">
        <Button
          neutral
          className=" py-[0.75rem] px-[1.5rem] !text-lightGray !text-[0.875rem] bg-alabasterHeader rounded-[0.25rem]"
          onClick={handlePreviousPage}
        >
          Previous
        </Button>

        <Button
          neutral
          className=" py-[0.75rem] px-[1.5rem] !text-white !text-[0.875rem] bg-primaryColor rounded-[0.25rem]"
          onClick={handleProceed}
        >
          Preview
        </Button>
      </div>
    </div>
  );
}

export default StepThree;
