import { motion } from "framer-motion";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detailsBuyerOpenMarket } from "appstate/openMarket/openMarketSlice";
import InvoiceDetails from "features/openMarket/common/components/InvoiceDetails";
import BidDetails from "./OpenMarkretBidDetails";

function ActiveInvoiceDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(detailsBuyerOpenMarket({ openMarketReference: id }));
  }, [dispatch, id]);

  const { detailsBuyerOpenMarketData } = useSelector(
    (state) => state?.openMarket
  );
  const invoiceByIdData = detailsBuyerOpenMarketData;

  function handleGoBack() {
    navigate(-1); // Navigate back one step
  }

  return (
    <DashboardLayout subHeader="Here is what is happening in your finance">
      <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
        <header onClick={handleGoBack} className="w-full">
          <div className="flex cursor-pointer w-fit gap-1 items-center">
            <PAIconArrowLeftGreen />
            <span className=" text-primaryColor text-sm">Go back</span>
          </div>
        </header>

        <main className="flex flex-col semi-lg:flex-row pb-8 gap-8 w-full ">
          {/* <InvoiceDetails
            invoiceByIdData={invoiceByIdData}
            // setLockModalOpen={setLockModalOpen}
          /> */}
          <InvoiceDetails
            invoiceByIdData={invoiceByIdData}
            status={invoiceByIdData?.data?.status}
          />

          <motion.section
            initial={{
              y: -20,
            }}
            animate={{
              y: 0,
            }}
            exit={{
              y: 20,
            }}
            transition={{
              delay: 0.8,
            }}
            className="flex flex-col gap-3  pt10 pb-24 w-full semi-lg:w-2/5 bg-white rounded-[10px]"
          >
            <BidDetails paymentDate={invoiceByIdData?.data?.paymentDate} />
          </motion.section>
        </main>
      </TransitionScale>
    </DashboardLayout>
  );
}

export default ActiveInvoiceDetails;
