import React from 'react';

const ViewMore = ({ onClose, discount, invoice, discountRateWithRecourse }) => {
    return (
        <div  className={`${
            invoice ? "w-full" : "w-[858px]"
          } fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50`}>
            <div className="bg-white w-80 p-4 rounded shadow-lg z-30 relative">
                <button 
                    onClick={onClose} 
                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 focus:outline-none"
                    aria-label="Close"
                >
                    X
                </button>
                <br />
                <span className='mt-4'>
                    <span style={{ color: "orange" }}>The discount rate of {discountRateWithRecourse || discountRateWithRecourse === 0 ? discountRateWithRecourse : discount}% </span>
                     indicates the rate at which the funder is willing to finance this invoice.
                </span>
            </div>
        </div>
    );
};

export default ViewMore;
