import { AnimatePresence, motion } from "framer-motion";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect, useState } from "react";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import toast from "react-hot-toast";
import OfferDetailsModal from "./modal/OfferDetailsModal";
import InvoiceLockSuccessModal from "./modal/InvoiceLockSuccessModal";
import { useDispatch, useSelector } from "react-redux";
import {
  detailsBuyerOpenMarket,
  funderDetailsOpenMarketBid,
  funderPlaceBidOpenMarket,
  getCurrentLockRate,
  placeInvoiceBid,
  updateInvoice,
  // getInvoiceBidByStatus,
} from "appstate/openMarket/openMarketSlice";
import { toggleCancelButtonOff } from "./buttonSlice";
import InvoiceDetails from "features/openMarket/common/components/InvoiceDetails";
import SelectAccountModal from "./modal/SelectAccountModal";
import VerifyLien from "./modal/VerifyLienModal";
import EnterPin from "./modal/Pin";
import useCountdown from "utilities/helper/useCountdown";
import { formatDate } from "utilities/helper/dateFormatterSpelt";

function ViewInvoiceDetails() {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const offerId = searchParams.get("offerId");

  const invoiceId = searchParams.get("invoiceId");
  const dispatch = useDispatch();

  const { userId } = useSelector((state) => state?.auth);
  const roleIndex = userId?.realm_access?.roles?.findIndex((arr) => {
    return (
      arr === "SUPPLIER" ||
      arr === "BUYER" ||
      arr === "FUNDER" ||
      arr === "ADMIN"
    );
  });
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();
  const navigate = useNavigate();
  const buttonClicked = useSelector(
    (state) => state?.invoiceButton?.buttonClicked
  );

  useEffect(() => {
    dispatch(detailsBuyerOpenMarket({ openMarketReference: id }));
    dispatch(funderDetailsOpenMarketBid(id));

    dispatch(getCurrentLockRate(id));
  }, [dispatch, id]);

  const { detailsBuyerOpenMarketData, getCurrentLockRateData } = useSelector(
    (state) => state?.openMarket
  );

  const refNumber = detailsBuyerOpenMarketData?.data?.invoiceReference;
  const [bidAmount, setBidAmount] = useState("");
  const [discountRate, setDiscountRate] = useState("");
  const [noteToSupplier, setNoteToSupplier] = useState("");
  const [selectedAccountDetails, setSelectedAccountDetails] = useState("");

  const [bidModalOpen, setBidModalOpen] = useState(false);
  const [offerDetailModalOpen, setOfferDetailModalOpen] = useState(false);
  const [bidRequest, setBidRequest] = useState(false);
  const [selectAccountModal, setSelectAccountModal] = useState(false);
  const [confirmSelectedAccountModal, setConfirmSelectedAccountModal] =
    useState(false);
  const [pinModal, setPinModal] = useState(false);
  const [verifyLienStatusModal, setVerifyLienStatusModal] = useState(false);
  const [invoiceLockedSuccessModal, setInvoiceLockedSuccessModal] =
    useState(false);

  function handleGoBack() {
    dispatch(toggleCancelButtonOff());
    navigate(-1); // Navigate back one step
  }

  // Handle Bid
  const handleBid = () => {
    const body = { amount: bidAmount, discountRate, note: noteToSupplier };
    dispatch(placeInvoiceBid({ id, body })).then((data) => {
      if (data?.payload?.success) {
        toast.success("Your bid has been sent succesfully");
        setBidAmount("");
        setDiscountRate("");
        setNoteToSupplier("");
      }
    });
  };

  // Handle Update Detail
  const handleUpdate = () => {
    const body = { amount: bidAmount, discountRate, note: noteToSupplier };
    dispatch(updateInvoice({ id: offerId, body })).then((res) => {
      if (res?.payload?.success) {
        toast.success("Your bid has been updated succesfully");
        setBidAmount("");
        setDiscountRate("");
        setNoteToSupplier("");
      }
    });
  };

  const handleDiscountRateChange = (e) => {
    const value = e.target.value.replace("%", "");
    if (value === "" || /^[0-9]*\.?[0-9]*$/.test(value)) {
      setDiscountRate(value);
    }
  };

  const handleFocus = (e) => {
    const { target } = e;
    const len = target.value.length;
    target.setSelectionRange(len - 1, len - 1);
  };

  const handleCursorPosition = (e) => {
    const { target } = e;
    const len = target.value.length;
    if (target.selectionStart === len) {
      target.setSelectionRange(len - 1, len - 1);
    }
  };

  // const handleAction = () => {
  //   setOfferDetailModalOpen(false);
  //   Promise.resolve().then(() => {
  //     setBidRequest(true);
  //   });
  // };

  const handleAction = () => {
    setOfferDetailModalOpen(false);
    setTimeout(() => {
      setBidRequest(true);
    }, 500);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!discountRate) {
      return toast.error("Kindly provide a discount rate to proceed");
    }

    if (discountRate >= getCurrentLockRateData?.data?.lockRate) {
      return toast.error(
        `Bid discount rate (${discountRate}%) cannot exceed or be equal to global maximum rate (${getCurrentLockRateData?.data?.lockRate}%)!`
      );
    }

    setOfferDetailModalOpen(true);

    // handleAction();
  };

  const handleProceedToSelectAccount = () => {
    setBidRequest(false);
    setTimeout(() => {
      setSelectAccountModal(true);
    }, 500);
  };

  const handleConfirmAccount = () => {
    setSelectAccountModal(false);
    setTimeout(() => {
      setConfirmSelectedAccountModal(true);
    }, 500);
  };

  const handleProceedEnterPin = () => {
    setConfirmSelectedAccountModal(false);
    setTimeout(() => {
      setPinModal(true);
    }, 500);
  };

  const handlePlaceBid = (pin) => {
    const pendingToast = toast.loading("Placing your Bid...");
    const body = {
      discountRate,
      note: "",
      virtualAccountId: selectedAccountDetails?.virtualAccountId,
      transactionPin: pin,
    };

    dispatch(funderPlaceBidOpenMarket({ invoiceId, body })).then((data) => {
      if (data?.payload?.success) {
        toast.dismiss(pendingToast);
        setPinModal(false);
        setVerifyLienStatusModal(true);
        setDiscountRate("");
      } else {
        toast.dismiss(pendingToast);
      }
    });
  };
  const { timeLeft, isTimeUnderOneHour } = useCountdown(
    formatDate(detailsBuyerOpenMarketData?.data?.paymentDate)
  );

  return (
    <DashboardLayout subHeader="Here is what is happening in your finance">
      <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
        <header onClick={handleGoBack} className="w-full">
          <div className="flex cursor-pointer w-fit gap-1 items-center">
            <PAIconArrowLeftGreen />
            <span className=" text-primaryColor text-sm">Go back</span>
          </div>
        </header>

        <main className="flex flex-col semi-lg:flex-row pb-8 gap-8 w-full ">
          <InvoiceDetails
            player={userRole}
            invoiceByIdData={detailsBuyerOpenMarketData}
            // setLockModalOpen={setLockModalOpen}
          />

          <motion.section
            initial={{
              y: -20,
            }}
            animate={{
              y: 0,
            }}
            exit={{
              y: 20,
            }}
            transition={{
              delay: 0.8,
            }}
            className="flex flex-col gap-3 px-6  pt-7 pb-24 w-full semi-lg:w-2/5 bg-white rounded-[10px]"
          >
            <header className="flex pb-2 justify-between">
              <span className="font-bold">Bid Details</span>

              <span>
                <section
                  className={`flex ${isTimeUnderOneHour ? "text-red" : ""}`}
                >
                  <div className="flex">
                    <div className="flex items-center justify-center flex-col">
                      <span className="font-semibold">{timeLeft.days}</span>
                      <span className="text-xs">days</span>
                    </div>
                    <span>:</span>
                  </div>

                  <div className="flex">
                    <div className="flex items-center justify-center flex-col">
                      <span className="font-semibold">{timeLeft.hours}</span>
                      <span className="text-xs">hours</span>
                    </div>
                    <span>:</span>
                  </div>

                  <div className="flex gap-0">
                    <div className="flex items-center justify-center flex-col">
                      <span className="font-semibold">{timeLeft.minutes}</span>
                      <span className="text-xs">mins</span>
                    </div>
                    <span>:</span>
                  </div>

                  <div className="flex">
                    <div className="flex  items-center justify-center flex-col">
                      <span className="font-semibold">{timeLeft.seconds}</span>
                      <span className="text-xs">secs</span>
                    </div>
                  </div>
                </section>
              </span>
            </header>

            <section className="flex flex-col gap-6">
              <div className="text-sm">
                Please input your invoice rate. <br />
                Please be aware that the bidding option available to you is with{" "}
                <br />
                <span className="font-bold text-secondaryColor">Recourse</span>,
                as you are not associated with the buyer.
                <div className="font-bold text-primaryColor">
                  Current bid rate: {getCurrentLockRateData?.data?.lockRate}%
                </div>
              </div>

              <input
                className="w-full border border-primaryColor p-2.5 rounded-[5px] outline-none"
                type="text"
                value={discountRate ? `${discountRate}%` : ""}
                onChange={handleDiscountRateChange}
                inputMode="decimal"
                onFocus={handleFocus}
                onClick={handleCursorPosition}
                onKeyUp={handleCursorPosition}
              />

              <motion.button
                whileTap={{ scale: 0.96 }}
                className="p-2.5 rounded-[5px] bg-primaryColor text-white"
                onClick={handleSubmit}
              >
                Bid on Request
              </motion.button>
            </section>
            {/* Section */}
          </motion.section>
        </main>
      </TransitionScale>
      {/* Bid Modal */}
      <AnimatePresence>
        {bidModalOpen && (
          <ViewDocumentModal
            onAction={buttonClicked ? handleUpdate : handleBid}
            onClose={() => setBidModalOpen(false)}
          >
            <span className=" text-center">
              Would you like to proceed with{" "}
              {buttonClicked ? "updating you bid on" : "bidding on"} this
              invoice
              <br />
              <span className="font-bold text-center text-primaryColor">
                {id}
              </span>
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {/* Offer Detail Modal */}
      <AnimatePresence>
        {offerDetailModalOpen && (
          <OfferDetailsModal
            discountRate={discountRate}
            setDiscountRate={setDiscountRate}
            onAction={handleAction}
            onClose={() => setOfferDetailModalOpen(false)}
          />
        )}
      </AnimatePresence>

      {/* Lock Modal */}
      <AnimatePresence>
        {bidRequest && (
          <ViewDocumentModal
            onAction={() => handleProceedToSelectAccount()}
            onClose={() => setBidRequest(false)}
            proceed="Yes Proceed"
          >
            <span className=" text-center">
              Are you sure you want to{" "}
              <span className="font-bold text-center text-primaryColor">
                Bid
              </span>{" "}
              on this request with reference number{" "}
              <span className="font-bold text-center text-primaryColor">
                {refNumber}
              </span>{" "}
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {/* Select Account Modal */}
      <AnimatePresence>
        {selectAccountModal && (
          <SelectAccountModal
            setSelectedAccountDetails={setSelectedAccountDetails}
            onClose={() => setSelectAccountModal(false)}
            onAction={() => {
              handleConfirmAccount();
            }}
          />
        )}
      </AnimatePresence>

      {/* Approve Selected Account */}
      <AnimatePresence>
        {confirmSelectedAccountModal && (
          <ViewDocumentModal
            onAction={() => handleProceedEnterPin()}
            onClose={() => setConfirmSelectedAccountModal(false)}
            proceed="Yes Proceed"
          >
            <span className=" text-center">
              Are you sure you want to proceed with this account{" "}
              <span className="font-bold text-center text-primaryColor">
                {selectedAccountDetails?.accountNumber}
              </span>{" "}
              to bid on this invoice
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {/* Proceed to Enter pin */}
      <AnimatePresence>
        {pinModal && (
          <EnterPin
            onClose={() => setPinModal(false)}
            onSubmit={(pin) => {
              handlePlaceBid(pin);
            }}
          />
        )}
      </AnimatePresence>

      {verifyLienStatusModal && (
        <VerifyLien
          onClose={() => setVerifyLienStatusModal(false)}
          onAction={() => {
            setVerifyLienStatusModal(false);
            setTimeout(() => {
              setInvoiceLockedSuccessModal(true);
            }, 500);
          }}
        />
      )}

      <AnimatePresence>
        {invoiceLockedSuccessModal && (
          <InvoiceLockSuccessModal
            setActiveTab={() => {
              sessionStorage.setItem("activeTab", 3);
              navigate("/open-market");
            }}
            onClose={() => setInvoiceLockedSuccessModal(false)}
          />
        )}
      </AnimatePresence>
    </DashboardLayout>
  );
}

export default ViewInvoiceDetails;
