import { AnimatePresence, motion } from "framer-motion";

import TransitionOpacity from "shared/animation/TransitionOpacity";
import ReportAndAnalisCard from "features/closedmarket/components/cards/ReportAndAnalysisCard";

import Card from "shared/Card";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import FunderMetricModal from "features/openMarket/funder/components/modal/FunderMetricModal";
import {
  getClosedMarketOverview,
  getClosedMarketTopTen,
} from "appstate/buyer/buyerSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatCurrency } from "utilities/helper/formatters";
// import InvoicesTab from "features/invoice/SingleInvoice/TabsComponents/InvoicesTab";

const ReportAndAnalysis = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [viewInvoiceDetailModal, setViewInvoiceDetailModal] = useState(false);

  const { closedMarketOverviewData, closedMarketTopTenData } = useSelector(
    (state) => state?.buyer
  );

  const handleViewDetail = () => {
    setViewInvoiceDetailModal(true);
  };

  useEffect(() => {
    dispatch(getClosedMarketOverview());
    dispatch(getClosedMarketTopTen());
  }, [dispatch]);

  return (
    <>
      <AnimatePresence>
        {viewInvoiceDetailModal && (
          <FunderMetricModal onClose={() => setViewInvoiceDetailModal(false)} />
        )}
      </AnimatePresence>

      <TransitionOpacity className="flex flex-col gap-8">
        <>
          <div className="flex flex-col semi-lg:flex-row gap-7 w-full ">
            <section className=" rounded-[5px] bg-unusualGray flex gap-6 flex-col extra-md:flex-row px-6 py-5 w-full semi-lg:w-full">
              <div className=" w-full">
                <div className="flex w-full flex-row gap-[18px] flex-wrap">
                  <div className=" border-l-2 border-white flex flex-col items-center justify-between px-14  py-7 gap-4 bg-primaryColor rounded-b-[10px] cursor-pointer text-white  md:w-[37%]">
                    <div>
                      <span className=" text-[50px] font-bold">
                        {closedMarketOverviewData?.data
                          ?.totalCloseMarketRequests ?? "N/A"}
                      </span>
                    </div>
                    <span class="w-[198px] text-center text-white text-xl font-normal">
                      Request in the Closed market
                    </span>
                  </div>

                  <div className=" border-l-2 border-white flex flex-col   gap-4 rounded-[10px] cursor-pointer text-white w-[61%] bg-white ">
                    <div className=" flex bg-[#F08000] rounded-t-[10px] px-6 py-4  ">
                      <div className="flex flex-row justify-between w-full">
                        <div className=" flex items-center justify-center">
                          <span class="text-white text-sm font-medium uppercase">
                            CLOSED Market
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="flex cursor-pointer justify-between bg-white py-5  rounded-[10px] px-6">
                      <div class=" flex-col justify-start  gap-2 inline-flex  ">
                        <div class="text-[#7A7A7A] text-sm font-normal ">
                          Total Invoice Amount
                        </div>
                        <div class="justify-start items-center gap-[3px] inline-flex">
                          <div class="text-[#222222]  text-xl font-bold ">
                            {formatCurrency(
                              closedMarketOverviewData?.data?.totalInvoiceAmount
                            )}
                          </div>
                        </div>
                      </div>

                      <div class=" h-[49px] flex-col justify-center  items-center inline-flex">
                        <div class="text-[black] text-lg font-medium ">-</div>
                      </div>

                      <div class="flex-col justify-start  gap-2 inline-flex ">
                        <div class="text-[#7A7A7A] text-sm font-normal ">
                          Total Amount in Locked Bids
                        </div>
                        <div class="justify-center items-center gap-[3px] inline-flex">
                          <div class="text-[#222222]  text-xl font-bold  text-center">
                            <span>
                              {formatCurrency(
                                closedMarketOverviewData?.data
                                  ?.totalAmountLockedInBid
                              )}
                            </span>
                            <span className="text-neutral-800 text-sm font-normal  ml-1"></span>
                          </div>
                        </div>
                      </div>

                      <div class=" h-[49px] flex-col justify-center  items-center inline-flex ">
                        <div class="text-[black] text-lg font-medium ">-</div>
                      </div>

                      <div class=" flex-col justify-start  gap-2 inline-flex">
                        <div class="text-[#7A7A7A] text-sm font-normal ">
                          Total Funders
                        </div>
                        <div class="justify-center items-center gap-[3px] inline-flex">
                          <div class="text-[#222222]  text-xl font-bold  text-center">
                            {closedMarketOverviewData?.data?.totalFunders}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" flex flex-row w-full mt-4 gap-[18px]">
                  <div className="flex px-3 rounded-b-[10px] flex-wrap bg-white border-t-2 border-t-[#f08000] flex-col pt-5 pb-4 gap-4 w-1/2">
                    <div className="flex  flex-wrap justify-between ">
                      <span className="text-[20px] text-primaryColor">
                        Requests
                      </span>
                      <motion.button
                        whileTap={{ scale: 0.9 }}
                        className=" bg-[#f08000] text-white rounded-[5px]  py-[5px] px-[32px]"
                      >
                        Insight
                      </motion.button>
                    </div>

                    <div className="flex">
                      <div className="flex w-1/2 px-5 flex-col gap-3  border-r-2 border-r-[#07593D]">
                        <span class="text-center text-[#07593D] text-[40px] font-bold ">
                          {closedMarketOverviewData?.data?.totalPostRequests ??
                            "N/A"}{" "}
                        </span>
                        <span className=" text-center text-sm text-[#565656]">
                          Posted
                        </span>
                      </div>

                      <div className="flex w-1/2 px-5 flex-col gap-3  border-r-2 border-r-[#07593D]">
                        <span class="text-center text-amber-600 text-[40px] font-bold ">
                          {closedMarketOverviewData?.data?.totalActiveBids ??
                            "N/A"}{" "}
                        </span>
                        <span className=" text-center text-sm text-[#565656]">
                          Bid Active
                        </span>
                      </div>
                      <div className="flex w-1/2 px-5 flex-col gap-3">
                        <span class="text-center text-[#07593D] text-[40px] font-bold ">
                          {closedMarketOverviewData?.data
                            ?.totalPendingFundingRequests ?? "N/A"}
                        </span>
                        <span className=" text-center text-sm text-[#565656]">
                          Pending Funding
                        </span>
                      </div>

                      {/* unit */}
                    </div>
                  </div>
                  {/* flex-side */}
                  <div className=" border-l-2 border-white flex flex-col   gap-4 rounded-[10px] cursor-pointer text-white w-[61%] bg-white ">
                    <div className=" flex bg-[#07593D] rounded-t-[10px] px-6 py-[8px]  ">
                      <div className="flex flex-row justify-between w-full">
                        <div className=" flex items-center justify-center">
                          <span class="text-white text-sm font-medium  uppercase">
                            Top Performing Funder
                          </span>
                        </div>
                        <div
                          class="text-[#222222] text-sm font-medium  bg-stone-50 rounded p-2.5"
                          onClick={handleViewDetail}
                        >
                          View Details
                        </div>
                      </div>
                    </div>

                    <div className="flex cursor-pointer justify-between bg-white py-5  rounded-[10px] px-6">
                      <div class=" flex-col justify-start  gap-2 inline-flex  ">
                        <div class="text-[#7A7A7A] text-sm font-normal ">
                          Funder
                        </div>
                        <div class="justify-start items-center gap-[3px] inline-flex">
                          <div class="text-[#222222]  text-xl font-bold">
                            Dangote PLC
                          </div>
                        </div>
                      </div>

                      <div class=" h-[49px] flex-col justify-center  items-center inline-flex">
                        <div class="text-[black] text-lg font-medium ">-</div>
                      </div>

                      <div class="flex-col justify-start  gap-2 inline-flex ">
                        <div class="text-[#7A7A7A] text-sm font-normal ">
                          Metric Score
                        </div>
                        <div class="justify-center items-center gap-[3px] inline-flex">
                          <div class="text-[#222222]  text-xl font-bold  text-center">
                            <span>97%</span>
                          </div>
                        </div>
                      </div>

                      <div class=" h-[49px] flex-col justify-center  items-center inline-flex ">
                        <div class="text-[black] text-lg font-medium ">-</div>
                      </div>

                      <div class=" flex-col justify-start  gap-2 inline-flex">
                        <div class="text-[#7A7A7A] text-sm font-normal ">
                          Number of Accepted bids
                        </div>
                        <div class="justify-center items-center gap-[3px] inline-flex">
                          <div class="text-[#222222]  text-xl font-bold  text-center">
                            54
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <TransitionOpacity className="min-h-[75vh] rounded-b-[10px] bg-unusualGray rounded-tl-[10px]  rounded-tr-[10px] ">
            <Card className="  rounded-tl-[10px]  rounded-tr-[10px] p-[0.5px] ">
              <div className=" px-[24px]  rounded-tl-[10px]  rounded-tr-[10px]  ">
                <div className="my-2 grid md:grid-cols-2 items-center">
                  <div className=" flex flex-col relative">
                    <div className="  flex ">
                      <div class="text-black text-base font-semibold ">
                        Top 10 Contested Invoices
                      </div>
                    </div>

                    <div class="text-neutral-400 text-xs font-normal ">
                      Invoices with the highest number of bidders
                    </div>
                  </div>

                  <div className="flex md:col-span-1 justify-end">
                    <div class="h-[34px] px-5 py-2.5 rounded-[5px] border border-neutral-400 justify-start items-center gap-2.5 inline-flex">
                      <div
                        class="text-neutral-800 cursor-pointer text-xs font-normal"
                        onClick={() => navigate(`topContestedInvoices`)}
                      >
                        View all
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>

            <div className=" p-6 flex flex-col gap-5 ">
              {closedMarketTopTenData?.data?.map((invoice) => {
                return (
                  <ReportAndAnalisCard
                    key={invoice?.closedMarketId}
                    id={invoice?.closedMarketId}
                    numberofBidders={invoice?.numberOfbidders}
                    supplierCompanyName={invoice?.supplierCompanyName}
                    invoiceNumber={invoice?.requestRef}
                    invoiceRef={invoice?.requestRef}
                    invoiceAmount={invoice?.requestAmount}
                    invoiceDueDate={invoice?.earlyPaymentDate}
                    supplierCompanyIcon={invoice?.userTypeIcon}
                    requestType={invoice?.requestType}
                    lockRate={invoice?.lockRate}
                  />
                );
              })}
            </div>
          </TransitionOpacity>
        </>
      </TransitionOpacity>
    </>
  );
};

export default ReportAndAnalysis;
