// import { useNavigate } from "react-router-dom";
import EmptyPage from "features/roleManager/Dashbaord/components/EmptyPage";
import ContestedInvoiceCard from "./cards/ContestedInvoiceCard";

function TopContestedInvoice({
  head = "Top 10 Contested Invoices",
  subHead = "Invoices with the highest number of bidders",
  topTenBids,
  setActiveTab,
}) {
  // const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-6 rounded-[10px]  bg-unusualGray pb-12  p-6">
      <header className="flex justify-between items-center pb-4 border-b border-b-subtleGray ">
        <div className="flex flex-col gap-1">
          <span className=" font-semibold">{head}</span>
          <span className=" text-[#959595] text-xs">{subHead}</span>
        </div>

        {/* <button
          onClick={() => navigate("top-ten-invoices")}
          className="text-sm px-5 py-2.5 rounded-[5px] underline"
        >
          View all
        </button> */}
        <button
          onClick={() => {
            setActiveTab(4);
            // navigate("top-ten-invoices");
          }}
          className="text-sm px-5 py-2.5 rounded-[5px] underline"
        >
          View all
        </button>
      </header>

      <div className="flex flex-col gap-2.5">
        {topTenBids?.length > 0 ? (
          topTenBids
            ?.slice(0, 3)
            ?.map((invoice) => (
              <ContestedInvoiceCard
                id={invoice?.openMarketReference}
                invoiceNumber={invoice?.openMarketReference}
                buyerName={invoice?.buyerCompanyName}
                noOfBids={invoice?.numberOfBids}
                invoiceAmount={invoice?.fundingAmount}
                dueDate={invoice?.invoiceDueDate}
                lockRate={invoice?.lockRate}
              />
            ))
        ) : (
          <div>
            <EmptyPage />
          </div>
        )}
      </div>
    </div>
  );
}

export default TopContestedInvoice;
