import {
  PAIconIconParkSucess,
  PAIconInventoryArrowLeft,
  PAIconMDISuccess,
  // PAIconUnlockPad,
  // PAIconViewMini,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableHeader from "shared/tableHeader/TableHeader";
import { useCallback, useEffect, useState } from "react";
import FundAccountOption from "./components/modals/SelectAccountModal";
import AcceptModal from "shared/AcceptModal";
import { useDispatch, useSelector } from "react-redux";
import PinModal from "shared/PinModal";
import toast from "react-hot-toast";
import {
  // allFunderPfp,
  // allFunderPfpInvoices,
  fullFunderPfp,
  fundPfpInvoice,
} from "appstate/funder/funderSlice";
import Pagination from "shared/Pagination";
import Loading from "shared/Loading";

function PfpTableDetails() {
  const navigate = useNavigate();
  // const [showCheckbox, setShowCheckbox] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [hasSelected, setHasSelected] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFundAccountOptionOpen, setIsFundAccountOptionOpen] = useState(false);
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [fundAccountPinModal, setFundAccountPinModal] = useState(false);
  const [selectedOfferIds, setSelectedOfferIds] = useState([]);
  const [selectedInvoicesTotalAmount, setSelectedInvoicesTotalAmount] =
    useState(0);
  const selectedAccounts = useSelector(
    (state) => state?.wallet?.selectedAccounts
  );
  const { fullFunderPfpData, pfpId, isLoading } = useSelector(
    (state) => state?.funder
  );
  const dispatch = useDispatch();
  // getFullFunderPfp

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;

  const handleSearch = useCallback(() => {
    if (pfpId) {
      dispatch(
        fullFunderPfp({ id: pfpId, page, pageSize, search: searchTerm })
      );
    }
  }, [dispatch, pfpId, page, pageSize, searchTerm]);

  useEffect(() => {
    const handler = setTimeout(() => {
      handleSearch();
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, handleSearch]);

  // useEffect(() => {
  //   if (pfpId) {
  //     dispatch(
  //       fullFunderPfp({ id: pfpId, page, pageSize, search: searchQuery })
  //     );
  //   }
  // }, [dispatch, pfpId, page, pageSize]);
  // console.log("allPfpInvoicesData", fullFunderPfpData?.data);

  // const onRowClicked = () => {
  //   let path = "1";
  //   navigate(path);
  // };

  function handleGoback() {
    let path = -1;
    navigate(path);
  }

  const options = [
    { id: 1, name: "All" },
    { id: 2, name: "ACTIVE" },
    { id: 3, name: "PENDING" },
    { id: 4, name: "APPROVED" },
    { id: 5, name: "REJECTED" },
    { id: 6, name: "CREATED" },
  ];

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
    // setShowCheckbox(event.target.value === "ACTIVE");
  };

  const InvoiceDetails = (offerId, status) => {
    // let path = "/collections/recievables";
    let path = "";

    switch (status) {
      case "ACCEPTED":
        path = `/payablefinancing-campaign/invoice/${offerId}`;
        break;
      case "REJECTED":
        path = `/payablefinancing-campaign/rejected/${offerId}`;
        break;
      case "PENDING":
        path = `/payablefinancing-campaign/invoice/${offerId}`;
        break;
      case "ACTIVE":
        path = `/payablefinancing-campaign/invoice/${offerId}`;
        break;
      case "CREATED":
        path = `/payablefinancing-campaign/invoice/${offerId}`;
        break;
      default:
        break;
    }

    navigate(path);
  };

  // const handleCheckboxChange = (id) => {
  //   setSelectedInvoices((prevSelected) => {
  //     const newSelected = prevSelected.includes(id)
  //       ? prevSelected.filter((invoiceId) => invoiceId !== id)
  //       : [...prevSelected, id];

  //     setHasSelected(newSelected.length > 0);
  //     return newSelected;
  //   });
  // };

  const handleCheckboxChange = (offerId) => {
    setSelectedInvoices((prevSelected) => {
      const newSelected = prevSelected.includes(offerId)
        ? prevSelected.filter((invoiceId) => invoiceId !== offerId)
        : [...prevSelected, offerId];

      setHasSelected(newSelected.length > 0);
      calculateTotalAmount(newSelected);
      setSelectedOfferIds(newSelected);
      // console.log(newSelected);
      return newSelected;
    });
  };

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    setIsSelectAllChecked(isChecked);
    if (isChecked) {
      const allIds = filteredData.map((invoice) => invoice.offerId);
      setSelectedInvoices(allIds);
      setHasSelected(true);
      setSelectedOfferIds(allIds);
      // console.log("saa", allIds);
      calculateTotalAmount(allIds);
    } else {
      setSelectedInvoices([]);
      setHasSelected(false);
      setSelectedInvoicesTotalAmount(0);
    }
  };

  // const handleSelectAllChange = (e) => {
  //   const isChecked = e.target.checked;
  //   setIsSelectAllChecked(isChecked);
  //   if (isChecked) {
  //     setSelectedInvoices(filteredData.map((invoice) => invoice.id));
  //     setHasSelected(true);
  //   } else {
  //     setSelectedInvoices([]);
  //     setHasSelected(false);
  //   }
  // };

  const calculateTotalAmount = (selectedIds) => {
    const totalAmount = selectedIds.reduce((total, offerId) => {
      const invoice = fullFunderPfpData?.data.find(
        (invoice) => invoice.offerId === offerId
      );
      return total + (invoice ? invoice.invoicedAmount : 0);
    }, 0);
    setSelectedInvoicesTotalAmount(totalAmount);
  };

  // const handleApproval = () => {
  //   const selectedIds = selectedInvoices;
  //   if (selectedIds?.length > 0) {
  //     setIsFundAccountOptionOpen(true);
  //     setSelectedInvoicesTotalAmount(selectedIds?.invoiceAmount);
  //   }
  //   console.log("selected: ",selectedIds);

  //   // const invoice = fullFunderPfpData?.data?.find(
  //   //   (invoice) => invoice.offerId === offerId
  //   // );
  //   // if (invoice) {
  //   //   setSelectedInvoicesTotalAmount(invoice.invoiceAmount);
  //   //   setIsFundAccountOptionOpen(true);
  //   // }
  // };

  const handleApproval = () => {
    const selectedIds = selectedInvoices;
    if (selectedIds?.length > 0) {
      // Calculate the total amount of the selected invoices
      const totalAmount = selectedIds.reduce((total, offerId) => {
        const invoice = fullFunderPfpData?.data.find(
          (invoice) => invoice.offerId === offerId
        );
        return total + (invoice ? invoice.invoiceAmount : 0);
      }, 0);

      // Set the total amount to state
      setSelectedInvoicesTotalAmount(totalAmount);

      // Set the selected offer IDs
      setSelectedOfferIds(selectedIds);

      // Open the fund account option modal
      setIsFundAccountOptionOpen(true);
    }
    // console.log("selected: ", selectedIds);
  };

  const handleAccountSubmit = () => {
    setIsFundAccountOptionOpen(false);
    setIsAcceptModalOpen(true);
  };

  const AcceptModalConfirm = () => {
    setIsAcceptModalOpen(false);
    setFundAccountPinModal(true);
  };

  // const handleFundInvoice = (id) => {
  //   const invoice = fullFunderPfpData?.data.find(
  //     (invoice) => invoice.id === id
  //   );
  //   if (invoice) {
  //     setSelectedInvoicesTotalAmount(invoice.invoicedAmount);
  //     setIsFundAccountOptionOpen(true);
  //   }
  // };

  const handleFundInvoice = (offerId) => {
    const invoice = fullFunderPfpData?.data?.find(
      (invoice) => invoice?.offerId === offerId
    );
    if (invoice) {
      setSelectedInvoicesTotalAmount(invoice?.fundableAmount);
      setSelectedOfferIds(offerId);
      setIsFundAccountOptionOpen(true);
    }
  };

  const filteredData = fullFunderPfpData?.data?.filter((invoice) => {
    if (selectedFilter === "All") return true;
    return invoice.status === selectedFilter;
  });

  function handleDownloadSuccessful(pin) {
    setFundAccountPinModal(false);

    const fundInvoice = {
      invoiceIds: [...selectedOfferIds],
      virtualAccountId: selectedAccounts?.id || "",
      transactionPin: pin,
    };
    // console.log(body);
    dispatch(fundPfpInvoice({ pfpId, body: fundInvoice })).then((data) => {
      if (data?.payload?.success) {
        toast.custom(
          <>
            <div className="flex-1 items-center justify-center flex w-full h-full">
              <div className="flex mt-[300px]">
                <div className="bg-primaryColor w-[147.79px] h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
                  <PAIconMDISuccess />
                </div>
                <div className="bg-white  flex-col justify-center items-center self-stretch py-[25px] h-[160px] rounded-r-[7px] shadow-lg flex px-16 ">
                  <PAIconIconParkSucess />
                  <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
                    Account{" "}
                    <span className="font-bold text-primaryColor">
                      {" "}
                      {selectedAccounts?.accountNumber}{" "}
                    </span>{" "}
                    <br /> {data?.payload?.data}
                  </p>
                </div>
              </div>
            </div>
          </>
        );
      }
    });

    setTimeout(() => {
      toast.dismiss();
      navigate(pathh);
    }, 3000);

    let pathh = "/payablefinancing-campaign";
  }

  const columns = [
    ...(selectedFilter === "ACTIVE"
      ? [
          {
            name: (
              <input
                type="checkbox"
                onChange={handleSelectAllChange}
                checked={isSelectAllChecked}
              />
            ),
            cell: (row) => (
              <input
                type="checkbox"
                checked={selectedInvoices.includes(row.offerId)}
                onChange={() => handleCheckboxChange(row.offerId)}
              />
            ),
            width: "50px",
          },
        ]
      : []),
    {
      name: "Invoice Reference",
      selector: (row) => row.invoiceReference,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "6rem",
    },
    {
      name: "Due Date",
      selector: (row) => row.dueDate,
      sortable: true,
    },
    {
      name: "Payment Date",
      selector: (row) => row.paymentDate,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) =>
        Number(row.invoiceAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
    },
    {
      name: "Offer Amount",
      selector: (row) =>
        Number(row.offerAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
    },
    {
      name: "Funder Share",
      selector: (row) =>
        Number(row.funderShare).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
      cell: (row) => (
        <p className="text-primaryColor">
          {Number(row.funderShare).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      ),
    },
    {
      name: "Fundabale Amount",
      selector: (row) =>
        Number(row.fundableAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
      cell: (row) => (
        <p className="text-primaryColor">
          {Number(row.fundableAmount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color:
              row.status === "ACCEPTED"
                ? "green"
                : row.status === "ACTIVE"
                ? "#90EE90"
                : row.status === "REJECTED"
                ? "red"
                : "orange",
            // fontWeight: "bold",
          }}
        >
          {row.status}
        </span>
      ),
      sortable: true,
    },
    {
      // name: "Action",
      cell: (row) => (
        <button
          className={`py-2 px-4 rounded text-white ${
            row.status === "ACTIVE"
              ? "bg-green-900"
              : "bg-gray300 cursor-not-allowed"
          }`}
          disabled={row.status !== "ACTIVE"}
          onClick={() => handleFundInvoice(row.offerId)}
        >
          Fund
        </button>
      ),
      width: "6rem",
    },
  ];

  return (
    <DashboardLayout
      section="Payable Finance Program"
      subHeader="Here is an overview of your PFP"
    >
      <div className="min-h-full">
        <div
          onClick={handleGoback}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>
        <div className="mt-[17px] min-h-full">
          <Card className="shadow-md min-h-[80%] p-[20px] rounded-[10px]">
            <div>
              <TableHeader
                total={`${filteredData?.length} Invoices`}
                btnName="Fund Invoices"
                options={options}
                handleChange={handleFilterChange}
                details={
                  <span className="text-[#5F4D61]">
                    List of invoices in{" "}
                    <span className="font-bold">Payable flex</span> Payable
                    finance program
                  </span>
                }
                // payableFinance
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                handleSearch={handleSearch}
                funderPayableFinance
                approvalName="Fund Invoice"
                handleApproval={handleApproval}
                active={hasSelected}
                nonActive={!hasSelected}
              />
            </div>
            <Table
              columns={columns}
              data={filteredData}
              // selectableRows
              onRowClicked={(row) => InvoiceDetails(row.offerId, row.status)}
              progressPending={isLoading}
              progressComponent={<Loading />}
              pointer
              tableHeader
              className="mt-5"
            />
            <div className="flex justify-center gap-x-[8px] mt-[20px]">
              {!isLoading && fullFunderPfpData?.data?.length >= 10 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={fullFunderPfpData?.meta?.totalElements}
                />
              )}
            </div>
          </Card>
        </div>
      </div>

      <FundAccountOption
        isOpen={isFundAccountOptionOpen}
        onClose={() => setIsFundAccountOptionOpen(false)}
        HeaderText={
          <p>
            Select which account to fund with <br />{" "}
            {/* <span className="text-xs text-center font-base">
              Amount of invoice: NGN{" "}
              {selectedInvoicesTotalAmount?.toLocaleString()}
            </span> */}
          </p>
        }
        onSubmit={handleAccountSubmit}
      />

      <AcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        message={
          <>
            {selectedInvoices.length >= 1 ? (
              <p>
                Active invoice selected:{" "}
                <span className="text-primaryColor font-bold">
                  {selectedInvoices?.length}{" "}
                </span>{" "}
              </p>
            ) : null}
            <p className="mb-3">
              Total Fundabale amount:{" "}
              <span className="text-primaryColor font-bold">
                {" "}
                NGN {selectedInvoicesTotalAmount?.toLocaleString()}{" "}
              </span>
            </p>
            Would you like to proceed to fund the invoice with <br /> this
            account{" "}
            <span className="text-primaryColor font-bold">
              {selectedAccounts?.accountNumber}
            </span>{" "}
            ?
          </>
        }
        onConfirm={AcceptModalConfirm}
        fund
      />
      <PinModal
        isOpen={fundAccountPinModal}
        onClose={() => {
          setFundAccountPinModal(false);
        }}
        onSubmit={handleDownloadSuccessful}
        // isLoading={isLoading}
        headerText="Fund Account"
        instructionText="Enter your transaction PIN to use the desired account for this transaction."
      />
    </DashboardLayout>
  );
}

export default PfpTableDetails;
