import React, { useState } from "react";
import { Link } from "react-router-dom";

const CardFlow = ({ href, title, subtitle, text, totalPages }) => {
  const [swap, setSwap] = useState(false);
  return (
    <Link
      to={href}
      onMouseEnter={() => setSwap(true)}
      onMouseLeave={() => setSwap(false)}
      className="relative mb-2 group cursor-pointer overflow-hidden text-gray-50 max-h-64 w-full rounded-2xl hover:duration-700 duration-700"
    >
      <div className="w-full  h-72 bg-[#f5faf6] text-gray-800">
        <div className="flex flex-row justify-between">
          <svg
            className="fill-current stroke-current w-8 h-8 p-2 hover:bg-lime-200 rounded-full m-1"
            height={100}
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 100 100"
            width={100}
            x={0}
            xmlns="http://www.w3.org/2000/svg"
            y={0}
          >
            <path
              className="M15.8,32.9V15.8m0,0H32.9m-17.1,0L37.2,37.2m47-4.3V15.8m0,0H67.1m17.1,0L62.8,37.2m-47,29.9V84.2m0,0H32.9m-17.1,0L37.2,62.8m47,21.4L62.8,62.8M84.2,84.2V67.1m0,17.1H67.1"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={8}
            ></path>
          </svg>
          <svg
            className="fill-current stroke-current w-8 h-8 p-2 m-1 hover:bg-lime-200 rounded-full"
            height={100}
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 100 100"
            width={100}
            x={0}
            xmlns="http://www.w3.org/2000/svg"
            y={0}
          >
            <path
              className="svg-stroke-primary"
              d="M50,17.4h0M50,50h0m0,32.6h0M50,22a4.7,4.7,0,1,1,4.7-4.6A4.7,4.7,0,0,1,50,22Zm0,32.7A4.7,4.7,0,1,1,54.7,50,4.7,4.7,0,0,1,50,54.7Zm0,32.6a4.7,4.7,0,1,1,4.7-4.7A4.7,4.7,0,0,1,50,87.3Z"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={8}
            ></path>
          </svg>
        </div>
      </div>
      <div className="absolute shadow-lg bg-[#E9EED9] -bottom-24 w-full p-3 flex flex-col gap-1 group-hover:-bottom-0 group-hover:duration-600 duration-500">
        <span
          className={`text-primaryColor ${
            swap ? "mb-0" : "mb-16"
          } font-bold text-sm`}
        >
          {swap ? subtitle : title}
        </span>
        <span className="text-gray-800 font-bold text-3xl">{title}</span>
        {swap && <p className="text-neutral-800">{text}</p>}
        {/* {!swap && <div className="text-[#E9EED9] text-sm">{placeholder}</div>}
        {!swap && <div className="text-[#F9FAFB] text-sm">{placeholder}</div>} */}
      </div>
    </Link>
  );
};

export default CardFlow;
