import { motion } from "framer-motion";
import { PAIconArrowLeftGray } from "assets/images/svgs";

import { useNavigate, useParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import DashboardLayout from "layout/dashboardlayout";
import LoadingScreen from "shared/LoadingScreen";
import { allInvoiceById } from "appstate/invoice/invoiceSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

function BulkDetailPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { invoiceIdData, isLoading } = useSelector((state) => state?.invoice);

  useEffect(() => {
    dispatch(allInvoiceById({ id }));
  }, [dispatch, id]);

  // FORMAT AMOUNT
  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const navigate = useNavigate();

  function handleGoBack() {
    navigate(-1);
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <DashboardLayout
        section="Closed Market"
        subHeader="View invoices uploaded to the closed market by your associated buyers"
      >
        <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] ">
          <main>
            <div
              className="flex cursor-pointer w-full gap-1 items-center bg-[#fafafa] h-[52px] rounded-t-md  border-b-[1.5px] border-[#b1a6b2]  px-7"
              onClick={handleGoBack}
            >
              <PAIconArrowLeftGray />
              <span className=" text-[#7A7A7A] text-sm">Go Back</span>
            </div>
            <motion.section
              initial={{
                x: 20,
              }}
              animate={{
                x: 0,
              }}
              exit={{
                x: -20,
              }}
              transition={{
                delay: 0.6,
              }}
            >
              <TransitionScale className=" pb-5 bg-white  rounded-[3px]  pt-2">
                {/* Header with Left Padding */}

                {/* Other sections */}

                <section className="  mt-3">
                  <div class="text-[#222222] text-lg font-semibold mt-4 px-12">
                    Invoice | INV-{invoiceIdData?.data?.invoiceNo}
                  </div>

                  {/* Down Flex Texts */}
                  <div className=" flex justify-between px-12">
                    {/* first stage */}

                    <div class="lg:w-[229px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex">
                      <div class="self-stretch justify-between items-center inline-flex mt-2">
                        <div class="text-[#222222] text-base font-semibold  ">
                          Reference No:{" "}
                        </div>
                        <div class="text-[#555555] text-sm font-semibold ">
                          {invoiceIdData?.data?.invoiceRef || "N/A"}
                        </div>
                      </div>
                      <div class="self-stretch  flex-col justify-center items-start gap-1 flex mt-2">
                        <div class="self-stretch justify-between items-center inline-flex ">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Created Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            {invoiceIdData?.data?.createdDate || "N/A"}
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Submitted Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            {invoiceIdData?.data?.submittedDate || "N/A"}
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Approved Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            {invoiceIdData?.data?.approvedDate || "N/A"}
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Due Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            {invoiceIdData?.data?.dueDate || "N/A"}
                          </div>
                        </div>
                        <div class="self-stretch justify-between items-center inline-flex">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Issuance Date:{" "}
                          </div>
                          <div class="text-[#7a7a7a] text-xs font-semibold ">
                            {invoiceIdData?.data?.issuanceDate || "N/A"}
                          </div>
                        </div>
                      </div>

                      {/* stop */}
                    </div>
                    {/* second stage */}
                    
                  </div>

                  {/* stops here */}
                  <div className="  px-12 mt-24">
                    <header className="border-b-[5px] border-b-[#F6F5FA] mt-3  flex justify-between py-1">
                      {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                      <div>
                        <div class="text-[#222222] text-lg font-semibold ">
                          {invoiceIdData?.data?.buyerCompanyName || "N/A"}
                        </div>
                        <div class="text-[#7a7a7a] text-xs font-light  ">
                          Buyer details
                        </div>
                      </div>

                      <div class="h-[41px] flex-col justify-start items-start  inline-flex px-12">
                        <div class="text-[#222222] text-base font-semibold ">
                          {invoiceIdData?.data?.paymentTerms || "N/A"} Days
                        </div>
                        <div class="w-[100px] text-[#7a7a7a] text-xs font-light  mt-1">
                          Payment
                        </div>
                      </div>

                      <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                        <div class="text-[#222222] text-lg font-semibold ">
                          {invoiceIdData?.data?.currency || "N/A"}
                        </div>
                        <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                          Currency
                        </div>
                      </div>
                    </header>

                    <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">
                      {/* <h1 className="text-[18px] font-semibold">
                  Supplier/buyer details
                </h1> */}
                      <div class="text-[#11192a] text-sm font-semibold ">
                        Line Items
                      </div>
                      <div class="text-[#11192a] text-lg font-light  mr-6">
                        {invoiceIdData?.data?.items?.length || "N/A"}
                        Total Items{" "}
                      </div>
                    </header>

                    <div className=" w-full overflow-x-auto mt-3 pb-4">
                      <table className=" w-full overflow-x-auto">
                        <thead className="bg-subtleGray w-full p-6   ">
                          <tr className=" bg-unusualGray  px-6 w-full ">
                            {/* <td className="text-[#11192A] p-2.5 text-xs font-bold">
                          Item Number
                        </td> */}
                            <td class="text-[#11192a] text-xs font-semibold  p-4  ">
                              Item{" "}
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4">
                              Description{" "}
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4">
                              Unit Price
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4">
                              Quantity
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4">
                              Amount
                              <div> (before tax)</div>
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4">
                              Tax Rate
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4">
                              Tax Amount
                            </td>
                            <td class="text-[#11192a] text-xs font-semibold  p-4">
                              Total Price
                            </td>
                          </tr>
                        </thead>

                        <tbody className="  ">
                          {invoiceIdData?.data?.items?.map((cell, idx) => {
                            return (
                              <tr key={idx} className=" w-full ">
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 ">
                                  {cell.itemName}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.description}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {formatAmount(cell.unitPrice)}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.quantity}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {formatAmount(cell.amount)}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {cell.taxRate}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {formatAmount(cell.taxAmount)}
                                </td>
                                <td className="text-[#11192a] text-xs font-semibold mt-2 p-4">
                                  {formatAmount(cell.totalPrice)}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    {/* Calculate cost and price  shaded or gray section */}
                  </div>

                  <div className=" px-12   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2]">
                    <div className="lg:flex md:flex justify-between  w-full sm:block ">
                      {/* First left side */}
                      <div>
                        <section>
                          {" "}
                          <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                            Receiving Funding Account Information
                          </div>
                          <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                            {invoiceIdData?.data?.virtualAccount
                              ?.accountNumber || "N/A"}
                          </div>
                          <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                            {invoiceIdData?.data?.virtualAccount?.accountName ||
                              "N/A"}
                          </div>
                        </section>
                      </div>

                      {/* second division */}

                      <div>
                        {" "}
                        <div class=" flex-col justify-start items-start inline-flex lg:min-w-[380px]">
                          <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                            <div className=" flex w-full justify-between">
                              <div class="text-[#222222] text-xs font-semibold ">
                                Subtotal
                              </div>
                              <div class=" text-[#222222] text-xs font-semibold ">
                                {formatAmount(invoiceIdData?.data?.subTotal || "N/A")}
                              </div>
                            </div>
                            <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                              <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                                <span>VAT %</span>
                                <span>
                                  {formatAmount(invoiceIdData?.data?.vat || "N/A")}%
                                </span>
                              </div>
                              <div class="text-[#222222] text-xs font-medium ">
                                {formatAmount(
                                  invoiceIdData?.data?.vatAmount || "N/A"
                                )}
                              </div>
                            </div>

                            <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                              <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                                <span>Discount %</span>
                                <span>
                                  {invoiceIdData?.data?.discount || "N/A"}%
                                </span>
                              </div>
                              <div class="text-[#222222] text-xs font-medium ">
                                {formatAmount(invoiceIdData?.data?.discountAmount || "N/A")}
                              </div>
                            </div>
                          </section>
                          <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black">
                            <div class="text-[#222222] text-lg font-semibold ">
                              Grand Total
                            </div>
                            <div class="text-[#222222] text-lg font-semibold ">
                              {formatAmount(invoiceIdData?.data?.grandTotal || "N/A")}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* stops */}
                    </div>
                  </div>
                </section>
              </TransitionScale>
            </motion.section>
          </main>
        </TransitionScale>
      </DashboardLayout>
    </>
  );
}

export default BulkDetailPage;
