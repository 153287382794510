import React, { useState, useRef, useEffect } from "react";
import DashboardLayout from "layout/dashboardlayout";
import { useLocation, useNavigate } from "react-router-dom";
import Edit from "../../../../assets/images/svgs/edit-light.svg";
import Delete from "../../../../assets/images/svgs/DeleteIcon.svg";
import ArrowDown from "../../../../assets/images/svgs/arrow-down1.svg";
import Button from "shared/Button";
import toast from "react-hot-toast";
import {
  insertInvoice,
  saveValidationForLaterInvoice,
} from "appstate/api/invoice/invoice";
import Spinner from "shared/Spinner/Spinner";
import InvoiceModalUpload from "./invoicemodal";
import InvoiceSuccessModal from "shared/InvoiceSuccessModal/Index";

const InvoiceTable = () => {
  const { state: { data = [], mappings = [] } = {} } = useLocation();
  const navigate = useNavigate();
  const [selectedHeaders, setSelectedHeaders] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState(null);
  const [editValues, setEditValues] = useState({});
  const [invoiceData, setInvoiceData] = useState(data);
  const [loading, setLoading] = useState(false);
  const [saveForLaterLoading, setSaveForLaterLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [saveForLater, setSaveForLater] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showSuccessUpload, setShowSuccessUpload] = useState(false);
  const [totalIssues, setTotalIssues] = useState(0);
  // eslint-disable-next-line
  const [hoveredRow, setHoveredRow] = useState(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleGoBack = () => {
    navigate("/invoice/invoice");
  };

  const handleRowHover = (index) => {
    setHoveredRow(index);
  };

  const handleRowLeave = () => {
    setHoveredRow(null);
  };

  useEffect(() => {
    setShowSuccessUpload(true);

    const timer = setTimeout(() => {
      setShowSuccessUpload(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    invoiceData.forEach((invoice) => {
      if (invoice.validationMessages && invoice.validationMessages.length > 0) {
        setTotalIssues(invoice.validationMessages.length);
        invoice.validationMessages.forEach((msg) => {
          toast.error(
            `${msg.field}: ${msg.message} (Invoice No: ${invoice.invoiceNo})`,
            {
              duration: 10000,
            }
          );
        });
      }
    });
  }, [invoiceData]);

  useEffect(() => {
    if (mappings && mappings.length > 0) {
      setInvoiceData(mappings);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleEdit = (row) => {
    setIsEditing(true);
    setCurrentInvoice(row);
    setEditValues({ ...row });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    if (currentInvoice) {
      const updatedData = invoiceData.map((invoice) => {
        if (invoice.invoiceNo === currentInvoice.invoiceNo) {
          return { ...invoice, ...editValues };
        }
        return invoice;
      });

      setInvoiceData(updatedData);
      setIsEditing(false);
      setCurrentInvoice(null);
      setEditValues({});
    }
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleSaveLater = () => {
    setSaveForLater(true);
  };

  const handleCancel = () => {
    setShowCancelModal(true);
  };

  const handleSaveForLater = async () => {
    setSaveForLaterLoading(true);
    try {
      const response = await saveValidationForLaterInvoice(invoiceData);
      if (response?.status === 200) {
        // toast.success("Validation saved successfully");
        setSaveForLater(false);
      }
    } catch (error) {
      console.error("Error saving invoice for later:", error);
      toast.error("Failed to save invoice for later.");
    } finally {
      setSaveForLaterLoading(false);
      setSaveForLater(false);
    }
  };

  const handleUpload = async () => {
    setOpenModal(false);
    setLoading(true);
    const sanitizedInvoiceData = invoiceData.map((invoice) => {
      const { validationMessages, ...rest } = invoice;
      return rest;
    });

    try {
      const response = await insertInvoice(sanitizedInvoiceData);
      if (response) {
        if (response?.data) {
          response?.data.forEach((invoice) => {
            if (
              invoice.validationMessages &&
              invoice.validationMessages.length > 0
            ) {
              invoice.validationMessages.forEach((msg) => {
                toast.error(
                  `${msg.field}: ${msg.message} (Invoice No: ${invoice.invoiceNo})`,
                  {
                    duration: 10000,
                  }
                );
              });
            }
          });
        }
        if (
          response?.success &&
          response.data.every((inv) => inv.status !== "FAILED")
        ) {
          // toast.success("Invoice uploaded successfully!");
          setShowSuccessModal(true);
          setTimeout(() => {
            navigate("/invoice/invoice")
          }, 1000)
        }
      }
    } catch (error) {
      console.error("Error uploading invoice:", error);
      toast.error("Failed to upload invoice.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (row) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this invoice?"
    );
    if (confirmDelete) {
      const updatedInvoices = invoiceData.filter(
        (invoice) => invoice.invoiceNo !== row.invoiceNo
      );
      setInvoiceData(updatedInvoices);
      toast.success("Invoice deleted successfully.");
    }
  };

  const handleCloseCancel = () => {
    setShowCancelModal(false);
  };

  const DropdownHeaders = [
    "Account Name",
    "Account No",
    "Bank Name",
    "Item Name",
    "Item Desc",
    "Quantity",
    "Unit Price",
    "Tax Rate",
    "Amount",
    "Sub Total",
  ];

  const headers = [
    "Invoice No",
    "Currency Code",
    "Company",
    "Due Date",
    "Issuance Date",
    "Discount",
    "VAT",
    "Quantity",
    "Unit Price",
    "Amount",
    "Tax Rate",
    "Tax Amount",
    "Total Price",
    "Account Name",
    "Account No",
    "Bank Name",
    "Sub Total",
    "Grand Total",
    "Status",
    "Actions",
  ];

  const toggleHeaderSelection = (header) => {
    setSelectedHeaders((prevSelected) =>
      prevSelected.includes(header)
        ? prevSelected.filter((h) => h !== header)
        : [...prevSelected, header]
    );
  };

  const handleCloseConfirm = () => {
    setSaveForLater(false);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <DashboardLayout
      section="Invoices"
      subHeader="Here is an overview of your invoices"
    >
      {showSuccessUpload && (
        <InvoiceSuccessModal
          message={`Upload successful ${totalIssues} issues found, all all field was captured successfully`}
          onClose={handleCloseSuccessModal}
        />
      )}
      {showSuccessModal && (
        <InvoiceSuccessModal
          message="Invoice Imported Successfully"
          onClose={handleCloseSuccessModal}
        />
      )}
      {openModal && (
        <InvoiceModalUpload
          info="Are you sure you would like to proceed?"
          handleCloseConfirm={handleCloseConfirm}
          handleProceed={handleUpload}
          saveForLaterLoading={saveForLaterLoading}
        />
      )}
      {saveForLater && (
        <InvoiceModalUpload
          saveForLaterLoadin
          info="Are you sure you would like to save this invoice upload for later?"
          handleCloseConfirm={handleCloseConfirm}
          handleProceed={handleSaveForLater}
          saveForLaterLoading={saveForLaterLoading}
        />
      )}
      {showCancelModal && (
        <InvoiceModalUpload
          info="Are you sure you would like to cancel this invoice import?"
          handleCloseConfirm={handleCloseCancel}
          handleProceed={handleGoBack}
          saveForLaterLoading={saveForLaterLoading}
        />
      )}
      <div>
        <header className="flex flex-col items-start w-full self-stretch py-4 px-6 bg-[#FAFAFA] rounded-t-[10px]">
          <div className="flex justify-between items-center w-full">
            <div className="Pro Display text-black font-[sf text-sm font-medium leading-[normal]">
              Review Invoice Content
            </div>
            <div className="flex align-items">
              <div style={{ paddingRight: 10 }}>
                <span style={{ color: "#7A7A7A" }}>
                  Select fields to view :
                </span>
              </div>
              <div className="relative" ref={dropdownRef}>
                <div
                  className="border border-gray-300 rounded px-2 py-1 cursor-pointer flex items-center w-[150px] justify-between"
                  onClick={toggleDropdown}
                >
                  <span style={{ color: "#7A7A7A" }}>View Fields</span>
                  <img
                    src={ArrowDown}
                    alt="Arrow Down"
                    className="ml-2 w-4 h-4"
                  />
                </div>

                {dropdownOpen && (
                  <div className="absolute bg-white border border-[#7A7A7A] rounded mt-1 w-[150px] z-10">
                    {DropdownHeaders.map((header, index) => (
                      <label
                        style={{ color: "#7A7A7A" }}
                        key={index}
                        className="flex items-center px-2 py-1 hover:bg-gray-100"
                      >
                        <input
                          type="checkbox"
                          checked={selectedHeaders.includes(header)}
                          onChange={() => toggleHeaderSelection(header)}
                          className="mr-2"
                        />
                        {header}
                      </label>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>
        <div className="p-[1.5rem] rounded-b-[10px] bg-white">
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right relative">
              <thead className="text-xs uppercase border-t border-b border-silver">
                <tr>
                  {headers.map((header, index) => (
                    <th
                      key={index}
                      className="px-6 py-3 text-[0.75rem] font-bold text-lightGray"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {invoiceData.map((invoice, index) => {
                  const hasError =
                    invoice.validationMessages &&
                    invoice.validationMessages.length > 0;
                    // eslint-disable-next-line
                  const validationMessage = hasError
                    ? invoice.validationMessages
                        .map((msg) => `${msg.field}: ${msg.message}`)
                        .join(", ")
                    : "";

                  return (
                    <tr
                      key={index}
                      className="border-b border-silver"
                      onMouseEnter={() => handleRowHover(index)}
                      onMouseLeave={handleRowLeave}
                    >
                      <td
                        className={`px-6 py-4 font-medium text-[0.75rem] text-black whitespace-nowrap ${
                          hasError ? "bg-red-200" : ""
                        }`}
                      >
                        {isEditing && currentInvoice?.sno === invoice.sno ? (
                          <input
                            type="text"
                            name="invoiceNo"
                            value={editValues.invoiceNo || ""}
                            onChange={handleChange}
                            className="border border-gray-300 rounded px-2 py-1"
                          />
                        ) : (
                          invoice?.invoiceNo
                        )}
                      </td>
                      <td
                        className={`px-6 py-4 font-medium text-[0.75rem] text-black whitespace-nowrap ${
                          hasError ? "bg-red-200" : ""
                        }`}
                      >
                        {isEditing && currentInvoice?.sno === invoice?.sno ? (
                          <input
                            type="text"
                            name="currency"
                            value={editValues.currency || ""}
                            onChange={handleChange}
                            className="border border-gray-300 rounded px-2 py-1"
                          />
                        ) : (
                          invoice?.currency
                        )}
                      </td>
                      <td
                        className={`px-6 py-4 text-[0.75rem] text-black ${
                          hasError ? "bg-red-200" : ""
                        }`}
                      >
                        {isEditing && currentInvoice?.sno === invoice.sno ? (
                          <input
                            type="text"
                            name="company"
                            value={editValues.company || ""}
                            onChange={handleChange}
                            className="border border-gray-300 rounded px-2 py-1"
                          />
                        ) : (
                          invoice?.company
                        )}
                      </td>
                      <td
                        className={`px-6 py-4 text-[0.75rem] text-black ${
                          hasError ? "bg-red-200" : ""
                        }`}
                      >
                        {isEditing && currentInvoice?.sno === invoice.sno ? (
                          <input
                            type="text"
                            name="dueDate"
                            value={editValues.dueDate || ""}
                            onChange={handleChange}
                            className="border border-gray-300 rounded px-2 py-1"
                          />
                        ) : (
                          invoice?.dueDate
                        )}
                      </td>
                      <td
                        className={`px-6 py-4 text-[0.75rem] text-black ${
                          hasError ? "bg-red-200" : ""
                        }`}
                      >
                        {isEditing && currentInvoice?.sno === invoice.sno ? (
                          <input
                            type="text"
                            name="issuanceDate"
                            value={editValues.issuanceDate || ""}
                            onChange={handleChange}
                            className="border border-gray-300 rounded px-2 py-1"
                          />
                        ) : (
                          invoice?.issuanceDate
                        )}
                      </td>
                      <td
                        className={`px-6 py-4 text-[0.75rem] text-black ${
                          hasError ? "bg-red-200" : ""
                        }`}
                      >
                        {isEditing && currentInvoice?.sno === invoice.sno ? (
                          <input
                            type="text"
                            name="discount"
                            value={editValues.discount || ""}
                            onChange={handleChange}
                            className="border border-gray-300 rounded px-2 py-1"
                          />
                        ) : (
                          invoice?.discount
                        )}
                      </td>
                      <td
                        className={`px-6 py-4 text-[0.75rem] text-black ${
                          hasError ? "bg-red-200" : ""
                        }`}
                      >
                        {isEditing && currentInvoice?.sno === invoice.sno ? (
                          <input
                            type="text"
                            name="vat"
                            value={editValues.vat || ""}
                            onChange={handleChange}
                            className="border border-gray-300 rounded px-2 py-1"
                          />
                        ) : (
                          invoice?.vat
                        )}
                      </td>
                      <td
                        className={`px-6 py-4 text-[0.75rem] text-black ${
                          hasError ? "bg-red-200" : ""
                        }`}
                      >
                        {isEditing && currentInvoice?.sno === invoice.sno ? (
                          <input
                            type="text"
                            name="quantity"
                            value={editValues.quantity || ""}
                            onChange={handleChange}
                            className="border border-gray-300 rounded px-2 py-1"
                          />
                        ) : (
                          invoice?.quantity
                        )}
                      </td>
                      <td
                        className={`px-6 py-4 text-[0.75rem] text-black ${
                          hasError ? "bg-red-200" : ""
                        }`}
                      >
                        {isEditing && currentInvoice?.sno === invoice.sno ? (
                          <input
                            type="text"
                            name="unitPrice"
                            value={editValues.unitPrice || ""}
                            onChange={handleChange}
                            className="border border-gray-300 rounded px-2 py-1"
                          />
                        ) : (
                          invoice?.unitPrice
                        )}
                      </td>
                      <td
                        className={`px-6 py-4 text-[0.75rem] text-black ${
                          hasError ? "bg-red-200" : ""
                        }`}
                      >
                        {isEditing && currentInvoice?.sno === invoice.sno ? (
                          <input
                            type="text"
                            name="amount"
                            value={editValues.amount || ""}
                            onChange={handleChange}
                            className="border border-gray-300 rounded px-2 py-1"
                          />
                        ) : (
                          invoice?.amount
                        )}
                      </td>
                      <td
                        className={`px-6 py-4 text-[0.75rem] text-black ${
                          hasError ? "bg-red-200" : ""
                        }`}
                      >
                        {isEditing && currentInvoice?.sno === invoice.sno ? (
                          <input
                            type="text"
                            name="taxRate"
                            value={editValues.taxRate || ""}
                            onChange={handleChange}
                            className="border border-gray-300 rounded px-2 py-1"
                          />
                        ) : (
                          invoice?.taxRate
                        )}
                      </td>
                      <td
                        className={`px-6 py-4 text-[0.75rem] text-black ${
                          hasError ? "bg-red-200" : ""
                        }`}
                      >
                        {isEditing && currentInvoice?.sno === invoice.sno ? (
                          <input
                            type="text"
                            name="taxAmount"
                            value={editValues.taxAmount || ""}
                            onChange={handleChange}
                            className="border border-gray-300 rounded px-2 py-1"
                          />
                        ) : (
                          invoice?.taxAmount
                        )}
                      </td>
                      <td
                        className={`px-6 py-4 text-[0.75rem] text-black ${
                          hasError ? "bg-red-200" : ""
                        }`}
                      >
                        {isEditing && currentInvoice?.sno === invoice.sno ? (
                          <input
                            type="text"
                            name="totalPrice"
                            value={editValues.totalPrice || ""}
                            onChange={handleChange}
                            className="border border-gray-300 rounded px-2 py-1"
                          />
                        ) : (
                          invoice?.totalPrice
                        )}
                      </td>
                      <td
                        className={`px-6 py-4 text-[0.75rem] text-black ${
                          hasError ? "bg-red-200" : ""
                        }`}
                      >
                        {isEditing && currentInvoice?.sno === invoice.sno ? (
                          <input
                            type="text"
                            name="accountName"
                            value={editValues.accountName || ""}
                            onChange={handleChange}
                            className="border border-gray-300 rounded px-2 py-1"
                          />
                        ) : (
                          invoice?.accountName
                        )}
                      </td>
                      <td
                        className={`px-6 py-4 text-[0.75rem] text-black ${
                          hasError ? "bg-red-200" : ""
                        }`}
                      >
                        {isEditing && currentInvoice?.sno === invoice.sno ? (
                          <input
                            type="text"
                            name="accountNo"
                            value={editValues.accountNo || ""}
                            onChange={handleChange}
                            className="border border-gray-300 rounded px-2 py-1"
                          />
                        ) : (
                          invoice?.accountNo
                        )}
                      </td>
                      <td
                        className={`px-6 py-4 text-[0.75rem] text-black ${
                          hasError ? "bg-red-200" : ""
                        }`}
                      >
                        {isEditing && currentInvoice?.sno === invoice.sno ? (
                          <input
                            type="text"
                            name="bankName"
                            value={editValues.bankName || ""}
                            onChange={handleChange}
                            className="border border-gray-300 rounded px-2 py-1"
                          />
                        ) : (
                          invoice?.bankName
                        )}
                      </td>
                      <td
                        className={`px-6 py-4 text-[0.75rem] text-black ${
                          hasError ? "bg-red-200" : ""
                        }`}
                      >
                        {isEditing && currentInvoice?.sno === invoice.sno ? (
                          <input
                            type="text"
                            name="subTotal"
                            value={editValues.subTotal || ""}
                            onChange={handleChange}
                            className="border border-gray-300 rounded px-2 py-1"
                          />
                        ) : (
                          invoice?.subTotal
                        )}
                      </td>
                      <td
                        className={`px-6 py-4 text-[0.75rem] text-black ${
                          hasError ? "bg-red-200" : ""
                        }`}
                      >
                        {isEditing && currentInvoice?.sno === invoice.sno ? (
                          <input
                            type="text"
                            name="grandTotal"
                            value={editValues.grandTotal || ""}
                            onChange={handleChange}
                            className="border border-gray-300 rounded px-2 py-1"
                          />
                        ) : (
                          invoice?.grandTotal
                        )}
                      </td>
                      <td
                        style={{
                          color: invoice?.status === "FAILED" ? "red" : "green",
                        }}
                        className={`px-6 py-4 text-[0.75rem] text-black ${
                          hasError ? "bg-red-200" : ""
                        }`}
                      >
                        {invoice?.status}
                      </td>
                      <td className="px-6 py-4 text-[0.75rem] text-black whitespace-nowrap">
                        <div className="flex items-center">
                          {isEditing && currentInvoice?.sno === invoice.sno ? (
                            <>
                              <button onClick={handleSave} className="mr-2">
                                Save
                              </button>
                              <button onClick={() => setIsEditing(false)}>
                                Cancel
                              </button>
                            </>
                          ) : (
                            <>
                              <img
                                src={Edit}
                                alt="Edit"
                                className="mr-2 cursor-pointer"
                                onClick={() => handleEdit(invoice)}
                              />
                              <img
                                src={Delete}
                                alt="Delete"
                                className="cursor-pointer"
                                onClick={() => handleDelete(invoice)}
                              />
                            </>
                          )}
                        </div>
                      </td>
                      {/* {hoveredRow === index && hasError && !isEditing && (
                       <div className="absolute inset-0 flex justify-center items-center">
                       <div className="bg-white text-black text-sm px-4 py-2 rounded-md shadow-lg">
                         {validationMessage}
                       </div>
                     </div>
                      )} */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <br />
          <div className="flex justify-between">
            <div>
              <Button
                onClick={handleCancel}
                neutral
                className="bg-[red] border-[1px] border-silver rounded-[5px] py-[12px] px-[24px] !text-white font-medium !text-sm ml-[10px]"
              >
                Cancel
              </Button>
            </div>
            <div>
              <Button
                onClick={handleSaveLater}
                neutral
                className="bg-[#F8F8F8] border-[1px] border-silver rounded-[5px] py-[12px] px-[24px] !text-black font-medium !text-sm"
              >
                Save for later
              </Button>

              <Button
                onClick={handleOpen}
                neutral
                className="bg-primaryColor border-[1px] border-silver rounded-[5px] py-[12px] px-[24px] !text-white font-medium !text-sm ml-[10px]"
              >
                {loading ? (
                  <div className="flex items-center">
                    <Spinner className="mr-2" />
                    Save & Upload
                  </div>
                ) : (
                  " Save & Upload"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default InvoiceTable;
