import { AnimatePresence, motion } from "framer-motion";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect, useState } from "react";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import toast from "react-hot-toast";
import FunderRequestAcceptedModal from "./modals/FunderRequestAcceptedModal";
import RejectBidReasonModal from "./modals/RejectBidReasonModal";
import FunderRequestDeclineModal from "./modals/FunderRequestDeclineModal";
import NewAllBidsModal from "./modals/NewAllBidsModal";
import { useDispatch, useSelector } from "react-redux";
import {
  detailsBuyerOpenMarket,
  getAllSupplierOpenMarketByStatus,
  updateBidStatus,
} from "appstate/openMarket/openMarketSlice";
import InvoiceDetails from "features/openMarket/common/components/InvoiceDetails";
import BidDetails from "./OpenMarkretBidDetails";

function PendingInvoiceDetails() {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const offerId = searchParams.get("offerId");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [acceptBidModalOpen, setAcceptBidModalOpen] = useState(false);
  const [rejectBidModalOpen, setRejectBidModalOpen] = useState(false);
  const [rejectBidReasonModal, setRejectBidReasonModal] = useState(false);
  const [invoiceDeclineSuccessModalOpen, setInvoiceDeclineSuccessModalOpen] =
    useState(false);
  const [invoiceSuccessModalOpen, setInvoiceSuccessModalOpen] = useState(false);

  useEffect(() => {
    dispatch(detailsBuyerOpenMarket({ openMarketReference: id }));
  }, [dispatch, id]);

  const { detailsBuyerOpenMarketData, isLoading } = useSelector(
    (state) => state?.openMarket
  );

  const invoiceByIdData = detailsBuyerOpenMarketData;

  function handleGoBack() {
    navigate(-1);
  }

  // Handle Accept Bid
  const handleAcceptBid = () => {
    setAcceptBidModalOpen(false);

    dispatch(updateBidStatus({ offerId, status: "Accepted" })).then((data) => {
      if (data?.payload?.success) {
        setInvoiceSuccessModalOpen(true);
        setTimeout(() => {
          toast.success("Funder Request Accepted");
        }, 50);

        dispatch(getAllSupplierOpenMarketByStatus({ status: "Pending" }));
      }
    });

    // setTimeout(() => {
    //   // toast.success("Invoice Requested Successfully");
    // }, 600);

    // setTimeout(() => {
    //   toast.success("Funder Request Accepted");
    // }, 650);
  };

  // Handle Reject Bid
  const handleRejectBid = () => {
    setRejectBidModalOpen(false);
    setTimeout(() => {
      setRejectBidReasonModal(true);
      //   // toast.success("Invoice Requested Successfully");
    }, 600);
  };

  return (
    <DashboardLayout subHeader="Here is what is happening in your finance">
      <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
        <header onClick={handleGoBack} className="w-full">
          <div className="flex cursor-pointer w-fit gap-1 items-center">
            <PAIconArrowLeftGreen />
            <span className=" text-primaryColor text-sm">Go back</span>
          </div>
        </header>

        <main className="flex flex-col semi-lg:flex-row pb-8 gap-8 w-full ">
          {/* <InvoiceDetails
            invoiceByIdData={invoiceByIdData}
            // setLockModalOpen={setLockModalOpen}
          /> */}
          <InvoiceDetails
            invoiceByIdData={invoiceByIdData}
            // status="Posted"
            openMarketReference={id}
            pending
            setActiveTab={() => {
              sessionStorage.setItem("activeTabSupplier", 2);
              navigate("/open-market");
            }}
          />

          <motion.section
            initial={{
              y: -20,
            }}
            animate={{
              y: 0,
            }}
            exit={{
              y: 20,
            }}
            transition={{
              delay: 0.8,
            }}
            className="flex flex-col gap-3  pt10 pb-24 w-full semi-lg:w-2/5 bg-white rounded-[10px]"
          >
            <BidDetails
              data={invoiceByIdData?.data}
              paymentDate={invoiceByIdData?.data?.paymentDate}
              pending
            />
          </motion.section>
        </main>
      </TransitionScale>

      <NewAllBidsModal
        isLoading={isLoading}
        isOpen={isOpen}
        // allbidsData={getAllBidsData?.data}
        setIsOpen={setIsOpen}
      />

      {/* Accept Bid Modal */}
      <AnimatePresence>
        {acceptBidModalOpen && (
          <ViewDocumentModal
            onAction={handleAcceptBid}
            onClose={() => setAcceptBidModalOpen(false)}
          >
            <span className=" text-center">
              Would you like to proceed with {` `}
              <span className="font-bold text-center text-primaryColor">
                Accepting
                {/* {selectedData?.name} */}
              </span>{" "}
              {` `}
              request bid
              <div>offer?</div>
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {/* Reject Bid Modal */}
      <AnimatePresence>
        {rejectBidModalOpen && (
          <ViewDocumentModal
            onAction={handleRejectBid}
            onClose={() => setRejectBidModalOpen(false)}
          >
            <span className=" text-center">
              Would you like to proceed with {` `}
              <span className="font-bold text-center text-red">
                Rejecting
                {/* {selectedData?.name} */}
              </span>{" "}
              {` `}
              request bid
              <div>offer?</div>
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {/* Invoice Accept Success Modal */}
      <AnimatePresence>
        {invoiceSuccessModalOpen && (
          <FunderRequestAcceptedModal
            onClose={() => setInvoiceSuccessModalOpen(false)}
          />
        )}
      </AnimatePresence>

      {/* Invoice Decline Success Modal */}
      <AnimatePresence>
        {invoiceDeclineSuccessModalOpen && (
          <FunderRequestDeclineModal
            onClose={() => setInvoiceDeclineSuccessModalOpen(false)}
          />
        )}
      </AnimatePresence>

      {/* Reject Bid Reject Reason Modal */}
      <AnimatePresence>
        {rejectBidReasonModal && (
          <RejectBidReasonModal
            onClose={() => setRejectBidReasonModal(false)}
            setRejectBidReasonModal={setRejectBidReasonModal}
            setInvoiceDeclineSuccessModalOpen={
              setInvoiceDeclineSuccessModalOpen
            }
          />
        )}
      </AnimatePresence>
    </DashboardLayout>
  );
}

export default PendingInvoiceDetails;
