import {
  allSupplierInvoiceData,
  supplierInvoiceApprove,
} from "appstate/invoice/invoiceSlice";
import { allVirtualAccounts } from "appstate/wallet/walletSlice";
// import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import SelectAccountModal from "features/supplierEarlyPayment/payment";
// import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AcceptModal from "shared/AcceptModal";
import Card from "shared/Card";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";
import SuccessModal from "shared/SuccessModal";
import TableHeader from "shared/tableHeader/TableHeader";

const AllSupplierInvoices = () => {
  const navigate = useNavigate();
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [productName, setProductName] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [hasSelected, setHasSelected] = useState(false);
  const [isSelectAccountModalOpen, setIsSelectAccountModalOpen] = useState("");
  const [isProceedModalOpen, setIsProceedModalOpen] = useState(false);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const dispatch = useDispatch();

  // const handleNavigate = () => {
  //   navigate(-1);
  // };

  const { supplierInvoiceData, isLoading } = useSelector(
    (state) => state?.invoice
  );

  const selectedAccounts = useSelector(
    (state) => state?.wallet?.selectedAccounts
  );

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;
  // const [status, setStatus] = useState("");
  const [status] = useState("");

  const handleSearch = () => {
    dispatch(
      allSupplierInvoiceData({ page, pageSize, search: productName, status })
    );
  };

  useEffect(() => {
    dispatch(
      allSupplierInvoiceData({ page, pageSize, search: productName, status })
    );
  }, [dispatch, page, pageSize, status, productName]);

  // const InvoiceDetails = (id) => {
  //   let path = `/invoice/supplier-invoice-details/${id}`;
  //   navigate(path);
  // };

  function handleRedirect() {
    let path = "/invoice/invoice";
    navigate(path);
  }

  const InvoiceDetails = (id, status) => {
    // let path = "/collections/recievables";
    let path = "";

    switch (status) {
      case "APPROVED":
        path = `/invoice/invoice/approve/${id}`;
        break;
      case "PARTIAL_PAYMENT":
        path = `/invoice/invoice/partial-payment/${id}`;
        break;
      case "PAID":
        path = `/invoice/invoice/paid/${id}`;
        break;
      case "PENDING_WORKFLOW":
        path = `/invoice/invoice/pending-workflow/${id}`;
        break;
      case "PENDING_APPROVAL":
        path = `/invoice/invoice/pending-approval/${id}`;
        break;
      case "ACTIVE":
        path = `/invoice/invoice/active/${id}`;
        break;
      case "CREATED":
        path = `/invoice/invoice/created/${id}`;
        break;
      default:
        break;
    }

    navigate(path);
  };

  const options = [
    { id: 1, name: "All" },
    { id: 2, name: "CREATED" },
    { id: 3, name: "PAID" },
    { id: 4, name: "PENDING_APPROVAL" },
    { id: 5, name: "PENDING_WORKFLOW" },
    { id: 6, name: "AWAITING_PAYMENT" },
    { id: 7, name: "APPROVED" },
  ];

  const statusMapping = {
    CREATED: "Created",
    PAID: "Paid",
    PENDING_APPROVAL: "Pending Approval",
    PENDING_WORKFLOW: "Pending Workflow",
    AWAITING_PAYMENT: "Awaiting Payment",
    APPROVED: "Approved",
    PARTIAL_PAYMENT: "Partial Payment",
    ACTIVE: "Active",
    FULLY_PAID: "Fully Paid",
    PARTIALLY_PAID: "Partially Paid",
    REJECTED: "Rejected",
  };

  const handleFilterChange = (e) => {
    setSelectedFilter(e.target.value);
  };

  const handleAccept = () => {
    setIsAcceptModalOpen(false);
    setIsSelectAccountModalOpen(true);
    // setIsSuccessModalOpen(true);
    // setSuccessMessage("thanks");
    // dispatch(acceptOffer({ offerId, invIds: invoiceId })).then((response) => {
    //   if (response?.payload?.status === 200 && response?.payload?.success) {
    //     setIsSuccessModalOpen(true);
    //     setSuccessMessage(response?.payload?.message);
    //   }
    // });
  };

  const handleAccountSubmit = () => {
    setIsSelectAccountModalOpen(false);
    setIsProceedModalOpen(true);
  };

  useEffect(() => {
    dispatch(allVirtualAccounts());
  }, [dispatch]);

  const handleProceedSubmit = () => {
    setIsProceedModalOpen(false);

    const approvedBySupplier = {
      invoiceIds: selectedInvoices,
      virtualAccountId: selectedAccounts?.id,
    };

    dispatch(supplierInvoiceApprove({ body: approvedBySupplier })).then(
      (data) => {
        if (data?.payload?.success) {
          setIsSuccessModalOpen(true);
          setSuccessMessage(data?.payload?.message);
          dispatch(
            allSupplierInvoiceData({
              page,
              pageSize,
              search: productName,
              status,
            })
          );
          handleRedirect();
        }
      }
    );
  };

  const handleApproval = () => {
    if (hasSelected) {
      setIsAcceptModalOpen(true);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedInvoices((prevSelected) => {
      const newSelected = prevSelected.includes(id)
        ? prevSelected.filter((invoiceId) => invoiceId !== id)
        : [...prevSelected, id];

      setHasSelected(newSelected.length > 0);
      return newSelected;
    });
  };

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    setIsSelectAllChecked(isChecked);
    if (isChecked) {
      setSelectedInvoices(filteredData.map((invoice) => invoice.id));
      setHasSelected(true);
    } else {
      setSelectedInvoices([]);
      setHasSelected(false);
    }
  };

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const filteredData = supplierInvoiceData?.data?.filter((invoice) => {
    if (selectedFilter === "All") return true;
    return invoice.status === selectedFilter;
  });

  const columns = [
    ...(selectedFilter === "CREATED"
      ? [
          {
            name: (
              <input
                type="checkbox"
                onChange={handleSelectAllChange}
                checked={isSelectAllChecked}
              />
            ),
            cell: (row) => (
              <input
                type="checkbox"
                checked={selectedInvoices.includes(row.id)}
                onChange={() => handleCheckboxChange(row.id)}
              />
            ),
            width: "50px",
          },
        ]
      : []),
    {
      name: "Invoice Reference",
      selector: (row) => row.invoiceRef,
      sortable: true,
    },
    {
      name: "Buyer",
      selector: (row) => row.buyer,
      sortable: true,
    },
    {
      name: "Invoice Date",
      selector: (row) => row.invoiceDate,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row.dueDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoicedAmount,
      cell: (row) => (
        <p className="text-success">{formatAmount(row.invoicedAmount)}</p>
      ),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        const displayStatus = statusMapping[row.status] || row.status;
        const successStatuses = ["APPROVED", "PAID", "FULLY_PAID"];
        const rejectedStatuses = ["PARTIALLY_PAID", "REJECTED"];
        const awaitingStatuses = [
          "PENDING_APPROVAL",
          "AWAITING_APPROVAL",
          "PARTIAL_PAYMENT",
          "ACTIVE",
        ];
        const createdStatus = ["CREATED"];

        return successStatuses.includes(row.status) ? (
          <p className="text-success">{displayStatus}</p>
        ) : rejectedStatuses.includes(row.status) ? (
          <p className="text-red">{displayStatus}</p>
        ) : createdStatus.includes(row.status) ? (
          <p className="text-[#065256]">{displayStatus}</p>
        ) : awaitingStatuses.includes(row.status) ? (
          <p className="text-yellow-400">{displayStatus}</p>
        ) : (
          <p>{displayStatus}</p>
        );
      },
    },
  ];

  return (
    <>
      {/* <div
        onClick={handleNavigate}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
      >
        <PAIconInventoryArrowLeft />
        <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
      </div> */}
      <div>
        <Card className="p-[24px] rounded-[10px]">
          <div>
            <TableHeader
              total={`${
                supplierInvoiceData?.meta?.totalElements || 0
              } Invoices`}
              details={
                <p>
                  Filter by saved invoices to select multiple saved invoices for
                  approval
                </p>
              }
              // productName={productName}
              // setProductName={setProductName}
              searchTerm={productName}
              setSearchTerm={setProductName}
              handleSearch={handleSearch}
              showSelectContainer={true}
              options={options}
              handleChange={handleFilterChange}
              approval
              // funder
              approvalName="Submit for approval"
              active={hasSelected}
              nonActive={!hasSelected}
              handleApproval={handleApproval}
            />
          </div>
          <Table
            columns={columns}
            data={filteredData}
            onRowClicked={(row) => InvoiceDetails(row.id, row.status)}
            progressPending={isLoading}
            progressComponent={<Loading />}
            pointer
            tableHeader
            className="mt-5 min-h-[60vh]"
          />
          <div className="flex justify-center gap-x-[8px] mt-[20px]">
            {supplierInvoiceData?.data?.length && (
              <Pagination
                page={page}
                startPage={startPage}
                setEndPage={setEndPage}
                endPage={endPage}
                setStartPage={setStartPage}
                setPage={setPage}
                totalPages={supplierInvoiceData?.meta?.totalElements}
              />
            )}
          </div>
          <AcceptModal
            isOpen={isAcceptModalOpen}
            onClose={() => setIsAcceptModalOpen(false)}
            message="Would you like to submit these invoices for approval"
            onConfirm={handleAccept}
            loading={false}
          />
          <SuccessModal
            isOpen={isSuccessModalOpen}
            onClose={() => setIsSuccessModalOpen(false)}
            message={successMessage}
          />
          <SelectAccountModal
            isOpen={isSelectAccountModalOpen}
            onClose={() => setIsSelectAccountModalOpen(false)}
            onSubmit={handleAccountSubmit}
          />
          <AcceptModal
            isOpen={isProceedModalOpen}
            onClose={() => setIsProceedModalOpen(false)}
            message={
              <>
                Would you like to proceed with this account{" "}
                <span className="text-[#07593D] font-bold">
                  {selectedAccounts?.accountNumber}?
                </span>
              </>
            }
            onConfirm={handleProceedSubmit}
            loading={false}
          />
        </Card>
      </div>
    </>
  );
};

export default AllSupplierInvoices;
