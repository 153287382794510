import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import InputField from "shared/InputField";
// import { useDispatch } from "react-redux";
// import { creatNewPfp } from "appstate/buyer/buyerSlice";
// import { unwrapResult } from "@reduxjs/toolkit";

const StepOne = ({
  handleNextPage,
  formData,
  errors,
  handleInputChange,
  dynamic,
  reverse,
  handleReverse,
  handleDynamic,
  setuserPfpId,
  isNextDisabled,
}) => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  function handleNavigate() {
    navigate(-1);
  }

  return (
    <div className="w-[31.25rem]">
      <div className="mb-[20px]">
        <p className="text-gray800 text-[14px] mb-1 font-semibold">
          Program Name:
        </p>
        <InputField
          labelFontSize
          type="text"
          id="programName"
          name="programName"
          placeholder="Enter name of program"
          className="border border-[#c5c5c5] text-gray800 rounded-[4px] px-[16px] py-[12px] outline-none w-[500px] height-[50px]"
          onChange={handleInputChange}
          value={formData.programName}
        />
        {errors.programName && (
          <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
            {errors.programName}
          </p>
        )}
      </div>

      <div>
        <div className="relative mb-[20px] flex items-center">
          <div className="flex items-center border-[1px] border-[#b3b3b3] px-5 rounded-[20px]">
            <InputField
              labelFontSize
              id="dynamic"
              type="checkbox"
              placeholder="Enter name of program"
              onChange={handleDynamic}
              checked={formData.financingOptions === 'DYNAMIC_DISCOUNTING'}
              disabled={reverse}
              className=" h-[14px] outline-none mr-2"
              value="DYNAMIC_DISCOUNTING"
            />
            <label htmlFor="dynamic" className="text-sm text-gray800">
              Dynamic Discounting
            </label>
          </div>

          <div className="flex ml-3 items-center border-[1px] border-[#b3b3b3] px-5 rounded-[20px]">
            <InputField
              labelFontSize
              id="reverse"
              type="checkbox"
              onChange={handleReverse}
              checked={formData.financingOptions === "REVERSE_FACTORING"}
              value="REVERSE_FACTORING"
              placeholder="Enter name of program"
              disabled={dynamic}
              className=" h-[14px] outline-none mr-2"
            />
            <label htmlFor="reverse" className="text-sm text-gray800">
              Reverse Factoring
            </label>
          </div>
        </div>

        {!dynamic && !reverse && (
          <>
            {errors.financingOptions && (
              <p className="text-red text-xs w-[300px] md:w-[424px] relative bottom-[5px]">
                {errors.financingOptions}
              </p>
            )}
          </>
        )}
      </div>

      <div>
        <p className="text-gray800 mb-1 font-semibold text-[14px]">
          Campaign Duration:
        </p>
        <div className="flex items-center justify-between">
          <div className="mb-[20px] w-[49%] h-[80px]">
            <InputField
              labelFontSize
              type="date"
              label="Start Date:"
              id="startDate"
              name="startDate"
              placeholder="Enter name of program"
              className="border border-[#c5c5c5] rounded-[4px] px-[16px] py-[12px] outline-none"
              onChange={handleInputChange}
              value={formData.startDate}
            />
            {errors.startDate && (
              <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
                {errors.startDate}
              </p>
            )}
          </div>
          <div className="mb-[20px] w-[49%] h-[80px]">
            <InputField
              labelFontSize
              type="date"
              label="End Date:"
              id="endDate"
              name="endDate"
              placeholder="Enter name of program"
              className="border border-[#c5c5c5] rounded-[4px] px-[16px] py-[12px] outline-none "
              onChange={handleInputChange}
              value={formData.endDate}
            />
            {errors.endDate && (
              <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
                {errors.endDate}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="mt-[5px] mb-1">
        <label
          htmlFor=""
          className="text-[14px] font-semibold text-gray800 mb-[8px]"
        >
          Amount Range
        </label>
        <div className="flex justify-between">
          <div className="mb-[20px] w-[235px]">
            <InputField
              labelFontSize
              type="number"
              label="Minimum Amount"
              id="minAmount"
              name="minAmount"
              placeholder="min"
              onChange={handleInputChange}
              value={formData.minAmount}
              className=" text-[#a1a1a1] border-[#c5c5c5] border [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none rounded-[4px] px-[16px] py-[12px]  outline-none w-full"
            />
            {errors.minAmount && (
              <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
                {errors.minAmount}
              </p>
            )}
          </div>
          <div className="mb-[20px] w-[235px]">
            <InputField
              labelFontSize
              type="number"
              label="Maximum Amount"
              id="maxAmount"
              name="maxAmount"
              placeholder="max"
              onChange={handleInputChange}
              value={formData.maxAmount}
              className="border border-[#c5c5c5] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none rounded-[4px] px-[16px] py-[12px]  outline-none w-full"
            />
            {errors.maxAmount && (
              <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
                {errors.maxAmount}
              </p>
            )}
          </div>
        </div>
      </div>

      <div>
        <InputField
          labelFontSize
          type="number"
          label="Discount Rate"
          id="discount"
          name="discount"
          placeholder="Discount rate"
          className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none border border-[#c5c5c5] rounded-[4px] px-[16px] py-[12px]  outline-none"
          onChange={handleInputChange}
          value={formData.discount}
        />
        {errors.discount && (
          <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
            {errors.discount}
          </p>
        )}
      </div>

      <div className="mt-4">
        <InputField
          labelFontSize
          type="number"
          label="Offer Pecentage"
          id="percentage"
          name="percentage"
          placeholder="Offer Pecentage"
          className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none border border-[#c5c5c5] rounded-[4px] px-[16px] py-[12px]  outline-none"
          onChange={handleInputChange}
          value={formData.percentage}
        />
        {errors.percentage && (
          <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
            {errors.percentage}
          </p>
        )}
      </div>

      <div className="flex justify-between mt-[1.88rem]">
        <Button
          onClick={handleNavigate}
          type="Button"
          neutral
          className="!text-lightGray text-base !font-normal"
        >
          Cancel
        </Button>
        <Button
          type="Button"
          neutral
          onClick={handleNextPage}
          disabled={isNextDisabled}
          className="!text-white !font-normal bg-primaryColor py-[8px] px-[24px] rounded-[5px]"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default StepOne;
