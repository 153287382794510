import React from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import Loading from "shared/Loading";
import TableEmptyState from "shared/TableEmptyState";
import { useNavigate } from "react-router-dom";

const EarlyPaymentComponent = ({ requestType, data, bulkData }) => {
  const navigate = useNavigate();

  const onRowClicked = (row) => {
    if(row?.status === "PENDING_APPROVAL"){
      let path = `/single-early-payment-details-pending-approval/${row?.id}`;
      navigate(path, { state: { invoiceId: row?.invoiceId } });
    }
    if(row?.status === "APPROVED"){
      let path = `/single-early-payment-details-approval/${row?.id}`;
      navigate(path, { state: { invoiceId: row?.invoiceId } });
    }
    if(row?.status === "PROCESSING"){
      let path = `/single-early-payment-details-processing/${row?.id}`;
      navigate(path, { state: { invoiceId: row?.invoiceId } });
    }
    if(row?.status === "REJECTED"){
      let path = `/single-early-payment-details-rejected/${row?.id}`;
      navigate(path, { state: { invoiceId: row?.invoiceId } });
    }
  };

  const onBulkEarlyPaymentRowClicked = (row) => {
    if(row?.status === "PENDING_APPROVAL"){
      let path = `/single-bulk-early-payment-details-pending-approval/${row?.earlyPaymentId}`;
      navigate(path, { state: { invoiceId: row?.earlyPaymentId } });
    }
    if(row?.status === "APPROVED"){
      let path = `/single-bulk-early-payment-details-approval/${row?.earlyPaymentId}`;
      navigate(path, { state: { invoiceId: row?.invoiceId } });
    }
    if(row?.status === "PROCESSING"){
      let path = `/single-bulk-early-payment-details-processing/${row?.earlyPaymentId}`;
      navigate(path, { state: { invoiceId: row?.invoiceId } });
    }
    if(row?.status === "REJECTED"){
      let path = `/single-bulk-early-payment-details-rejected/${row?.earlyPaymentId}`;
      navigate(path, { state: { invoiceId: row?.invoiceId } });
    }
  };


  const columns =
    requestType === "BulkRequest"
      ? [
          { name: "Request Ref No", selector: (row) => row.reference },
          { name: "Buyer", selector: (row) => row.buyerCompanyName },
          {
            name: "Early Payment Date",
            selector: (row) => row.newRequestedDate,
          },
          { name: "No. of Invoices", selector: (row) => row.noOfInvoice }, 
          {
            name: "Request Amount",
            selector: (row) => row.totalRequestedAmount,
            cell: (row) => <p className="text-success">{row.totalRequestedAmount}</p>,
          },
          {
            name: "Discount Amount",
            selector: (row) => row.discountAmount,
            cell: (row) => <p className="text-warning">{row.discountAmount}</p>,
          },
          {
            name: "Receivable Amount",
            selector: (row) => row.receivableAmount,
            cell: (row) => (
              <p style={{color: "#F5A850"}} className="text-primary">{row.receivableAmount}</p>
            ),
          },
          {
            name: "Status",
            selector: (row) => row.status,
            cell: (row) => (
              <span
              style={{
                color:
                  row.status === "PENDING_CONFIRMATION"
                    ? "#F08000"
                    : row.status === "APPROVED"
                    ? "#2EA923"
                    : row.status === "REJECTED"
                    ? "#D40606"
                    : row.status === "PROCESSING"
                    ? "#5C8AFF"
                    : "black",
              }}
              >
                {row.status}
              </span>
            ),
          },
        ]
      : [
          { name: "Request Ref No", selector: (row) => row.reference },
          { name: "Invoice Reference", selector: (row) => row.reference }, 
          { name: "Buyer", selector: (row) => row.buyerCompanyName },
          {
            name: "Payment Date",
            selector: (row) => row.newRequestedDate,
          },
          {
            name: "Request Amount",
            selector: (row) => row.requestAmount,
            cell: (row) => <p className="text-success">{row.totalRequestedAmount}</p>,
          },
          {
            name: "Discount Amount",
            selector: (row) => row.discountAmount,
            cell: (row) => <p className="text-warning">{row.discountedAmount}</p>,
          },
          {
            name: "Receivable Amount",
            selector: (row) => row.receivableAmount,
            cell: (row) => (
              <p style={{color: "#F5A850"}} className="text-primary">{row.receivableAmount}</p>
            ),
          },
          {
            name: "Status",
            selector: (row) => row.status,
            cell: (row) => (
              <span
              style={{
                color:
                  row.status === "PENDING_CONFIRMATION"
                    ? "#F08000"
                    : row.status === "APPROVED"
                    ? "#2EA923"
                    : row.status === "REJECTED"
                    ? "#D40606"
                    : row.status === "PROCESSING"
                    ? "#5C8AFF"
                    : "black",
              }}
              >
                {row.status}
              </span>
            ),
          },
        ];

  return (
    <>
      {data?.length === 0 ? (
        <>
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">Recent Request</p>
          </div>
          <TableEmptyState dashboard />
        </>
      ) : (
        <Card className="rounded-[10px]">
          <div className="pb-[24px]">
            {requestType === "BulkRequest" ? <Table
              columns={columns}
              data={bulkData}
              progressPending={false}
              progressComponent={<Loading />}
              onRowClicked={onBulkEarlyPaymentRowClicked}
              pointer
              tableHeader
              className="mt-5"
            /> : <Table
              columns={columns}
              data={data}
              progressPending={false}
              onRowClicked={onRowClicked}
              progressComponent={<Loading />}
              pointer
              tableHeader
              className="mt-5"
            />}
          </div>
        </Card>
      )}
    </>
  );
};

export default EarlyPaymentComponent;
