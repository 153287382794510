import { PAIconCircleAdd, PAIconColapse } from "assets/images/svgs";
import { useSelector } from "react-redux";
import Button from "shared/Button";

const OverviewCard = () => {
  const { supplierDashboardData } = useSelector((state) => state.supplier);
  return (
    <>
      <div className="flex justify-between gap-x-[12px] mt-[30px] lg:mt-0 2xl:w-[450px] 2xl:justify-between">
        <div className="bg-secondaryColor py-[67px] xl:py-[57px] lg:py-[30px] rounded-t-[10px] border-b-[2px] border-white 2xl:w-full">
          <div>
            <div className="flex justify-center mb-[8px]">
              <PAIconCircleAdd />
            </div>
            <p className="text-white text-[18px] xl:text-lg mx-[17px] md:mx-[24px] xl:mx-[24px] whitespace-nowrap font-medium lg:mx-[15px] lg:text-sm 2xl:text-center">
              Create Invoice
            </p>
          </div>
        </div>
        <div className="bg-white rounded-t-[10px] border-b-[2px] border-secondaryColor 2xl:w-full">
          <div className="mt-[23px] xl:mt-[23px] lg:mt-[11px] flex justify-end mr-[19.38px]">
            <PAIconColapse />
          </div>
          <div className="mt-[21.4px] xl:mt-[21.4px] lg:mt-[11px]">
            <h1 className="text-center text-5xl font-bold mb-[8px]">
              {supplierDashboardData?.data?.totalInvoices || "2"}
            </h1>
            <p className="text-gray800 text-sm font-normal mx-[30px] whitespace-nowrap md:mx-[39px] xl:mx-[39px] lg:mx-[15px] 2xl:text-center">
              Total Invoices
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white pt-[20px] pb-[33px] mt-[20px] rounded-b-[10px] border-t-[2px] border-secondaryColor 2xl:w-full">
        <div className="flex justify-between px-[20px] items-center">
          <h1 className="text-primaryColor text-[20px] font-normal">
            Invoices
          </h1>
          <Button
            neutral
            className="!text-white bg-secondaryColor px-[10px] py-[5px] rounded-[5px] flex gap-x-[10px] items-center"
          >
            <p className="text-base font-normal">Open market</p>
            <PAIconColapse className="w-[12px]" />
          </Button>
        </div>
        <div className="mt-[29px] flex justify-between px-[20px] items-center">
          <div>
            <h1 className="text-primaryColor text-center text-[40px] font-bold">
              {supplierDashboardData?.data?.totalOpenInvoices || "2"}
            </h1>
            <p className="text-sm text-gray800 font-normal">Opened</p>
          </div>
          <div className="border-r-[1px] border-primaryColor border-l-[1px] px-[40px] xl:px-[30px] lg:px-[20px]">
            <h1 className="text-secondaryColor text-center text-[40px] font-bold">
              {supplierDashboardData?.data?.totalLockedInvoices || "0"}
            </h1>
            <p className="text-sm text-gray800 font-normal">Locked</p>
          </div>
          <div>
            <h1 className="text-lightGreen text-center text-[40px] font-bold">
              {supplierDashboardData?.data?.totalSettledInvoices || "2"}
            </h1>
            <p className="text-sm text-gray800 font-normal">Settled</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverviewCard;
