import React, { useState } from "react";
import { PAIconSmallClose } from "assets/images/svgs";
import { motion } from "framer-motion";
import MultiSelectComponent from "features/openMarket/supplier/components/MultiSelectedComponent";
import { rejectSingleEarlyPaymentRequest } from "appstate/buyer/buyerSlice";
// import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

const RejectReason = ({
  onClose,
  isOpen,
  confirm,
  setIsRequestRejected,
  earlyPaymentId,
}) => {
  const [selectedReason, setSelectedReason] = useState([]);
  const dispatch = useDispatch();

  if (!isOpen) {
    return null;
  }

  const reasons = selectedReason.map((item) => item.label)

  
  const handleConfirmation = async () => {
    try {
      const response = await dispatch(
        rejectSingleEarlyPaymentRequest(earlyPaymentId, reasons)
      ).unwrap();
      if (response.status === 200) {
        setIsRequestRejected(true);
      } else {
        toast.error(response.message);
        return
      }
    } catch (error) {
      console.error("Error:", error);
    }
    confirm();
  };

  return (
    <div className="bg-[rgba(0,0,0,0.2)] w-[100%] h-[100vh] flex justify-center items-center absolute left-0 top-0">
      <div className=" py-9 overflow-auto bg-white rounded-[10px] w-[60%] md:w-1/2 h-[55%] flex flex-col">
        <header className="flex mx-6 sticky top-0 bg-white justify-between pb-4 border-b-[3px] border-[#F6F5FA]">
          <span className=" text-[#222] font-bold">Reason Required</span>

          <span
            onClick={onClose}
            className="flex items-center cursor-pointer gap-2 text-sm font-[500] text-[#565656]"
          >
            <PAIconSmallClose />
            Close
          </span>
        </header>

        <div className="flex flex-col pt-2">
          <div className="text-[#959595] px-6 py-4 text-sm text border-b-[2px]  border-[#F6F5FA] w-full flex justify-center bg-white">
            Please provide a detailed explanation for rejecting the bid. Your
            insights assist in improving our platform and understanding user
            preferences. Thank you for your valuable feedback
          </div>

          <div className="flex flex-col gap-3 py-5 px-6 text-[#959595]">
            <span className=" text-sm text-primaryColor font-[500]">
              Input your reply or choose from our predefined responses
            </span>

            <div className="rounded-[5px] text-[#959595] border border-primaryColor h-[100px] flex items-start flex-col pt-[2%]">
              <div className="flex flex-wrap justify-start items-center">
                {selectedReason?.map((option) => (
                  <span
                    key={option.id}
                    className="border border-[#F08000] text-primaryColor rounded-md px-2 py-2 ml-2"
                  >
                    {option.label}
                    <button
                      className="ml-1"
                      onClick={() =>
                        setSelectedReason((prevSelected) =>
                          prevSelected.filter((item) => item !== option)
                        )
                      }
                    >
                      &#10006;
                    </button>
                  </span>
                ))}
                <input className="  outline-none px-5 w-[35%]" type="text" />
              </div>
            </div>

            <div className=" flex gap-3 flex-wrap  mt-5">
              <MultiSelectComponent setSelectedReason={setSelectedReason} />
            </div>

            <motion.button
              whileTap={{ scale: 0.9 }}
              onClick={() => handleConfirmation()}
              className=" w-full mt-5 rounded-[5px] text-white bg-primaryColor px-6 py-3"
            >
              Submit
            </motion.button>
            {/* Congratulations! request Accepted. You've successfully agreed to
              the terms. Expect further details on the transaction shortly.
              Thank you! */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectReason;
