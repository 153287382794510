import TransitionOpacity from "shared/animation/TransitionOpacity";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getAllSupplierOpenMarket,
  pendingBuyerConfirmationSupplier,
} from "appstate/openMarket/openMarketSlice";
import LoadingScreen from "shared/LoadingScreen";
import EmptyPage from "features/roleManager/Dashbaord/components/EmptyPage";
import { PAIconSearchGreen } from "assets/images/svgs";
import { motion } from "framer-motion";
import PendingConfirmationCard from "./cards/PendingConfirmationCard";
import Pagination from "shared/Pagination";

function PendingConfirmation({
  pendingConfirmationQuery,
  setPendingConfirmationQuery,
}) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(3);
  const pageSize = 10;

  const { isLoading, pendingBuyerConfirmationSupplierData } = useSelector(
    (state) => state?.openMarket
  );

  useEffect(() => {
    dispatch(
      pendingBuyerConfirmationSupplier({
        page,
        pageSize,
        searchQuery: pendingConfirmationQuery,
      })
    );

    dispatch(getAllSupplierOpenMarket());
  }, [dispatch, page, pageSize, pendingConfirmationQuery]);

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setPendingConfirmationQuery(value);
  };

  // console.log(pendingBuyerConfirmationData);

  return (
    <TransitionOpacity className="flex flex-col gap-5 min-h-[75vh] rounded-b-[10px] bg-unusualGray  pb-6">
      <div className="flex bg-white py-5 px-6 justify-between items-center w-full">
        <div className="items-center gap-2.5">
          <span className="py-2.5 text-sm">Pending Buyer Confirmation </span>

          <p className="text-gray600 text-xs">
            List of request awaiting buyers confirmation before being moved to
            the open market
          </p>
        </div>
        <div className="flex w-[35%]  items-center gap-3">
          <div className="flex w-full items-center gap-4">
            <div className="flex gap-2.5 w-full bg-white border-[0.5px] border-[#ccc] p-2.5 items-center rounded-[5px]">
              <PAIconSearchGreen />
              <input
                type="text"
                placeholder="Search for request"
                value={pendingConfirmationQuery}
                onChange={handleSearchInputChange}
                className="px-2 w-full text-xs flex items-center text-black placeholder:text-black focus:outline-none"
              />
            </div>
          </div>

          <motion.button
            whileTap={{ scale: 0.94 }}
            className="py-2.5 text-white text-sm px-6 rounded-[5px] bg-[#2FA06A]"
          >
            Search
          </motion.button>
        </div>
      </div>
      <div className="w-full px-6 font-medium text-sm text-end text-primaryColor justify-end items-center">
        Days:Hours:Mins:Secs
      </div>
      {isLoading ? (
        <LoadingScreen />
      ) : pendingBuyerConfirmationSupplierData?.data?.length > 0 ? (
        <div className="flex px-6 flex-col gap-4 ">
          {pendingBuyerConfirmationSupplierData?.data?.map((invoice) => (
            <PendingConfirmationCard
              key={invoice?.invoiceId}
              id={invoice?.openMarketReference}
              requestAmount={invoice?.fundingAmount}
              invoiceNumber={invoice?.invoiceNumber}
              invoiceReference={invoice?.openMarketReference}
              buyerCompanyName={invoice?.buyerCompanyName}
              invoiceAmount={invoice?.fundingAmount}
              invoiceDueDate={invoice?.invoiceDueDate?.join("-")}
              status={invoice?.status}
            />
          ))}
          <div className="flex justify-center">
            {pendingBuyerConfirmationSupplierData?.meta?.totalElements >=
              pageSize && (
              <Pagination
                page={page}
                startPage={startPage}
                endPage={endPage}
                setEndPage={setEndPage}
                setPage={setPage}
                setStartPage={setStartPage}
                totalPages={
                  pendingBuyerConfirmationSupplierData?.meta?.totalElements
                }
              />
            )}
          </div>
        </div>
      ) : (
        <EmptyPage />
      )}
    </TransitionOpacity>
  );
}

export default PendingConfirmation;
