import { useState } from "react";
import "./styles/debitcard.css";
import { PAIconKitEyeclose, PAIconMasterCardLogo } from "assets/images/svgs";
function DebitCardNew({ cardData }) {
  const [isBalanceVisible, setBalanceVisibility] = useState(true);

  const toggleBalanceVisibility = () => {
    setBalanceVisibility((prevVisibility) => !prevVisibility);
  };
  return (
    <div className="debit-body flex flex-col gap-5 py-5 rounded-lg mb-6 px-[4%] w-full ">
      <div className=" flex justify-center items-center w-full">
        <div className="flex justify-center cursor-pointer  w-[209px] h-[121px] transition duration-[350ms] transform hover:scale-105 ease-in-out ">
          <div className="flex justify-between w-full shadow-card-mod py-[16px] px-[17px] relative rounded-[10px]">
            <div className=" w-full">
              <h2 className="text-[8px] font-semibold text-white ">
                Card Balance
              </h2>
              <div className=" flex items-center text-[24.35px] text-white">
                {/* <span>
                  <img className=' inline' src='/naira.svg' alt='₦' />
                </span> */}
                {isBalanceVisible ? `${cardData?.balance || "100,000,000"}` : "****"}
              </div>
              <p className="text-[9.4px] font-semibold text-white mb-[5px]">
                {cardData?.cardName || "Unregistered Card"}
              </p>
              <p className="text-[6.764px]  text-white">
                {cardData?.cardNumber || "0000 **** **** 0000"}
              </p>
            </div>
            <div className="flex flex-col justify-between items-center">
              <p className="text-[8px] font-[300] text-white ">
                {cardData?.expiryDate || "N/A"}
              </p>
              <PAIconKitEyeclose
                onClick={toggleBalanceVisibility}
                className=" cursor-pointer "
              />
              <PAIconMasterCardLogo />
            </div>
          </div>
        </div>
      </div>
      <div className=" gap-5 flex justify-center">
        <button className=" bg-white rounded-[5px] text-[#07593D] font-light text-xs px-5 py-2.5 transition duration-300 transform hover:scale-105 ease-in-out hover:bg-slate-100">
          Fund invoice
        </button>
        <button className=" bg-[#FF8E0D] rounded-[5px] text-white text-xs font-light px-5 py-2.5 transition duration-300 transform hover:scale-105 ease-in-out hover:bg-[#ff8e0de5] ">
          Fund account
        </button>
      </div>
    </div>
  );
}
export default DebitCardNew;
