import InputField from "shared/InputField";
import { currencies } from "utilities/Currencies";
import InvoiceSelect from "../components/InvoiceSelect";

const Controls = ({
  toggleTaxInclusive,
  setToggleTaxInclusive,
  editing,
  controls,
  setControls,
  singleInvoice,
  buyers,
}) => {
  const inputClassName =
    "rounded-[5px] border !border-[#CCCCCA] px-4 py-2 focus:outline-none w-full h-[51px] box-border";

  const options = [
    { id: 1, title: "Tax Inclusive", value: true },
    { id: 2, title: "Not Inclusive", value: false },
  ];

  return (
    <div className="relative">
      <div className="flex max-md:flex-col justify-between pb-[30px] gap-2 border-b">
        <div className="flex flex-col relative w-full">
          <label className="font-medium text-sm mb-1">To</label>
          <div className="flex items-center border rounded-lg overflow-hidden relative border-[#DADAD9] w-full h-[51px]">
            <select
              className={inputClassName}
              value={controls.to || ""}
              onChange={(e) => setControls({ ...controls, to: e.target.value })}
            >
              <option value="" disabled>
                Select an associated buyer
              </option>
              {buyers?.map((buyer) => (
                <option key={buyer.userId} value={buyer.userId}>
                  {buyer.fullName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <label className="font-medium text-sm mb-1">Date</label>
          <input
            type="date"
            value={controls.date || ""}
            onChange={(e) => setControls({ ...controls, date: e.target.value })}
            placeholder=""
            className={inputClassName}
          />
        </div>
        <div className="flex flex-col w-full">
          <label className="font-medium text-sm mb-1">Due Date</label>

          <input
            type="date"
            value={controls.dueDate || ""}
            onChange={(e) =>
              setControls({ ...controls, dueDate: e.target.value })
            }
            placeholder=""
            className={inputClassName}
          />
        </div>
        <div className="flex flex-col w-full">
          <label className="font-medium text-sm mb-1">
            Invoice #<span style={{ color: "#A1A7B0" }}>(optional)</span>
          </label>
          <InputField
            type="text"
            placeholder="09012A6BBB"
            className={inputClassName}
            value={controls.invoiceNo || ""}
            onChange={(e) =>
              setControls({ ...controls, invoiceNo: e.target.value })
            }
          />
        </div>
      </div>
      <div className="flex max-lg:flex-col justify-between py-[10px] mt-[10px]">
        <div className="flex">
          <div className="min-w-[230px]">
            <InvoiceSelect
              defaultValue={singleInvoice?.currency}
              onChange={(e) => {
                setControls({ ...controls, currency: e.target.value });
              }}
              options={currencies}
              optionsHolder="Currency"
              height="51px"
            />
          </div>
        </div>
        <div style={{width: "100%",  display: "flex", justifyContent: "flex-end"}}>
        <div style={{width:"30%"}} className="flex  flex-wrap items-center gap-1 max-lg:mt-[10px]">
          <p className=" text-sm whitespace-nowrap pr-[3px]">Amounts are</p>
          <div   className="flex flex-1 min-w-[40px]">
            <InvoiceSelect
              disabledSelect={editing}
              defaultValue={singleInvoice?.taxInclusive}
              onChange={(e) => {
                setToggleTaxInclusive(!toggleTaxInclusive);
                setControls({ ...controls, taxInclusive: e.target.value });
              }}
              options={options}
            />
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Controls;



