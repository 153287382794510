import React, { Suspense } from "react";
import Details from "features/relationshipManagement/supplier/buyer/Details";
import Associate from "features/relationshipManagement/supplier/buyer/associate";
import Createproduct from "features/inventory/Product/Createproduct";
import Product from "features/inventory/Product/Product";
import AllInvoiceContents from "features/invoice/AllInvoiceDashboard";
import NewInvoiceDashboardContents from "features/invoice/NewInvoiceDashboard";
import SingleInvoiceContents from "features/invoice/SingleInvoice";
import AddAccount from "features/onboarding/buyer/AddAccount";
import BuyerCompanyDetails from "features/onboarding/buyer/BuyerCompanyDetails";
import PersonalDetails from "features/onboarding/buyer/PersonalDetails";
import Terms from "features/onboarding/buyer/Terms";
import BuyerInviteSupplier from "features/onboarding/buyer/buyerOnboardSupplier/BuyerInviteSupplier";
import BuyerOnboardSupplierAddAccount from "features/onboarding/buyer/buyerOnboardSupplier/BuyerOnboardSupplierAddAccount";
import BuyerOnboardSupplierCompanyDetails from "features/onboarding/buyer/buyerOnboardSupplier/BuyerOnboardSupplierCompanyDetails";
import BuyerOnboardSupplierGetStarted from "features/onboarding/buyer/buyerOnboardSupplier/BuyerOnboardSupplierGetStarted";
import BuyerOnboardSupplierSigIn from "features/onboarding/buyer/buyerOnboardSupplier/BuyerOnboardSupplierSignIn";
import BuyerOnboardSupplierSignUp from "features/onboarding/buyer/buyerOnboardSupplier/BuyerOnboardSupplierSignUp";
import BuyerOnboardSupplierTerms from "features/onboarding/buyer/buyerOnboardSupplier/BuyerOnboardSupplierTerms";
import NextSteps from "features/onboarding/buyer/buyerOnboardSupplier/NextSteps";
import CooperateFunderCompany from "features/onboarding/funder/co-operate-funder/CooperateFunderCompany";
import CooperateFunderGetstarted from "features/onboarding/funder/co-operate-funder/CooperateFunderGetstarted";
import CooperateFunderPassword from "features/onboarding/funder/co-operate-funder/CooperateFunderPassword";
import CooperateFunderTerms from "features/onboarding/funder/co-operate-funder/CooperateFunderTerms";
import AddBankAll from "features/onboarding/supplier/Addbank";
import Companydetails from "features/onboarding/supplier/Companydetails";
import GetStartedAll from "features/onboarding/supplier/Getstarted";
import SelectBuyer from "features/onboarding/supplier/SelectBuyer";
import SupplierSignup from "features/onboarding/supplier/SupplierSignup";
import TermsAndCondition from "features/onboarding/supplier/TermsAndCondition";
import CreatePO from "features/purchaseOrder/createPurchaseOrder/CreatePO";
import SelectProducts from "features/purchaseOrder/createPurchaseOrder/SelectProducts";
import CreateComplaint from "features/support/components/CreateComplaint";
import UserCommunication from "features/support/components/UserCommunication";
// import TransactionDashboard from "features/transactions/transactionDashboard";
import SupplierBuyersPage from "pages/relationshipmanagement/supplier/buyers";
import CategoryPage from "pages/CategoryPage";
import Dashboard from "pages/Dashboard";
import Inventory from "pages/Inventory";
import InvoiceDashboard from "pages/Invoice";
import LandingPage from "pages/LandingPage";
import PurchaseOrder from "pages/PurchaseOrder";
import SettingsPage from "pages/SettingsPage";
import SupportPage from "pages/SupportPage";
import TransactionPage from "pages/TransactionPage";
import { Outlet } from "react-router-dom";
import ResendVerification from "shared/ResendVerification";
import RoleManagerAccountsPage from "pages/RoleManagerAccountsPage";
import RoleManagerDashboardPage from "pages/RoleManagerDashboard";
import RoleManagerDocument from "pages/RoleManagerDocument";
import FunderDetails from "features/relationshipManagement/supplier/funder/Details";
import AssociateFunders from "features/relationshipManagement/supplier/funder/associate";
import BuyerDetails from "features/relationshipManagement/funder/buyer/Details";
import FunderAssociateBuyers from "features/relationshipManagement/funder/buyer/associate";
import FunderBuyerPendingRequest from "features/relationshipManagement/funder/buyer/pendingRequest";
import SupplierDetails from "features/relationshipManagement/funder/supplier/Details";
import FunderAssociateSuppliers from "features/relationshipManagement/funder/supplier/associate";
import BuyerSupplierDetails from "features/relationshipManagement/buyer/supplier/Details";

import BuyerSuppliersPendingRequest from "features/relationshipManagement/buyer/supplier/pendingRequest";
import BuyerFunderDetails from "features/relationshipManagement/buyer/funder/Details";
import BuyerAssociateFunders from "features/relationshipManagement/buyer/funder/associate";
import SupplierFundersPage from "pages/relationshipmanagement/supplier/funders";
import FunderBuyersPage from "pages/relationshipmanagement/funder/buyer";
import FunderSuppliersPage from "pages/relationshipmanagement/funder/supplier";
import BuyerSuppliersPage from "pages/relationshipmanagement/buyer/supplier";
import BuyerFunderPage from "pages/relationshipmanagement/buyer/funder";
import NotificationPage from "pages/NotificationPage";
import FunderSignup from "features/onboarding/funder/funder/FunderSignup";
import FunderTerms from "features/onboarding/funder/funder/FunderTerms";
import FunderGetStarted from "features/onboarding/funder/funder/FunderGetStarted";
import FunderCompany from "features/onboarding/funder/funder/FunderCompany";
import ToolsAgreement from "features/ToolsAgreement/ToolsAgreement";
import RulesConfig from "features/RulesConfig/RulesConfig";
import AccountManagementPage from "features/wallet/accountManagement";
import RuleConfigScreen from "features/RulesConfig/common";
import MemberSignup from "features/onboarding/Member/MemberSignup";
import MemberTermsAndCondition from "features/onboarding/Member/MemberTerms";
import MemberGetstarted from "features/onboarding/Member/MemberGetStarted";
import FundingSectionPage from "features/invoice/fundingSection";
import PayableFinancingDashboard from "pages/PayableFinancingDashboard";
import PayableFinanceDetails from "features/payableFinancing/PayableFinanceDetails";
import PayableFinanceInvoices from "features/payableFinancing/PayableFinanceInvoices";
// import PayableInvoiceDetails from "features/payableFinancing/invoicedetails";

import Pfpdetails from "features/funderpayablefinance/Pfpdetails";
import FunderPfpInvoiceDetails from "features/funderpayablefinance/FunderPfpInvoiceDetails";
import PfpPendingRequest from "features/funderpayablefinance/pendingrequest";
import OpenMarket from "features/openMarket";
import ViewInvoiceDetails from "features/openMarket/funder/components/ViewInvoiceDetails";
import AcceptedInvoiceDetails from "features/openMarket/supplier/components/AcceptedInvoiceDetails";
import LockedInvoiceDetails from "features/openMarket/supplier/components/LockedInvoiceDetails";
import RequestedInvoiceDetails from "features/openMarket/supplier/components/RequestedInvoiceDetails";
import SingleRequestInvoiceList from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest";
import InvoiceDetails from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/InvoiceDetails";
import SelectBulkTable from "features/invoice/fundingSection/earlyPaymentRequest/bulkrequest";
import DirectFundingSingleRequestInvoiceList from "features/invoice/fundingSection/directFundingRequest/singlerequest";
import DirectFundingInvoiceDetails from "features/invoice/fundingSection/directFundingRequest/singlerequest/InvoiceDetails";
import DirectFundingSelectBulkTable from "features/invoice/fundingSection/directFundingRequest/bulkrequest";
import DirectFundingInvoiceList from "features/invoice/fundingSection/directFundingRequest/bulkrequest/DirectFundingInvoiceList";
import SingleEarlyPaymentDetails from "features/invoice/buyerInvoice/earlyPayment/singleEarlyPayment/Details";
// import NewSingleEarlyPaymentDetails from "features/invoice/buyerInvoice/newEarlyPaymentRequests/newSingleEarlyPayment/Details";
// import NewBulkEarlyPaymentDetails from "features/invoice/buyerInvoice/newEarlyPaymentRequests/newBulkEarlyPayment/Details";
// import BulkEarlyPaymentDetailsInvoice from "features/invoice/buyerInvoice/newEarlyPaymentRequests/newBulkEarlyPayment/Details/invoiceIndex";
import TransactionHistoryDetails from "features/collections/supplier/transactionHistory/Details";
import ReceivablesPreview from "features/collections/supplier/recievables/Details";
// import EarlyPaymentPreview from "features/collections/supplier/earlyPayment/Details";
import DirectFundingPreview from "features/collections/supplier/directFunding/Details";
// import OpenMarketPreview from "features/collections/supplier/openMarket/Details";
import CollectionDashboard from "features/collections";
import PayableFinancePreview from "features/collections/funder/payableFinanceProgram/Details";
import Funders from "features/invoice/fundingSection/directFundingRequest/Funders";
import AcceptedInvoiceFunderDetails from "features/openMarket/funder/components/AcceptedInvoiceFunderDetails";
import ImportInvoice from "features/invoice/fundingSection/importInvoice";
import CreatePfp from "features/payableFinancing/createpfp";
import PaymentExtensionDashboard from "features/paymentExtension";
import ApprovedExtensionPreview from "features/paymentExtension/funder/approvedExtensions/Details";
import BulkApprovedExtensionPreview from "features/paymentExtension/funder/approvedExtensions/Details/bulkpreview";
import BulkInvoice from "features/paymentExtension/funder/approvedExtensions/Details/bulkinvoice";
import SingleNewExtensionRequestDetails from "features/paymentExtension/funder/newExtensionRequests/single.js/Details";
import BulkNewExtensionRequestDetails from "features/paymentExtension/funder/newExtensionRequests/bulk/Details";
import InvoiceBulk from "features/paymentExtension/funder/newExtensionRequests/bulk/Details/invoice";
import SingleUpdated from "features/paymentExtension/buyer/single/updated";
import SingleRejected from "features/paymentExtension/buyer/single/rejected";
import SinglePending from "features/paymentExtension/buyer/single/pending";
import SingleApproved from "features/paymentExtension/buyer/single/approved";
import SingleExtensionInvoiceList from "features/paymentExtension/buyer/single/Modal/Table";
import SingleInvoiceDetails from "features/paymentExtension/buyer/single/InvoiceDetails";
import BulkRejected from "features/paymentExtension/buyer/bulk/rejected/Details";
import BulkPaymentExtensionPreview from "features/paymentExtension/buyer/bulk/invoice/invoiceIndex";
import BulkPending from "features/paymentExtension/buyer/bulk/pending/Details";
import PfpTableDetails from "features/funderpayablefinance/PfpTableDetails";
import ViewFundInvoice from "features/funderpayablefinance/components/ViewFundInvoice";
import BulkApproved from "features/paymentExtension/buyer/bulk/approved/Details";
import BulkExtensionInvoiceList from "features/paymentExtension/buyer/bulk/createBulkRequest/Table";
import SingleDirectFundingDetails from "features/invoice/funder/components/pages/directFunding/directFunding/singleDirectFunding/Details";
import BulkDirectFundingPaymentDetails from "features/invoice/funder/components/pages/directFunding/directFunding/bulkDirectFunding/Details";
import BulkDirectFundingInvoiceDetails from "features/invoice/funder/components/pages/directFunding/directFunding/bulkDirectFunding/Details/BulkDirectFundingInvoiceDetails";
import NewSingleDirectFundingDetails from "features/invoice/funder/components/pages/newdirectfunding/directFundingNew/singleDirectFunding/Details";
import NewBulkDirectFundingPaymentDetails from "features/invoice/funder/components/pages/newdirectfunding/directFundingNew/bulkDirectFunding/Details";
import NewBulkDirectFundingInvoiceDetails from "features/invoice/funder/components/pages/newdirectfunding/directFundingNew/bulkDirectFunding/Details/BulkDirectFundingInvoiceDetails";
import UploadInvoice from "features/invoice/fundingSection/invoiceupload";
import ValidationMaping from "features/invoice/fundingSection/invoiceupload/ValidationMaping";
import InvoiceTable from "features/invoice/fundingSection/invoiceupload/InvoiceTable";
import Invoice from "features/paymentExtension/buyer/bulk/createBulkRequest/bulkInvoice";
import SupplierEarlyPaymentDashboard from "features/supplierEarlyPayment/Dashboard";
import SupplierEarlypaymentDetails from "features/supplierEarlyPayment/details";
import SupplierEarlyPaymentBulkInvoice from "features/supplierEarlyPayment/bulkInvoice";

import ClosedMarketDashBoard from "pages/ClosedMarket";
import MyInvoicesDetails from "features/closedmarket/components/carddetails/MyInvoicesDetails";
// import MyInvoicesDetailsBulk from "features/closedmarket/components/carddetails/MyInvoicesDetailsBulk";
import MyInvoiceDetailsBulk from "features/closedmarket/components/carddetails/details/MyInvoicesDetailsBulk";
import RequestDetails from "features/closedmarket/components/carddetails/RequestsDetails";
// import BuyerTabs from "features/BuyerEarlyPayment/Tabs";
import FunderList from "features/BuyerEarlyPayment/EarlyPayment/FunderList";
import FunderInvoiceDetails from "features/BuyerEarlyPayment/EarlyPayment/singleEarlyPayment/Details/FunderInvoiceDetails";

import TopContestedInvoiceDetail from "features/closedmarket/components/carddetails/TopContestedInvoiceDetail";

import DirectEarlyPaymentRequestsDetailContainer from "features/closedmarket/closedMarketInvoice/DirectEarlyPaymentRequestsDetailContainer";
import DirectEarlyPaymentRequests from "features/closedmarket/closedMarketInvoice/DirectEarlyPaymentRequests";

import ReinbursementDirectFundInvoice from "features/payments/reinbursement/reinbursementDashBoard/supplier/directFundingInvoice/DirectFundingInvoice";
import SingleDirectFundingDetai from "features/payments/reinbursement/reinbursementDashBoard/supplier/directFundingInvoice/Single/Detail";
import DirectFundingPageDetail from "features/payments/reinbursement/reinbursementDashBoard/supplier/directFunding/detail";
import BulkDirectFundingPageDetails from "features/payments/reinbursement/reinbursementDashBoard/supplier/directFundingInvoice/bulk/Details";
import ReimbursementDashboard from "features/Reimbursement/reimbursementDashboard";
import ReimbursementDirectFunding from "features/Reimbursement/buyerReimbursement/directFunding";
import BuyerSingleDetails from "features/Reimbursement/buyerReimbursement/directFunding/single/details";
import BuyerBulkDetails from "features/Reimbursement/buyerReimbursement/directFunding/bulk/details";
import BuyerBulkInvoice from "features/Reimbursement/buyerReimbursement/directFunding/bulk/invoice";
import ReimbursementBuyerEaryPayment from "features/Reimbursement/buyerReimbursement/earlyPayment";
import ReimbursementBuyerOpenMarket from "features/Reimbursement/buyerReimbursement/openMarket";
import ReimbursementBuyerClosedMarket from "features/Reimbursement/buyerReimbursement/closedMarket";
import OpenMarketSelectableDetail from "features/payments/reinbursement/reinbursementDashBoard/supplier/directFundingInvoice/openmarket/OpenMarketSelectableDetail";
import OpenMarketDirectFundingDetai from "features/payments/reinbursement/reinbursementDashBoard/supplier/directFundingInvoice/openmarket/details";
import BulkDirectFundingInnerPageDetails from "features/payments/reinbursement/reinbursementDashBoard/supplier/directFundingInvoice/bulk/Details/InnerDetail";
import DisbursementDashboard from "features/disbursement";

import DisbursementDirectFundingDetails from "features/disbursement/buyer/directFunding/Details";
import DisbursementBulkDirectFundingPageDetails from "features/disbursement/buyer/directFunding/bulk";
import DisbursementBulkDirectFundingInnerPageDetails from "features/disbursement/buyer/directFunding/bulk/index";

// import SettlementBoard from "pages/Settlement";
import SettlementDashboardContents from "features/settlement";
import ViewReconciliationHistory from "features/settlement/buyer/reconciliation/viewHistory/ViewReconciliationHistory";
import ReconciliationDetail from "features/settlement/buyer/reconciliation/Detail";
import AllPayableFinance from "features/payableFinancing";
import FundsAndPortfolioDashboard from "features/fundsPortfolio/dashboard";
import ActiveInvoiceDetails from "features/openMarket/supplier/components/ActiveInvoiceDetails";
import KYC from "features/onboarding/supplier/KYC";
import PortfolioInvoiceDetails from "features/fundsPortfolio/dashboard/invoice";
import FundedInvoice from "features/fundsPortfolio/dashboard/fundedInvoice";
import ConnectedPlayers from "features/fundsPortfolio/connectedPlayers";
import PortfolioManagement from "features/fundsPortfolio/portfolioManagement";
import DisbursementTransactionHistory from "features/disbursement/buyer/transactionHistory";
import FundingAgreement from "features/onboarding/supplier/FundingAgreement";
import CheckLoginComplete from "./CheckLoginComplete";

import BuyerOpenMarketDashBoard from "features/factoring/openMarket";
import FacOpenMarketAllInvoiceDetail from "features/factoring/openMarket/all/details";
import FacOpenMarketFundedDetail from "features/factoring/openMarket/funded/details";
// import FacOpenMarketPendingDetail from "features/factoring/openMarket/pendingfunding/details/FacOpenMarketPendingDetail";
import FacDirectFundingPendingDetail from "features/factoring/directFunding/pendingfunding/details/FacOpenMarketPendingDetail";
import BuyerDirectFundingDashBoard from "features/factoring/directFunding";
import VerifyEmail from "shared/VerifyEmail/VerifyEmail";
import ResendVerificationLink from "shared/VerifyEmail/ResendVerification";
import SupplierEarlyPaymentAcceptedInvoice from "features/supplierEarlyPayment/invoices/invoice";
import SupplierEarlyPaymentRejectedInvoice from "features/supplierEarlyPayment/invoices/rejectedInvoice";
import SupplierEarlyPaymentPendingInvoice from "features/supplierEarlyPayment/invoices/pendingInvoice";
import AllSupplierInvoices from "features/invoice/InvoiceDashboard/Tables/AllSupplierInvoices";
import BuyerInvoiceDetails from "features/invoice/buyerInvoice/invoiceDetails";
import TopContestPage from "features/openMarket/funder/components/TopContestPage";
import FunderPayableFinance from "features/funderpayablefinance";
import FunderClosedMarketDashBoard from "features/payableFinaceForFunder/CloseMarket";
import FunderClosedMarketOverViewDetails from "features/payableFinaceForFunder/CloseMarket/overview/details";
import FunderClosedMarketClosedMarketDetails from "features/payableFinaceForFunder/CloseMarket/closedMarket/details";
import FunderClosedMarketOnGoingBidDetails from "features/payableFinaceForFunder/CloseMarket/ongoingBid/details";
import BukOverViewDetails from "features/payableFinaceForFunder/CloseMarket/overview/details/BulkOverviewDetail";
import BulkClosedMarketDetails from "features/payableFinaceForFunder/CloseMarket/closedMarket/details/BulkClosedMarketDetails";
import OngoingBidBulkDetails from "features/payableFinaceForFunder/CloseMarket/ongoingBid/details/OngoingBidBulkDetails";
import BulkDetailPage from "features/payableFinaceForFunder/CloseMarket/overview/details/BulkDetailPage";
import WalletDashboard from "features/wallet(new)";
import Tier from "features/relationshipManagement/buyer/supplier/tier/tier";
import AssociationRequest from "features/relationshipManagement/buyer/supplier/associationRequest/associationRequest";
import AssociationRequestFunder from "features/relationshipManagement/buyer/funder/associationRequest/associationReequest";
import BuyerFunderPendingRequest from "features/relationshipManagement/buyer/funder/buyerFunderPending/buyerFunderPending";
import AssociateRequestBuyer from "features/relationshipManagement/supplier/buyer/associateRequest/associateRequest";
import SupplierPendingRequestBuyer from "features/relationshipManagement/funder/supplier/pendingRequest";
import AssociateRequest from "features/relationshipManagement/funder/supplier/associateRequest/associateRequest";
import AccountStatementPage from "features/wallet(new)/accountmanagement/Dashboard/AccountStatementPage";
import SupplierPendingRequestFunder from "features/relationshipManagement/supplier/funder/pendingRequest";
import AssociationSupplierFunder from "features/relationshipManagement/supplier/funder/associateFunder";
import PendingAssociation from "features/relationshipManagement/funder/supplier/pendingassociation/pendingassociation";
import PendingInvites from "features/relationshipManagement/funder/supplier/pendinginvites";
import PendingAssociationFunderBuyer from "features/relationshipManagement/funder/buyer/pendingassociation";
import PendingInvitesFunderBuyer from "features/relationshipManagement/funder/buyer/pendinginvites";
import WorkFlowDashBoard from "features/workflowmanagement";
import ApprovalHistoryAllDetails from "features/workflowmanagement/details/ApprovalHistoryAllDetails ";
import WorkFlowPendingApprovalRequestsScreen from "features/workflowmanagement/pendingapprovals";
import PendingApprovalDetailsScreen from "features/workflowmanagement/details/PendingApprovalDetailsScreen";
import WorkFlowDclinedRequestsScreen from "features/workflowmanagement/declinedrequests";
import DeclinedRequestsDetailsScreen from "features/workflowmanagement/details/DeclinedRequestDetails";
import PartialPaymentDetails from "features/invoice/buyerInvoice/invoiceDetails/partialPayment";
import PaidPaymentDetails from "features/invoice/buyerInvoice/invoiceDetails/paid";
import ActivePaymentDetails from "features/invoice/buyerInvoice/invoiceDetails/active";
import PendingWorkflowPaymentDetails from "features/invoice/buyerInvoice/invoiceDetails/pendingWorkflow";
import WorkFlowPendingRequests from "features/workflowmanagement/pendingrequests";
import PendingRequestsDetailsScreen from "features/workflowmanagement/details/PendingRequestDetails";
import PendingDeclinedDetailsScreen from "features/workflowmanagement/details/PendingDeclinedDetailScreen";
import CreatedPaymentDetails from "features/invoice/buyerInvoice/invoiceDetails/created";
import ApprovedPendingApprovalDetailsScreen from "features/workflowmanagement/details/ApprovedAprovalHistoryScreen";
import CompletedPendingApprovalDetailsScreen from "features/workflowmanagement/details/CompletedAprovalHistoryScreen";
import DeclinedPendingApprovalDetailsScreen from "features/workflowmanagement/details/DeclinedAprovalHistoryScreen";
import PendingApprovalDetails from "features/invoice/buyerInvoice/invoiceDetails/pendingApproval";
import SupplierEarlyPayments from "features/SupplierEarlyPayments";
import SupplierEarlyPaymentRequestFlow from "features/SupplierEarlyPayments/supplierearlypaymentrequestflow";
import ViewRoleFullDetails from "features/roleManager/Dashbaord/components/ViewRoleFullDetails";
import InvoiceApprovalScreen from "features/workflowmanagement/details/InvoiceApproval";
import InvoiceUploadScreen from "features/workflowmanagement/details/InvoiceUpload";
import ApprovalViaWalletScreenDetails from "features/workflowmanagement/details/ApprovalViaWalletScreen";
import ApprovalViaWalletScreen from "features/workflowmanagement/details/ApprovalViaWallet";
import ApprovalViaWalletScreenTwoDetails from "features/workflowmanagement/details/ApprovalViaWalletScreenTwoDetails";
import SupplierEarlyPaymentDetails from "features/SupplierEarlyPayments/supplierearlypaymentdetails";
import BulkEarlyPaymentRequestFlow from "features/SupplierEarlyPayments/bulkearlypaymentrequestflows";
import BulkEarlyPaymentInvoiceDetail from "features/SupplierEarlyPayments/bulkearlypaymentinvoicedetails";
import SupplierDirectFundingDetails from "features/invoice/directfunding/singledirectfundingdetails";
import DirectFundingRequestFlow from "features/invoice/directfunding/directfundingrequestflow";
import DirectFundingInvoiceDetailsPage from "features/invoice/directfunding/directfundinginvoicedetails";
import BulkDirectFundingRequestFlow from "features/invoice/directfunding/bulkdirectfundingrequestflow";
import BulkDirectFundingInvoiceDetailPage from "features/invoice/directfunding/bulkdirectfundinginvoicedetails";
import FunderPayableFinanceEarlyPayment from "features/payableFinaceForFunder/earlyPayment";
import ViewAllTransactions from "features/wallet(new)/accountmanagement/transactions/ViewAllTransaction";
import FunderFactoringDirectFunding from "features/funderfactoring";
import SupplierBulkEarlyPaymentDetails from "features/SupplierEarlyPayments/supplierbulkearlypaymentdetails";
import SupplierBulkDirectFundingDetails from "features/invoice/directfunding/singlebulkdirectfundingdetails";
import DirectFundingDetailsScreen from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/DirectFundingDetails";
import FunderDirectFundingDetailsAccepted from "features/invoice/directfunding/funderdirectfundingapproved";
import FunderDirectFundingDetailsRejected from "features/invoice/directfunding/funderdirectfundingrejected";
import EditNewInvoiceIndex from "features/invoice/NewInvoiceDashboard/EditNewInvoiceIndex";
import StaffPersonalDetails from "features/onboarding/staff/StaffPersonalDetails";
import StaffTermsAndConditions from "features/onboarding/staff/StaffTermsAndConditions";
import SupplierEarlyPaymentBulkDetailsScreen from "features/SupplierEarlyPayments/supplierearlypaymentbulkdetails";
import ViewWorkflowAction from "features/settings/components/WorkflowConfig/buyer/components/ViewWorkflowAction";
import EditWorkflow from "features/settings/components/WorkflowConfig/buyer/components/EditWorkflow";
import PFPAgreement from "features/funderpayablefinance/pendingrequest/FundingAgreement";
import PFPPendingDetails from "features/funderpayablefinance/invoices/Invoice";
import PFPRejectedInvoiceDetails from "features/funderpayablefinance/invoices/Rejected";
import FunderEarlyPaymentDetails from "features/SupplierEarlyPayments/FunderEarlyPaymentDetails";
import FunderBulkEarlyPaymentDetails from "features/SupplierEarlyPayments/FunderBulkEarlyPaymentDetails";
import ProcessSingleEarlyPaymentDetails from "features/BuyerEarlyPayment/ProcessingEarlyPayment/singleEarlyPayment/Details";
import FunderDirectFundingDetailsRejectedSingleRequest from "features/invoice/directfunding/funderdirectfundingsinglerequestdetails";
import FunderDirectFundingDetailsAcceptedSingleRequest from "features/invoice/directfunding/funderdirectfundingsinglerequestaccepted";
import FunderDirectFundingDetailsAcceptedBulkRequest from "features/invoice/directfunding/funderdirectfundingbulkrequestaccepted";
import FunderEarlyPaymentDetailsAccepted from "features/payableFinaceForFunder/earlyPayment/pending/details/SingleDetails";
//  EarlyPaymentFundingBulkDetailsAccepted
import EarlyPaymentFundingBulkDetailsAccepted from "features/payableFinaceForFunder/earlyPayment/pending/details/BulkDetails";
// FunderDirectFundingBulkInnerDetailsPage
import FunderEarlyPaymentBulkInnerDetailsPage from "features/payableFinaceForFunder/earlyPayment/pending/details/FunderDirectFundingBulkInnerDetails";
import ApprovedFunderEarlyPaymentDetailsAccepted from "features/payableFinaceForFunder/earlyPayment/approval/details/ApprovedSingleDetails";
import ApprovedEarlyPaymentFundingBulkDetailsAccepted from "features/payableFinaceForFunder/earlyPayment/approval/details/ApprovedBulkDetails";
import ApprovedFunderEarlyPaymentBulkInnerDetailsPage from "features/payableFinaceForFunder/earlyPayment/approval/details/ApprovedFunderApprovedDirectFundingBulkInnerDetails";
import ApprovedSingleEarlyPaymentDetails from "features/BuyerEarlyPayment/EarlyPayment/singleEarlyPayment/Details";
import RejectedleEarlyPaymentDetails from "features/BuyerEarlyPayment/RejectedEarlyPayment/singleEarlyPayment/Details";
import SupplierSingleEarlyPaymentDetailsApproved from "features/SupplierEarlyPayments/SupplierSingleEarlyPaymentDetailsApproved";
import SupplierSingleEarlyPaymentDetailsRejected from "features/SupplierEarlyPayments/SupplierSingleEarlyPaymentDetailsRejected";
import SupplierSingleEarlyPaymentDetailsProcessings from "features/SupplierEarlyPayments/SupplierEarlyPaymentSingleRequestProcessing";
import NewSingleEarlyPaymentDetails from "features/BuyerEarlyPayment/NewEarlyPaymentRequests/newSingleEarlyPayment/Details";
import NewBulkEarlyPaymentDetails from "features/BuyerEarlyPayment/NewEarlyPaymentRequests/newBulkEarlyPayment/Details";
import ProcessingBulkEarlyPaymentDetails from "features/BuyerEarlyPayment/ProcessingEarlyPayment/bulkEarlyPayment/Details";
import BulkEarlyPaymentDetails from "features/BuyerEarlyPayment/EarlyPayment/bulkEarlyPayment/Details";
import OngoingBidInvoiceDetails from "features/openMarket/funder/components/OngoingInvoiceDetails";
import PendingInvoiceDetails from "features/openMarket/supplier/components/PendingInvoiceDetails";
import TopContestedInvoiceDetails from "features/openMarket/funder/components/TopContestedInvoiceDetails";
import SupplierBulkEarlyPaymentDetailsRejected from "features/SupplierEarlyPayments/supplierbulkearlypaymentdetailsrejected";
import SupplierBulkEarlyPaymentDetailsApproved from "features/SupplierEarlyPayments/supplierbulkearlypsymentdetailsapproved";
import DisbursementDirectFundingDetailsPage from "features/disbursement/funder/directFunding/Details";
import CollectionDirectFundingInvoice from "features/collections/supplier/directFunding/Invoice/invoice";
import CollectionBulkDirectFundingInvoice from "features/collections/supplier/directFunding/bulkInvocie/invoice";
import CollectionOpenMarketInvoice from "features/collections/supplier/openMarket/Invoice/invoice";
import CollectionEarlyPaymentInvoice from "features/collections/supplier/earlyPayment/Invoice/invoice";
import CollectionBulkEarlyPaymentInvoice from "features/collections/supplier/earlyPayment/bulkInvocie/invoice";
import EarlyPaymentOffersPreview from "features/collections/supplier/earlyPaymentOffers/Details";
import DisbursementTransactionHistoryDetails from "features/disbursement/funder/transactionHistory/Details";
import CollectionReceivableInvoice from "features/collections/supplier/recievables/Invoice/invoice";
import ReceivableTransactionHistoryDetails from "features/collections/supplier/transactionHistory/receivableHistory";
import ReimbursementWithRecourseDetails from "features/Reimbursement/buyerReimbursement/dashboard/reimbursementwithrecoursedetails";
import SupplierEarlyPaymentRequest from "features/workflowmanagement/details/SupplierEarlyPaymentRequest";
import SupplierSubmitForApproval from "features/workflowmanagement/details/SupplierSubmitForApproval";
import ReimbursementWithRecourseBulk from "features/Reimbursement/buyerReimbursement/dashboard/reimbursementwithrecoursedetailsbulk";
import ReimbursementWithRecourseOpenMarketDetails from "features/Reimbursement/buyerReimbursement/dashboard/reimbursementwithrecourseopenmarketdetails";
import DisbursementTransactionHistoryDetailsBulk from "features/disbursement/funder/transactionHistory/Details/bulk";
import DisbursementDirectFundingDetailsPageBulk from "features/disbursement/funder/directFunding/bulk";
import DisbursementEarlyPaymentDetailsPage from "features/disbursement/funder/earlyPayment/Details/details";
import DisbursementOpenMarketDetailsPage from "features/disbursement/funder/openMarket/Details";
import DisbursementClosedMarketDetailsPage from "features/disbursement/funder/closedMarket/Details";
import DisbursementClosedMarketBulkDetailsPage from "features/disbursement/funder/closedMarket/Details/bulk";
import ReimbursementTransactionHistoryDetailsPage from "features/Reimbursement/buyerReimbursement/dashboard/ReimbursementTransactionHistoryDetails";
import ReimbursementTransactionHistoryOpenMarketDetailsPage from "features/Reimbursement/buyerReimbursement/dashboard/ReimbursementTransactionHistoryOpenMarketDetails";
import DeclinedSupplierInvoiceScreen from "features/workflowmanagement/details/declinedrequests/DeclinedSupplierInvoiceScreen";
import ImportInvoiceOpenMarket from "features/openMarket/supplier/components/ImportInvoiceOpenMarket";
import FacOpenMarketPendingDetail from "features/factoring/openMarket/pendingfunding/Invoice/invoice";
import PostedInvoice from "features/factoring/openMarket/all/PostedInvoice/invoice";
import BidActiveInvoice from "features/factoring/openMarket/all/BidActiveInvoice/invoice";
import FundedOpenMarketInvoice from "features/factoring/openMarket/all/funded/invoice";
// import FacOpenMarketPendingDetail from "features/factoring/openMarket/pendingfunding/Details copy";
import PayableFinanceInvoiceDetails from "features/payableFinancing/PayableFinanceInvoiceDetails";
import ViewMembers from "features/payableFinancing/ViewMembers";
import DetailsForEarlyPaymentRequest from "features/disbursement/buyer/overview/details/DetailsForEarlyPaymentRequest";
import DetailsForEarlyPaymentRequestMain from "features/disbursement/buyer/overview/details/DetailsForEarlyPaymentRequestMain";
import DetailsForEarlyPaymentOffer from "features/disbursement/buyer/overview/details/DetailsForEarlyPaymentOffer";
import BulkDetailsForEarlyPaymentRequest from "features/disbursement/buyer/overview/details/BulkDetailsEarlyPaymentRequest";
import InnerBulkDetailPageForDisbursement from "features/disbursement/buyer/overview/details/InnerBulkDetailsPage";
import DisbursementDirectFundingDetailsPageTwo from "features/disbursement/buyer/directFunding/Details";
//

//DisbursementEarlyPaymentDetailsPage DisbursementEarlyPaymentDetailsPage
import DisbursementDirectFundingDetailsPageBulkBuyer from "features/disbursement/buyer/directFunding/bulk";
import DisbursementEarlyPaymentDetailsPageBuyer from "features/disbursement/buyer/earlyPayment/Details/details";
import PendingRequestsSignPfpdetails from "features/workflowmanagement/details/pendingrequestdetail/PendingRequestSignPfp";
import PendingRequestEarlyPaymentOffer from "features/workflowmanagement/details/pendingrequestdetail/PendingRequestEarlyPaymentOffer";
import PayableDetailsPage from "features/disbursement/buyer/Payables/Details/details";
import TopTenContestedSupplier from "features/openMarket/supplier/components/TopTenContestedSupplier";
import PendingRequestPFPFundOffer from "features/workflowmanagement/details/pendingrequestdetail/PendingRequestPFPFundOffer";
import PendingApprovalSignPfpdetails from "features/workflowmanagement/details/PendingApprovalSignPfp";
import PendingApprovalPFPFundOffer from "features/workflowmanagement/details/PendingApprovalPFPFundOffer";
import DeclinedPendingRequestsSignPfpdetails from "features/workflowmanagement/details/declinedrequests/DeclinedPendingRequestSignPfp";
import DeclinedPendingRequestPFPFundOffer from "features/workflowmanagement/details/declinedrequests/DeclinedPendingRequestPFPFundOffer";
import RejectedBulkEarlyPaymentDetails from "features/BuyerEarlyPayment/RejectedEarlyPayment/bulkEarlyPayment/Details";
import LoanDefaultsDetailsPage from "features/disbursement/buyer/LoanDefaults/Details/details";
import BidsNotInLeadDetails from "features/payableFinaceForFunder/CloseMarket/ongoingBid/details/BidsNotInLeadDetails";
import FunderDirectFundingBulkDetailsAccepted from "features/invoice/directfunding/DirectFundingBulkDetails";
import DirectEarlyPaymentRequestsBulkDetailContainer from "features/closedmarket/closedMarketInvoice/DirectEarlyPaymentRequestsBulkDetailsContainer";
const ApprovalViaDirectFundingScreenDetails = React.lazy(() =>
  import("features/workflowmanagement/details/ApprovalViaDirectFundingScreen")
);
const ApprovalViaDirectFundingScreen = React.lazy(() =>
  import("features/workflowmanagement/details/ApprovalViaDirectFunding2")
);
const ApprovalViaClosedMarketScreenDetails = React.lazy(() =>
  import("features/workflowmanagement/details/ApprovalViaClosedMarketScreen")
);
const ApprovalViaClosedMarketScreen = React.lazy(() =>
  import("features/workflowmanagement/details/ApprovalViaClosedMarket2")
);
const ApprovalViaConfirmationAndOpenMarket = React.lazy(() =>
  import(
    "features/workflowmanagement/details/ApprovalViaConfirmationAndOpenmarket"
  )
);
const ApprovalViaCampagnCreationScreen = React.lazy(() =>
  import("features/workflowmanagement/details/ApprovalViaCampaignCreation")
);
const ApprovalViaDirectFundingAndConfirmationScreenDetails = React.lazy(() =>
  import(
    "features/workflowmanagement/details/ApprovalViaDirectFundingAndConfirmation"
  )
);
//Pending requests details screens
const PendingRequestInvoiceApprovalScreen = React.lazy(() =>
  import(
    "features/workflowmanagement/details/pendingrequestdetail/PendingRequestViaInvoiceApproval"
  )
);

const PendingRequestInvoiceUpload = React.lazy(() =>
  import(
    "features/workflowmanagement/details/pendingrequestdetail/PendingRequestInvoiceUpload"
  )
);
const PendingRequestApprovalViaWalletScreen = React.lazy(() =>
  import(
    "features/workflowmanagement/details/pendingrequestdetail/PendingRequestApprovalViaWalletScreen"
  )
);
const PendingRequestApprovalViaWallet = React.lazy(() =>
  import(
    "features/workflowmanagement/details/pendingrequestdetail/PendingRequestApprovalViaWallet"
  )
);
const PendingRequestApprovalViaWalletScreenTwoDetails = React.lazy(() =>
  import(
    "features/workflowmanagement/details/pendingrequestdetail/PendingRequestApprovalViaWalletScreenTwoDetails"
  )
);
const PendingRequestApprovalViaWalletScreenTwoDetailsSecond = React.lazy(() =>
  import(
    "features/workflowmanagement/details/pendingrequestdetail/PendingRequestApprovalViaWalletScreenTwoDetailsSecond"
  )
);
const PendingRequestsApprovalViaDirectFundingScreenDetails = React.lazy(() =>
  import(
    "features/workflowmanagement/details/pendingrequestdetail/PendingRequestApprovalViaDirectFundingScreen"
  )
);
const PendingRequestApprovalViaDirectFundingScreen = React.lazy(() =>
  import(
    "features/workflowmanagement/details/pendingrequestdetail/PendingRequestApprovalViaDirectFunding2"
  )
);
const PendingRequestApprovalViaClosedMarketScreenDetails = React.lazy(() =>
  import(
    "features/workflowmanagement/details/pendingrequestdetail/PendingRequestApprovalViaClosedMarketScreen"
  )
);
const PendingRequestApprovalViaClosedMarketScreen = React.lazy(() =>
  import(
    "features/workflowmanagement/details/pendingrequestdetail/PendingRequestApprovalViaClosedMarket2"
  )
);
const PendingRequestApprovalViaConfirmationAndOpenMarket = React.lazy(() =>
  import(
    "features/workflowmanagement/details/pendingrequestdetail/PendingRequestApprovalViaConfirmationAndOpenmarket"
  )
);
const PendingRequestApprovalViaCampagnCreationScreen = React.lazy(() =>
  import(
    "features/workflowmanagement/details/pendingrequestdetail/PendingRequestApprovalViaCampaignCreation"
  )
);
const PendingRequestApprovalViaDirectFundingAndConfirmationScreenDetails =
  React.lazy(() =>
    import(
      "features/workflowmanagement/details/pendingrequestdetail/PendingRequestApprovalViaDirectFundingAndConfirmation"
    )
  );

//Declined Request  Screens

//Pending requests details screens
const DeclinedRequestInvoiceApprovalScreen = React.lazy(() =>
  import(
    "features/workflowmanagement/details/declinedrequests/DeclinedRequestViaInvoiceApproval"
  )
);

const DeclinedRequestInvoiceUploadScreen = React.lazy(() =>
  import(
    "features/workflowmanagement/details/declinedrequests/DeclinedRequestInvoiceUpload"
  )
);
const DeclinedRequestApprovalViaWalletScreen = React.lazy(() =>
  import(
    "features/workflowmanagement/details/declinedrequests/DeclinedRequestApprovalViaWalletScreen"
  )
);
const DeclinedRequestApprovalViaWallet = React.lazy(() =>
  import(
    "features/workflowmanagement/details/declinedrequests/DeclinedRequestApprovalViaWallet"
  )
);
const DeclinedRequestApprovalViaWalletScreenTwoDetails = React.lazy(() =>
  import(
    "features/workflowmanagement/details/declinedrequests/DeclinedRequestApprovalViaWalletScreenTwoDetails"
  )
);
const DeclinedRequestsApprovalViaDirectFundingScreenDetails = React.lazy(() =>
  import(
    "features/workflowmanagement/details/declinedrequests/DeclinedRequestApprovalViaDirectFundingScreen"
  )
);
const DeclinedRequestApprovalViaDirectFundingScreen = React.lazy(() =>
  import(
    "features/workflowmanagement/details/declinedrequests/DeclinedRequestApprovalViaDirectFunding2"
  )
);
const DeclinedRequestApprovalViaClosedMarketScreenDetails = React.lazy(() =>
  import(
    "features/workflowmanagement/details/declinedrequests/DeclinedRequestApprovalViaClosedMarketScreen"
  )
);
const DeclinedRequestApprovalViaClosedMarketScreen = React.lazy(() =>
  import(
    "features/workflowmanagement/details/declinedrequests/DeclinedRequestApprovalViaClosedMarket2"
  )
);
const DeclinedRequestApprovalViaConfirmationAndOpenMarket = React.lazy(() =>
  import(
    "features/workflowmanagement/details/declinedrequests/DeclinedRequestApprovalViaConfirmationAndOpenmarket"
  )
);
const DeclinedRequestApprovalViaCampagnCreationScreen = React.lazy(() =>
  import(
    "features/workflowmanagement/details/declinedrequests/DeclinedRequestApprovalViaCampaignCreation"
  )
);
const DeclinedRequestApprovalViaDirectFundingAndConfirmationScreenDetails =
  React.lazy(() =>
    import(
      "features/workflowmanagement/details/declinedrequests/DeclinedRequestApprovalViaDirectFundingAndConfirmation"
    )
  );
const FunderClosedMarketPostedDetails = React.lazy(() =>
  import(
    "features/payableFinaceForFunder/CloseMarket/closedMarket/details/FunderClosedMarketPostedDetails"
  )
);

const BulkClosedMarketPostedDetails = React.lazy(() =>
  import(
    "features/payableFinaceForFunder/CloseMarket/closedMarket/details/BulkClosedMarketPostedDetails"
  )
);
const FunderDirectFundingBulkInnerDetailsPage = React.lazy(() =>
  import("features/invoice/directfunding/FunderDirectFundingBulkInnerDetails")
);
// const FunderDirectFundingBulkDetailsAccepted = React.lazy(() =>
//   import("features/invoice/directfunding/DirectFundingBulkDetails")
// );
export const privateRoutes = [
  {
    path: "buyer-association-funder-request",
    element: <AssociationRequestFunder />,
  },
  {
    path: "/reimbursement-with-recourse-details-single",
    element: <ReimbursementWithRecourseDetails />,
  },
  // {
  //   path: "/reimbursement-with-recourse-details-bulk",
  //   element: <ReimbursementWithRecourseBulkDetails  />,
  // },
  {
    path: "/reimbursement-with-recourse-details-bulk",
    element: <ReimbursementWithRecourseBulk />,
  },
  {
    path: "/reimbursement-with-recourse-details-open-market",
    element: <ReimbursementWithRecourseOpenMarketDetails />,
  },
  {
    path: "/single-early-payment-invoice-details/:id",
    element: <InvoiceDetails />,
  },
  {
    path: "/single-early-payment-details-pending-approval/:id",
    element: <SupplierEarlyPaymentDetails />,
  },
  {
    path: "/single-early-payment-details-approval/:id",
    element: <SupplierSingleEarlyPaymentDetailsApproved />,
  },
  {
    path: "/single-early-payment-details-processing/:id",
    element: <SupplierSingleEarlyPaymentDetailsProcessings />,
  },
  //directfunding
  {
    path: "/single-direct-funding-details-pending-confirmation/:id",
    element: <SupplierDirectFundingDetails />,
  },
  {
    path: "/single-direct-funding-details-pending-approval/:id",
    element: <SupplierDirectFundingDetails />,
  },
  {
    path: "/single-direct-funding-details-approval/:id",
    element: <SupplierDirectFundingDetails />,
  },
  {
    path: "/single-direct-funding-details-processing/:id",
    element: <SupplierDirectFundingDetails />,
  },
  {
    path: "/single-direct-funding-details-rejected/:id",
    element: <SupplierDirectFundingDetails />,
  },
  //bulk direct funding
  {
    path: "/single-bulk-direct-funding-details-pending-confirmation/:id",
    element: <SupplierBulkDirectFundingDetails />,
  },
  {
    path: "/single-bulk-direct-funding-details-pending-approval/:id",
    element: <SupplierBulkDirectFundingDetails />,
  },
  {
    path: "/single-bulk-direct-funding-details-approval/:id",
    element: <SupplierBulkDirectFundingDetails />,
  },
  {
    path: "/single-bulk-direct-funding-details-processing/:id",
    element: <SupplierBulkDirectFundingDetails />,
  },
  {
    path: "/single-bulk-direct-funding-details-rejected/:id",
    element: <SupplierBulkDirectFundingDetails />,
  },
  //end
  {
    path: "/reimbursement-transaction-history/:id",
    element: <ReimbursementTransactionHistoryDetailsPage />,
  },
  {
    path: "/reimbursement-transaction-history-open-market/:id",
    element: <ReimbursementTransactionHistoryOpenMarketDetailsPage />,
  },
  {
    path: "/single-early-payment-details-rejected/:id",
    element: <SupplierSingleEarlyPaymentDetailsRejected />,
  },
  {
    path: "/funder-single-early-payment-details-rejected",
    element: <FunderEarlyPaymentDetails />,
  },
  {
    path: "/funder-bulk-early-payment-details-rejected",
    element: <FunderBulkEarlyPaymentDetails />,
  },
  {
    path: "/single-bulk-early-payment-details-pending-approval/:id",
    element: <SupplierBulkEarlyPaymentDetails />,
  },
  {
    path: "/single-bulk-early-payment-details-approval/:id",
    element: <SupplierBulkEarlyPaymentDetailsApproved />,
  },
  {
    path: "/single-bulk-early-payment-details-processing/:id",
    element: <SupplierSingleEarlyPaymentDetailsProcessings />,
  },
  {
    path: "/single-bulk-early-payment-details-rejected/:id",
    element: <SupplierBulkEarlyPaymentDetailsRejected />,
  },
  {
    path: "/single-direct-funding-details",
    element: <SupplierDirectFundingDetails />,
  },

  // FunderEarlyPaymentDetailsAccepted
  {
    path: "/funder-direct-funding-details-accepted/:id",
    element: <FunderDirectFundingDetailsAccepted />,
  },

  {
    path: "/funder-direct-funding-bulk-details-accepted/:id",
    element: <FunderDirectFundingBulkDetailsAccepted />,
  },
  {
    path: "/funder-direct-funding-bulk-inner-details-page/:id",
    element: (
      <Suspense fallback="loading...">
        <FunderDirectFundingBulkInnerDetailsPage />
      </Suspense>
    ),
  },

  {
    path: "/funder-direct-funding-details-rejected",
    element: <FunderDirectFundingDetailsRejected />,
  },
  {
    path: "/funder-direct-funding-details-single-request-rejected",
    element: <FunderDirectFundingDetailsRejectedSingleRequest />,
  },
  {
    path: "/funder-direct-funding-details-single-request-accepted",
    element: <FunderDirectFundingDetailsAcceptedSingleRequest />,
  },
  {
    path: "/funder-direct-funding-details-bulk-request-accepted",
    element: <FunderDirectFundingDetailsAcceptedBulkRequest />,
  },
  {
    path: "/edit-invoice",
    element: <EditNewInvoiceIndex />,
  },
  {
    path: "/single-bulk-direct-funding-details",
    element: <SupplierBulkDirectFundingDetails />,
  },
  {
    path: "/bulk-early-payment-request-flow",
    element: <BulkEarlyPaymentRequestFlow />,
  },
  {
    path: "/supplier bulk-early-payment-details-details",
    element: <SupplierEarlyPaymentBulkDetailsScreen />,
  },
  {
    path: "/bulk-direct-funding-request-flow",
    element: <BulkDirectFundingRequestFlow />,
  },
  {
    path: "/bulk-early-payment-invoice-details",
    element: <BulkEarlyPaymentInvoiceDetail />,
  },
  {
    path: "/bulk-direct-funding-invoice-details",
    element: <BulkDirectFundingInvoiceDetailPage />,
  },
  {
    path: "/direct-funding-invoice-details",
    element: <DirectFundingInvoiceDetailsPage />,
  },
  {
    path: "/directfunding-invoice-details/:id",
    element: <DirectFundingDetailsScreen />,
  },
  {
    path: "/dashboard",
    element: (
      <CheckLoginComplete>
        <Dashboard />
      </CheckLoginComplete>
    ),
  },
  {
    path: "/notifications",
    element: <NotificationPage />,
  },
  {
    path: "/alltiers",
    element: <Tier />,
  },
  {
    path: "purchase-order/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <PurchaseOrder />,
      },
      {
        path: "createpo",
        element: <CreatePO />,
      },
      {
        path: "createpo/selectproducts",
        element: <SelectProducts />,
      },
    ],
  },
  {
    path: "accounts/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <SupplierBuyersPage />,
      },
      {
        path: "accountdetails",
        element: <Details />,
      },
      {
        path: "associate",
        element: <Associate />,
      },
      {
        path: "supplier-pending-buyer-request",
        element: <SupplierPendingRequestBuyer />,
      },
      {
        path: "supplier-associate-buyer-request",
        element: <AssociateRequest />,
      },
    ],
  },
  {
    path: "supplier-funders/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <SupplierFundersPage />,
      },
      {
        path: "funderdetails",
        element: <FunderDetails />,
      },
      {
        path: "associate-funders",
        element: <AssociateFunders />,
      },
      {
        path: "supplier-pending-funder-request",
        element: <SupplierPendingRequestFunder />,
      },
      {
        path: "supplier-association-funder-request",
        element: <AssociationSupplierFunder />,
      },
    ],
  },

  {
    path: "funder-buyers/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <FunderBuyersPage />,
      },
      {
        path: "buyerdetails",
        element: <BuyerDetails />,
      },
      {
        path: "funder-associate-buyer",
        element: <FunderAssociateBuyers />,
      },
      {
        path: "funder-pending-buyer-request",
        element: <FunderBuyerPendingRequest />,
      },
      {
        path: "funder-association-buyer-request",
        element: <AssociateRequestBuyer />,
      },
      {
        path: "funder-pending-association-buyer",
        element: <PendingAssociationFunderBuyer />,
      },
      {
        path: "funder-pending-invites-buyer",
        element: <PendingInvitesFunderBuyer />,
      },
    ],
  },
  {
    path: "funder-suppliers/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <FunderSuppliersPage />,
      },
      {
        path: "supplierdetails",
        element: <SupplierDetails />,
      },
      {
        path: "funder-associate-supplier",
        element: <FunderAssociateSuppliers />,
      },
      {
        path: "funder-pending-request-supplier",
        element: <PendingAssociation />,
      },
      {
        path: "funder-pending-invites-supplier",
        element: <PendingInvites />,
      },
    ],
  },
  {
    path: "buyer-suppliers/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <BuyerSuppliersPage />,
      },
      {
        path: "supplierdetails",
        element: <BuyerSupplierDetails />,
      },

      {
        path: "buyer-pending-supplier-request",
        element: <BuyerSuppliersPendingRequest />,
      },

      {
        path: "buyer-association-supplier-request",
        element: <AssociationRequest />,
      },
    ],
  },

  //buyer-funders/buyer-pending-funder-request

  {
    path: "buyer-funders/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <BuyerFunderPage />,
      },
      {
        path: "funderdetails",
        element: <BuyerFunderDetails />,
      },
      {
        path: "buyer-pending-funder-request",
        element: <BuyerFunderPendingRequest />,
      },
      {
        path: "buyer-associate-funders",
        element: <BuyerAssociateFunders />,
      },
      {
        path: "buyer-association-funder-request",
        element: <AssociationRequestFunder />,
      },
    ],
  },
  {
    path: "invoice/*",
    element: <Outlet />,
    children: [
      {
        path: "invoice",
        element: <InvoiceDashboard />,
      },
      {
        path: "all-invoices",
        element: <AllInvoiceContents />,
      },
      {
        path: "all-invoice",
        element: <AllSupplierInvoices />,
      },
      {
        path: "invoice/approve/:id",
        element: <BuyerInvoiceDetails />,
      },
      {
        path: "invoice/partial-payment/:id",
        element: <PartialPaymentDetails />,
      },
      {
        path: "invoice/paid/:id",
        element: <PaidPaymentDetails />,
      },
      {
        path: "invoice/active/:id",
        element: <ActivePaymentDetails />,
      },
      {
        path: "invoice/pending-workflow/:id",
        element: <PendingWorkflowPaymentDetails />,
      },
      {
        path: "invoice/created/:id",
        element: <CreatedPaymentDetails />,
      },
      {
        path: "invoice/pending-approval/:id",
        element: <PendingApprovalDetails />,
      },
      {
        path: "supplier-invoice-details/:id",
        element: <BuyerInvoiceDetails />,
      },
      {
        path: "invoice/new-invoice",
        element: <NewInvoiceDashboardContents />,
      },

      {
        path: "edit-invoice/:invoiceId",
        element: <NewInvoiceDashboardContents />,
      },
      {
        path: ":id",
        element: <SingleInvoiceContents />,
      },
      {
        path: "invoice/fundingSection",
        element: <FundingSectionPage />,
      },
      {
        path: "invoice/upload-invoice",
        element: <UploadInvoice />,
      },
      // {
      //   path: "invoice/verify-upload-invoice",
      //   element: <UploadPage />,
      // },
      {
        path: "fundingSection/singlerequestinvoicelist",
        element: <SingleRequestInvoiceList />,
      },
      {
        path: "fundingSection/singlerequestinvoicelist/invoicedetails",
        element: <InvoiceDetails />,
      },
      {
        path: "fundingSection/bulkrequestinvoicelist",
        element: <SelectBulkTable />,
      },
      {
        path: "fundingSection/directsinglerequestFundersLists",
        element: <Funders />,
      },
      {
        path: "fundingSection/directsinglerequestinvoicelist",
        element: <DirectFundingSingleRequestInvoiceList />,
      },
      {
        path: "fundingSection/directsinglerequestinvoicelist/directinvoicedetails",
        element: <DirectFundingInvoiceDetails />,
      },
      {
        path: "fundingSection/directbulkinvoicelist",
        element: <DirectFundingInvoiceList />,
      },
      {
        path: "fundingSection/directfundingbulkrequest/directfundingbulklist",
        element: <DirectFundingSelectBulkTable />,
      },
      {
        path: "invoice/singleearlypaymentdetails/approved",
        element: <SingleEarlyPaymentDetails />,
      },
      {
        path: "invoice/bulkearlypaymentdetails/approved",
        element: <BulkEarlyPaymentDetails />,
      },
      // {
      //   path: "invoice/bulkearlypaymentdetails/new",
      //   element: <NewBulkEarlyPaymentDetails />,
      // },
      // {
      //   path: "invoice/bulkearlypaymentdetails/new/invoice",
      //   element: <BulkEarlyPaymentDetailsInvoice />,
      // },
      {
        path: "/invoice/singledirectfunding/approved",
        element: <SingleDirectFundingDetails />,
      },
      {
        path: "/invoice/bulkdirectfundingypaymentdetails/approved",
        element: <BulkDirectFundingPaymentDetails />,
      },
      {
        path: "/invoice/bulkdirectfundingypaymeninvoicetdetails/approved",
        element: <BulkDirectFundingInvoiceDetails />,
      },
      {
        path: "/invoice/newsingledirectfunding/approved",
        element: <NewSingleDirectFundingDetails />,
      },
      {
        path: "/invoice/newbulkdirectfundingypaymentdetails/approved",
        element: <NewBulkDirectFundingPaymentDetails />,
      },
      {
        path: "/invoice/newbulkdirectfundingypaymeninvoicetdetails/approved",
        element: <NewBulkDirectFundingInvoiceDetails />,
      },
      {
        path: "/invoice/fundingSection/invoiceupload",
        element: <UploadInvoice />,
      },
      {
        path: "/invoice/fundingSection/invoiceupload/validation",
        element: <ValidationMaping />,
      },
      {
        path: "/invoice/fundingSection/invoiceupload/validation/invoice-table",
        element: <InvoiceTable />,
      },
      {
        path: "invoice/fundingSection/invoice-import",
        element: <ImportInvoice />,
      },
    ],
  },

  {
    path: "/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <FunderPayableFinanceEarlyPayment />,
      },
    ],
  },

  //Buyer Payable Finance Program
  {
    path: "payablefinancing/*",
    element: <Outlet />,
    children: [
      {
        path: "/overview",
        element: <PayableFinancingDashboard />,
        // element: <BuyerTabs />,
      },
      {
        path: "payablefinace/singleearlypaymentdetails/approved",
        element: <SingleEarlyPaymentDetails />,
      },
      {
        path: "payablefinanceProgram",
        element: <AllPayableFinance />,
      },
      {
        path: "payablefinance",
        element: <AllPayableFinance />,
      },
      {
        path: "singleearlypaymentdetails/approved",
        element: <SingleEarlyPaymentDetails />,
      },

      // {
      //   path: "singleearlypaymentdetails/new",
      //   element: <NewSingleEarlyPaymentDetails />,
      // },
      // {
      //   path: "bulkearlypaymentdetails/new",
      //   element: <NewBulkEarlyPaymentDetails />,
      // },
      // {
      //   path: "bulkearlypaymentdetails/new/invoice",
      //   element: <BulkEarlyPaymentDetailsInvoice />,
      // },
      {
        path: "payablefinanceProgram/payablefinancedetails",
        element: <PayableFinanceDetails />,
      },

      {
        path: "payablefinancedetail/createpayablefinance/payablefinanceDetails/viewmembers",
        element: <ViewMembers />,
      },
      // {
      //   path: "payablefinanceProgram/payablefinanceinvoice",
      //   element: <PayableFinanceInvoiceDetails />,
      // },

      {
        path: "payablefinancedetail/createpayablefinance",
        element: <CreatePfp />,
      },
      {
        path: "payablefinancedetails/payablefinanceinvoice",
        element: <PayableFinanceInvoices />,
      },
      {
        path: "payablefinancedetails/payablefinanceinvoice/payableinvoicedetails",
        element: <PayableFinanceInvoiceDetails />,
        // element: <PayableInvoiceDetails />,
      },
      {
        path: "payablefinace/singleearlypaymentdetails/approved/directfuning_funderlist",
        element: <FunderList />,
      },
      {
        path: "payablefinace/fundingSection/directsinglerequestinvoicelist/directinvoicedetails",
        element: <FunderInvoiceDetails />,
      },
      {
        path: "invoice/singleearlypaymentdetails/new",
        element: <NewSingleEarlyPaymentDetails />,
      },
      {
        path: "invoice/singleearlypaymentdetails/processing",
        element: <ProcessSingleEarlyPaymentDetails />,
      },
      {
        path: "invoice/singleearlypaymentdetails/approved",
        element: <ApprovedSingleEarlyPaymentDetails />,
      },
      {
        path: "invoice/singleearlypaymentdetails/rejected",
        element: <RejectedleEarlyPaymentDetails />,
      },
      {
        path: "invoice/bulkearlypaymentdetails/new",
        element: <NewBulkEarlyPaymentDetails />,
      },
      {
        path: "invoice/bulkearlypaymentdetails/processing",
        element: <ProcessingBulkEarlyPaymentDetails />,
      },
      {
        path: "invoice/bulkearlypaymentdetails/approved",
        element: <BulkEarlyPaymentDetails />,
      },
      {
        path: "invoice/bulkearlypaymentdetails/rejected",
        element: <RejectedBulkEarlyPaymentDetails />,
      },
    ],
  },

  {
    path: "directfunding/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <FunderFactoringDirectFunding />,
      },
    ],
  },

  // started here

  {
    path: "factoring/*",
    element: <Outlet />,
    children: [
      {
        path: "/openmarket",
        element: <BuyerOpenMarketDashBoard />,
      },
      {
        path: "/directfunding",
        element: <BuyerDirectFundingDashBoard />,
      },
      {
        path: "/posted/:id",
        element: <PostedInvoice />,
      },
      {
        path: "/bid-active/:id",
        element: <BidActiveInvoice />,
      },
      {
        path: "/funded/:id",
        element: <FundedOpenMarketInvoice />,
      },
      { path: "/directfunding", element: <BuyerDirectFundingDashBoard /> },
      {
        path: "/facopenmarketallinvoicedetail/:id",
        element: <FacOpenMarketAllInvoiceDetail />,
      },
      // FacDirectFundingPendingDetail
      {
        path: "/facopenmarketpendingdetail/:id",
        element: <FacOpenMarketPendingDetail />,
      },

      {
        path: "/facdirectfundingpendingdetail/:id",
        element: <FacDirectFundingPendingDetail />,
      },

      {
        path: "/facopenmarketfundeddetail/:id",
        element: <FacOpenMarketFundedDetail />,
      },
    ],
  },

  //mine ends here

  {
    path: "settlement/*",
    element: <Outlet />,
    children: [
      { path: "/", element: <SettlementDashboardContents /> },
      {
        path: "/detail/viewreconciliation",
        element: <ViewReconciliationHistory />,
      },
      {
        path: "/detail/reconciliationdetail/:id",
        element: <ReconciliationDetail />,
      },
    ],
  },
  {
    path: "closedmarket/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <ClosedMarketDashBoard />,
      },

      {
        path: "myinvoicesdetails/:id",
        element: <MyInvoicesDetails />,
      },
      {
        path: "myinvoicesdetails-bulk/:id",
        element: <MyInvoiceDetailsBulk />,
      },
      {
        path: "requestsdetails/",
        element: <RequestDetails />,
      },
      {
        path: "topContestedInvoices/",
        element: <TopContestedInvoiceDetail />,
      },

      {
        path: "directEarlyPaymentRequests/",
        element: <DirectEarlyPaymentRequests />,
      },
      {
        path: "directEarlyPaymentRequestsDetail/:id",
        element: <DirectEarlyPaymentRequestsDetailContainer />,
      },

      {
        path: "directEarlyPaymentRequestsDetail/bulk/:id",
        element: <DirectEarlyPaymentRequestsBulkDetailContainer />,
      },
    ],
  },

  {
    path: "reinbursement/*",
    element: <Outlet />,
    children: [
      { path: "/", element: <ReimbursementDashboard /> },
      {
        path: "/reinbursementdirectfundinvoice",
        element: <ReinbursementDirectFundInvoice />,
      },
      {
        path: "/singledirectfundingdetail",
        element: <SingleDirectFundingDetai />,
      },
      {
        path: "/detail/directfundingpageDetail",
        element: <DirectFundingPageDetail />,
      },
      {
        path: "/detail/bulkdirectfundingpagedetails/:id",
        element: <BulkDirectFundingPageDetails />,
      },
      {
        path: "/detail/bulkdirectfundinginnerpagedetails/:id",
        element: <BulkDirectFundingInnerPageDetails />,
      },
      {
        path: "/detail/openmarketselectabledetail",
        element: <OpenMarketSelectableDetail />,
      },
      {
        path: "/detail/openmarketdirectfundingdetai/:id",
        element: <OpenMarketDirectFundingDetai />,
      },
    ],
  },

  //Funder Payable Finace Program

  //Funder Payable Finace Program
  {
    path: "payablefinancing-campaign*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <FunderPayableFinance />,
      },
      {
        path: "funder-payablefinance-details",
        element: <Pfpdetails />,
      },
      {
        path: "funder-payablefinance-view-details",
        element: <PfpTableDetails />,
      },
      {
        path: "funderpayablefinanceviewdetails/:id",
        element: <ViewFundInvoice />,
      },
      {
        path: "funderpayablefinancedetails/funderpayablefinanceinvoice",
        element: <FunderPfpInvoiceDetails />,
      },
      {
        path: "pfppendingrequest",
        element: <PfpPendingRequest />,
      },
      {
        path: "pfpagreement/:id",
        element: <PFPAgreement />,
      },
      {
        path: "invoice/:id",
        element: <PFPPendingDetails />,
      },
      {
        path: "rejected/:id",
        element: <PFPRejectedInvoiceDetails />,
      },
    ],
  },

  // payablefinance-earlypayment/funder-early-payment-bulk-inner-details-page/:id

  {
    path: "payablefinance-earlypayment/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <FunderPayableFinanceEarlyPayment />,
      },

      {
        path: "/funder-early-payment-details-accepted",
        element: <FunderEarlyPaymentDetailsAccepted />,
      },
      {
        path: "/funder-early-payment-funding-bulk-details-accepted",
        element: <EarlyPaymentFundingBulkDetailsAccepted />,
      },
      {
        path: "/funder-early-payment-bulk-inner-details-page/:id",
        element: <FunderEarlyPaymentBulkInnerDetailsPage />,
      },
      {
        path: "/approved-funder-early-payment-bulk-inner-details-page/:id",
        element: <ApprovedFunderEarlyPaymentBulkInnerDetailsPage />,
      },
      {
        path: "/approved-funder-early-payment-details-accepted",
        element: <ApprovedFunderEarlyPaymentDetailsAccepted />,
      },

      {
        path: "/approved-funder-early-payment-funding-bulk-details-accepted",
        element: <ApprovedEarlyPaymentFundingBulkDetailsAccepted />,
      },
    ],
  },
  //afunder payablefinance for closedmarket  ApprovedFunderEarlyPaymentDetailsAccepted ApprovedFunderEarlyPaymentBulkInnerDetailsPage

  //payablefinancing-closedmarket/funderclosedmarketoverviewdetails/

  {
    path: "payablefinancing-closedmarket/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <FunderClosedMarketDashBoard />,
      },
      {
        path: "funderclosedmarketoverviewdetails/:id",
        element: <FunderClosedMarketOverViewDetails />,
      },
      {
        path: "funderclosemarketdetails/:id",
        element: <FunderClosedMarketClosedMarketDetails />,
      },
      {
        path: "funderclosemarketposteddetails/:id",
        element: (
          <Suspense fallback="loading..">
            <FunderClosedMarketPostedDetails />
          </Suspense>
        ),
      },
      {
        path: "bulkclosedmarketposteddetails/:id",
        element: (
          <Suspense fallback="loading..">
            <BulkClosedMarketPostedDetails />
          </Suspense>
        ),
      },
      {
        path: "funderclosemarketbulkdetails/:id",
        element: <BulkClosedMarketDetails />,
      },
      {
        path: "funderclosedmarketongoingbiddetails/:id",
        element: <FunderClosedMarketOnGoingBidDetails />,
      },

      {
        path: "BidsNotInLeadDetails/:id",
        element: <BidsNotInLeadDetails />,
      },
      {
        path: "funderclosedmarketongoingbidbulkdetails/:id",
        element: <OngoingBidBulkDetails />,
      },
      {
        path: "funderclosedmarketbulkoverviewdetails/:id",
        element: <BukOverViewDetails />,
      },
      {
        path: "funderclosedmarketbuldetailspage/:id",
        element: <BulkDetailPage />,
      },
    ],
  },
  {
    path: "inventory/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <Inventory />,
      },
      {
        path: "createproduct",
        element: <Createproduct />,
      },
      {
        path: "product/:id",
        element: <Product />,
      },
    ],
  },
  {
    path: "wallet/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <WalletDashboard />,
      },
      {
        path: "transactions",
        element: <TransactionPage />,
      },
      {
        path: "transactions/:accountId",
        element: <ViewAllTransactions />,
      },
      {
        path: "accountManagement",
        element: <AccountManagementPage />,
      },
      {
        path: "accountManagement/:accountId",
        element: <AccountStatementPage />,
      },
    ],
  },

  //DISBURSEMENT ROUTES
  {
    path: "disbursement/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <DisbursementDashboard />,
      },
      {
        path: "transactionhistory/disbursementtransactionhistory",
        element: <DisbursementTransactionHistory />,
      },

      {
        path: "funder/disbursement/directfunding/:id",
        element: <DisbursementDirectFundingDetailsPage />,
      },
      {
        path: "funder/disbursement/directfundingtwo/:id",
        element: <DisbursementDirectFundingDetailsPageTwo />,
      },
      {
        path: "funder/disbursement/PayableDetailsPage/:id",
        element: <PayableDetailsPage />,
      },

      {
        path: "funder/disbursement/directfunding/bulk/:id",
        element: <DisbursementDirectFundingDetailsPageBulk />,
      },

      {
        path: "funder/disbursement/directfunding/bulkbuyer/:id",
        element: <DisbursementDirectFundingDetailsPageBulkBuyer />,
      },

      {
        path: "funder/disbursement/earlyPayment/:id",
        element: <DisbursementEarlyPaymentDetailsPage />,
      },
      {
        path: "funder/disbursement/earlyPaymentbuyer/:id",
        element: <DisbursementEarlyPaymentDetailsPageBuyer />,
      },

      {
        path: "funder/disbursement/openMarket/:id",
        element: <DisbursementOpenMarketDetailsPage />,
      },
      {
        path: "funder/disbursement/closedMarket/:id",
        element: <DisbursementClosedMarketDetailsPage />,
      },
      {
        path: "funder/disbursement/closedMarket/bulk/:id",
        element: <DisbursementClosedMarketBulkDetailsPage />,
      },

      {
        path: "funder/disbursement/transaction-details/bulk/:id",
        element: <DisbursementTransactionHistoryDetailsBulk />,
      },
      // DetailsForEarlyPaymentRequestMain
      // funder/disbursement/detailsforEarlyPaymentRequestMain/:id
      //funder/disbursement/detailsforEarlyPaymentOffer/:id

      {
        path: "funder/disbursement/detailsforEarlyPaymentRequest/:id",
        element: <DetailsForEarlyPaymentRequest />,
      },
      {
        path: "funder/disbursement/bulkdetailsforEarlyPaymentRequest/:id",
        element: <BulkDetailsForEarlyPaymentRequest />,
      },
      {
        path: "funder/disbursement/bulkdetailsforEarlyPaymentRequest/:id",
        element: <BulkDetailsForEarlyPaymentRequest />,
      },
      {
        path: "funder/disbursement/innerbulkdetailpagefordisbursement/:id",
        element: <InnerBulkDetailPageForDisbursement />,
      },
      {
        path: "funder/disbursement/detailsforEarlyPaymentRequestMain/:id",
        element: <DetailsForEarlyPaymentRequestMain />,
      },
      {
        path: "funder/disbursement/detailsforEarlyPaymentOffer/:id",
        element: <DetailsForEarlyPaymentOffer />,
      },
      {
        path: "funder/disbursement/transaction-details/:id",
        element: <DisbursementTransactionHistoryDetails />,
      },

      {
        path: "buyer/disbursement/transaction-details/:id",
        element: <DisbursementTransactionHistoryDetails />,
      },
      {
        path: "buyer/disbursement/loan-defaults-details-page/:invoiceId",
        element: <LoanDefaultsDetailsPage />,
      },

      {
        path: "detail/disbursementdirectfundingdetails/:id",
        element: <DisbursementDirectFundingDetails />,
      },

      {
        path: "detail/disbursementbulkdirectfundingpagedetails/:id",
        element: <DisbursementBulkDirectFundingPageDetails />,
      },
      {
        path: "detail/disbursementbulkdirectfundinginnerpagedetails/:id",
        element: <DisbursementBulkDirectFundingInnerPageDetails />,
      },
    ],
  },

  {
    path: "collections/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <CollectionDashboard />,
      },
      {
        path: "invoice/:id",
        element: <DirectFundingPreview />,
      },
      {
        path: "transactionhistory/:id",
        element: <TransactionHistoryDetails />,
      },
      {
        path: "receivables-transaction-history/:id",
        element: <ReceivableTransactionHistoryDetails />,
      },
      {
        path: "recievables/:id",
        element: <ReceivablesPreview />,
      },
      {
        path: "partial-recievables/:id",
        element: <CollectionReceivableInvoice />,
      },
      {
        path: "earlypayment/:id",
        element: <CollectionEarlyPaymentInvoice />,
      },
      {
        path: "bulk-earlypayment/:id",
        element: <CollectionBulkEarlyPaymentInvoice />,
      },
      {
        path: "directfunding/:id",
        element: <CollectionDirectFundingInvoice />,
      },
      {
        path: "bulk-directfunding/:id",
        element: <CollectionBulkDirectFundingInvoice />,
      },
      {
        path: "open-market/:id",
        element: <CollectionOpenMarketInvoice />,
      },
      {
        path: "early-payment-offer/:id",
        element: <EarlyPaymentOffersPreview />,
      },
      {
        path: "payablefinanceprogram/:id",
        element: <PayableFinancePreview />,
      },
    ],
  },

  {
    path: "funds&portfolio/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <FundsAndPortfolioDashboard />,
      },
      {
        path: "/portfolioinvoice/:id",
        element: <PortfolioInvoiceDetails />,
      },
      {
        path: "/fundedinvoice",
        element: <FundedInvoice />,
      },
      {
        path: "/connectedplayers",
        element: <ConnectedPlayers />,
      },
      {
        path: "/portfoliomanagement",
        element: <PortfolioManagement />,
      },
    ],
  },

  {
    path: "payment-extension/*",
    element: <PaymentExtensionDashboard />,
    children: [
      {
        path: "/",
        element: <PaymentExtensionDashboard />,
      },
      {
        path: "singleapprovedextension/:id",
        element: <ApprovedExtensionPreview />,
      },
      {
        path: "bulkapprovedextension/:id",
        element: <BulkApprovedExtensionPreview />,
      },
      {
        path: "bulkapprovedextension/:id/:id",
        element: <BulkInvoice />,
      },
      {
        path: "singlenewextensiondetails/:id",
        element: <SingleNewExtensionRequestDetails />,
      },
      {
        path: "bulknewextensiondetails/:id",
        element: <BulkNewExtensionRequestDetails />,
      },
      {
        path: "bulknewextensiondetailspreview/:id/",
        element: <InvoiceBulk />,
      },
      {
        path: "single/updated/:id/",
        element: <SingleUpdated />,
      },
      {
        path: "single/rejected/:id/",
        element: <SingleRejected />,
      },
      {
        path: "single/pending/:id/",
        element: <SinglePending />,
      },
      {
        path: "single/approved/:id/",
        element: <SingleApproved />,
      },
      {
        path: "singleextensioninvoicelist",
        element: <SingleExtensionInvoiceList />,
      },
      {
        path: "singleextensioninvoicelist/invoicedetails",
        element: <SingleInvoiceDetails />,
      },
      {
        path: "bulk/rejected/:id/",
        element: <BulkRejected />,
      },
      {
        path: "bulk/invoice/:id",
        element: <BulkPaymentExtensionPreview />,
      },
      {
        path: "bulk/invoice/",
        element: <Invoice />,
      },
      {
        path: "bulk/pending/:id/",
        element: <BulkPending />,
      },
      {
        path: "bulk/approved/:id/",
        element: <BulkApproved />,
      },
      {
        path: "bulkextensioninvoicelist",
        element: <BulkExtensionInvoiceList />,
      },
    ],
  },

  {
    path: "supplier-earlypayment/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <SupplierEarlyPaymentDashboard />,
      },
      {
        path: "details/:offerId",
        element: <SupplierEarlypaymentDetails />,
      },
      // {
      //   path: "invoice/:offerId/:invoiceId",
      //   element: <SupplierEarlyPaymentAcceptedInvoice />,
      // },
      {
        path: "invoice/pending/:id",
        element: <SupplierEarlyPaymentPendingInvoice />,
      },
      {
        path: "invoice/accepted/:id",
        element: <SupplierEarlyPaymentAcceptedInvoice />,
      },
      {
        path: "invoice/rejected/:id",
        element: <SupplierEarlyPaymentRejectedInvoice />,
      },
      {
        path: "bulk-invoice/:offerId",
        element: <SupplierEarlyPaymentBulkInvoice />,
      },
    ],
  },
  {
    path: "supplier-early-payments/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <SupplierEarlyPayments />,
      },
      {
        path: "/single-early-payment-request-flow",
        element: <SupplierEarlyPaymentRequestFlow />,
      },
      {
        path: "/single-direct-funding-request-flow",
        element: <DirectFundingRequestFlow />,
      },
      // {
      //   path: "/single-early-payment-invoice-details",
      //   element: <SupplierEarlyPaymentInvoiceDetails />,
      // },
    ],
  },
  {
    path: "reimbursement/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <ReimbursementDashboard />,
      },
      {
        path: "/directfunding",
        element: <ReimbursementDirectFunding />,
      },
      {
        path: "/singlereimbursement/:id",
        element: <BuyerSingleDetails />,
      },
      {
        path: "/bulkreimbursement/:id",
        element: <BuyerBulkDetails />,
      },
      {
        path: "/invoice/:id",
        element: <BuyerBulkInvoice />,
      },
      {
        path: "/earlypayment",
        element: <ReimbursementBuyerEaryPayment />,
      },
      {
        path: "/openmarket",
        element: <ReimbursementBuyerOpenMarket />,
      },
      {
        path: "/closedmarkegggt",
        element: <ReimbursementBuyerClosedMarket />,
      },

      { path: "/", element: <ReimbursementDashboard /> },
      {
        path: "/reinbursementdirectfundinvoice",
        element: <ReinbursementDirectFundInvoice />,
      },
      {
        path: "/singledirectfundingdetail",
        element: <SingleDirectFundingDetai />,
      },
      {
        path: "/detail/directfundingpageDetail",
        element: <DirectFundingPageDetail />,
      },
      {
        path: "/detail/bulkdirectfundingpagedetails/:id",
        element: <BulkDirectFundingPageDetails />,
      },
      {
        path: "/detail/bulkdirectfundinginnerpagedetails/:id",
        element: <BulkDirectFundingInnerPageDetails />,
      },
      {
        path: "/detail/openmarketselectabledetail",
        element: <OpenMarketSelectableDetail />,
      },
      {
        path: "/detail/openmarketdirectfundingdetai/:id",
        element: <OpenMarketDirectFundingDetai />,
      },
    ],
  },
  {
    path: "payment-extension/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <PaymentExtensionDashboard />,
      },
      {
        path: "/singleapprovedextension/:id",
        element: <ApprovedExtensionPreview />,
      },
      {
        path: "/bulkapprovedextension/:id",
        element: <BulkApprovedExtensionPreview />,
      },
      {
        path: "/bulkapprovedextension/:id/:id",
        element: <BulkInvoice />,
      },
      {
        path: "/singlenewextensiondetails/:id",
        element: <SingleNewExtensionRequestDetails />,
      },
      {
        path: "/bulknewextensiondetails/:id",
        element: <BulkNewExtensionRequestDetails />,
      },
      {
        path: "/bulknewextensiondetailspreview/:id/",
        element: <InvoiceBulk />,
      },
      {
        path: "/single/updated/:id/",
        element: <SingleUpdated />,
      },
      {
        path: "/single/rejected/:id/",
        element: <SingleRejected />,
      },
      {
        path: "/single/pending/:id/",
        element: <SinglePending />,
      },
      {
        path: "/single/approved/:id/",
        element: <SingleApproved />,
      },
      {
        path: "/singleextensioninvoicelist",
        element: <SingleExtensionInvoiceList />,
      },
      {
        path: "/singleextensioninvoicelist/invoicedetails",
        element: <SingleInvoiceDetails />,
      },
      {
        path: "/bulk/rejected/:id/",
        element: <BulkRejected />,
      },
      {
        path: "/bulk/invoice/:id",
        element: <BulkPaymentExtensionPreview />,
      },
      {
        path: "/bulk/invoice/",
        element: <Invoice />,
      },

      {
        path: "/bulk/pending/:id/",
        element: <BulkPending />,
      },

      {
        path: "/bulk/approved/:id/",
        element: <BulkApproved />,
      },
      {
        path: "/bulkextensioninvoicelist",
        element: <BulkExtensionInvoiceList />,
      },
    ],
  },

  {
    path: "support/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <SupportPage />,
      },

      {
        path: "createcomplaint",
        element: <CreateComplaint />,
      },

      {
        path: "usercommunication/:id",
        element: <UserCommunication />,
      },
    ],
  },
  {
    path: "/settings",
    element: <SettingsPage />,
  },

  {
    path: "/settings/tools&agreement/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <ToolsAgreement />,
      },
    ],
  },

  {
    path: "/settings/workflow-config/*",
    element: <Outlet />,
    children: [
      {
        path: "/:player/:actionType",
        element: <ViewWorkflowAction />,
      },
      {
        path: "/edit/:player/:actionType",
        element: <EditWorkflow />,
      },
    ],
  },

  // RULES & CONFIGURATION
  {
    path: "/settings/rules&config/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <RulesConfig />,
      },
      {
        path: "/:ruleType",
        element: <RuleConfigScreen />,
      },
    ],
  },

  // Open Market
  {
    path: "/open-market/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <OpenMarket />,
      },
      {
        path: "/:id",
        element: <ViewInvoiceDetails />,
      },
      {
        path: "details/posted/:id",
        element: <ActiveInvoiceDetails />,
      },
      {
        path: "details/bid_active/:id",
        element: <TopContestedInvoiceDetails />,
      },
      {
        path: "pendingInvoice/:id",
        element: <PendingInvoiceDetails />,
      },
      {
        path: "ongoing/:id",
        element: <OngoingBidInvoiceDetails />,
      },
      {
        path: "acceptedInvoice/:id",
        element: <AcceptedInvoiceDetails />,
      },
      {
        path: "lockedInvoice/:id",
        element: <LockedInvoiceDetails />,
      },
      {
        path: "requestedInvoice/:id",
        element: <RequestedInvoiceDetails />,
      },
      {
        path: "accepted-bid/:id",
        element: <AcceptedInvoiceFunderDetails nofund />,
      },
      {
        path: "top-ten-invoices",
        element: <TopContestPage />,
      },
      {
        path: "top-ten-invoices-supplier",
        element: <TopTenContestedSupplier />,
      },
      {
        path: "top-contested-invoice-details/:id",
        element: <TopContestedInvoiceDetails />,
      },
      {
        path: "invoices/import",
        element: <ImportInvoiceOpenMarket />,
      },
    ],
  },

  // workflow management/detail/invoiceapprovalscreen/:id
  ///work-flow-managementdetaildetail/approvalviawalletscreentwodetails/:id

  {
    path: "/work-flow-management/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <WorkFlowDashBoard />,
      },
      {
        path: "detail/approvalhistoryalldetails",
        element: <ApprovalHistoryAllDetails />,
      },
      {
        path: "/workflowpendingapprovalrequests",
        element: <WorkFlowPendingApprovalRequestsScreen />,
      },

      {
        path: "/workflowpendingapprovaldetails/:workflowId",
        element: <PendingApprovalDetailsScreen />,
      },
      {
        path: "/workflowapprovedpendingapprovaldetails/:workflowId",
        element: <ApprovedPendingApprovalDetailsScreen />,
      },
      {
        path: "/workflowcompletedpendingapprovaldetails/:workflowId",
        element: <CompletedPendingApprovalDetailsScreen />,
      },
      {
        path: "/workflowdeclinedpendingapprovaldetails/:workflowId",
        element: <DeclinedPendingApprovalDetailsScreen />,
      },

      {
        path: "/workflowdclinedrequests",
        element: <WorkFlowDclinedRequestsScreen />,
      },

      {
        path: "/declinedrequestsdetails/:workflowId",
        element: <DeclinedRequestsDetailsScreen />,
      },

      {
        path: "pendingrequests",
        element: <WorkFlowPendingRequests />,
      },

      {
        path: "detail/pendingrequestsdetails/:workflowId",
        element: <PendingRequestsDetailsScreen />,
      },
      // PendingRequestsSignPfpdetails

      {
        path: "detail/pendingrequestsdetailssignpfp/:workflowId",
        element: <PendingRequestsSignPfpdetails />,
      },
      {
        path: "detail/pendingdeclineddetails/:workflowId",
        element: <PendingDeclinedDetailsScreen />,
      },
      {
        path: "detail/declinedsupplierinvoicescreen/:workflowId",
        element: <DeclinedSupplierInvoiceScreen />,
      },
      {
        path: "detail/invoiceapprovalscreen/:workflowId",
        element: <InvoiceApprovalScreen />,
      },
      {
        path: "detail/supplierearlypaymentrequest/:workflowId",
        element: <SupplierEarlyPaymentRequest />,
      },
      {
        path: "detail/suppliersubmitforApproval/:workflowId",
        element: <SupplierSubmitForApproval />,
      },
      {
        path: "detail/invoiceuploadscreen/:workflowId",
        element: <InvoiceUploadScreen />,
      },
      {
        path: "detail/approvalviawalletscreendetails/:workflowId",
        element: <ApprovalViaWalletScreenDetails />,
      },
      {
        path: "detail/approvalviawalletscreen/:workflowId",
        element: <ApprovalViaWalletScreen />,
      },

      {
        path: "detail/approvalviawalletscreentwodetails/:workflowId",
        element: <ApprovalViaWalletScreenTwoDetails />,
      },
      {
        path: "detail/approvalviadirectfundingscreendetails/:workflowId",
        element: (
          <Suspense fallback="loading">
            <ApprovalViaDirectFundingScreenDetails />
          </Suspense>
        ),
      },
      {
        path: "detail/approvalviadirectfundingscreen/:workflowId",
        element: (
          <Suspense fallback="loading">
            <ApprovalViaDirectFundingScreen />
          </Suspense>
        ),
      },

      {
        path: "detail/approvalviaClosedMarketscreendetails/:workflowId",
        element: (
          <Suspense fallback="loading">
            <ApprovalViaClosedMarketScreenDetails />
          </Suspense>
        ),
      },

      {
        path: "detail/approvalviaClosedMarketscreen/:workflowId",
        element: (
          <Suspense fallback="loading">
            <ApprovalViaClosedMarketScreen />
          </Suspense>
        ),
      },
      {
        path: "detail/approvalviaconfirmationandopenmarket/:workflowId",
        element: (
          <Suspense fallback="loading">
            <ApprovalViaConfirmationAndOpenMarket />
          </Suspense>
        ),
      },
      {
        path: "detail/approvalviacampagncreationscreen/:workflowId",
        element: (
          <Suspense fallback="loading">
            <ApprovalViaCampagnCreationScreen />
          </Suspense>
        ),
      },
      {
        path: "detail/approvalviadirectfundingAndconfirmationscreendetails/:workflowId",
        element: (
          <Suspense fallback="loading">
            <ApprovalViaDirectFundingAndConfirmationScreenDetails />
          </Suspense>
        ),
      },
      {
        path: "detail/PendingRequestInvoiceApprovalScreen/:workflowId",
        element: (
          <Suspense fallback="loading">
            <PendingRequestInvoiceApprovalScreen />
          </Suspense>
        ),
      },
      {
        path: "detail/PendingRequestInvoiceUpload/:workflowId",
        element: (
          <Suspense fallback="loading">
            <PendingRequestInvoiceUpload />
          </Suspense>
        ),
      },
      {
        path: "detail/PendingRequestApprovalViaWalletScreen/:workflowId",
        element: (
          <Suspense fallback="loading">
            <PendingRequestApprovalViaWalletScreen />
          </Suspense>
        ),
      },
      {
        path: "detail/PendingRequestApprovalViaWallet/:workflowId",
        element: (
          <Suspense fallback="loading">
            <PendingRequestApprovalViaWallet />
          </Suspense>
        ),
      },

      // PendingRequestApprovalViaWalletScreenTwoDetailsSecond
      {
        path: "detail/PendingRequestApprovalViaWalletScreenTwoDetails/:workflowId",
        element: (
          <Suspense fallback="loading">
            <PendingRequestApprovalViaWalletScreenTwoDetails />
          </Suspense>
        ),
      },
      {
        path: "detail/PendingRequestApprovalViaWalletScreenTwoDetailsSecond/:workflowId",
        element: (
          <Suspense fallback="loading">
            <PendingRequestApprovalViaWalletScreenTwoDetailsSecond />
          </Suspense>
        ),
      },
      {
        path: "detail/PendingRequestsApprovalViaDirectFundingScreenDetails/:workflowId",
        element: (
          <Suspense fallback="loading">
            <PendingRequestsApprovalViaDirectFundingScreenDetails />
          </Suspense>
        ),
      },
      // PendingRequestApprovalViaClosedMarketScreenDetails

      {
        path: "detail/PendingRequestApprovalViaDirectFundingScreen/:workflowId",
        element: (
          <Suspense fallback="loading">
            <PendingRequestApprovalViaDirectFundingScreen />
          </Suspense>
        ),
      },

      // {
      //   path: "detail/PendingRequestApprovalViaDirectFundingScreen/:id",
      //   element: (
      //     <Suspense fallback="loading">
      //       <PendingRequestApprovalViaDirectFundingScreen />
      //     </Suspense>
      //   ),
      // },

      // PendingRequestApprovalViaClosedMarketScreen
      {
        path: "detail/PendingRequestApprovalViaClosedMarketScreenDetails/:workflowId",
        element: (
          <Suspense fallback="loading">
            <PendingRequestApprovalViaClosedMarketScreenDetails />
          </Suspense>
        ),
      },
      {
        path: "detail/PendingRequestApprovalViaClosedMarketScreen/:workflowId",
        element: (
          <Suspense fallback="loading">
            <PendingRequestApprovalViaClosedMarketScreen />
          </Suspense>
        ),
      },
      {
        path: "detail/PendingRequestApprovalViaConfirmationAndOpenMarket/:workflowId",
        element: (
          <Suspense fallback="loading">
            <PendingRequestApprovalViaConfirmationAndOpenMarket />
          </Suspense>
        ),
      },

      // PendingRequestApprovalViaDirectFundingAndConfirmationScreenDetails

      {
        path: "detail/PendingRequestApprovalViaCampagnCreationScreen/:workflowId",
        element: (
          <Suspense fallback="loading">
            <PendingRequestApprovalViaCampagnCreationScreen />
          </Suspense>
        ),
      },
      {
        path: "detail/PendingRequestEarlyPaymentOffer/:workflowId",
        element: <PendingRequestEarlyPaymentOffer />,
      },

      //  PendingApprovalSignPfpdetails
      {
        path: "detail/PendingRequestPFPFundOffer/:workflowId",
        element: <PendingRequestPFPFundOffer />,
      },

      // DeclinedPendingRequestsSignPfpdetails
      // DeclinedPendingRequestPFPFundOffer
      {
        path: "detail/PendingApprovalSignPfpdetails/:workflowId",
        element: <PendingApprovalSignPfpdetails />,
      },
      {
        path: "detail/DeclinedPendingRequestsSignPfpdetails/:workflowId",
        element: <DeclinedPendingRequestsSignPfpdetails />,
      },
      {
        path: "detail/DeclinedPendingRequestPFPFundOffer/:workflowId",
        element: <DeclinedPendingRequestPFPFundOffer />,
      },
      {
        path: "detail/PendingApprovalPFPFundOffer/:workflowId",
        element: <PendingApprovalPFPFundOffer />,
      },
      {
        path: "detail/PendingRequestApprovalViaDirectFundingAndConfirmationScreenDetails/:workflowId",
        element: (
          <Suspense fallback="loading">
            <PendingRequestApprovalViaDirectFundingAndConfirmationScreenDetails />
          </Suspense>
        ),
      },

      //dclined request routes

      {
        path: "detail/DeclinedRequestInvoiceApprovalScreen/:workflowId",
        element: (
          <Suspense fallback="loading">
            <DeclinedRequestInvoiceApprovalScreen />
          </Suspense>
        ),
      },
      {
        path: "detail/DeclinedRequestInvoiceUploadScreen/:workflowId",
        element: (
          <Suspense fallback="loading">
            <DeclinedRequestInvoiceUploadScreen />
          </Suspense>
        ),
      },
      {
        path: "detail/DeclinedRequestApprovalViaWalletScreen/:workflowId",
        element: (
          <Suspense fallback="loading">
            <DeclinedRequestApprovalViaWalletScreen />
          </Suspense>
        ),
      },
      {
        path: "detail/DeclinedRequestApprovalViaWallet/:workflowId",
        element: (
          <Suspense fallback="loading">
            <DeclinedRequestApprovalViaWallet />
          </Suspense>
        ),
      },
      {
        path: "detail/DeclinedRequestApprovalViaWalletScreenTwoDetails/:workflowId",
        element: (
          <Suspense fallback="loading">
            <DeclinedRequestApprovalViaWalletScreenTwoDetails />
          </Suspense>
        ),
      },
      {
        path: "detail/DeclinedRequestsApprovalViaDirectFundingScreenDetails/:workflowId",
        element: (
          <Suspense fallback="loading">
            <DeclinedRequestsApprovalViaDirectFundingScreenDetails />
          </Suspense>
        ),
      },
      // PendingRequestApprovalViaClosedMarketScreenDetails

      {
        path: "detail/DeclinedRequestApprovalViaDirectFundingScreen/:workflowId",
        element: (
          <Suspense fallback="loading">
            <DeclinedRequestApprovalViaDirectFundingScreen />
          </Suspense>
        ),
      },

      // {
      //   path: "detail/PendingRequestApprovalViaDirectFundingScreen/:id",
      //   element: (
      //     <Suspense fallback="loading">
      //       <PendingRequestApprovalViaDirectFundingScreen />
      //     </Suspense>
      //   ),
      // },

      // PendingRequestApprovalViaClosedMarketScreen
      {
        path: "detail/DeclinedRequestApprovalViaClosedMarketScreenDetails/:workflowId",
        element: (
          <Suspense fallback="loading">
            <DeclinedRequestApprovalViaClosedMarketScreenDetails />
          </Suspense>
        ),
      },
      {
        path: "detail/DeclinedRequestApprovalViaClosedMarketScreen/:workflowId",
        element: (
          <Suspense fallback="loading">
            <DeclinedRequestApprovalViaClosedMarketScreen />
          </Suspense>
        ),
      },
      {
        path: "detail/DeclinedRequestApprovalViaConfirmationAndOpenMarket/:workflowId",
        element: (
          <Suspense fallback="loading">
            <DeclinedRequestApprovalViaConfirmationAndOpenMarket />
          </Suspense>
        ),
      },

      // PendingRequestApprovalViaDirectFundingAndConfirmationScreenDetails

      {
        path: "detail/DeclinedRequestApprovalViaCampagnCreationScreen/:workflowId",
        element: (
          <Suspense fallback="loading">
            <DeclinedRequestApprovalViaCampagnCreationScreen />
          </Suspense>
        ),
      },
      {
        path: "detail/DeclinedRequestApprovalViaDirectFundingAndConfirmationScreenDetails/:workflowId",
        element: (
          <Suspense fallback="loading">
            <DeclinedRequestApprovalViaDirectFundingAndConfirmationScreenDetails />
          </Suspense>
        ),
      },
    ],
  },

  //role manager

  {
    path: "role_manager/*",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <RoleManagerDashboardPage />,
      },

      {
        path: ":id",
        element: <ViewRoleFullDetails />,
      },
    ],
  },

  {
    path: "/account-management",
    element: <RoleManagerAccountsPage />,
  },
  {
    path: "/documents",
    element: <RoleManagerDocument />,
  },

  //onboarding
  {
    path: "buyer/*",
    element: <Outlet />,
    children: [
      {
        path: "company-details",
        element: <BuyerCompanyDetails />,
      },
      {
        path: "terms",
        element: <Terms />,
      },

      {
        path: "add-account",
        element: <AddAccount />,
      },
    ],
  },
  {
    path: "buyer-onboard-supplier/*",
    element: <Outlet />,
    children: [
      {
        path: "buyer-invite-supplier",
        element: <BuyerInviteSupplier />,
      },
      {
        path: "next-steps",
        element: <NextSteps />,
      },
      {
        path: "create-account",
        element: <BuyerOnboardSupplierSignUp />,
      },
      {
        path: "sign-in",
        element: <BuyerOnboardSupplierSigIn />,
      },
      {
        path: "add-account",
        element: <BuyerOnboardSupplierAddAccount />,
      },

      {
        path: "get-started",
        element: <BuyerOnboardSupplierGetStarted />,
      },
      {
        path: "company-details",
        element: <BuyerOnboardSupplierCompanyDetails />,
      },

      {
        path: "terms",
        element: <BuyerOnboardSupplierTerms />,
      },
    ],
  },
  {
    path: "supplier/*",
    element: <Outlet />,
    children: [
      {
        path: "select-buyer",
        element: <SelectBuyer />,
      },
      {
        path: "company-details",
        element: <Companydetails />,
      },
      {
        path: "terms",
        element: <TermsAndCondition />,
      },
    ],
  },

  {
    path: "funder/*",
    element: <Outlet />,
    children: [
      {
        path: "funder-company-details",
        element: <FunderCompany />,
      },
      {
        path: "terms",
        element: <FunderTerms />,
      },
      {
        path: "get-started",
        element: <FunderGetStarted />,
      },
    ],
  },

  // Dynamic Onboarding routes

  {
    path: "onboarding/:player/*",
    element: <Outlet />,
    children: [
      {
        path: "staff/personal-details",
        element: <StaffPersonalDetails />,
      },
      {
        path: "staff/terms&conditions",
        element: <StaffTermsAndConditions />,
      },
      {
        path: "kyc",
        element: <KYC />,
      },
      {
        path: "sign-funding-agreement",
        element: <FundingAgreement />,
      },
      {
        path: "get-started",
        element: <GetStartedAll />,
      },
      {
        path: "banking-details",
        element: <AddBankAll />,
      },
    ],
  },
];

export const publicRoutes = [
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/verify-email",
    element: <VerifyEmail />,
  },
  {
    path: "/resend-verification-link",
    element: <ResendVerificationLink />,
  },

  {
    path: "/resend-verification",
    element: <ResendVerification />,
  },
  {
    path: "/category",
    element: <CategoryPage />,
  },
  {
    path: "/personal-details",
    element: <PersonalDetails />,
  },
  {
    path: "/supplier-create-account",
    element: <SupplierSignup />,
  },
  {
    path: "funder/Cooperate-Funder/*",
    element: <Outlet />,
    children: [
      {
        path: "company-details",
        element: <CooperateFunderCompany />,
      },
      {
        path: "password",
        element: <CooperateFunderPassword />,
      },
      {
        path: "get-started",
        element: <CooperateFunderGetstarted />,
      },
      {
        path: "terms",
        element: <CooperateFunderTerms />,
      },
    ],
  },
  {
    path: "/funder-create-account",
    element: <FunderSignup />,
  },
  {
    path: "member/*",
    element: <Outlet />,
    children: [
      {
        path: "membersignup",
        element: <MemberSignup />,
      },
      {
        path: "memberTerms",
        element: <MemberTermsAndCondition />,
      },
      {
        path: "membergetstarted",
        element: <MemberGetstarted />,
      },
    ],
  },
];
