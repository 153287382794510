import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { PAIconCancel, PAIconSearchForFunder } from "assets/images/svgs";
import AddModalComponent from "./modalcomponent/AddModalComponent";
import { useDispatch, useSelector } from "react-redux";
import { getAllFunderListClosedMarket } from "appstate/openMarket/openMarketSlice";

const AddFundModal = ({
  isOpen,
  onClose,
  handleProceed,
  selectable = [
    { id: "1", discount: 2.3, title: "Nestlé Alimentana SA" },
    { id: "2", discount: 2.3, title: "Dangote" },
    { id: "3", discount: 2.3, title: "Coca-Cola Company" },
    { id: "4", discount: 2.3, title: "Piggyvest PLC." },
    { id: "5", discount: 2.3, title: "Shell" },
    { id: "6", discount: 2.3, title: "Shell" },
    { id: "7", discount: 2.3, title: "Shell" },
    { id: "8", discount: 2.3, title: "Shell" },
    { id: "9", discount: 2.3, title: "Shell" },
  ],

  selectedFunderIds,
  setSelectedFunderIds,
}) => {
  const dispatch = useDispatch();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const { getAllFunderListClosedMarketData } = useSelector(
    (state) => state?.openMarket
  );

  useEffect(() => {
    dispatch(getAllFunderListClosedMarket({ searchQuery }));
  }, [dispatch, searchQuery]);

  // console.log(getAllFunderListClosedMarketData);
  // Handlers
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allIds =
        getAllFunderListClosedMarketData?.data?.funderDataList?.map(
          (row) => row?.funderId
        ) || [];
      // const allRows = selectable?.map((row) => row) || [];
      setSelectedRows(allIds);
      // setSelectedRowsContent(allRows);
    } else {
      setSelectedRows([]);
      // setSelectedRowsContent([]);
    }
  };
  const handleCheckboxChange = (row) => {
    if (selectAll) setSelectAll(false);
    const isSelected = selectedRows?.includes(row?.funderId);
    const updatedRows = isSelected
      ? selectedRows?.filter((id) => id !== row?.funderId)
      : [...selectedRows, row?.funderId];

    // const updatedRowContents = isSelected
    //   ? selectedRows.filter((id) => id !== row.id)
    //   : [...selectedRowsContent, row];
    setSelectedRows(updatedRows);
    // setSelectedRowsContent(updatedRowContents);
  };

  return (
    <AnimatePresence>
      <motion.div
        className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <motion.div
          className="relative z-[999999] bg-white rounded-lg shadow-lg p-6 w-full max-w-xl mx-4 sm:mx-auto top-[5%] max-h-[75vh] h-fit transform flex flex-col"
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -100, opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <header className="flex mb-5 justify-between border-b-[3px] border-[#F6F5FA] pb-4">
            <p className="text-neutral-800 text-base font-bold font-['SF Pro Display']">
              Add funders to your closed market
            </p>
            <div
              onClick={() => {
                setSelectedRows([]);
                // setSelectedRowsContent([]);
                setSelectAll(false);
                onClose();
              }}
              className="flex justify-between items-center gap-x-[10px] cursor-pointer"
            >
              <PAIconCancel className="w-[8px]" />
              <p className="text-sm font-medium text-gray800">Close</p>
            </div>
          </header>
          <div className="w-full pb-4 border-[#BDBDBD] items-center gap-6 mb-5 inline-flex border-b">
            <div>
              <PAIconSearchForFunder />
            </div>
            <div className="justify-start items-center w-full flex">
              <input
                className="w-full focus:outline-none placeholder:text-[#565656] text-neutral-600 text-xl accent-[#F08000] font-normal"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                type="text"
                placeholder="Search for funder"
              />
            </div>
          </div>

          <div
            style={{ scrollbarWidth: "3px", scrollbarColor: "#D9D9D9" }}
            className="flex flex-col min-h-[30vh] max-h-[40vh] gap-2.5 overflow-auto"
          >
            <div className="w-full  pb-4 border-b border-[#BDBDBD] items-center gap-4 flex">
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={selectAll}
                className="checked:bg-blue-500 h-5 w-5 rounded-sm cursor-pointer"
              />

              <span className="text-[#1D0220] text-sm">Select All</span>
            </div>

            {getAllFunderListClosedMarketData?.data?.funderDataList?.map(
              (item) => (
                <AddModalComponent
                  onBoxChange={() => handleCheckboxChange(item)}
                  boxChecked={selectedRows?.includes(item?.funderId)}
                  companyName={item?.companyName}
                  key={item?.funderId}
                  discount={item?.discount}
                />
              )
            )}
          </div>

          <div
            className="py-4 w-full"
            onClick={() => {
              setSelectedFunderIds(selectedRows);
              handleProceed();
              setSelectedRows([]);
              setSelectAll(false);
            }}
          >
            <button
              disabled={selectedRows?.length < 1}
              className="w-full px-8 py-5 bg-[#07593D] rounded-[5px] justify-center items-center gap-2.5 inline-flex cursor-pointer"
            >
              {selectedRows?.length < 1 ? (
                <span className="text-white text-sm font-normal">Next</span>
              ) : (
                <div className="w-full flex justify-between">
                  <span className="text-white font-semibold">Continue</span>

                  <span className="text-white italic font-semibold text-sm">
                    ({selectedRows?.length} selected)
                  </span>
                </div>
              )}
            </button>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default AddFundModal;
