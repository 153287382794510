import React, { useEffect, useState } from "react";
import { PAIconFilter, PAIconOutlineSearch } from "assets/images/svgs";
import Card from "shared/Card";

// import styled from "styled-components";

// import ClosedMarketHeaderSelect from "shared/tableHeader/ClosedMarketHeaderSelect";

import TransitionOpacity from "shared/animation/TransitionOpacity";
import MyInvoicesCard from "features/closedmarket/components/cards/MyInvoicesCard";

import { useDispatch, useSelector } from "react-redux";
import {
  closedMarketViewListBulk,
  closedMarketViewListSingle,
} from "appstate/buyer/buyerSlice";
import MyInvoicesCardBulk from "features/closedmarket/components/cards/MyInvoicesCardBulk";

const MyInvoices = ({ handleChange }) => {
  const dispatch = useDispatch();
  const [requestType, setRequestType] = useState("single");
  // const options = [
  //   {
  //     id: 1,
  //     name: "All Invoices",
  //   },
  //   {
  //     id: 2,
  //     name: "Accepted Invoices",
  //   },
  //   {
  //     id: 3,
  //     name: "Locked Invoices",
  //   },
  //   {
  //     id: 4,
  //     name: "Active Bids",
  //   },
  // ];
  // const dummyData = [
  //   {
  //     bidAmount: 3,
  //     buyerCompanyName: "Coca Cola",
  //     buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
  //     discountRate: 3,
  //     funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
  //     invoiceAmount: 32164082.78,
  //     invoiceDueDate: "2024-03-01",
  //     invoiceId: "65dfc0942b607753910b022e",
  //     invoiceReference: "INV-001",
  //     lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
  //     lockedDate: "2024-03-07",
  //     note: "Wale",
  //     offerId: "65e1eda9e789120b67af15b4",
  //     status: "Locked",
  //     supplierCompanyName: "Lancoan",
  //     supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
  //     userTypeIcon: PAIconCocacola,
  //     userType: "Supplier",
  //   },
  //   {
  //     bidAmount: 2,
  //     buyerCompanyName: "Coke Plc.",
  //     buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f22",
  //     discountRate: 2,
  //     funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa2",
  //     invoiceAmount: 32164082.78,
  //     invoiceDueDate: "2024-03-01",
  //     invoiceId: "65dfc0942b607753910b022e2",
  //     invoiceReference: "INV-345",
  //     lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa2",
  //     lockedDate: "2024-03-07",
  //     note: "Wale",
  //     offerId: "65e1eda9e789120b67af15b42",
  //     status: "Locked",
  //     supplierCompanyName: "Lancoan",
  //     supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c3802",
  //     userTypeIcon: PAIconSmallCoke,
  //     userType: "Buyer",
  //   },
  //   {
  //     bidAmount: 4,
  //     buyerCompanyName: "Dangote PLC",
  //     buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f24",
  //     discountRate: 4,
  //     funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa4",
  //     invoiceAmount: 32164082.78,
  //     invoiceDueDate: "2024-03-01",
  //     invoiceId: "65dfc0942b607753910b022e4",
  //     invoiceReference: "INV-937",
  //     lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa4",
  //     lockedDate: "2024-03-07",
  //     note: "Wale",
  //     offerId: "65e1eda9e789120b67af15b44",
  //     status: "Locked",
  //     supplierCompanyName: "Lancoan",
  //     supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c3804",
  //     userTypeIcon: PAIconSmallDangote,
  //     userType: "Buyer",
  //   },
  //   {
  //     bidAmount: 5,
  //     buyerCompanyName: "Nestle plc",
  //     buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f25",
  //     discountRate: 5,
  //     funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa5",
  //     invoiceAmount: 32164082.78,
  //     invoiceDueDate: "2024-03-01",
  //     invoiceId: "65dfc0942b607753910b022e5",
  //     invoiceReference: "INV-892",
  //     lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa5",
  //     lockedDate: "2024-03-07",
  //     note: "Wale",
  //     offerId: "65e1eda9e789120b67af15b45",
  //     status: "Locked",
  //     supplierCompanyName: "Lancoan",
  //     supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c3805",
  //     userTypeIcon: PAIconSmallNestle,
  //     userType: "Buyer",
  //   },
  //   {
  //     bidAmount: 6,
  //     buyerCompanyName: "Shell  Ltd.",
  //     buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f26",
  //     discountRate: 6,
  //     funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa6",
  //     invoiceAmount: 32164082.78,
  //     invoiceDueDate: "2024-03-01",
  //     invoiceId: "65dfc0942b607753910b022e6",
  //     invoiceReference: "INV-826",
  //     lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa6",
  //     lockedDate: "2024-03-07",
  //     note: "Wale",
  //     offerId: "65e1eda9e789120b67af15b46",
  //     status: "Locked",
  //     supplierCompanyName: "Lancoan",
  //     supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c3806",
  //     userTypeIcon: PAIconSmallShell,
  //     userType: "Buyer",
  //   },
  //   {
  //     bidAmount: 7,
  //     buyerCompanyName: "Trust ltd",
  //     buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f27",
  //     discountRate: 7,
  //     funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa7",
  //     invoiceAmount: 32164082.78,
  //     invoiceDueDate: "2024-03-01",
  //     invoiceId: "65dfc0942b607753910b022e7",
  //     invoiceReference: "INV-123",
  //     lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa7",
  //     lockedDate: "2024-03-07",
  //     note: "Wale",
  //     offerId: "65e1eda9e789120b67af15b47",
  //     status: "Locked",
  //     supplierCompanyName: "Lancoan",
  //     supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c3807",
  //     userTypeIcon: PAIconSmallTrust,
  //     userType: "Buyer",
  //   },
  //   {
  //     bidAmount: 8,
  //     buyerCompanyName: "Brewery",
  //     buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f28",
  //     discountRate: 7,
  //     funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa8",
  //     invoiceAmount: 32164082.78,
  //     invoiceDueDate: "2024-03-01",
  //     invoiceId: "65dfc0942b607753910b022e8",
  //     invoiceReference: "INV-048",
  //     lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa8",
  //     lockedDate: "2024-03-07",
  //     note: "Wale",
  //     offerId: "65e1eda9e789120b67af15b48",
  //     status: "Locked",
  //     supplierCompanyName: "Lancoan",
  //     supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c3808",
  //     userTypeIcon: PAIconSmallBrewIcon,
  //     userType: "Buyer",
  //   },
  // ];

  const { closedMarketViewListSingleData, closedMarketViewListBulkData } =
    useSelector((state) => state?.buyer);

  useEffect(() => {
    dispatch(closedMarketViewListSingle("LOCKED"));
    dispatch(closedMarketViewListBulk("LOCKED"));
  }, [dispatch]);

  return (
    <>
      <div>
        <TransitionOpacity className="min-h-[75vh] rounded-b-[10px] bg-unusualGray rounded-tl-[10px]  rounded-tr-[10px] ">
          <Card className="  rounded-tl-[10px]  rounded-tr-[10px] p-[0.5px] ">
            <div className=" px-[24px]  rounded-tl-[10px]  rounded-tr-[10px]  ">
              <div className="my-2 grid md:grid-cols-2 items-center">
                <div className="gap-3 flex relative">
                  {/* FILTER */}
                  <div className="">
                    <p className=" text-black text-[16px] font-bold">
                      Won bids from funder:
                    </p>
                    <p className=" text-black text-sm font-normal">
                      List of bids won by your associated funders:
                    </p>
                  </div>
                </div>

                <div className="flex md:col-span-1 justify-end">
                  <div className="flex items-center justify-between gap-5">
                    <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
                      <div className="items-center flex bg-white">
                        <PAIconOutlineSearch />
                        <input
                          className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                          type="text"
                          placeholder="Search for request"
                        />
                      </div>
                      <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                        <PAIconFilter className="bg-[#2FA06A]" />
                      </div>
                    </div>
                    <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <div className=" p-6 flex flex-col gap-5 ">
            <div className="flex items-center">
              <div
                onClick={() => {
                  setRequestType("single");
                }}
                className="mr-6 flex items-center flex-col cursor-pointer"
              >
                <p
                  className={
                    requestType === "single"
                      ? "text-secondaryColor font-bold text-sm"
                      : "text-[#959595] text-sm"
                  }
                >
                  Single Requests
                </p>

                {requestType === "single" && (
                  <div className="h-[1.5px] w-6 rounded-lg bg-secondaryColor"></div>
                )}
              </div>
              <div
                onClick={() => {
                  setRequestType("bulk");
                }}
                className="flex items-center flex-col cursor-pointer"
              >
                <p
                  className={
                    requestType === "bulk"
                      ? "text-secondaryColor font-bold text-sm"
                      : "text-[#959595] text-sm"
                  }
                >
                  Bulk Requests
                </p>

                {requestType === "bulk" && (
                  <div className="h-[1.5px] w-6 rounded-lg bg-secondaryColor"></div>
                )}
              </div>
            </div>
            {requestType === "single" ? (
              closedMarketViewListSingleData?.data?.length > 0 ? (
                closedMarketViewListSingleData?.data?.map((invoice) => {
                  return (
                    <MyInvoicesCard
                      key={invoice?.invoiceId}
                      id={invoice?.invoiceId}
                      status={invoice.status}
                      supplierCompanyName={invoice.buyerCompanyName}
                      invoiceNumber={invoice?.invoiceReference}
                      doerType={invoice.userType}
                      invoiceAmount={invoice?.invoiceAmount}
                      invoiceDueDate={invoice?.invoiceDueDate}
                      supplierCompanyIcon={invoice.userTypeIcon}
                      discountRate={invoice.discountRate}
                    />
                  );
                })
              ) : (
                <div className="flex pt-6 justify-center">
                  <span className="font-medium">
                    There are no records to display
                  </span>
                </div>
              )
            ) : closedMarketViewListBulkData?.data?.length > 0 ? (
              closedMarketViewListBulkData?.data?.map((invoice) => {
                return (
                  <MyInvoicesCardBulk
                    key={invoice?.invoiceId}
                    id={invoice?.invoiceId}
                    status={invoice.status}
                    supplierCompanyName={invoice.buyerCompanyName}
                    invoiceNumber={invoice?.invoiceReference}
                    doerType={invoice.userType}
                    invoiceAmount={invoice?.invoiceAmount}
                    invoiceDueDate={invoice?.invoiceDueDate}
                    supplierCompanyIcon={invoice.userTypeIcon}
                    discountRate={invoice.discountRate}
                  />
                );
              })
            ) : (
              <div className="flex pt-6 justify-center">
                <span className="font-medium">
                  There are no records to display
                </span>
              </div>
            )}
          </div>
        </TransitionOpacity>
      </div>
    </>
  );
};

export default MyInvoices;
// const SelectContainer = styled.div`
//   min-width: 150px;
//   @media screen and (max-width: 470px) {
//     min-width: auto;
//   }
// `;
