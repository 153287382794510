import { PAIconInfo } from "assets/images/svgs";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Button from "shared/Button";
import InputField from "shared/InputField";
// import ConfirmModal from "./ConfirmModal";
import { useSelector } from "react-redux";
import ReceivingFundModal from "./ReceivingFundModal";
// import VirtualAccountSelection from "./VirtualAccountSelection";
import ViewMore from "./ViewMore";
import TransferFundModal from "./TransferFund";
import PinModalPayment from "./PinModal";
import SuccessEarlyPayment from "./SuccessEarlyPayment";
import EarlyPaymentConfirmation from "./EarlyPaymentConfirmation";
import {
  createDirectFundingRequest,
  fetchAppliedRule,
  getVirtualAccounts,
} from "appstate/api/invoice/invoice";
import EarlyPaymentVirtualAccountSelection from "./VirtualAccountSelection";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "utilities/helper/formatters";

const DirectRequestModalContent = ({
  onClose,
  data,
  setShowDirectModal,
  funderId,
  invoiceAmount,
  invoiceId,
  dueDate,
  currency,
  companyName,
  invoiceDate,
  invoiceData
}) => {
  // eslint-disable-next-line
  const { earlyPaymentLoading, approvedInvoiceId } = useSelector(
    (state) => state?.supplier
  );
  const navigate = useNavigate();
  const [applied, setApplied] = useState(false);
  // eslint-disable-next-line
  const [amount, setAmount] = useState("");
  //  const [enableDiscount, setEnableDiscount] = useState(false);
  // eslint-disable-next-line
  const [discountedAmount, setDiscountedAmount] = useState("");
  const [open, setOpen] = useState(false);
  const [newPaymentDate, setNewPaymentDate] = useState("");
  const [description, setDescription] = useState("");
  const [selectedRequest, setSelectedRequest] = useState("");
  const [showReceivingFundModal, setShowReceivingFundModal] = useState(false);
  const [showVirtualSelectionModal, setShowVirtualSelectionModal] =
    useState(false);
  const [showViewMore, setShowViewMore] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isActivatedWithRecourse, setIsActivatedWithRecourse] = useState(false);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  const [requestAmount, setRequestAmount] = useState(null);
  const [receivableAmount, setReceivableAmount] = useState(null);
  const [discountRate, setDiscountRate] = useState(null);
  const [virtualAccounts, setVirtualAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
  const [percentageLimit, setPercentageLimit] = useState(null);
  const [receivableAmountWithoutDiscount, setReceivableAmountWithoutDiscount] = useState("");
  const [discountRateWithRecourse, setDiscountRateWithRecourse] = useState(null);
  // eslint-disable-next-line
  const buyerId = data?.buyerId;

  const handleViewMoreClick = () => {
    setShowViewMore(true);
  };

  const virtualAccountId = selectedAccount?.id;

  useEffect(() => {
    const fetchVirtualAccounts = async () => {
      try {
        const result = await getVirtualAccounts();
        setVirtualAccounts(result?.data);
      } catch (error) {
        console.error("Failed to fetch virtual accounts:", error);
      }
    };

    fetchVirtualAccounts();
  }, []);

  const handleSetNewAmount = () => {
    // setNewPaymentDate("");
    setTypingInputVal(false);
    // setDescription("");
    setApplied(false);
  };

  const handleCloseViewMore = () => {
    setShowViewMore(false);
  };

  const handleShowPin = () => {
    setShowTransferModal(false);
    setShowPin(true);
  };

  const handleCheckboxChange = (event) => {
    setIsActivatedWithRecourse(event.target.checked);
  };

  const [typingInputVal, setTypingInputVal] = useState(false);

  const [debounceTimer, setDebounceTimer] = useState(null);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSelectedRequest(value);

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const timer = setTimeout(() => {
      if (value) {
        setTypingInputVal(true);
        handleFetchRule(value);
      }
    }, 3000);

    setDebounceTimer(timer);
  };

  const handleShowReceivingFundModal = () => {
    setShowReceivingFundModal(true);
  };

  const handleShowVirtualAccountSelection = () => {
    setShowVirtualSelectionModal(true);
    setShowReceivingFundModal(false);
  };

  const handleCloseVirtualAccount = () => {
    setShowReceivingFundModal(false);
    setShowVirtualSelectionModal(false);
  };

  const handleChangeAccount = () => {
    setSelectedAccount(null); 
    setShowVirtualSelectionModal(true);
    setShowTransferModal(false);
  };

  const handleCloseTransferModal = () => {
    setShowTransferModal(false);
  };

  function handleSendRequest() {
    setOpen(false);
    setShowTransferModal(true);
    //  onClose();
  }

  const handleDirectFundingRequest = async () => {
    const pin = otpInputs.toString();
    const transactPin = pin.replace(/,/g, "");
    const fundingRequestData = {
      invoiceId,
      funderId,
      funderCompanyName: companyName,
      invoiceAmount,
      requestAmount: receivableAmount,
      requestPercentage: `${selectedRequest}%`,
      discountRate,
      newPaymentDate,
      invoiceDate,
      description,
      percentageLimit,
      virtualAccountId,
      transactionPin: transactPin,
      withoutRecourse: isActivatedWithRecourse,
    };

    try {
      const response = await createDirectFundingRequest(fundingRequestData);
      if (response?.status === 201) {
        setShowConfirmModal(false);
        setShowSuccess(true);

        toast.success(response?.message);

        setTimeout(() => {
          setShowDirectModal(false);
          navigate("/supplier-early-payments");
        }, 3000);
      } else {
        toast.error(response?.message);
        setTimeout(() => {
          setShowDirectModal(false);
          navigate("/supplier-early-payments");
        }, 3000);
        // /direct-funding-invoice-details
      }
    } catch (error) {
      console.error("Error creating direct funding request:", error);
    }
  };

  const confirmPayment = () => {
    setShowConfirmModal(true);
    setShowPin(false);
  };

  const onClosePinModal = () => {
    setShowPin(false);
  };

  const handleApply = () => {
    setApplied(true);
  };

  const handleEdit = () => {
    setTypingInputVal(false);
  }


  function handleOpenModal() {
    if (!description || !newPaymentDate) {
      toast.error("Description or new payment date are required.");
      return;
    }
    setOpen(true);
  }

  const handleFetchRule = async (directFundingPercentage) => {
    const requestBody = {
      directFundingId: "67456ff057d53173af9044c0",
      funderId,
      invoiceId,
      invoiceAmount,
      invoiceDueDate: dueDate,
      //here
      directFundingPercentage: directFundingPercentage,
      // percentageLimit: selectedRequest,
      newPaymentDate: newPaymentDate,
      bulk: false,
      withoutRecourse: isActivatedWithRecourse,
      buyerId: buyerId,
      currency,
      issuanceDate: data?.createdAt,
    };

    try {
      setLoading(true);
      const response = await fetchAppliedRule(funderId, requestBody);
      setRequestAmount(response?.requestAmount);
      setReceivableAmount(response?.receivableAmount);
      setDiscountRate(response?.discount);
      setPercentageLimit(response?.percentageLimit);
      setReceivableAmountWithoutDiscount(response?.receivableAmountWithoutDiscount);
      setDiscountRateWithRecourse(response?.discountRateWithRecourse)
      // if (!response?.requestAmount) {
      //   toast.error(response?.message);
      //   setTypingInputVal(true);
      //   setSelectedRequest("");
      // } 
    } catch (error) {
      console.error("Error fetching rule:", error);
      setTypingInputVal(false);
      setSelectedRequest("");
      // error?.response?.data?.message
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {(open || showConfirmModal) && (
        <div className="w-full fixed inset-0 bg-black bg-opacity-50 z-10"></div>
      )}
      {open && (
        <div className="absolute mt-[20px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[614px] h-[254px] z-10 rounded-[10px] shadow-lg">
          <EarlyPaymentConfirmation
            hide={() => setOpen(false)}
            isLoading={earlyPaymentLoading}
            handleAssociate={handleSendRequest}
            earlyPayment={true}
            invoice={true}
            warning={
              <>
                Would you like to proceed to use this account{" "}
                <span className="text-primaryColor">
                  {selectedAccount?.accountNumber}
                </span>{" "}
                to bid on invoice?
              </>
            }
          />
        </div>
      )}

      {showConfirmModal && (
        <div className="absolute mt-[20px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[614px] h-[254px] z-10 rounded-[10px] shadow-lg">
          <EarlyPaymentConfirmation
            hide={() => setShowConfirmModal(false)}
            isLoading={earlyPaymentLoading}
            handleAssociate={handleDirectFundingRequest}
            // earlyPayment={true}
            warning="Would you like to proceed with sending the Fund request without Recourse? "
          />
        </div>
      )}

      {showSuccess && (
        <SuccessEarlyPayment
          isOpen={showSuccess}
          onClose={() => setShowSuccess(false)}
          message="Direct funding request success"
          invoice={true}
        />
      )}

      {showReceivingFundModal && (
        <div>
          <ReceivingFundModal
            handleCloseVirtualAccount={handleCloseVirtualAccount}
            handleShowVirtualAccountSelection={
              handleShowVirtualAccountSelection
            }
            setShowReceivingFundModal={setShowReceivingFundModal}
            invoice={true}
          />
        </div>
      )}
      {showTransferModal && (
        <div>
          <TransferFundModal
            handleCloseVirtualAccount={handleCloseVirtualAccount}
            handleShowVirtualAccountSelection={
              handleShowVirtualAccountSelection
            }
            setShowReceivingFundModal={setShowReceivingFundModal}
            handleChangeAccount={handleChangeAccount}
            handleCloseTransferModal={handleCloseTransferModal}
            handleShowPin={handleShowPin}
            selectedAccount={selectedAccount}
            invoice={true}
          />
        </div>
      )}
      {showVirtualSelectionModal && (
        <div>
          <EarlyPaymentVirtualAccountSelection
            handleCloseVirtualAccount={handleCloseVirtualAccount}
            setOpen={setOpen}
            onClose={onClose}
            virtualAccounts={virtualAccounts}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            invoice={true}
          />
        </div>
      )}
      {showPin && (
        <PinModalPayment
          confirmPayment={confirmPayment}
          isOpen={showPin}
          onCancel={onClosePinModal}
          otpInputs={otpInputs}
          setOtpInputs={setOtpInputs}
          invoice={true}
        />
      )}

      {showViewMore && (
        <div style={{ width: "100%" }}>
          {" "}
          <ViewMore invoice={true} discountRateWithRecourse={discountRateWithRecourse} discount={discountRate} onClose={handleCloseViewMore} />
        </div>
      )}
      <div className="p-[39px]">
        <div className="flex justify-between">
          <div className="flex-1">
            <div className="flex items-center gap-x-[23px]">
              <h1 className="text-[#222222] text-[20px] font-semibold leading-[23.87px] font-sfpro">
                Invoice | INV - {data?.invoiceNo}
              </h1>
              <p className="text-textColor text-sm">
                Reference No: {data?.invoiceRef}
              </p>
            </div>
            <div>
              <span className="text-textColor text-xs mr-[10px]">
                Created Date: {invoiceDate}
              </span>

              <span className="text-textColor text-xs ml-[10px]">
                Payment Due Date: {data?.dueDate}
              </span>
            </div>
          </div>
          {!applied && (
            <Button
              neutral
              type="Button"
              disabled={!amount}
              onClick={handleOpenModal}
              className="border flex justify-center items-center border-[#AFAFAF] rounded-[5px] text-xs text-[#AFAFAF] w-[135px]  h-[30px]"
            >
              Send Request
            </Button>
          )}
          {applied && (
            <button
              onClick={handleShowReceivingFundModal}
              style={{ backgroundColor: "#F08000", color: "white" }}
              className="border flex justify-center items-center rounded-[5px] text-xs  w-[155px]  h-[30px]"
            >
              Select Receiving Account
            </button>
          )}
        </div>
        <div className="flex justify-between mt-[25px]">
          {applied && (
            <div>
              <p className="text-textColor font-extrabold text-base">
                Receivable Amount
              </p>
              <h1 className="text-primaryColor text-2xl font-extrabold">
                NGN {formatCurrency(Number(receivableAmount))}
              </h1>
              <h1 className="text-textColor text-sm font-extrabold mt-[19px]">
                Fund Request
              </h1>
            </div>
          )}
          {!applied && (
            <div>
              <p className="text-textColor font-extrabold text-base">
                Invoice Amount
              </p>
              <h1 className="text-primaryColor text-2xl font-extrabold">
                NGN {formatCurrency(Number(invoiceAmount))}
              </h1>
              {/* <h1 className="text-textColor text-sm font-extrabold mt-[19px]">
                Fund Request
              </h1> */}
            </div>
          )}
          <div>
            {typingInputVal && (
              <div className="flex justify-end">
                {/* <h4 style={{ fontSize: 13 }}>Request Percentage</h4> */}
              </div>
            )}
            {/* <h4 style={{ fontSize: 13 }}>Request Percentage</h4> */}
            <div>
              {/* <h4 style={{ fontSize: 13 }}>Request Percentage</h4> */}
              {typingInputVal ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h4 style={{ fontSize: 13 }}>Request Percentage</h4>
                  <span onClick={handleEdit}>{selectedRequest}%</span>


                </div>
              ) : (
                <div className="flex justify-end">
                  <InputField
                    value={selectedRequest}
                    onChange={handleInputChange}
                    disabled={!newPaymentDate || !description }
                    placeholder="Enter percentage"
                    className="border border-gray-300 rounded-md p-2"
                  />{" "}
                </div>
              )}

            </div>
            {applied && !isActivatedWithRecourse && (
              <div className="flex justify-end">
                <Button
                  neutral
                  onClick={handleSetNewAmount}
                  disabled={!requestAmount}
                  type="Button"
                  className="w-[107px] h-[24px] text-white bg-primaryColor text-xs rounded-[5px]"
                >
                  Set new amount
                </Button>
              </div>
            )}
            <br />
            <div>
              {selectedRequest ? null : (
                <span className="flex justify-end" style={{ fontSize: 13 }}>
                  Request Percentage
                </span>
              )}
            </div>
            <br />
            <div>
              {isActivatedWithRecourse && (
                <Button
                  neutral
                  type="Button"
                  disabled={!amount}
                  // onClick={handleOpenModal}
                  className="border border-orange-500 flex justify-center items-center rounded-[5px] text-xs text-[#AFAFAF] w-[205px] h-[30px]"
                >
                  Change your request percentage
                </Button>
              )}
            </div>
            <br />
            {!typingInputVal && <div className="flex justify-end ">
              <input
                onChange={handleCheckboxChange}
                // disabled={!requestAmount}
                type="checkbox"
                id="activeCheckbox"
                className="mr-2"
              />
              <label htmlFor="activeCheckbox" className="text-sm">
                Activate With Recourse
              </label>
            </div>}
          </div>
        </div>
        <div className="mt-[20px]">
          <div className="flex justify-between items-center">
            <InputField
              label="New Payment Date:"
              type="date"
              value={newPaymentDate}
              onChange={(e) => setNewPaymentDate(e.target.value)}
              className=" w-[369px] h-[39px] rounded-[5px] outline-none !border border-[#AFAFAF] px-[5px]"
            />
            <div style={{ marginTop: 0 }}>
              {!applied ? (
                <>
                  <div style={{ marginTop: selectedRequest ? 45 : 0 }}>
                    <InputField
                      label="Request Amount"
                      placeholder={"Please set your request percentage"}
                      // type="number"
                      value={requestAmount}
                      readOnly
                      className={`w-[369px] h-[39px] rounded-[5px] outline-none !border border-[#AFAFAF] px-[5px] bg-[#E5E5E5]`}
                    />
                  </div>
                  {selectedRequest && (
                    <div className="mt-[10px] flex justify-between">
                      <div>
                        <p className="text-textColor text-xs font-medium border-b border-[#AFAFAF] w-[84px]">
                          Discount:{" "}
                          <span className="text-[#17191C] text-xs">{isActivatedWithRecourse ? discountRateWithRecourse : discountRate}%</span>
                        </p>
                        <div
                          onClick={handleViewMoreClick}
                          className="flex items-center mt-[2px]"
                        >
                          <PAIconInfo />
                          <p
                            style={{ cursor: "pointer" }}
                            className="text-[#F08000] text-[10px] font-medium"
                          >
                            Tap to view more
                          </p>
                        </div>
                      </div>
                      <div>
                        <p className="text-textColor text-xs">
                        Receivable Amount: {formatCurrency(Number(receivableAmountWithoutDiscount))}
                          <span className="text-primaryColor text-xs font-extrabold border-b border-[#AFAFAF] pb-[2px]">
                            {discountedAmount && "N" + formatCurrency(Number(discountedAmount))}
                          </span>
                        </p>
                        <div className="flex justify-end mt-[5px]">
                          <Button
                            neutral
                            onClick={handleApply}
                            type="Button"
                            className="w-[87px] h-[24px] text-white bg-secondaryColor text-xs rounded-[5px]"
                          >
                            Apply
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="flex justify-end">
                  <div className="flex flex-col">
                    <span className="flex justify-end">Invoice Amount</span>
                    <span
                      style={{ fontSize: 28 }}
                      className="flex justify-end text-[#07593D]"
                    >
                      NGN {formatCurrency(Number(invoiceAmount))}
                    </span>
                    <span
                      className="flex justify-end text-[#F08000]"
                      style={{ textDecoration: "line-through" }}
                    >
                      NGN {formatCurrency(Number(requestAmount))}
                    </span>
                    <span
                      style={{ fontSize: 12 }}
                      className="flex justify-end text-[#F08000]"
                    >
                      {discountRate}%
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-textColor mb-[10px] text-xs" htmlFor="">
              Description
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="border outline-none border-[#AFAFAF] w-full rounded-[5px] h-[67px] p-[10px]"
            ></textarea>
          </div>

          <div className="mt-[28px] border-b-4 border-[#F6F5FA] pb-[12px]">
            <h1 className="text-textColor text-lg font-bold">
              Supplier/buyer details
            </h1>
          </div>
          <div className="flex justify-between mt-[16px]">
            <div>
              <p className="text-textColor text-sm italic font-extrabold">
                Supplier
              </p>
              <p className="text-lightGray text-xs"> {invoiceData?.virtualAccount?.firstName + " " + invoiceData?.virtualAccount?.lastName}</p>
            </div>
            <div>
              <p className="text-textColor text-sm italic font-extrabold">
                Buyer
              </p>
              <p className="text-lightGray text-xs">{data?.buyerCompanyName}</p>
            </div>
          </div>
          <div className="mt-[32px] border-b border-[#F6F5FA] pb-[12px]">
            <h1 className="text-[#11192A] text-sm font-bold">Line Items</h1>
          </div>
          <div className="mt-[28px] border-b-4 border-[#F6F5FA] pb-[12px]"></div>
          <div className=" w-full overflow-x-auto">
            <table className=" w-full overflow-x-auto">
              <thead className="bg-subtleGray w-">
                <tr className=" bg-unusualGray p-2.5 ">
                  <td className="text-[#11192A] p-2.5 text-xs font-bold">
                    Item Number
                  </td>
                  <td className=" p-2.5 text-xs font-bold">
                    Product/Service Description
                  </td>
                  <td className=" p-2.5 text-xs font-bold">Quantity</td>
                  <td className=" p-2.5 text-xs font-bold">Unit Price</td>
                  <td className=" p-2.5 text-xs font-bold">Total Amount</td>
                </tr>
              </thead>
              {data?.items?.map((item, index) => (
                <tbody key={index}>
                  <tr className=" py-[3px] px-1.5">
                    <td className=" text-xs text-[#11192A] p-2.5">INV-2002</td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {item?.description}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {item?.quantity}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {formatCurrency(Number(item?.unitPrice))}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {formatCurrency(Number(item?.amount))}
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DirectRequestModalContent;
