import { PAIconInventoryArrowLeft, PAIconSearch } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import Button from "shared/Button";
import { useNavigate } from "react-router-dom";
import { SelectContainer } from "shared/DeleteModal/Delete/styles";
import TableHeaderSelect from "shared/tableHeader/TableHeaderSelect";
import Table from "layout/table/Table";
import ThreeDots from "../../assets/images/svgs/option.svg";
import { useEffect, useState } from "react";
import { buyerWithActivatedEarlyPayment } from "appstate/api/invoice/invoice";
import Loading from "shared/Loading";

const BulkEarlyPaymentRequestFlow = () => {
  const navigate = useNavigate();
  const [buyers, setBuyers] = useState([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBuyers = async () => {
      try {
        const result = await buyerWithActivatedEarlyPayment();
        setBuyers(result?.data || []);
      } catch (error) {
        console.error("Error fetching buyer data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBuyers();
  }, []);

  function handleNavigate() {
    navigate(-1);
  }

  const onRowClicked = (row) => {
    const buyerId = row?.userId;
    navigate("/bulk-early-payment-invoice-details", { state: { buyerId, row } });
  };
  

  const column = [
    {
      name: "Name",
      selector: (row) => row?.buyerName,
    },
    {
      name: "Company",
      selector: (row) => row?.companyName,
    },
    {
      name: "Email",
      selector: (row) => row?.companyEmail,
    },
    {
      name: "Phone",
      selector: (row) => row?.companyPhoneNumber,
    },
    {
      name: "No of invoices",
      selector: (row) => row?.noOfInvoice,
    },
    {
      cell: (row) => (
        <div className="relative">
          <img
            src={ThreeDots}
            alt="Options"
            // onClick={() => handleThreeDotsClick(row, row?.email)}
            className="cursor-pointer"
          />
        </div>
      ),
    },
  ];

  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      <div>
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
          <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconInventoryArrowLeft />
            <p className="mt-[2px] text-sm font-normal text-lightGray">
              Go Back
            </p>
          </div>
          <div className="flex justify-between flex-wrap ">
            <div>
              <p className="text-black text-sm font-medium">All buyers</p>
              <p className="text-textColor text-xs font-normal">
                List of all buyers that have activated early payment request
              </p>
            </div>
          </div>
          <br />
          <hr />
          <br />
          <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
            <div className="flex items-center  bg-white p-[10px] gap-x-[8px] rounded-[5px] border-[0.5px] border-silver xl:w-[350px]">
              <PAIconSearch />
              <input
                type="text"
                //   value={companyName}
                //   onChange={(e) => setCompanyName(e.target.value)}
                placeholder="Search funders"
                className="placeholder:text-xs outline-none"
              />
            </div>
            <div className="pr-2">
              <SelectContainer>
                <TableHeaderSelect />
              </SelectContainer>
            </div>
            <Button
              neutral
              // onClick={handleSearch}
              // disabled={data < 1}
              className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-brightGray text-sm !text-black rounded-[5px] font-medium"
            >
              Search
            </Button>
          </div>
        </header>
      </div>
      <div className="p-[24px] bg-white rounded-b-[10px]">
        {loading ? (
          <div className="flex justify-center">
            <Loading />
          </div>
        ) : (
          <Table
            columns={column}
            pointer
            data={buyers}
            onRowClicked={onRowClicked}
          />
        )}
      </div>
    </DashboardLayout>
  );
};
export default BulkEarlyPaymentRequestFlow;
