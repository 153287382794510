import { PAIconAlert24 } from "assets/images/svgs";
import React from "react";
import { motion, AnimatePresence } from "framer-motion";

const ImportInvoicesModal = ({ isOpen, onClose, handleImportInvoice }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <motion.div
            className="bg-white p-5 rounded-md shadow-md flex flex-col items-center px-10"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <PAIconAlert24 />
            <p className="text-[#979797] text-center font-sans text-base font-normal leading-normal tracking-wide mb-3 my-5">
              <span>Are you sure you would like to import these invoices?</span>
            </p>
            <div className="flex justify-center mt-3">
              <button
                className="px-5 py-2 mr-5 text-[#07593D] border border-[#07593D] rounded-md"
                onClick={handleImportInvoice}
              >
                Yes
              </button>
              <button
                className="px-5 py-2 bg-red-500 border border-red text-red rounded-md"
                onClick={onClose}
              >
                No
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ImportInvoicesModal;
