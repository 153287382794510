import { PAIconCocacola, PAIconArrowLeftGreen } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import DirectFundingAcceptModal from "features/invoice/directfunding/modals/DirectFundingAcceptModal";
import { useState, useEffect } from "react";

import DirectFundingBidSelectAccountModal from "features/invoice/directfunding/modals/DirectFundingBidSelectAccountModal";

import BidPinModal from "features/payableFinaceForFunder/CloseMarket/modals/BidPinModal";
import BidProceedModal from "features/payableFinaceForFunder/CloseMarket/modals/BidProceedModal";
import BidLoaderModal from "features/payableFinaceForFunder/CloseMarket/modals/BidLoaderModla";
import DirectFundingPayBidSuccessOnModal from "features/invoice/directfunding/modals/DirectFundingPaySucessOnBoardModal";
import DirectFundingRejectModal from "features/invoice/directfunding/modals/DirectFundingRejectModal";
import ReasonForRequiredModal from "features/invoice/directfunding/modals/ResaonRequired";
import DirectFundingDeclinedModal from "features/invoice/directfunding/modals/DirectFundingDeclined";
import Table from "layout/table/Table";
import ConfirmDirectFundingModalBulk from "features/invoice/directfunding/modals/ConfirmDirectFundingModalBulk2";
import BulkAcceptFirstModal from "features/invoice/directfunding/modals/BulkAcceptFirstModal";
import { useDispatch, useSelector } from "react-redux";
import { funderEarlyPaymentViewBulkApprovedDetails } from "appstate/funder/funderSlice";

const ApprovedEarlyPaymentFundingBulkDetailsAccepted = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateToDetail = (id) =>
    navigate(
      `/payablefinance-earlypayment/approved-funder-early-payment-bulk-inner-details-page/${id}`
    );

  function handleNavigate() {
    let path = -1;
    navigate(path);
  }
  const [acceptModal, setAcceptModal] = useState(false);
  const [acceptFirstModal, setAcceptFirstModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [bidSelectAccountModal, setBidSelectAccountModal] = useState(false);
  const { getInvoiceId, funderEarlyPaymentViewBulkApprovedDetailsData } =
    useSelector((state) => state?.funder);
  const [bidProceed, setBidProceed] = useState(false);
  const [bidPin, setBidPin] = useState(false);
  const [bidLoaderModal, setBidLoaderModal] = useState(false);
  const [bidSuccess, setBidSuccess] = useState(false);
  const [reasonFor, setReasonfor] = useState(false);
  const [directRejectModal, setDirectRejectModal] = useState(false);
  const [editable, setEditable] = useState(false);
  const [selectedRow] = useState(null);
  const [disCountPer, setDisCountPer] = useState("");
  const [data, setData] = useState([]);

  // const handleRowClick = (row) => {
  //   setSelectedRow(row);
  //   setDisCountPer(row.discountRate);
  //   setConfirmModal(true);
  //   setEditable(true);
  //   // navigate(`/funder-direct-funding-bulk-inner-details-page/${row.id}`);
  // };

  useEffect(() => {
    dispatch(
      funderEarlyPaymentViewBulkApprovedDetails(getInvoiceId?.earlyPaymentId)
    );
  }, [dispatch, getInvoiceId?.earlyPaymentId]);

  useEffect(() => {
    setData(funderEarlyPaymentViewBulkApprovedDetailsData?.data);
  }, [funderEarlyPaymentViewBulkApprovedDetailsData]);

  const handleDiscountChange = (e) => {
    setDisCountPer(e.target.value);
    if (selectedRow) {
      setTableData((prevData) =>
        prevData.map((item) =>
          item.bidAmount === selectedRow.bidAmount
            ? { ...item, discountRate: e.target.value }
            : item
        )
      );
    }
  };

  const handleSave = () => {
    setTableData((prevData) =>
      prevData.map((item) =>
        item.bidAmount === selectedRow.bidAmount
          ? { ...item, discountRate: disCountPer }
          : item
      )
    );
    setConfirmModal(false);
  };

  const handleProceed = () => {
    setBidProceed(false);

    setTimeout(() => {
      setBidPin(true);
    }, 300);
  };

  const handleOnselectModal = () => {
    setBidSelectAccountModal(false);

    setTimeout(() => {
      setBidProceed(true);
    }, 300);
  };
  const handlePinModal = () => {
    setBidPin(false);
    setBidLoaderModal(true);
  };

  const handleYes = () => {
    setAcceptModal(false);
    setBidSelectAccountModal(true);
  };

  const handleYesFirst = () => {
    setAcceptFirstModal(false);
    setAcceptModal(true);
  };
  const handleReject = () => {
    setRejectModal(false);
    setReasonfor(true);
  };

  const handleBidSuccessClose = () => {
    setBidSuccess(false);
    navigate(-1);
  };
  const handleContinue = () => {
    setReasonfor(false);
    setDirectRejectModal(true);
  };
  const handleDeclineClose = () => {
    setDirectRejectModal(false);
    handleNavigate();
  };

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Ref Number
        </span>
      ),
      selector: (row) => row.invoiceRef,
    },

    {
      name: "Request Date",
      selector: (row) => row.requestDate,
    },

    {
      name: "Request Amount",
      selector: (row) => row.requestAmount,
    },
    {
      name: "Discount Rate",
      selector: (row) => row.discountRate,
    },
    {
      name: "Receivable Amount",
      selector: (row) => row.payableAmount,
    },
  ];

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(data?.earlyPaymentInvoiceResponse);
  }, [data?.earlyPaymentInvoiceResponse]);

  return (
    <>
      <TransitionScale>
        <DirectFundingAcceptModal
          mess1={"Would you like to proceed with "}
          blueText={"Approving "}
          mess2={" this Direct "}
          mess3={"Funding request?"}
          isOpen={acceptModal}
          onClose={() => setAcceptModal(false)}
          handleYes={handleYes}
        />
      </TransitionScale>
      <TransitionScale>
        <BulkAcceptFirstModal
          mess1={"Do you agree with the current  "}
          mess2={"discount rate "}
          mess3={" applied to each invoice?"}
          isOpen={acceptFirstModal}
          onClose={() => setAcceptFirstModal(false)}
          handleYes={handleYesFirst}
        />
      </TransitionScale>
      <TransitionScale>
        <DirectFundingRejectModal
          mess1={"Would you like to proceed with "}
          blueText={"Rejecting "}
          mess2={" this Bulk "}
          mess3={" Direct Funding request?"}
          isOpen={rejectModal}
          onClose={() => setRejectModal(false)}
          handleYes={handleReject}
        />
      </TransitionScale>

      <TransitionScale>
        <ConfirmDirectFundingModalBulk
          isOpen={confirmModal}
          onClose={() => setConfirmModal(false)}
          disValue={disCountPer}
          handleChange={handleDiscountChange}
          handleCancel={() => setConfirmModal(false)}
          handleSave={handleSave}
          handleNext={() => setConfirmModal(false)}
          editable={editable}
          setEditable={setEditable}
          onBlur={() => setEditable(false)}
        />
      </TransitionScale>
      <TransitionScale>
        <DirectFundingBidSelectAccountModal
          isOpen={bidSelectAccountModal}
          onClose={() => setBidSelectAccountModal(false)}
          handleProceed={handleOnselectModal}
          message={"Select which account you want to use for this Request"}
        />
      </TransitionScale>

      <TransitionScale>
        {bidLoaderModal && (
          <BidLoaderModal
            isOpen={bidLoaderModal}
            onClose={() => setBidLoaderModal(false)}
            onAction={() => {
              setBidLoaderModal(false);
              setTimeout(() => {
                setBidSuccess(true);
              }, 300);
            }}
            statusText={"Fund Invoice"}
          />
        )}{" "}
      </TransitionScale>

      <TransitionScale>
        {" "}
        <BidPinModal
          isOpen={bidPin}
          onClose={() => setBidPin(false)}
          handleNext={handlePinModal}
        />
      </TransitionScale>
      <TransitionScale>
        {" "}
        <BidProceedModal
          isOpen={bidProceed}
          refNum={" 7364738921"}
          onClose={() => setBidProceed(false)}
          handleYes={handleProceed}
        />
      </TransitionScale>
      <TransitionScale>
        <DirectFundingPayBidSuccessOnModal
          isOpen={bidSuccess}
          onClose={handleBidSuccessClose}
          message={"Early payment request sent for"}
          sucess={"workflow approval"}
        />
      </TransitionScale>
      {reasonFor && (
        <TransitionScale>
          <ReasonForRequiredModal
            onClose={() => setReasonfor(false)}
            onContinue={handleContinue}
          />
        </TransitionScale>
      )}
      <TransitionScale>
        <DirectFundingDeclinedModal
          isOpen={directRejectModal}
          onClose={handleDeclineClose}
        />
      </TransitionScale>

      <DashboardLayout
        section="Early Payment Request"
        subHeader="Manage all your Early payment requests"
      >
        <header>
          <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconArrowLeftGreen />
            <span className="flex items-center text-primaryColor">Go Back</span>
          </div>
        </header>

        <div className="grid lg:grid-cols-[60%,40%] gap-5">
          <TransitionScale className=" pb-5 bg-white  rounded-[10px] mt-5 pt-2 ">
            {/* Header with Left Padding */}

            {/* Other sections */}

            <section className="  mt-3">
              {/* Down Flex Texts */}
              <div class=" justify-between items-center inline-flex mt-2 px-6 w-full">
                <div class=" justify-between items-center inline-flex mt-2 w-[45%]">
                  <div class="text-[#222222] text-base font-semibold">
                    Reference No:
                  </div>
                  <div class="text-[#222222] text-base font-semibold ">
                    | {data?.requestReferenceNumber}
                  </div>
                </div>
                <button
                  className="h-[41px] px-6 py-3 bg-[#07593d] rounded-[5px] justify-center items-center gap-2.5 inline-flex"
                  onClick={() => navigate("/disbursement")}
                >
                  <div className="text-white text-sm font-medium ">
                    Disburse Now
                  </div>
                </button>
              </div>
              <div className=" flex justify-between px-6">
                {/* first stage */}

                <div class="lg:w-[240px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex">
                  <div class="self-stretch  flex-col justify-center items-start gap-1 flex mt-2">
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Request Date:
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {data?.requestDate}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex ">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Total Request amount
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {data?.totalRequestAmount?.toLocaleString()}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Total Payable amount
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {data?.totalPayableAmount?.toLocaleString()}
                      </div>
                    </div>
                  </div>

                  {/* stop */}
                </div>
                {/* second stage */}
                <div className="flex justify-between items-center  ">
                  <div class="h-[30px] px-8 py-2 bg-[#2ea923]/20 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                    <div class="text-center text-[#2ea923] text-xs font-semibold ">
                      Approved
                    </div>
                  </div>{" "}
                </div>
              </div>

              {/* stops here */}
              <div className="  px-6   ">
                <header className="border-b-[5px] border-b-[#F6F5FA]  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                    Supplier/buyer details
                  </h1> */}
                  <div>
                    <div class="text-[#222222] text-lg font-semibold  ">
                      {data?.buyerCompanyName}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-light  ">
                      Buyer details
                    </div>
                  </div>

                  <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                    <div class="text-[#222222] text-lg font-semibold ">NGN</div>
                    <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                      Currency
                    </div>
                  </div>
                </header>

                <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                    Supplier/buyer details
                  </h1> */}
                  <div class="text-[#11192a] text-sm font-semibold ">
                    Invoice List
                  </div>
                  <div class="text-[#11192a] text-lg font-light  mr-6">
                    {data?.earlyPaymentInvoiceResponse?.length} Invoices
                  </div>
                </header>

                {/* <div className=" w-full overflow-x-auto mt-3 pb-4"></div> */}

                <div className=" w-full overflow-x-auto mt-3 ">
                  <Table
                    columns={columns}
                    data={tableData}
                    pointer
                    className=""
                    // onRowClicked={(row) => navigateToDetail(row.bidAmount)}
                    onRowClicked={(row) => navigateToDetail(row.bidAmount)}
                    progressPending={false}
                    // progressComponent={<Loading />}
                  />
                </div>
                {/* Calculate cost and price  shaded or gray section */}
              </div>
            </section>
          </TransitionScale>
          {/* stops the best here */}

          <div className="bg-white rounded-[10px] gap-8  mt-5">
            {/* <FundDetailSide /> */}
            <div class="w-full justify-start items-center gap-4 inline-flex ">
              <div class=" justify-start items-center gap-2.5 flex w-full px-6  py-3">
                <div class="text-black text-xl font-bold  mt-5">
                  Early Payment Details
                </div>
              </div>
            </div>

            <div>
              {" "}
              {/* second */}
              <div className="   pb-4">
                <div class=" justify-between  flex flex-row    px-6 mt-4  ">
                  <div className="flex gap-2 py-2.5">
                    <PAIconCocacola />
                    <div className="flex flex-col">
                      {/* <span className=" text-black text-xs font-normal">
                      Coca Cola
                    </span> */}
                      <span class="text-black text-sm font-normal ">
                        {data?.buyerCompanyName}
                      </span>

                      <span class="text-[#949494] text-sm font-normal ">
                        Buyer
                      </span>
                    </div>
                  </div>

                  {/* here */}
                </div>
                <div class=" justify-between  flex flex-row    px-6  mt-6 ">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        NGN {data?.totalDiscountAmount}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Discount amount
                      </div>
                    </div>
                  </div>
                  {/* hd */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        {data?.requestDate}
                      </div>
                    </div>
                    <div class="flex-col justify-end items-end gap-0.5  w-full  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Early Payment Date
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div class=" justify-between  flex flex-row    border-b border-[#c2c2c2]/30 px-6  py-3  pb-12 mt-6">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        NGN {data?.totalRequestAmount?.toLocaleString()}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Total request amount
                      </div>
                    </div>
                  </div>
                  {/* dhdh */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col  gap-0.5  w-full flex">
                      <div class="text-black text-sm font-normal ">
                        NGN {data?.totalPayableAmount?.toLocaleString()}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Total Payable Amount
                      </div>
                    </div>
                  </div>
                </div>
                {/* 2nd */}
                <div class=" justify-between  flex flex-col    border-b border-[#c2c2c2]/30 px-6  pb-10 ">
                  <div class="text-black text-lg font-semibold  mt-6">
                    Note from Supplier:{" "}
                  </div>

                  <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                    {data?.description}
                  </div>
                </div>{" "}
                <div class=" justify-between  flex flex-col    px-6  ">
                  <div class="text-black text-lg font-semibold  mt-6">
                    Funding Option
                  </div>

                  <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                    This invoice would be funded from this virtual account:
                  </div>

                  <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                    {data?.funderVirtualAccountName}
                  </div>

                  <div className="text-[#222222] font-light mt-2 text-[16px] ">
                    {data?.funderVirtualAccountNumber}
                  </div>
                  <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                    {data?.funderBankName}
                  </div>

                  {/* stops */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};
export default ApprovedEarlyPaymentFundingBulkDetailsAccepted;
