import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllOpenMarketInvoices,
  placeBidOnInvoice,
  lockAnInvoice,
  updateAnInvoice,
  invoiceByStatus,
  funderDashBoard,
  cancelInvoiceBid,
  invoiceByID,
  supplierOpenMarket,
  supplierOpenMarketByStatus,
  invoiceBidByID,
  updateBidOfferStatus,
  getAllBidOffers,
  invoiceImportIntoOpenMarket,
  importInvoiceOpenMarketApi,
  availableOpenMarketInvoiceApi,
  findAllBuyerOpenMarket,
  findFundedBuyerOpenMarket,
  pendingBuyerConfirmationSupplierApi,
  getSupplierOpenMarketDashboardApi,
  getAllSupplierOpenMarketInvoicesApi,
  lockedInvoicesSupplierApi,
  approveOpenMarketFunding,
  pendingBuyerOpenMarket,
  buyerOpenMarketDetails,
  cancelSupplierPendingConfirmation,
  getAllBidDetails,
  getBidHistory,
  funderWonBids,
  getInvoicesInOpenMarketFunderApi,
  ongoingBidOpenMarketFunderApi,
  funderPlaceBidOpenMarketApi,
  getOpenMarketPercentageLimitApi,
  funderUpdateOpenMarketBidApi,
  funderDetailsOpenMarketBidApi,
  funderOpenMarketMetricsApi,
  getAllFunderListClosedMarketApi,
  getCurrentLockRateApi,
  updateFunderOpenMarketConfigApi,
  getFunderOpenMarketConfigApi,
} from "appstate/api/openMarket";

const initialState = {
  isLoading: false,
  allOpenMarketInvoicesData: [],
  getInvoiceBidByStatusData: [],
  funderDashboardData: [],
  invoiceByIdData: [],
  getAllSupplierOpenMarketData: [],
  getAllSupplierOpenMarketByStatusData: [],
  getBidInvoiceByIdData: [],
  getAllBidsData: [],
  availableOpenMarketInvoiceData: [],
  importLoading: false,
  allBuyerOpenMarketData: [],
  fundedBuyerOpenMarketData: [],
  pendingBuyerConfirmationSupplierData: [],
  getSupplierOpenMarketDashboardData: [],
  getAllSupplierOpenMarketInvoicesData: [],
  lockedInvoicesSupplierData: [],
  buyerOpenMarketPendingData: [],
  detailsBuyerOpenMarketData: [],
  supplierAllBidDetailsData: [],
  bidHistoryData: [],
  wonBidsFunderData: [],
  getInvoicesInOpenMarketFunderData: [],
  ongoingBidOpenMarketFunderData: [],
  getOpenMarketPercentageLimitData: [],
  funderDetailsOpenMarketBidData: [],
  funderOpenMarketMetricsData: [],
  getAllFunderListClosedMarketData: [],
  getCurrentLockRateData: [],
  getFunderOpenMarketConfigData: [],
};

export const allOpenMarketInvoices = createAsyncThunk(
  "openMarket/allOpenMarketInvoices",
  getAllOpenMarketInvoices
);

export const availableOpenMarketInvoice = createAsyncThunk(
  "openMarket/availableOpenMarketInvoice",
  availableOpenMarketInvoiceApi
);

export const getAllSupplierOpenMarket = createAsyncThunk(
  "openMarket/getAllSupplierOpenMarket",
  supplierOpenMarket
);

export const pendingBuyerConfirmationSupplier = createAsyncThunk(
  "openMarket/pendingBuyerConfirmationSupplier",
  pendingBuyerConfirmationSupplierApi
);
export const getCurrentLockRate = createAsyncThunk(
  "openMarket/getCurrentLockRate",
  getCurrentLockRateApi
);

export const lockedInvoicesSupplier = createAsyncThunk(
  "openMarket/lockedInvoicesSupplier",
  lockedInvoicesSupplierApi
);

export const getSupplierOpenMarketDashboard = createAsyncThunk(
  "openMarket/getSupplierOpenMarketDashboard",
  getSupplierOpenMarketDashboardApi
);

export const getAllSupplierOpenMarketInvoices = createAsyncThunk(
  "openMarket/getAllSupplierOpenMarketInvoices",
  getAllSupplierOpenMarketInvoicesApi
);

export const getAllBids = createAsyncThunk(
  "openMarket/getAllBids",
  getAllBidOffers
);

export const getAllSupplierOpenMarketByStatus = createAsyncThunk(
  "openMarket/getAllSupplierOpenMarketByStatus",
  supplierOpenMarketByStatus
);

export const funderDashboard = createAsyncThunk(
  "openMarket/funderDashboard",
  funderDashBoard
);

export const wonBidsFunder = createAsyncThunk(
  "openMarket/wonBidsFunder",
  funderWonBids
);

export const placeInvoiceBid = createAsyncThunk(
  "openMarket/placeInvoiceBid",
  placeBidOnInvoice
);

export const lockInvoice = createAsyncThunk(
  "openMarket/lockInvoice",
  lockAnInvoice
);

export const updateInvoice = createAsyncThunk(
  "openMarket/updateInvoice",
  updateAnInvoice
);

export const cancelBid = createAsyncThunk(
  "openMarket/cancelBid",
  cancelInvoiceBid
);

export const updateBidStatus = createAsyncThunk(
  "openMarket/updateBidStatus",
  updateBidOfferStatus
);
export const updateFunderOpenMarketConfig = createAsyncThunk(
  "openMarket/updateFunderOpenMarketConfig",
  updateFunderOpenMarketConfigApi
);
export const getFunderOpenMarketConfig = createAsyncThunk(
  "openMarket/getFunderOpenMarketConfig",
  getFunderOpenMarketConfigApi
);

export const getInvoiceBidByStatus = createAsyncThunk(
  "openMarket/getInvoiceBidByStatus",
  invoiceByStatus
);
export const getInvoiceById = createAsyncThunk(
  "openMarket/getInvoiceById",
  invoiceByID
);

export const getBidInvoiceById = createAsyncThunk(
  "openMarket/getBidInvoiceById",
  invoiceBidByID
);

export const importInvoiceIntoOpenMarket = createAsyncThunk(
  "openMarket/importInvoiceIntoOpenMarket",
  invoiceImportIntoOpenMarket
);

export const importInvoiceOpenMarket = createAsyncThunk(
  "openMarket/importInvoiceOpenMarket",
  importInvoiceOpenMarketApi
);

export const cancelPendingConfirmation = createAsyncThunk(
  "openMarket/cancelPendingConfirmation",
  cancelSupplierPendingConfirmation
);

export const supplierAllBidDetails = createAsyncThunk(
  "openMarket/supplierAllBidDetails",
  getAllBidDetails
);

export const bidHistory = createAsyncThunk(
  "openMarket/bidHistory",
  getBidHistory
);

export const getOpenMarketPercentageLimit = createAsyncThunk(
  "openMarket/getOpenMarketPercentageLimit",
  getOpenMarketPercentageLimitApi
);

// // // // )// // // // // // // BUYER OPEN MARKET // // // // // // // // // // // //
export const allBuyerOpenMarket = createAsyncThunk(
  "openMarket/allBuyerOpenMarket",
  findAllBuyerOpenMarket
);

export const fundedBuyerOpenMarket = createAsyncThunk(
  "openMarket/fundedBuyerOpenMarket",
  findFundedBuyerOpenMarket
);

export const confirmBuyerOpenMarket = createAsyncThunk(
  "openMarket/confirmBuyerOpenMarket",
  approveOpenMarketFunding
);

export const buyerOpenMarketPending = createAsyncThunk(
  "openMarket/buyerOpenMarketPending",
  pendingBuyerOpenMarket
);

export const detailsBuyerOpenMarket = createAsyncThunk(
  "openMarket/detailsBuyerOpenMarket",
  buyerOpenMarketDetails
);
export const getInvoicesInOpenMarketFunder = createAsyncThunk(
  "openMarket/getInvoicesInOpenMarketFunder",
  getInvoicesInOpenMarketFunderApi
);

export const ongoingBidOpenMarketFunder = createAsyncThunk(
  "openMarket/ongoingBidOpenMarketFunder",
  ongoingBidOpenMarketFunderApi
);

export const funderPlaceBidOpenMarket = createAsyncThunk(
  "openMarket/funderPlaceBidOpenMarket",
  funderPlaceBidOpenMarketApi
);

export const funderUpdateOpenMarketBid = createAsyncThunk(
  "openMarket/funderUpdateOpenMarketBid",
  funderUpdateOpenMarketBidApi
);

export const funderDetailsOpenMarketBid = createAsyncThunk(
  "openMarket/funderDetailsOpenMarketBid",
  funderDetailsOpenMarketBidApi
);

export const funderOpenMarketMetrics = createAsyncThunk(
  "openMarket/funderOpenMarketMetrics",
  funderOpenMarketMetricsApi
);

export const getAllFunderListClosedMarket = createAsyncThunk(
  "openMarket/getAllFunderListClosedMarket",
  getAllFunderListClosedMarketApi
);

export const openMarketSlice = createSlice({
  name: "openMarket",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(allOpenMarketInvoices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allOpenMarketInvoices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allOpenMarketInvoicesData = action.payload;
    });
    builder.addCase(allOpenMarketInvoices.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(availableOpenMarketInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(availableOpenMarketInvoice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.availableOpenMarketInvoiceData = action.payload;
    });
    builder.addCase(availableOpenMarketInvoice.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(lockedInvoicesSupplier.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(lockedInvoicesSupplier.fulfilled, (state, action) => {
      state.isLoading = false;
      state.lockedInvoicesSupplierData = action.payload;
    });
    builder.addCase(lockedInvoicesSupplier.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getAllSupplierOpenMarketInvoices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllSupplierOpenMarketInvoices.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.getAllSupplierOpenMarketInvoicesData = action.payload;
      }
    );
    builder.addCase(getAllSupplierOpenMarketInvoices.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getAllBids.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllBids.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getAllBidsData = action.payload;
    });
    builder.addCase(getAllBids.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(funderPlaceBidOpenMarket.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(funderPlaceBidOpenMarket.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(funderPlaceBidOpenMarket.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(pendingBuyerConfirmationSupplier.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      pendingBuyerConfirmationSupplier.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.pendingBuyerConfirmationSupplierData = action.payload;
      }
    );
    builder.addCase(pendingBuyerConfirmationSupplier.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getCurrentLockRate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCurrentLockRate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getCurrentLockRateData = action.payload;
    });
    builder.addCase(getCurrentLockRate.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getAllSupplierOpenMarket.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllSupplierOpenMarket.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getAllSupplierOpenMarketData = action.payload;
    });
    builder.addCase(getAllSupplierOpenMarket.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getAllSupplierOpenMarketByStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllSupplierOpenMarketByStatus.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.getAllSupplierOpenMarketByStatusData = action.payload;
      }
    );
    builder.addCase(getAllSupplierOpenMarketByStatus.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getInvoiceBidByStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getInvoiceBidByStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getInvoiceBidByStatusData = action.payload;
    });
    builder.addCase(getInvoiceBidByStatus.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getInvoiceById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getInvoiceById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.invoiceByIdData = action.payload;
    });
    builder.addCase(getInvoiceById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getSupplierOpenMarketDashboard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getSupplierOpenMarketDashboard.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.getSupplierOpenMarketDashboardData = action.payload;
      }
    );
    builder.addCase(getSupplierOpenMarketDashboard.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getBidInvoiceById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBidInvoiceById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getBidInvoiceByIdData = action.payload;
    });
    builder.addCase(getBidInvoiceById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(cancelBid.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(cancelBid.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(cancelBid.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(funderDashboard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(funderDashboard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.funderDashboardData = action.payload;
    });
    builder.addCase(funderDashboard.rejected, (state) => {
      state.isLoading = false;
    });

    // FUNDER WON BIDS
    builder.addCase(wonBidsFunder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(wonBidsFunder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.wonBidsFunderData = action.payload;
    });
    builder.addCase(wonBidsFunder.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(placeInvoiceBid.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(placeInvoiceBid.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(placeInvoiceBid.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(lockInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(lockInvoice.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(lockInvoice.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateInvoice.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateInvoice.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateBidStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateBidStatus.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateBidStatus.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateFunderOpenMarketConfig.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateFunderOpenMarketConfig.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateFunderOpenMarketConfig.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getFunderOpenMarketConfig.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFunderOpenMarketConfig.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getFunderOpenMarketConfigData = action.payload;
    });
    builder.addCase(getFunderOpenMarketConfig.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(importInvoiceIntoOpenMarket.pending, (state) => {
      state.importLoading = true;
    });
    builder.addCase(importInvoiceIntoOpenMarket.fulfilled, (state) => {
      state.importLoading = false;
    });
    builder.addCase(importInvoiceIntoOpenMarket.rejected, (state) => {
      state.importLoading = false;
    });

    builder.addCase(importInvoiceOpenMarket.pending, (state) => {
      state.importLoading = true;
    });
    builder.addCase(importInvoiceOpenMarket.fulfilled, (state) => {
      state.importLoading = false;
    });
    builder.addCase(importInvoiceOpenMarket.rejected, (state) => {
      state.importLoading = false;
    });
    // // // // /// // // // // // // // SUPPLIER CANCEL OPEN MARKET // // // // // // // // // // // //
    builder.addCase(cancelPendingConfirmation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(cancelPendingConfirmation.fulfilled, (state) => {
      state.isLoading = false;
      // state.cancelPendingConfirmationData = action.payload;
    });
    builder.addCase(cancelPendingConfirmation.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // /// // // // // // // // SUPPLIER GET ALL BID DETAILS OPEN MARKET // // // // // // // // // // // //
    builder.addCase(supplierAllBidDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(supplierAllBidDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplierAllBidDetailsData = action.payload;
    });
    builder.addCase(supplierAllBidDetails.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // /// // // // // // // // SUPPLIER BID HISTORY OPEN MARKET // // // // // // // // // // // //
    builder.addCase(bidHistory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(bidHistory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bidHistoryData = action.payload;
    });
    builder.addCase(bidHistory.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // // // // // // // // BUYER OPEN MARKET // // // // // // // // // // //
    builder.addCase(allBuyerOpenMarket.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allBuyerOpenMarket.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allBuyerOpenMarketData = action.payload;
    });
    builder.addCase(allBuyerOpenMarket.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // /// // // // // // // // FUNDED BUYER OPEN MARKET // // // // // // // // // // // //
    builder.addCase(fundedBuyerOpenMarket.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fundedBuyerOpenMarket.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fundedBuyerOpenMarketData = action.payload;
    });
    builder.addCase(fundedBuyerOpenMarket.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // /// // // // // // // // CONFIRM BUYER OPEN MARKET // // // // // // // // // // // //
    builder.addCase(confirmBuyerOpenMarket.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(confirmBuyerOpenMarket.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.fundedBuyerOpenMarketData = action.payload;
    });
    builder.addCase(confirmBuyerOpenMarket.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // /// // // // // // // // PENDING BUYER OPEN MARKET // // // // // // // // // // // //
    builder.addCase(buyerOpenMarketPending.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(buyerOpenMarketPending.fulfilled, (state, action) => {
      state.isLoading = false;
      state.buyerOpenMarketPendingData = action.payload;
    });
    builder.addCase(buyerOpenMarketPending.rejected, (state) => {
      state.isLoading = false;
    });
    // // // // /// // // // // // // // DETAILS BUYER OPEN MARKET // // // // // // // // // // // //
    builder.addCase(detailsBuyerOpenMarket.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(detailsBuyerOpenMarket.fulfilled, (state, action) => {
      state.isLoading = false;
      state.detailsBuyerOpenMarketData = action.payload;
    });
    builder.addCase(detailsBuyerOpenMarket.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getInvoicesInOpenMarketFunder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getInvoicesInOpenMarketFunder.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.getInvoicesInOpenMarketFunderData = action.payload;
      }
    );
    builder.addCase(getInvoicesInOpenMarketFunder.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(ongoingBidOpenMarketFunder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(ongoingBidOpenMarketFunder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.ongoingBidOpenMarketFunderData = action.payload;
    });
    builder.addCase(ongoingBidOpenMarketFunder.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getOpenMarketPercentageLimit.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOpenMarketPercentageLimit.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getOpenMarketPercentageLimitData = action.payload;
    });
    builder.addCase(getOpenMarketPercentageLimit.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(funderDetailsOpenMarketBid.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(funderDetailsOpenMarketBid.fulfilled, (state, action) => {
      state.isLoading = false;
      state.funderDetailsOpenMarketBidData = action.payload;
    });
    builder.addCase(funderDetailsOpenMarketBid.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(funderOpenMarketMetrics.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(funderOpenMarketMetrics.fulfilled, (state, action) => {
      state.isLoading = false;
      state.funderOpenMarketMetricsData = action.payload;
    });
    builder.addCase(funderOpenMarketMetrics.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getAllFunderListClosedMarket.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllFunderListClosedMarket.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getAllFunderListClosedMarketData = action.payload;
    });
    builder.addCase(getAllFunderListClosedMarket.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(funderUpdateOpenMarketBid.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(funderUpdateOpenMarketBid.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(funderUpdateOpenMarketBid.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default openMarketSlice.reducer;
