import React, { useState } from "react";
import VirtualSelectedAccount from "./VirtualSelectedAccount";
import EmptyWallet from "../../../../../../assets/images/svgs/empty-wallet.svg";
import { formatCurrency } from "utilities/helper/formatters";

const BulkVsa = ({
  handleCloseVirtualAccount,
  setOpen,
  onClose,
  virtualAccounts, 
  selectedAccount, 
  setSelectedAccount, 
}) => {
  const [showSelectedAccountModal, setShowSelectedAccountModal] =
    useState(false);
  const [showAccountSelectionModal, setShowAccountSelectionModal] =
    useState(true);

    const handleNextClick = (account) => {
      setSelectedAccount(
        account.id === selectedAccount?.id ? null : { id: account.id, accountName: account?.accountName , accountNumber: account.accountNumber, accountBalance: account.balance }
      );
      setTimeout(() => {
        handleCloseVirtualAccount();
        setOpen(true);
      }, 1000);
    };
  
  const handleCloseSelectedAccountModal = () => {
    setShowSelectedAccountModal(false);
    setShowAccountSelectionModal(true);
  };

  const handleCloseModal = () => {
    handleCloseVirtualAccount();
    setShowSelectedAccountModal(false);
    setShowAccountSelectionModal(true);
  };


  if (showSelectedAccountModal) {
    return (
      <VirtualSelectedAccount
        selectedAccount={virtualAccounts?.find(
          (account) => account.id === selectedAccount
        )}
        onClose={handleCloseModal}
        handleChangeAccount={handleCloseSelectedAccountModal}
        showAccountSelectionModal={showAccountSelectionModal}
      />
    );
  }

  return (
    <div className="w-full fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
      <div className="relative w-full max-w-2xl p-6 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between mb-4">
          <h2 className="text-sm font-light">Select Account</h2>
          <button onClick={onClose} className="text-red-500">
            X Close
          </button>
        </div>
        <div className="mb-4">
          <h2 className="text-gray-600 text-center">
            Select which account you want to use for this Request
          </h2>
        </div>

        <div style={{ cursor: "pointer" }} className="grid grid-cols-2 gap-1">
          {virtualAccounts?.map((account) => (
            <div
              onClick={() => handleNextClick(account)}
              key={account.id}
              className="w-[300px]"
            >
              <div className="bg-primaryColor p-2">
                <div className="flex justify-between">
                  <div className="flex">
                    <img src={EmptyWallet} alt="" />
                    <span className="ml-2" style={{ color: "white" }}>
                      {account?.accountName}
                    </span>
                  </div>
                  <div>
                    <span style={{ color: "white" }}>
                      {account?.accountType || "Virtual Account"}
                    </span>
                  </div>
                </div>
                <div>
                  <span style={{ color: "white" }}>NGN {formatCurrency(Number(account?.balance))}</span>
                </div>
                <div className="flex">
                  <span style={{ color: "white" }}>{account?.bankName || "PayEdge"}</span>
                  <span className="mx-2 border-l border-white h-6"></span>
                  <span style={{ color: "white" }}>
                    {account?.accountNumber}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BulkVsa;
