import {
  // allSupplierAssociatedBuyer,
  getAssociatedBuyerId,
  supplierBuyerDisassociate,
} from "appstate/supplier/supplierSlice";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import Loading from "shared/Loading";
import AccountModalContainer from "shared/accountModal/Modal";
import TableHeader from "shared/tableHeader/TableHeader";
// import { getAllCreatedTiers } from "appstate/api/tier";
import CardDisassociation from "features/relationshipManagement/buyer/card/card";
import ThreeDots from "../../../../../assets/images/svgs/option.svg";
import Pagination from "shared/Pagination";
import { getAllSupplierAssociatedBuyers } from "appstate/api/supplier/relationshipManagement";
import _ from "lodash";
import SuccessRelationship from "shared/SuccessRelationship";

const Members = () => {
    // eslint-disable-next-line
  const [dropdown, setDropdown] = useState(false);
  // eslint-disable-next-line
  const [success, setSuccess] = useState(false);
  const [getId, setGetId] = useState(false);
  const [openDisassociateModal, setOpenDisAssociateModal] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const [total, setTotal] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [associatedBuyerData, setAssociatedBuyerData] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(null); 
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  // eslint-disable-next-line
  const [funderMember, setFunderMember] = useState(true);

  const pageSize = 10;
  const { isLoading, disAssociateLoading } = useSelector(
    (state) => state?.supplier
  );

  // eslint-disable-next-line
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleThreeDotsClick = (row, rowEmail) => {
    setGetId(row?.userId)
    setDropdownVisible(dropdownVisible === rowEmail ? null : rowEmail);
  };
  
  // const fetchTiers = async () => {
  //   const result = await getAllCreatedTiers({ page,  pageSize, searchTerm });
  //   setData(result?.data || []);
  // };

  // useEffect(() => {
  //   fetchTiers();
  //   // eslint-disable-next-line
  // }, []);

  const fetchAssociatedSuppliersBuyers = async () => {
    setLoading(true);
    try {
      const search = searchTerm;
      const result = await getAllSupplierAssociatedBuyers({
        page,
        pageSize,
        search,
      });
      setTotal(result?.meta?.totalElements);
      setAssociatedBuyerData(result?.data || []);
    } catch (error) {
      console.error("Error fetching associated buyers:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssociatedSuppliersBuyers();
    // eslint-disable-next-line
  }, [page, pageSize, companyName]);

 
  const onRowClicked = (row) => {
    let path = "/accounts/accountdetails";
    setGetId(row?.userId)
    dispatch(getAssociatedBuyerId(row?.userId));
    navigate(path, { state: { row } });
  };


  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    _.debounce(() => {
      fetchAssociatedSuppliersBuyers();
    }, 200),
    [searchTerm]
  );

  useEffect(() => {
    if (searchTerm) {
      debouncedSearch();
    }
    debouncedSearch();
    // eslint-disable-next-line
  }, [searchTerm, debouncedSearch]);

  const handleSearch = () => {
    fetchAssociatedSuppliersBuyers();
  };
  // const handleOpenModal = (row) => {
  //   setGetId(row?.userId);
  //   setOpenDisAssociateModal(true);
  // };

  const handleDisAssociate = () => {
    const buyerId = getId;
    // const search = "";
    dispatch(supplierBuyerDisassociate(buyerId)).then((data) => {
      if (data?.payload?.success === true) {
        setShowSuccessModal(true);
        fetchAssociatedSuppliersBuyers();
        setOpenDisAssociateModal(false);
      }
    });
  };

  const handleShowModal = async () => {
    setOpenDisAssociateModal(true);
  };

  const column = [
    {
      name: "NAME",
      selector: (row) => row?.fullName,
    },
    {
      name: "COMPANY",
      selector: (row) => row?.companyName,
    },
    {
      name: "EMAIL",
      selector: (row) => row?.email,
    },
    {
      name: "PHONE",
      selector: (row) => row?.phoneNumber,
    },
    {
      cell: (row) => (
        <div className="relative">
          <img
            src={ThreeDots}
            alt="Options"
            onClick={() => handleThreeDotsClick(row, row?.email)} 
            className="cursor-pointer"
          />
          {dropdownVisible === row?.email && (
            <div className="absolute right-0 z-10 mt-2">
              <CardDisassociation 
                handleShowModal={handleShowModal} 
                onRowClicked={() => onRowClicked(row)} 
                funderMember={funderMember} 
                row={row} 
              />
            </div>
          )}
        </div>
      ),
    },
  ];
  
  const totalPages = total;

  let path = "associate";

  const onClose = () => {
    setShowSuccessModal(false);
  };

  return (
    <DashboardLayout
      section="Buyers"
      subHeader="Manage all your associated buyer's account"
    >
       {showSuccessModal && (
        <SuccessRelationship
          isOpen={showSuccessModal}
          onClose={onClose}
          message="Disassociation successful"
        />
      )}
      <Card className="p-[24px] rounded-[10px]">
        <AccountModalContainer
          accontModal
          isShown={openDisassociateModal}
          onClose={() => {
            setOpenDisAssociateModal(false);
          }}
          isLoading={disAssociateLoading}
          success={success}
          handleAssociate={handleDisAssociate}
          warning="Are you sure you would like to disassociate with this member?"
        />
        <TableHeader
          path={path}
          setProductName={setCompanyName}
          productName={companyName}
          // fetchTiers={fetchTiers}
          search={handleSearch}
          associateBtn
          data={data}
          accounts
         
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          funderMember={funderMember}
          total="All Buyers"
          details="Access contacts details of your associated buyers"
          tier
        />
         {loading ? (
          <div className="flex justify-center">
            <Loading />
          </div>
        ) : (
        <Table
          columns={column}
          data={associatedBuyerData}
          // selectableRows
          onRowClicked={onRowClicked}
          progressPending={isLoading}
          progressComponent={<Loading />}
          pointer
          tableHeader
          className="mt-5"
          dropdown={dropdown}
        />
        )}
        <br />
        <div className="flex justify-center gap-x-[8px]  mt-12">
          {!isLoading && totalPages > 10 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default Members;
