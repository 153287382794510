import {
  // PAIconCocacola,
  PAIconInvShop,
  PAIconLockYellow,
} from "assets/images/svgs";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import useCountdown from "utilities/helper/useCountdown";

function ClosedMarketCard({
  id,
  invoiceNumber = "N/A",
  buyerName = "N/A",
  lockRate = "N/A",
  buyerDiscountRate,
  statusOfInvoice = "N/A",
  requestAmount = "N/A",
  dueDate = "N/A",
  supplierName = "N/A",
  earlyPaymentDate = "N/A",
  onBid,
  refNum,
  closeMarketId,
  amount,
  bulk,
  setLockRate,
  setBuyerDiscountRate,
}) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleOnbid = (event) => {
    amount(requestAmount);
    setLockRate(lockRate);
    setBuyerDiscountRate(buyerDiscountRate);
    closeMarketId(id);
    refNum(invoiceNumber);
    event.stopPropagation();
    onBid();
  };

  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  const { timeLeft, isTimeUnderOneHour } = useCountdown(earlyPaymentDate);

  const formatAmount = (amount) => {
    if (!amount) return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  //funderclosemarketposteddetails

  return (
    <motion.div
      //
      onClick={() => {
        // invoiceType === "Single request"
        //   ? navigate(
        //       `/payablefinancing-closedmarket/funderclosemarketdetails/${id}`
        //     )
        //   : navigate(
        //       `/payablefinancing-closedmarket/funderclosemarketbulkdetails/${id}`
        // bulkclosedmarketposteddetails;
        //     );
        if (!bulk && statusOfInvoice === "POSTED") {
          navigate(
            `/payablefinancing-closedmarket/funderclosemarketposteddetails/${id}`
          );
        } else if (!bulk && statusOfInvoice !== "POSTED") {
          navigate(
            // `/payablefinancing-closedmarket/funderclosemarketdetails/${id}`
            `/payablefinancing-closedmarket/funderclosemarketposteddetails/${id}`
          );
        } else if (bulk && statusOfInvoice !== "POSTED") {
          navigate(
            `/payablefinancing-closedmarket/bulkclosedmarketposteddetails/${id}`
          );
        } else if (bulk && statusOfInvoice === "POSTED") {
          navigate(
            `/payablefinancing-closedmarket/bulkclosedmarketposteddetails/${id}`
          );
        }
      }}
      whileTap={{ scale: 0.98 }}
      style={{ boxShadow: "0px 2px 4px 0px rgba(232, 226, 216, 0.36)" }}
      className="flex mb-5  cursor-pointer  bg-white  rounded-[10px] sm:w-full md:w-full gap-6    "
    >
      {/* first layer */}

      {/* flex cursor-pointer justify-between bg-white py-5 px-6 rounded-[10px] w-full sm:w-full md:w-full gap-6 */}

      <div className="flex flex-col cursor-pointer  bg-white py-5 px-6 rounded-[10px] sm:w-full md:w-full gap-6 ">
        <div className=" flex cursor-pointer justify-between bg-white   rounded-[10px] w-full sm:w-full md:w-full gap-6">
          <span className="flex gap-6 items-center flex-nowrap whitespace-nowrap">
            <PAIconInvShop />
            <div className="flex flex-col justify-center">
              <span className="font-[500] text-sm">{invoiceNumber}</span>
              <span className=" text-[#959595] text-[10px]">
                {"Reference number"}
              </span>
            </div>
          </span>
          {/* Button segment */}
          <span className="flex  items-center flex-nowrap whitespace-nowrap">
            <button
              className={`h-[37px] p-2.5 rounded-[5px] inline-flex ${
                bulk ? " bg-[#07593d]/10" : "bg-[#f08000]/10"
              }`}
            >
              {/* <div class="h-[37px] p-2.5 bg-[#07593d]/10 rounded-[5px] border flex-col justify-start items-start gap-0.5 inline-flex">
              <div class="text-black text-sm font-semibold font-['SF Pro Display']">
                Bulk request
              </div>
            </div> */}
              <div className="text-black text-sm font-medium ">
                {bulk ? "Bulk Request" : "Single Request"}
              </div>
            </button>
          </span>

          {/* button stops here */}
          <span className="flex gap-6 items-center flex-nowrap whitespace-nowrap ">
            <div className="flex flex-col justify-center">
              <span className="font-[500] text-sm">{buyerName}</span>
              <span className=" text-[#959595] text-[10px]">Buyer</span>
            </div>
          </span>

          <span className="flex gap-6 items-center flex-nowrap whitespace-nowrap">
            <div className="flex flex-col justify-center">
              <span className="font-[500] text-sm">{supplierName}</span>
              <span className=" text-[#959595] text-[10px]">Supplier</span>
            </div>
          </span>

          <span className="flex gap-6 flex-nowrap whitespace-nowrap ">
            <div className="flex flex-col justify-center">
              <span
                className={`font-[500]  text-sm ${
                  statusOfInvoice === "BID_ACTIVE"
                    ? "text-[#f08000]"
                    : "text-[#07593d]"
                }`}
              >
                {statusOfInvoice === "BID_ACTIVE" ? "Bid Active" : "Posted"}
              </span>
              <span className=" text-[#959595] text-[10px]">
                Status of invoice
              </span>
            </div>
          </span>

          {/* side end button */}

          <button
            class=" p-2.5 bg-white rounded-[5px] border border-[#07593d] justify-center items-center gap-2 inline-flex"
            onClick={handleOnbid}
          >
            <span className="sm:hidden md:inline">Bid</span>{" "}
            <PAIconLockYellow />
            <span className=" pl-2 border-l border-primaryColor">
              <div className="flex flex-col justify-center">
                <span
                  className={`text-sm font-bold ${
                    isTimeUnderOneHour ? "text-red-500" : ""
                  }`}
                >
                  {(timeLeft.days > 0 ||
                    timeLeft.hours > 0 ||
                    timeLeft.minutes > 0 ||
                    timeLeft.seconds > 0) &&
                  formatCapitaliseString(statusOfInvoice) !== "Cancelled" ? (
                    `${timeLeft.days}d:${timeLeft.hours}h:${timeLeft.minutes}m:${timeLeft.seconds}s`
                  ) : isLoading ? (
                    <span className="text-secondaryColor">Fetching...</span>
                  ) : formatCapitaliseString(statusOfInvoice) ===
                    "Cancelled" ? (
                    <span className="text-red">Cancelled</span>
                  ) : (
                    <span className="text-red">Expired</span>
                  )}
                </span>
                <span className="text-[#959595] text-[10px]">
                  Count down to payment date
                </span>
              </div>
            </span>
          </button>
        </div>
        {/* side end button ends here */}
        {/* second layer */}
        <div className=" flex cursor-pointer justify-between bg-white   rounded-[10px] w-full sm:w-full md:w-full gap-6">
          <span className="flex gap-3  items-center flex-nowrap whitespace-nowrap">
            <div className="text-black text-sm font-medium ">
              {`${statusOfInvoice === "POSTED" ? "Buyer Rate" : "Lock Rate"}`}
            </div>
            <div className="text-[#949494] text-sm font-medium ">
              {`${
                statusOfInvoice === "POSTED"
                  ? `${buyerDiscountRate}`
                  : `${lockRate}`
              }`}
              {/* {lockRate || "N/A"} */}
            </div>
          </span>

          <span className="flex gap-3  items-center flex-nowrap whitespace-nowrap">
            <div className="text-black text-sm font-medium ">
              Request Amount
            </div>
            <div className="text-[#949494] text-sm font-medium ">
              {formatAmount(requestAmount)}
            </div>
          </span>
          <span className="flex gap-3  items-center flex-nowrap whitespace-nowrap">
            <div className="text-black text-sm font-medium ">
              Early Payment Date
            </div>
            <div className="text-[#949494] text-sm font-medium ">
              {earlyPaymentDate || "N/A"}
            </div>
          </span>
          <span className="flex gap-6 flex-nowrap whitespace-nowrap">
            <div className="text-black text-sm font-medium ">Due Date</div>
            <div className="text-[#949494] text-sm font-medium ">{dueDate}</div>
          </span>
        </div>
      </div>
    </motion.div>
  );
}

export default ClosedMarketCard;
