import React, { useEffect, useState } from "react";

import RequestDetails from "./RequestDetails";

import {
  fetchBidsInLead,
  fetchBidsNotInLead,
} from "appstate/funder/funderSlice";
import { useDispatch } from "react-redux";
import NotInLeadBidHistory from "./NotInLeadBidHistory";

const MoreInvoiceIdSegmentNotLeading = ({
  details,
  closedMarketRequestDetails,
  currentDa,
  NotCurrentLeading,
  otherBids,
}) => {
  const [activeTab, setActiveTab] = useState("NumberOfBids");
  const dispatch = useDispatch();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  /*
   leadingBids: [],
  notInLeadingBids: [],*/

  useEffect(() => {
    dispatch(fetchBidsInLead());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchBidsNotInLead());
  }, [dispatch]);
  // const { leadingBids, notInLeadingBids } = useSelector(
  //   (state) => state.funder
  // );

  const renderTabContent = () => {
    switch (activeTab) {
      case "NumberOfBids":
        return (
          <NotInLeadBidHistory
            details={details}
            currentLeadData={currentDa}
            NotCurrentLeadData={NotCurrentLeading}
            OtherBidsData={otherBids}
          />
        );
      case "FundersInvited":
        return <RequestDetails details={closedMarketRequestDetails} />;
      // case "ViewByFunders":
      //   return <ViewByFunders />;
      default:
        return null;
    }
  };

  return (
    <div className=" gap-2">
      <div className="  ">
        <div className="text-sm ">
          <div className="flex  gap-5  flex-row px-6  ">
            <div className=" py-4">
              <p
                className={`cursor-pointer  py-1 ${
                  activeTab === "NumberOfBids"
                    ? " border-[#F08000]   text-amber-600 text-xs font-bold border-b-2 "
                    : "text-gray500  text-neutral-400 text-xs font-normal"
                }`}
                onClick={() => handleTabClick("NumberOfBids")}
              >
                Bid history
              </p>
            </div>

            <div className="py-4">
              <p
                className={`cursor-pointer  py-1 ${
                  activeTab === "FundersInvited"
                    ? " border-[#F08000]   text-amber-600 text-xs font-bold border-b-2 "
                    : "text-gray500  text-neutral-400 text-xs font-normal"
                }`}
                onClick={() => handleTabClick("FundersInvited")}
              >
                Request details
              </p>
            </div>
          </div>

          <div className="mt-3">{renderTabContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default MoreInvoiceIdSegmentNotLeading;
