import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import {
  PAIconSmallBrewIcon,
  PAIconSmallCoke,
  PAIconSmallDangote,
  PAIconSmallNestle,
  PAIconSmallShell,
  PAIconSmallTrust,
  PAIconCocacola,
  PAIconInvShop,
} from "assets/images/svgs";

function MyInvoicesCard({
  id,
  invoiceNumber = "N/A",
  supplierCompanyName = "N/A",
  invoiceAmount = "N/A",
  status = "N/A",
  invoiceDueDate = "N/A",
  discountRate = "N/A",
  supplierCompanyIcon,
  doerType = "N/A",
}) {
  const navigate = useNavigate();

  return (
    <motion.div
      onClick={() => navigate(`myinvoicesdetails/${id}`)}
      whileTap={{ scale: 0.98 }}
      style={{ boxShadow: "0px 2px 4px 0px rgba(232, 226, 216, 0.36)" }}
      className="flex cursor-pointer justify-between bg-white py-5 px-6 rounded-[10px]"
    >
      <span className="flex gap-6  items-center">
        <PAIconInvShop />
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceNumber}</span>
          <span className=" text-[#959595] text-[10px]">Invoice number</span>
        </div>
      </span>

      <span className="flex gap-6  items-center">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceNumber}</span>
          <span className=" text-[#959595] text-[10px]">Invoice number</span>
        </div>
      </span>

      <span className="flex gap-6 items-center ">
        {/* <PAIconCocacola /> */}
        {supplierCompanyName === "Coca Cola" ? (
          <PAIconCocacola />
        ) : supplierCompanyName === "Coke Plc." ? (
          <PAIconSmallCoke />
        ) : supplierCompanyName === "Dangote" ? (
          <PAIconSmallDangote />
        ) : supplierCompanyName === "Nestle" ? (
          <PAIconSmallNestle />
        ) : supplierCompanyName === "Shell  Ltd." ? (
          <PAIconSmallShell />
        ) : supplierCompanyName === "Trust" ? (
          <PAIconSmallTrust />
        ) : (
          <PAIconSmallBrewIcon />
        )}
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{supplierCompanyName}</span>
          <span className=" text-[#959595] text-[10px]">{doerType}</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className={`font-[500] text-brilliantYellow text-sm`}>
            {status}
          </span>
          <span className=" text-[#959595] text-[10px]">Status of invoice</span>
        </div>
      </span>

      <span className="flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceAmount}</span>
          <span className=" text-[#959595] text-[10px]">Invoice Amount</span>
        </div>
      </span>

      <span className="flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{discountRate}%</span>
          <span className=" text-[#959595] text-[10px]">Lock Rate</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceDueDate}</span>
          <span className=" text-[#959595] text-[10px]">
            Early Payment Date
          </span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceDueDate}</span>
          <span className=" text-[#959595] text-[10px]">Bid Closure Date</span>
        </div>
      </span>
    </motion.div>
  );
}

export default MyInvoicesCard;
