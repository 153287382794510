import React, { useEffect, useState } from "react";
import Card from "shared/Card";
import Loading from "shared/Loading";
import Heading from "shared/heading/Heading";
import Table from "layout/table/Table";
import { PAIconResume } from "assets/images/svgs";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelPendingUploadInvoice,
  pendingUploadInvoice,
} from "appstate/invoice/invoiceSlice";
import Pagination from "shared/Pagination";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";
import ResumeModal from "./AcceptModal";
import { useNavigate } from "react-router-dom";
// import toast from "react-hot-toast";
import {
  getInvoiceResumeMapping,
  getInvoiceResumeValidation,
} from "appstate/api/invoice/invoice";

function PendingImport() {
  const [companyName, setCompanyName] = useState("");
  const [isValidation, setValidation] = useState(false);
  const [isMapping, setMapping] = useState(false);
  const [isCancelModal, setCancelModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // /invoice/fundingSection/invoiceupload/validation

  const { pendingUploadInvoiceData, isLoading } = useSelector(
    (state) => state?.invoice
  );

  const handleSearch = () => {
    dispatch(pendingUploadInvoice({ page, pageSize, search: companyName }));
  };

  useEffect(() => {
    dispatch(pendingUploadInvoice({ page, pageSize, search: companyName }));
  }, [dispatch, page, pageSize, companyName]);

  // const handleValidationSubmit = () => {
  //   // remove the console.log when done
  //   // console.log("Validation resumed for:", selectedRow?.invoiceRef);
  //   setValidation(false);
  // };

  const handleCancelSubmit = () => {
    dispatch(cancelPendingUploadInvoice({ id: selectedRow?.id })).then(
      (response) => {
        // if (response?.payload?.status === 200 && response?.payload?.success) {
        //   // setIsSuccessModalOpen(true);
        //   // setSuccessMessage(response?.payload?.message);
        //   setIsSuccessModal(true);
        if (response.type === "cancelPendingUploadInvoice/fulfilled") {
          setCancelModal(false);
          setIsSuccessModal(true);
          dispatch(
            pendingUploadInvoice({ page, pageSize, search: companyName })
          );

          // setSuccessMessage(response.payload.message);
        }
      }
    );
  };

  const handleCancelClick = (row) => {
    setSelectedRow(row);
    setCancelModal(true);
  };

  const handleMappingSubmit = async () => {
    try {
      const result = await getInvoiceResumeMapping(selectedRow?.reference);
      if (result) {
        navigate("/invoice/invoice/fundingSection/invoiceupload/validation", {
          state: { mappings: result?.data },
        });
      }
    } catch (error) {
      console.error("Error fetching invoice mapping:", error);
    } finally {
      setMapping(false);
    }
  };

  const handleValidationSubmit = async () => {
    try {
      const result = await getInvoiceResumeValidation(selectedRow?.reference);
      if (result) {
        navigate(
          "/invoice/invoice/fundingSection/invoiceupload/validation/invoice-table",
          {
            state: { mappings: result?.data },
          }
        );
      }
    } catch (error) {
      console.error("Error fetching invoice validation:", error);
    } finally {
      setValidation(false);
    }
  };

  const columns = [
    { name: "Reference Number", selector: (row) => row.reference },
    { name: "Upload Date", selector: (row) => row.uploadDate },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <p className="text-[#BB0C0C] font-semibold">{row.status}</p>
      ),
    },
    {
      name: "Actions",
      selector: (row) => row.action,
      cell: (row) => (
        <div className="flex items-center justify-between gap-3">
          <div
            className="flex items-center justify-between border border-gray-300 rounded text-[#959595] p-2 cursor-pointer"
            onClick={() => {
              setSelectedRow(row);
              if (row.status === "PENDING_VALIDATION") {
                setValidation(true);
              } else if (row.status === "PENDING_MAPPING") {
                setMapping(true);
                // navigate("/invoice/invoice/fundingSection/invoiceupload/validation")
              }
            }}
          >
            <span className="h-4 flex items-center">
              <PAIconResume />
            </span>
            Resume
          </div>
          <div
            className="border border-gray-300 rounded text-[#959595] p-2 cursor-pointer"
            onClick={() => handleCancelClick(row)}
          >
            <span className="bg-[#BB0C0C] py-[1px] px-2 mr-2"></span>Cancel
          </div>
        </div>
      ),
    },
  ];

  const totalPages = pendingUploadInvoiceData?.meta?.totalElements;

  return (
    <>
      <Card className="rounded-lg">
        <Heading
          setCompanyName={setCompanyName}
          heading="Pending Invoice Upload"
          info="List of saved invoice upload"
          companyName={companyName}
          search={handleSearch}
          invoice
        />
        <div className="rounded-b-lg px-[24px] pb-[24px] min-h-[70vh]">
          <Table
            columns={columns}
            data={pendingUploadInvoiceData?.data}
            pointer
            progressComponent={<Loading />}
            tableHeader
            className="mt-5"
          />
          <div className="flex justify-center gap-x-[8px] mt-[20px]">
            {!isLoading && pendingUploadInvoiceData?.data?.length >= 10 && (
              <Pagination
                page={page}
                startPage={startPage}
                setStartPage={setStartPage}
                endPage={endPage}
                setEndPage={setEndPage}
                setPage={setPage}
                totalPages={totalPages}
              />
            )}
          </div>
        </div>
      </Card>
      <ResumeModal
        isOpen={isValidation}
        onClose={() => setValidation(false)}
        message={
          <>
            Are you sure you would like to resume this invoice <br />
            validation?
          </>
        }
        onConfirm={handleValidationSubmit}
      />
      <ResumeModal
        isOpen={isMapping}
        onClose={() => setMapping(false)}
        message={
          <>
            Are you sure you would like to resume this invoice <br /> mapping?
          </>
        }
        onConfirm={handleMappingSubmit}
      />
      <AcceptModal
        isOpen={isCancelModal}
        onClose={() => setCancelModal(false)}
        message={
          <>
            Are you sure you would like to cancel this invoice <br /> import?
          </>
        }
        onConfirm={handleCancelSubmit}
        loading={isLoading}
      />
      <SuccessModal
        isOpen={isSuccessModal}
        onClose={() => setIsSuccessModal(false)}
        message="Invoice import has been cancelled successfully"
      />
    </>
  );
}

export default PendingImport;
