import { PAIconArrowLeftGreen, PAIconCocacola } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import TransitionScale from "shared/animation/TransitionScale";
import { useSelector, useDispatch } from "react-redux";
import { funderEarlyPaymentViewSingleApprovedDetails } from "appstate/funder/funderSlice";
import { allInvoiceById } from "appstate/invoice/invoiceSlice";

const ApprovedFunderEarlyPaymentDetailsAccepted = () => {
  const dispatch = useDispatch();
  const { getInvoiceId, funderEarlyPaymentViewSingleApprovedDetailsData } =
    useSelector((state) => state?.funder);
  const { invoiceIdData } = useSelector((state) => state.invoice);
  const [data, setData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      funderEarlyPaymentViewSingleApprovedDetails(getInvoiceId?.earlyPaymentId)
    );
  }, [dispatch, getInvoiceId?.earlyPaymentId]);

  useEffect(() => {
    const id = getInvoiceId?.invoiceId;
    dispatch(allInvoiceById({ id }));
  }, [dispatch, getInvoiceId?.invoiceId]);

  useEffect(() => {
    setData(funderEarlyPaymentViewSingleApprovedDetailsData?.data);
  }, [funderEarlyPaymentViewSingleApprovedDetailsData]);

  useEffect(() => {
    setInvoiceData(invoiceIdData?.data);
  }, [invoiceIdData]);

  function handleNavigate() {
    let path = -1;
    navigate(path);
  }

  // const [editDiscount, setEditDiscount] = useState(false);

  return (
    <>
      <DashboardLayout
        section="Early Payment Request"
        subHeader="Manage all your Early payment requests"
      >
        <header>
          <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconArrowLeftGreen />
            <span className="flex items-center text-primaryColor">Go Back</span>
          </div>
        </header>

        <div className="grid lg:grid-cols-[60%,40%] gap-5">
          <TransitionScale className=" pb-5 bg-white  rounded-[10px] mt-5 pt-2 ">
            <section className="  mt-3">
              <div class=" justify-between items-center inline-flex mt-2 px-6 w-full">
                <div class="text-[#222222] text-base font-semibold  ">
                  Reference No: | {data?.invoiceRef}
                </div>
                <button
                  className="h-[41px] px-6 py-3 bg-[#07593d] rounded-[5px] justify-center items-center gap-2.5 inline-flex"
                  onClick={() => navigate("/disbursement")}
                >
                  <div className="text-white text-sm font-medium ">
                    Disburse Now
                  </div>
                </button>
              </div>
              <div className=" flex justify-between px-6">
                {/* first stage */}

                <div class="lg:w-[240px]  sm:w-full h-[117px] flex-col justify-start items-start gap-3 inline-flex">
                  <div class="self-stretch  flex-col justify-center items-start gap-1 flex mt-2">
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Invoice Number
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        INV {data?.invoiceNo}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex ">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Created Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {invoiceData?.createdDate}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Submitted Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {invoiceData?.submittedDate}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Approved Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {invoiceData?.approvedDate}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Due Date:{" "}
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {invoiceData?.dueDate}
                      </div>
                    </div>
                    <div class="self-stretch justify-between items-center inline-flex">
                      <div class="text-[#222222] text-xs font-semibold ">
                        Issuance Date:
                      </div>
                      <div class="text-[#7a7a7a] text-xs font-semibold ">
                        {invoiceData?.issuanceDate}
                      </div>
                    </div>
                  </div>

                  {/* stop */}
                </div>
                {/* second stage */}
                <div className="flex justify-between items-center  ">
                  <div class="h-[30px] px-8 py-2 bg-[#2ea923]/20 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
                    <div class="text-center text-[#2ea923] text-xs font-semibold ">
                      Approved
                    </div>
                  </div>{" "}
                </div>
              </div>

              {/* stops here */}
              <div className="  px-6 mt-24">
                <header className="border-b-[5px] border-b-[#F6F5FA] mt-3  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                Supplier/buyer details
              </h1> */}
                  <div>
                    <div class="text-[#222222] text-lg font-semibold  ">
                      {data?.supplierCompanyName}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-light  ">
                      Buyer details
                    </div>
                  </div>

                  <div class="h-[41px] flex-col justify-start items-start  inline-flex px-12">
                    <div class="text-[#222222] text-base font-semibold ">
                      {invoiceData?.paymentTerms} Days
                    </div>
                    <div class="w-[100px] text-[#7a7a7a] text-xs font-light  mt-1">
                      Payment terms
                    </div>
                  </div>

                  <div class="h-[43px] flex-col justify-start items-end  inline-flex">
                    <div class="text-[#222222] text-lg font-semibold ">
                      {invoiceData?.currency}
                    </div>
                    <div class="text-[#7a7a7a] text-xs font-semibold mt-1">
                      Currency
                    </div>
                  </div>
                </header>

                <header className="border-b-[5px] border-b-[#F6F5FA] mt-6  flex justify-between py-1">
                  {/* <h1 className="text-[18px] font-semibold">
                Supplier/buyer details
              </h1> */}
                  <div class="text-[#11192a] text-sm font-semibold ">
                    Line Items
                  </div>
                  <div class="text-[#11192a] text-lg font-light  mr-6">
                    {invoiceData?.items?.length} Total Item(s)
                  </div>
                </header>
                <div className=" w-full overflow-x-auto mt-3 pb-4 h-[25vh]">
                  <table className=" w-full overflow-x-auto">
                    <thead className="bg-subtleGray w-full p-6  flex-nowrap whitespace-nowrap">
                      <tr className=" bg-unusualGray  px-6 w-full flex-nowrap whitespace-nowrap ">
                        {/* <td className="text-[#11192A] p-2.5 text-xs font-bold">
                        Item Number
                      </td> */}
                        <td class="text-[#11192a] text-xs font-semibold  p-4  flex-nowrap whitespace-nowrap ">
                          Item{" "}
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Description{" "}
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Unit Price
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Quantity
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Amount
                          <div> (before tax)</div>
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Tax Rate
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Tax Amount
                        </td>
                        <td class="text-[#11192a] text-xs font-semibold  p-4 flex-nowrap whitespace-nowrap">
                          Total Price
                        </td>
                      </tr>
                    </thead>

                    <tbody className="">
                      {invoiceData?.items?.map((cell, idx) => {
                        return (
                          <tr key={idx} className=" w-full ">
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap ">
                              {cell.itemName}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                              {cell.description}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                              {cell.unitPrice}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 text-center flex-nowrap whitespace-nowrap">
                              {cell.quantity}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                              {cell.amount}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                              {cell.taxRate}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                              {cell.taxAmount?.toLocaleString()}
                            </td>
                            <td className="text-[#11192a] text-xs font-semibold mt-2 p-4 flex-nowrap whitespace-nowrap">
                              {cell.totalPrice?.toLocaleString()}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {/* Calculate cost and price  shaded or gray section */}
              </div>

              <div className=" px-6   py-5 bg-neutral-50 flex-col justify-center items-start  inline-flex bg-[#fafafa] w-full border-t-[0.2px]  border-t-[#b1a6b2]">
                <div className="lg:flex md:flex justify-between  w-full sm:block ">
                  {/* First left side */}
                  <div>
                    <section>
                      {" "}
                      <div class="text-[#222222] text-lg  font-medium border-b-[0.2px]  border-[#b1a6b2]">
                        Receiving Funding Account Information
                      </div>
                      {/* <div class="text-[#222222] text-lg font-semibold ">
                        Receiving Funding Account Information
                      </div> */}
                      <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                        {invoiceData?.virtualAccount?.accountNumber}
                      </div>
                      <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                        {invoiceData?.virtualAccount?.accountName}
                      </div>
                    </section>
                  </div>

                  {/* second division */}

                  <div>
                    {" "}
                    <div class=" flex-col justify-start items-start inline-flex lg:min-w-[290px]">
                      <section className=" w-full px-6 pb-2 border-b-[1px] border-black">
                        <div className=" flex w-full justify-between">
                          <div class="text-[#222222] text-xs font-semibold ">
                            Subtotal
                          </div>
                          <div class=" text-[#222222] text-xs font-semibold ">
                            {invoiceData?.subTotal?.toLocaleString()}
                          </div>
                        </div>
                        <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                          <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                            <span>VAT%</span>
                            <span>{invoiceData?.vat}%</span>
                          </div>
                          <div class="text-[#222222] text-xs font-medium ">
                            {invoiceData?.vatAmount?.toLocaleString()}
                          </div>
                        </div>

                        <div className=" flex w-full justify-between pt-3 pb-1 border-b-[0.2px] border-b-[#b1a6b2]/30">
                          <div class="text-[#222222]  text-xs font-medium flex  space-x-9 ">
                            <span>Discount%</span>
                            <span>{invoiceData?.discount}%</span>
                          </div>
                          <div class="text-[#222222] text-xs font-medium ">
                            {invoiceData?.discountAmount?.toLocaleString()}
                          </div>
                        </div>
                      </section>
                      <div className=" flex w-full justify-between py-2 px-6 border-b-[1px] border-b-black">
                        <div class="text-[#222222] text-lg font-semibold ">
                          Grand Total
                        </div>
                        <div class="text-[#222222] text-lg font-semibold ">
                          {invoiceData?.grandTotal?.toLocaleString()}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* stops */}
                </div>
              </div>
            </section>
          </TransitionScale>
          {/* stops the best here */}

          <div className="bg-white rounded-[10px] gap-8  mt-5">
            {/* <FundDetailSide /> */}
            <div class="w-full justify-start items-center gap-4 inline-flex ">
              <div class=" justify-start items-center gap-2.5 flex w-full px-6  py-3">
                <div class="text-black text-xl font-bold  mt-5">
                  Early Payment Details
                </div>
              </div>
            </div>

            <div>
              {" "}
              {/* second */}
              <div className="   pb-4">
                <div class=" justify-between  flex flex-row    px-6 mt-4  ">
                  <div className="flex gap-2 py-2.5">
                    <PAIconCocacola />
                    <div className="flex flex-col">
                      {/* <span className=" text-black text-xs font-normal">
                      Coca Cola
                    </span> */}
                      <span class="text-black text-sm font-normal ">
                        {data?.supplierCompanyName}
                      </span>
                      {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                      <span class="text-[#949494] text-sm font-normal ">
                        Supplier
                      </span>
                    </div>
                  </div>
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-end items-end gap-0.5 flex w-full">
                      <div class="text-black text-sm font-normal text-end ">
                        {data?.discountRate}%
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Discount rate
                      </div>
                    </div>
                  </div>

                  {/* here */}
                </div>
                <div class=" justify-between  flex flex-row    px-6  mt-6 ">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        NGN {data?.discountAmount?.toLocaleString()}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Discount amount
                      </div>
                    </div>
                  </div>
                  {/* hd */}
                  <div class="py-2.5 flex-col justify-start items-end inline-flex">
                    <div class="flex-col justify-end items-end gap-0.5 flex">
                      <div class="text-black text-sm font-normal text-end">
                        {data?.earlyPaymentDate}
                      </div>
                    </div>
                    <div class="flex-col justify-end items-end gap-0.5  w-full  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Early Payment Date
                      </div>
                    </div>
                  </div>{" "}
                </div>
                <div class=" justify-between  flex flex-row    border-b border-[#c2c2c2]/30 px-6  py-3  pb-12 mt-6">
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div class="text-black text-sm font-normal ">
                        NGN {data?.requestAmount?.toLocaleString()}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Request amount
                      </div>
                    </div>
                  </div>
                  {/* dhdh */}
                  <div class="py-2.5 flex-col justify-start items-start inline-flex">
                    <div class="flex-col justify-end items-end gap-0.5  w-full flex">
                      <div class="text-black text-sm font-normal ">
                        NNGN {data?.fundableAmount?.toLocaleString()}
                      </div>
                    </div>
                    <div class="flex-col justify-start items-start  flex">
                      <div class="text-[#949494] text-sm font-normal ">
                        Fundable Amount
                      </div>
                    </div>
                  </div>
                </div>
                {/* 2nd */}
                <div class=" justify-between  flex flex-col    border-b border-[#c2c2c2]/30 px-6  pb-10 ">
                  <div class="text-black text-lg font-semibold  mt-6">
                    Note from Supplier:{" "}
                  </div>

                  <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                    {data?.description}
                  </div>
                </div>{" "}
                <div class=" justify-between  flex flex-col    px-6  ">
                  <div class="text-black text-lg font-semibold  mt-6">
                    Funding Option
                  </div>

                  <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
                    This invoice would be funded from this virtual account:
                  </div>

                  <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                    {data?.funderVirtualAccountName}
                  </div>

                  <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                    {data?.funderVirtualAccountNumber}
                  </div>
                  <div className="text-[#222222]   font-light mt-2  body text-[16px] ">
                    PayEdge MFB
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};
export default ApprovedFunderEarlyPaymentDetailsAccepted;
