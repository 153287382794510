import React from "react";
import { PAIconSmallCoke } from "assets/images/svgs";
import DetailCard from "../card/DetailCard";

const NotInLeadBidHistory = ({
  // details,
  currentLeadData,
  NotCurrentLeadData,
  OtherBidsData,
}) => {
  return (
    <div>
      <div className=" px-4">
        <div className=" bg-[#07593d]/5 rounded-[5px] border border-[#07593d] relative ">
          <div class="text-black text-xs font-semibold absolute -left-0.5 -top-2.5 z-1111 bg-white px-1 ">
            Winning bid
          </div>
          <div class=" justify-between  flex px-2  flex-row  items-center  ">
            <div class=" items-center flex gap-3">
              {/* <PAIconSmallCoke /> */}
              <div class="py-2.5 flex-col justify-start items-start gap-0.5 inline-flex">
                <div class="flex-col justify-start items-start gap-0.5 flex">
                  <div className="text-black text-xs font-normal">
                    {currentLeadData && currentLeadData[0]?.funderCompanyName}
                  </div>
                </div>
                <div class="flex-col justify-start items-start gap-0.5 flex">
                  <div class="text-[#949494] text-[10px] font-normal ">
                    Funder
                  </div>
                </div>
              </div>
            </div>
            <div class="p-2.5 flex-col gap-0.5 inline-flex">
              <div class="flex-col  justify-start items-start gap-0.5 flex">
                <div className="text-black text-xs font-normal">
                  {currentLeadData && currentLeadData[0]?.lockRate}%
                </div>
              </div>
              <div class="h-3 flex-col justify-start items-start gap-0.5 flex">
                <div className=" text-[#949494] text-[10px]">
                  Discount Rate{" "}
                </div>
              </div>
            </div>
            <div class=" p-2.5 flex-col  gap-0.5 inline-flex">
              <div class="flex-col justify-center items-center gap-0.5 flex">
                <div className={`font-[500] text-sm ${"text-[#f08000]"}`}>
                  {currentLeadData && currentLeadData[0]?.status}
                </div>
              </div>
              <div class="h-3 flex-col justify-start items-start gap-0.5 flex">
                <div className=" text-[#959595] text-[10px]">Status</div>
              </div>
            </div>
            <div class="w-[129px] p-2.5 flex-col justify-start items-end gap-0.5 inline-flex">
              <div class="flex-col justify-start items-start gap-0.5 flex">
                <div className="font-[500] text-sm">
                  {" "}
                  {currentLeadData && currentLeadData[0]?.bidTimeStamp}
                </div>
              </div>
              <div class="flex-col justify-start items-end gap-0.5 flex">
                <div className=" text-[#959595] text-[10px]">Bidding Time</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {OtherBidsData &&
        OtherBidsData?.length > 0 &&
        OtherBidsData?.map((item, idx) => {
          return (
            <DetailCard
              key={idx}
              funderName={item.funderCompanyName}
              discountRate={item.lockRate}
              statusOfInvoice={item.status}
              biddingTime={item.bidTimeStamp}
            />
          );
        })}

      <div className=" px-4  mt-10">
        <div className=" bg-[#f08000]/5 rounded-[5px] border border-[#f08000] relative ">
          <div class="text-black text-xs font-semibold absolute -left-0.5 -top-2.5 z-1111 bg-white px-1 ">
            Your bid
          </div>
          <div class=" justify-between  flex px-2  flex-row  items-center  ">
            <div class=" items-center flex gap-3">
              <PAIconSmallCoke />
              <div class="py-2.5 flex-col justify-start items-start gap-0.5 inline-flex">
                <div class="flex-col justify-start items-start gap-0.5 flex">
                  <div className="text-black text-xs font-normal">
                    {NotCurrentLeadData &&
                      NotCurrentLeadData[0]?.funderCompanyName}
                  </div>
                </div>
                <div class="flex-col justify-start items-start gap-0.5 flex">
                  {/* <div className=" text-[#949494] text-[10px]">
                Funder
              </div> */}
                  <div class="text-[#949494] text-[10px] font-normal ">
                    Funder
                  </div>
                </div>
              </div>
            </div>
            <div class="p-2.5 flex-col gap-0.5 inline-flex">
              <div class="flex-col  justify-start items-start gap-0.5 flex">
                <div className="text-black text-xs font-normal">
                  {NotCurrentLeadData && NotCurrentLeadData[0]?.lockRate}%
                </div>
              </div>
              <div class="h-3 flex-col justify-start items-start gap-0.5 flex">
                <div className=" text-[#949494] text-[10px]">
                  Discount Rate{" "}
                </div>
              </div>
            </div>
            <div class=" p-2.5 flex-col  gap-0.5 inline-flex">
              <div class="flex-col justify-center items-center gap-0.5 flex">
                <div className={`font-[500] text-sm ${""}`}>
                  {" "}
                  {NotCurrentLeadData && NotCurrentLeadData[0]?.status}
                </div>
              </div>
              <div class="h-3 flex-col justify-start items-start gap-0.5 flex">
                <div className=" text-[#959595] text-[10px]">Status</div>
              </div>
            </div>
            <div class="w-[129px] p-2.5 flex-col justify-start items-end gap-0.5 inline-flex">
              <div class="flex-col justify-start items-start gap-0.5 flex">
                <div className="font-[500] text-sm">
                  {" "}
                  {NotCurrentLeadData && NotCurrentLeadData[0]?.bidTimeStamp}
                </div>
              </div>
              <div class="flex-col justify-start items-end gap-0.5 flex">
                <div className=" text-[#959595] text-[10px]">Bidding Time</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {OtherBidsData &&
        OtherBidsData?.length > 0 &&
        OtherBidsData?.map((item, idx) => {
          return (
            <DetailCard
              key={idx}
              funderName={item.funderCompanyName}
              discountRate={item.lockRate}
              statusOfInvoice={item.status}
              biddingTime={item.bidTimeStamp}
            />
          );
        })}
    </div>
  );
};

export default NotInLeadBidHistory;
