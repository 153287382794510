import React, { useState, Suspense, useEffect } from "react";
import DashboardLayout from "layout/dashboardlayout";
import MarketCards from "./components/cards/MarketCards";
import OpenMarketCard from "./components/cards/OpenMarketCard";
import InvoiceTabbedCard from "./components/cards/InvoiceTabbedCard";
import SimpleCard from "./components/cards/SimpleCard";
import "./components/styles/tabbedinvoice.css";
import RecentTransactionTable from "./components/tables/RecentTransactionTable";
import DebitCardNew from "./components/cards/DebitCardNew";
// import { AnimatePresence } from "framer-motion";
import OverviewHeader from "shared/overviewHeader";
import { useSelector } from "react-redux";
import PayableFinanceModal from "features/payableFinancing/components/Modal";
import { buyerCreateTransPin } from "appstate/buyer/buyerSlice";
import { useDispatch } from "react-redux";
import { setNewToDashBoard } from "features/onboarding/supplier/supplierVerifcationSlice/verificationSlice";
import { useKeycloak } from "@react-keycloak/web";
import { staffProfileById } from "appstate/roleManager/roleManagerSlice";
import { buyerDataById } from "appstate/auth/authSlice";

const CreateTransPinModal = React.lazy(() =>
  import("shared/createTransPinModal/CreateTransPinModal")
);
const ConfirmTransPinModal = React.lazy(() =>
  import("shared/createTransPinModal/ConfirmTransPinModal")
);
const CreateTransPinSucessModal = React.lazy(() =>
  import("shared/createTransPinModal/CreatTranPinSucessModal")
);
const VirtualAccountSucessModal = React.lazy(() =>
  import("shared/createTransPinModal/VirtualAccountSucessModal")
);

const BuyerDashboard = () => {
  const { keycloak } = useKeycloak();
  const { given_name } = keycloak?.idTokenParsed;
  const [toggleModal, setToggleModal] = useState(false);
  const { buyerDashboardData } = useSelector((state) => state.buyer);
  const { allVirtualCardsData } = useSelector((state) => state?.wallet);
  const { userId } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [payableFianceUpdateAction, setPayableFinanceUpdateAction] =
    useState(false);

  useEffect(() => {
    if (payableFianceUpdateAction === true) {
      const buyerId = userId?.sub;
      dispatch(buyerDataById({ buyerId }));
      setPayableFinanceUpdateAction(false)
    }
  }, [dispatch, userId.sub, payableFianceUpdateAction]);

  const constants = [
    "default-roles-payassyst",
    "offline_access",
    "uma_authorization",
  ];
  const roleIndex = userId?.realm_access?.roles.findIndex(
    (role) => !constants.includes(role)
  );
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();
  const player = userRole?.includes("buyer")
    ? "buyer"
    : userRole?.includes("funder")
    ? "funder"
    : "supplier";

  const [pinOpen, setPinOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [createdsucess, setCreatedsucess] = useState(false);
  const [staff, setStaff] = useState(false);
  const [virtualAccountCreated, setVirtualAccountCreated] = useState(false);
  const { isLoading } = useSelector((state) => state.buyer);
  const { newToDashBoard } = useSelector(
    (state) => state?.verificationSupplier
  );

  const { userDetails } = useSelector((state) => state?.auth);

  const [pinstate, setPinState] = useState("");

  const formatCardNumber = (cardNumber) => {
    if (!cardNumber) {
      return "";
    }

    const sanitizedCardNumber = cardNumber.replace(/\D/g, "");
    const maskedCardNumber = sanitizedCardNumber.replace(
      /(\d{4})(\d{4})(\d{4})(\d{4})/,
      (_, firstPart, middlePart1, middlePart2, lastPart) => {
        const maskedMiddlePart = `${middlePart1.replace(
          /\d/g,
          "*"
        )} ${middlePart2.replace(/\d/g, "*")}`;
        return `${firstPart} ${maskedMiddlePart} ${lastPart}`;
      }
    );

    return maskedCardNumber.trim();
  };

  const formatExpiryDate = (expiryDate) => {
    if (!expiryDate) {
      return "";
    }

    const options = { month: "2-digit", year: "2-digit" };
    return new Date(expiryDate).toLocaleDateString(undefined, options);
  };

  const getFirstCardData = () => {
    const cardData = Array.isArray(allVirtualCardsData?.data)
      ? allVirtualCardsData.data.map((card) => ({
          id: card?.id,
          cardName: card?.cardName,
          cardNumber: formatCardNumber(card?.cardNumber),
          cardCurrency: card?.cardCurrency,
          paymentMethod: card?.paymentMethod,
          balance: card?.balance,
          expiryDate: formatExpiryDate(card?.expiryDate),
        }))
      : [];
    return cardData?.length > 0 ? cardData[0] : null;
  };

  const firstCardData = getFirstCardData();

  function toggleModalOn() {
    setToggleModal(true);
  }

  function toggleModalOff() {
    setToggleModal(false);
  }

  const handleNextPin = () => {
    setPinOpen(false);
    setConfirm(true);
  };
  const handleConfirm = () => {
    setConfirm(false);
    setCreatedsucess(true);
  };
  const handlepinSucess = () => {
    setCreatedsucess(false);
    setVirtualAccountCreated(true);
  };

  useEffect(() => {
    if (newToDashBoard) {
      setPinOpen(true);
    }
    // Clean up sessionStorage if necessary
  }, [newToDashBoard]);

  useEffect(() => {
    if (userRole?.includes("staff")) {
      setStaff(true);
      dispatch(
        staffProfileById({
          player,
          staffId: keycloak?.tokenParsed?.sub,
        })
      );
    }
  }, [dispatch, keycloak?.tokenParsed?.sub, player, userRole]);

  return (
    <DashboardLayout subHeader="Here is what is happening in your finance">
      <Suspense fallback={"loading ..."}>
        <CreateTransPinModal
          disabled={true}
          isOpen={pinOpen}
          onClose={() => setPinOpen(false)}
          onSubmit={(pin) => {
            setPinState(pin);
            handleNextPin();
          }}
        />
      </Suspense>
      <Suspense fallback={"loading ..."}>
        <ConfirmTransPinModal
          isOpen={confirm}
          onClose={() => setConfirm(false)}
          // onSubmit={(pin) => {
          //   handleConfirm();
          // }}
          isLoading={isLoading}
          onSubmit={(pin) => {
            const body = {
              transactionPin: pinstate,
              confirmTransactionPin: pin,
            };

            dispatch(buyerCreateTransPin(body)).then((data) => {
              if (
                data?.payload?.message === "Transaction PIN already exists."
              ) {
                dispatch(setNewToDashBoard(false));
              }
              if (data?.payload?.status === 200) {
                dispatch(setNewToDashBoard(false));

                handleConfirm();
              }
            });
          }}
        />
      </Suspense>
      <Suspense fallback={"loading ..."}>
        <CreateTransPinSucessModal
          isOpen={createdsucess}
          onClose={handlepinSucess}
          message={"Your transaction PIN has been"}
          message2={" successfully created."}
        />
      </Suspense>
      <Suspense fallback={"loading ..."}>
        <VirtualAccountSucessModal
          isOpen={virtualAccountCreated}
          onClose={() => setVirtualAccountCreated(false)}
          userName={given_name ? given_name : ""}
          collectionType={"reimbursements"}
        />
      </Suspense>

      {toggleModal && (
        <div>
          <PayableFinanceModal
            userDetails={userDetails}
            isShown={toggleModal}
            hide={toggleModalOff}
            setPayableFinanceUpdateAction={setPayableFinanceUpdateAction}
          />
        </div>
      )}

      <div className=" w-full">
        <OverviewHeader
          userDetails={userDetails}
          toggleModalOn={toggleModalOn}
          activateButton
        />
        <div className="w-full flex flex-col gap-3 md:flex-row md: justify-between mt-5">
          {/* Content for the first div */}
          <section className="md:w-[48%] ">
            <DebitCardNew cardData={firstCardData} />
            <SimpleCard />
            <RecentTransactionTable />
          </section>
          {/* Content for the second div */}
          <section className=" md:w-[50%] ">
            <div className=" lg:flex-1 py-4  pl-8 rounded-md bg-white w-full pr-6">
              {staff && <div></div>}
              {/* Card */}

              <OpenMarketCard />

              <div className="flex md:justify-center flex-wrap flex-row gap-4 flex-1 py-5">
                <MarketCards
                  amount={buyerDashboardData?.totalPayables || 18}
                  title="Payables"
                />
                <MarketCards
                  amount={buyerDashboardData?.totalReceivables || 2}
                  title="Recievables"
                />
                <MarketCards
                  amount={buyerDashboardData?.totalSettledInvoices || 3}
                  title="Settled Invoice"
                />
              </div>
            </div>

            <InvoiceTabbedCard />
          </section>
        </div>
      </div>
    </DashboardLayout>
  );
};
export default BuyerDashboard;
