import React, { useState, useRef, useEffect } from "react";
import { PAIconCancelMini } from "assets/images/svgs";
import {
  approveSingleEarlyPaymentRequest,
  approveSingleEarlyPaymentRequestViaClosed,
  approveSingleEarlyPaymentRequestViaFunder,
} from "appstate/buyer/buyerSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
// import { updatedDiscountData } from "appstate/buyer/buyerSlice";

const EnterPin = ({
  isOpen,
  onClose,
  confirm,
  checkedAccounts,
  isDirectFundingActive,
  selectedFunder,
  selectedFunders,
  closeMarketActive,
  isBulk,
  setRequestData,
  earlyPaymentRequestId,
  totalInvoiceAmount,
}) => {
  const dispatch = useDispatch();
  const {
    pendingSingleEarlyPaymentDetailsData,
    pendingBulkEarlyPaymentDetailsData,
  } = useSelector((state) => state?.buyer);

  const [data, setData] = useState([]);
  const [updatedDiscountData, setUpdatedDiscountData] = useState([]);

  useEffect(() => {
    if (isBulk === true) {
      setData(pendingBulkEarlyPaymentDetailsData.data);
    } else {
      setData(pendingSingleEarlyPaymentDetailsData?.data);
    }
  }, [
    pendingSingleEarlyPaymentDetailsData,
    pendingBulkEarlyPaymentDetailsData,
    isBulk,
  ]);

  console.log(isBulk)

  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);

  const isDisabled = otpInputs[otpInputs.length - 1] === "";

  const inputRefs = useRef([]);

  const { invoiceIdData } = useSelector((state) => state?.invoice);
  const { updatedDiscountDataResponse } = useSelector((state) => state?.buyer);

  useEffect(() => {
    setUpdatedDiscountData(updatedDiscountDataResponse);
  }, [updatedDiscountDataResponse]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, otpInputs.length);
  }, [otpInputs.length]);

  const handleInputChange = (index, value) => {
    if (!isNaN(value) || value === "") {
      const newOtpInputs = [...otpInputs];
      newOtpInputs[index] = value;
      setOtpInputs(newOtpInputs);

      if (value !== "" && index < otpInputs.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otpInputs[index] === "") {
      const newOtpInputs = [...otpInputs];
      newOtpInputs[index - 1] = "";
      setOtpInputs(newOtpInputs);

      inputRefs.current[index - 1].focus();
    }
  };

  const clsoedMarketBulkBody = {
    earlyPaymentId: data?.earlyPaymentId,
    virtualAccountId: checkedAccounts?.id,
    payableAmount:
      updatedDiscountData?.data?.payableAmount || data?.totalPayableAmount,
    requestedAmount: data?.requestAmount || data?.totalRequestAmount,
    discountAmount:
      updatedDiscountData?.data?.discountedAmount || data?.totalDiscountAmount,
    transactionPin: otpInputs.join(""),
    funderId: selectedFunders,
    invoiceAmount: totalInvoiceAmount,
  };

  const closedMarketSinglebody = {
    earlyPaymentId: data?.earlyPaymentId,
    virtualAccountId: checkedAccounts?.id,
    payableAmount:
      updatedDiscountData?.data?.payableAmount || data?.payableAmount,
    requestedAmount: data?.requestAmount,
    discountAmount:
      updatedDiscountData?.data?.discountedAmount || data?.discountAmount,
    transactionPin: otpInputs.join(""),
    funderId: selectedFunders,
    invoiceAmount: invoiceIdData?.data?.grandTotal,
  };

  const funderbodyBulk = {
    earlyPaymentId: data?.earlyPaymentId,
    virtualAccountId: checkedAccounts?.id,
    invoiceAmount: totalInvoiceAmount,
    payableAmount:
      updatedDiscountData?.data?.totalPayableAmount || data?.totalPayableAmount,
    requestedAmount:
      updatedDiscountData?.data?.totalRequestAmount || data?.totalRequestAmount,
    discountAmount:
      updatedDiscountData?.data?.discountedAmount || data?.totalDiscountAmount,
    transactionPin: otpInputs.join(""),
    funderId: selectedFunder?.userId,
  };

  const funderbodySingle = {
    earlyPaymentId: earlyPaymentRequestId || data?.earlyPaymentId,
    virtualAccountId: checkedAccounts?.id,
    invoiceAmount: invoiceIdData?.data?.grandTotal,
    payableAmount:
      updatedDiscountData?.data?.payableAmount || data?.payableAmount,
    requestedAmount:
      updatedDiscountData?.data?.requestAmount || data?.requestAmount,
    discountAmount:
      updatedDiscountData?.data?.discountedAmount || data?.discountAmount,
    transactionPin: otpInputs.join(""),
    funderId: selectedFunder?.userId,
  };

  const walletBulkBody = {
    transactionPin: otpInputs.join(""),
    earlyPaymentId: data?.earlyPaymentId,
    payableAmount:
      updatedDiscountData?.data?.payableAmount || data?.totalPayableAmount,
    discountAmount:
      updatedDiscountData?.data?.discountedAmount || data?.totalDiscountAmount,
    virtualAccountId: checkedAccounts?.id,
  };

  const walletSingleBody = {
    transactionPin: otpInputs.join(""),
    earlyPaymentId: data?.earlyPaymentId,
    payableAmount:
      updatedDiscountData?.data?.payableAmount || data?.payableAmount,
    discountAmount:
      updatedDiscountData?.data?.discountedAmount || data?.discountAmount,
    virtualAccountId: checkedAccounts?.id,
  };

  const handleTransferProceed = async () => {
    if (closeMarketActive === true) {
      if (isBulk === true) {
        try {
          const response = await dispatch(
            approveSingleEarlyPaymentRequestViaClosed(clsoedMarketBulkBody)
          ).unwrap();
          if (response.status === 200) {
            confirm();
            setData([]);
            setUpdatedDiscountData([]);
          } else {
            toast.error(response.message);
            return;
          }
        } catch (error) {
          console.error("Error:", error);
          // setData([]);
          // Handle error (e.g., show a toast notification)
        }
      } else {
        try {
          const response = await dispatch(
            approveSingleEarlyPaymentRequestViaClosed(closedMarketSinglebody)
          ).unwrap();
          if (response.status === 200) {
            confirm();
            setData([]);
          } else {
            toast.error(response.message);
            return;
          }
        } catch (error) {
          console.error("Error:", error);
          // Handle error (e.g., show a toast notification)
        }
      }
    } else if (isDirectFundingActive === true) {
      if (isBulk === true) {
        try {
          const response = await dispatch(
            approveSingleEarlyPaymentRequestViaFunder(funderbodyBulk)
          ).unwrap();
          if (response.status === 200) {
            confirm();
            setData([]);
            setUpdatedDiscountData([]);
          } else {
            toast.error(response.message);
            // setData([]);
            // setUpdatedDiscountData([]);
            return;
          }
        } catch (error) {
          console.error("Error:", error);
          setData([]);
        }
      } else {
        try {
          const response = await dispatch(
            approveSingleEarlyPaymentRequestViaFunder(funderbodySingle)
          ).unwrap();
          if (response.status === 200) {
            confirm();
            setRequestData([]);
          } else {
            toast.error(response.message);
            // setRequestData([]);
            return;
          }
        } catch (error) {
          console.error("Error:", error);
          // setRequestData([]);
          // Handle error (e.g., show a toast notification)
        }
      }
    } else {
      if (isBulk === true) {
        try {
          const response = await dispatch(
            approveSingleEarlyPaymentRequest(walletBulkBody)
          ).unwrap();
          if (response.status === 200) {
            confirm();
            setData([]);
          } else {
            toast.error(response.message);
            return;
          }
        } catch (error) {
          console.error("Error:", error);
          // Handle error (e.g., show a toast notification)
        }
      } else {
        try {
          const response = await dispatch(
            approveSingleEarlyPaymentRequest(walletSingleBody)
          ).unwrap();
          if (response.status === 200) {
            confirm();
            setData([]);
          } else {
            toast.error(response.message);
            return;
          }
        } catch (error) {
          console.error("Error:", error);
          // Handle error (e.g., show a toast notification)
        }
      }
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="bg-[rgba(0,0,0,0.2)] w-[100%] h-[100vh] flex justify-center items-center absolute left-0 top-0">
        <div className="bg-white p10 px5 rounded-md max-w-xs md:max-w-lg w-full pb-5 px10">
          <div className="flex justify-between items-center py-5 px-6 rounded-t-[10px]  bg-[#FAFAFA]">
            <p className="text-sm text-[#7A7A7A] font-thin">Transfer Funds</p>
            <button
              onClick={onClose}
              className="gap-2 text-xl cursor-pointer flex justify-center items-center"
            >
              <PAIconCancelMini />{" "}
              <span className="text-sm text-[#565656] flex items-center">
                Close
              </span>
            </button>
          </div>

          {/* BVN Form */}
          <div className="w-full px-7 pt-7">
            <p className="mb-5 flex justify-center text-[#7A7A7A] font-thin">
              Enter your PIN
            </p>
            <div className="mb-5 flex flex-row justify-center gap-2">
              {otpInputs.map((value, index) => (
                <input
                  key={index}
                  type="password"
                  value={value}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  maxLength="1"
                  className={`border rounded p-2 ${
                    isDisabled
                      ? "border-[#7A7A7A] focus:border-[#7A7A7A]"
                      : "border-[#07593D]"
                  }    focus:outline-none w-10 h-10 flex-shrink-0 text-center font-bold`}
                  ref={(ref) => (inputRefs.current[index] = ref)}
                />
              ))}
            </div>
            <p className="text-[#7A7A7A] text-sm font-normal flex justify-center my-5">
              Enter your transaction PIN to transfer funds to desired account
            </p>

            {/* Buttons */}
            <div className="flex gap-4 justify-end mt-10">
              <button
                type="button"
                onClick={onClose}
                className="text-center px-6 py-2.5 rounded-[5px] text-[#7A7A7A] text-base font-normal font-['SF Pro Display'] tracking-wide"
              >
                Cancel
              </button>

              <button
                disabled={isDisabled}
                className={`text-center ${
                  isDisabled
                    ? "bg-[#EDEDED] text-[#7A7A7A]"
                    : "bg-primaryColor text-white"
                }  px-6 py-2.5 rounded-[5px]  text-base font-normal`}
                onClick={(e) => {
                  e.preventDefault();

                  handleTransferProceed();
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterPin;
