import { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import {
  PAIconSmallBrewIcon,
  PAIconSmallCoke,
  PAIconSmallDangote,
  PAIconSmallNestle,
  PAIconSmallShell,
  PAIconInvShop,
  PAIconCocacola,
  PAIconSmallAccessIcon,
} from "assets/images/svgs";
import { useDispatch } from "react-redux";
import { getInvoiceId } from "appstate/buyer/buyerSlice";

function RequestsCard({
  id,
  invoiceId,
  invoiceNumber = "N/A",
  invoiceReference = "N/A",
  supplierCompanyName = "N/A",
  requestAmount = "N/A",
  status = "N/A",
  statusRequest = "N/A",
  requestType,
  numberOfInoices = "N/A",
  buyerRate = "N/A",
  earlypaymentDate = "N/A",
}) {
  const navigate = useNavigate();
  const [timer, setTimer] = useState("");
  const dispatch = useDispatch();

  const onRowClick = () => {
    dispatch(
      getInvoiceId({
        id: id,
        invoiceId: invoiceId,
      })
    );
    navigate(`/closedmarket/requestsdetails`);
  };

  function startCountdown(futureDate) {
    // Convert the future date string to a Date object
    const targetDate = new Date(futureDate + "T00:00:00"); // Set time to midnight

    // Update the countdown every second
    const countdownInterval = setInterval(() => {
      const now = new Date(); // Get the current date and time
      const timeDifference = targetDate - now; // Calculate the time difference in milliseconds

      // If the countdown is finished, clear the interval and display a message
      if (timeDifference <= 0) {
        clearInterval(countdownInterval);
        return;
      }

      // Calculate days, hours, minutes, and seconds
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      // Format the countdown string
      const countdownString = `${String(days).padStart(2, "0")}d:${String(
        hours
      ).padStart(2, "0")}h:${String(minutes).padStart(2, "0")}m:${String(
        seconds
      ).padStart(3, "0")}s`;

      setTimer(countdownString);
    }, 1000);
  }

  // Example usage
  startCountdown(earlypaymentDate);

  return (
    <motion.div
      onClick={onRowClick}
      whileTap={{ scale: 0.98 }}
      style={{ boxShadow: "0px 2px 4px 0px rgba(232, 226, 216, 0.36)" }}
      className="flex cursor-pointer justify-between bg-white py-5 px-6 rounded-[10px]"
    >
      <span className="flex gap-6  items-center">
        <PAIconInvShop />
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceReference}</span>
          <span className=" text-[#959595] text-[10px]">Reference number</span>
        </div>
      </span>

      <span className="flex gap-6  items-center">
        {requestType === "single" ? (
          <div className="flex flex-col justify-center">
            <span className="font-[500] text-sm">INV-{invoiceNumber}</span>
            <span className=" text-[#959595] text-[10px]">Invoice number</span>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center">
            <span className="font-[500] text-sm">{numberOfInoices}</span>
            <span className=" text-[#959595] text-[10px]">
              Number of invoices
            </span>
          </div>
        )}
      </span>

      <span className="flex gap-6 items-center ">
        {supplierCompanyName === "Access Plc" ? (
          <PAIconSmallAccessIcon />
        ) : supplierCompanyName === "Cocacola" ? (
          <PAIconSmallCoke />
        ) : supplierCompanyName === "Dangote" ? (
          <PAIconSmallDangote />
        ) : supplierCompanyName === "Unity bank" ? (
          <PAIconSmallNestle />
        ) : supplierCompanyName === "XYZ Funds" ? (
          <PAIconSmallShell />
        ) : supplierCompanyName === "Trust Ltd." ? (
          <PAIconSmallBrewIcon />
        ) : (
          <PAIconCocacola />
        )}

        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{supplierCompanyName}</span>
          <span className=" text-[#959595] text-[10px]">{"Supplier"}</span>
        </div>
      </span>

      <span className="flex gap-6  items-center">
        <div className="flex flex-col justify-center">
          <span
            className={
              status === "POSTED"
                ? "font-[500] text-sm text-primaryColor"
                : "font-[500] text-sm text-secondaryColor"
            }
          >
            {status === "POSTED" ? "Posted" : "Bid Active"}
          </span>
          <span className=" text-[#959595] text-[10px]">Status of request</span>
        </div>
      </span>

      <span className="flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">
            NGN {requestAmount?.toLocaleString()}
          </span>
          <span className=" text-[#959595] text-[10px]">Request Amount</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{buyerRate}</span>
          <span className=" text-[#959595] text-[10px]">Buyer's Rate</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{earlypaymentDate}</span>
          <span className=" text-[#959595] text-[10px]">
            Early payment date
          </span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{timer ? timer : "00:00"}</span>
          <span className=" text-[#959595] text-[10px]">
            {statusRequest === "Posted"
              ? "Count down to Early Payment"
              : "Count down to the end of bid"}
          </span>
        </div>
      </span>
    </motion.div>
  );
}

export default RequestsCard;
