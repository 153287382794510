import { useState, useEffect } from "react";
import emptyBid from "../../../../../assets/images/svgs/empty-bid.png";
// import cocaCola from "../../../../../assets/images/svgs/cocacola.svg";

const BidDetails = ({ data }) => {
  const [isActive, setIsActive] = useState("history");
  const [tab, setTab] = useState("payment");
  const [timer, setTimer] = useState("");

  useEffect(() => {
    if (data && data.bidHistoryResponse && data.bidHistoryResponse.length > 0) {
      const remainingTime = data.bidHistoryResponse[0].remainingTime;
      if (remainingTime && remainingTime.length === 3) {
        const year = remainingTime[0];
        const month = String(remainingTime[1]).padStart(2, "0");
        const day = String(remainingTime[2]).padStart(2, "0");
        const futureDate = `${year}-${month}-${day}`;

        function startCountdown(futureDate) {
          const targetDate = new Date(futureDate + "T00:00:00");

          const countdownInterval = setInterval(() => {
            const now = new Date();
            const timeDifference = targetDate - now;

            if (timeDifference <= 0) {
              clearInterval(countdownInterval);
              return;
            }

            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hours = Math.floor(
              (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor(
              (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

            const countdownString = `${String(days).padStart(2, "0")}d:${String(
              hours
            ).padStart(2, "0")}h:${String(minutes).padStart(2, "0")}m:${String(
              seconds
            ).padStart(2, "0")}s`;

            setTimer(countdownString);
          }, 1000);
        }

        startCountdown(futureDate);
      } else {
        console.error(
          "remainingTime is not defined or does not have the expected length."
        );
      }
    } else {
      console.error("data or bidHistoryResponse is not defined or is empty.");
    }
  }, [data]);

  return (
    <div className="bg-white w-full rounded-[10px] pt-5 pb-24 h-[100%]">
      <div className="max-h-[15%]">
        <div className="flex items-center border-b-[1px] border-b-slate-300">
          <h2
            onClick={() => {
              setTab("payment");
            }}
            style={{
              color: tab === "payment" ? "#07593D" : "#777777",
              borderBottom: tab === "payment" ? "1px solid #07593D" : "none",
            }}
            className="text-lg px-6 font-semibold text-gray-800 pb-4"
          >
            Early Payment Details
          </h2>
          <h2
            onClick={() => {
              setTab("bids");
            }}
            style={{
              color: tab === "bids" ? "#07593D" : "#777777",
              borderBottom: tab === "bids" ? "1px solid #07593D" : "none",
            }}
            className="text-lg px-6 font-semibold text-gray-800 pb-4 text-primaryColor"
          >
            Bid Details
          </h2>
        </div>

        {tab === "payment" ? null : (
          <div className="flex justify-between text-sm text-gray-600 mt-4 px-6">
            <span
              onClick={() => {
                setIsActive("history");
              }}
              className={
                isActive === "history"
                  ? "text-secondaryColor border-b-[1px] border-secondaryColor"
                  : "text-[#959595]"
              }
            >
              Bid history: 0
            </span>
            <span
              onClick={() => {
                setIsActive("invited");
              }}
              className={
                isActive === "invited"
                  ? "text-secondaryColor border-b-[1px] border-secondaryColor"
                  : "text-[#959595]"
              }
            >
              Funders invited: {data?.funderInvitedResponse?.length}
            </span>
            <span
              onClick={() => {
                setIsActive("viewed");
              }}
              className={
                isActive === "viewed"
                  ? "text-secondaryColor border-b-[1px] border-secondaryColor"
                  : "text-[#959595]"
              }
            >
              Viewed by funders: {data?.viewByFunderResponse?.length}
            </span>
          </div>
        )}
      </div>

      {tab === "payment" ? (
        <div className="px-4">
          <div className="flex justify-between my-5">
            <div className="flex gap-2">
              {/* <PAIconModelOne /> */}
              <div className="flex flex-col">
                <span className="text-sm">Supplier</span>
                <span className="text-[#979797]  text-xs">
                  {" "}
                  {data?.supplierCompanyName}
                </span>
              </div>
            </div>
            <div className="flex flex-col items-end">
              <span className="text-sm">{data?.buyerDiscountRate}%</span>
              <span className="text-[#979797]  text-xs">Discount rate</span>
            </div>
          </div>

          <div className="flex justify-between pb-5">
            <div className="flex gap-2">
              <div className="flex flex-col">
                <span className="text-sm">
                  N{data?.discountAmount?.toLocaleString() || "0.00"}
                </span>
                <span className="text-[#979797]  text-xs">Discount Amount</span>
              </div>
            </div>
            <div className="flex flex-col items-end">
              <span className="text-sm">
                {data?.earlyPaymentDate}({data?.paymentDay} days)
              </span>
              <span className="text-[#979797]  text-xs">
                Early Payment Request Date
              </span>
            </div>
          </div>

          <div className="flex justify-between mt-5 pb-10">
            <div className="flex gap-2">
              <div className="flex flex-col">
                <span className="text-sm">
                  NGN {data?.requestAmount?.toLocaleString() || "0.00"}
                </span>
                <span className="text-[#979797]  text-xs">Request Amount</span>
              </div>
            </div>
            <div className="flex flex-col">
              <span className="font-semibold text-[16px]">
                NGN {data?.payableAmount?.toLocaleString()}
              </span>
              <span className="text-[#979797] text-xs">Payable amount</span>
            </div>
          </div>
        </div>
      ) : (
        <>
          {isActive === "history" && (
            <div className="flex flex-col justify-center items-center mb-6 h-[65%]">
              <img src={emptyBid} alt="No Data" className="h-80 w-auto" />
              <div className="text-center text-3xl font-bold text-gray-800 mb-2">
                {timer}
              </div>
              <p className="text-center text-gray-500 mb-4">
                Count down to early payment date
              </p>
            </div>
          )}

          {isActive === "invited" && (
            <div className="flex flex-col justify-start px-5 mb-6 h-[65%]">
              {data?.funderInvitedResponse?.map((item, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center mb-4"
                >
                  <div className="flex items-center my-2">
                    {/* <img
                  src={item.img}
                  alt="Funder"
                  className="w-10 h-10 rounded-full mr-4"
                /> */}
                    <div>
                      <p className="text-[16px] font-regular text-gray-600">
                        {item.funderName}
                      </p>
                      <p className="text-[12px] font-regular text-zinc-400">
                        Funder
                      </p>
                    </div>
                  </div>

                  <div className="text-center">
                    <p className="text-[14px] ">{item.contactNumber}</p>
                    <p className="text-[12px] text-zinc-400">Contact number</p>
                  </div>

                  <div className="text-right">
                    <p className="text-sm text-gray-600">{item.funderEmail}</p>
                    <p className="text-[12px] text-zinc-400">Email</p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {isActive === "viewed" && (
            <div className="flex flex-col justify-start px-5 mb-6 h-[65%]">
              {data.viewByFunderResponse.length > 0 ? (
                <>
                  {data?.viewByFunderResponse?.map((item, index) => (
                    <div
                      key={index}
                      className="flex justify-between items-center mb-4"
                    >
                      <div className="flex items-center my-2">
                        <img
                          src={item.img}
                          alt="Funder"
                          className="w-10 h-10 rounded-full mr-4"
                        />
                        <div>
                          <p className="text-[16px] font-regular text-gray-600">
                            {item.name}
                          </p>
                          <p className="text-[12px] font-regular text-zinc-400">
                            Funder
                          </p>
                        </div>
                      </div>

                      <div className="text-center">
                        <p className="text-[14px] ">{item.number}</p>
                        <p className="text-[12px] text-zinc-400">
                          Contact number
                        </p>
                      </div>

                      <div className="text-right">
                        <p className="text-sm text-gray-600">{item.number}</p>
                        <p className="text-[12px] text-zinc-400">Email</p>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="flex flex-col justify-center items-center mb-6 h-[65%]">
                  <img src={emptyBid} alt="No Data" className="h-80 w-auto" />

                  <p className="text-center text-gray-500 mb-4">
                    Viewed funder list is empty
                  </p>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BidDetails;
