import React, { useEffect, useState } from "react";
import Funded from "./funded";
import AllInvoices from "./all";
import Pending from "./pendingfunding";
import {  getPendingDirectFundingConfirmationBoth } from "appstate/api/invoice/invoice";

const DashboardContent = () => {
  const [activeTab, setActiveTab] = useState("myinvoices");
  const [data, setData] = useState([]);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [error, setError] = useState(null);
  // eslint-disable-next-line
  const [size, setSize] = useState(10);
  // eslint-disable-next-line
  const [page, setPage] = useState(0);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "myinvoices":
        return <AllInvoices />;
      case "requests":
        return <Pending />;
      case "reportsAndAnalysis":
        return <Funded />;

      default:
        return null;
    }
  };

  const fetchPendingData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getPendingDirectFundingConfirmationBoth({
        size,
        sort: "invoiceDate",
        // bulk: false,
        sortDirection: "asc",
        page,
      });
      setData(response?.data);
    } catch (err) {
      setError(err?.response?.data?.message || "Failed to fetch data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPendingData();
    // eslint-disable-next-line
  }, [page, size]);

  return (
    <div>
      <div className="mt5 mx6 mb-[17px] w-full cursor-pointer rounded-[10px] bg-white py-2">
        <div className=" flex-col items-center justify-between gap-10 p-2 px-4 md:flex md:flex-row">
          <div className="flex-col gap-10 md:flex md:flex-row">
            <div
              className={
                activeTab === "myinvoices"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 text-base font-normal"
              }
              onClick={() => handleTabClick("myinvoices")}
            >
              Invoices
            </div>
            <div
              className={
                activeTab === "requests"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 my-2 text-base font-normal md:my-0"
              }
              onClick={() => handleTabClick("requests")}
            >
              Pending Confirmation
              <span className="bg-[#FFEAD3] rounded-[20px] p-1 px-2 ml-1 text-[#07593d]  ">
                {data?.length}
              </span>
            </div>
            {/* <div
              className={
                activeTab === "reportsAndAnalysis"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 text-base font-normal"
              }
              onClick={() => handleTabClick("reportsAndAnalysis")}
            >
              Funded
            </div> */}
          </div>
        </div>
      </div>
      {renderTabContent()}
    </div>
  );
};

export default DashboardContent;
