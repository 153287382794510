import React, { useEffect } from "react";
import { PAIconSmallClose } from "assets/images/svgs";
import bin from "../../../../assets/images/svgs/rejectionBin.svg";
import { useNavigate } from "react-router-dom";

const RejectionSuccess = ({ onClose, isOpen, confirm, isRejected }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen === true) {
      setTimeout(() => {
        navigate("/payablefinancing/overview");
      }, 2000);
    }
  }, [navigate, isOpen]);

  if (!isOpen) {
    return null;
  }
  return (
    <div className="bg-[rgba(0,0,0,0.2)] w-[100%] h-[100vh] flex justify-center items-center absolute left-0 top-0">
      <div className=" py-9 bg-white rounded-[10px] w-[40%] flex flex-col">
        <header className="flex mx-6 sticky top-0 bg-white justify-between pb-4 border-b-[3px] border-[#F6F5FA]">
          <span className=" text-[#222] font-bold">
            Early payment request rejected
          </span>

          <span
            onClick={onClose}
            className="flex items-center cursor-pointer gap-2 text-sm font-[500] text-[#565656]"
          >
            <PAIconSmallClose />
            Close
          </span>
        </header>

        <div className="flex flex-col pt-2">
          <div className="flex items-center justify-center mt-[3%]">
            <img className="w-[40%]" src={bin} alt="" />
          </div>

          <div className="flex flex-col gap-3 py-5 px-6 text-[#959595]">
            <span className=" text-[16px] font-[500]">
              Request Declined. Thank you for your consideration. Feel free to
              explore more opportunities on our platform. We appreciate your
              partnership
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectionSuccess;
