import { PAIconSearchGreen } from "assets/images/svgs";
import Card from "shared/Card";
import { motion } from "framer-motion";
import { setClosedMarketActiveTab } from "appstate/funder/funderSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

import { useSelector } from "react-redux";

function FunderClosedMarketHeader({ searchQuery, setSearchQuery }) {
  const dispatch = useDispatch();
  const handleTabClick = (tabNumber) => {
    dispatch(setClosedMarketActiveTab(tabNumber));
    sessionStorage.setItem("activeTab", tabNumber);
  };

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    sessionStorage.setItem("searchQuery", value);
  };

  const handleFilterButtonClick = () => {
    //In progress, coming soon
  };

  const { closeMarketactiveTab } = useSelector((state) => state?.funder);
  useEffect(() => {
    if (
      closeMarketactiveTab !== 2 &&
      closeMarketactiveTab !== 3 &&
      closeMarketactiveTab !== 4
    ) {
      dispatch(setClosedMarketActiveTab(1));
    }
  }, [closeMarketactiveTab, dispatch]);

  return (
    <Card className="pl5 pr-2 rounded-[5px] h-16  flex items-center justify-between">
      <div className="flex h-full items-center overflow-x-auto space-x-1">
        <div
          className={`cursor-pointer relative h-full pt-5 text-sm overflow-hidden  text-[#959595] ${
            closeMarketactiveTab === 1
              ? " text-primaryColor font-bold"
              : "text-gray-400"
          }`}
          onClick={() => handleTabClick(1)}
        >
          <span className="px-5 text-center flex justify-center w-full">
            Overview
          </span>

          {closeMarketactiveTab === 1 && (
            <div className="absolute bottom-0 left-0 w-full  h-1 rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div>
        <div
          className={`cursor-pointer relative h-full pt-5 text-center text-sm overflow-hidden  text-[#959595] ${
            closeMarketactiveTab === 2
              ? "text-primaryColor font-bold"
              : "text-gray-400"
          }`}
          onClick={() => handleTabClick(2)}
        >
          <span className="px-5 text-center flex justify-center w-full ">
            Closed Market
          </span>
          {closeMarketactiveTab === 2 && (
            <div className="absolute bottom-0 left-0 w-full h-1 rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div>
        <div
          className={`cursor-pointer relative  h-full overflow-hidden  pt-5 text-sm text-[#959595] ${
            closeMarketactiveTab === 3
              ? "text-primaryColor font-bold"
              : "text-gray-400"
          }`}
          onClick={() => handleTabClick(3)}
        >
          <span className="px-5 flex justify-center text-center w-full">
            Ongoing Bid
          </span>
          {closeMarketactiveTab === 3 && (
            <div className="absolute bottom-0 left-0 w-full h-1 rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div>

        <div
          className={`cursor-pointer relative   h-full pt-5 overflow-hidden  text-sm text-[#959595] ${
            closeMarketactiveTab === 4
              ? "text-primaryColor font-bold"
              : "text-gray-400"
          }`}
          onClick={() => handleTabClick(4)}
        >
          <span className="px-5 text-center flex justify-center  w-full">
            Won Bids
          </span>
          {closeMarketactiveTab === 4 && (
            <div className="absolute bottom-0 left-0 w-full h-1 rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div>
      </div>
      <div className="hidden md:flex w-[35%]  items-center gap-2">
        <div className="flex gap-2.5 w-full bg-white border-[0.5px] border-[#ccc] p-2.5 items-center rounded-[5px]">
          <PAIconSearchGreen />

          <input
            type="text"
            placeholder="Search for invoices on the closed market"
            value={searchQuery}
            onChange={handleSearchInputChange}
            className="px-2 w-full text-xs flex items-center text-black placeholder:text-black focus:outline-none"
          />
        </div>

        <motion.button
          whileTap={{ scale: 0.9 }}
          onClick={handleFilterButtonClick}
          className="h-[41px] px-6 py-3 bg-[#2fa069] rounded-[5px] border border-[#cccccc] justify-center items-center gap-2.5 inline-flex"
        >
          <div class="w-[47px] text-white text-sm font-normal ">Search</div>
        </motion.button>
      </div>
    </Card>
  );
}

export default FunderClosedMarketHeader;
