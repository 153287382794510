import request from "apiInstance";
import toast from "react-hot-toast";

export const getAllFunderPendingPfp = async ({ page, pageSize, search }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/pending-invites?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const acceptToJoinPfp = async ({ id, accountId }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/payable-finance/funder/sign-agreement/${id}?fundingAccountId=${accountId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const pfpRequestDecline = async ({ id }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/payable-finance/reject-invite/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

// export const pfpViewById = async ({ id }) => {
//   try {
//     const response = await request({
//       method: "get",
//       url: `/api/v1/payable-finance/view/funder?pfpID=${id}`,
//     });
//     return response?.data;
//   } catch (error) {
//     toast.error(error?.response?.data?.message + "!");
//   }
// };
export const pfpViewById = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/funder/pfp/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const pfpViewAgreementDetailsById = async ({ pfpId }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/funder/agreement-details/${pfpId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getEarlyPaymentFunderConfigApi = async (
  programType = "EARLY_PAYMENT_REQUEST"
) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/rule/get-discount-config?programType=${programType}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllFunderPfp = async ({ page, pageSize, search }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/funder/pfp-list?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getFullFunderPfp = async ({ id, page, pageSize, search }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/funder/pfp-list/${id}/invoices?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const sendRequestToLeavePfp = async ({ pfpId }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/payable-finance/exit-program?pfpID=${pfpId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
// export const getAllFunderPfpInvoices = async ({ page, pageSize, search }) => {
//   try {
//     const response = await request({
//       method: "get",
//       url: `/api/v1/payable-finance/funder/pfp-invoices?page=${page}&size=${pageSize}&search=${
//         search || ""
//       }`,
//     });
//     return response?.data;
//   } catch (error) {
//     toast.error(error?.response?.data?.message + "!");
//   }
// };

export const getAllFunderPfpInvoices = async ({ pfpId, offerId }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/funder/pfp/${pfpId}/invoice/${offerId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const fundSelectedPfpInvoice = async ({ pfpId, body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/payable-finance/fund-invoice/${pfpId}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderEarlyPaymentSingleList = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/funder/views-pending/single`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderEarlyPaymentBulkList = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/funder/views-pending/bulk`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderEarlyPaymentSingleRejectedList = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/funder/views-rejected/single`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderEarlyPaymentBulkRejectedList = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/funder/views-rejected/bulk`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderEarlyPaymentSingleApprovedList = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/funder/views-approved/single`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderEarlyPaymentBulkApprovedList = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/funder/views-approved/bulk`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderEarlyPaymentSinglePendingDetails = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/funder/view-single/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderEarlyPaymentSingleRejectedDetails = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/funder/view-rejected-single/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderEarlyPaymentBulkRejectedDetails = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/funder/view-rejected-bulk/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderEarlyPaymentBulkPendingDetails = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/funder/view-bulk/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderEarlyPaymentSingleApprovedDetails = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/funder/view-approved-single/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderEarlyPaymentBulkApprovedDetails = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/funder/view-approved-bulk/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const approveDirectFundingRequestFromBuyer = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/fund-request/early-payment-request/direct-funding`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const rejectSingleEarlyPaymentRequestbyFunder = async (id, reasons) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/fund-request/early-payment-request/reject-request/${id}?fundingChannel=DIRECT_FUNDING&rejectionType=REJECTED_BY_FUNDER&userType=FUNDER&rejectionReason=${reasons}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
