import React from "react";

import OverViewCard from "./card/OverViewCard";
import { setClosedMarketActiveTab } from "appstate/funder/funderSlice";
import { useDispatch } from "react-redux";
import { setOngoingBidType } from "features/onboarding/supplier/supplierVerifcationSlice/verificationSlice";
import { useEffect } from "react";
import {
  funderFetchClosedMarketDashBoard,
  allClosedMarketWonBids,
} from "appstate/funder/funderSlice";
import { useSelector } from "react-redux";
import TableEmptyState from "shared/TableEmptyState";

const OverView = () => {
  const dispatch = useDispatch();

  const handleTabClick = (tabNumber) => {
    sessionStorage.setItem("activeTab", tabNumber);
    dispatch(setClosedMarketActiveTab(tabNumber));
    dispatch(setOngoingBidType("Bid Active"));
  };
  const handleTabClicktwo = (tabNumber) => {
    sessionStorage.setItem("activeTab", tabNumber);
    dispatch(setClosedMarketActiveTab(tabNumber));
    dispatch(setOngoingBidType("Currently Leading"));
  };

  const { closedMarketDashBoard, allClosedMarketWonBidsData } = useSelector(
    (state) => state.funder
  );

  useEffect(() => {
    dispatch(funderFetchClosedMarketDashBoard());
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      allClosedMarketWonBids({
        page: 0,
        pageSize: 10,
      })
    );
  }, [dispatch]);

  return (
    <>
      <div>
        <div className="flex flex-col semi-lg:flex-row gap-7 w-full ">
          <section className=" rounded-[5px] bg-unusualGray flex gap-6  extra-md:flex-row px-6 py-8 w-full semi-lg:w-[70%]  flex-col semi-lg:flex-row">
            <div className="flex w-full semi-lg:w-auto  flex-col gap-[18px]">
              <div className="text-white flex w-full  border-b-2 border-b-white rounded-[10px] flex-col items-center justify-center gap-3 bg-[#F08000] px-[73px] py-5">
                {/* <span className=" text-[50px]">{"N/A"}</span> */}
                <div class=" text-white text-[38.84px] font-bold ">
                  {" "}
                  {closedMarketDashBoard?.totalRequestsInClosedMarket}
                </div>
                <div class=" text-center text-white   text-lg font-normal   ">
                  Requests in the Closed market
                </div>
              </div>

              {/* stops */}
            </div>

            <div className="flex gap-4 flex-col w-full  semi-lg:w-1/2 justify-between">
              <div
                className={`p-4 itemscenter bg-white rounded-b-md border-t-2 min-h-[46%]   py-5 `}
                style={{ borderColor: "#F08000" }}
              >
                <div className="flex item-center justify-between ">
                  <div class="text-[#07593d] text-xl font-normal ">Request</div>
                  <div class="h-[31px] px-2.5 py-[5px] bg-[#f08000] rounded-[5px]  justify-start items-center gap-2.5 inline-flex">
                    <div class="w-[75px] h-[21px] text-center text-white text-base font-normal ">
                      Insight
                    </div>
                  </div>
                </div>
                <div className="flex justify-between mt-4">
                  {/* {section.data.map((item, idx) => ( */}
                  {/* second step */}
                  <div className="  w-1/2   flex-row   justify-center items-center ">
                    <div class="text-center text-[#07593d] text-[32px]    font-semibold ">
                      {closedMarketDashBoard?.totalPostedRequests}
                    </div>
                    {/* <div class="text-center text-[#07593d] text-[40px] font-bold font-['Inter']">
                      4,537
                    </div> */}
                    <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                      {"Posted"}
                    </h2>
                  </div>

                  {/* another step */}

                  <div className="flex-row  border-l-2 border-[#07593d] w-1/2 px-2">
                    <div class="text-center text-[#f08000] text-[32px]   font-bold">
                      {closedMarketDashBoard?.totalBidActiveRequests}
                    </div>
                    <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                      {"Bid Active"}
                    </h2>
                  </div>
                  {/* ))} */}
                </div>
              </div>

              {/* second layer */}
            </div>
          </section>

          <section className="flex gap-4 flex-col justify-between">
            <div
              className=" border-l-2 border-white flex items-center justify-between px-14  gap-4 bg-primaryColor rounded-r-[10px] cursor-pointer text-white  py-6"
              onClick={() => handleTabClick(3)}
            >
              <div class="text-white text-[41.84px] font-bold ">
                {" "}
                {closedMarketDashBoard?.totalBidsNotLeading}
              </div>
              <div class="w-[168px] text-right text-white text-sm font-medium font-['SF Pro Display']">
                Active bids you are not currently leading
              </div>
            </div>

            <div
              className=" border-l-2 border-primaryColor flex items-center justify-between px-14  gap-4 bg-white  rounded-r-[10px] cursor-pointer text-white  py-6"
              onClick={() => handleTabClicktwo(3)}
            >
              <div class="text-primaryColor text-[41.84px] font-bold ">
                {" "}
                {closedMarketDashBoard?.totalBidsCurrentlyLeading}
              </div>
              <div class="w-[168px] text-right text-[#565656] text-sm font-medium ">
                Active bids you are currently leading
              </div>
            </div>
          </section>

          {/* LIST */}
        </div>

        <div className="flex flex-col gap-6 rounded-[10px] bg-unusualGray  p-6 mt-12">
          <header className="pb-4 border-b border-b-subtleGray flex flex-row gap-1 justify-between">
            <div>
              <div>
                {" "}
                <span className=" font-semibold">Won bids</span>
              </div>{" "}
              <span class="text-[#949494] text-xs font-normal ">
                Your won bids that are liened for funding
              </span>
            </div>
            <div>
              <button
                class="text-black text-sm font-medium  underline"
                onClick={() => handleTabClick(4)}
              >
                View All
              </button>
            </div>
          </header>

          <div className="w-full  overflow-x-auto py-4 ">
            <div className="flex flex-col gap-2.5 ">
              {allClosedMarketWonBidsData?.data?.length > 0 ? (
                <div className="flex flex-col gap-2.5 ">
                  {allClosedMarketWonBidsData?.data &&
                    allClosedMarketWonBidsData?.data?.length > 0 &&
                    allClosedMarketWonBidsData?.data
                      ?.slice(0, 4)
                      .map((bids, idx) => (
                        <OverViewCard
                          key={idx}
                          id={bids.id}
                          invoiceNumber={bids.closedMarketReference}
                          buyerName={bids.buyerCompanyName}
                          invoiceType={`${
                            bids.bulk ? "Bulk Invoice" : "Single Invoice"
                          }`}
                          lockRate={bids.lockRate}
                          statusOfInvoice={bids.status}
                          requestAmount={bids.requestAmount}
                          dueDate={bids?.invoiceDueDate}
                        />
                      ))}
                </div>
              ) : (
                <TableEmptyState dashboard />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverView;
