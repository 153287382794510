import React, { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { PAIconCancelMini, PAIconEditlight } from "assets/images/svgs";
import { formatCurrency } from "utilities/helper/formatters";
import toast from "react-hot-toast";
import { formatDate } from "utilities/helper/dateFormatterSpelt";
import { getOpenMarketPercentageLimit } from "appstate/openMarket/openMarketSlice";
import { useDispatch, useSelector } from "react-redux";

const PercentageFundingModal = ({
  invoiceData = [],
  isOpen,
  onClose,
  fundingPercentage,
  paymentDate,
  setPaymentDate,
  setFundingPercentage,
  setAcceptModal,
}) => {
  const dispatch = useDispatch();
  let filled = fundingPercentage && paymentDate;
  const [editToggle, setEditToggle] = useState(false);
  const inputRef = useRef(null);
  const { getOpenMarketPercentageLimitData } = useSelector(
    (state) => state?.openMarket
  );

  const handleChange = (e) => {
    const value = e.target.value.replace("%", ""); // Remove % for processing
    if (!value || /^\d+$/.test(value)) {
      const numericValue = parseInt(value, 10);

      if (numericValue >= 0 && numericValue <= 80) {
        setFundingPercentage(value);
      } else if (value === "") {
        setFundingPercentage("");
      } else if (numericValue > 80) {
        return toast.error(
          "The maximum allowable request on each invoice is 80%"
        );
      }
    }

    setTimeout(() => {
      const inputElement = inputRef.current;
      if (inputElement) {
        inputElement.setSelectionRange(
          inputElement.value.length - 1,
          inputElement.value.length - 1
        );
      }
    }, 0);
  };

  const handlePercentageUpdate = () => {
    const pendingToast = toast.loading("Updating invoice details...");
    setTimeout(() => {
      toast.dismiss(pendingToast);
      toast.success("Percentage and Due date Updated Sucessfully");
      setEditToggle(true);
    }, 700);
  };

  useEffect(() => {
    dispatch(getOpenMarketPercentageLimit());
  }, [dispatch]);
  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          {/* Overlay */}
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
          ></motion.div>

          {/* Modal with Entrance & Exit Animation */}
          <motion.div
            className="relative z-[999999] bg-white rounded-lg shadow-lg p-6 w-full max-w-lg mx-4 sm:mx-auto top-[5%] max-h-[75vh] h-fit transform flex flex-col"
            initial={{ opacity: 0, y: -50, scale: 0.9 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: -50, scale: 0.9 }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
          >
            {/* Header */}
            <div className="flex justify-between items-center border-b-[3px] border-[#F6F5FA] pb-4 mb-4">
              <h2 className="font-[300]">
                Specify percentage and funding date for invoice
              </h2>
              <span
                onClick={() => {
                  setEditToggle(false);
                  onClose();
                }}
                className="text-gray-500 cursor-pointer flex items-center gap-2 hover:text-gray-700"
              >
                <PAIconCancelMini />
                <span className="text-sm text-[#565656]">Close</span>
              </span>
            </div>

            <section className="flex flex-col gap-3 font-light text-[#959595]">
              <div className="text-sm">
                Please indicate the percentage of the invoice and payment date
                for which funding is required.
              </div>

              <div className="text-sm text-secondaryColor font-light">
                Note: The maximum allowable request on each invoice is{" "}
                {
                  getOpenMarketPercentageLimitData?.data?.defaultDiscountRates?.find(
                    (program) => program?.programType === "OPEN_MARKET"
                  )?.percentageLimit
                }
                %.
              </div>
            </section>

            <motion.section
              className="mt-8 border p-2.5 w-full rounded border-gray max-h-[60%] overflow-y-auto overflow-x-hidden mb-6 flex flex-col gap-5 scrollbar-hide scroll-smooth"
              layout // Enables layout transitions for the whole section
              initial={{ opacity: 0, y: 50 }} // Section enter animation
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.5 }}
            >
              <AnimatePresence mode="sync">
                {invoiceData?.map((invoice) => (
                  <motion.div
                    key={invoice?.invoiceRef}
                    className="flex justify-between"
                    layout // Smooth position changes for rows
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                  >
                    {/* Invoice Reference Column */}
                    <motion.div
                      layout
                      className="font-light flex flex-col w-fit gap-2"
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -20 }}
                      transition={{ duration: 0.3 }}
                    >
                      <span className="text-sm">{invoice?.invoiceRef}</span>
                      <span className="text-xs text-[#959595]">Invoice</span>
                    </motion.div>

                    {/* Buyer Name Column */}
                    <motion.div
                      layout
                      className="font-light flex flex-col w-fit gap-2"
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -20 }}
                      transition={{ duration: 0.3 }}
                    >
                      <span className="text-sm">{invoice?.buyerName}</span>
                      <span className="text-xs text-[#959595]">Buyer</span>
                    </motion.div>

                    {/* Invoice Amount Column */}
                    <motion.div
                      layout
                      className="font-light flex justify-end flex-col w-fit gap-2"
                      initial={{ opacity: 0, x: 20 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: 20 }}
                      transition={{ duration: 0.3 }}
                    >
                      <span className="text-sm flex justify-end">
                        {formatCurrency(invoice?.subTotal)}
                      </span>
                      <span className="text-xs flex justify-end text-[#959595]">
                        Invoice Amount
                      </span>
                    </motion.div>

                    {/* Funding Percentage Column */}
                    {fundingPercentage && (
                      <motion.div
                        layout
                        className="font-light flex justify-end flex-col w-fit gap-2"
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: 20 }}
                        transition={{ duration: 0.3 }}
                      >
                        <span className="text-sm flex justify-end">
                          {formatCurrency(
                            invoice?.subTotal * (fundingPercentage / 100)
                          )}
                        </span>
                        <span className="text-xs flex justify-end text-[#959595]">
                          Request Amount
                        </span>
                      </motion.div>
                    )}
                  </motion.div>
                ))}
              </AnimatePresence>
            </motion.section>

            <footer className="py-5 flex flex-col gap-4">
              <AnimatePresence mode="wait">
                {editToggle ? (
                  <motion.div
                    key="editMode"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    transition={{ duration: 0.3 }}
                    className="flex flex-col gap-4"
                  >
                    <div className="flex flex-col gap-5">
                      <div className="text-sm flex justify-end font-light">
                        <button
                          onClick={() => {
                            setEditToggle(false);
                          }}
                          className="py-2.5 px-2 rounded-[5px] border border-secondaryColor flex items-center gap-2 "
                        >
                          <PAIconEditlight />
                          <span>Edit percentage and date</span>
                        </button>
                      </div>

                      <div className="text-sm">
                        The payment date scheduled for all invoices is{" "}
                        <span className="text-secondaryColor">
                          {formatDate(paymentDate)}
                        </span>{" "}
                        with a funding percent of{" "}
                        <span className="text-secondaryColor">
                          {fundingPercentage}%.
                        </span>
                      </div>
                    </div>

                    <div className="w-full">
                      <button
                        onClick={() => {
                          onClose();
                          setAcceptModal(true);
                        }}
                        className={`${
                          filled ? "bg-primaryColor" : "bg-[#959595]/30"
                        }
            text-sm rounded-[5px] w-full text-white font-light px-6 py-3`}
                      >
                        Send to Buyer for Confirmation
                      </button>
                    </div>
                  </motion.div>
                ) : (
                  <motion.div
                    key="inputMode"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    transition={{ duration: 0.3 }}
                    className="flex flex-col gap-4"
                  >
                    <div className="flex justify-between">
                      <div className="flex w-[45%] flex-col gap-1">
                        <span className="text-sm font-light">
                          Set percent of amount for funding
                        </span>
                        <input
                          ref={inputRef}
                          value={
                            fundingPercentage +
                            (fundingPercentage !== "" ? "%" : "")
                          }
                          onChange={handleChange}
                          type="text"
                          placeholder="eg.80"
                          className="p-2.5 text-sm outline-none border border-[#959595] rounded-[2px]"
                        />
                      </div>

                      <div className="flex w-[45%] gap-1 flex-col">
                        <span className="text-sm font-light">
                          Set payment date
                        </span>
                        <input
                          value={paymentDate}
                          onChange={(e) => setPaymentDate(e.target.value)}
                          placeholder="dd/mm/yyyy"
                          className="p-2.5 outline-none placeholder:text-[#959595] text-sm border border-[#959595] rounded-[2px]"
                          type="date"
                        />
                      </div>
                    </div>

                    <div className="w-full">
                      <button
                        disabled={!filled}
                        onClick={() => {
                          handlePercentageUpdate();
                        }}
                        className={`${
                          filled ? "bg-primaryColor" : "bg-[#959595]/30"
                        }
            text-sm rounded-[5px] w-full text-white font-light px-6 py-3`}
                      >
                        Apply percentage and payment date to all invoice amount
                      </button>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </footer>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default PercentageFundingModal;
