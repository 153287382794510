import React from "react";
import Loading from "shared/Loading";
import { AnimatePresence, motion } from "framer-motion";

import DetailCard from "../card/DetailCard";
const BidHistory = ({ currentLeadData, showButton, cancelBid, othersBid }) => {
  return (
    <div>
      {/* first outline card */}

      <div className=" px-4">
        <div className=" bg-[#07593d]/5 rounded-[5px] border border-[#07593d] relative ">
          <div class="text-black text-xs font-semibold absolute -left-0.5 -top-2.5 z-1111 bg-white px-1 ">
            Your bid is the winning bid
          </div>

          {showButton ? (
            <>
              <div className=" flex justify-between">
                <div></div>
                <div className=" flex items-center justify-center z-20">
                  <div className="transform scale-50">
                    <Loading />
                  </div>
                </div>
                <AnimatePresence>
                  <div className=" flex items-center justify-center mr-4">
                    {" "}
                    <motion.div
                      initial={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      onClick={cancelBid}
                      className=" flex items-center bg-white justify-center text-[8px] text-red border-[1.5px] border-red py-1 px-2 rounded cursor-pointer z-30 w-[120px]"
                    >
                      Cancel x <span className="text-black ml-1">| 30s</span>
                    </motion.div>
                  </div>
                </AnimatePresence>
              </div>
            </>
          ) : (
            <div class=" justify-between  flex px-2  flex-row  items-center  ">
              <div class=" items-center flex gap-3">
                <div class="py-2.5 flex-col justify-start items-start gap-0.5 inline-flex">
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    <div className="text-black text-xs font-normal">
                      {currentLeadData && currentLeadData[0]?.funderCompanyName}
                    </div>
                  </div>
                  <div class="flex-col justify-start items-start gap-0.5 flex">
                    {/* <div className=" text-[#949494] text-[10px]">
                Funder
              </div> */}
                    <div class="text-[#949494] text-[10px] font-normal ">
                      Funder
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-2.5 flex-col gap-0.5 inline-flex">
                <div class="flex-col  justify-start items-start gap-0.5 flex">
                  <div className="text-black text-xs font-normal">
                    {currentLeadData && currentLeadData[0]?.lockRate}%
                  </div>
                </div>
                <div class="h-3 flex-col justify-start items-start gap-0.5 flex">
                  <div className=" text-[#949494] text-[10px]">
                    Discount Rate{" "}
                  </div>
                </div>
              </div>
              <div class=" p-2.5 flex-col  gap-0.5 inline-flex">
                <div class="flex-col justify-center items-center gap-0.5 flex">
                  <div className={`font-[500] text-sm ${"text-[#f08000]"}`}>
                    {currentLeadData && currentLeadData[0]?.status}
                  </div>
                </div>
                <div class="h-3 flex-col justify-start items-start gap-0.5 flex">
                  <div className=" text-[#959595] text-[10px]">Status</div>
                </div>
              </div>
              <div class="w-[129px] p-2.5 flex-col justify-start items-end gap-0.5 inline-flex">
                <div class="flex-col justify-start items-start gap-0.5 flex">
                  <div className="font-[500] text-sm">
                    {" "}
                    {currentLeadData && currentLeadData[0]?.bidTimeStamp}
                  </div>
                </div>
                <div class="flex-col justify-start items-end gap-0.5 flex">
                  <div className=" text-[#959595] text-[10px]">
                    Bidding Time
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {othersBid &&
        othersBid?.length > 0 &&
        othersBid?.map((item, idx) => {
          return (
            <DetailCard
              key={idx}
              funderName={item.funderCompanyName}
              discountRate={item.lockRate}
              statusOfInvoice={item.status}
              biddingTime={item.bidTimeStamp}
            />
          );
        })}
    </div>
  );
};

export default BidHistory;
