import { getAllCreatedTiers } from "appstate/api/tier";
import {
  getAssociatedSupplierId,
} from "appstate/buyer/buyerSlice";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import Loading from "shared/Loading";
import AccountModalContainer from "shared/accountModal/Modal";
import TableHeader from "shared/tableHeader/TableHeader";
import ThreeDots from "../../../../../assets/images/svgs/option.svg";
import CardDisassociation from "../../card/card";
import Pagination from "shared/Pagination";
import {
  BuyyerSendOnboardingInviteToSuppliers,
  disassociateBuyerSupplier,
  getAllBuyerAssociatedSuppliers,
} from "appstate/api/buyer/relationshipManagement";
import _ from "lodash";
import toast from "react-hot-toast";
import SuccessRelationship from "shared/SuccessRelationship";
import FunderInvitationModal from "shared/inviteModal/FunderInvitationModal";
import ContinueInviteModal from "shared/inviteModal/ContnueInviteModal";
import SuccessModal from "shared/inviteModal/SuccessModal";

const BuyerSuppliers = () => {
  const [openDisassociateModal, setOpenDisAssociateModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [getId, setGetId] = useState(null);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const [total, setTotal] = useState("");
  const [invite, setInvite] = useState([]);
  const [newInvite, setNewInvite] = useState("");
  // eslint-disable-next-line
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [associatedSupplierData, setAssociatedSupplierData] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDisassociation, setLoadingDisassociation] = useState(false);
  const [continueInvite, setContinueInvite] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [openmodal, setOpenmodal] = useState(false);
  const {
    isLoading,
    inviteLoading,
  } = useSelector((state) => state?.buyer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleThreeDotsClick = (rowId, row) => {
    setGetId(row?.userId);
    setDropdownVisible(dropdownVisible === rowId ? null : rowId);
  };

  const fetchTiers = async () => {
    try {
      const result = await getAllCreatedTiers({page, pageSize, searchTerm});
      setData(result?.data || []);
    } catch (error) {
      console.error("Tier failed to load");
    }
  };

  useEffect(() => {
    fetchTiers();
    // eslint-disable-next-line
  }, []);

  const fetchAssociatedBuyers = async () => {
    setLoading(true);
    try {
      const search = searchTerm;
      const result = await getAllBuyerAssociatedSuppliers({
        page,
        pageSize,
        search,
      });
      setTotal(result?.meta?.totalElements);
      setAssociatedSupplierData(result?.data || []);
    } catch (error) {
      console.error("Error fetching associated buyers:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssociatedBuyers();
    // eslint-disable-next-line
  }, [page, pageSize, companyName]);

  const onRowClicked = (row) => {
    let path = "/buyer-suppliers/supplierdetails";
    dispatch(getAssociatedSupplierId(row?.id));
    navigate(path, { state: { row } });
  };

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    _.debounce(() => {
      fetchAssociatedBuyers();
    }, 200),
    [searchTerm]
  );

  useEffect(() => {
    if (searchTerm) {
      debouncedSearch();
    } else {
      fetchAssociatedBuyers();
    }
    // eslint-disable-next-line
  }, [searchTerm, debouncedSearch]);

  const handleSearch = () => {
    fetchAssociatedBuyers();
  };

  const handleDisAssociate = async () => {
    const supplierId = getId;
    try {
      setLoadingDisassociation(true);
      const response = await disassociateBuyerSupplier(supplierId);
      if (response?.status === 200) {
        setSuccess(true);
        setShowSuccessModal(true);
        setOpenDisAssociateModal(false);
        fetchAssociatedBuyers();
      }
    } catch (error) {
      setOpenDisAssociateModal(false);
      console.error("Error disassociating supplier:", error);
      toast.error("Error disassociating supplier, try again later.");
    } finally {
      setLoadingDisassociation(false);
    }
  };

  const column = [
    {
      name: "NAME",
      selector: (row) => row?.fullName,
    },
    {
      name: "COMPANY",
      selector: (row) => row?.companyName,
    },
    {
      name: "EMAIL",
      selector: (row) => row?.email,
    },
    {
      name: "VENDOR ID",
      selector: (row) => row?.vendorId,
    },
    {
      name: "STATUS",
      selector: (row) => (
        <span
          style={{
            color: row?.status === "Accepted" ? "green" : "green",
          }}
        >
          Accepted
        </span>
      ),
    },
    {
      cell: (row) => (
        <div className="relative">
          <img
            src={ThreeDots}
            alt="Options"
            onClick={() => handleThreeDotsClick(row?.userId, row)}
            className="cursor-pointer"
          />
          {dropdownVisible === row?.userId && (
            <div className="absolute right-0 z-10 mt-2">
              <CardDisassociation
                handleShowModal={handleShowModal}
                onRowClicked={() => onRowClicked(row)}
              />
            </div>
          )}
        </div>
      ),
    },
  ];

  const handleShowModal = async () => {
    setOpenDisAssociateModal(true);
  };

  const totalPages = total;

  let pendingRequestPath = "buyer-association-supplier-request";

  const handleSubmitEmails = async () => {
    const body = invite;
    try {
      const response = await BuyyerSendOnboardingInviteToSuppliers(body);
      if (response) {
        setOpenmodal(false);
        setSuccess(true);
      }
    } catch {
      console.error("an error occured");
      toast.error("error occured try again later!")
    }
  };

  const handleSubContinue = () => {
    setContinueInvite(false);
    setSuccess(true);
  };
  const handleContinueInvite = () => {
    setOpenmodal(false);
    setContinueInvite(true);
  };

  const handleClose = () => {
    setSuccess(false);
    // navigate("/buyer-funders/buyer-pending-funder-request");
  };


  const onClose = () => {
    setShowSuccessModal(false);
  };

  return (
    <DashboardLayout
      section="Suppliers"
      subHeader="Manage all your associated suppliers accounts"
    >
      {showSuccessModal && (
        <SuccessRelationship
          isOpen={showSuccessModal}
          onClose={onClose}
          message="Disassociation successful"
        />
      )}
       <SuccessModal
        isOpen={success}
        onClose={handleClose}
        message=" The Supplier would received a mail that your are inviting to join
          PayEdge."
      />
      <AccountModalContainer
        accontModal
        isShown={openDisassociateModal}
        onClose={() => {
          setOpenDisAssociateModal(false);
        }}
        isLoading={loadingDisassociation}
        success={success}
        handleAssociate={handleDisAssociate}
        warning="Are you sure you would like to disassociate with this supplier?"
      />
      <ContinueInviteModal
       isOpen={continueInvite}
       onClose={() => setContinueInvite(false)}
       isLoading={isLoading}
       handleYes={handleSubContinue}
      />
      <FunderInvitationModal
         onClose={() => setOpenmodal(false)}
         isOpen={openmodal}
         message=" The Supplier would received a mail that your are inviting to join
         PayEdge."
         heading="Invite Supplier"
         info="You can add one or more suppliers at once with ease"
         role="Funder"
         invite={invite}
         setInvite={setInvite}
         newInvite={newInvite}
         setNewInvite={setNewInvite}
         success={success}
         setSuccess={setSuccess}
         handleAdd={handleSubmitEmails}
         handleYes={handleContinueInvite}
         isLoading={inviteLoading}
         supplier={true}
      />
      <Card className="p-[24px] rounded-[10px]">
        <TableHeader
          pendingPath={pendingRequestPath}
          setProductName={setCompanyName}
          productName={companyName}
          handleSearch={handleSearch}
          fetchTiers={fetchTiers}
          btnName="Invite Supplier"
          funder
          accounts
          buyer
          tier={false}
          data={data}
          setOpen={setOpenmodal}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          total="All Suppliers"
          details="Access contacts details of the associated suppliers"
        />
        {loading ? (
          <div className="flex justify-center">
            <Loading />
          </div>
        ) : (
          <Table
            columns={column}
            data={associatedSupplierData}
            onRowClicked={onRowClicked}
            progressPending={isLoading}
            pointer
            tableHeader
            className="mt-5"
          />
        )}
        <br />

        <div className="flex justify-center gap-x-[8px]  mt-12">
          {!isLoading && totalPages > 10 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default BuyerSuppliers;
