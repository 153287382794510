import { AnimatePresence, motion } from "framer-motion";
import {
  PAIconArrowLeftGreen,
  PAIconInvoiceIcon,
  PAIconProfilePicSide,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  bidHistory,
  detailsBuyerOpenMarket,
} from "appstate/openMarket/openMarketSlice";
import { toggleCancelButtonOff } from "./buttonSlice";
import InvoiceDetails from "features/openMarket/common/components/InvoiceDetails";
import FunderDetails from "./modal/FunderDetails";
import BiddingDetails from "features/openMarket/supplier/components/modals/BiddingDetails";
import Pagination from "shared/Pagination";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import { formatCapitalize } from "utilities/formatCapitalize";
import { formatDate } from "utilities/helper/dateFormatterSpelt";
import useCountdown from "utilities/helper/useCountdown";

function TopContestedInvoiceDetails() {
  const { id } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(3);
  const [sortedBidHistory, setSortedBidHistory] = useState([]);
  const pageSize = 5;

  useEffect(() => {
    dispatch(detailsBuyerOpenMarket({ openMarketReference: id }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(bidHistory({ openMarketReference: id, page, pageSize }));
  }, [dispatch, id, page, pageSize]);

  const { detailsBuyerOpenMarketData, bidHistoryData } = useSelector(
    (state) => state?.openMarket
  );

  const invoiceByIdData = detailsBuyerOpenMarketData;

  const [funderDetail, setFunderDetail] = useState(false);
  const [biddingDetail, setBiddingDetail] = useState(false);

  const [activeTab, setActiveTab] = useState("bidHistory");

  function handleGoBack() {
    dispatch(toggleCancelButtonOff());
    navigate(-1);
  }

  const { timeLeft, isTimeUnderOneHour } = useCountdown(
    formatDate(detailsBuyerOpenMarketData?.data?.paymentDate)
  );

  // Sort bid history data whenever it changes
  useEffect(() => {
    if (bidHistoryData?.bidHistory?.data) {
      // Create a shallow copy of the bid history data and sort it
      const sortedData = [...bidHistoryData?.bidHistory?.data]?.sort(
        (a, b) => b?.isLeading - a?.isLeading // Sort by 'isLeading', highest first
      );
      setSortedBidHistory(sortedData); // Update the sorted state
    }
  }, [bidHistoryData]);

  return (
    <DashboardLayout subHeader="Here is what is happening in your finance">
      <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
        <header onClick={handleGoBack} className="w-full">
          <div className="flex cursor-pointer w-fit gap-1 items-center">
            <PAIconArrowLeftGreen />
            <span className=" text-primaryColor text-sm">Go back</span>
          </div>
        </header>

        <main className="flex flex-col semi-lg:flex-row pb-8 gap-8 w-full ">
          <InvoiceDetails invoiceByIdData={invoiceByIdData} />

          <motion.section
            initial={{
              y: -20,
            }}
            animate={{
              y: 0,
            }}
            exit={{
              y: 20,
            }}
            transition={{
              delay: 0.8,
            }}
            className="flex flex-col gap-3 px- pt-7 pb-24 w-full semi-lg:w-[45%] min-h-screen bg-white rounded-[10px]"
          >
            <header className="flex pb-2 px-6 justify-between items-center">
              <span className="font-bold text-primaryColor">
                Bid details for this invoice
              </span>
              <span>
                <section
                  className={`flex ${isTimeUnderOneHour ? "text-red" : ""}`}
                >
                  <div className="flex">
                    <div className="flex items-center justify-center flex-col">
                      <span className="font-semibold">{timeLeft.days}</span>
                      <span className="text-xs">days</span>
                    </div>
                    <span>:</span>
                  </div>

                  <div className="flex">
                    <div className="flex items-center justify-center flex-col">
                      <span className="font-semibold">{timeLeft.hours}</span>
                      <span className="text-xs">hours</span>
                    </div>
                    <span>:</span>
                  </div>

                  <div className="flex gap-0">
                    <div className="flex items-center justify-center flex-col">
                      <span className="font-semibold">{timeLeft.minutes}</span>
                      <span className="text-xs">mins</span>
                    </div>
                    <span>:</span>
                  </div>

                  <div className="flex">
                    <div className="flex  items-center justify-center flex-col">
                      <span className="font-semibold">{timeLeft.seconds}</span>
                      <span className="text-xs">secs</span>
                    </div>
                  </div>
                </section>
              </span>
            </header>

            <div className="border-t border-gray"></div>
            <div className="w-full ">
              <div className="mt-3 flex justifycenter px-6">
                <div className="flex space-x-4">
                  {/* Bid History Tab */}
                  <button
                    onClick={() => setActiveTab("bidHistory")}
                    className={`text-gray600 ${
                      activeTab === "bidHistory"
                        ? "border-b border-secondaryColor text-secondaryColor"
                        : ""
                    } font-medium text-sm`}
                  >
                    Bid History : {bidHistoryData?.bidHistory?.data?.length}
                  </button>
                  {/* Request Details Tab */}
                  <button
                    onClick={() => setActiveTab("requestDetails")}
                    className={`pb2 text-gray600 ${
                      activeTab === "requestDetails"
                        ? "border-b border-secondaryColor text-secondaryColor"
                        : ""
                    } font-medium text-sm`}
                  >
                    Viewed by funders:{" "}
                    {bidHistoryData?.viewedByFunders?.data?.length}
                  </button>
                </div>
              </div>
              <div className="mt-3">
                {activeTab === "bidHistory" &&
                  sortedBidHistory?.map((bid, index) => (
                    <div
                      key={index}
                      className={`flex justify-between px-6 pb-1 my-6 cursor-pointer ${
                        index === 0
                          ? "bg-[#F1F9FF] border-b-[2px] border-r-[2px] border-l-[2px] border-t-[2px] rounded border-primaryColor relative"
                          : ""
                      }`}
                      onClick={() => setBiddingDetail(true)}
                    >
                      {bid?.isLeading && (
                        <div className="absolute top-[-10px] left-4 text-xs font-semibold text-primaryColor bg-white px-2">
                          Current winning bid
                        </div>
                      )}
                      <div className="flex gap-6 items-center w-[30%] mt-3">
                        <div className="flex flex-col gap-0.5">
                          <span
                            className={`text-xs font-[500] ${
                              index === 0 ? "text-primaryColor" : ""
                            }`}
                          >
                            {formatCapitalize(bid?.funderName)}
                          </span>
                          <span className="text-[10px] text-[#959595]">
                            Funder
                          </span>
                        </div>
                      </div>

                      <div className="flex gap-6 items-center w-[25%] mt-3">
                        <div className="flex flex-col gap-0.5">
                          <span className="text-xs font-[500]">
                            {(bid?.discountRate * 100)?.toFixed(2)}% {" - "}
                            <span
                              className={`text-[8px] ${
                                bid.state === "Without Recourse"
                                  ? "text-primaryColor"
                                  : "text-secondaryColor"
                              }`}
                            >
                              {formatCapitaliseString(bid?.discountType)}
                            </span>
                          </span>
                          <span className="text-[10px] text-[#959595]">
                            Lock Rate
                          </span>
                        </div>
                      </div>
                      <div className="flex gap-6 items-center w-[20%] mt-3">
                        <div className="flex flex-col gap-0.5">
                          <span
                            className={`text-xs font-[500] ${
                              bid.status === "Locked"
                                ? "text-secondaryColor"
                                : "text-black"
                            } ${index === 0 ? "font-bold" : ""}`}
                          >
                            {bid?.status === "BID_ENDED"
                              ? formatCapitaliseString(bid?.status)
                              : `${formatCapitaliseString(bid?.status)}ed`}
                          </span>
                          <span className="text-[10px] text-[#959595]">
                            Status
                          </span>
                        </div>
                      </div>
                      <div className="flex gap-6 items-center w-[20%] mt-3">
                        <div className="flex flex-col gap-0.5">
                          <span className="text-xs font-[500]">
                            {bid?.lockDuration}
                          </span>
                          <span className="text-[10px] text-[#959595]">
                            Lock duration{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                <div className="flex justify-center">
                  {bidHistoryData?.meta?.totalElements >= pageSize && (
                    <Pagination
                      page={page}
                      startPage={startPage}
                      endPage={endPage}
                      setEndPage={setEndPage}
                      setPage={setPage}
                      setStartPage={setStartPage}
                      totalPages={bidHistoryData?.meta?.totalElements}
                    />
                  )}
                </div>

                {activeTab === "requestDetails" && (
                  <div className="space-y-4">
                    {bidHistoryData?.viewedByFunders?.data?.length === 0 ? (
                      <div className="flex gap-1.5 flex-col justify-center font-medium min-h-[50vh] items-center">
                        <PAIconInvoiceIcon className="animate-pulse" />
                        <span className="font-semibold italic">
                          No view by funder yet
                        </span>
                      </div>
                    ) : (
                      bidHistoryData?.viewedByFunders?.data?.map(
                        (item, index) => (
                          <div
                            key={index}
                            className="flex justify-between shadow-sm items-center mb-4 cursor-pointer px-6"
                            onClick={() => setFunderDetail(true)}
                          >
                            <div className="flex items-center my-2 gap-2">
                              <PAIconProfilePicSide />
                              <div>
                                <p className="text-[16px] font-regular text-gray-600">
                                  {item?.funder || "N/A"}
                                </p>
                                <p className="text-[12px] font-regular text-zinc-400">
                                  Funder
                                </p>
                              </div>
                            </div>

                            <div className="text-center">
                              <p className="text-[14px] ">
                                {item?.phoneNo || "N/A"}
                              </p>
                              <p className="text-[12px] text-zinc-400">
                                Contact number
                              </p>
                            </div>

                            <div className="text-right">
                              <p className="text-sm text-gray-600">
                                {item?.companyEmail || "N/A"}
                              </p>
                              <p className="text-[12px] text-zinc-400">Email</p>
                            </div>
                          </div>
                        )
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
          </motion.section>
        </main>
      </TransitionScale>

      <AnimatePresence>
        {funderDetail && (
          <FunderDetails onClose={() => setFunderDetail(false)} />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {biddingDetail && (
          <BiddingDetails
            bidHistoryData={bidHistoryData}
            onClose={() => setBiddingDetail(false)}
          />
        )}
      </AnimatePresence>
    </DashboardLayout>
  );
}

export default TopContestedInvoiceDetails;
