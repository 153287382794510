import { PAIconInvShop } from "assets/images/svgs";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TooltipShared from "shared/tooltip/Tooltip";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import { formatCurrency } from "utilities/helper/formatters";
import useCountdown from "utilities/helper/useCountdown";

function AllInvoicesCard({
  id,
  invoiceReference = "N/A",
  invoiceNumber = "N/A",
  buyerCompanyName = "N/A",
  fundingAmount = "N/A",
  status = "N/A",
  invoiceDueDate = "N/A",
  lockRate = "",
  fundRequestDate,
  paymentDate,
}) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  const { timeLeft, isTimeUnderOneHour } = useCountdown(paymentDate);
  return (
    <motion.div
      onClick={() => navigate(`details/${status?.toLowerCase()}/${id}`)}
      whileTap={{ scale: 0.98 }}
      style={{ boxShadow: "0px 2px 4px 0px rgba(232, 226, 216, 0.36)" }}
      className="flex cursor-pointer justify-between bg-white py-5 px-6 rounded-[10px]"
    >
      <span className="flex gap-6 w-[250px] items-center">
        <PAIconInvShop />
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">
            <TooltipShared text={invoiceReference} />
          </span>
          <span className=" text-[#959595] text-[10px]">Reference number</span>
        </div>
      </span>

      <span className="flex gap-6 font-light w-[110px]  items-center">
        <div className="flex flex-col justify-center">
          <span className="text-sm">
            <TooltipShared text={`INV-${invoiceNumber}`} maxLength={10} />
          </span>
          <span className=" text-[#959595] text-[10px]">Invoice number</span>
        </div>
      </span>

      <span className="flex gap-6 font-light w-[110px] mr-2 items-center ">
        {/* <PAIconCocacola /> */}
        <div className="flex flex-col justify-center">
          <span className="text-sm">
            <TooltipShared text={buyerCompanyName} maxLength={15} />
          </span>
          <span className=" text-[#959595] text-[10px]">Buyer</span>
        </div>
      </span>

      <span className="flex gap-6 font-light  items-center">
        <div className="flex flex-col justify-center">
          <span
            className={`${
              status?.toLowerCase() === "posted"
                ? "text-primaryColor"
                : status?.toLowerCase() === "cancelled"
                ? "text-red"
                : "text-secondaryColor"
            } text-sm`}
          >
            {formatCapitaliseString(status)}
          </span>
          <span className=" text-[#959595] text-[10px]">Status of Invoice</span>
        </div>
      </span>

      {/* <span className="hidden extra-md:flex gap-6 font-light ">
        <div className="flex flex-col justify-center">
          <span
            className={`${
              status === "Accepted"
                ? "text-primaryColor"
                : status === "Pending"
                ? "text-brilliantYellow"
                : "text-reddish"
            } text-sm`}
          >
            {status}
          </span>
          <span className=" text-[#959595] text-[10px]">Status of invoice</span>
        </div>
      </span> */}

      <span className="flex w-[105px] gap-6 font-light ">
        <div className="flex flex-col justify-center">
          <span className="flex justify-center text-sm">
            {formatCurrency(fundingAmount)}
          </span>
          <span className=" text-[#959595] text-[10px]">Request Amount</span>
        </div>
      </span>

      <span className="flex gap-6 font-light w-[70px]">
        <div className="flex flex-col justify-center">
          <span className="flex justify-center text-sm">
            {lockRate || "-"}%
          </span>
          <span className=" text-[#959595] text-[10px]">Lock Rate</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 font-light ">
        <div className="flex flex-col justify-center">
          <span className="text-sm">{paymentDate}</span>
          <span className=" text-[#959595] text-[10px]">Payment Date</span>
        </div>
      </span>

      <span className="flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span
            className={`text-sm font-bold ${
              isTimeUnderOneHour ? "text-red-500" : ""
            }`}
          >
            {(timeLeft.days > 0 ||
              timeLeft.hours > 0 ||
              timeLeft.minutes > 0 ||
              timeLeft.seconds > 0) &&
            formatCapitaliseString(status) !== "Cancelled" ? (
              `${timeLeft.days}d:${timeLeft.hours}h:${timeLeft.minutes}m:${timeLeft.seconds}s`
            ) : isLoading ? (
              <span className="text-secondaryColor">Fetching...</span>
            ) : formatCapitaliseString(status) === "Cancelled" ? (
              <span className="text-red">Cancelled</span>
            ) : (
              <span className="text-red">Expired</span>
            )}
          </span>
          <span className="text-[#959595] text-[10px]">
            Count down to payment date
          </span>
        </div>
      </span>
    </motion.div>
  );
}

export default AllInvoicesCard;
