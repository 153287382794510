import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  allSupplierInvoiceData,
  //   supplierInvoiceApprove,
} from "appstate/invoice/invoiceSlice";
import { allVirtualAccounts } from "appstate/wallet/walletSlice";

import { PAIconArrowLeftGreen, PAIconInvoiceIcon } from "assets/images/svgs";
import SelectAccountModal from "features/supplierEarlyPayment/payment";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";

import AcceptModal from "shared/AcceptModal";
import Card from "shared/Card";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";
import SuccessModal from "shared/SuccessModal";
import TableHeader from "shared/tableHeader/TableHeader";
import PercentageFundingModal from "./modals/PercentageFundingModal";
import { AnimatePresence } from "framer-motion";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import toast from "react-hot-toast";
import {
  availableOpenMarketInvoice,
  importInvoiceOpenMarket,
} from "appstate/openMarket/openMarketSlice";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";

const ImportInvoiceOpenMarket = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Local state
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [productName, setProductName] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [acceptModal, setAcceptModal] = useState(false);
  //   const [hasSelected, setHasSelected] = useState(false);
  const [fundingPercentage, setFundingPercentage] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [percentageModal, setPercentageModal] = useState(false);
  const [isSelectAccountModalOpen, setIsSelectAccountModalOpen] =
    useState(false);
  const [, setIsProceedModalOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowsContent, setSelectedRowsContent] = useState([]);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(3);
  const pageSize = 10;

  //   const selectedAccounts = useSelector(
  //     (state) => state?.wallet?.selectedAccounts
  //   );
  const { availableOpenMarketInvoiceData, isLoading } = useSelector(
    (state) => state?.openMarket
  );

  // Filtered data
  const filteredData = availableOpenMarketInvoiceData?.data?.filter(
    (invoice) => {
      if (selectedFilter === "All") return true;
      return invoice.status === selectedFilter;
    }
  );

  // Fetch data
  useEffect(() => {
    dispatch(
      availableOpenMarketInvoice({
        page,
        pageSize,
        search: productName,
        status: "",
      })
    );
  }, [dispatch, page, pageSize, productName]);

  useEffect(() => {
    dispatch(allVirtualAccounts());
  }, [dispatch]);

  // Handlers
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allIds = filteredData?.map((row) => row?.invoiceId) || [];
      const allRows = filteredData?.map((row) => row) || [];
      setSelectedRows(allIds);
      setSelectedRowsContent(allRows);
    } else {
      setSelectedRows([]);
      setSelectedRowsContent([]);
    }
  };

  const handleCheckboxChange = (row) => {
    if (selectAll) setSelectAll(false);
    const isSelected = selectedRows?.includes(row?.invoiceId);
    const updatedRows = isSelected
      ? selectedRows?.filter((id) => id !== row?.invoiceId)
      : [...selectedRows, row?.invoiceId];

    const updatedRowContents = isSelected
      ? selectedRows?.filter((id) => id !== row?.invoiceId)
      : [...selectedRowsContent, row];
    setSelectedRows(updatedRows);
    setSelectedRowsContent(updatedRowContents);
  };

  const handleFilterChange = (e) => setSelectedFilter(e.target.value);

  const handleAccept = () => {
    setIsAcceptModalOpen(false);
    setIsSelectAccountModalOpen(true);
  };

  const handleAccountSubmit = () => {
    setIsSelectAccountModalOpen(false);
    setIsProceedModalOpen(true);
  };

  const handleProceedSubmit = () => {
    const pendingToast = toast.loading("Sending invoice for confirmation...");
    const body = {
      invoiceIds: [...selectedRows],
      fundingPercentage,
      fundRequestDate: paymentDate,
    };
    dispatch(importInvoiceOpenMarket(body)).then((data) => {
      if (data?.payload[0]?.success) {
        setSelectedRows([]);
        setSuccessMessage("Open Market request sent for workflow approval");
        setIsSuccessModalOpen(true);
        toast.dismiss(pendingToast);
        setTimeout(() => {
          navigate("/open-market");
        }, 1000);
      } else {
        toast.dismiss(pendingToast);
        toast.error(data?.payload[0]?.message);
      }
    });
  };

  const handlePercentageUpdate = () => {
    const pendingToast = toast.loading("Sending invoice for confirmation...");
    const body = {
      invoiceIds: [...selectedRows],
      fundingPercentage,
      fundRequestDate: paymentDate,
    };
    dispatch(importInvoiceOpenMarket(body)).then((data) => {
      if (data?.payload[0]?.success) {
        setSuccessMessage("Open Market request sent for workflow approval");
        setIsSuccessModalOpen(true);
        toast.dismiss(pendingToast);
        setTimeout(() => {
          navigate("/open-market");
        }, 1000);
      } else {
        toast.dismiss(pendingToast);
        toast.error(data?.payload?.message);
      }
    });
  };

  const formatAmount = (amount) =>
    typeof amount === "number"
      ? amount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : "0.00";

  const columns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row.invoiceId)}
        />
      ),
      width: "45px",
    },
    {
      name: "Invoice Reference",
      selector: (row) => row?.invoiceRef,
      sortable: true,
    },
    {
      name: "Buyer",
      selector: (row) => row?.buyerName,
      sortable: true,
    },
    {
      name: "Invoice Date",
      selector: (row) => row?.createdDate,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row?.currency,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => {
        formatAmount(row?.subTotal);
      },
      cell: (row) => (
        <p className="text-success">{formatAmount(row?.subTotal)}</p>
      ),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      cell: (row) => (
        <p
          className={
            row.status === "APPROVED" || row.status === "PAID"
              ? "text-success"
              : "text-danger"
          }
        >
          {formatCapitaliseString(row.status)}
        </p>
      ),
      sortable: true,
    },
  ];

  return (
    <DashboardLayout
      section="Open Market Invoice Import"
      subHeader="Here is an overview of your invoices"
    >
      <div className="flex flex-col gap-3">
        <div className="w-full bg-white py-5 px-6 rounded-lg text-primaryColor">
          <span
            onClick={() => navigate(-1)}
            className="flex items-center gap-2 cursor-pointer"
          >
            <PAIconArrowLeftGreen /> <span>Go Back</span>
          </span>
        </div>
        <Card className="p-6 rounded-lg">
          <TableHeader
            total="Transaction History"
            details={<p>All Available Invoices</p>}
            searchTerm={productName}
            setSearchTerm={setProductName}
            handleSearch={() =>
              dispatch(
                allSupplierInvoiceData({
                  page,
                  pageSize,
                  search: productName,
                  status: "",
                })
              )
            }
            showSelectContainer
            options={[
              { id: "1", name: "All" },
              { id: "2", name: "CREATED" },
              { id: "3", name: "PAID" },
            ]}
            handleChange={handleFilterChange}
            approval
            approvalName="Import Selected Invoices"
            active={!!selectedRows?.length}
            handleApproval={() => setPercentageModal(true)}
          />
          <Table
            columns={columns}
            data={filteredData}
            progressPending={isLoading}
            progressComponent={<Loading />}
            // onRowClicked={(row) =>
            //   navigate(`/invoice/invoice/${row.id}/${row.status}`)
            // }
          />
          <div className="flex justify-center gap-x-[8px] mt-[20px]">
            {availableOpenMarketInvoiceData?.meta?.totalElements > pageSize && (
              <Pagination
                page={page}
                startPage={startPage}
                endPage={endPage}
                setEndPage={setEndPage}
                setPage={setPage}
                setStartPage={setStartPage}
                totalPages={availableOpenMarketInvoiceData?.meta?.totalElements}
              />
            )}
          </div>
        </Card>
        <AcceptModal
          isOpen={isAcceptModalOpen}
          onClose={() => setIsAcceptModalOpen(false)}
          message="Would you like to submit these invoices for approval?"
          onConfirm={handleAccept}
        />
        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={() => setIsSuccessModalOpen(false)}
          message={successMessage}
        />
        <SelectAccountModal
          isOpen={isSelectAccountModalOpen}
          onClose={() => setIsSelectAccountModalOpen(false)}
          onSubmit={handleAccountSubmit}
        />

        <PercentageFundingModal
          handlePercentageUpdate={handlePercentageUpdate}
          invoiceData={selectedRowsContent}
          invoiceIds={selectedRows}
          isOpen={percentageModal}
          fundingPercentage={fundingPercentage}
          setFundingPercentage={setFundingPercentage}
          paymentDate={paymentDate}
          setPaymentDate={setPaymentDate}
          setAcceptModal={setAcceptModal}
          onClose={() => {
            setPercentageModal(false);
          }}
        />

        {/* Accept Modal */}
        <AnimatePresence>
          {acceptModal && (
            <ViewDocumentModal
              icon={<PAIconInvoiceIcon className=" animate-pulse" />}
              onAction={handleProceedSubmit}
              onClose={() => setAcceptModal(false)}
            >
              <span className=" text-center">
                Are you sure you want to send these invoices to the buyer for
                confirmation? Once approved, they will be sent to the Open
                market.
              </span>
            </ViewDocumentModal>
          )}
        </AnimatePresence>
      </div>
    </DashboardLayout>
  );
};

export default ImportInvoiceOpenMarket;
