export function formatCapitalize(sentence) {
  // if (typeof sentence !== "string") {
  //   throw new Error("Input must be a string");
  // }

  return sentence
    ?.split(" ") // Split the sentence into words
    ?.map((word) => {
      return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase();
    })
    ?.join(" "); // Join the words back into a sentence
}
