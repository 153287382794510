import { PAIconCocacola, PAIconInvShop } from "assets/images/svgs";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TooltipShared from "shared/tooltip/Tooltip";
import { formatCapitalize } from "utilities/formatCapitalize";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import { formatCurrency } from "utilities/helper/formatters";
import useCountdown from "utilities/helper/useCountdown";

function ContestedInvoiceCard({
  id,
  invoiceNumber = "N/A",
  buyerName = "N/A",
  noOfBids = "N/A",
  status = "Bid Active",
  invoiceAmount = "N/A",
  dueDate = "N/A",
  invoiceRef,
  lockRate,
}) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);

    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  const { timeLeft, isTimeUnderOneHour } = useCountdown(dueDate);

  return (
    <motion.div
      onClick={() =>
        navigate(`/open-market/top-contested-invoice-details/${id}`)
      }
      whileTap={{ scale: 0.98 }}
      style={{ boxShadow: "0px 2px 4px 0px rgba(232, 226, 216, 0.36)" }}
      className="flex cursor-pointer justify-between bg-white py-5 px-6 rounded-[10px]"
    >
      <span className="flex gap-6 w-[180px]  items-center">
        <PAIconInvShop />
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">
            <TooltipShared maxLength={10} text={invoiceRef || "N/A"} />
          </span>
          <span className=" text-[#959595] text-[10px]">Reference Number</span>
        </div>
      </span>
      <span className="semi-lg:flex hidden gap-6 w-[120px]  items-center">
        {/* <PAIconInvShop /> */}
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">
            {" "}
            <TooltipShared maxLength={15} a text={invoiceNumber || "N/A"} />
          </span>
          <span className=" text-[#959595] text-[10px]">Invoice Number</span>
        </div>
      </span>
      <span className="flex gap-6  semi-lg:w-[150px] items-center ">
        <PAIconCocacola className="semi-lg:flex hidden " />
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">
            <TooltipShared maxLength={20} text={buyerName} />
          </span>
          <span className=" text-[#959595] text-[10px]">Buyer</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-secondaryColor text-sm">
            {formatCapitalize(status) || "N/A"}
          </span>
          <span className=" text-[#959595] text-[10px]">Status of Invoice</span>
        </div>
      </span>

      <span className="flex w-[110px] gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">
            {formatCurrency(invoiceAmount)}
          </span>
          <span className=" text-[#959595] text-[10px]">Request Amount</span>
        </div>
      </span>

      <span className="flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">
            {lockRate ? formatCurrency(lockRate) * 100 : "-"}%
          </span>
          <span className=" text-[#959595] text-[10px]">Lock Rate</span>
        </div>
      </span>

      <span className="semi-lg:flex hidden  gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{dueDate}</span>
          <span className=" text-[#959595] text-[10px]">Payment Date</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 justify-center ">
        <div className="flex flex-col items-center justify-center">
          <span className="font-[500] text-sm">{noOfBids}</span>
          <span className=" text-[#959595] text-center text-[10px]">
            Number of bidders
          </span>
        </div>
      </span>

      <span className="semi-lg:flex hidden gap-6 w-[130px]">
        <div className="flex flex-col justify-center">
          <span
            className={`text-sm font-bold ${
              isTimeUnderOneHour ? "text-red-500" : ""
            }`}
          >
            {(timeLeft.days > 0 ||
              timeLeft.hours > 0 ||
              timeLeft.minutes > 0 ||
              timeLeft.seconds > 0) &&
            formatCapitaliseString(status) !== "Cancelled" ? (
              `${timeLeft.days}d:${timeLeft.hours}h:${timeLeft.minutes}m:${timeLeft.seconds}s`
            ) : isLoading ? (
              <span className="text-secondaryColor">Fetching...</span>
            ) : formatCapitaliseString(status) === "Cancelled" ? (
              <span className="text-red">Cancelled</span>
            ) : (
              <span className="text-red">Expired</span>
            )}
          </span>
          <span className="text-[#959595] text-[10px]">
            Count down to payment date
          </span>
        </div>
      </span>
      {/* 
      <motion.button
        whileTap={{ scale: 0.9 }}
        className='py-2.5 px-4 rounded-[10px] bg-white border text-sm border-primaryColor'
      >
        View
      </motion.button> */}
    </motion.div>
  );
}

export default ContestedInvoiceCard;
