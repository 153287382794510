import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import InputField from "shared/InputField";
import Pagination from "shared/Pagination";
import Table from "layout/table/Table";
import { PAIconSmallExportDownloadIcon } from "assets/images/svgs";
import { useDispatch, useSelector } from "react-redux";
import TableIcons from "../../../../assets/images/svgs/TableIcons.svg";
import Card from "shared/Card";
import TransitionScale from "shared/animation/TransitionScale";
import DirectFundingAcceptModal from "features/invoice/directfunding/modals/DirectFundingAcceptModal";
import DirectFundingBidSelectAccountModal from "features/invoice/directfunding/modals/DirectFundingBidSelectAccountModal";
import DirectFundingPayBidSuccessOnModal from "features/invoice/directfunding/modals/DirectFundingPaySucessOnBoardModal";
import BidLoaderModal from "features/payableFinaceForFunder/CloseMarket/modals/BidLoaderModla";
import BidPinModal from "features/payableFinaceForFunder/CloseMarket/modals/BidPinModal";
import DirectFundingRejectModal from "features/invoice/directfunding/modals/DirectFundingRejectModal";
import ReasonForRequiredModal from "features/invoice/directfunding/modals/ResaonRequired";
import DirectFundingDeclinedModal from "features/invoice/directfunding/modals/DirectFundingDeclined";
import BidProceedModal from "features/payableFinaceForFunder/CloseMarket/modals/BidProceedModal";
import {
  funderEarlyPaymentViewSingleList,
  funderEarlyPaymentViewBulkList,
  getInvoiceId,
} from "appstate/funder/funderSlice";

const PendingRequest = () => {
  const navigate = useNavigate();

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  // eslint-disable-next-line

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [bulkData, setBulkData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(funderEarlyPaymentViewSingleList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(funderEarlyPaymentViewBulkList());
  }, [dispatch]);

  const { funderEarlyPaymentViewSingleListsData } = useSelector(
    (state) => state.funder
  );

  const { funderEarlyPaymentViewBulkListData } = useSelector(
    (state) => state.funder
  );

  useEffect(() => {
    setData(
      funderEarlyPaymentViewSingleListsData?.data
        ?.buyerSingleEarlyPaymentResponses
    );
  }, [funderEarlyPaymentViewSingleListsData]);

  useEffect(() => {
    setBulkData(
      funderEarlyPaymentViewBulkListData?.data?.buyerBulkEarlyPaymentResponse
    );
  }, [funderEarlyPaymentViewBulkListData]);

  const totalPages = data?.length;

  const HeaderWithIcon = ({ icon, text }) => (
    <div className="flex items-center justify-between ">
      <img src={icon} alt="" className="mr-2" />
      <span>{text}</span>
    </div>
  );

  const handlCheckboxChange = (row) => {
    const isSelected = selectedRows.includes(row.id);
    let newSelectedRows = [];

    if (isSelected) {
      newSelectedRows = selectedRows.filter((id) => id !== row.id);
    } else {
      newSelectedRows = [...selectedRows, row.id];
    }

    setSelectedRows(newSelectedRows);
  };

  // SELECT ALL
  const handlSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectAllText("Select All");
    } else {
      const allIds = data.map((row) => row.id);
      setSelectedRows(allIds);
      setSelectAllText("Deselect All");
    }
    setSelectAll(!selectAll);
    // setDisabled(selectAll);
  };

  const [activeTab, setActiveTab] = useState("single");
  const handleTableClick = (tab) => {
    setActiveTab(tab);
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectAllText, setSelectAllText] = useState("Select All");

  const onSingleRowClicked = (row) => {
    dispatch(
      getInvoiceId({
        earlyPaymentId: row.earlyPaymentId,
        invoiceId: row.invoiceId,
      })
    );
    let path = `/payablefinance-earlypayment/funder-early-payment-details-accepted`;
    navigate(path);
  };
  const onRowClicked = (row) => {
    dispatch(
      getInvoiceId({
        earlyPaymentId: row.earlyPaymentId,
        invoiceId: row.invoiceId,
      })
    );
    let path = `/payablefinance-earlypayment/funder-early-payment-funding-bulk-details-accepted`;
    navigate(path);
  };

  //for single requests
  const column = [
    {
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handlCheckboxChange(row)}
          checked={selectedRows.includes(row.earlyPaymentId)}
        />
      ),
      width: "30px",
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Request Ref. No." />,
      selector: (row) => row?.earlyPaymentRef,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Invoice Ref." />,
      selector: (row) => row?.invoiceRef,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Buyer" />,
      selector: (row) => row?.buyerName,
    },

    {
      name: <HeaderWithIcon icon={TableIcons} text="Supplier" />,
      selector: (row) => row?.supplierCompanyName,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
    },
    {
      name: "Payment Date",
      selector: (row) => row?.earlyPaymentDate,
    },
    {
      name: "Discount Amount",
      // selector: (row) => row?.invoiceAmount,
      selector: (row) => (
        <span style={{ color: "#2EA923" }}>{row?.discountAmount}</span>
      ),
    },
    {
      name: "Request Amount",
      // selector: (row) => row?.requestAmount,
      selector: (row) => (
        <span style={{ color: "#F08000" }}>{row?.requestAmount}</span>
      ),
    },

    {
      name: "Discount Rate",
      selector: (row) => (
        <span style={{ color: "#2EA923" }}>{row?.discountRate}</span>
      ),
    },

    {
      name: "Payment Amount",
      selector: (row) => (
        <span style={{ color: "#F08000" }}>{row?.fundableAmount}</span>
      ),
    },
  ];

  const columnBulkRequest = [
    {
      name: <HeaderWithIcon icon={TableIcons} text="Request Ref. No." />,
      selector: (row) => row?.requestReferenceNumber,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Buyer" />,

      selector: (row) => row?.buyerCompanyName,
    },
    {
      name: <HeaderWithIcon icon={TableIcons} text="Supplier" />,

      selector: (row) => row?.supplierCompanyName,
    },
    {
      name: "Request Date",
      selector: (row) => row?.requestDate,
    },

    {
      name: "No. of Invoices",
      selector: (row) => row?.noOfInvoices,
    },
    {
      name: "Total Discount amount",
      selector: (row) => (
        <span style={{ color: "#2EA923" }}>{row?.totalDiscountAmount}</span>
      ),
    },
    {
      name: "Total Request Amount",
      selector: (row) => (
        <span style={{ color: "#F08000" }}>{row?.totalRequestAmount}</span>
      ),
    },

    {
      name: "Total Payable Amount",
      selector: (row) => (
        <span style={{ color: "#F08000" }}>{row?.totalPayableAmount}</span>
      ),
    },
    // {
    //   name: "",
    //   selector: () => <PAIconOption title="Options" />,
    // },
  ];

  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [checkedAccount, setCheckedAccount] = useState(null);
  const [bidSelectAccountModal, setBidSelectAccountModal] = useState(false);

  const [bidProceed, setBidProceed] = useState(false);
  const [bidPin, setBidPin] = useState(false);
  const [bidLoaderModal, setBidLoaderModal] = useState(false);
  const [bidSuccess, setBidSuccess] = useState(false);
  const [reasonFor, setReasonfor] = useState(false);
  const [directRejectModal, setDirectRejectModal] = useState(false);
  const handleProceed = () => {
    setBidProceed(false);

    setBidPin(true);
  };

  const handleOnselectModal = () => {
    setBidSelectAccountModal(false);

    setBidProceed(true);
  };
  const handlePinModal = () => {
    setBidPin(false);
    setBidLoaderModal(true);
  };

  const handleYes = () => {
    setAcceptModal(false);
    setBidSelectAccountModal(true);
  };
  const handleReject = () => {
    setRejectModal(false);
    setReasonfor(true);
  };

  // const [editDiscount, setEditDiscount] = useState(false);

  const handleBidSuccessClose = () => {
    setBidSuccess(false);
    setSelectedRows([]);
    setSelectAll(false);
    setSelectAllText("Select All");
  };
  const handleContinue = () => {
    setReasonfor(false);
    setBidSuccess(true);
  };
  const handleDeclineClose = () => {
    setDirectRejectModal(false);
    // handleNavigate();
  };
  const renderTabContent = () => {
    switch (activeTab) {
      case "single":
        return (
          <div className=" bg-white rounded-b-[10px]">
            <Table
              columns={column}
              pointer
              data={data}
              onRowClicked={onSingleRowClicked}
              tableHeader
            />

            <div>
              {totalPages > 2 && (
                <div className="flex justify-center gap-x-[8px]  mt-8">
                  <Pagination
                    page={page}
                    startPage={startPage}
                    setEndPage={setEndPage}
                    endPage={endPage}
                    setStartPage={setStartPage}
                    setPage={setPage}
                    totalPages={totalPages}
                  />
                </div>
              )}
            </div>
          </div>
        );
      case "bulk":
        return (
          <div className=" bg-white rounded-b-[10px]">
            <Table
              columns={columnBulkRequest}
              pointer
              data={bulkData}
              onRowClicked={onRowClicked}
            />

            <div>
              {totalPages > 2 && (
                <div className="flex justify-center gap-x-[8px]  mt-8">
                  <Pagination
                    page={page}
                    startPage={startPage}
                    setEndPage={setEndPage}
                    endPage={endPage}
                    setStartPage={setStartPage}
                    setPage={setPage}
                    totalPages={totalPages}
                  />
                </div>
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <TransitionScale>
        <DirectFundingAcceptModal
          mess1={"Would you like to proceed with "}
          blueText={"Approving "}
          mess2={"all"}
          mess3={
            "select Direct Funding requests at their respective effective rates?"
          }
          isOpen={acceptModal}
          onClose={() => {
            setRejectModal(true);
            setAcceptModal(false);
          }}
          handleYes={handleYes}
        />
      </TransitionScale>
      <TransitionScale>
        <DirectFundingRejectModal
          mess1={"Would you like to proceed with "}
          blueText={"Rejecting "}
          mess2={" all the"}
          mess3={" selected Direct Funding request?"}
          isOpen={rejectModal}
          onClose={() => {
            setRejectModal(false);
            setSelectedRows([]);
            setSelectAll(false);
            setSelectAllText("Select All");
          }}
          handleYes={handleReject}
        />
      </TransitionScale>

      <TransitionScale>
        <DirectFundingBidSelectAccountModal
          isOpen={bidSelectAccountModal}
          onClose={() => setBidSelectAccountModal(false)}
          handleProceed={handleOnselectModal}
          message={"Select which account you want to use for this Request"}
          setCheckedAccount={setCheckedAccount}
        />
      </TransitionScale>

      <TransitionScale>
        {bidLoaderModal && (
          <BidLoaderModal
            isOpen={bidLoaderModal}
            onClose={() => setBidLoaderModal(false)}
            onAction={() => {
              setBidLoaderModal(false);
              setTimeout(() => {
                setBidSuccess(true);
              }, 300);
            }}
            statusText={"Fund Invoice"}
          />
        )}{" "}
      </TransitionScale>

      <TransitionScale>
        {" "}
        <BidPinModal
          isOpen={bidPin}
          onClose={() => setBidPin(false)}
          handleNext={handlePinModal}
          checkedAccount={checkedAccount}
        />
      </TransitionScale>
      <TransitionScale>
        {" "}
        <BidProceedModal
          isOpen={bidProceed}
          refNum={" 7364738921"}
          onClose={() => setBidProceed(false)}
          handleYes={handleProceed}
        />
      </TransitionScale>
      <TransitionScale>
        <DirectFundingPayBidSuccessOnModal
          isOpen={bidSuccess}
          onClose={handleBidSuccessClose}
          message={"Early payment request sent for"}
          sucess={"workflow approval"}
        />
      </TransitionScale>
      {reasonFor && (
        <TransitionScale>
          <ReasonForRequiredModal
            onClose={() => setReasonfor(false)}
            onContinue={handleContinue}
          />
        </TransitionScale>
      )}
      <TransitionScale>
        <DirectFundingDeclinedModal
          isOpen={directRejectModal}
          onClose={handleDeclineClose}
        />
      </TransitionScale>
      <TransitionScale></TransitionScale>
      <div>
        <div className="flex justify-between flex-wrap py-3">
          <div className="flex flex-wrap">
            <div className="flex gap-4 mb-4">
              <div className="relative">
                <DatePicker
                  selected={startDate}
                  onChange={(update) => setDateRange(update)}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  placeholderText="Filters"
                  className="border  border-[#ccc] px-4 py-2 cursor-pointer rounded-md w-60"
                />

                <div className="absolute top-2/4 right-3 transform -translate-y-2/4 pointer-events-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 text-gray-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div className="flex ml-2">
              <Button
                neutral
                className="flex !text-[#565656] gap-2 text-xs font-medium items-center  md:mb-0 border border-[#ccc] bg-white py-2 px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px] h-[37px]"
              >
                <p className="whitespace-nowrap">Export</p>
                <PAIconSmallExportDownloadIcon />
              </Button>
            </div>
          </div>
          {/* <div className=" flex items-center justify-center bg-red"> */}
          <div className="flex ">
            <div className="relative flex">
              <div className=" items-center justify-center mt-3">
                {" "}
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute left-3 w-4 h-4"
                >
                  <g
                    id="clarity:search-line"
                    clip-path="url(#clip0_4085_289550)"
                  >
                    <path
                      id="Vector"
                      d="M7.25825 2.7451C8.22097 2.74422 9.16233 3.02889 9.96323 3.56312C10.7641 4.09734 11.3886 4.8571 11.7576 5.74629C12.1267 6.63548 12.2237 7.61414 12.0364 8.55847C11.8491 9.50281 11.386 10.3704 10.7055 11.0514C10.0251 11.7325 9.15793 12.1964 8.21377 12.3846C7.26961 12.5727 6.29085 12.4766 5.40133 12.1084C4.5118 11.7402 3.75147 11.1164 3.21652 10.316C2.68156 9.51558 2.39603 8.57449 2.39603 7.61176C2.40186 6.32361 2.91587 5.08981 3.82632 4.17852C4.73678 3.26724 5.9701 2.75211 7.25825 2.7451ZM7.25825 1.83398C6.11551 1.83398 4.99844 2.17285 4.04829 2.80772C3.09814 3.44259 2.35758 4.34495 1.92028 5.4007C1.48297 6.45645 1.36855 7.61817 1.59149 8.73895C1.81443 9.85973 2.36471 10.8892 3.17274 11.6973C3.98078 12.5053 5.01028 13.0556 6.13106 13.2785C7.25184 13.5015 8.41356 13.387 9.46931 12.9497C10.5251 12.5124 11.4274 11.7719 12.0623 10.8217C12.6972 9.87157 13.036 8.7545 13.036 7.61176C13.036 6.0794 12.4273 4.6098 11.3438 3.52626C10.2602 2.44271 8.79061 1.83398 7.25825 1.83398Z"
                      fill="#2FA06A"
                    />
                    <path
                      id="Vector_2"
                      d="M15.5551 15.2958L12.2795 11.998L11.6484 12.6247L14.924 15.9225C14.9651 15.9639 15.014 15.9969 15.0679 16.0194C15.1218 16.0419 15.1796 16.0536 15.238 16.0538C15.2964 16.0541 15.3542 16.0428 15.4083 16.0206C15.4623 15.9984 15.5114 15.9659 15.5529 15.9247C15.5943 15.8836 15.6273 15.8347 15.6498 15.7808C15.6723 15.7269 15.684 15.6691 15.6842 15.6107C15.6844 15.5523 15.6732 15.4945 15.651 15.4404C15.6288 15.3864 15.5963 15.3373 15.5551 15.2958Z"
                      fill="#2FA06A"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4085_289550">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <InputField
                placeholder="Search for requests"
                className="pl-10 w-full py-2 border rounded-md border[#CCCCCC] outline-none md:w-[350px] !border-silver"
              />
            </div>

            <div className="ml-2">
              <Button
                neutral
                className="flex text-white  text-xs font-medium bg-[#2FA06A] items-center mb-[10px] md:mb-0  py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
              >
                <p className="whitespace-nowrap">Search</p>
              </Button>
            </div>
          </div>
          {/* </div> */}
        </div>

        {/* starts from  here */}
        <Card className="pb-[24px] rounded-[10px]   min-h-[670px]">
          <div className="flex justify-between flex-wrap bg-[#fafafa] py-7 px-6 rounded-t-[10px]">
            <div>
              {activeTab === "single" && (
                <div class=" flex-col justify-start items-start inline-flex">
                  <div class="text-[#1d0220] text-base font-medium ">
                    {selectAll
                      ? "New Early Payment requests "
                      : "Early Payment Requests"}
                  </div>
                  <div class="text-[#5f4d61] text-sm font-medium ">
                    {selectAll
                      ? "List of all new Early Payment requests for you to review"
                      : "List of all New Early Payment requests"}
                  </div>
                </div>
              )}

              {activeTab === "bulk" && (
                <div class=" flex-col justify-start items-start inline-flex">
                  <div class="text-[#1d0220] text-base font-medium ">
                    New Early Payment Requests
                  </div>
                  <div class="text-[#5f4d61] text-sm font-medium ">
                    List of all New Direct Funding requests
                  </div>
                </div>
              )}
            </div>

            {activeTab === "single" && (
              <div className=" flex flex-wrap gap-2">
                <div className="">
                  <Button
                    neutral
                    className="flex text-white  text-xs font-medium bg-[#2FA06A] items-center mb-[10px] md:mb-0  py-[10px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
                    onClick={handlSelectAll}
                  >
                    <p className="whitespace-nowrap">{selectAllText}</p>
                  </Button>
                </div>
                <div>
                  <Button
                    neutral
                    className={`flex text-white  text-xs font-medium ${
                      selectAll ? "bg-[#F08000]" : "bg-[#c2c0c0]"
                    } items-center mb-[10px] md:mb-0  py-[10px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]`}
                    disabled={!selectAll}
                    onClick={() => setAcceptModal(true)}
                  >
                    <p className="whitespace-nowrap">Approve Early Payment</p>
                  </Button>
                </div>
              </div>
            )}
          </div>

          <div className="mt-3 px-[15px] text-sm bg-white">
            <div className="flex gap-3 py-3">
              <p
                className={`cursor-pointer font-bold relative ${
                  activeTab === "single" ? "text-[#F08000]" : "text-[#959595]"
                }`}
                onClick={() => handleTableClick("single")}
              >
                Single requests
                {activeTab === "single" && (
                  <span
                    className="absolute bottom-0 left-1/2 transform -translate-x-1/2
               bg-[#F08000] h-0.5 w-5"
                  ></span>
                )}
              </p>

              <p
                className={`cursor-pointer font-bold relative ${
                  activeTab === "bulk" ? "text-[#F08000]" : "text-[#959595]"
                }`}
                onClick={() => handleTableClick("bulk")}
              >
                Bulk requests
                {activeTab === "bulk" && (
                  <span
                    className="absolute bottom-0 left-1/2 transform -translate-x-1/2
               bg-[#F08000] h-0.5 w-5"
                  ></span>
                )}
              </p>
            </div>

            {/* Render the tab content */}
            <div className="mt-3">{renderTabContent()}</div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default PendingRequest;
