import React from "react";

const RequestDetails = ({ details }) => {
  // earlyPaymentDate
  return (
    <div>
      {" "}
      <div className="   pb-4">
        <div class=" justify-between  flex flex-row    px-6 mt-4  ">
          <div className="flex gap-2 py-2.5">
            <div className="flex flex-col">
              {/* <span className=" text-black text-xs font-normal">
      Coca Cola
    </span> */}
              <span class="text-black text-sm font-normal ">
                {details?.buyerCompanyName && details?.buyerCompanyName}
              </span>
              {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
              <span class="text-[#949494] text-sm font-normal ">Buyer</span>
            </div>
          </div>
          <div class=" flex-col justify-start items-start inline-flex">
            {" "}
            <div className="flex gap-2 py-2.5">
              <div className="flex flex-col">
                {/* <span className=" text-black text-xs font-normal">
      Coca Cola
    </span> */}
                <span class="text-black text-sm font-normal ">
                  {details?.supplierCompanyName && details?.supplierCompanyName}
                </span>
                {/* <span className="text-[#979797]  text-xs">Supplier</span> */}
                <span class="text-[#949494] text-sm font-normal ">
                  Supplier
                </span>
              </div>
            </div>
          </div>

          {/* here */}
        </div>
        <div class=" justify-between  flex flex-row     px-6  py-3   mt-6">
          <div class="py-2.5 flex-col justify-start items-start inline-flex">
            <div class="flex-col justify-start items-start gap-0.5 flex">
              <div class="text-black text-sm font-normal ">
                {" "}
                {details?.discountAmount && details?.discountAmount}
              </div>
            </div>
            <div class="flex-col justify-start items-start  flex">
              <div class="text-[#949494] text-sm font-normal ">
                Discount Amount
              </div>
            </div>
          </div>
          {/* dhdh */}
          <div class="py-2.5 flex-col justify-start items-start inline-flex">
            <div class="flex-col justify-start items-start gap-0.5 flex">
              <div class="text-black text-sm font-normal ">
                {" "}
                {details?.earlyPaymentDate && details?.earlyPaymentDate}
              </div>
            </div>
            <div class="flex-col justify-start items-start  flex">
              <div class="text-[#949494] text-sm font-normal ">
                Early Payment date
              </div>
            </div>
          </div>
        </div>
        <div class=" justify-between  flex flex-row    px-6  mt-6 ">
          <div class="py-2.5 flex-col justify-start items-start inline-flex">
            <div class="flex-col justify-start items-start gap-0.5 flex">
              <div class="text-black text-sm font-normal ">
                NGN {details?.requestAmount && details?.requestAmount}
              </div>
            </div>
            <div class="flex-col justify-start items-start  flex">
              <div class="text-[#949494] text-sm font-normal ">
                Request Amount
              </div>
            </div>
          </div>
          {/* hd */}
          <div class="py-2.5 flex-col justify-start items-start inline-flex">
            <div class="flex-col justify-start items-start gap-0.5 flex">
              <div class="text-black text-base font-extrabold ">
                NGN {details?.fundableAmount && details?.fundableAmount}
              </div>
            </div>
            <div class="flex-col justify-start items-start  flex">
              <div class="text-[#949494] text-sm font-normal ">
                Fundable Amount
              </div>
            </div>
          </div>{" "}
        </div>
        <div class=" justify-between  flex flex-col     px-6  pt-6 border-t border-[#c1c1c1]/60 mt-4">
          <div class="text-black text-lg font-semibold  ">
            Note from Supplier:{" "}
          </div>

          <div class=" text-[#949494] text-sm font-light  mt-4  leading-6">
            {details?.note && details?.note}
          </div>
        </div>{" "}
        {/* 2nd */}
        {/* another header */}
        {/* header ends here */}
        {/* here */}
      </div>
    </div>
  );
};

export default RequestDetails;
