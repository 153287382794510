import React, { useState, useEffect } from "react";
import {
  PAIconFilter,
  PAIconOutlineSearch,
  PAIconDropdown,
  PAIExportableDownload,
} from "assets/images/svgs";

// import Table from "layout/table/Table";
import Loading from "shared/Loading";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import SingleRequest from "./Single";
import Bulk from "./Bulk";
import { fetchTransactionHistory } from "appstate/buyer/buyerSlice";
import { useDispatch } from "react-redux";
import Pagination from "shared/Pagination";
import { useSelector } from "react-redux";
const TransactionHistory = () => {
  const [selectedFilters] = useState([]);
  const [isDropdownOpen] = useState(false);

  // const navigate = useNavigate();

  const [searchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;

  const bulkdumData = [
    {
      idx: "1",
      companyName: "XYZ Ventures",
      player: "Supplier",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      channel: "Early Payment Request",
    },
    {
      idx: "2",
      companyName: "Dangote LTD ",
      player: "Supplier",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      channel: "Loan Default",
    },
    {
      idx: "3",
      companyName: "Nestle PLC",
      player: "Supplier",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Successful",
      channel: "Early Payment Offers ",
    },
  ];

  const [activeTab, setActiveTab] = useState("single");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchTransactionHistory({
        page,
        size: pageSize,
        search: searchQuery,
        isBulk: false,
      })
    );
  }, [dispatch, page, searchQuery]);

  const {
    transHistory,
    // earlyPaymentOfferByVa,
    isLoading,
  } = useSelector((state) => state.buyer);

  /*



  {
  "data": [
    {
      "transactionId": "string",
      "programId": "string",
      "transactionParty": "string",
      "paymentDate": "2025-01-23",
      "invoiceNo": "string",
      "currency": "DZD",
      "amount": 0,
      "status": "PENDING",
      "fundingChannel": "WALLET"
    }
  ],
  "success": true,
  "message": "string",
  "status": 0,
  "meta": {
    "size": 0,
    "page": 0,
    "numberOfElements": 0,
    "totalElements": 0
  }
}*/

  const ReturnTab = () => {
    if (activeTab === "Bulk requests") {
      return <Bulk />;
    } else {
      return (
        <>
          <SingleRequest />;
          <div className="flex justify-center gap-x-[8px] mt-[20px]">
            {transHistory?.data?.meta?.totalElements > 10 && (
              <Pagination
                page={page}
                startPage={startPage}
                setEndPage={setEndPage}
                endPage={endPage}
                setStartPage={setStartPage}
                setPage={setPage}
                progressPending={isLoading}
                progressComponent={<Loading />}
                pointer
                tableHeader
                totalPages={transHistory?.data?.meta?.totalElements}
              />
            )}
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div>
        {" "}
        <div className="my-2 grid md:grid-cols-2 items-center">
          <div className="gap-3 flex relative">
            <div>
              <div className="flex items-center gap-[4px] border border-[#DEDEDE] bg-white shadow-sm rounded-[5px] px-4 py-2 text-sm focus:outline-none cursor-pointer">
                Filter{" "}
                {selectedFilters.length > 0 && (
                  <span className="bg-gray rounded-lg p-1 px-2">
                    {selectedFilters.length}
                  </span>
                )}{" "}
                Selected <PAIconDropdown />
              </div>
              {isDropdownOpen && (
                <div className="absolute top-full mt1 wfull border border-[#DEDEDE] shadow-lg rounded-b-[5px] px-4 py-3 bg-white">
                  <label className="block">
                    <input type="checkbox" value="date" />
                    Date
                  </label>
                  <label className="block">
                    <input type="checkbox" value="name" />
                    Name
                  </label>
                  <label className="block">
                    <input
                      type="checkbox"
                      value="currency"
                      checked={selectedFilters.includes("currency")}
                    />
                    Currency
                  </label>
                  <label className="block">
                    <input
                      type="checkbox"
                      value="amount"
                      checked={selectedFilters.includes("amount")}
                    />
                    Amount
                  </label>
                </div>
              )}
            </div>
            <div>
              <select
                className="border border-[#DEDEDE] shadow-sm rounded-[5px] px-4 py-3 text-sm focus:outline-none cursor-pointer"
                onChange={(e) => {}}
              >
                <option value="">Sort by</option>
                <option value="date">Date</option>
                <option value="name">Name</option>
              </select>
            </div>
            <button className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2  flex  gap-2">
              Export
              <span>{<PAIExportableDownload />}</span>
            </button>
          </div>
          <div className="flex md:col-span-1 justify-end">
            {/* <SearchInput /> */}
            {/* starts from here */}
            <div>
              <div className="flex items-center justify-between gap-5">
                <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
                  <div className="items-center flex bg-white">
                    <PAIconOutlineSearch />
                    <input
                      className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                      type="text"
                      placeholder="Search for due invoices awaiting collection"
                    />
                  </div>
                  <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                    <PAIconFilter className="bg-[#2FA06A]" />
                  </div>
                </div>
                <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
                  <PAIconFilter /> Filter
                </button>
              </div>
            </div>

            {/* ends here */}
          </div>
        </div>
        <>
          {!bulkdumData.length ? (
            <div className="ml3">
              <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
                <p className="text-black text-sm font-medium">
                  Overview of CashInflow Records
                </p>
              </div>
              <TableEmptyState dashboard />
            </div>
          ) : (
            <Card className="rounded-[10px] mt-[24px] ">
              <div className="bg-alabasterHeader rounded-t-[10px] min-h-20 px-[24px]   ">
                <div className=" border-b-[0.8px] border-zinc-400 py-4 h-full ">
                  <div className="flex  items-center    ">
                    <div>
                      <p className="text-black text-lg font-semibold">
                        Recent disbursement history
                      </p>
                      <span className="text-xs font-thin text-[#959595]">
                        Here is a recent list of your receivables from Payment
                        disbursement
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* top ends here */}

              <div className="flex  items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
                {/* another */}

                <div className="flex px-[24px] gap-5 my-5">
                  <p
                    className={`cursor-pointer text-sm font-bold relative ${
                      activeTab === "single"
                        ? "text-[#F08000]"
                        : "text-[#959595]"
                    }`}
                    onClick={() => setActiveTab("single")}
                  >
                    Single Request
                    {activeTab === "single" && (
                      <span className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-[#F08000] h-0.5 w-5"></span>
                    )}
                  </p>

                  <p
                    className={`cursor-pointer text-sm font-bold relative ${
                      activeTab === "bulk" ? "text-[#F08000]" : "text-[#959595]"
                    }`}
                    onClick={() => setActiveTab("bulk")}
                  >
                    Bulk Request
                    {activeTab === "bulk" && (
                      <span className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-[#F08000] h-0.5 w-5"></span>
                    )}
                  </p>
                </div>
              </div>
              <div className="px-[24px] pb[24px]">
                {/* {activeTab === "single Request" ? ( */}
                {/* <SingleRequest /> */}
                {/* // ) : ( // <BulkTable />
                // )} */}

                {<ReturnTab />}
              </div>
            </Card>
          )}
        </>
      </div>
    </>
  );
};

export default TransactionHistory;
