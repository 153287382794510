import React, { useEffect, useState } from "react";
import {
  PAIconDownload,
  PAIconFilter,
  PAIconOutlineSearch,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
// import TableEmptyState from "shared/TableEmptyState";
import Table from "layout/table/Table";
import { useDispatch, useSelector } from "react-redux";
import { allDirectFunding } from "appstate/invoice/invoiceSlice";
import Pagination from "shared/Pagination";
import Loading from "shared/Loading";
import {
  allBuyerOpenMarket,
  fundedBuyerOpenMarket,
} from "appstate/openMarket/openMarketSlice";
import { formatCurrency } from "utilities/helper/formatters";
import { formatCapitalize } from "utilities/formatCapitalize";

const AllInvoices = () => {
  const [activeTab, setActiveTab] = useState("single");
  const navigate = useNavigate();

  const { allBuyerOpenMarketData, fundedBuyerOpenMarketData, isLoading } =
    useSelector((state) => state?.openMarket);

  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;

  const handleSearch = () => {
    dispatch(allDirectFunding({ search: searchQuery }));
  };

  // all
  useEffect(() => {
    dispatch(
      allBuyerOpenMarket({
        page,
        pageSize,
        searchTerm: searchQuery,
      })
    );
  }, [dispatch, page, pageSize, searchQuery]);

  // funded
  useEffect(() => {
    dispatch(
      fundedBuyerOpenMarket({
        page,
        pageSize,
        searchTerm: searchQuery,
      })
    );
  }, [dispatch, page, pageSize, searchQuery]);

  const singleDetails = (row) => {
    // const { openMarketReference, status } = row;
    // if (status === "POSTED") {
    //   navigate(`/factoring/posted/${openMarketReference}`);
    // } else if (status === "BID_ACTIVE") {
    //   navigate(`/factoring/bid-active/${openMarketReference}`);
    // }
    const { openMarketReference } = row;
    let path = `/open-market/acceptedInvoice/${openMarketReference}`;
    navigate(path);
  };

  const bulkDetails = (row) => {
    const { openMarketReference } = row;
    let path = `/factoring/funded/${openMarketReference}`;
    navigate(path);
  };

  // const bulkDetails = (id) => {
  //   let path = `/factoring/funded/${id}`;
  //   navigate(path);
  // };

  // const singleDummyData = [
  //   {
  //     id: 1,
  //     invoiceNumber: "R3456X89Y",
  //     supplier: "XYZ Ventures",
  //     invoiceDate: "2023-05-23",
  //     dueDate: "2023-05-23",
  //     currency: "NGN",
  //     invoiceAmount: "9,000,000",
  //     status: "POSTED",
  //   },
  //   {
  //     id: 2,
  //     invoiceNumber: "R3456X89Y",
  //     supplier: "XYZ Ventures",
  //     invoiceDate: "2023-05-23",
  //     dueDate: "2023-05-23",
  //     currency: "NGN",
  //     invoiceAmount: "9,000,000",
  //     status: "BID ACTIVE",
  //   },
  //   {
  //     id: 3,
  //     invoiceNumber: "R3456X89Y",
  //     supplier: "XYZ Ventures",
  //     invoiceDate: "2023-05-23",
  //     dueDate: "2023-05-23",
  //     currency: "NGN",
  //     invoiceAmount: "9,000,000",
  //     status: "POSTED",
  //   },
  // ];

  // const bulkDummyData = [
  //   {
  //     id: 1,
  //     invoiceNumber: "R3456X89Y",
  //     supplier: "XYZ Ventures",
  //     invoiceDate: "2023-05-23",
  //     dueDate: "2023-05-23",
  //     currency: "NGN",
  //     invoiceAmount: "9,000,000",
  //     status: "FUNDED",
  //   },
  //   {
  //     id: 2,
  //     invoiceNumber: "R3456X89Y",
  //     supplier: "XYZ Ventures",
  //     invoiceDate: "2023-05-23",
  //     dueDate: "2023-05-23",
  //     currency: "NGN",
  //     invoiceAmount: "9,000,000",
  //     status: "FUNDED",
  //   },
  //   {
  //     id: 3,
  //     invoiceNumber: "R3456X89Y",
  //     supplier: "XYZ Ventures",
  //     invoiceDate: "2023-05-23",
  //     dueDate: "2023-05-23",
  //     currency: "NGN",
  //     invoiceAmount: "9,000,000",
  //     status: "FUNDED",
  //   },
  // ];

  const columns = [
    {
      name: (
        <span className="whitespace-normal">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request reference
        </span>
      ),
      selector: (row) => {
        const invoiceNumber = row.invoiceNumber;
        return invoiceNumber && !isNaN(invoiceNumber)
          ? `INV-${invoiceNumber}`
          : "INV-0";
      },
      sortable: true,
    },
    {
      name: "Supplier",
      selector: (row) => row.supplierCompanyName,
      sortable: true,
    },
    {
      name: "Invoice Date",
      selector: (row) => row?.createdDate?.join("-"),
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row?.invoiceDueDate?.join("-"),
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row?.invoiceAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{formatCurrency(row?.invoiceAmount)}</p>
      ),
    },
    {
      name: "Bid Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <p
          className={
            row.status === "POSTED" || row.status === "FUNDED"
              ? "text-primaryColor"
              : "text-secondaryColor"
          }
        >
          {formatCapitalize(row.status)}
        </p>
      ),
    },
  ];

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const totalPendingInvoices = allBuyerOpenMarketData?.data?.length || 0;

  const totalFundedInvoices = fundedBuyerOpenMarketData?.data?.length || 0;

  const renderTabContent = () => {
    switch (activeTab) {
      case "single":
        // return <SingleTableDirectFund data={dummyData} columns={columns} />;
        return (
          <>
            <Table
              columns={columns}
              data={allBuyerOpenMarketData?.data}
              onRowClicked={singleDetails}
              pointer
              tableHeader
              className="mt-5"
            />
            <div className="flex justify-center gap-x-[8px] mt-[20px]">
              {allBuyerOpenMarketData?.data?.length > 0 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={allBuyerOpenMarketData?.meta?.totalElements}
                />
              )}
            </div>
          </>
        );
      case "bulk":
        // return <BulkTableDirectFund />;
        return (
          <>
            <Table
              columns={columns}
              data={fundedBuyerOpenMarketData?.data}
              // onRowClicked={(row) => bulkDetails(row.id)}
              onRowClicked={bulkDetails}
              progressPending={isLoading}
              progressComponent={<Loading />}
              pointer
              tableHeader
              className="mt-5"
            />
            <div className="flex justify-center gap-x-[8px] mt-[20px]">
              {fundedBuyerOpenMarketData?.data?.length > 0 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={fundedBuyerOpenMarketData?.meta?.totalElements}
                />
              )}
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="my-2 grid md:grid-cols-2 items-center">
        <div className="gap-3 flex relative">
          {/* EXPORT */}
          <button className="py-2 px-4 text-sm rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 flex items-center justify-center gap-1.5">
            Export {<PAIconDownload className="accent-gray300 w-4" />}
          </button>
        </div>

        {/* SEARCH */}
        <div className="flex md:col-span-1 justify-end">
          <div className="flex items-center justify-between gap-5">
            <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
              <div className="items-center flex bg-white">
                <PAIconOutlineSearch />
                <input
                  className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                  type="text"
                  placeholder="Search for invoices"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </div>
              <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                <PAIconFilter className="bg-[#2FA06A]" />
              </div>
            </div>
            <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
              Search
            </button>
          </div>
        </div>
      </div>

      {/* TABLE */}
      <div>
        {/* {!allBuyerOpenMarketData?.data?.length && !fundedBuyerOpenMarketData?.data?.length ? (
          <div>
            <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <p className="text-black text-sm font-medium">
                Invoices from Direct Funding awaiting collection
              </p>
            </div>
            <TableEmptyState dashboard />
          </div>
        ) : ( */}
        <Card className="rounded-[10px] mt-[15px]">
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <div className="py-[24px]">
              <div className="flex px[24px] gap-3 my-3">
                <p
                  className={`cursor-pointer font-bold relative ${
                    activeTab === "single" ? "text-[#F08000]" : "text-[#959595]"
                  }`}
                  onClick={() => handleTabClick("single")}
                >
                  Pending Funding
                  {activeTab === "single" && (
                    <span
                      className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                 bg-[#F08000] h-0.5 w-5"
                    ></span>
                  )}
                </p>

                <p
                  className={`cursor-pointer font-bold relative ${
                    activeTab === "bulk" ? "text-[#F08000]" : "text-[#959595]"
                  }`}
                  onClick={() => handleTabClick("bulk")}
                >
                  Funded
                  {activeTab === "bulk" && (
                    <span
                      className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                 bg-[#F08000] h-0.5 w-5"
                    ></span>
                  )}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-center gap-3">
              <p>
                {activeTab === "single"
                  ? `${totalPendingInvoices} Total Invoices`
                  : `${totalFundedInvoices} Total Invoices`}
              </p>{" "}
            </div>
          </div>
          <div className="pb-[24px]">
            <div className="mt-3">{renderTabContent()}</div>
          </div>
        </Card>
        {/* )} */}
      </div>
    </>
  );
};

export default AllInvoices;
